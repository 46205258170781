export const RESPONSE_SUCCESS = 200;
export const RESPONSE_SERVER_NOT_AVAILABLE = 404;
export const RESPONSE_INVALID_REQUEST = 400;
export const RESPONSE_NOT_AUTHORIZED = 401;
export const RESPONSE_FORBIDDEN = 403;
export const RESPONSE_SERVER_ERROR = 500;
export const RESPONSE_PAYMENT_REQUIRED = 402;
export const RESPONSE_METHOD_NOT_ALLOWED = 405;
export const RESPONSE_NOT_ACCEPTABLE = 406;
export const RESPONSE_REQUEST_TIMEOUT = 408;
export const RESPONSE_CONFLICT = 409;
export const RESPONSE_GONE = 410;
export const RESPONSE_PRECONDITION_FAILED = 412;
export const RESPONSE_PAYLOAD_TOO_LARGE = 413;
export const RESPONSE_URI_TOO_LONG = 414;
export const RESPONSE_UNSUPPORTED_MEDIA_TYPE = 415;
export const RESPONSE_TOO_MANY_REQUEST = 429;
