/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useState } from "react";
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete, Grid, Icon, IconButton } from "@mui/material";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

import "./styles.css";
import MDButton from "components/MDButton";
import HTTPSServices from "services/https/HTTPSServices";
import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import utils from "services/utils";

const { postRequest } = HTTPSServices;
const { CUSTOMERS, ORDERS } = pathUrl;
const { getResponseMsg, getStorageItem, getPaymentOptions } = utils;

function Email({
  uuid,
  onClose,
  emailOptions,
  showCc,
  showTo,
  showInitialTo,
  defaultEmailType,
  transactionOptions,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showToInput, setShowToInput] = useState(false);
  const [showCcInput, setShowCcInput] = useState(false);

  const [emailToList, setEmailToList] = useState([]);
  const [emailToValue, setEmailToValue] = useState(showInitialTo);

  const [emailCcList, setEmailCcList] = useState([]);
  const [emailCcValue, setEmailCcValue] = useState("");

  const [emailType, setEmailType] = useState(
    defaultEmailType?.disabled === true ? emailOptions[1] : defaultEmailType || emailOptions[1]
  );

  const [errMsg, setErrMsg] = useState("");
  const [editorValue, setEditorValue] = useState("");

  const [inputValue, setInputValue] = useState("");
  const [transactionType, setTransactionType] = useState(transactionOptions[0] || null);

  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));
  const customerDetail = JSON.parse(getStorageItem("current_customer_detail"));

  const handleShowToInput = () => setShowToInput(true);
  const handleHideToInput = () => setShowToInput(false);

  const handleShowCcInput = () => setShowCcInput(true);
  const handleHideCcInput = () => setShowCcInput(false);

  const isInList = (email) => emailToList.includes(email);

  const isEmail = (email) => /[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/.test(email);

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setErrMsg(error);

      return false;
    }

    return true;
  };

  const handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      let value = "";
      switch (evt.target.name) {
        case "emailTo":
          value = emailToValue.trim();

          if (value && isValid(value)) {
            setEmailToList([...emailToList, emailToValue]);
            if (evt.key !== ",") {
              setEmailToValue("");
            }
          }
          break;
        case "emailCc":
          value = emailCcValue.trim();

          if (value && isValid(value)) {
            setEmailCcList([...emailCcList, emailCcValue]);
            if (evt.key !== ",") {
              handleHideCcInput();
            }
            setEmailCcValue("");
          }
          break;
        default:
          break;
      }
    }
  };

  const handleChange = (evt) => {
    switch (evt.target.name) {
      case "emailTo":
        setEmailToValue(evt.target.value);
        break;
      case "emailCc":
        setEmailCcValue(evt.target.value);
        break;
      default:
        break;
    }
  };

  const handleDeleteTo = (item) => {
    setEmailToList(emailToList.filter((i) => i !== item));
  };

  const handleDeleteCc = (item) => {
    setEmailCcList(emailCcList.filter((i) => i !== item));
  };

  const handlePaste = (evt) => {
    evt.preventDefault();
    const paste = evt.clipboardData.getData("text");
    const emails = paste.match(/[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/g);

    switch (evt.target.name) {
      case "emailTo":
        if (emails) {
          const toBeAdded = emails.filter((email) => !isInList(email));

          setEmailToList([...emailToList, ...toBeAdded]);
        }
        break;
      case "emailCc":
        if (emails) {
          const toBeAdded = emails.filter((email) => !isInList(email));

          setEmailCcList([...emailToList, ...toBeAdded]);
        }
        break;
      default:
        break;
    }
  };

  const handleChangeEmailType = (e, val) => {
    if (val) {
      setEmailType(val);
    }
    // if (val.name === "Payment Receipt") {
    //   handleHideCcInput();
    // } else {
    //   handleShowCcInput();
    // }
  };

  const handleChangePaymentType = (e, val) => {
    if (val) {
      setTransactionType(val);
    }
    // if (val.name === "Payment Receipt") {
    //   handleHideCcInput();
    // } else {
    //   handleShowCcInput();
    // }
  };

  const handleSendEmail = async () => {
    setIsLoading(true);
    const body = {
      customer: uuid,
      note: editorValue,
      to_email: emailToValue,
      cc_email: emailCcList,
    };
    // console.log(emailType.name);

    let response = {};

    switch (emailType.name) {
      case "Missing Paperworks":
        response = await postRequest(`${CUSTOMERS.API}${uuid}/email-missing-paperwork/`, body);

        break;
      case "Invoice":
        response = await postRequest(`${CUSTOMERS.API}${uuid}/email-invoice/`, body);
        break;
      case "Order Invoice":
        response = await postRequest(`${ORDERS.API}email-invoice/`, {
          ...body,
          orders: [orderDetail?.uuid],
        });
        break;
      case "Orders":
        response = await postRequest(`${ORDERS.API}email-order/`, {
          ...body,
          order: orderDetail?.uuid,
        });
        break;
      case "Payment Receipt":
        response = await postRequest(`/payments/${transactionType?.value}/email-receipt/`, {
          ...body,
          order: orderDetail?.uuid,
        });
        break;
      default:
        break;
    }
    setIsLoading(false);
    onClose(emailType, getResponseMsg(response) === "Success", response);

    setEmailToValue("");
    handleHideToInput();

    setEmailCcValue("");
    handleHideCcInput();
  };

  const handleOnBlur = (evt) => {
    evt.preventDefault();
    let value = "";
    switch (evt.target.name) {
      case "emailTo":
        value = emailToValue.trim();

        if (evt.target.value === "") {
          handleHideToInput();
        } else if (value && isValid(value)) {
          setEmailToList([...emailToList, emailToValue]);

          setEmailToValue("");
          handleHideToInput();
        }

        break;
      case "emailCc":
        value = emailCcValue.trim();
        if (evt.target.value === "") {
          handleHideCcInput();
        } else if (value && isValid(value)) {
          setEmailCcList([...emailCcList, emailCcValue]);

          setEmailCcValue("");
          handleHideCcInput();
        }

        break;
      default:
        break;
    }
  };

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDTypography variant="h5">Email</MDTypography>

      <MDBox mt={2}>
        {showInitialTo !== "" && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={2} display="flex">
                <MDBox>
                  <MDTypography component="label" variant="button" fontWeight="bold" color="text">
                    To:&nbsp; &nbsp;
                    {/* {showInitialTo} */}
                  </MDTypography>
                </MDBox>
                <MDBox width="80%">
                  <FormField
                    type="text"
                    // label="Add Email to"
                    name="emailTo"
                    value={emailToValue}
                    onKeyDown={handleKeyDown}
                    onChange={handleChange}
                    onPaste={handlePaste}
                  />
                </MDBox>
                {/* <MDTypography component="label" variant="button" fontWeight="regular" color="dark">
                  {emailToList.map((item) => (
                    <div className="tag-item" key={item}>
                      {item}
                      <MDBox mx={1}>
                        <IconButton
                          size="small"
                          aria-label="edit"
                          color="info"
                          onClick={() => handleDeleteTo(item)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                      </MDBox>
                    </div>
                  ))}
                </MDTypography> */}

                {/* <IconButton size="small" aria-label="edit" color="info" onClick={handleShowToInput}>
                  <Icon fontSize="small">add_circle_outline</Icon>
                </IconButton> */}
              </MDBox>
            </Grid>
            {showToInput && (
              <Grid item xs={12}>
                <FormField
                  type="text"
                  label="Add Email to"
                  name="emailTo"
                  value={emailToValue}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />
              </Grid>
            )}
          </Grid>
        )}
        {showTo && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox my={2} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="bold" color="text">
                  To:&nbsp;
                </MDTypography>
                <MDTypography component="label" variant="button" fontWeight="regular" color="dark">
                  {emailToList.map((item) => (
                    <div className="tag-item" key={item}>
                      {item}
                      <MDBox mx={1}>
                        <IconButton
                          size="small"
                          aria-label="edit"
                          color="info"
                          onClick={() => handleDeleteTo(item)}
                        >
                          <Icon fontSize="small">cancel</Icon>
                        </IconButton>
                      </MDBox>
                    </div>
                  ))}
                </MDTypography>

                <IconButton size="small" aria-label="edit" color="info" onClick={handleShowToInput}>
                  <Icon fontSize="small">add_circle_outline</Icon>
                </IconButton>
              </MDBox>
            </Grid>
            {showToInput && (
              <Grid item xs={12}>
                <FormField
                  type="text"
                  label="Add Email to"
                  name="emailTo"
                  value={emailToValue}
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                />
              </Grid>
            )}
          </Grid>
        )}
        {showCc && emailType.name !== "Payment Receipt" && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MDBox display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="bold" color="text">
                  CC:&nbsp;
                </MDTypography>
                <MDTypography component="label" variant="button" fontWeight="regular" color="dark">
                  {emailCcList.map((item) => (
                    <div className="tag-item" key={item}>
                      <MDBox>{item}</MDBox>
                      <MDBox mx={1}>
                        <IconButton size="small" aria-label="edit" color="info">
                          <MDTypography>
                            <Icon fontSize="small" onClick={() => handleDeleteCc(item)}>
                              cancel
                            </Icon>
                          </MDTypography>
                        </IconButton>
                      </MDBox>
                    </div>
                  ))}
                </MDTypography>
                <IconButton size="small" aria-label="edit" color="info" onClick={handleShowCcInput}>
                  <Icon fontSize="small">add_circle_outline</Icon>
                </IconButton>
              </MDBox>
            </Grid>
            {showCcInput && (
              <Grid item xs={12}>
                <FormField
                  autoFocus
                  type="email"
                  label="Email"
                  name="emailCc"
                  value={emailCcValue}
                  placeholder="Press Enter / Comma / Tab to add Email on the  List"
                  onKeyDown={handleKeyDown}
                  onChange={handleChange}
                  onPaste={handlePaste}
                  onBlur={handleOnBlur}
                />
              </Grid>
            )}
          </Grid>
        )}
      </MDBox>
      <MDBox mt={2}>
        <Grid container>
          <Grid item xs={12}>
            <MDBox>
              <Grid container>
                <Grid item xs={2}>
                  <MDBox display="inline-block">
                    <MDTypography
                      component="label"
                      variant="button"
                      fontWeight="bold"
                      color="dark"
                      textTransform="capitalize"
                    >
                      Type :&nbsp;
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item xs={10} mt={0.5}>
                  <Autocomplete
                    // defaultValue={
                    //   defaultEmailType?.disabled === true
                    //     ? emailOptions[1]
                    //     : defaultEmailType || emailOptions[1]
                    // }
                    value={emailType}
                    onChange={(event, newValue) => {
                      handleChangeEmailType(event, newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      value !== "" ? option.name === value.name : option.name
                    }
                    options={emailOptions}
                    getOptionLabel={(option) => option.name}
                    getOptionDisabled={(option) => option?.disabled === true}
                    sx={{ width: 300 }}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                  />
                </Grid>
              </Grid>
            </MDBox>
          </Grid>
          {emailType.name === "Payment Receipt" && (
            // <MDBox>
            <Grid container>
              <Grid item xs={7}>
                <MDBox display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="bold"
                    color="dark"
                    textTransform="capitalize"
                  >
                    Transaction :
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid item xs={10} mt={0.5}>
                <Autocomplete
                  // defaultValue={
                  //   defaultEmailType?.disabled === true
                  //     ? emailOptions[1]
                  //     : defaultEmailType || emailOptions[1]
                  // }
                  value={transactionType}
                  onChange={(event, newValue) => {
                    handleChangePaymentType(event, newValue);
                  }}
                  // inputValue={inputValue}
                  // onInputChange={(event, newInputValue) => {
                  //   setInputValue(newInputValue);
                  // }}
                  isOptionEqualToValue={(option, value) =>
                    value !== "" ? option.name === value.name : option.name
                  }
                  options={transactionOptions}
                  getOptionLabel={(option) => option.name}
                  style={{
                    width: 300,
                  }}
                  // fullWidth
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" style={{ width: 370 }} />
                  )}
                />
              </Grid>
            </Grid>
            // </MDBox>
          )}
          {emailType.name !== "Payment Receipt" && (
            <Grid item xs={12}>
              <MDBox mb={1} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="bold" color="dark">
                  Addtional Notes&nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (optional)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditor value={editorValue} onChange={setEditorValue} />
            </Grid>
          )}
          <Grid item xs={12}>
            <MDBox mt={3} display="flex" justifyContent="flex-end">
              <MDBox mx={1}>
                <MDButton variant="gradient" color="error" size="small" onClick={onClose}>
                  cancel
                </MDButton>
              </MDBox>

              <MDBox mx={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleSendEmail}
                  // disabled={emailType.name === "Payment Receipt" && transactionType === null}
                >
                  send
                </MDButton>
              </MDBox>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}
Email.defaultProps = {
  uuid: "",
  onClose: () => {},
  emailOptions: ["Type 1", "Type 2", "Type 3"],
  transactionOptions: ["Type 1", "Type 2", "Type 3"],
  showCc: false,
  showTo: false,
  showInitialTo: "",
  defaultEmailType: { name: "Email", disabled: false },
};

Email.propTypes = {
  uuid: PropTypes.string,
  onClose: PropTypes.func,
  emailOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  transactionOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  showCc: PropTypes.bool,
  showTo: PropTypes.bool,
  showInitialTo: PropTypes.string,
  defaultEmailType: PropTypes.objectOf(PropTypes.any),
};

export default Email;
