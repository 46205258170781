/* eslint-disable react/no-unused-prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// Data
import OrderMgr from "layouts/Order/OrderMgr";
import MDTypography from "components/MDTypography";
import { Autocomplete, CircularProgress, Icon, IconButton } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  preventNegativeInput,
  limitInputLength,
  convertToCurrency,
} = OrderMgr;

function OrderPayment({ onClose }) {
  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));
  const regex = /^[0-9a-zA-Z(\-)]+$/;

  const paymentOptions = [
    { name: "Cash", value: "cash" },
    { name: "Check", value: "check" },
    { name: "Credit/Debit", value: "credit_debit" },
    { name: "ACH payment", value: "ach" },
    // { name: "Credit/Debit or ACH payment", value: "credit_debit" },
    { name: "Wire transfer", value: "wire_transfer" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [revisionLogData, setRevisionLogData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(paymentOptions[0]);
  const [amount, setAmount] = useState(orderDetail?.remaining_balance);
  // const [cashAmount, setCashAmount] = useState(orderDetail?.remaining_balance);
  // const [checkAmount, setCheckAmount] = useState(orderDetail?.remaining_balance);
  // const [creditDebitAmount, setCreditDebitAmount] = useState(orderDetail?.remaining_balance);
  // const [achAmount, setAchAmount] = useState(orderDetail?.remaining_balance);
  // const [wireTransferAmount, setWireTransferAmount] = useState(orderDetail?.remaining_balance);
  const [checkNumber, setCheckNumber] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handlePayment = async () => {
    let response = {};
    let body = {};
    let rest = {};

    setIsLoading(true);

    body = {
      payment_method: "credit_debit",
      amount,
    };

    rest = {
      uuid: orderDetail?.uuid,
    };

    response = await httpRequest("payments-link", body, rest);

    if (getResponseMsg(response) === "Success") {
      // console.log(response?.data?.data?.payment_link);
      window.open(response?.data?.data?.payment_link, "_blank", "noreferrer");
      onClose("Success");
    } else {
      onClose("failed-payment");
    }

    setIsLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handlePayment();
    }
  };

  return (
    <MDBox>
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Payment
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox p={1}>
        <Grid item xs={12} lg={12}>
          {isLoading ? (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDBox mt={0.75}>
                <CircularProgress />
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  Processing...
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : (
            <Grid item xs={12} lg={12}>
              <MDBox mb={1} display="flex">
                <MDTypography component="div" variant="caption" color="text" fontWeight="regular">
                  Remaining amount to pay is &nbsp;
                </MDTypography>
                <MDTypography component="div" variant="caption" color="info" fontWeight="medium">
                  {convertToCurrency(orderDetail?.remaining_balance || 0)}
                </MDTypography>
              </MDBox>
              <MDBox>
                {/* <Autocomplete
                  options={paymentOptions}
                  value={paymentMethod}
                  isOptionEqualToValue={(option, value) =>
                    value !== "" ? option.value === value.value : option.value
                  }
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" label="Select payment method" />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setPaymentMethod(newValue);
                    }
                  }}
                /> */}
              </MDBox>

              <MDBox mt={2}>
                <MDInput
                  variant="standard"
                  label="Payment Amount (USD)"
                  value={amount}
                  fullWidth
                  onChange={(e) => {
                    const val = preventNegativeInput(e.target.value);
                    setAmount(val);
                    if (val <= 0) {
                      setErrorMsg("Amount should be more than 0.");
                    } else if (Number(val || 0) < 0.5) {
                      setErrorMsg("Amount should be more than $0.5");
                    } else {
                      setErrorMsg("");
                    }
                  }}
                  onKeyPress={handleKeyPress}
                  // InputLabelProps={{ shrink: true }}
                />
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {errorMsg}
                  </MDTypography>
                </MDBox>
              </MDBox>

              <MDBox mt={2} display="flex" justifyContent="flex-end">
                <MDBox mx={2}>
                  <MDButton
                    // variant="outlined"
                    color="success"
                    size="small"
                    onClick={() => {
                      handlePayment();
                    }}
                    disabled={
                      // paymentMethod?.value === paymentOptions[0]?.value &&
                      amount <= 0 || Number(amount || 0) < 0.5
                      // ||
                      // (paymentMethod?.value === paymentOptions[0]?.value &&
                      //   Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0) ||
                      // (paymentMethod?.value === paymentOptions[1]?.value &&
                      //   (amount <= 0 ||
                      //     checkNumber.length <= 0 ||
                      //     Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0)) ||
                      // (paymentMethod?.value === paymentOptions[2]?.value &&
                      //   Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0) ||
                      // (paymentMethod?.value === paymentOptions[3]?.value &&
                      //   Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0) ||
                      // (paymentMethod?.value === paymentOptions[4]?.value &&
                      //   Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0)
                      // ||
                      // paymentMethod?.value === paymentOptions[2]?.value ||
                      // paymentMethod?.value === paymentOptions[3]?.value ||
                      // paymentMethod?.value === paymentOptions[4]?.value
                    }
                  >
                    Proceed Payment
                  </MDButton>
                </MDBox>
                <MDBox>
                  <MDButton
                    // variant="outlined"
                    color="error"
                    size="small"
                    onClick={onClose}
                  >
                    cancel
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

OrderPayment.defaultProps = {
  userDetail: {},
};

OrderPayment.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(OrderPayment);
