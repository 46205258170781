import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import FormField from "layouts/admin/Categories/new-category/components/FormField";

function Info({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { categoryName } = formField;
  const { name: categoryNameV } = values;

  return (
    <MDBox>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <FormField
            autoFocus
            type={categoryName.type}
            label={categoryName.label}
            name={categoryName.name}
            value={categoryNameV}
            placeholder={categoryName.placeholder}
            error={errors.categoryName && touched.categoryName}
            success={categoryNameV.length > 0 && !errors.categoryName}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

// typechecking props for UserInfo
Info.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Info;
