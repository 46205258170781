/* eslint-disable no-unused-vars */
import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import cinevoLoading from "assets/images/loading-cinevo-no-bg.gif";
import cinevoLoading from "assets/images/rocket/fading-rocket.svg";
import MDAvatar from "components/MDAvatar";

export default function SimpleBackdrop() {
  return (
    <div>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
        {/* <CircularProgress color="inherit" />
        test */}
        <MDAvatar
          src={cinevoLoading}
          alt="cinevoLoading"
          size="xxl"
          variant="rounded"
          color="inherit"
          sx={{
            height: "10rem",
            p: 1,
            mt: -6,
            borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
          }}
        />
      </Backdrop>
    </div>
  );
}
