/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";

const { getStorageItem } = WarehouseMgr;

function BasicInfo({ data }) {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const { identifier, status, description } = data;

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isMobile ? (
        <MDBox p={2} display="flex" justifyContent="space-between">
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                {identifier}
              </MDTypography>
            </MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end">
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color={status?.value === "canceled" ? "error" : "success"}
            >
              {status?.name}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox p={2} display="flex" justifyContent="space-between">
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {identifier}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Description: &nbsp;{description}
            </MDTypography>
          </MDBox>

          <MDBox p={2} display="flex" justifyContent="flex-end">
            <MDBox display="flex">
              <MDTypography variant="h5">Order Status:&nbsp;</MDTypography>

              <MDTypography
                variant="h5"
                fontWeight="medium"
                color={status?.value === "canceled" ? "error" : "success"}
              >
                &nbsp;{status?.name}
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

BasicInfo.defaultProps = {
  data: {},
};

BasicInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(BasicInfo);
