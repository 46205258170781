/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/admin/ManageUsers/new-user/components/FormField";
import UsersMgr from "layouts/admin/ManageUsers/UsersMgr";
import { Autocomplete, Switch } from "@mui/material";
import MDInput from "components/MDInput";

const { limitInputLength, getStorageItem, httpRequest, getResponseMsg } = UsersMgr;

function Info({ formData, locationError, warehouseError }) {
    const locationList = JSON.parse(getStorageItem("locations_list"));
    const userDetail = JSON.parse(getStorageItem("current_user_detail"));
    const { formField, values } = formData;
    const { location, warehouse, firstName, lastName, email } = formField;
    const {
        first_name: firstNameV,
        last_name: lastNameV,
        email: emailV,
        location: locationV,
        warehouse: warehouseV,
    } = values;

    const [fnameValue, setFnameValue] = useState(firstNameV);
    const [lnameValue, setLnameValue] = useState(lastNameV);
    const [emailValue, setEmailValue] = useState(emailV);
    const [locationValue, setLocationValue] = useState(locationV);
    const [warehouseValue, setWarehouseValue] = useState(warehouseV);
    const [warehouseList, setWarehouseList] = useState(null);

    const handelChangeLocation = async (val) => {
        setLocationValue(val);
        values.location = val;

        const response = await httpRequest("warehouse-list", null, { uuid: val?.id });

        if (getResponseMsg(response) === "Success") {
            if (response?.data?.data?.count > 0) {
                setWarehouseValue(response?.data?.data?.results[0] || null);
                values.warehouse = response?.data?.data?.results[0] || null;
            } else {
                setWarehouseValue(null);
                values.warehouse = null;
            }

            setWarehouseList(response?.data?.data?.results);
        }
    };

    useEffect(() => {
        if (userDetail?.pageState === "NEW") {
            setFnameValue("");
            values.first_name = "";

            setLnameValue("");
            values.last_name = "";

            setEmailValue("");
            values.email = "";

            setLocationValue(null);
            values.location = null;

            setWarehouseValue(null);
            values.warehouse = null;

            setWarehouseList([]);
        }
    }, []);

    return (
        <MDBox px={1}>
            <MDBox lineHeight={0}>
                <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    <MDBox>
                        <MDTypography variant="h6">Personal details </MDTypography>
                    </MDBox>
                    <MDTypography variant="caption" fontWeight="regular" color="warning">
                        * = Required Fields
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <FormField
                            type={firstName.type}
                            label={firstName.label}
                            name={firstName.name}
                            value={fnameValue}
                            placeholder={firstName.placeholder}
                            // error={errors.firstName && touched.firstName}
                            // success={firstNameV.length > 0 && !errors.firstName}
                            onChange={(e) => {
                                setFnameValue(limitInputLength(e.target.value, 20));
                                values.first_name = limitInputLength(e.target.value, 20);
                            }}
                            // onBlur={(e) => handleOnBlur(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormField
                            type={lastName.type}
                            label={lastName.label}
                            name={lastName.name}
                            value={lnameValue}
                            placeholder={lastName.placeholder}
                            // error={errors.lastName && touched.lastName}
                            // success={lastNameV.length > 0 && !errors.lastName}
                            onChange={(e) => {
                                setLnameValue(limitInputLength(e.target.value, 20));
                                values.last_name = limitInputLength(e.target.value, 20);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormField
                            type={email.type}
                            label={email.label}
                            name={email.name}
                            value={emailValue}
                            placeholder={email.placeholder}
                            onChange={(e) => {
                                setEmailValue(e.target.value);
                                values.email = e.target.value;
                            }}
                            // error={errors.email && touched.email}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            options={locationList || []}
                            value={locationValue}
                            name={location.name}
                            isOptionEqualToValue={(option, value) =>
                                value !== "" ? option.name === value.name : option.name
                            }
                            getOptionLabel={(option) => (option ? option.name : "")}
                            renderInput={(params) => (
                                <MDInput {...params} variant="standard" label={location.label} />
                            )}
                            onChange={(e, newValue) => {
                                if (newValue !== null) {
                                    handelChangeLocation(newValue);
                                }
                            }}
                        />
                        {locationError && locationValue === null && (
                            <MDBox mt={0.75}>
                                <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                >
                                    {location.errorMsg}
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            options={warehouseList || []}
                            value={warehouseValue}
                            name={warehouse.name}
                            isOptionEqualToValue={(option, value) =>
                                value !== "" ? option.name === value.name : option.name
                            }
                            getOptionLabel={(option) => (option ? option.name : "")}
                            renderInput={(params) => (
                                <MDInput {...params} variant="standard" label={warehouse.label} />
                            )}
                            onChange={(e, newValue) => {
                                if (newValue !== null) {
                                    setWarehouseValue(newValue);
                                    values.warehouse = newValue;
                                }
                            }}
                            disabled={locationValue === null}
                        />
                        {warehouseError && warehouseValue === null && (
                            <MDBox mt={0.75}>
                                <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                >
                                    {warehouse.errorMsg}
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

// typechecking props for UserInfo
Info.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    locationError: PropTypes.bool.isRequired,
    warehouseError: PropTypes.bool.isRequired,
};

export default Info;
