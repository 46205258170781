/* eslint-disable no-await-in-loop */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";
import moment from "moment";

import { useMaterialUIController } from "context";
import { CircularProgress, Popover } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import Calendar from "examples/Calendar";
import calendarEventsData from "layouts/pages/widgets/data/calendarEventsData";

import InventoryMgr from "../InventoryMgr";

const {
  addDate,
  getDateToday,
  getDayOfWeek,
  httpRequest,
  getStorageItem,
  getResponseMsg,
  addDateFormatted,
} = InventoryMgr;

function Availability({ calendarData }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  // const [calendarData, setCalendarDat] = useState(calendarEventsData);
  const [popupData, setPopUpData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const theme = {
    color: darkMode ? "white" : "dark",
  };

  const dateToday = getDateToday();

  const processData = (data = []) => {
    const tmpList = [];

    data?.map((item) => {
      tmpList.push({
        title: `${item.local} available`,
        start: item?.date,
        end: item?.date,
        global: item?.global,
        local: item?.local,
        is_available: item?.is_available,
        className: item?.is_available === true ? "success" : "error",
      });
      return 0;
    });

    return tmpList;
  };

  // useEffect(async () => {
  //   // console.log(dateToday);
  //   setLoading(true);

  //   let responseAvailability = {};
  //   let tmpCalendarData = [];
  //   for (let index = 0; index < 4; index += 1) {
  //     if (index === 0) {
  //       responseAvailability = await httpRequest("inventory-availability", null, {
  //         uuid: itemDetail?.uuid,
  //         date: dateToday,
  //         // date: "2022-11-1",
  //       });
  //     } else {
  //       responseAvailability = await httpRequest("inventory-availability", null, {
  //         uuid: itemDetail?.uuid,
  //         date: addDateFormatted(
  //           moment().startOf("month").format("YYYY-MM-DD"),
  //           index,
  //           "month",
  //           "YYYY-MM-DD"
  //         ),
  //         // date: "2022-11-1",
  //       });
  //     }
  //     tmpCalendarData = tmpCalendarData.concat(processData(responseAvailability?.data?.data));
  //     // if (getResponseMsg(responseAvailability) === "Success") {
  //     //   setCalendarDat([...calendarData, { ...processData(responseAvailability?.data?.data) }]);
  //     // }
  //     // console.log(tmpCalendarData);
  //   }

  //   setCalendarDat(tmpCalendarData);

  //   // console.log(tmpCalendarData);
  //   // responseAvailability = await httpRequest("inventory-availability", null, {
  //   //   uuid: itemDetail?.uuid,
  //   //   date: dateToday,
  //   //   // date: "2022-11-1",
  //   // });
  //   // debugger;

  //   setLoading(false);
  // }, []);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, index) => {
    // console.log(event, index);
    // console.log({
    //   global: event?.event?._def?.extendedProps?.global,
    //   local: event?.event?._def?.extendedProps?.local,
    //   is_available: event?.event?._def?.extendedProps?.is_available,
    //   date: event?.event?.startStr,
    // });
    setPopUpData({
      global: event?.event?._def?.extendedProps?.global,
      local: event?.event?._def?.extendedProps?.local,
      is_available: event?.event?._def?.extendedProps?.is_available,
      date: event?.event?.startStr,
    });
    // setAnchorEl(event.currentTarget);
    setAnchorEl(event.jsEvent.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // const open = true;
  // const id = open ? "simple-popover" : undefined;

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" pt={3} pl={3}>
        <MDTypography variant="h6">
          Availability as of {getDateToday("MMM DD, YYYY")}
          &nbsp;({getDayOfWeek(dateToday)})
        </MDTypography>
      </MDBox>
      <MDBox>
        <MDBox>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <MDBox bgColor="white">
              <MDTypography sx={{ p: 1 }} variant="h6">
                {popupData?.date}
              </MDTypography>
              <MDBox display="flex">
                <MDTypography sx={{ p: 1 }} variant="h6">
                  Local:
                </MDTypography>
                <MDTypography sx={{ p: 1 }} variant="button">
                  {popupData.local}
                </MDTypography>
              </MDBox>

              <MDBox display="flex">
                <MDTypography sx={{ p: 1 }} variant="h6">
                  Global:
                </MDTypography>
                <MDTypography sx={{ p: 1 }} variant="button">
                  {popupData.global}
                </MDTypography>
              </MDBox>
              {/* <MDTypography sx={{ p: 2 }}>The content of the Popover.</MDTypography> */}
            </MDBox>
          </Popover>
        </MDBox>
        {/* {isLoading && <SimpleBackdrop />} */}
        {/* <MDBox ml={30} direction="row" justifyContent="center" alignItems="center">
          {isLoading && <CircularProgress color="success" />}
        </MDBox> */}

        {useMemo(
          () => (
            <MDBox height="32rem">
              {/* {setCalendarDat([])} */}
              {/* <MDBox sx={{ margin: "auto", width: "50%" }}>
                {isLoading && <CircularProgress color="success" />}
              </MDBox> */}
              {/* {!isLoading && ( */}
              <Calendar
                // sx={{ border: "3px solid green" }}
                initialView="dayGridMonth"
                // plugins={["dayGridPlugin", "timeGridPlugin"]}
                initialDate={dateToday || "2021-08-10"}
                events={calendarData}
                selectable
                editable
                // droppable={false}
                validRange={{
                  start: moment().startOf("month").format("YYYY-MM-DD"),
                  end: addDate(14, "weeks"),
                }}
                eventClick={handleClick}
                showNonCurrentDates={false}
                headerToolbar={{
                  right: "today prev,next",
                  // center: "title",
                  // right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
              />
              {/* )} */}
            </MDBox>
          ),
          [calendarData, isLoading]
        )}
      </MDBox>
    </MDBox>
  );
}

Availability.defaultProps = {
  calendarData: [],
};

Availability.propTypes = {
  calendarData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

export default Availability;
