import checkout from "layouts/admin/Categories/new-category/schemas/form";

const {
  formField: { categoryName },
} = checkout;

const initialValues = {
  [categoryName.name]: "",
};

export default initialValues;
