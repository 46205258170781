import checkout from "layouts/Vendors/new-user/schemas/form";

const {
  formField: {
    firstName,
    lastName,
    companyName,
    email,
    vendorType,
    percentage,
    isIndividual,

    line1,
    line2,
    city,
    state,
    country,
    zip,

    mobile,
    telephone,
    fax,
    other,
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [companyName.name]: "",
  [vendorType.name]: null,
  [percentage.name]: 0.6,
  [isIndividual.name]: false,

  [line1.name]: "",
  [line2.name]: "",
  [city.name]: "",
  [state.name]: "",
  [country.name]: { name: "United States of America", value: "US" },
  [zip.name]: "",

  [mobile.name]: "",
  [telephone.name]: "",
  [fax.name]: "",
  [other.name]: "",
};

export default initialValues;
