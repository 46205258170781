/* eslint-disable no-unused-vars */
import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import helper from "services/helpers";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";

const { postRequest, putRequest, getRequest, patchRequest, deleteRequest, uploadPutRequest } =
  HTTPSServices;
const { CUSTOMERS, _DASHBOARD } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  getDocumentTypeName,
  getResponseMsg,
  hasPermission,
  getDateToday,
  preventNegativeInput,
  limitInputLength,
  convertToCurrency,
  formatDate,
  convertTimeZoneFormatDateTime,
} = utils;
const { validateEmail } = helper;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  const API = `${CUSTOMERS.API}${rest?.uuid}`;

  switch (action) {
    case "customer-list":
      response = await getRequest(
        `${CUSTOMERS.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }`
      );

      break;

    case "customer-create":
      response = await postRequest(CUSTOMERS.API, body);

      break;

    case "customer-update":
      response = await putRequest(`${API}/`, body);

      break;

    case "customer-detail":
      response = await getRequest(`${API}/`);

      break;

    case "customer-unpaid-contracts":
      response = await getRequest(`${API}/contracts/?page_size=${rest?.entriesPerPage || 10}`);

      break;

    case "customer-deactivate":
      response = await patchRequest(`${API}/`);

      break;

    case "customer-delete":
      response = await deleteRequest(`${API}/`);

      break;

    case "customer-update-document":
      response = await uploadPutRequest(`${API}/documents/${rest?.id}/`, body);

      break;

    case "customer-waive-document":
      response = await patchRequest(
        `${CUSTOMERS.API}${rest?.uuid}/documents/${rest?.data?.uuid}/`,
        {
          action: "waive",
          value: rest?.waive,
        }
      );

      break;

    case "customer-update-status":
      response = await patchRequest(`${API}/documents/${rest?.data?.uuid}/`, {
        action: "status",
        value: rest?.value,
      });

      break;

    case "customer-transaction-list":
      response = await getRequest(`${API}/transactions/?page_size=${rest?.entriesPerPage || ""}`);

      break;

    case "download-customer-unpaid-contracts":
      response = await getRequest(`${API}/downloads/?contract=${rest?.contractName}`);

      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (
  sortType = "asc",
  id = "first_name",
  searchKeyword = "",
  entriesPerPage = 10
) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${CUSTOMERS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${
        id === "full_name" ? "first_name" : id
      }`
    );
  } else {
    response = await getRequest(
      `${CUSTOMERS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${
        id === "full_name" ? "first_name" : id
      }`
    );
  }

  return response;
};

const getAttachedDocuments = async (uuid = "") => {
  const response = await getRequest(`${CUSTOMERS.API + uuid}/documents/`);

  if (response.status === RESPONSE_SUCCESS || response.status === 201) {
    // console.log(response.data.data.insurance);
    const records = [];
    Object.entries(response?.data?.data).map((item) => {
      records.push({
        document_name: item[0],
        id: item[1]?.id,
        uuid: item[1]?.uuid,
        file_name: item[1]?.file_name,
        file_url: item[1]?.file_url,
        document_type: item[1]?.document_type,
        limit: item[1]?.limit,
        expiration_date: item[1]?.expiration_date,
        status: item[1]?.status,
        is_waived: item[1]?.is_waived,
      });
      return records;
    });

    // console.log(records);
    return records;
  }

  return response;
};

const CustomerMgr = {
  getNextPage,
  getPrevPage,
  getSort,
  getAttachedDocuments,
  CUSTOMERS,
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  getDocumentTypeName,
  httpRequest,
  RESPONSE_SUCCESS,
  validateEmail,
  getResponseMsg,
  hasPermission,
  getDateToday,
  _DASHBOARD,
  preventNegativeInput,
  limitInputLength,
  convertToCurrency,
  formatDate,
  convertTimeZoneFormatDateTime,
};

export default CustomerMgr;
