const form = {
  formId: "new-vendor-form",
  formField: {
    firstName: {
      name: "first_name",
      label: "First Name *",
      type: "text",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "last_name",
      label: "Last Name *",
      type: "text",
      errorMsg: "Last name is required.",
    },
    companyName: {
      name: "company_name",
      label: "Company *",
      type: "text",
      errorMsg: "Company Name is required, check Individual if not applicable",
    },
    isIndividual: {
      name: "individual",
      label: "N/A",
    },
    email: {
      name: "email",
      label: "Email Address *",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    vendorType: {
      name: "vendor_type",
      label: "Vendor Type *",
      type: "text",
    },
    percentage: {
      name: "percentage",
      label: "Consigner Fee (%)",
      type: "number",
      errorMsg: "Consignor Fee is required.",
      invalidMsg: "Consignor Fee is invalid",
    },
    line1: {
      name: "line_1",
      label: "Line 1 *",
      type: "text",
      errorMsg: "Address is required.",
    },
    line2: {
      name: "line_2",
      label: "Line 2",
      type: "text",
    },
    city: {
      name: "city",
      label: "City *",
      type: "text",
      errorMsg: "City is required.",
    },
    state: {
      name: "state",
      label: "State *",
      type: "text",
      errorMsg: "State is required.",
    },
    country: {
      name: "country",
      label: "Country *",
      type: "text",
      errorMsg: "Country is required.",
    },
    zip: {
      name: "zip_code",
      label: "Zip *",
      type: "text",
      errorMsg: "Zip is required.",
      invalidMsg: "Your Zip number is invalid",
    },

    mobile: {
      name: "mobile",
      label: "Telephone 1 *",
      type: "tel",
      errorMsg: "Telephone number is required.",
      invalidMsg: "Your Telephone number is invalid",
    },
    telephone: {
      name: "telephone",
      label: "Telephone 2 ",
      type: "tel",
      errorMsg: "Telephone number is required.",
      invalidMsg: "Your Telephone number is invalid",
    },
    fax: {
      name: "fax",
      label: "Fax",
      type: "text",
      errorMsg: "Fax number is required.",
      invalidMsg: "Your Fax number is invalid",
    },
    other: {
      name: "other",
      label: "Other",
      type: "text",
      errorMsg: "Other number is required.",
      invalidMsg: "Your Other number is invalid",
    },
  },
};

export default form;
