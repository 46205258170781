/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import RentItemTable from "./RentItemTable";

const { hasPermission, getStorageItem } = InventoryMgr;
const { httpRequest, getResponseMsg, WAREHOUSE_CHECKOUT } = WarehouseMgr;

function RentItems({ data, loadDetails }) {
  const navigate = useNavigate();
  const checkoutDetails = JSON.parse(getStorageItem("current_checkout_detail"));
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const { items } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false);
  const [subitemDetail, setSubitemDetail] = useState({});
  const [showItemList, setShowItemList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Failed");
  // const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item Checkout");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [confirmationDialogue, setConfirmationDialogue] = useState(false);
  const [removeItemDetails, setRemoveItemDetails] = useState({});

  const handleOpenDialog = () => setShowConfirmationDialogue(true);
  const handleCloseDialog = () => setShowConfirmationDialogue(false);
  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openSuccessSB = () => setSuccessSB(true);

  const handleCloseConfirmationDialogue = () => {
    setConfirmationDialogue(false);
    navigate(WAREHOUSE_CHECKOUT?.LIST_ROUTE);
  };

  const handleOpenItemlistModal = (value) => {
    setSubitemDetail(value);
    setShowItemList(true);
  };

  const setItemData = (itemData = {}) => {
    setRemoveItemDetails(itemData);
    handleOpenDialog();
  };

  const handleRemoveItem = async () => {
    // console.log(
    //   removeItemDetails?.barcode_id,
    //   body,
    //   checkoutDetails?.uuid,
    //   `${
    //     removeItemDetails?.item?.name ||
    //     removeItemDetails?.barcode_id ||
    //     removeItemDetails?.serial_number ||
    //     removeItemDetails?.name
    //   } has been removed.`
    // );

    // START HERE
    setIsLoading(true);
    handleCloseDialog();
    document.body.style.cursor = "wait";
    let responseDetails = {};

    if (removeItemDetails?.barcode_id !== undefined) {
      responseDetails = await httpRequest(
        "remove-subitem",
        {
          subitem: removeItemDetails?.uuid,
        },
        {
          uuid: checkoutDetails?.uuid,
        }
      );
    } else {
      responseDetails = await httpRequest(
        "remove-item",
        {
          order_item: removeItemDetails?.uuid,
        },
        {
          uuid: checkoutDetails?.uuid,
        }
      );
    }

    if (getResponseMsg(responseDetails) === "Success") {
      setSuccessMsg(
        `${
          removeItemDetails?.item?.name ||
          removeItemDetails?.barcode_id ||
          removeItemDetails?.serial_number ||
          removeItemDetails?.name
        } has been removed.`
      );
      setIsLoading(false);
      openSuccessSB();
      loadDetails();
    } else {
      setErrMsg("Failed to remove item.");
      openErrorSB();
    }
    document.body.style.cursor = "default";
    setIsLoading(false);
  };

  const dataTableDataWeb = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox
              direction="column"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox>
                <MDTypography variant="button" color="success" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
              </MDBox>
              <MDBox ml={2}>
                <MDButton
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => {
                    setItemData(row.original);
                  }}
                >
                  remove
                </MDButton>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: items || [],
  };

  const dataTableDataMobile = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox display="flex " justifyContent="space-between" alignItems="center">
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox>
                <MDTypography variant="overline" color="success" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
              </MDBox>
              <MDBox mx={1}>
                <IconButton
                  size="small"
                  aria-label="edit"
                  color="error"
                  onClick={() => {
                    setItemData(row.original);
                  }}
                >
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: items || [],
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox sx={{ width: "100%" }}>
        <MDBox ml={2}>
          <MDTypography variant="h6" color="info">
            Items to be rented
          </MDTypography>
        </MDBox>
        <Grid container spacing={0}>
          <RentItemTable
            // height="30rem"
            table={isMobile ? dataTableDataMobile : dataTableDataWeb}
            entriesPerPage={false}
            // showTotalEntries={false}
            isSorted={false}
            canSearch={false}
            hasCollapse
            loadDetails={loadDetails}
            handleOpenItemlistModal={handleOpenItemlistModal}
            setItemData={setItemData}
          />
        </Grid>
      </MDBox>

      {confirmationDialogue && (
        <Dialog
          open={confirmationDialogue}
          onClose={handleCloseConfirmationDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Order is now ready to invoice</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All items has been Checked-in. Order is now ready to invoice.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseConfirmationDialogue} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
      {showConfirmationDialogue && (
        <Dialog open={showConfirmationDialogue} onClose={handleCloseDialog}>
          <DialogTitle>Order Item Removal</DialogTitle>
          <DialogContent>
            <DialogContentText>{`Are you sure you want to remove ${
              removeItemDetails?.item?.name ||
              removeItemDetails?.barcode_id ||
              removeItemDetails?.serial_number ||
              removeItemDetails?.name
            }?`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={handleRemoveItem}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

RentItems.defaultProps = {
  data: {},
};

RentItems.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
};

export default RentItems;
