/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Autocomplete, Grid, Icon, IconButton, Modal } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DataTable from "components/Tables/DataTable";
import DataTable from "examples/Tables/DataTable";
import CustomModal from "components/CustomModal";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

import { useMaterialUIController } from "context";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VendorMgr from "layouts/Vendors/VendorMgr";
import InventoryMgr from "../InventoryMgr";
import RentalHitory from "./components/RentalHitory";
import ServiceHistory from "./components/ServiceHistory";

const { setStorageItem, MANAGE_VENDORS } = VendorMgr;
const { INVENTORY, convertToCurrency, getStorageItem, httpRequest, getResponseMsg } = InventoryMgr;

function SubItems({ data, loadDetails }) {
  const { subitems, item_type, collections } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const navigate = useNavigate();
  const location = useLocation();

  const warehouseList = useSelector((state) => state?.helper?.warehouse);

  const [showRentalHistory, setShowRentalHistory] = useState(false);
  const [showServiceHistory, setShowServiceHistory] = useState(false);
  const [subitemData, setSubitemData] = useState({});
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [warehouseValue, setWarehouseValue] = useState(null);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Change warehouse");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const closeErrorSB = () => setErrorSB(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const openSuccessSB = () => setSuccessSB(true);
  const openRentalHistory = () => setShowRentalHistory(true);
  const closeRentalHistory = () => setShowRentalHistory(false);
  const openServiceHistory = () => setShowServiceHistory(true);
  const closeServiceHistory = () => setShowServiceHistory(false);

  const showConsignerDetail = (values) => {
    // console.log(location);
    setStorageItem("current_vendor_detail", values);
    navigate(MANAGE_VENDORS.DETAIL_ROUTE, { state: { from: location }, replace: true });
  };

  const subItemsColumns = [
    { Header: "Barcode", accessor: "barcode_id", headerAlign: "left", width: "20%" },
    { Header: "Serial Number", accessor: "serial_number", headerAlign: "left", width: "20%" },
    { Header: "Name", accessor: "name", headerAlign: "left", width: "20%" },
    {
      Header: "Owned",
      accessor: "is_owned",
      headerAlign: "left",
      width: "15%",
      Cell: ({ value }) => {
        const status = (
          <MDBox display="flex" justifyContent="space-between">
            <IconButton size="small" aria-label="edit" disabled>
              <Icon color={value <= 0 ? "error" : "success"} fontSize="medium">
                square
              </Icon>
            </IconButton>
            <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
              {value <= 0 ? "Consigned" : "Owned"}
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: "consigner",
      accessor: "consigner.full_name",
      width: "20%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDTypography
            variant="button"
            color="text"
            onClick={() => {
              // console.log(row.original.consigner.uuid);
              showConsignerDetail(row.original?.consigner);
            }}
          >
            <MDTypography
              component={Link}
              to="#"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              {row.original?.consigner?.full_name || ""}
            </MDTypography>
          </MDTypography>
        );

        return status;
      },
    },
    { Header: "status", accessor: "status.name", headerAlign: "left", width: "10%" },
    {
      Header: "notes",
      accessor: "notes",
      width: "20%",
      headerAlign: "left",
    },
    {
      Header: "warehouse",
      accessor: "warehouse",
      headerAlign: "left",
      width: "50%",
      Cell: ({ row, value }) => {
        const status = (
          <MDBox width={300}>
            <Autocomplete
              fullWidth
              disablePortal
              options={warehouseList || null}
              value={value}
              isOptionEqualToValue={(option, val) =>
                val !== "" ? option.name === val.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name || option.name : "")}
              renderInput={(params) => <MDInput {...params} variant="standard" width={300} />}
              onChange={async (event, newValue) => {
                // handleChangeAutoComplete(event, newValue);
                const body = {
                  warehouse: newValue?.id,
                };
                const rest = {
                  uuid: data?.uuid,
                  subitemUuid: row?.original?.uuid,
                };
                // console.log(newValue, body, rest);
                //  // debugger;
                if (newValue !== null) {
                  const responseDetails = await httpRequest("change-warehouse", body, rest);

                  if (getResponseMsg(responseDetails) === "Success") {
                    loadDetails();
                    setSuccessMsg(`Successfully changed warehouse to ${newValue?.name}`);
                    openSuccessSB();
                  } else {
                    setErrMsg(`Failed to change warehouse.`);
                    openErrorSB();
                  }
                  setWarehouseValue(newValue);
                }
              }}
            />
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: " ",
      accessor: "",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox display="flex" justifyContent="space-between">
            <MDBox mx={2}>
              <MDButton
                size="small"
                variant="outlined"
                color="info"
                onClick={() => {
                  setSubitemData(row?.original);
                  openServiceHistory();
                  // console.log(itemDetail?.uuid, row?.original?.uuid);
                }}
                // disabled
              >
                Service History
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton
                size="small"
                variant="outlined"
                color="info"
                onClick={() => {
                  // console.log(row?.original);
                  setSubitemData(row?.original);
                  openRentalHistory();
                }}
                sx={{ marginRight: 1 }}
                // disabled
              >
                Rental History
              </MDButton>
            </MDBox>
          </MDBox>
        );

        return status;
      },
    },
  ];

  const kitItemsColumns = [
    {
      Header: "name",
      accessor: "item.name",
      width: "20%",
      headerAlign: "left",
    },
    {
      Header: "Daily",
      accessor: "custom_prices.daily",
      width: "auto",
      headerAlign: "left",
      align: "right",
      Cell: ({ value }) => {
        const status = (
          <MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
              {convertToCurrency(value || 0)}
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: "Weekly",
      accessor: "custom_prices.weekly",
      width: "auto",
      headerAlign: "left",
      align: "right",
      Cell: ({ value }) => {
        const status = (
          <MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
              {convertToCurrency(value || 0)}
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: "Monthly",
      accessor: "custom_prices.monthly",
      width: "auto",
      headerAlign: "left",
      align: "right",
      Cell: ({ value }) => {
        const status = (
          <MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
              {convertToCurrency(value || 0)}
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    { Header: "Item type", accessor: "item.item_type.name", headerAlign: "left", width: "20%" },
    {
      Header: "Available",
      accessor: "available",
      width: "20%",
      headerAlign: "left",
      Cell: ({ row }) => {
        // console.log(row);
        const status = (
          <MDBox
            bgColor={row?.original?.available <= 0 ? "error" : "success"}
            display="flex"
            justifyContent="center"
            textAlign="center"
            width="4rem"
            sx={{ border: "2px ", borderRadius: "5px" }}
          >
            <MDTypography
              variant="button"
              fontWeight="regular"
              color="white"
              // onClick={() => console.log(row)}
            >
              {row?.original?.available}
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    { Header: "Quantity", accessor: "quantity", headerAlign: "left", width: "20%" },
    {
      Header: "Optional",
      accessor: "optional",
      width: "20%",
      headerAlign: "left",
      Cell: ({ value }) => {
        const status = (
          <MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
              {value === true ? "Optional" : "Not Optional"}
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: " ",
      accessor: "",
      headerAlign: "left",
      Cell: () => {
        const status = (
          <MDBox display="flex" justifyContent="space-between">
            <MDBox mx={2}>
              <MDButton
                size="small"
                variant="outlined"
                color="info"
                // onClick={() => console.log("show service history")}
                disabled
              >
                Service History
              </MDButton>
            </MDBox>
            {/* <MDBox>
              <MDButton
                size="small"
                variant="outlined"
                color="info"
                onClick={() => {
                  // console.log(row?.original);
                  setSubitemData(row?.original);
                  openRentalHistory();
                }}
                sx={{ marginRight: 1 }}
                // disabled
              >
                Rental History
              </MDButton>
            </MDBox> */}
          </MDBox>
        );

        return status;
      },
    },
  ];

  const dataTableData = {
    columns: item_type?.value !== "kit" ? subItemsColumns : kitItemsColumns,
    rows: item_type?.value !== "kit" ? subitems || [] : collections.collections || [],
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      <MDBox>
        <MDBox p={2} display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" color="info">
              {item_type?.value !== "kit" ? "Quantities" : "Kit Inclusions"}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" px={4}>
          <Grid container spacing={1}>
            <DataTable
              height="20rem"
              table={dataTableData}
              entriesPerPage={false}
              // showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              hiddenCols={["file_url"]}
              totals={{
                show: item_type?.value === "kit",
                hasBorderRight: true,
                hasBorderTop: true,
                align: "right",
                totalRows: [
                  "Total",
                  `${convertToCurrency(data?.dailyTotal || 0)}`,
                  `${convertToCurrency(data?.weeklyTotal || 0)}`,
                  `${convertToCurrency(data?.monthlyTotal || 0)}`,
                ],
              }}
            />
          </Grid>
        </MDBox>
        {showRentalHistory && (
          <CustomModal
            showModal={showRentalHistory}
            handleHideModal={closeRentalHistory}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: "auto",
              width: "auto",
              // border: "2px solid #000",
              boxShadow: 24,
              borderRadius: "5px",
              p: 4,
              overflow: "auto",
            }}
          >
            <RentalHitory onClose={closeRentalHistory} subitemData={subitemData} />

            {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
          </CustomModal>
        )}
        {showServiceHistory && (
          <CustomModal
            showModal={showServiceHistory}
            handleHideModal={closeServiceHistory}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: isMobile ? "80%" : "98%",
              width: isMobile ? "100%" : "70%",
              // border: "2px solid #000",
              boxShadow: 24,
              borderRadius: "5px",
              p: 4,
              overflow: "auto",
            }}
          >
            <ServiceHistory onClose={closeServiceHistory} subitemData={subitemData} />

            {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
          </CustomModal>
        )}
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

SubItems.defaultProps = {
  data: [{}],
};

SubItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
};

export default SubItems;
