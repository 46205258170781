/* eslint-disable no-unused-vars */
import CryptoJS from "react-native-crypto-js";
import numeral from "numeral";
import useSound from "use-sound";

import moment from "moment";
import { RESPONSE_SUCCESS } from "../context/response";
import roles from "../context/roles";

const k = "9#Jk$7Lp@3qY+rT5&Z*2";

const { MANAGER, AGENT, OPERATOR, CUSTOMER, SUBVENDOR, CONSIGNER, ADMIN } = roles;

/**
 * Will Stringify any json form data.
 * @param val JSON data to be converted to string
 * @return String form of the JSON
 */
const jsonToString = (val) => JSON.stringify(val);

/**
 * Gets the Role level of the user.
 * @param val Title of the role
 * @return Role level of the user
 */
const getRoles = (val) => {
  let role = 1;

  if (val === MANAGER.title) {
    role = MANAGER.level;
  } else if (val === AGENT.title) {
    role = AGENT.level;
  } else if (val === OPERATOR.title) {
    role = OPERATOR.level;
  }
  // else if (val === SUBVENDOR.title) {
  //   role = SUBVENDOR.level;
  // }
  // else if (val === CONSIGNER.title) {
  //   role = CONSIGNER.level;
  // }
  // else if (val === CUSTOMER.title) {
  //   role = CUSTOMER.level;
  // }
  else if (val === ADMIN.title) {
    role = ADMIN.level;
  }

  return role;
};

/**
 * Encrypts the data using CryptoJS AEES library
 * @param val  string/object to be encrypted
 * @return String of Encrypted value
 */
const encrypt = (val) => {
  let encrypted = "";
  switch (typeof val) {
    case "string":
      encrypted = CryptoJS.AES.encrypt(val, k).toString();
      break;

    case "object":
      encrypted = CryptoJS.AES.encrypt(jsonToString(val), k).toString();
      break;

    default:
      break;
  }

  return encrypted;
};

/**
 * Decrypt the data using CryptoJS AEES library
 * @param val string/object to be decrypted
 * @return String of decrypted value
 */
const decrypt = (val) => {
  const decrypted = CryptoJS.AES.decrypt(val, k);
  let text = "";
  switch (typeof val) {
    case "string":
      text = decrypted.toString(CryptoJS.enc.Utf8);
      break;

    case "object":
      text = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      break;

    default:
      break;
  }

  return text;
};

/**
 * Stores the accesstoken to localstorage
 * @param val generated access token
 * @return Encrypt access token and store in local storage
 */
const setAccessToken = (val) => localStorage.setItem("accessToken", encrypt(val));

/**
 * Gets the accesstoken from localstorage
 * @param
 * @return Decrypted access token from local storage, or else emptry string
 */
const getAccessToken = () => decrypt(localStorage.getItem("accessToken") || "");

/**
 * Stores the refresh tokentoken in local storage
 * @param val generated refresh token
 * @return Encrypt refresh token and store in local storage
 */
const setRefreshToken = (val) => localStorage.setItem("refreshToken", encrypt(val));

/**
 * Gets the refresh token from localstorage
 * @param
 * @return Decrypted refresh token from local storage, or else emptry string
 */
const getRefreshToken = () => decrypt(localStorage.getItem("refreshToken") || "");

/**
 * Encrypt password and store in local storage
 * @param val String of accepted password
 * @return Encrypt password and store in local storage
 */
const storePassword = (val) => localStorage.setItem("password", encrypt(val));

/**
 * Gets the password from localstorage
 * @param
 * @return Decrypted password from local storage, or else emptry string
 */
const getPassword = () =>
  localStorage.getItem("password") ? decrypt(localStorage.getItem("password") || "") : "";

/**
 * Checks if Password have at least 1 uppercase
 * @param passwordInputValue String, password inputted by the user to confirm the first password
 * @return boolean, true if it meets the criteria, false otherwise
 */
const validateUppercasePassword = (passwordInputValue = "") => {
  const uppercaseRegExp = /(?=.*?[A-Z])/;

  return uppercaseRegExp.test(passwordInputValue);
};

/**
 * Checks if Password have at least 1 lowercase
 * @param passwordInputValue String, password inputted by the user to confirm the first password
 * @return boolean, true if it meets the criteria, false otherwise
 */
const validateLowercasePassword = (passwordInputValue = "") => {
  const lowercaseRegExp = /(?=.*?[a-z])/;

  return lowercaseRegExp.test(passwordInputValue);
};

/**
 * Checks if Password have at least 1 digit
 * @param passwordInputValue String, password inputted by the user to confirm the first password
 * @return boolean, true if it meets the criteria, false otherwise
 */
const validateHasNumericPassword = (passwordInputValue = "") => {
  const digitsRegExp = /(?=.*?[0-9])/;

  return digitsRegExp.test(passwordInputValue);
};

/**
 * Checks if Password have at least 8 character
 * @param passwordInputValue String, password inputted by the user to confirm the first password
 * @return boolean, true if it meets the criteria, false otherwise
 */
const validateEightCharPassword = (passwordInputValue = "") => {
  const minLengthRegExp = /.{8,}/;

  return minLengthRegExp.test(passwordInputValue);
};

/**
 * Function that validates password upon input
 *
 *              1. Password should match
 *
 *              2. Password should not be empty
 *
 *              3. Password should have at least 1 uppercase
 *
 *              4. Password should have at least 1 lowercase
 *
 *              5. Password should have at least 1 digit
 *
 *              6. Password should have at least 8 characters
 *
 * @param passwordInputValue String, password inputted by the user
 * @param passwordInputValue2 String, password inputted by the user to confirm the first password
 * @return Error message if password inputted did not suffice the criterias
 */
const validatePassword = (passwordInputValue = "", passwordInputValue2 = "") => {
  let errMsg = "";

  // const specialCharRegExp = "/(?=.*?[#?!@$%^&*-])/";

  const passwordLength = passwordInputValue.length;
  // const specialCharPassword = specialCharRegExp.test(passwordInputValue);

  if (passwordInputValue !== passwordInputValue2) {
    errMsg = "Password does not match";
  } else if (passwordLength === 0) {
    errMsg = "Password is empty";
  } else if (!validateUppercasePassword(passwordInputValue)) {
    errMsg = "At least one Uppercase";
  } else if (!validateLowercasePassword(passwordInputValue)) {
    errMsg = "At least one Lowercase";
  } else if (!validateHasNumericPassword(passwordInputValue)) {
    errMsg = "At least one digit";
  }
  // else if (!specialCharPassword) {
  //   errMsg = "At least one Special Characters";
  // }
  else if (!validateEightCharPassword(passwordInputValue)) {
    errMsg = "At least minimum 8 characters";
  } else {
    errMsg = "";
  }

  return errMsg;
};

/**
 * Checks if email is empty
 * @param email String, password inputted by the user
 * @return errMsg, empty if no error found
 */
const validateEmail = (email = "") => {
  let errMsg = "";
  if (email === "") {
    errMsg = "Email should not be empty";
  }
  return errMsg;
};

/**
 * Checks response of requests is successful or not
 * @param response object, response of server for each request
 * @return message, returns success if the response is 200 or 201
 */
const getResponseMsg = (response) => {
  let message = "";
  switch (response.status) {
    case RESPONSE_SUCCESS:
    case 201:
      message = "Success";
      break;
    default:
      message = "Fail";
      break;
  }
  return message;
};

/**
 * Formats the zip code to US standards (eg, 12345-6)
 * @param value string, user input for zip code
 * @return value, formatted value for zip code (eg, 12345-6)
 */
const formatZip = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const zipNumber = value.replace(/[^\d|^\w]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = zipNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 6) return zipNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 10) {
    return `${zipNumber.slice(0, 5)}-${zipNumber.slice(5)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${zipNumber.slice(0, 5)}-${zipNumber.slice(5, 9)}`;
};

/**
 * Formats the Phone number to US standards (eg, (123)1234-123)
 * @param value numeric, user input for Phone number
 * @return value, formatted value for Phone number (eg, (123)1234-123))
 */
const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

/**
 * Capitalize the first letter of the word
 * @param val String, user input any string
 * @return val, Capitalized value
 */
const capitalizeFirstLetter = (val) => val.charAt(0).toUpperCase() + val.slice(1);

const getDocumentTypeName = (id) => {
  let name = "";
  switch (id) {
    case "insurance":
      name = "Insurance";
      break;
    case "rental_agreement":
      name = "Rental Agreement";
      break;
    case "reference":
      name = "Reference";
      break;
    case "resale_certificate":
      name = "Resale Certificate";
      break;
    default:
      break;
  }
  return name;
};

/**
 * Checking if the inputted numeric is greater than zero or negative 0
 * @param val numeric, user input for numbers
 * @return val, 0 if the value is below 0
 */
const preventNegativeInput = (val = 0) => (val >= 0 ? val : 0);

/**
 * Limits the input length of the field
 * @param val String/int, user input data
 * @param limit numeric, length of the string
 * @return val, limitted string length according to limit passed to function
 */
const limitInputLength = (val = "", limit = 20) => val.slice(0, limit);

/**
 * Stores data on local storage of the browser with corresponding name and value
 * @param storageName, sets the storage name
 * @param value any, sets the value for that storage name
 * @return
 */
const setStorageItem = (storageName = "", value = {}) => {
  switch (typeof value) {
    case "object":
      localStorage.setItem(storageName, JSON.stringify(value));
      break;
    default:
      localStorage.setItem(storageName, value);
      break;
  }
};

/**
 * Gets the stored data on local storage of the browser with corresponding name and value
 * @param storageName, sets the storage name
 * @return data from the local storage from the storage name
 */
const getStorageItem = (storageName = "") => localStorage.getItem(storageName);

/**
 * Add number of date to our current date
 * @param number, number of date to add
 *  @param type, sets the type of date to be added (day, week, month, year)
 * @return Date that has an added duration
 */
const addDate = (number = 0, type = "") =>
  new Date(
    moment(new Date(moment().add(number, type).calendar()).toISOString()).format("YYYY/MM/DD")
  ).toISOString();

/**
 * Add number of date to our current date
 * @param number, number of date to add
 *  @param type, sets the type of date to be added (day, week, month, year)
 * @return Date that has an added duration
 */
const addDateFormatted = (date, number = 0, type = "", format = "YYYY/MM/DD") =>
  moment(new Date(moment(date).add(number, type)).toISOString()).format(format);

/**
 * Subtract number of date to our current date
 * @param number, number of date to add
 *  @param type, sets the type of date to be added (day, week, month, year)
 * @return Date that has an added duration
 */
const subtractDateFormatted = (date, number = 0, type = "", format = "YYYY/MM/DD") =>
  moment(new Date(moment(date).subtract(number, type)).toISOString()).format(format);

/**
 * Gets date today with a specific format, YYYY-MM-DD for default
 * @param format, String, format of the desired date
 * @return date, Formatted date
 */
const getDateToday = (format = "YYYY-MM-DD") => moment(new Date().toISOString()).format(format);

/**
 * Gets Current time with a specific format, HH:MM for default
 * @param format, String, format of the desired date
 * @return date, Formatted date
 */
// eslint-disable-next-line no-unused-vars
const getCurrentTime = (format = "HH:mm") => moment(new Date().toISOString()).format(format);

/**
 * Formats date and time
 * @param format, String, format of the desired date
 * @return String, formatted time and date
 */
const formatDate = (dateTime = "", format = "HH:mm") => moment(dateTime).format(format);

/**
 * Gets date today with a specific format, YYYY-MM-DD for default
 * @param date, String, date to be referred to
 * @return string, day of the week (Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday)
 */
const getDayOfWeek = (date = "Sep 13, 2022") => {
  const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  return weekday[new Date(date).getDay()];
};

/**
 * Gets Error message from request made on APIS
 * @param response, String, whole response from the server
 * @return string, Error message from the response, empty string if none
 */
const getErrorMessage = (response) => {
  let responseErrMsg = "";

  Object.entries(response.data).map((item) => {
    responseErrMsg = `${capitalizeFirstLetter(item[0])}: ${item[1]}`;
    return 0;
  });

  return responseErrMsg;
};

/**
 * Converts percentage value to number with two decimal places (eg 0 - 1 === 0% - 100%)
 * @param val, number, converted decimal percentage
 * @return number, percentage in whole value
 */
const convertToDecimal = (val = 0) => Number(Number(val) * 100).toFixed(2);

/**
 * Converts number to percentage value with two decimal places (eg 0% - 100% === 0.00 - 1.00  )
 * @param val, number, converted percentage value
 * @return number, percentage in decimal value
 */
const convertToPecentDecimal = (val = 0) => Number(Number(val) / 100 || 0).toFixed(2);

/**
 * Time Zone Converter
 * @param date, date to be converted
 * @param timeZone, timezone that the date to be converted
 * @return dateTime format, Converted timezone of the date time
 */
const changeTimeZone = (date = "", timeZone = "") => {
  if (typeof date === "string") {
    return new Date(
      new Date(date).toLocaleString("en-US", {
        timeZone,
      })
    );
  }

  return new Date(
    date.toLocaleString("en-US", {
      timeZone,
    })
  );
};

/**
 * Timezone converter with Date Time Formatter
 * @param date, date to be converted
 * @param timeZone, timezone that the date to be converted
 * @param abbreviation, Timezone name e.g, UTC, MTC, PSD
 * @param format, format of date and time
 * @return dateTime format, Converted timezone of the date time
 */
const convertTimeZoneFormatDateTime = (
  date = "",
  timeZone = "",
  abbreviation = "UTC",
  format = "lll"
) => `${moment(changeTimeZone(date, timeZone)).format(format)} (${abbreviation})`;

/**
 * Formats the string to ellipsis state
 * @param value, String to be converted to ellipsis
 * @return string format, Converted string to ellipsis
 */
const ellipsis = (value = "") =>
  (value.length || 10) > 30
    ? `${value.substring(0, 15)}.....${value.substring((value.length || 10) - 15, value.length)}`
    : value;

/**
 * Checks single permission
 * @param checkPermission, Permission to check if permitted to do the action
 * @param permissionLookUp, Permissions of user
 * @return boolean, true if permitted, false other wise
 */
const hasPermission = (checkPermission = "", permissionLookUp = []) => {
  let isPermitted = false;
  permissionLookUp.map((perm) => {
    if (perm?.value === checkPermission && perm?.has_permission === true) {
      isPermitted = true;
    }
    return false;
  });

  return isPermitted;
};

/**
 * Checks single permission
 * @param checkRole, role to check if permitted to do the action
 * @param roleLookup, roles of user
 * @return boolean, true if permitted, false other wise
 */
const hasRolePermission = (checkRole = "", roleLookup = []) => {
  let isPermitted = false;
  roleLookup.map((perm) => {
    if (perm?.value === checkRole) {
      isPermitted = true;
    }
    return false;
  });

  return isPermitted;
};

const getPaymentOptions = () => [
  { name: "Cash", value: "cash" },
  { name: "Check", value: "check" },
  { name: "Credit/Debit", value: "credit_debit" },
  { name: "ACH payment", value: "ach" },
  // { name: "Credit/Debit or ACH payment", value: "credit_debit" },
  { name: "Wire transfer", value: "wire_transfer" },
];

const addCommas = (num) => num.toLocaleString();

const convertToCurrency = (num) => numeral(num).format("$0,0.00");

const checkNullEmpty = (val) => val === "" || val === null;

const utils = {
  jsonToString,
  getRoles,
  encrypt,
  decrypt,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  validatePassword,
  getResponseMsg,
  storePassword,
  getPassword,
  validateUppercasePassword,
  validateLowercasePassword,
  validateHasNumericPassword,
  validateEightCharPassword,
  validateEmail,
  formatZip,
  capitalizeFirstLetter,
  formatPhoneNumber,
  getDocumentTypeName,
  preventNegativeInput,
  setStorageItem,
  getStorageItem,
  addDate,
  getDateToday,
  getCurrentTime,
  getDayOfWeek,
  limitInputLength,
  getErrorMessage,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  changeTimeZone,
  convertTimeZoneFormatDateTime,
  addDateFormatted,
  subtractDateFormatted,
  ellipsis,
  hasPermission,
  hasRolePermission,
  getPaymentOptions,
  addCommas,
  convertToCurrency,
  checkNullEmpty,
};

export default utils;
