/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, IconButton, Switch } from "@mui/material";

function Info({ data }) {
  const {
    name,
    timezone,
    tax_rate,

    uuid,
    identifier,
    first_name,
    last_name,
    email,
    company_name,
    percentage,
    vendor_type,
    last_completed_contract,
    unpaid_balance,
    address,
    contact,
    status,
    username,
    role,
    permissions,
  } = data;

  const otherDetails = [
    // { label: "Identifier :", value: identifier },
    { label: "Tax Rate :", value: `${Number(tax_rate).toFixed(2)} %` },
    { label: "Email :", value: `${email}` },
    { label: "Timezone :", value: `${timezone?.name} (${timezone?.abbreviation})` },
  ];

  const addressDetails = [
    { label: "Line 1 :", value: address?.line_1 },
    { label: "Line 2 :", value: address?.line_2 },
    { label: "Country :", value: `${address?.country?.name} (${address?.country?.value})` },
    { label: "City :", value: address?.city },
    { label: "State :", value: address?.state },
    { label: "Zip :", value: address?.zip_code },
  ];

  // const permissionDetails = [
  //   { label: "Permissions 1 ", value: permissions },
  //   { label: "Permissions 2", value: permissions },
  //   { label: "Permissions 3", value: permissions },
  //   { label: "Permissions 4", value: permissions },
  //   { label: "Permissions 52", value: permissions },
  //   { label: "Permissions 6", value: permissions },
  //   { label: "Permissions 7", value: permissions },
  //   { label: "Permissions 8", value: permissions },
  //   { label: "Permissions 9", value: permissions },
  // ];

  const renderItems = (items) =>
    items.map((item) => (
      <MDBox display="flex" key={item.label} py={0.5} pr={2}>
        {/* {item.value !== "" && ( */}
        <MDBox>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label} &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value || "-"}
          </MDTypography>
        </MDBox>
        {/* )} */}
      </MDBox>
    ));

  const renderPermissionItems = (
    items = [
      { name: "permission 1", value: 1, isPermitted: true },
      { name: "permission 2", value: 2, isPermitted: false },
      { name: "permission 3", value: 3, isPermitted: true },
      { name: "permission 4", value: 4, isPermitted: true },
      { name: "permission 5", value: 5, isPermitted: true },
      { name: "permission 6", value: 6, isPermitted: false },
      { name: "permission 7", value: 7, isPermitted: false },
      { name: "permission 8", value: 8, isPermitted: true },
      { name: "permission 9", value: 9, isPermitted: true },
      { name: "permission 11", value: 11, isPermitted: true },
      { name: "permission 12", value: 12, isPermitted: false },
      { name: "permission 13", value: 13, isPermitted: false },
      { name: "permission 14", value: 14, isPermitted: true },
      { name: "permission 15", value: 15, isPermitted: true },
      { name: "permission 16", value: 16, isPermitted: false },
      { name: "permission 17", value: 17, isPermitted: true },
      { name: "permission 18", value: 18, isPermitted: true },
      { name: "permission 19", value: 19, isPermitted: true },
      { name: "permission 21", value: 21, isPermitted: true },
      { name: "permission 22", value: 22, isPermitted: false },
      { name: "permission 23", value: 23, isPermitted: true },
      { name: "permission 24", value: 24, isPermitted: false },
      { name: "permission 25", value: 25, isPermitted: true },
      { name: "permission 26", value: 26, isPermitted: false },
      { name: "permission 27", value: 27, isPermitted: true },
      { name: "permission 28", value: 28, isPermitted: false },
      { name: "permission 29", value: 29, isPermitted: true },
    ]
  ) =>
    items.map((item) => (
      //       <MDBox display="flex" key={item.label} py={0.5} pr={2}>
      //   {/* {item.value !== "" && ( */}
      //   <MDBox>
      //     <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
      //       {item.label} &nbsp;
      //     </MDTypography>
      //     <MDTypography variant="button" fontWeight="regular" color="text">
      //       &nbsp;{item.value || "-"}
      //     </MDTypography>
      //   </MDBox>
      //   {/* )} */}
      // </MDBox>
      <MDBox>
        <MDBox display="flex" alignItems="center" mb={0.5} mr={4}>
          <MDBox mt={0.5}>
            <Switch checked={item?.isPermitted} disabled />
          </MDBox>
          <MDBox width="80%" ml={0.5}>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {item?.name}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
    ));

  return (
    // <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
    <MDBox mx={1}>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox p={2} height="100%" mt={0.5} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {`${name || ""}`}
          </MDTypography>
          <MDBox display="flex">
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Business Phone Number &nbsp;:
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="regular">
              &nbsp;+1 {contact?.mobile}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Basic Info
            </MDTypography>

            <Grid container>{renderItems(otherDetails)}</Grid>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} xl={6}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Address
            </MDTypography>
            <Grid container>{renderItems(addressDetails)}</Grid>
          </MDBox>
        </Grid>

        {/* <Grid item xs={12} md={6} xl={12}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Permissions
            </MDTypography>
            <Grid container>{renderPermissionItems()}</Grid>
          </MDBox>
        </Grid> */}
      </Grid>
    </MDBox>
  );
}

Info.defaultProps = {
  data: {},
  address: {},
  contact: {},
};

Info.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  address: PropTypes.objectOf(PropTypes.any),
  contact: PropTypes.objectOf(PropTypes.any),
};

export default Info;
