/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import CustomerMgr from "layouts/Customers/CustomerMgr";

const { CUSTOMERS, httpRequest, hasPermission } = CustomerMgr;

function DeleteCustomer({ data }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const navigate = useNavigate();

  const [msg, setMsg] = useState("");

  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenDeactivateDialog = () => setShowDeactivateDialog(true);
  const handleCloseDeactiVateDialog = () => setShowDeactivateDialog(false);

  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

  const handleConfirm = async (action) => {
    setIsLoading(true);

    switch (action) {
      case "deactivate":
        handleCloseDeactiVateDialog();
        await httpRequest("customer-deactivate", null, { uuid: data.uuid });
        setMsg("Successfully Deactivated Customer Profile");
        break;
      case "delete":
        handleCloseDeleteDialog();
        await httpRequest("customer-delete", null, { uuid: data.uuid });
        setMsg("Successfully Deleted Customer Profile");
        break;
      default:
        break;
    }

    openSuccessSB(true);

    setTimeout(() => {
      setIsLoading(false);
      navigate(CUSTOMERS.LIST_ROUTE, { state: { from: "" }, replace: true });
    }, 2000);
  };

  return (
    <Card id="delete-account">
      {isLoading && <SimpleBackdrop />}
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Deactivate Customer Account</MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="button" color="warning">
              ** Once you Deactivate customer account, you need to contact the admin to restore the
              customer account. **
            </MDTypography>
          </MDBox>
          {/* <MDBox mb={1}>
            <MDTypography variant="button" color="warning">
              ** Once you Deleted customer account, it will be removed from the records. **
            </MDTypography>
          </MDBox> */}
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpenDeactivateDialog}
            disabled={!hasPermission("deactivate_customer", permissions?.customers)}
          >
            deactivate
          </MDButton>
          {/* <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <MDButton
              variant="gradient"
              color="error"
              sx={{ height: "100%" }}
              onClick={handleOpenDeleteDialog}
            >
              Delete
            </MDButton>
          </MDBox> */}
        </MDBox>
      </MDBox>

      {showDeactivateDialog && (
        <DraggableDialog
          title="Deactivate Customer"
          description={`Are you sure you want to deactivate ${data.first_name}  ${data.last_name}?`}
          buttonDesc="Deactivate"
          openDialog={showDeactivateDialog}
          handleClose={handleCloseDeactiVateDialog}
          handleConfirm={() => handleConfirm("deactivate")}
          fullWidth={false}
        />
      )}

      {showDeleteDialog && (
        <DraggableDialog
          title="Delete Customer"
          description={`Are you sure you want to delete ${data.first_name}  ${data.last_name}?`}
          buttonDesc="Delete"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={() => handleConfirm("delete")}
          fullWidth={false}
        />
      )}

      <MDSnackbar
        color="success"
        icon="check"
        title="Customer Deactivated"
        dateTime=""
        content={msg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </Card>
  );
}
DeleteCustomer.defaultProps = {
  data: {},
};

DeleteCustomer.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default DeleteCustomer;
