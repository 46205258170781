/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import CustomerInfo from "layouts/Customers/new-user/components/CustomerInfo";
import CustomerNotes from "layouts/Customers/new-user/components/CustomerNotes";
import Address from "layouts/Customers/new-user/components/Address";
import Contact from "layouts/Customers/new-user/components/Contact";
import form from "layouts/Customers/new-user/schemas/form";
import initialValues from "layouts/Customers/new-user/schemas/initialValues";
import validations from "layouts/Customers/new-user/schemas/validations";
import CustomerMgr from "layouts/Customers/CustomerMgr";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { RESPONSE_SUCCESS } from "services/context/response";

const {
    CUSTOMERS,
    setStorageItem,
    capitalizeFirstLetter,
    httpRequest,
    validateEmail,
    getResponseMsg,
} = CustomerMgr;

function CustomerNew() {
    const { formId, formField } = form;
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || CUSTOMERS.LIST_ROUTE;
    const custData = location.state?.custData;
    const pageState = location.state?.pageState;

    const [errMsg, setErrMsg] = useState("Failed to Create Customer Profile");
    const successMsg = "Successfully Created Customer Profile";

    const [isLoading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const submitForm = async (values, actions) => {
        let response = null;

        if (pageState === "NEW") {
            response = await httpRequest("customer-create", values);
        } else if (pageState === "EDIT") {
            response = await httpRequest("customer-update", values, { uuid: custData.uuid });
        }

        if (getResponseMsg(response) === "Success") {
            openSuccessSB(true);
            setTimeout(() => {
                setLoading(false);
                actions.resetForm();
                setStorageItem("current_customer_detail", response.data.data);
                navigate(CUSTOMERS.DETAIL_ROUTE, {
                    state: {
                        uuid: response?.data?.data?.uuid,
                        searchKeyword: location.state?.searchKeyword || "",
                    },
                    replace: true,
                });
            }, 2000);
        } else {
            window.scrollTo(0, 0);
            setLoading(false);
            openErrorSB(true);
        }

        actions.setSubmitting(false);
    };

    const handleSave = (err) => {
        window.scrollTo(0, 0);
        if (JSON.stringify(err, null, 2) !== "{}") {
            openErrorSB(true);
            setErrMsg("Please fill in the required field/s");
        }
    };

    const handleSubmit = async (values, actions) => {
        setLoading(true);

        const body = {
            first_name: capitalizeFirstLetter(values?.first_name),
            last_name: capitalizeFirstLetter(values?.last_name),
            email: values?.email,
            company_name: values?.company_name,
            payment_type: values?.payment_type.value,
            classification: values?.classification.value,
            default_discount: (values?.default_discount || 0) / 100,
            notes: values?.notes,
            contact: {
                mobile: values?.mobile,
                telephone: values?.telephone,
                fax: values?.fax,
                other: values?.other,
            },
            address: {
                line_1: values?.line_1,
                line_2: values?.line_2,
                city: values?.city,
                state: values?.state,
                country: values?.country.value,
                zip_code: values?.zip_code,
            },
            tax_exempt: values?.taxExempt,
        };

        const newBody = {
            company_name: values?.company_name,
            payment_type: values?.payment_type.value,
            classification: values?.classification.value,
            default_discount: (values?.default_discount || 0) / 100,
            notes: values?.notes,
            contacts: [
                {
                    first_name: capitalizeFirstLetter(values?.first_name),
                    last_name: capitalizeFirstLetter(values?.last_name),
                    mobile: values?.mobile,
                    telephone: values?.telephone,
                    fax: values?.fax,
                    other: values?.other,
                    email: values?.email,
                },
            ],
            address: {
                line_1: values?.line_1,
                line_2: values?.line_2,
                city: values?.city,
                state: values?.state,
                country: values?.country.value,
                zip_code: values?.zip_code,
            },
            tax_exempt: values?.taxExempt,
        };

        submitForm(newBody, actions);
    };

    const handleClose = () => {
        if (pageState === "NEW") {
            navigate(from, {
                replace: true,
                state: { searchKeyword: location.state?.searchKeyword || "" },
            });
        } else if (pageState === "EDIT") {
            navigate(from, {
                state: { uuid: custData.uuid, searchKeyword: location.state?.searchKeyword || "" },
                replace: true,
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {isLoading && <SimpleBackdrop />}
            <MDBox mb={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox>
                                <MDTypography variant="h6" fontWeight="medium" color="dark">
                                    {pageState === "NEW" ? "New Customer" : "Edit Customer"}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="space-between">
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="dark"
                                    onClick={handleClose}
                                >
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={custData || initialValues}
                            validationSchema={validations}
                            onSubmit={(values, actions) => handleSubmit(values, actions)}
                        >
                            {({ values, errors, touched, isSubmitting }) => {
                                return (
                                    <Form id={formId} autoComplete="off">
                                        <MDBox>
                                            <Card>
                                                <Grid
                                                    container
                                                    spacing={{ xs: 2, md: 3 }}
                                                    columns={{ xs: 4, sm: 8, md: 12 }}
                                                >
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <MDBox ml={2}>
                                                            <CustomerInfo
                                                                formData={{
                                                                    values,
                                                                    touched,
                                                                    formField,
                                                                    errors,
                                                                }}
                                                            />
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Address
                                                            formData={{
                                                                values,
                                                                touched,
                                                                formField,
                                                                errors,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <MDBox ml={2}>
                                                            <Contact
                                                                formData={{
                                                                    values,
                                                                    touched,
                                                                    formField,
                                                                    errors,
                                                                }}
                                                            />
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        {/* <CustomerNotes
                                                        formData={{
                                                            values,
                                                            touched,
                                                            formField,
                                                            errors,
                                                        }}
                                                    /> */}
                                                    </Grid>
                                                </Grid>
                                                <MDBox
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    mb={2}
                                                    mx={2}
                                                >
                                                    <MDButton
                                                        size="small"
                                                        variant="gradient"
                                                        color="error"
                                                        onClick={handleClose}
                                                        sx={{ marginRight: 1 }}
                                                    >
                                                        Cancel
                                                    </MDButton>
                                                    <MDButton
                                                        disabled={isSubmitting}
                                                        size="small"
                                                        type="submit"
                                                        variant="gradient"
                                                        color="info"
                                                        onClick={() => handleSave(errors)}
                                                    >
                                                        save
                                                    </MDButton>
                                                </MDBox>
                                            </Card>
                                        </MDBox>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
            <MDSnackbar
                color="error"
                icon="warning"
                title="Customer Creation"
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />
            <MDSnackbar
                color="success"
                icon="check"
                title="Customer Creation"
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default CustomerNew;
