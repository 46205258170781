/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Switch from "@mui/material/Switch";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import bgImage from "assets/images/rocket/rocket.svg";
import {
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESS,
  MISSING_FIELDS,
  NO_SERVER_RESPONSE,
} from "services/context/messages";
import {
  RESPONSE_SUCCESS,
  RESPONSE_SERVER_NOT_AVAILABLE,
  RESPONSE_INVALID_REQUEST,
} from "services/context/response";
import pathUrl from "services/context/pathUrl";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";
import { connect } from "react-redux";
import authActions from "services/redux/actions/authActions";

const { login, getRequest } = HTTPSServices;
const { setUserData, setMeDetails } = authActions;
const { USER_DETAILS, DASHBOARD, WAREHOUSE_STAGE } = pathUrl;
// eslint-disable-next-line no-unused-vars
const { setAccessToken, setRefreshToken, storePassword, getPassword, setStorageItem } = utils;

function Signin(props) {
  const { userData, me } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || DASHBOARD.route;

  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem("rememberMe") === "true" || false
  );
  const [username, setUsername] = useState(localStorage.getItem("username") || "");
  const [password, setPassword] = useState(getPassword() || "");
  // const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [invalidCreds, setInvalidCreds] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [loading, setLoading] = useState(false);

  const openWarningSB = () => setInvalidCreds(true);
  const closeErrorSB = () => setInvalidCreds(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const initialState = () => {
    setLoading(false);
    setUsername(localStorage.getItem("username") || "");
    setPassword(getPassword() || "");
    // setPassword("");
    setErrMsg("");
    setInvalidCreds(false);
    setSuccessSB(false);
  };

  const getUserDetails = async () => {
    const response = await getRequest(USER_DETAILS.api);
    me(response.data.data);
    // debugger;
    setStorageItem("user_location", response?.data?.data?.location);

    return response?.status;
  };

  useEffect(async () => {
    const controllerRequest = new AbortController();

    if (localStorage.getItem("accessToken")) {
      setLoading(true);
      if ((await getUserDetails()) === RESPONSE_SUCCESS) {
        navigate(from, { replace: true });
      }

      setLoading(false);
    } else {
      initialState();
    }

    return () => {
      initialState();
      controllerRequest.abort();
    };
  }, []);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await login({ username, password });

    if (!response || response?.status === RESPONSE_SERVER_NOT_AVAILABLE) {
      setErrMsg(NO_SERVER_RESPONSE);
      openWarningSB(true);
      setLoading(false);
    } else if (response?.status === RESPONSE_SUCCESS) {
      const axstok = response?.data?.access;
      const rfrtok = response?.data?.refresh;

      openSuccessSB(true);

      localStorage.setItem("rememberMe", rememberMe);
      localStorage.setItem("username", rememberMe ? username : "");
      localStorage.setItem("isLoggedIn", true);
      setAccessToken(axstok);
      setRefreshToken(rfrtok);
      storePassword(rememberMe ? password : "");

      userData({ rememberMe, username, loggedIn: true });

      getUserDetails();
      const responseUserDetails = await getRequest(USER_DETAILS.api);

      setTimeout(() => {
        setLoading(false);
        if (responseUserDetails?.data?.data?.role?.value === "operator") {
          navigate(WAREHOUSE_STAGE.LIST_ROUTE, { replace: true });
        } else {
          navigate(from, { replace: true });
        }
      }, 2000);
    } else if (response?.status === RESPONSE_INVALID_REQUEST) {
      setErrMsg(MISSING_FIELDS);
      setLoading(false);
      openWarningSB(true);
    } else {
      setErrMsg(SIGN_IN_FAILED);
      setLoading(false);
      openWarningSB(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your username and password to sign in"
      illustration={bgImage}
    >
      {loading && <SimpleBackdrop />}

      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Username"
            defaultValue={username}
            fullWidth
            onChange={(e) => setUsername(e.target.value)}
            onKeyPress={handleKeyPress}
            required
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            defaultValue={password}
            fullWidth
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            required
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton variant="gradient" color="info" size="large" fullWidth onClick={handleSubmit}>
            sign in
          </MDButton>
        </MDBox>
        <MDBox display="flex" alignItems="center" ml={-1}>
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <MDTypography
            variant="button"
            fontWeight="regular"
            color="text"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
          >
            &nbsp;&nbsp;Remember me
          </MDTypography>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography variant="button" color="text">
            <MDTypography
              component={Link}
              to="/reset-password"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Forgot Password?
            </MDTypography>
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Sign-in"
        dateTime=""
        content={errMsg}
        open={invalidCreds}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Sign-in"
        dateTime=""
        content={SIGN_IN_SUCCESS}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </IllustrationLayout>
  );
}

Signin.propTypes = {
  userData: PropTypes.func.isRequired,
  me: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  userData: (data) => dispatch(setUserData(data)),
  me: (data) => dispatch(setMeDetails(data)),
});

export default connect(null, mapDispatchToProps)(Signin);
