import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import FormField from "layouts/Customers/new-user/components/FormField";

function CustomerNotes({ formData }) {
  const { formField, values } = formData;
  const { notes } = formField;
  const { notes: notesV } = values;

  return (
    <MDBox mx={2} my={1}>
      <MDBox mt={6.5}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type={notes.type}
              label={notes.label}
              name={notes.name}
              value={notesV}
              placeholder={notes.placeholder}
              multiline
              rows={4}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
CustomerNotes.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CustomerNotes;
