/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Grid, Modal } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";

import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import TransactionList from "layouts/Customers/customer-detail/components/TransactionList";
import { useMaterialUIController } from "context";
import OrderMgr from "layouts/Order/OrderMgr";
import CustomerMgr from "../CustomerMgr";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "50rem",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 2,
  overflow: "auto",
};

const {
  getStorageItem,
  httpRequest,
  formatDate,
  getNextPage,
  getPrevPage,
  getSort,
  RESPONSE_SUCCESS,
  convertToCurrency,
  setStorageItem,
  getResponseMsg,
} = CustomerMgr;
const { ORDERS } = OrderMgr;

function CustomerOrders() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  const location = useLocation();
  const stateKeyword = location.state?.searchKeyword || "";
  const { uuid } = JSON.parse(getStorageItem("current_customer_detail")) || { uuid: "" };

  const [showPayInvoice, setShowPayInvoice] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);

  const [unpaidList, setUnpaidList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(stateKeyword);
  // const [prevSortId, setPrevSortId] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpenTransactionDialog = () => setShowTransactionHistory(true);
  const handleCloseTransactionDialog = () => setShowTransactionHistory(false);

  const showItemDetail = (values) => {
    setStorageItem("current_order_detail", values);
    navigate(ORDERS.DETAIL_ROUTE, {
      state: { from: location, searchKeyword },
      // replace: true
    });
  };

  const dataTableData = {
    columns: [
      { Header: "date", accessor: "date", headerAlign: "left", width: "10%" },
      {
        Header: "job description",
        accessor: "description",
        headerAlign: "left",
        width: "35%",
        // type: "file-link",
      },
      {
        Header: "Contract ",
        accessor: "contract",
        headerAlign: "left",
        width: "20%",
        Cell: ({ value, row }) => {
          const status = (
            <MDBox>
              <MDTypography
                variant="button"
                color="text"
                onClick={async () => {
                  document.body.style.cursor = "wait";
                  const response = await httpRequest("download-customer-unpaid-contracts", null, {
                    contractName: value,
                    uuid,
                  });
                  if (getResponseMsg(response) === "Success") {
                    window.open(response?.data?.data?.file_url);
                  }
                  document.body.style.cursor = "default";
                  // window.open(row?.original?.file_url);
                }}
              >
                <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {/* {row.values.name} */}
                  {value}
                </MDTypography>
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Order ID ",
        accessor: "identifier",
        headerAlign: "left",
        width: "10%",
        Cell: ({ value, row }) => {
          const status = (
            <MDBox>
              <MDTypography
                variant="button"
                color="text"
                onClick={() => showItemDetail(row?.original)}
              >
                <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {/* {row.values.name} */}
                  {value}
                </MDTypography>
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Remaining Balance",
        accessor: "remaining_balance",
        headerAlign: "left",
        width: "15%",
        Cell: ({ value, row }) => {
          const status = (
            <MDTypography variant="body" color="inherit" fontSize="small">
              {convertToCurrency(value)}
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "status",
        accessor: "status",
        headerAlign: "left",
        Cell: ({ value }) => {
          let status;

          if (value === "paid") {
            status = <StatusCell icon="done" color="success" status="Paid" disabled />;
          } else if (value === "refunded") {
            status = <StatusCell icon="replay" color="dark" status="Refunded" disabled />;
          } else {
            status = <StatusCell icon="close" color="error" status="Unpaid" disabled />;
          }

          return status;
        },
      },
    ],
    rows: unpaidList || [],
  };

  // const showCustomerDetail = (values) => {
  //   setStorageItem("current_customer_detail", values);

  //   navigate(CUSTOMERS.DETAIL_ROUTE, {
  //     state: { from: location, uuid: values.uuid, searchKeyword },
  //     // replace: true,
  //   });
  // };

  const processRows = (data = []) => {
    const unpaidLists = [];

    data.map((unpaid) =>
      unpaidLists.push({
        ...unpaid,
        date: formatDate(unpaid?.date, "ll") || "----/--/--",
        file_name: unpaid.file_name,
        file_url: unpaid.file_url,
      })
    );

    return unpaidLists;
  };

  const proccessResponse = (response) => {
    if (response.status === RESPONSE_SUCCESS) {
      setUnpaidList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("customer-unpaid-contracts", null, {
        searchKeyword: val,
        entriesPerPage,
        uuid,
      })
    );
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);

    proccessResponse(
      await httpRequest("customer-unpaid-contracts", null, {
        searchKeyword,
        entriesPerPage: val,
        uuid,
      })
    );
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const loadList = async () => {
    setIsLoading(true);
    proccessResponse(
      await httpRequest("customer-unpaid-contracts", null, { searchKeyword, entriesPerPage, uuid })
    );
    setSearchKeyword(stateKeyword);
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    loadList();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  return (
    <Card id="basic-info" sx={{ overflow: "visible" }}>
      <MDBox>
        <MDBox p={3} display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h5" fontWeight="medium">
              List of unpaid contracts
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDButton
              size="small"
              variant="gradient"
              color="info"
              onClick={handleOpenTransactionDialog}
              sx={{ marginRight: 1 }}
            >
              Transaction History
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox component="form" px={4}>
          <Grid container spacing={1}>
            {/* <DataTable
              table={dataTableData}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={false}
              canSearch={false}
            /> */}

            <DataTable
              table={dataTableData}
              noEndBorder
              entriesPerPage={{
                show: true,
                defaultValue: 10,
                entries: ["5", "10", "15", "20", "25"],
              }}
              // details={showCustomerDetail}
              handleNextPage={handleNextPage}
              handlePrevPage={handlePrevPage}
              handleSearch={handleSearchKeyword}
              handleSort={handleSort}
              handleChangeEntries={handleChangeEntries}
              hasNextPage={nextPageLink !== null}
              hasPrevPage={prevPageLink !== null}
              totalCount={totalRows}
              canRefresh={false}
              canSearch={false}
              showTotalEntries
              // title="Customer List"
              isLoading={isLoading}
            />
          </Grid>
        </MDBox>

        <MDBox my={2} display="flex" justifyContent="center">
          <MDBox>
            <MDButton
              size="small"
              variant="gradient"
              color="info"
              onClick={() => loadList()}
              sx={{ marginRight: 1 }}
              disabled
            >
              Pay Invoice
            </MDButton>
          </MDBox>

          {showTransactionHistory && (
            <Modal
              open={showTransactionHistory}
              onClose={handleCloseTransactionDialog}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
                <TransactionList onClose={handleCloseTransactionDialog} />
              </MDBox>
            </Modal>
          )}
        </MDBox>
      </MDBox>
    </Card>
  );
}

CustomerOrders.defaultProps = {
  // unpaidList: [{}],
};

CustomerOrders.propTypes = {
  // unpaidList: PropTypes.arrayOf(PropTypes.any),
};

export default CustomerOrders;
