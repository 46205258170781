import { combineReducers } from "redux";
import authReducer from "./authReducer";
import helperReducer from "./helperReducer";

const reducers = combineReducers({
  authentication: authReducer,
  helper: helperReducer,
});

export default reducers;
