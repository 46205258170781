/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
// import DataTable from "components/Tables/DataTable";

import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import DraggableDialog from "components/DragableDialog";
import MDInput from "components/MDInput";
import CustomModal from "components/CustomModal";

import { Icon, IconButton, Switch } from "@mui/material";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDButton from "components/MDButton";
import KitsItems from "./KitsItems";

const { getStorageItem, preventNegativeInput, convertToCurrency } = InventoryMgr;

function SubItem({ formData }) {
  const { values } = formData;
  const { collections: collectionsV } = values;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const [kitItemList, setKitItemList] = useState(collectionsV || []);
  const [totals, setTotals] = useState({ totalDaily: 0, totalWeekly: 0, totalMonthly: 0 });

  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showItemList, setShowItemList] = useState(false);

  const handleOpenWarningDialog = () => setShowWarningDialog(true);
  const handleCloseWarningDialog = () => setShowWarningDialog(false);
  const handleOpenItemlistModal = () => setShowItemList(true);
  const handleCloseItemlistModal = () => setShowItemList(false);

  const calculateTotals = (newSubItems) => {
    // const { totalDaily, totalWeekly, totalMonthly } = totals;
    let daily = 0;
    let weekly = 0;
    let monthly = 0;
    // console.log(newSubItems);

    newSubItems.map((item) => {
      // console.log(parseInt(item?.daily_price, 10));
      if (!item?.optional && !item?.delete) {
        daily += Number(item?.daily_price || item?.custom_prices?.daily || 0);
        weekly += Number(item?.weekly_price || item?.custom_prices?.weekly || 0);
        monthly += Number(item?.monthly_price || item?.custom_prices?.monthly || 0);
      }
      return 0;
    });

    values.daily_price_total = daily;
    values.weekly_price_total = weekly;
    values.monthly_price_total = monthly;

    // console.log(daily);
    setTotals({
      totalDaily: daily,
      totalWeekly: weekly,
      totalMonthly: monthly,
    });
  };

  const handleItems = (
    action = "",
    rest = {
      subitem: {
        optional: false,
        quantity: 1,
      },
    }
  ) => {
    let newSubItems = [];

    switch (action) {
      case "add-sub-item":
        setIsLoading(true);
        newSubItems = [
          ...kitItemList,
          {
            ...rest?.kitItem,
            daily_price: rest?.kitItem?.price?.daily,
            weekly_price: rest?.kitItem?.price?.weekly,
            monthly_price: rest?.kitItem?.price?.monthly,
            quantity: 0,
            optional: false,
          },
        ];

        // console.log(newSubItems);
        setIsLoading(false);
        break;

      case "edit-sub-item":
        setIsLoading(true);
        newSubItems = kitItemList.map((subitem, index) => {
          if (rest?.row?.index === index) {
            return {
              ...subitem,
              [rest?.column]: rest?.value,
            };
          }

          return subitem;
        });
        setIsLoading(false);

        break;

      case "remove-sub-item":
        setIsLoading(true);
        // console.log(rest?.row);

        if (itemDetail?.pageState === "EDIT" && rest?.row?.original?.parent_uuid) {
          newSubItems = kitItemList.map((subitem, index) => {
            if (rest?.row?.index === index) {
              return {
                ...subitem,
                delete: true,
              };
            }

            return subitem;
          });
        } else {
          newSubItems = kitItemList.filter((subitem, index) => index !== rest?.row?.index);
        }

        setKitItemList(newSubItems);
        values.collections = newSubItems;
        calculateTotals(newSubItems);
        // console.log(newSubItems);
        setIsLoading(false);

        /** Removing from list */
        // setKitItemList(kitItemList.filter((subitem, index) => index !== rest?.row?.index));
        // values.collections = newSubItems;
        // calculateTotals(kitItemList.filter((subitem, index) => index !== rest?.row?.index));
        return;

      case "restore-sub-item":
        setIsLoading(true);

        newSubItems = kitItemList.map((subitem, index) => {
          if (rest?.row?.index === index) {
            return {
              ...subitem,
              delete: false,
            };
          }

          return subitem;
        });

        setKitItemList(newSubItems);
        values.collections = newSubItems;
        calculateTotals(newSubItems);
        // console.log(newSubItems);

        setIsLoading(false);

        /** Removing from list */
        // setKitItemList(kitItemList.filter((subitem, index) => index !== rest?.row?.index));
        // values.collections = newSubItems;
        // calculateTotals(kitItemList.filter((subitem, index) => index !== rest?.row?.index));
        return;

      default:
        break;
    }

    setKitItemList(newSubItems);
    values.collections = newSubItems;
    // console.log(newSubItems);
    calculateTotals(newSubItems);
  };

  const updateState = (index, name, value) => {
    // UPDATE with render
    // const newArray = subItemList.map((item, i) => {
    //   // console.log(item, i, index, index === i);
    //   if (index === i) {
    //     return { ...item, [name]: value };
    //   }
    //   return item;
    // });
    // settmpSubItemList(newArray);
    // values.subitems = newArray;

    // UPDATE without render

    kitItemList[index] = {
      ...kitItemList[index],
      [name]: value,
    };

    values.collections = kitItemList;
    calculateTotals(kitItemList);

    // console.log(subItemList);
  };

  const dataTableData = {
    columns: [
      {
        Header: "item name",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDBox>
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
                sx={row?.values?.delete === true ? { textDecoration: "line-through" } : ""}
              >
                {`${row?.original?.name || row?.original?.item_name || ""}`}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Daily Rate",
        accessor: "daily_price",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [dailyRate, setDailyRate] = useState(value);

          const status = (
            <MDBox>
              <MDBox
                // display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                width="5rem"
              >
                <MDBox>
                  <MDInput
                    value={dailyRate}
                    type="number"
                    onChange={(e) => {
                      const val = preventNegativeInput(e.target.value);
                      setDailyRate(val);
                      updateState(row?.index, column?.id, val);
                    }}
                    // onBlur={handleOnBlur}
                    disabled={row?.values?.delete === true}
                    // error={dailyRate < 0}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Weekly Rate",
        accessor: "weekly_price",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [weeklyPrice, setWeeklyPrice] = useState(value);

          const status = (
            <MDBox>
              <MDBox
                // display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                width="5rem"
              >
                <MDBox>
                  <MDInput
                    value={weeklyPrice}
                    type="number"
                    onChange={(e) => {
                      const val = preventNegativeInput(e.target.value);
                      setWeeklyPrice(val);
                      updateState(row?.index, column?.id, val);
                    }}
                    // onBlur={handleOnBlur}
                    disabled={row?.values?.delete === true}
                    // error={weeklyRate < 0}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Monthly Rate",
        accessor: "monthly_price",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [monthlyPrice, setMonthlyPrice] = useState(value);

          const status = (
            <MDBox>
              <MDBox
                // display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                width="5rem"
              >
                <MDBox>
                  <MDInput
                    value={monthlyPrice}
                    type="number"
                    onChange={(e) => {
                      const val = preventNegativeInput(e.target.value);
                      setMonthlyPrice(val);
                      updateState(row?.index, column?.id, val);
                    }}
                    // onBlur={handleOnBlur}
                    disabled={row?.values?.delete === true}
                    // error={weeklyRate < 0}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "available",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDBox
              bgColor={row?.original?.local_qty?.available <= 0 ? "error" : "success"}
              display="flex"
              justifyContent="center"
              textAlign="center"
              width="4rem"
              sx={{ border: "2px ", borderRadius: "5px" }}
            >
              <MDTypography variant="button" fontWeight="regular" color="white">
                {row?.original?.local_qty?.available}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Quantity",
        accessor: "quantity",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [quantity, setQuantity] = useState(value);

          const status = (
            <MDBox>
              <MDBox
                // display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
                width="5rem"
                bgColor={quantity > row?.original?.local_qty?.available ? "#ffff39" : ""}
              >
                <MDBox>
                  <MDInput
                    value={quantity}
                    type="number"
                    onChange={(e) => {
                      const val = preventNegativeInput(e.target.value);
                      setQuantity(val);
                      if (val > row?.original?.local_qty?.available) {
                        handleOpenWarningDialog();
                      }
                      updateState(row?.index, column?.id, val);
                    }}
                    // onBlur={handleOnBlur}
                    disabled={row?.values?.delete === true}
                    // error={quantity < 0}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "optional",
        accessor: "optional",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, value, column }) => {
          const [optional, setOptional] = useState(value);

          const handleToggleOptional = (e) => {
            setOptional(e.target.checked);
            updateState(row?.index, column?.id, e.target.checked);
            // handleItems("edit-sub-item", { row, column: column?.id, value: e.target.checked });
          };

          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                <MDBox>
                  <Switch
                    checked={optional}
                    onChange={(e) => handleToggleOptional(e)}
                    disabled={row?.values?.delete === true}
                  />
                </MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="button" color="text">
                    {optional ? "Optional" : "Not Optional"}
                  </MDTypography>
                </MDBox>
              </MDBox>
              {/* )} */}
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "action",
        accessor: "delete",
        width: "20%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const handleRemove = () => {
            handleItems("remove-sub-item", { row });
            // console.log(row);
          };

          const handleRestore = () => {
            handleItems("restore-sub-item", { row });
            // console.log(row);
          };

          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              {row?.values?.delete !== true ? (
                <IconButton
                  size="small"
                  aria-label="edit"
                  color="error"
                  onClick={() => {
                    // console.log(row);
                    handleRemove();
                  }}
                >
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              ) : (
                <IconButton
                  size="small"
                  aria-label="edit"
                  color="info"
                  onClick={() => {
                    // console.log(row);
                    handleRestore();
                  }}
                >
                  <Icon fontSize="small">restore</Icon>
                </IconButton>
              )}
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: kitItemList || [],
  };

  // const processRows = (data = []) => {
  //   const tmpList = [];
  //   let dailyTotal = 0;
  //   let weeklyTotal = 0;
  //   let monthlyTotal = 0;

  //   data?.collections.map((item) => {
  //     if (!item?.optional) {
  //       dailyTotal += Number(item?.custom_prices?.daily) || 0;
  //       weeklyTotal += Number(item?.custom_prices?.weekly) || 0;
  //       monthlyTotal += Number(item?.custom_prices?.monthly) || 0;
  //     }
  //     // tmpList.push({
  //     //   available: item?.available,
  //     //   item_name: item?.name,
  //     //   item_price: {
  //     //     daily: item?.item_price?.daily,
  //     //     weekly: item?.item_price?.weekly,
  //     //     monthly: item?.item_price?.monthly,
  //     //   },
  //     //   item_type: item?.item_type,
  //     //   item_uuid: item?.item_uuid,
  //     //   optional: item?.optional,
  //     //   quantity: item?.quantity,
  //     //   uuid: item?.uuid,
  //     // });
  //     return 0;
  //   });

  //   return { ...data, dailyTotal, weeklyTotal, monthlyTotal };
  // };

  useEffect(() => {
    if (itemDetail?.pageState === "NEW") {
      setKitItemList([]);
      values.collections = [];
    } else {
      // console.log(itemDetail);
      setKitItemList(itemDetail?.collections);
      calculateTotals(itemDetail?.collections);
    }
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox pl={3} display="flex" justifyContent="space-between">
        <MDBox display="flex" justifyContent="flex-start">
          <MDTypography variant="h5" fontWeight="medium">
            Item
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDButton
              size="small"
              variant="gradient"
              color="info"
              onClick={handleOpenItemlistModal}
              sx={{ marginRight: 1 }}
            >
              Add items
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <DataTable
        table={dataTableData}
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        canSearch={false}
        hiddenCols={["is_new"]}
        totals={{
          show: true,
          totalRows: [
            "Total",
            `${convertToCurrency(totals?.totalDaily)}`,
            `${convertToCurrency(totals?.totalWeekly)}`,
            `${convertToCurrency(totals?.totalMonthly)}`,
          ],
        }}
      />

      {showWarningDialog && (
        <DraggableDialog
          title="Insufficient Available Items"
          description="The current available number is less than the desired quantity. You will need to sub-rent to cover the insufficient items."
          buttonDesc="Confirm"
          openDialog={showWarningDialog}
          handleClose={handleCloseWarningDialog}
          handleConfirm={handleCloseWarningDialog}
          fullWidth={false}
          showCancelBtn={false}
        />
      )}

      {showItemList && (
        <CustomModal
          showModal={showItemList}
          handleHideModal={handleCloseItemlistModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "98%",
            width: "80%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <KitsItems
            handleClose={handleCloseItemlistModal}
            setKitItemList={handleItems}
            kitItemList={kitItemList}
          />
        </CustomModal>
      )}
    </MDBox>
  );
}

// Setting default values for the props of DashboardNavbar
SubItem.defaultProps = {};

// typechecking props for UserInfo
SubItem.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

const mapStateToProps = (state) => ({
  consignerListRedux: state?.helper?.consignerList,
});

export default connect(mapStateToProps, null)(SubItem);
