/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment/moment";

import Card from "@mui/material/Card";
import { Autocomplete, Icon } from "@mui/material";
import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";

import ReportsMgr from "layouts/Reports/ReportsMgr";
import FormField from "layouts/pages/account/components/FormField";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import RMRDatePicker from "components/RMRDatePicker";

const {
  httpRequest,
  REPORTS,
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getResponseMsg,
  ellipsis,
  formatDate,
  getDateToday,
  convertToCurrency,
  addDateFormatted,
  subtractDateFormatted,
} = ReportsMgr;

function ReportSearch({ userDetail, warehouseReduxList }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const [itemList, setItemList] = useState([]);
  const [itemTotalList, setItemTotalList] = useState([]);
  const [itemPercentageList, setItemPercentageList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [prevPageLink, setPrevPageLink] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(null);

  const generateReportOptions = [
    { name: "Consignment report", value: "1" },
    { name: "Daily rentals", value: "2" },
    { name: "Weekly rentals", value: "3" },
    { name: "Monthly rentals", value: "4" },
    { name: "Sales tax report by site", value: "5" },
    { name: "Receivables report", value: "6" },
    // { name: "Payables  report", value: "1" },
  ];

  const [warehouseFilter, setWarehouseFilter] = useState(userDetail?.warehouse);
  const [dateFrom, setDateFrom] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [dateTo, setDateTo] = useState(addDateFormatted(getDateToday(), 6, "day", "MM/DD/YYYY"));
  const [monthOf, setMonthOf] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [reportToGenerate, setReportToGenerate] = useState(generateReportOptions[1] || null);
  const [showTable, setShowTable] = useState(null);

  const [showNewbtnTooltip, setShoWNewbtmTooltip] = useState(false);
  const [showViewInventoryTooltip, setShowViewInventoryTooltip] = useState(false);

  const closeMenu = () => setMenu(null);

  const loadList = async (searchKeywordVal = "", entriesPerPageVal = 25) =>
    // console.log(statusFilterList);
    httpRequest(
      "orders-list",
      {},
      {
        searchKeyword: searchKeywordVal,
        entriesPerPage: entriesPerPageVal,
        date_from: dateFrom,
        date_to: dateTo,
        // status: statusFilterList,
      }
    );
  const showItemDetail = (values) => {
    setStorageItem("current_order_detail", values);
    navigate(REPORTS.DETAIL_ROUTE, {
      state: { from: location },
      // replace: true
    });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Day",
        accessor: "date",
        width: "10%",
        align: "center",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox align="center">
                <MDTypography variant="button" fontWeight="regular">
                  {moment(formatDate(value, "LL")).date()}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Cash",
        accessor: "cash",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start">
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Check",
        accessor: "check",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Credit/Debit",
        accessor: "credit_debit",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "ACH Transfer",
        accessor: "ach",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Wire Transfer",
        accessor: "wire_transfer",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Total Payment",
        accessor: "total_payment",
        width: "10%",
        align: "right",
        headerAlign: "center",
        hasBorderRight: true,
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Rental Revenue",
        accessor: "rental_revenue",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Sales Revenue",
        accessor: "sales_revenue",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Other Revenue",
        accessor: "other_revenue",
        align: "right",
        headerAlign: "center",
        width: "10%",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      // {
      //   Header: "Discounts Given",
      //   accessor: "discounts_given",
      //   align: "right",
      //   width: "10%",
      //   headerAlign: "left",
      //   Cell: ({ value }) => {
      //     const status = (
      //       <MDBox>
      //         <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
      //           <MDTypography variant="button" fontWeight="regular">
      //             {convertToCurrency(value || 0)}
      //           </MDTypography>
      //         </MDBox>
      //       </MDBox>
      //     );
      //     return status;
      //   },
      // },
      {
        Header: "Taxes",
        accessor: "taxes",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Total Revenue",
        accessor: "total_revenue",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList || [],
    // rows: [],
  };

  const changeTimeZone = (date, timeZone) => {
    if (typeof date === "string") {
      return new Date(
        new Date(date).toLocaleString("en-US", {
          timeZone,
        })
      );
    }

    return new Date(
      date.toLocaleString("en-US", {
        timeZone,
      })
    );
  };

  const processRows = (data = []) => {
    const items = [];

    data.map((item) => {
      items.push({
        // item,
        identifier: item.identifier,
        date_ordered: `${moment(
          changeTimeZone(item?.date_ordered, userDetail?.location?.timezone?.name)
        ).format("LLL")} (${userDetail?.location?.timezone?.abbreviation || "UTC"})`,
        uuid: item.uuid,
        customer_name: item?.customer?.name,
        description: ellipsis(item?.description),
        order_total: item?.order_total,
        invoice_total: item?.invoice_total,
        total_amount_paid: item?.total_amount_paid,
        agent_name: item?.agent?.name,
        status: item?.status?.name,
      });
      return true;
    });

    return items;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setItemList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(await loadList(val, entriesPerPage));
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(await loadList(searchKeyword, val));
    setIsLoading(false);
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);

    // setDateFrom(formatDate(getDateToday(), "MM/DD/YYYY"));
    // debugger;
    // const orderListResponse = await loadList(searchKeyword, entriesPerPage);
    // proccessResponse(orderListResponse);
    setIsLoading(false);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleChangeFrom = (newValue) => {
    setDateFrom(formatDate(newValue?.$d, "MM/DD/YYYY"));
    setDateTo(addDateFormatted(newValue?.$d, 6, "day", "MM/DD/YYYY"));
  };

  const handleChangeTo = (newValue) => {
    setDateTo(formatDate(newValue?.$d, "MM/DD/YYYY"));
    setDateFrom(subtractDateFormatted(newValue?.$d, 6, "day", "MM/DD/YYYY"));
  };

  const handleChangeMonth = (newValue) => {
    setMonthOf(formatDate(newValue?.$d, "MM/DD/YYYY"));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="flex-end" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <Autocomplete
              // multiple
              disablePortal
              id="combo-box-demo"
              value={reportToGenerate}
              options={generateReportOptions}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <FormField {...params} label="SELECT A REPORT TO GENERATE" />
              )}
              sx={{ width: 250, height: "100%", lineHeight: 1, m: 1 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setReportToGenerate(newValue);
                  setShowTable(false);
                }
              }}
            />
          </MDBox>
          <MDBox>
            <Autocomplete
              value={warehouseFilter}
              options={warehouseReduxList}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <FormField {...params} label="Warehouse" InputLabelProps={{ shrink: true }} />
              )}
              sx={{ width: 200, height: "100%", lineHeight: 1, m: 1 }}
              onChange={(event, newValue) => {
                // console.log(newValue);
                if (newValue !== null) {
                  setWarehouseFilter(newValue);
                }
              }}
            />
          </MDBox>
          {(reportToGenerate.value === generateReportOptions[1].value ||
            reportToGenerate.value === generateReportOptions[2].value) && (
            <MDBox display="flex">
              <MDBox mt={2}>
                <RMRDatePicker
                  value={dateFrom}
                  handleChange={handleChangeFrom}
                  label={
                    reportToGenerate.value === generateReportOptions[1].value ? "Date" : "Date From"
                  }
                  inputFormat="MM/DD/YYYY"
                />
              </MDBox>
            </MDBox>
          )}
          {reportToGenerate.value === generateReportOptions[2].value && (
            <MDBox display="flex">
              <MDBox mt={2} ml={2}>
                <RMRDatePicker
                  value={dateTo}
                  handleChange={handleChangeTo}
                  label="Date To"
                  inputFormat="MM/DD/YYYY"
                  // disabled
                />
              </MDBox>
            </MDBox>
          )}
          {reportToGenerate.value === generateReportOptions[3].value && (
            <MDBox display="flex">
              <MDBox mt={2} ml={2}>
                <RMRDatePicker
                  value={monthOf}
                  views={["month"]}
                  handleChange={handleChangeMonth}
                  label="Month"
                  inputFormat="MMMM"
                  // disabled
                />
              </MDBox>
            </MDBox>
          )}
          <MDBox m={2}>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={async () => {
                setIsLoading(true);
                let response = {};
                switch (reportToGenerate.value) {
                  case generateReportOptions[1].value:
                    response = await httpRequest(
                      "daily-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  case generateReportOptions[2].value:
                    response = await httpRequest(
                      "weekly-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  case generateReportOptions[3].value:
                    response = await httpRequest(
                      "monthly-rentals",
                      {},
                      {
                        monthOf: (moment(monthOf).month() || 0) + 1,
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  default:
                    break;
                }

                if (getResponseMsg(response) === "Success") {
                  setItemList(response.data.data.day_totals);
                  setItemTotalList(response.data.data.column_totals);
                  setItemPercentageList(response.data.data.column_percentage);
                  // console.log(response.data.data.day_totals);
                }
                setShowTable(true);
                setIsLoading(false);
              }}
              // disabled={!hasPermission("add_order", permissions?.orders)}
              disabled={
                reportToGenerate === null ||
                reportToGenerate.value === generateReportOptions[0].value ||
                // reportToGenerate.value === generateReportOptions[1].value ||
                // reportToGenerate.value === generateReportOptions[2].value ||
                // reportToGenerate.value === generateReportOptions[3].value ||
                reportToGenerate.value === generateReportOptions[4].value ||
                reportToGenerate.value === generateReportOptions[5].value ||
                (reportToGenerate.value === generateReportOptions[1].value && dateFrom === null) ||
                (reportToGenerate.value === generateReportOptions[2].value && dateFrom === null) ||
                dateTo === null ||
                (reportToGenerate.value === generateReportOptions[2].value &&
                  (dateFrom === null || dateTo === null)) ||
                (reportToGenerate.value === generateReportOptions[3].value && monthOf === null) ||
                dateTo === "" ||
                warehouseFilter === null
              }
            >
              Generate report
            </MDButton>
          </MDBox>
        </MDBox>
        {showTable && (
          <MDBox>
            {itemList?.length > 0 && (
              <MDBox ml={1} mb={2} display="flex" justifyContent="flex-end">
                <MDBox mr={1}>
                  <MDButton
                    variant="outlined"
                    color="dark"
                    onClick={async () => {
                      setIsLoading(true);
                      let response = {};
                      switch (reportToGenerate.value) {
                        case generateReportOptions[1].value:
                          response = await httpRequest(
                            "export-daily-rentals",
                            {},
                            {
                              dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                              warehouse: warehouseFilter?.id,
                              exportType: "pdf",
                            }
                          );
                          break;
                        case generateReportOptions[2].value:
                          response = await httpRequest(
                            "export-weekly-rentals",
                            {},
                            {
                              dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                              dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                              warehouse: warehouseFilter?.id,
                              exportType: "pdf",
                            }
                          );
                          break;
                        case generateReportOptions[3].value:
                          response = await httpRequest(
                            "export-monthly-rentals",
                            {},
                            {
                              monthOf: (moment(monthOf).month() || 0) + 1,
                              warehouse: warehouseFilter?.id,
                              exportType: "pdf",
                            }
                          );
                          break;
                        default:
                          break;
                      }

                      if (getResponseMsg(response) === "Success") {
                        window.open(response?.data?.data?.url, "_blank", "noreferrer");
                      }
                      setShowTable(true);
                      setIsLoading(false);
                    }}
                  >
                    <Icon>description</Icon>
                    &nbsp;export PDF
                  </MDButton>
                </MDBox>
                {/* <MDButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export CSV
                </MDButton> */}
              </MDBox>
            )}
            <MDBox>
              <Card width="100%">
                <DataTable
                  table={dataTableData}
                  noEndBorder={false}
                  details={showItemDetail}
                  handleNextPage={handleNextPage}
                  handlePrevPage={handlePrevPage}
                  handleSearch={handleSearchKeyword}
                  handleChangeEntries={handleChangeEntries}
                  handleSort={handleSort}
                  hasNextPage={nextPageLink !== null}
                  hasPrevPage={prevPageLink !== null}
                  totalCount={totalRows}
                  totals={{
                    show: true,
                    hasBorderRight: true,
                    hasBorderTop: true,
                    align: "right",
                    totalRows: [
                      "Totals",
                      // "",
                      `${convertToCurrency(itemTotalList?.cash || 0)}`,
                      `${convertToCurrency(itemTotalList?.check || 0)}`,
                      `${convertToCurrency(itemTotalList?.credit_debit || 0)}`,
                      `${convertToCurrency(itemTotalList?.ach || 0)}`,
                      `${convertToCurrency(itemTotalList?.wire_transfer || 0)}`,
                      `${convertToCurrency(itemTotalList?.total_payment || 0)}`,
                      `${convertToCurrency(itemTotalList?.rental_revenue || 0)}`,
                      `${convertToCurrency(itemTotalList?.sales_revenue || 0)}`,
                      `${convertToCurrency(itemTotalList?.other_revenue || 0)}`,
                      // `${convertToCurrency(itemTotalList?.discounts_given || 0)}`,
                      `${convertToCurrency(itemTotalList?.taxes || 0)}`,
                      `${convertToCurrency(itemTotalList?.total_revenue || 0)}`,
                    ],
                  }}
                  totalPercentage={{
                    show: true,
                    hasBorderRight: true,
                    totalRows: [
                      "Percentage Rate",
                      // "",
                      `${itemPercentageList?.cash}`,
                      `${itemPercentageList?.check}`,
                      `${itemPercentageList?.credit_debit}`,
                      `${itemPercentageList?.ach}`,
                      `${itemPercentageList?.wire_transfer}`,
                      `${itemPercentageList?.total_payment}`,
                      `${itemPercentageList?.rental_revenue}`,
                      `${itemPercentageList?.sales_revenue}`,
                      `${itemPercentageList?.other_revenue}`,
                      // `${itemPercentageList?.discounts_given}`,
                      `${itemPercentageList?.taxes}`,
                      `${itemPercentageList?.total_revenue}`,
                    ],
                  }}
                  canSearch={false}
                  entriesPerPage={{ show: false, defaultValue: 25, entries: [10, 25, 50, 100] }}
                  // title={`${reportToGenerate.name} Report List`}
                  title={
                    <MDBox>
                      <MDBox>
                        <MDTypography variant="h6" fontWeight="medium">
                          {reportToGenerate.name} Report list
                        </MDTypography>
                      </MDBox>
                      <MDBox>
                        <MDTypography variant="h6" fontWeight="medium">
                          {reportToGenerate.value === generateReportOptions[1].value &&
                            `${formatDate(dateFrom, "LL")}`}
                          {reportToGenerate.value === generateReportOptions[2].value &&
                            `${formatDate(dateFrom, "ll")} to ${formatDate(dateTo, "ll")}`}
                          {reportToGenerate.value === generateReportOptions[3].value &&
                            `Month of ${formatDate(monthOf, "MMMM YYYY")}`}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  }
                  isLoading={isLoading}
                  canRefresh
                />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

ReportSearch.defaultProps = {
  userDetail: {},
  warehouseReduxList: [],
};

ReportSearch.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
  warehouseReduxList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
});

export default connect(mapStateToProps, null)(ReportSearch);
