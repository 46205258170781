const form = {
  formId: "new-location-form",
  formField: {
    name: {
      name: "name",
      label: "Location Name *",
      type: "text",
      errorMsg: "Location name is required.",
    },
    taxRate: {
      name: "tax_rate",
      label: "Tax Rate ",
      type: "number",
      errorMsg: "Tax Rate is required.",
    },
    timezone: {
      name: "timezone",
      label: "Timezone *",
      type: "text",
      errorMsg: "Timezone is required.",
    },
    email: {
      name: "email",
      label: "Email *",
      type: "text",
      errorMsg: "Email is required.",
    },

    mobile: {
      name: "mobile",
      label: "Telephone 1 *",
      type: "tel",
      errorMsg: "Mobile number is required.",
      invalidMsg: "Your Mobile number is invalid",
    },
    telephone: {
      name: "telephone",
      label: "Telephone 2 ",
      type: "tel",
      errorMsg: "Telephone number is required.",
      invalidMsg: "Your Telephone number is invalid",
    },
    fax: {
      name: "fax",
      label: "Fax",
      type: "text",
      errorMsg: "Fax number is required.",
      invalidMsg: "Your Fax number is invalid",
    },
    other: {
      name: "other",
      label: "Other",
      type: "text",
      errorMsg: "Other number is required.",
      invalidMsg: "Your Other number is invalid",
    },
    line1: {
      name: "line_1",
      label: "Line 1 *",
      type: "text",
      errorMsg: "Address is required.",
    },
    line2: {
      name: "line_2",
      label: "Line 2",
      type: "text",
    },
    city: {
      name: "city",
      label: "City *",
      type: "text",
      errorMsg: "City is required.",
    },
    state: {
      name: "state",
      label: "State *",
      type: "text",
      errorMsg: "State is required.",
    },
    country: {
      name: "country",
      label: "Country *",
      type: "text",
      errorMsg: "Country is required.",
    },
    zip: {
      name: "zip_code",
      label: "Zip *",
      type: "text",
      errorMsg: "Zip is required.",
      invalidMsg: "Your Zip number is invalid",
    },
  },
};

export default form;
