/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/Inventory/new-item/components/FormField";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import { Icon, IconButton } from "@mui/material";
import MDInput from "components/MDInput";

const { preventNegativeInput, getStorageItem, limitInputLength } = InventoryMgr;

function Pricing({ formData, showFieldError }) {
  const { formField, values, errors, touched } = formData;
  const { dailyPrice, weeklyPrice, monthlyPrice, replacementValue } = formField;
  const {
    daily_price: dailyPriceV,
    weekly_price: weeklyPriceV,
    monthly_price: monthlyPriceV,
    replacement_value: replacementValueV,
    item_type: itemTypeV,
  } = values;

  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const [dailyPriceInput, setDailyPriceInput] = useState(dailyPriceV || "");
  const [weeklyPriceInput, setWeeklyPriceInput] = useState(weeklyPriceV || "");
  const [monthlyPriceInput, setMonthlyPriceInput] = useState(monthlyPriceV || "");
  const [replacementInput, setReplacementInput] = useState(replacementValueV || 1);

  const handleUseDefaultPricing = () => {
    setDailyPriceInput(values?.daily_price_total);
    setWeeklyPriceInput(values?.weekly_price_total);
    setMonthlyPriceInput(values?.monthly_price_total);
    values.daily_price = values.daily_price_total;
    values.weekly_price = values.weekly_price_total;
    values.monthly_price = values.monthly_price_total;
  };

  useEffect(() => {
    if (itemDetail.pageState === "NEW") {
      setDailyPriceInput("");
      setWeeklyPriceInput("");
      setMonthlyPriceInput("");
      setReplacementInput(1);
      values.item_type = null;
      values.daily_price_total = "";
      values.weekly_price_total = "";
      values.monthly_price_total = "";
      values.daily_price = "";
      values.weekly_price = "";
      values.monthly_price = "";
      values.replacement_value = 1;
    }
  }, []);

  return (
    <MDBox>
      <MDBox p={3}>
        <MDBox width="100%" display="flex" justifyContent="space-between">
          <MDTypography variant="h5">{values?.item_type?.name} Pricing</MDTypography>

          <MDTypography variant="caption" fontWeight="regular" color="warning">
            * = Required Fields
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              <MDInput
                fullWidth
                variant="standard"
                label={dailyPrice.label}
                value={dailyPriceInput}
                onChange={(e) => {
                  const val = limitInputLength(preventNegativeInput(e.target.value), 6);
                  setDailyPriceInput(val);
                  values.daily_price = val;
                }}
              />
              {showFieldError && dailyPriceInput === "" && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {dailyPrice?.errorMsg}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              <MDInput
                fullWidth
                variant="standard"
                label={weeklyPrice.label}
                value={weeklyPriceInput}
                placeholder={weeklyPrice.placeholder}
                onChange={(e) => {
                  const val = limitInputLength(preventNegativeInput(e.target.value), 6);
                  setWeeklyPriceInput(val);
                  values.weekly_price = val;
                }}
              />
              {showFieldError && weeklyPriceInput === "" && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {weeklyPrice?.errorMsg}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Grid>

          <Grid item xs={12} sm={6}>
            <MDBox mb={1.5}>
              <MDInput
                fullWidth
                variant="standard"
                type={monthlyPrice.type}
                label={monthlyPrice.label}
                name={monthlyPrice.name}
                value={monthlyPriceInput}
                placeholder={monthlyPrice.placeholder}
                // error={monthlyPriceInput === ""}
                onChange={(e) => {
                  const val = limitInputLength(preventNegativeInput(e.target.value), 6);
                  setMonthlyPriceInput(val);
                  values.monthly_price = val;
                }}
              />
              {showFieldError && monthlyPriceInput === "" && (
                <MDBox mt={0.75}>
                  <MDTypography
                    component="div"
                    variant="caption"
                    color="error"
                    fontWeight="regular"
                  >
                    {monthlyPrice?.errorMsg}
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          </Grid>

          {itemTypeV?.value === "kit" && (
            <Grid item xs={12} sm={6}>
              <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                <MDBox display="flex" justifyContent="space-between">
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="info"
                    onClick={handleUseDefaultPricing}
                  >
                    <Icon fontSize="small">refresh</Icon>
                  </IconButton>
                </MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="button" color="text">
                    Refresh Pricing
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          )}

          {itemTypeV?.value !== "kit" && (
            <Grid item xs={12} sm={6}>
              <FormField
                type={replacementValue.type}
                label={replacementValue.label}
                name={replacementValue.name}
                value={replacementInput}
                placeholder={replacementValue.placeholder}
                // error={replacementInput === ""}
                onChange={(e) => {
                  const val = preventNegativeInput(e.target.value);
                  setReplacementInput(val);
                  values.replacement_value = val;
                }}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of DashboardNavbar
Pricing.defaultProps = {};

// typechecking props for UserInfo
Pricing.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  showFieldError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, null)(Pricing);
