/* eslint-disable no-unused-vars */
import * as React from "react";
import PropTypes from "prop-types";

import dayjs from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

function RMRDatePicker({ value, handleChange, label, inputFormat, disabled, views }) {
  // const [value, setValue] = React.useState(dayjs("2014-08-18T21:11:54"));

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        <DesktopDatePicker
          views={views || [("year", "month", "day")]}
          label={label}
          inputFormat={inputFormat || "MM/DD/YYYY"}
          value={value}
          onChange={handleChange}
          readOnly={disabled}
          renderInput={(params) => <TextField {...params} color="info" />}
        />
        {/* <MobileDatePicker
          label="Date mobile"
          inputFormat="MM/DD/YYYY"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <TimePicker
          label="Time"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          label="Date&Time picker"
          value={value}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        /> */}
      </Stack>
    </LocalizationProvider>
  );
}

RMRDatePicker.defaultProps = {
  value: "",
  handleChange: () => {},
  label: "",
  inputFormat: "MM/DD/YYYY",
  disabled: false,
  views: [("year", "month", "day")],
};

RMRDatePicker.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  label: PropTypes.string,
  inputFormat: PropTypes.string,
  disabled: PropTypes.bool,
  views: PropTypes.arrayOf(PropTypes.any),
};

export default RMRDatePicker;
