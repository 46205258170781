/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";

import MDSnackbar from "components/MDSnackbar";

import { useMaterialUIController } from "context";
import OrderMgr from "layouts/Order/OrderMgr";
import WarehouseMgr from "../WarehouseMgr";
import BasicInfo from "./checkin-detail-components/BasicInfo";
import RentItems from "./checkin-detail-components/RentItems";

const { ORDERS } = OrderMgr;

const {
  httpRequest,
  setStorageItem,
  getResponseMsg,
  getStorageItem,
  WAREHOUSE_CHECKIN,
  capitalizeFirstLetter,
} = WarehouseMgr;

function CheckinDetails() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || ORDERS.LIST_ROUTE;
  const checkinDetails = JSON.parse(getStorageItem("current_checkin_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const [orderData, setOrderData] = useState([]);
  const [showCheckInDialogue, setShowCheckInDialogue] = useState(false);
  const [slackBotMenu, setSlackBotMenu] = useState(null);

  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item Creation");

  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenDeleteDialog = () => setShowCheckInDialogue(true);
  const handleCloseDeleteDialog = () => setShowCheckInDialogue(false);
  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);

  const processRows = (data = []) => ({
    ...data,
    checked_in: data?.items?.checked_in,
    pending_items: data?.items?.pending,
  });

  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("warehouse-checkin-detail", null, {
      uuid: checkinDetails?.uuid,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      setOrderData(processRows(responseDetails?.data?.data));

      setStorageItem("current_checkin_detail", processRows(responseDetails?.data?.data));
    } else {
      setStorageItem("current_checkin_detail", null);
      navigate(WAREHOUSE_CHECKIN.LIST_ROUTE, {
        state: { from: location },
        // replace: true
      });
    }

    setLoading(false);

    return 0;
  };

  const handleConfirm = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("quick-check-in", { order: checkinDetails?.uuid });

    if (getResponseMsg(responseDetails) === "Success") {
      handleCloseDeleteDialog();
      setSuccessMsg("Successfully Performed Quick Check-in");
      openSuccessSB();
      loadDetails();
    } else {
      let responseErrMsg = "";

      Object.entries(responseDetails.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      handleCloseDeleteDialog();
      setErrMsg(responseErrMsg);
      openErrorSB();
    }

    setLoading(false);
  };

  useEffect(() => {
    const controllerRequest = new AbortController();
    setNotifTitle("Checkin Details");
    if (checkinDetails === {}) {
      navigate(WAREHOUSE_CHECKIN.LIST_ROUTE, {
        // replace: true
      });
    }
    loadDetails();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleClose = () => {
    // console.log(location, from);
    if (from && from !== WAREHOUSE_CHECKIN.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(WAREHOUSE_CHECKIN?.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <MenuItem
        onClick={handleOpenDeleteDialog}
        disabled={checkinDetails?.status?.value !== "on_rent"}
      >
        Quick Check-in
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <MDTypography variant="button" color="error" fontWeight="medium">
          Back
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        {isMobile ? (
          <MDBox display="flex" justifyContent="flex-end">
            <MDTypography
              color="secondary"
              onClick={openSlackBotMenu}
              sx={{
                ml: "auto",
                alignSelf: "flex-start",
              }}
            >
              <Icon fontSize="default" sx={{ cursor: "pointer", fontWeight: "bold" }}>
                more_vert
              </Icon>
            </MDTypography>
            {renderMenu(slackBotMenu, closeSlackBotMenu)}
          </MDBox>
        ) : (
          <MDBox mb={1} display="flex" justifyContent="flex-end">
            <MDBox mr={1}>
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                onClick={handleOpenDeleteDialog}
                disabled={checkinDetails?.status?.value !== "on_rent"}
              >
                quick check-in
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
                back
              </MDButton>
            </MDBox>
          </MDBox>
        )}
        {/* <MDBox mb={1} display="flex" justifyContent="flex-end">
          <MDBox mr={1}>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={handleOpenDeleteDialog}
              disabled={checkinDetails?.status?.value !== "on_rent"}
            >
              quick check-in
            </MDButton>
          </MDBox>
          <MDBox>
            <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
              back
            </MDButton>
          </MDBox>
        </MDBox> */}
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12}>
                <BasicInfo data={orderData || {}} loadDetails={loadDetails} />
              </Grid>
              <Grid item xs={12}>
                <RentItems
                  data={orderData || []}
                  setData={setOrderData}
                  loadDetails={loadDetails}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      {showCheckInDialogue && (
        // <div>
        <Dialog open={showCheckInDialogue} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Quick Check-in</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to perform Quick Check-in?</DialogContentText>
            <DialogContentText>
              ♦ Order status will be change to “Awaiting Check-In”
            </DialogContentText>
            <DialogContentText>
              ♦ All items under this order will automatically have their item status changed to
              “Returned”
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDeleteDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handleConfirm()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CheckinDetails;
