import { useState } from "react";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";

import Info from "layouts/admin/Categories/new-category/components/Info";
import form from "layouts/admin/Categories/new-category/schemas/form";
import initialValues from "layouts/admin/Categories/new-category/schemas/initialValues";
import validations from "layouts/admin/Categories/new-category/schemas/validations";

import CategoryMgr from "layouts/admin/Categories/CategoryMgr";
import { connect } from "react-redux";

const {
  setStorageItem,
  CATEGORIES,
  updateCategory,
  getStorageItem,
  httpRequest,
  RESPONSE_SUCCESS,
  setCategoryReduxList,
} = CategoryMgr;

function CategoriesNew({ handleClose, setCategoriesReduxList }) {
  const { formId, formField } = form;

  const categoryData = JSON.parse(getStorageItem("current_categories_detail"));

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to Create Category");

  const [isLoading, setLoading] = useState(false);

  const processRows = (data = []) => {
    const catList = [];

    data.map((item) =>
      catList.push({
        uuid: item.uuid,
        name: item.name,
        total_items: item.total_items,
      })
    );
    return catList;
  };

  const processRedux = (response) => {
    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      setCategoriesReduxList(processRows(response.data.data));
    }
  };

  const submitForm = async (values, actions) => {
    let response = null;
    let msg = "Successfully Created category";

    if (categoryData.pageState === "NEW") {
      response = await httpRequest("category-create", values);
    } else if (categoryData.pageState === "EDIT") {
      response = await updateCategory(categoryData?.uuid, values);
      msg = "Successfully Updated category";
    }

    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      setStorageItem("current_categories_detail", response.data.data);
      const categoryListResponse = await httpRequest("category-list", null, {
        searchKeyword: "",
        entriesPerPage: 0,
      });
      processRedux(categoryListResponse);
      handleClose("save", { msg });
      setLoading(false);
    } else {
      setLoading(false);
      setShowError(true);
      setErrMsg(response.data.name || response.data.detail);
    }

    setLoading(false);
    actions.setSubmitting(false);
  };

  const handleSave = () => {
    setShowError(false);
  };

  const handleSubmit = async (values, actions) => {
    if (values?.name === categoryData?.name) {
      handleClose();
    } else {
      setLoading(true);
      submitForm({ name: values.name }, actions);
    }
  };

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium" color="dark">
                {categoryData.pageState === "NEW"
                  ? `New ${CATEGORIES.TITLE}`
                  : `Edit ${CATEGORIES.TITLE}`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between">
              <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={
              categoryData.pageState === "NEW" ? initialValues : categoryData || initialValues
            }
            validationSchema={validations}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <MDBox>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <MDBox ml={2}>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }}
                        />

                        {showError && (
                          <MDBox mt={-2}>
                            <MDTypography variant="caption" fontWeight="regular" color="error">
                              {errMsg}
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox display="flex" justifyContent="flex-end" mx={2}>
                    <MDButton
                      size="small"
                      variant="gradient"
                      color="error"
                      onClick={handleClose}
                      sx={{ marginRight: 1 }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      disabled={isSubmitting}
                      size="small"
                      type="submit"
                      variant="gradient"
                      color="info"
                      onClick={() => handleSave(errors)}
                    >
                      save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

CategoriesNew.defaultProps = {
  handleClose: () => {},
};

CategoriesNew.propTypes = {
  handleClose: PropTypes.func,
  setCategoriesReduxList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setCategoriesReduxList: (data) => dispatch(setCategoryReduxList(data)),
});

export default connect(null, mapDispatchToProps)(CategoriesNew);
