/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import { useNavigate, useParams, Link } from "react-router-dom";

import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton"; // Material Dashboard 2 React Components
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import bgImage from "assets/images/reelmen/2.png";

import {
  PASSWORD_CHANGE_SUCCESS,
  SIGN_UP_REDIRECT,
  PASSWORD_CHANGE_FAILED,
} from "services/context/messages";
import { RESPONSE_SUCCESS, RESPONSE_NOT_AUTHORIZED } from "services/context/response";
import utils from "services/utils";
import pathUrl from "services/context/pathUrl";
import HTTPSServices from "services/https/HTTPSServices";
import { Grid } from "@mui/material";
import UsersMgr from "layouts/admin/ManageUsers/UsersMgr";

const {
  validatePassword,
  validateUppercasePassword,
  validateLowercasePassword,
  validateHasNumericPassword,
  validateEightCharPassword,
} = utils;
const { LOGIN, SET_NEW_PASSWORD } = pathUrl;
const { authRequest } = HTTPSServices;
const { httpRequest, getResponseMsg, getStorageItem } = UsersMgr;

function ResetUserPassword({ onClose }) {
  const userDetail = JSON.parse(getStorageItem("current_user_detail"));
  const [password, setPassword] = useState("");
  const [confirmPasword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [isUsed, setIsUsed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasEightChar, setHasEightChar] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasNumeric, setHasNumeric] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [isSuccess, setIisSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const navigate = useNavigate();
  const { uidb64, token } = useParams();

  const handleSave = async () => {
    // if (password === "") {
    //   setPasswordError(true);
    // }
    // if (confirmPasswordError === "") {
    //   setConfirmPasswordError(true);
    // }
    const errMsg = validatePassword(password, confirmPasword);

    if (errMsg === "") {
      setIsLoading(true);
      // setTimeout(async () => {
      const response = await httpRequest(
        "users-reset-password",
        { password },
        { uuid: userDetail?.uuid }
      );

      if (getResponseMsg(response) === "Success") {
        openSuccessSB();
        setIisSuccess(true);
        onClose("success");
        // navigate(LOGIN.route);
      } else {
        setIsUsed(response.status === RESPONSE_NOT_AUTHORIZED);
        setMessage(response.data.detail);
        openErrorSB(true);
      }

      setIsLoading(false);
      // }, 3000);
    } else {
      setMessage(errMsg);
      openErrorSB();
    }
  };

  const handleChangeConfirmPassword = (e) => {
    setMessage("");
    e.preventDefault();
    setConfirmPassword(e.target.value);

    setIsPasswordMatch(password === e.target.value);
  };

  const handleChangePassword = (e) => {
    setMessage("");
    e.preventDefault();
    setPassword(e.target.value);

    setHasNumeric(validateHasNumericPassword(e.target.value));
    setHasLowercase(validateLowercasePassword(e.target.value));
    setHasUppercase(validateUppercasePassword(e.target.value));
    setHasEightChar(validateEightCharPassword(e.target.value));
    setIsPasswordMatch(e.target.value === confirmPasword);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSave();
    }
  };

  const passwordRequirements = [
    { name: "At least 8 Characters", isAccepted: hasEightChar },
    { name: "At least 1 Uppercase", isAccepted: hasUppercase },
    { name: "At least 1 Numeric", isAccepted: hasNumeric },
    { name: "At least 1 Lowercase", isAccepted: hasLowercase },
    { name: "Password match", isAccepted: isPasswordMatch },
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox display="flex" key={itemKey} color="text" fontSize="1.25rem" lineHeight={1}>
        <MDBox mx={2}>
          <Icon color={item?.isAccepted ? "success" : "text"} fontSize="small">
            {item?.isAccepted ? "check_circle" : "cancel"}
          </Icon>
        </MDBox>
        <MDBox>
          <MDTypography variant="button" color="text" fontWeight="regular">
            {item?.name}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  });

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <MDBox display="flex">
            <MDTypography>
              <Icon color="inherit" fontSize="medium">
                lock_reset
              </Icon>
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium">
              Reset User Password
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <MDButton size="small" aria-label="close" color="error" onClick={onClose}>
            cancel
          </MDButton>
        </MDBox>
      </MDBox>
      <MDBox component="form">
        {message !== "" && (
          <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <MDBox display="flex">
              <MDBox>
                <MDTypography>
                  <Icon color="error" fontSize="medium">
                    error_outline
                  </Icon>
                </MDTypography>{" "}
              </MDBox>
              <MDBox mt={0.5} ml={2}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {message}
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        {isSuccess ? (
          <MDBox textAlign="center">
            <MDTypography
              component={Link}
              to="/auth/login"
              variant="h6"
              color="success"
              fontWeight="large"
              textGradient
            >
              Successfully changed password
            </MDTypography>
          </MDBox>
        ) : (
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  label="New Password"
                  value={password}
                  inputProps={{ type: "password", autoComplete: "" }}
                  onChange={(e) => handleChangePassword(e)}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  value={confirmPasword}
                  label="Confirm New Password"
                  inputProps={{ type: "password", autoComplete: "" }}
                  onChange={(e) => handleChangeConfirmPassword(e)}
                  onKeyPress={handleKeyPress}
                />
              </Grid>
            </Grid>
            <MDBox mt={2} mb={1}>
              <MDTypography variant="h5">Password requirements</MDTypography>
            </MDBox>
            <MDBox mb={1}>
              <MDTypography variant="body2" color="text">
                Please follow this guide for a strong password
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="flex-end"
              flexWrap="wrap"
            >
              <MDBox>{renderPasswordRequirements}</MDBox>
              <MDBox ml="auto">
                <MDButton
                  variant="gradient"
                  color="dark"
                  size="small"
                  fullWidth
                  onClick={handleSave}
                >
                  update password
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      {/* </Card> */}
      {/* <MDSnackbar
        color="error"
        icon="warning"
        title={PASSWORD_CHANGE_FAILED}
        dateTime=""
        content={message}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={PASSWORD_CHANGE_SUCCESS}
        dateTime=""
        content={SIGN_UP_REDIRECT}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      /> */}
    </MDBox>
  );
}

ResetUserPassword.defaultProps = {};

ResetUserPassword.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ResetUserPassword;
