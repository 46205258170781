/* eslint-disable no-debugger */
import axios from "services/https/axios";
import pathUrl from "services/context/pathUrl";
import utils from "services/utils";

const { REFRESH_TOKEN } = pathUrl;
const { getRefreshToken, setAccessToken } = utils;

const useRefreshToken = () => {
  const refresh = async () => {
    // debugger;
    const response = await axios.post(
      REFRESH_TOKEN.api,
      JSON.stringify({ refresh: getRefreshToken() })
    );
    setAccessToken(response.data.access);
    return response.data.access;
  };
  return refresh;
};

export default useRefreshToken;
