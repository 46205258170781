/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Card, Grid } from "@mui/material";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ReportsMgr from "layouts/Reports/ReportsMgr";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const { convertToCurrency } = ReportsMgr;

const styleBotderTopBottom = {
  borderStyle: "solid none solid none",
  borderWidth: "medium",
};

const btmBorder = {
  borderStyle: "none none solid none",
  borderWidth: "2px",
  borderColor: "#667a6b",
};

function AgedReportSummary({ reportsData }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox mt={2}>
      <Card width="100%">
        <MDBox textAlign="right">
          <Grid
            container
            columns={{ xs: 4, sm: 8, md: 12 }}
            sx={{
              paddingLeft: 0.5,
              paddingRight: 0.5,
              ...styleBotderTopBottom,
            }}
          >
            <Grid
              item
              xs={4}
              sm={8}
              md={6}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              padding={2}
            >
              <MDTypography variant="h6" fontWeight="medium">
                Reports Total:
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={8} md={6} textAlign="right" sx={{ backgroundColor: "#f2f0e9" }}>
              {/* 1st row */}
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  padding: 1,
                  // paddingLeft: 0.5,
                  // paddingRight: 0.5,
                  ...btmBorder,
                }}
              >
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Credits
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Under 31
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    31 - 60
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    61 - 90
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Over 90
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Total
                  </MDTypography>
                </Grid>
              </Grid>
              {/* 2nd row */}
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  padding: 1,
                  // paddingLeft: 0.5,
                  // paddingRight: 0.5,
                }}
              >
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    &nbsp;
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {convertToCurrency(reportsData?.reportsTotal?.column_totals?.under_31)}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {convertToCurrency(reportsData?.reportsTotal?.column_totals?.bet_31_to_60)}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {convertToCurrency(reportsData?.reportsTotal?.column_totals?.bet_61_to_90)}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {convertToCurrency(reportsData?.reportsTotal?.column_totals?.over_90)}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    &nbsp;
                  </MDTypography>
                </Grid>
              </Grid>
              {/* 3rd row */}
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  padding: 1,
                  // paddingLeft: 0.5,
                  // paddingRight: 0.5,
                }}
              >
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    ({convertToCurrency(reportsData?.reportsTotal?.column_totals?.credits)})
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={8} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    &nbsp;
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    ({convertToCurrency(reportsData?.reportsTotal?.column_totals?.total)})
                  </MDTypography>
                </Grid>
              </Grid>
              {/* 4th row */}
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  padding: 1,
                  // paddingLeft: 0.5,
                  // paddingRight: 0.5,
                }}
              >
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {reportsData?.reportsTotal?.column_percentages?.credits}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {reportsData?.reportsTotal?.column_percentages?.under_31}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {reportsData?.reportsTotal?.column_percentages?.bet_31_to_60}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {reportsData?.reportsTotal?.column_percentages?.bet_61_to_90}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    {reportsData?.reportsTotal?.column_percentages?.over_90}
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    &nbsp;
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </MDBox>
  );
}

AgedReportSummary.defaultProps = {
  reportsData: [],
};

AgedReportSummary.propTypes = {
  reportsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
});

export default connect(mapStateToProps, null)(AgedReportSummary);
