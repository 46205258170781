import * as React from "react";

import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function DraggableDialog({
  title,
  description,
  buttonDesc,
  openDialog,
  handleClose,
  handleConfirm,
  fullWidth,
  maxWidth,
  showCancelBtn,
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={openDialog}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: "move" }}>
        <MDTypography fontWeight="medium" color="secondary">
          {title}
        </MDTypography>
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          m: "auto",
          width: "fit-content",
        }}
      >
        <MDTypography variant="body2" fontWeight="regular" color="secondary">
          {description}
        </MDTypography>
      </DialogContent>
      <DialogActions>
        {showCancelBtn && (
          <MDButton
            variant="gradient"
            color="error"
            onClick={handleClose}
            // disabled
          >
            Cancel
          </MDButton>
        )}
        <MDButton variant="gradient" color="info" onClick={handleConfirm}>
          {buttonDesc || "Subscribe"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

DraggableDialog.defaultProps = {
  title: "Subscribe",
  buttonDesc: "",
  openDialog: true,
  fullWidth: false,
  maxWidth: "xl",
  showCancelBtn: true,
};

DraggableDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node.isRequired,
  buttonDesc: PropTypes.string,
  maxWidth: PropTypes.string,
  openDialog: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showCancelBtn: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};
