/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Icon, IconButton } from "@mui/material";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";
import CustomerMgr from "layouts/Customers/CustomerMgr";
import CustomModal from "components/CustomModal";
import CustomerNew from "./CustomerModal/CustomerNew";

const { INVENTORY } = InventoryMgr;
const { getSubCategoryList } = SubcategoryMgr;
const {
    httpRequest,
    getNextPage,
    getPrevPage,
    getSort,
    setStorageItem,
    getResponseMsg,
    hasPermission,
    convertToCurrency,
} = CustomerMgr;

function CustomerList({ handleClose, handleItems }) {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const navigate = useNavigate();
    const location = useLocation();

    const [itemList, setItemList] = useState([]);
    const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);
    const [categoryFilterValue, setCategoryFilterValue] = useState(null);
    const [subCategoryFilterValue, setSubCategoryFilterValue] = useState(null);
    const [nextPageLink, setNextPageLink] = useState("");
    const [prevPageLink, setPrevPageLink] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [sortType, setSortType] = useState("asc");
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [menu, setMenu] = useState(null);
    const [showCustomerListModal, setShowCustomerListModal] = useState(false);

    const showItemDetail = (values) => {
        setStorageItem("current_item_detail", values);
        navigate(INVENTORY.DETAIL_ROUTE, { state: { from: location }, replace: true });
    };

    const dataTableData = {
        columns: [
            {
                Header: "customer name",
                accessor: "full_name",
                // type: "detail-link",
                width: "15%",
                headerAlign: "left",
                hasSort: true,
            },
            {
                Header: "company name",
                accessor: "company_name",
                headerAlign: "left",
                width: "15%",
                hasSort: true,
            },
            // { Header: "address", accessor: "address", width: "20%" },
            // { Header: "date of last rental", accessor: "date_of_last_rental", width: "15%" },
            {
                Header: "unpaid balance",
                accessor: "unpaid_balance",
                headerAlign: "left",
                width: "10%",
            },
            {
                Header: "action",
                width: "10%",
                headerAlign: "left",
                Cell: ({ row }) => {
                    const handleAdd = () => {
                        handleItems("set-customer", { customer: row?.original });
                        handleClose();
                    };

                    const status = (
                        <MDBox
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <IconButton
                                size="small"
                                aria-label="edit"
                                color="info"
                                onClick={() => {
                                    handleAdd();
                                }}
                            >
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                        </MDBox>
                    );
                    return status;
                },
            },
        ],
        rows: itemList,
    };

    const processRows = (data = []) => {
        const custlist = [];

        data.map((cust) =>
            custlist.push({
                uuid: cust.uuid,
                identifier: cust.identifier,
                id: cust.identifier,
                full_name: `${cust.first_name} ${cust.last_name}`,
                company_name: cust.company_name || "Individual",
                address: `${cust.address.line_1} ${cust.address.line_2} ${cust.address.city} ${cust.address.state} ${cust.address.country.value} ${cust.address.zip_code}`,
                date_of_last_rental: cust.date_of_last_rental || "-",
                unpaid_balance: `${convertToCurrency(cust.unpaid_balance)}`,
            })
        );

        return custlist;
    };
    const proccessResponse = (response) => {
        if (getResponseMsg(response) === "Success") {
            setItemList(processRows(response?.data?.data?.results));
            setNextPageLink(response.data.data?.next);
            setPrevPageLink(response.data.data?.previous);
            setTotalRows(response.data.data?.count);
        }
    };

    const handleNextPage = async () => {
        setIsLoading(true);
        proccessResponse(await getNextPage(nextPageLink));
        setIsLoading(false);
    };

    const handlePrevPage = async () => {
        setIsLoading(true);
        proccessResponse(await getPrevPage(prevPageLink));
        setIsLoading(false);
    };

    const handleSearchKeyword = async (val) => {
        setSearchKeyword(val);
        setIsLoading(true);
        proccessResponse(
            await httpRequest(
                "customer-list",
                {},
                {
                    searchKeyword: val,
                    entriesPerPage,
                    categoryId: categoryFilterValue?.uuid,
                    subCategoryId: subCategoryFilterValue?.uuid,
                }
            )
        );
        setIsLoading(false);
    };

    const handleSort = async (id) => {
        setIsLoading(true);

        proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

        if (sortType === "asc") {
            setSortType("desc");
        } else {
            setSortType("asc");
        }
        setIsLoading(false);
    };

    const handleChangeEntries = async (val) => {
        setEntriesPerPage(val);
        setIsLoading(true);
        proccessResponse(
            await httpRequest("customer-list", null, { searchKeyword, entriesPerPage: val })
        );
        setIsLoading(false);
    };

    const controllerRequest = new AbortController();

    const loadCustonerList = async () => {
        setIsLoading(true);
        const customerListResponse = await httpRequest(
            "customer-list",
            {},
            { searchKeyword, entriesPerPage }
        );

        proccessResponse(customerListResponse);
        setIsLoading(false);
    };

    useEffect(async () => {
        loadCustonerList();
        return () => {
            controllerRequest.abort();
        };
    }, []);

    const handleOpenCustomerListModal = () => setShowCustomerListModal(true);
    const handleCloseCustomerListModal = () => {
        loadCustonerList();
        setShowCustomerListModal(false);
    };

    return (
        <MDBox>
            {/* {isLoading && <SimpleBackdrop />} */}
            <MDBox
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleOpenCustomerListModal}
                    disabled={!hasPermission("add_customer", permissions?.customers)}
                >
                    new customer
                </MDButton>
                <MDBox>
                    <MDBox>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="dark"
                            onClick={handleClose}
                        >
                            <Icon fontSize="small">close</Icon>
                        </IconButton>
                    </MDBox>
                </MDBox>
            </MDBox>
            <MDBox display="flex">
                <DataTable
                    table={dataTableData}
                    // noEndBorder
                    details={showItemDetail}
                    handleNextPage={handleNextPage}
                    handlePrevPage={handlePrevPage}
                    handleSearch={handleSearchKeyword}
                    handleChangeEntries={handleChangeEntries}
                    handleSort={handleSort}
                    hasNextPage={nextPageLink !== null}
                    hasPrevPage={prevPageLink !== null}
                    totalCount={totalRows}
                    entriesPerPage={{ defaultValue: 10, entries: [10, 25, 50, 100] }}
                    title="Customer List"
                    isLoading={isLoading}
                    canRefresh
                />
            </MDBox>
            {showCustomerListModal && (
                <CustomModal
                    showModal={showCustomerListModal}
                    handleHideModal={handleCloseCustomerListModal}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "auto",
                        width: "80%",
                        // border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "5px",
                        p: 4,
                        overflow: "auto",
                    }}
                >
                    <CustomerNew
                        handleClose={handleCloseCustomerListModal}
                        handleItems={handleItems}
                    />
                </CustomModal>
            )}
        </MDBox>
    );
}

CustomerList.defaultProps = {};

CustomerList.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleItems: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    categoriesListRedux: state?.helper?.categoryList,
});

export default connect(mapStateToProps, null)(CustomerList);
