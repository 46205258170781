/* eslint-disable no-debugger */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Autocomplete, Divider, Grid, Icon, IconButton, Menu, TextField } from "@mui/material";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";
import MDInput from "components/MDInput";
import DraggableDialog from "components/DragableDialog";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDSnackbar from "components/MDSnackbar";

const {
  INVENTORY,
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getResponseMsg,
  getStorageItem,
} = WarehouseMgr;

const { getSubCategoryList } = SubcategoryMgr;

function SubstituteSubitemListModal({
  itemUuid,
  categoriesListRedux,
  handleClose,
  setCollectionItems,
  collectionItems,
  subitemData,
  handleAddItem,
  originalSubItemData,
}) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const stagingDetails = JSON.parse(getStorageItem("current_staging_detail"));

  const [itemList, setItemList] = useState([]);
  const [itemListCopy, setItemListCopy] = useState([]);
  const [addedItems, setAddedItems] = useState([]);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);
  const [categoryFilterValue, setCategoryFilterValue] = useState(null);
  const [subCategoryFilterValue, setSubCategoryFilterValue] = useState(null);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [menu, setMenu] = useState(null);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to Deactivate User.");
  const [errorSB, setErrorSB] = useState(false);

  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const handleCloseWarningDialog = () => setShowWarningDialog(false);

  const closeMenu = () => setMenu(null);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const showItemDetail = (values) => {
    setStorageItem("current_item_detail", values);
    navigate(INVENTORY.DETAIL_ROUTE, { state: { from: location }, replace: true });
  };

  const filterAddItem = (data) => {
    const items = [];
    let newData = itemList;
    for (let i = 0; i < newData.length; i += 1) {
      for (let j = 0; j < data.length; j += 1) {
        newData = newData.filter((item) => item?.uuid !== data[j]?.uuid);
      }
    }

    newData.map((item) =>
      items.push({
        uuid: item.uuid,
        name: item.name,
        price: item?.price,
        local_qty: item?.local_qty,
        daily: `$${item?.price?.daily}`,
        description: item.description,
        item_type: item.item_type,
        owned: item.local_qty?.owned,
        available: item.local_qty?.available,
        on_rent: item.local_qty?.on_rent,
        in_repair: item.local_qty?.in_repair,
        global_available_qty: item.global_available_qty,
        category: item?.category,
        subcategory: item?.subcategory,

        quantity: item?.quantity || 1,
      })
    );

    return items;
  };

  const processRows = (data = []) => {
    const items = [];
    // let newData = data;
    // // console.log("called");
    // for (let i = 0; i < data.length; i += 1) {
    //   for (let j = 0; j < collectionItems.length; j += 1) {
    //     newData = newData.filter((item) => item?.uuid !== collectionItems[j]?.uuid);
    //   }
    // }

    // newData = newData.filter((item) => item?.uuid !== itemDetail?.uuid);

    data.map((item) =>
      items.push({
        ...item,
        status_name: item?.status?.name,
        status_value: item?.status?.value,
      })
    );

    return items;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setItemList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const dataTableData = {
    columns: [
      {
        Header: "Barcode number",
        accessor: "barcode_id",
        width: "15%",
        // hasSort: true,
        headerAlign: "left",
      },
      {
        Header: "Owned",
        accessor: "is_owned",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox display="flex" justifyContent="space-between">
              <IconButton size="small" aria-label="edit" disabled>
                <Icon color={value <= 0 ? "error" : "success"} fontSize="medium">
                  square
                </Icon>
              </IconButton>
              <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                {value <= 0 ? "Consigned" : "Owned"}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      { Header: "status", accessor: "status_name", headerAlign: "left", width: "15%" },
      {
        Header: "action",
        headerAlign: "left",
        width: "10%",
        Cell: ({ row }) => {
          let addList = [];
          const handleAdd = async () => {
            // console.log(
            //   {
            //     order: stagingDetails?.uuid || "",
            //     order_item: subitemData?.uuid || "",
            //     subitem: originalSubItemData?.uuid || "",
            //     substitute_subitem: row?.original?.uuid || "",
            //   },
            //   originalSubItemData
            // );

            // START HERE
            const response = await httpRequest("stage-item", {
              order: stagingDetails?.uuid || "",
              order_item: subitemData?.uuid || "",
              subitem: row?.original?.uuid || "",
              substitute_subitem: originalSubItemData?.uuid || "",
            });

            if (getResponseMsg(response) === "Success") {
              handleAddItem("Success");
            } else {
              let responseErrMsg = "";
              Object.entries(response?.data).map((item) => {
                responseErrMsg = `${item[1]}`;
                return 0;
              });
              setErrMsg(responseErrMsg || "Failed to stage item.");
              openErrorSB();
            }
          };

          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <IconButton
                size="small"
                aria-label="edit"
                color="info"
                onClick={() => {
                  handleAdd();
                }}
              >
                <Icon fontSize="small">swap_horiz</Icon>
              </IconButton>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList,
  };

  const dataTableDataMobile = {
    columns: [
      {
        Header: "Barcode number",
        accessor: "barcode_id",
        width: "10%",
        // hasSort: true,
        headerAlign: "left",
        Cell: ({ value, row }) => {
          const status = (
            <MDBox width="10rem">
              <Grid container spacing={0}>
                <Grid container item>
                  <MDTypography variant="button" fontWeight="medium" color="text">
                    {row?.original?.barcode_id}&nbsp;
                  </MDTypography>
                </Grid>

                <Grid container item>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color={row?.original?.is_owned <= 0 ? "error" : "success"}
                  >
                    {row?.original?.is_owned <= 0 ? "Consigned" : "Owned"}
                  </MDTypography>
                </Grid>

                <Grid container item>
                  {row?.original?.status_name}
                </Grid>
              </Grid>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "action",
        headerAlign: "left",
        width: "5%",
        Cell: ({ row }) => {
          let addList = [];
          const handleAdd = async () => {
            // console.log(
            //   {
            //     order: stagingDetails?.uuid || "",
            //     order_item: subitemData?.uuid || "",
            //     subitem: originalSubItemData?.uuid || "",
            //     substitute_subitem: row?.original?.uuid || "",
            //   },
            //   originalSubItemData
            // );

            // START HERE
            const response = await httpRequest("stage-item", {
              order: stagingDetails?.uuid || "",
              order_item: subitemData?.uuid || "",
              subitem: row?.original?.uuid || "",
              substitute_subitem: originalSubItemData?.uuid || "",
            });
            if (getResponseMsg(response) === "Success") {
              handleAddItem();
            } else {
              let responseErrMsg = "";
              Object.entries(response?.data).map((item) => {
                responseErrMsg = `${item[1]}`;
                return 0;
              });
              setErrMsg(responseErrMsg || "Failed to stage item.");
              openErrorSB();
            }
          };

          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <IconButton
                size="small"
                aria-label="edit"
                color="info"
                onClick={() => {
                  handleAdd();
                }}
              >
                <Icon fontSize="small">swap_horiz</Icon>
              </IconButton>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList,
  };

  const handleOnChangeCategoryFilter = async (e, val) => {
    if (val !== null && val?.uuid !== "") {
      setIsFilterLoading(true);
      setCategoryFilterValue(val);
      const response = await getSubCategoryList(val?.uuid, "", 0);
      setSubCategoryListOptions(response?.data?.data);
      setIsFilterLoading(false);
    }

    setSubCategoryFilterValue(null);
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest(
        "single-subitem-list",
        {},
        { uuid: itemUuid, searchKeyword: val, entriesPerPage }
      )
    );
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("single-subitem-list", null, { searchKeyword, entriesPerPage: val })
    );
    setIsLoading(false);
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);
    proccessResponse(
      await httpRequest(
        "single-subitem-list",
        {},
        { uuid: itemUuid, searchKeyword, entriesPerPage }
      )
    );
    setIsLoading(false);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox display="flex" justifyContent={isMobile ? "space-between" : "flex-end"}>
        <MDBox>
          {isMobile && (
            <MDTypography variant="button" fontWeight="medium" color="dark">
              Item List
            </MDTypography>
          )}
        </MDBox>
        <MDBox>
          <MDBox>
            <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
              <Icon fontSize="small">close</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox display="flex">
        <DataTable
          table={isMobile ? dataTableDataMobile : dataTableData}
          // noEndBorder
          details={showItemDetail}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handleSearch={handleSearchKeyword}
          handleChangeEntries={handleChangeEntries}
          handleSort={handleSort}
          hasNextPage={nextPageLink !== null}
          hasPrevPage={prevPageLink !== null}
          totalCount={totalRows}
          entriesPerPage={{ defaultValue: 10, entries: [10, 25, 50, 100] }}
          title={isMobile ? "" : "Item List"}
          canScanQr
          isLoading={isLoading}
        />
      </MDBox>

      {showWarningDialog && (
        <DraggableDialog
          title="Insufficient Available Items"
          description="The current available number is less than the desired quantity. You will need to sub-rent to cover the insufficient items."
          buttonDesc="Confirm"
          openDialog={showWarningDialog}
          handleClose={handleCloseWarningDialog}
          handleConfirm={handleCloseWarningDialog}
          fullWidth={false}
          showCancelBtn={false}
        />
      )}

      <MDBox sx={{ position: "fixed", bottom: "10px", right: "16px", fontSize: "18px" }}>
        <MDSnackbar
          color="error"
          icon="warning"
          title="Item Creation"
          dateTime=""
          content={errMsg}
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          bgWhite={false}
        />
      </MDBox>
    </MDBox>
  );
}

SubstituteSubitemListModal.defaultProps = {
  categoriesListRedux: [{}],
  collectionItems: [{}],
  subitemData: {},
  originalSubItemData: {},
  itemUuid: "",
  setCollectionItems: () => {},
};

SubstituteSubitemListModal.propTypes = {
  itemUuid: PropTypes.string,
  categoriesListRedux: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  subitemData: PropTypes.objectOf(PropTypes.any),
  originalSubItemData: PropTypes.objectOf(PropTypes.any),
  collectionItems: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  handleClose: PropTypes.func.isRequired,
  handleAddItem: PropTypes.func.isRequired,
  setCollectionItems: PropTypes.func,
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
});

export default connect(mapStateToProps, null)(SubstituteSubitemListModal);
