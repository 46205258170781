/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "components/Tables/DataTable";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import { CircularProgress, Icon, IconButton } from "@mui/material";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";

const {
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  getStorageItem,
  getResponseMsg,
  convertTimeZoneFormatDateTime,
  setStorageItem,
  ORDERS,
} = WarehouseMgr;

function RentalHitory({ onClose, userDetail, subitemData }) {
  const location = useLocation();
  const navigate = useNavigate();

  const itemDetail = JSON.parse(getStorageItem("current_qc_detail"));

  const [successSB, setSuccessSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPayInvoice, setShowPayInvoice] = useState(false);

  const [transactionList, setTransactionList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [prevPageLink, setPrevPageLink] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [sortType, setSortType] = useState("asc");
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenDialog = () => setShowPayInvoice(true);
  const handleCloseDialog = () => setShowPayInvoice(false);

  const showOrderDetail = (values) => {
    // console.log(values);
    setStorageItem("current_order_detail", values);
    navigate(ORDERS.DETAIL_ROUTE, { state: { from: location }, replace: true });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Order number",
        accessor: "order_number",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const status = (
            <MDTypography
              variant="button"
              color="text"
              onClick={() => showOrderDetail(row?.original)}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {/* {row.values.name} */}
                {value}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      { Header: "Customer name", accessor: "customer_name", headerAlign: "left", width: "20%" },
      {
        Header: "Date of rental (start date)",
        accessor: "start_date",
        headerAlign: "left",
        width: "15%",
        Cell: ({ row, value }) => {
          const status = (
            <MDTypography variant="button" fontWeight="regular">
              {convertTimeZoneFormatDateTime(
                value,
                userDetail?.location?.timezone?.name,
                userDetail?.location?.timezone?.abbreviation,
                "LLL"
              )}
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "Date returned",
        accessor: "return_date",
        headerAlign: "left",
        width: "15%",
        Cell: ({ row, value }) => {
          const status = (
            <MDTypography variant="button" fontWeight="regular">
              {convertTimeZoneFormatDateTime(
                value,
                userDetail?.location?.timezone?.name,
                userDetail?.location?.timezone?.abbreviation,
                "LLL"
              )}
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "Payment status",
        accessor: "payment_status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          let status;

          if (value === "paid") {
            status = <StatusCell icon="done" color="success" status="Paid" disabled />;
          } else if (value === "refunded") {
            status = <StatusCell icon="replay" color="dark" status="Refunded" disabled />;
          } else {
            status = <StatusCell icon="close" color="error" status="Unpaid" disabled />;
          }

          return status;
        },
      },
    ],
    rows: transactionList || [],
  };

  const dataTableDataMobile = {
    columns: [
      {
        Header: "Info",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const textColor = () => {
            if (row?.original?.action?.value === "change_in_condition") {
              return "error";
            }
            if (row?.original?.action?.value === "no_change_in_condition") {
              return "success";
            }
            return "dark";
          };
          const status = (
            <MDBox
            // onClick={() => console.log(row?.original?.date)}
            >
              <MDBox>
                <MDTypography
                  variant="button"
                  color="text"
                  onClick={() => showOrderDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    disabled
                  >
                    {/* {row.values.name} */}
                    {/* {formatDate(row?.original?.date, "LLL")} */}
                    {row?.original?.order_number}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular">
                  {row?.original?.customer_name}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color={textColor()}>
                  {convertTimeZoneFormatDateTime(
                    row?.original?.start_date,
                    userDetail?.location?.timezone?.name,
                    userDetail?.location?.timezone?.abbreviation,
                    "LLL"
                  )}
                  -
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color={textColor()}>
                  {convertTimeZoneFormatDateTime(
                    row?.original?.return_date,
                    userDetail?.location?.timezone?.name,
                    userDetail?.location?.timezone?.abbreviation,
                    "LLL"
                  )}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color={textColor()}>
                  {row?.original?.payment_status === "paid" && (
                    <StatusCell icon="done" color="success" status="Paid" disabled />
                  )}
                  {row?.original?.payment_status === "refunded" && (
                    <StatusCell icon="replay" color="dark" status="Refunded" disabled />
                  )}
                  {row?.original?.payment_status !== "paid" &&
                    row?.original?.payment_status !== "refunded" && (
                      <StatusCell icon="close" color="error" status="Unpaid" disabled />
                    )}
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },

      //   {
      //     Header: "Created By",
      //     headerAlign: "left",
      //     width: "15%",
      //     Cell: ({ row, value }) => {
      //       const status = (
      //         <MDBox>
      //           <MDTypography variant="button" fontWeight="regular">
      //             {row?.original?.created_by}
      //           </MDTypography>
      //         </MDBox>
      //       );

      //       return status;
      //     },
      //   },
    ],
    rows: transactionList || [],
  };

  // const processRows = (data = []) => {
  //   const rentalHistory = [];

  //   data.map((history) =>
  //     rentalHistory.push({
  //       uuid: history?.uuid,
  //       identifier: history.identifier,
  //       customer_name: history.customer.name,
  //       start_date: convertTimeZoneFormatDateTime(
  //         history.start_date,
  //         userDetail?.location?.timezone?.name,
  //         userDetail?.location?.timezone?.abbreviation,
  //         "lll"
  //       ),
  //       return_date: convertTimeZoneFormatDateTime(
  //         history.return_date,
  //         userDetail?.location?.timezone?.name,
  //         userDetail?.location?.timezone?.abbreviation,
  //         "lll"
  //       ),
  //       payment_status: history?.payment_status?.name || "Unpaid",
  //     })
  //   );

  //   return rentalHistory;
  // };

  const loadList = async (searchKeywordVal = "", entriesPerPageVal = 25) =>
    // console.log(statusFilterList);
    httpRequest("rental-history", null, {
      uuid: itemDetail?.uuid,
      entriesPerPage: entriesPerPageVal,
    });

  const processRows = (data = []) => {
    const rentalHistory = [];

    data.map((history) =>
      rentalHistory.push({ ...history, customer_name: history?.customer?.name })
    );

    return rentalHistory;
  };

  const getTransactionList = async () => {
    setIsLoading(true);

    const response = await httpRequest("rental-history", null, {
      uuid: itemDetail?.uuid,
    });

    // debugger;
    if (getResponseMsg(response) === "Success") {
      setTransactionList(processRows(response?.data?.data?.results));
    }

    setIsLoading(false);
    return 0;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setTransactionList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(await loadList(val, entriesPerPage));
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(await loadList(searchKeyword, val));
    setIsLoading(false);
  };

  // useEffect(() => {
  //   getTransactionList();
  // }, []);

  useEffect(async () => {
    // getTransactionList();

    setIsLoading(true);
    const qcListResponse = await loadList(searchKeyword, entriesPerPage);
    proccessResponse(qcListResponse);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Rental History
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </MDBox>
      ) : (
        <DataTable
          table={!isMobile ? dataTableData : dataTableDataMobile}
          noEndBorder
          // details={showItemDetail}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          // handleSearch={handleSearchKeyword}
          // popupDetailScan={handleSearchBarcodePopUpDetail}
          handleChangeEntries={handleChangeEntries}
          handleSort={handleSort}
          hasNextPage={nextPageLink !== null}
          hasPrevPage={prevPageLink !== null}
          totalCount={totalRows}
          keyPressSearch
          // canScanQr
          isMobile={isMobile}
          canRefresh={false}
          canSearch={false}
          entriesPerPage={{
            show: true,
            defaultValue: entriesPerPage,
            entries: [10, 25, 50, 100],
          }}
          // title="Quality-Check"
          isLoading={isLoading}
        />
      )}
    </MDBox>
  );
}
RentalHitory.defaultProps = {
  userDetail: {},
  subitemData: {},
};

RentalHitory.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
  subitemData: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(RentalHitory);
