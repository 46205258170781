import checkout from "layouts/Order/new-order/schemas/form";
import OrderMgr from "layouts/Order/OrderMgr";

const { getDateToday, addDateFormatted } = OrderMgr;

const {
  formField: {
    customerName,
    pickupDate,
    pickupTime,
    billingStartDate,
    billingStartTime,
    billingEndDate,
    billingEndTime,
    returnByDate,
    returnByTime,
    orderDescription,
    billDays,
    defaultDiscount,
    rentalAgentAssigned,
    depositAmount,
    totalCostOrder,
    taxRate,
    discountTotal,
    invoiceTotal,
    collections,
  },
} = checkout;

const initialValues = {
  [customerName.name]: undefined,
  [pickupDate.name]: getDateToday(),
  [pickupTime.name]: "14:00",
  [billingStartDate.name]: getDateToday(),
  [billingStartTime.name]: "09:00",
  [billingEndDate.name]: getDateToday(),
  [billingEndTime.name]: "16:00",
  [returnByDate.name]: addDateFormatted(getDateToday(), 1, "days", "YYYY-MM-DD"),
  [returnByTime.name]: "11:00",
  [orderDescription.name]: "",
  [billDays.name]: 1,
  [defaultDiscount.name]: 0,
  [rentalAgentAssigned.name]: "",
  [depositAmount.name]: 0,
  [totalCostOrder.name]: "",
  [taxRate.name]: "",
  [discountTotal.name]: "",
  [invoiceTotal.name]: "",
  [collections.name]: [],
};

export default initialValues;
