/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect, useRef, useCallback } from "react";
import SignatureCanvas from "react-signature-canvas";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import { Autocomplete, CardMedia, Icon, IconButton } from "@mui/material";
import Webcam from "react-webcam";
import MDInput from "components/MDInput";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

const { getStorageItem, httpRequest, getResponseMsg } = WarehouseMgr;

function Photo({ handleCloseModal, changeCamera, facingModeValue, photoType }) {
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [imgSrc, setImgSrc] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [cameraView, setCameraView] = useState({ name: "Front", value: "user" });

  const facingModeOptions = [
    { name: "Front", value: "user" },
    { name: "Back", value: "environment" },
  ];

  const webcamRef = useRef(null);

  const videoConstraints = {
    width: !isMobile ? 1280 : 480,
    height: !isMobile ? 720 : 360,
    facingMode: cameraView?.value || "environment",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef]);

  const clear = () => {
    setImgSrc("");
  };

  const save = async () => {
    setLoading(true);

    // console.log(imgSrc);
    // START HERE
    const body = new FormData();

    // body.append("attachment_type", photoType);
    body.append("attachment", imgSrc);
    body.append("action", "capture");

    const responseDetails = await httpRequest("upload-attachment-qc", body, {
      uuid: qcDetails?.uuid,
    });
    if (getResponseMsg(responseDetails) === "Success") {
      handleCloseModal("success", "success");
    } else {
      handleCloseModal("failed");
    }

    handleCloseModal("");
    setLoading(false);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              {!isMobile ? `Capture photo:` : "Take Photo: "}
            </MDTypography>
          </MDBox>
          <MDBox mx={1}>
            <MDButton color="error" size="small" onClick={handleCloseModal}>
              close
            </MDButton>
          </MDBox>
        </MDBox>

        {imgSrc !== "" ? (
          <MDBox
            sx={{ marginTop: 2 }}
            height={!isMobile ? "100%" : "60%"}
            width={!isMobile ? "100%" : 300}
          >
            <MDBox
              flexDirection="column"
              display="center"
              justifyContent="center"
              direction="column"
              alignItems={{ xs: "flex-start", sm: "center" }}
              textAlign="center"
            >
              <img src={imgSrc} alt="signature" />
            </MDBox>
            <MDBox display="flex" justifyContent="flex-end">
              <MDBox mr={1}>
                <MDButton color="error" size={isMobile ? "small" : "medium"} onClick={clear}>
                  Re-capture
                </MDButton>
              </MDBox>
              <MDBox>
                <MDButton color="info" size={isMobile ? "small" : "medium"} onClick={save}>
                  save
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        ) : (
          <MDBox sx={{ marginTop: 2 }}>
            {isMobile && (
              <Autocomplete
                disablePortal
                value={cameraView || {}}
                options={facingModeOptions || []}
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                onChange={(event, newValue) => {
                  setCameraView(newValue);
                }}
                renderInput={(params) => <MDInput {...params} label="Choose camera to use" />}
              />
            )}
            <Webcam
              audio={false}
              height={!isMobile ? "30%" : "60%"}
              width={!isMobile ? "100%" : 300}
              // height={180}
              // width={300}
              screenshotFormat="image/jpeg"
              ref={webcamRef}
              videoConstraints={videoConstraints}
            >
              {() => (
                <MDBox mt={1} display="flex" justifyContent="flex-end">
                  <MDBox ml={1}>
                    <MDButton
                      color="success"
                      size={isMobile ? "small" : "medium"}
                      onClick={capture}
                    >
                      capture
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
            </Webcam>
          </MDBox>
        )}

        {/* <MDBox
          flexDirection="column"
          display="center"
          justifyContent="center"
          direction="column"
          alignItems={{ xs: "flex-start", sm: "center" }}
          textAlign="center"
        >
          <img src={imgSrc} alt="signature" />
        </MDBox> */}
      </MDBox>
    </MDBox>
  );
}

Photo.defaultProps = {
  // userDetail: {},
  facingModeValue: {},
  changeCamera: () => {},
  photoType: "",
};

Photo.propTypes = {
  // userDetail: PropTypes.objectOf(PropTypes.any),
  handleCloseModal: PropTypes.func.isRequired,
  changeCamera: PropTypes.func,
  photoType: PropTypes.string,
  facingModeValue: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(Photo);
