import React, { useState, useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// import * as Sentry from "@sentry/react";

// react-redux
import { useSelector } from "react-redux";

// @mui material components
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import Sidenav from "examples/Sidenav";
import Configurator from "components/Configurator";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// Images
import brandWhite from "assets/images/reelmen/text white.png";
import brandDark from "assets/images/reelmen/text black.png";

import subbrand from "assets/images/rocket/rocket-rms.svg";

// Providers
import RequireAuth from "services/providers/RequireAuth";
// import PersistLogin from "services/providers/PersistentLogin";

// Services
import pathUrl from "services/context/pathUrl";
import utils from "services/utils";

const { DASHBOARD } = pathUrl;
const { getRoles, hasPermission } = utils;

function App() {
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { pathname } = useLocation();

    const [renderRoutes, setRenderRoutes] = useState([]);
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);

    //Role is technically now changed to group, but some parts of the code still use role
    const role = useSelector((state) => getRoles(state?.authentication?.userDetail?.group?.value));

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute("dir", direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;

        setRenderRoutes(
            routes.filter(
                (route) =>
                    (role && route.accessRole.indexOf(role) > -1) ||
                    hasPermission(route.accessCode, permissions?.customers) ||
                    hasPermission(route.accessCode, permissions?.inventory) ||
                    hasPermission(route.accessCode, permissions?.orders) ||
                    hasPermission(route.accessCode, permissions?.payments) ||
                    hasPermission(route.accessCode, permissions?.users)
            )
        );
    }, [pathname, role]);

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route && !route.requireAuth) {
                return <Route exact path={route.route} element={route.component} key={route.key} />;
            }

            if (route.route && route.requireAuth) {
                return (
                    // <Route element={<PersistLogin />}>
                    <Route
                        element={<RequireAuth allowedRoles={route.accessRole} />}
                        key={route.key}
                    >
                        <Route exact path={route.route} element={route.component} key={route.key} />
                    </Route>
                    // </Route>
                );
            }

            return null;
        });

    // eslint-disable-next-line no-unused-vars
    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: "pointer" }}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={darkMode ? themeDark : theme}>
                <CssBaseline />
                {layout === "dashboard" && pathname !== "/payment-success/" && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={
                                (transparentSidenav && !darkMode) || whiteSidenav
                                    ? brandDark
                                    : brandWhite
                            }
                            subbrand={subbrand}
                            brandName=""
                            routes={renderRoutes}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator />
                        {/* {configsButton} */}
                    </>
                )}
                {layout === "vr" && <Configurator />}
                <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to={DASHBOARD.route} />} />
                </Routes>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

// export default Sentry.withProfiler(App);
export default App;
