const form = {
  formId: "new-item-form",
  formField: {
    itemName: {
      name: "item_name",
      label: "Item Name *",
      type: "text",
      errorMsg: "Item name is required.",
    },
    itemDescription: {
      name: "item_description",
      label: "Item Description *",
      type: "text",
      errorMsg: "Item Description is required.",
    },
    itemType: {
      name: "item_type",
      label: "Item Type *",
      type: "text",
      errorMsg: "Item Type is required.",
    },
    itemCategory: {
      name: "item_category",
      label: "Item Category *",
      type: "text",
      errorMsg: "Item Category is required.",
    },
    itemSubCategory: {
      name: "item_sub_category",
      label: "Item Sub-Category *",
      type: "text",
      errorMsg: "Item Sub-Category is required.",
    },
    consigner: {
      name: "consigner",
      label: "Item Consigner *",
      type: "text",
      errorMsg: "Item Consigner is required.",
    },
    dailyPrice: {
      name: "daily_price",
      label: "Daily Price *",
      type: "number",
      errorMsg: "Daily Price is required.",
    },
    weeklyPrice: {
      name: "weekly_price",
      label: "Weekly Price *",
      type: "number",
      errorMsg: "Weekly Price is required.",
    },
    monthlyPrice: {
      name: "monthly_price",
      label: "Monthly Price *",
      type: "number",
      errorMsg: "Monthly Price is required.",
    },
    replacementValue: {
      name: "replacement_value",
      label: "Replacement Value *",
      type: "number",
      errorMsg: "Replacement Value is required.",
    },
    qcRequired: {
      name: "is_qc_required",
      label: "QC Required *",
    },
    isOwned: {
      name: "is_owned",
      label: "Is Owned *",
    },
    noBarcodeSerial: {
      name: "no_barcode_serial",
      label: "No Barcode Serial *",
    },
    notes: {
      name: "notes",
      label: "Notes",
      type: "text",
    },
    manufacturer: {
      name: "manufacturer",
      label: "Manufacturer",
      type: "text",
    },
    modelNumber: {
      name: "model_number",
      label: "Model Number",
      type: "text",
    },
    subitems: {
      name: "subitems",
      label: "Sub-Items",
    },
    collections: {
      name: "collections",
      label: "Items",
    },
    warehouse: {
      name: "warehouse",
      label: "Warehouse",
      type: "text",
    },
  },
};

export default form;
