import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import style from "services/context/style";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";

const { postRequest, getRequest, patchRequest, putRequest } = HTTPSServices;

const { fullXs, halfXs, fullSm, halfSm, spacing, flex, flexEnd, spaceBetween, h5, padding } = style;
const { INVENTORY, ORDERS, _DASHBOARD, WAREHOUSE_QUALITY_CHECK } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  addDate,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  changeTimeZone,
  convertTimeZoneFormatDateTime,
  jsonToString,
  addDateFormatted,
  hasPermission,
  convertToCurrency,
  ellipsis,
} = utils;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  switch (action) {
    case "create-item":
      response = await postRequest(INVENTORY.API, body);
      break;

    case "update-item":
      response = await putRequest(`${INVENTORY.API}${rest?.uuid}/`, body);
      break;

    case "update-sub-item":
      response = await putRequest(
        `${INVENTORY.API}${rest?.uuid}/subitems/${rest?.subitem_uuid}`,
        body
      );
      break;

    case "set-status-retired":
      response = await patchRequest(
        `${INVENTORY.API}${rest?.uuid}/subitems/${rest?.subitem_uuid}`,
        body
      );
      break;

    case "inventory-list":
      response = await getRequest(
        `${INVENTORY.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&category=${rest?.categoryId || ""}&subcategory=${rest?.subCategoryId || ""}`
      );
      break;

    case "inventory-list-no-pagination":
      response = await getRequest(
        `${INVENTORY.API}?search=${rest?.searchKeyword || ""}&page_size=0&category=${
          rest?.categoryId || ""
        }&subcategory=${rest?.subCategoryId || ""}`
      );
      break;

    case "inventory-detail":
      response = await getRequest(`${INVENTORY.API}${rest?.uuid}/`);
      break;

    case "inventory-availability":
      response = await getRequest(
        `${INVENTORY.API}${rest?.uuid}/availability-calendar/?start_date=${rest?.date}`,
        body
      );
      break;

    case "inventory-soft-delete":
      response = await patchRequest(`${INVENTORY.API}${rest?.uuid}/`);
      break;

    case "rental-history":
      response = await getRequest(
        `${INVENTORY.API}${rest?.uuid}/subitems/${rest?.subitemUuid}/rental-history/`
      );
      break;

    case "service-history":
      response = await getRequest(
        `${INVENTORY.API}${rest?.uuid}/subitems/${rest?.subitemUuid}/service-histories/?page_size=${
          rest?.entriesPerPage || ""
        }`
      );
      break;

    case "change-warehouse":
      response = await patchRequest(
        `${INVENTORY.API}${rest?.uuid}/subitems/${rest?.subitemUuid}/warehouse/`,
        body
      );
      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (sortType = "asc", id = "name", searchKeyword = "", entriesPerPage = 25) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${INVENTORY.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${id}`
    );
  } else {
    response = await getRequest(
      `${INVENTORY.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}`
    );
  }

  return response;
};

const InventoryMgr = {
  INVENTORY,
  fullXs,
  halfXs,
  fullSm,
  halfSm,
  spacing,
  flex,
  flexEnd,
  spaceBetween,
  h5,
  padding,
  getNextPage,
  getPrevPage,
  getSort,
  addDate,
  httpRequest,
  setStorageItem,
  getStorageItem,
  RESPONSE_SUCCESS,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  changeTimeZone,
  convertTimeZoneFormatDateTime,
  ORDERS,
  jsonToString,
  addDateFormatted,
  hasPermission,
  _DASHBOARD,
  convertToCurrency,
  ellipsis,
  WAREHOUSE_QUALITY_CHECK,
};

export default InventoryMgr;
