import HTTPSServices from "../../services/https/HTTPSServices";
import utils from "../../services/utils";
import pathUrl from "../../services/context/pathUrl";
import { RESPONSE_SUCCESS } from "../../services/context/response";
import style from "../../services/context/style";

const { getRequest } = HTTPSServices;

const { fullXs, halfXs, fullSm, halfSm, spacing, flex, flexEnd, spaceBetween, h5, padding } = style;
const { ORDERS, CUSTOMERS, _DASHBOARD, USER_DETAILS } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  addDate,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  getCurrentTime,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  addDateFormatted,
  ellipsis,
  hasPermission,
} = utils;

const httpRequest = async (action = "", rest = {}) => {
  let response = null;
  switch (action) {
    case "get-notification":
      response = await getRequest(
        `${_DASHBOARD.NOTIFICATION}?page_size=${rest?.entriesPerPage || ""}`
      );
      break;

    case "get-todays-pickup":
      response = await getRequest(
        `${ORDERS.API}?page_size=${rest?.entriesPerPage || ""}&pickup_date=${
          getDateToday() || ""
        }&location=${rest?.locationId || ""}`
      );
      break;

    case "get-tomorrows-pickup":
      response = await getRequest(
        `${ORDERS.API}?page_size=${rest?.entriesPerPage || ""}&pickup_date=${
          addDateFormatted(getDateToday(), 1, "day", "YYYY-MM-DD") || ""
        }&location=${rest?.locationId || ""}`
      );
      break;

    case "get-todays-return":
      response = await getRequest(
        `${ORDERS.API}?page_size=${rest?.entriesPerPage || ""}&return_date=${
          getDateToday() || ""
        }&location=${rest?.locationId || ""}`
      );
      break;

    case "get-current-user-detail":
      response = await getRequest(USER_DETAILS.API);
      break;

    case "orders-detail":
      response = await getRequest(`${ORDERS.API}${rest?.uuid}/?notif_read=${rest?.id || ""}`);
      break;

    case "calendar-data":
      response = await getRequest(
        `${ORDERS.API}calendar/?start_date=${rest?.startDate || ""}&location=${
          rest?.locationId || ""
        }`
      );
      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (sortType = "asc", id = "name", searchKeyword = "", entriesPerPage = 25) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${ORDERS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${id}`
    );
  } else {
    response = await getRequest(
      `${ORDERS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}`
    );
  }

  return response;
};

const DashboardMgr = {
  ORDERS,
  fullXs,
  halfXs,
  fullSm,
  halfSm,
  spacing,
  flex,
  flexEnd,
  spaceBetween,
  h5,
  padding,
  getNextPage,
  getPrevPage,
  getSort,
  addDate,
  httpRequest,
  setStorageItem,
  getStorageItem,
  RESPONSE_SUCCESS,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  getCurrentTime,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  CUSTOMERS,
  addDateFormatted,
  _DASHBOARD,
  ellipsis,
  hasPermission,
};

export default DashboardMgr;
