/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useMemo, useEffect, useState } from "react";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";

import InventoryMgr from "layouts/Inventory/InventoryMgr";
import MDButton from "components/MDButton";
import CustomModal from "components/CustomModal";
import MDSnackbar from "components/MDSnackbar";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import SubstituteSubitemListModal from "../Modals/SubstituteSubitemListModal";

const { getStorageItem } = InventoryMgr;
const { httpRequest, getResponseMsg } = WarehouseMgr;

function Row(props) {
  const { row, loadDetails, handleOpenItemlistModal, tabName } = props;

  const stagingDetails = JSON.parse(getStorageItem("current_staging_detail"));
  const [openSingleItem, setOpenSingleItem] = useState(false);
  const [openKitItem, setOpenKitItem] = useState(false);
  const [showItemList, setShowItemList] = useState(false);
  const [subitemDetail, setSubitemDetail] = useState({});
  const [originalSubItemData, setOriginalSubItemData] = useState({});
  const [showQrCodeScan, setShowQrCodeScan] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [fakeResult, setFakeresult] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [itemAvailableFlag, setItemAvailableFlag] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Failed");

  const [notifTitle, setNotifTitle] = useState("Item Substitution");

  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const closeErrorSB = () => setErrorSB(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const openSuccessSB = () => setSuccessSB(true);

  const handleOpenItemlistModalSubstitute = (subItemDetail = {}, originalSubItem = {}) => {
    setSubitemDetail(subItemDetail);
    setOriginalSubItemData(originalSubItem);
    setShowItemList(true);
  };

  const handleCloseItemlistModal = () => {
    setShowItemList(false);
  };

  const handleSwapItem = (action = "") => {
    switch (action) {
      case "Success":
        openSuccessSB();
        setSuccessMsg("Substituted item successfully.");
        break;
      default:
        break;
    }

    setShowItemList(false);
    loadDetails();
  };

  const handleOpenScanBarcodeModal = (subItemDetail = {}, originalSubItem = {}) => {
    // console.log(data);
    // setSubitemDetail(value);
    setSubitemDetail(subItemDetail);
    setOriginalSubItemData(originalSubItem);
    setShowQrCodeScan(true);
  };

  const handleCloseScanBarcodeModal = () => {
    setShowQrCodeScan(false);
    setSearch("");
    setFakeresult("");
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const substituteItem = async (value = "") => {
    setIsLoading(true);
    // console.log({
    //   order: stagingDetails?.uuid || "",
    //   barcode_id: value || "",
    //   sub_barcode_id: originalSubItemData?.barcode_id || "",
    // });

    const responseStaging = await httpRequest("stage-item", {
      order: stagingDetails?.uuid || "",
      barcode_id: value || "",
      substitute: originalSubItemData?.barcode_id || "",
    });

    if (getResponseMsg(responseStaging) === "Success") {
      setIsLoading(false);
      setSearch("");
      openSuccessSB();
      setSuccessMsg("Substituted item successfully.");
      handleCloseScanBarcodeModal();
      handleSwapItem(responseStaging.data.data);
    } else {
      let responseErrMsg = "";
      Object.entries(responseStaging?.data).map((item) => {
        responseErrMsg = `${item[1]}`;
        return 0;
      });
      setItemAvailableFlag(false);
      setFakeresult(responseErrMsg || "Failed to substitute item.");
    }
    setIsLoading(false);
  };

  const collapseSingleItemDisplayWeb = (open = false) => (
    <TableRow>
      <DataTableBodyCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mt={-1}>
            <Table size="small" aria-label="purchases">
              <TableRow>
                <DataTableHeadCell width="10rem" sorted={false}>
                  Barcode number
                </DataTableHeadCell>
                <DataTableHeadCell width="10rem" sorted={false}>
                  serial number
                </DataTableHeadCell>
                <DataTableHeadCell width="10rem" sorted={false}>
                  name
                </DataTableHeadCell>
                <DataTableHeadCell sorted={false}>change item</DataTableHeadCell>
                <DataTableHeadCell sorted={false}>owned </DataTableHeadCell>
                <DataTableHeadCell sorted={false}>status</DataTableHeadCell>
                {/* <DataTableHeadCell sorted={false}>warehouse</DataTableHeadCell> */}
              </TableRow>
              <TableBody>
                {row?.original?.subitems.length <= 0 ? (
                  <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ paddingLeft: "10rem", marginRight: "auto" }}
                  >
                    <MDTypography variant="button" color="text">
                      No records found
                    </MDTypography>
                  </MDBox>
                ) : (
                  row?.original?.subitems.map((subitems, index) => (
                    <TableRow
                      key={subitems?.barcode_id || subitems?.serial_number || subitems?.name}
                    >
                      <DataTableBodyCell component="th" scope="row">
                        {subitems?.barcode_id || "-"}
                      </DataTableBodyCell>
                      <DataTableBodyCell component="th" scope="row">
                        <MDTypography variant="button" fontWeight="regular" c color="text">
                          {subitems?.serial_number || "-"}&nbsp;
                        </MDTypography>
                        {/* {subitems?.serial_number ? (
                          <MDTypography variant="button" fontWeight="regular" c color="text">
                            {subitems?.serial_number}&nbsp;
                          </MDTypography>
                        ) : (
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            ccolor="text"
                            sx={{ fontStyle: "italic" }}
                          >
                            No serial number&nbsp;
                          </MDTypography>
                        )} */}
                      </DataTableBodyCell>
                      <DataTableBodyCell component="th" scope="row">
                        <MDTypography variant="button" fontWeight="regular" c color="text">
                          {subitems?.name || "-"}&nbsp;
                        </MDTypography>
                      </DataTableBodyCell>

                      <DataTableBodyCell>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox
                            direction="column"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <MDBox mr={1}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={() => {
                                  handleOpenScanBarcodeModal(
                                    {
                                      ...row?.original,
                                      index,
                                    },
                                    subitems
                                  );
                                }}
                              >
                                Scan Substitute
                              </MDButton>
                            </MDBox>
                            <MDBox>
                              <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={() => {
                                  handleOpenItemlistModalSubstitute(
                                    {
                                      ...row?.original,
                                      index,
                                    },
                                    subitems
                                  );
                                  // console.log(row?.original, subitems, index);
                                  // const tmpSubitems = {
                                  //   uuid: "f1fafec3-2289-4ada-a6dd-1e830dabbc3a",
                                  //   barcode_id: "bc0003 1",
                                  //   status: {
                                  //     name: "Staged",
                                  //     value: "staged",
                                  //   },
                                  //   consigner: {
                                  //     uuid: "4c269815-c170-43ff-bd4b-2e76b4f4b390",
                                  //     full_name: "consignor1 user",
                                  //   },
                                  // };

                                  // items[row?.index].subitems.push(tmpSubitems);

                                  // setData({ ...data, items });

                                  // console.log({ ...row?.original, index: row?.index }, pending_items);
                                  // handleOpenItemlistModalSubstitute({ ...row?.original, index: row?.index });
                                }}
                                // disabled={
                                //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                                // }
                              >
                                Manual Substitute
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        <MDBox display="flex" justifyContent="space-between">
                          <IconButton size="small" aria-label="edit" disabled>
                            <Icon
                              color={!subitems?.is_owned ? "error" : "success"}
                              fontSize="medium"
                            >
                              square
                            </Icon>
                          </IconButton>
                          <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                            {!subitems?.is_owned ? "Consigned" : "Owned"}
                          </MDTypography>
                        </MDBox>
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        {subitems?.status?.name || subitems?.status || ""}
                      </DataTableBodyCell>

                      {/* <DataTableBodyCell>{subitems?.warehouse || ""}</DataTableBodyCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </MDBox>
        </Collapse>
      </DataTableBodyCell>
    </TableRow>
  );

  const collapseSingleItemDisplayWebv2 = (open = false, dataCollection = {}) => (
    <TableRow>
      <DataTableBodyCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mt={-1}>
            <Table size="small" aria-label="purchases">
              <TableRow>
                <DataTableHeadCell width="10rem" sorted={false}>
                  Barcode number
                </DataTableHeadCell>
                <DataTableHeadCell width="10rem" sorted={false}>
                  serial number
                </DataTableHeadCell>
                <DataTableHeadCell width="10rem" sorted={false}>
                  name
                </DataTableHeadCell>
                <DataTableHeadCell sorted={false}>change item</DataTableHeadCell>
                <DataTableHeadCell sorted={false}>owned </DataTableHeadCell>
                <DataTableHeadCell sorted={false}>status</DataTableHeadCell>
                {/* <DataTableHeadCell sorted={false}>warehouse</DataTableHeadCell> */}
              </TableRow>
              <TableBody>
                {dataCollection?.subitems.length <= 0 ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center">
                    <MDTypography variant="button" color="text">
                      No records found
                    </MDTypography>
                  </MDBox>
                ) : (
                  dataCollection?.subitems.map((subitems, index) => (
                    <TableRow
                      key={subitems?.barcode_id || subitems?.serial_number || subitems?.name}
                    >
                      <DataTableBodyCell component="th" scope="row">
                        {subitems?.barcode_id || "-"}
                      </DataTableBodyCell>
                      <DataTableBodyCell component="th" scope="row">
                        <MDTypography variant="button" fontWeight="regular" c color="text">
                          {subitems?.serial_number || "-"}&nbsp;
                        </MDTypography>
                        {/* {subitems?.serial_number ? (
                          <MDTypography variant="button" fontWeight="regular" c color="text">
                            {subitems?.serial_number}&nbsp;
                          </MDTypography>
                        ) : (
                          <MDTypography
                            variant="button"
                            fontWeight="regular"
                            ccolor="text"
                            sx={{ fontStyle: "italic" }}
                          >
                            No serial number&nbsp;
                          </MDTypography>
                        )} */}
                      </DataTableBodyCell>
                      <DataTableBodyCell component="th" scope="row">
                        {subitems?.name || "-"}
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox
                            direction="column"
                            display="flex "
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <MDBox mr={1}>
                              <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={() => {
                                  handleOpenScanBarcodeModal(
                                    {
                                      ...dataCollection,
                                      index,
                                    },
                                    subitems
                                  );
                                }}
                              >
                                Scan Substitute
                              </MDButton>
                            </MDBox>
                            <MDBox>
                              <MDButton
                                variant="outlined"
                                color="info"
                                size="small"
                                onClick={() => {
                                  handleOpenItemlistModalSubstitute(
                                    {
                                      ...dataCollection,
                                      index,
                                    },
                                    subitems
                                  );
                                  // console.log(row?.original, subitems, index);
                                  // const tmpSubitems = {
                                  //   uuid: "f1fafec3-2289-4ada-a6dd-1e830dabbc3a",
                                  //   barcode_id: "bc0003 1",
                                  //   status: {
                                  //     name: "Staged",
                                  //     value: "staged",
                                  //   },
                                  //   consigner: {
                                  //     uuid: "4c269815-c170-43ff-bd4b-2e76b4f4b390",
                                  //     full_name: "consignor1 user",
                                  //   },
                                  // };

                                  // items[row?.index].subitems.push(tmpSubitems);

                                  // setData({ ...data, items });

                                  // console.log({ ...row?.original, index: row?.index }, pending_items);
                                  // handleOpenItemlistModalSubstitute({ ...row?.original, index: row?.index });
                                }}
                                // disabled={
                                //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                                // }
                              >
                                Manual Substitute
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        <MDBox display="flex" justifyContent="space-between">
                          <IconButton size="small" aria-label="edit" disabled>
                            <Icon
                              color={!subitems?.is_owned ? "error" : "success"}
                              fontSize="medium"
                            >
                              square
                            </Icon>
                          </IconButton>
                          <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                            {!subitems?.is_owned ? "Consigned" : "Owned"}
                          </MDTypography>
                        </MDBox>
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        {subitems?.status?.name || subitems?.status || ""}
                      </DataTableBodyCell>

                      {/* <DataTableBodyCell>{subitems?.warehouse || ""}</DataTableBodyCell> */}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </MDBox>
        </Collapse>
      </DataTableBodyCell>
    </TableRow>
  );

  const collapseSingleItemDisplayMobile = (open = false) => (
    <TableRow>
      <DataTableBodyCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mt={-1}>
            <Table size="small" aria-label="purchases">
              <TableRow>
                <DataTableHeadCell width="auto" sorted={false}>
                  Info
                </DataTableHeadCell>
                <DataTableHeadCell sorted={false}>Action</DataTableHeadCell>
              </TableRow>
              <TableBody>
                {row?.original?.subitems.length <= 0 ? (
                  <MDBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="auto"
                  >
                    <MDTypography variant="button" color="text">
                      No records found
                    </MDTypography>
                  </MDBox>
                ) : (
                  row?.original?.subitems.map((subitems, index) => (
                    <TableRow
                      key={subitems?.barcode_id || subitems?.serial_number || subitems?.name}
                    >
                      <DataTableBodyCell component="th" scope="row">
                        <MDBox width="5rem">
                          <Grid container spacing={0}>
                            <Grid container item>
                              <MDTypography variant="caption" fontWeight="medium" color="text">
                                {subitems?.barcode_id || "-"}&nbsp;
                              </MDTypography>
                            </Grid>
                            <Grid container item>
                              <MDTypography variant="button" fontWeight="regular" c color="text">
                                {subitems?.serial_number || "-"}&nbsp;
                              </MDTypography>
                              {/* {subitems?.serial_number ? (
                                <MDTypography variant="caption" color="text">
                                  {subitems?.serial_number}&nbsp;
                                </MDTypography>
                              ) : (
                                <MDTypography
                                  variant="caption"
                                  color="text"
                                  sx={{ fontStyle: "italic" }}
                                >
                                  No serial number&nbsp;
                                </MDTypography>
                              )} */}
                            </Grid>
                            <Grid container item>
                              <MDTypography variant="caption" fontWeight="medium" color="text">
                                {subitems?.name || "-"}&nbsp;
                              </MDTypography>
                            </Grid>

                            <Grid container item>
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                color={subitems?.is_owned <= 0 ? "error" : "success"}
                              >
                                {subitems?.is_owned <= 0 ? "Consigned" : "Owned"}
                              </MDTypography>
                            </Grid>

                            <Grid container item>
                              <MDTypography variant="caption" fontWeight="regular">
                                {subitems?.status?.name || subitems?.status || ""}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox>
                            <IconButton
                              size="small"
                              aria-label="edit"
                              color="info"
                              onClick={() => {
                                handleOpenScanBarcodeModal(
                                  {
                                    ...row?.original,
                                    index,
                                  },
                                  subitems
                                );
                              }}
                              // disabled={
                              //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                              // }
                            >
                              <Icon fontSize="small">qr_code_scanner</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="edit"
                              color="info"
                              onClick={() => {
                                handleOpenItemlistModalSubstitute(
                                  {
                                    ...row?.original,
                                    index,
                                  },
                                  subitems
                                );
                              }}
                              // disabled={
                              //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                              // }
                            >
                              <Icon fontSize="small">swap_horiz</Icon>
                            </IconButton>
                          </MDBox>
                        </MDBox>
                      </DataTableBodyCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </MDBox>
        </Collapse>
      </DataTableBodyCell>
    </TableRow>
  );

  const collapseSingleItemDisplayMobileV2 = (open = false, dataCollection = {}) => (
    <TableRow>
      <DataTableBodyCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <MDBox mt={-1}>
            <Table size="small" aria-label="purchases">
              <TableRow>
                <DataTableHeadCell width="auto" sorted={false}>
                  Info
                </DataTableHeadCell>
                <DataTableHeadCell sorted={false}>Action</DataTableHeadCell>
              </TableRow>
              <TableBody>
                {dataCollection?.subitems.length <= 0 ? (
                  <MDBox
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    width="auto"
                  >
                    <MDTypography variant="button" color="text">
                      No records found
                    </MDTypography>
                  </MDBox>
                ) : (
                  dataCollection?.subitems.map((subitems, index) => (
                    <TableRow
                      key={subitems?.barcode_id || subitems?.serial_number || subitems?.name}
                    >
                      <DataTableBodyCell component="th" scope="row">
                        <MDBox width="7rem">
                          <Grid container spacing={0}>
                            <Grid container item>
                              <MDTypography variant="caption" fontWeight="medium" color="text">
                                {subitems?.barcode_id || "-"}&nbsp;
                              </MDTypography>
                            </Grid>
                            <Grid container item>
                              <MDTypography variant="caption" color="text">
                                {subitems?.serial_number || "-"}&nbsp;
                              </MDTypography>
                              {/* {subitems?.serial_number ? (
                                <MDTypography variant="caption" color="text">
                                  {subitems?.serial_number}&nbsp;
                                </MDTypography>
                              ) : (
                                <MDTypography
                                  variant="caption"
                                  color="text"
                                  sx={{ fontStyle: "italic" }}
                                >
                                  No serial number&nbsp;
                                </MDTypography>
                              )} */}
                            </Grid>
                            <Grid container item>
                              <MDTypography variant="caption" fontWeight="medium" color="text">
                                {subitems?.name || "-"}&nbsp;
                              </MDTypography>
                            </Grid>

                            <Grid container item>
                              <MDTypography
                                variant="caption"
                                fontWeight="regular"
                                color={subitems?.is_owned <= 0 ? "error" : "success"}
                              >
                                {subitems?.is_owned <= 0 ? "Consigned" : "Owned"}
                              </MDTypography>
                            </Grid>

                            <Grid container item>
                              <MDTypography variant="caption" fontWeight="regular">
                                {subitems?.status?.name || subitems?.status || ""}
                              </MDTypography>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </DataTableBodyCell>
                      <DataTableBodyCell>
                        <MDBox display="flex" justifyContent="space-between">
                          <MDBox>
                            <IconButton
                              size="small"
                              aria-label="edit"
                              color="info"
                              onClick={() => {
                                handleOpenScanBarcodeModal(
                                  {
                                    ...dataCollection,
                                    index,
                                  },
                                  subitems
                                );
                              }}
                              // disabled={
                              //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                              // }
                            >
                              <Icon fontSize="small">qr_code_scanner</Icon>
                            </IconButton>
                            <IconButton
                              size="small"
                              aria-label="edit"
                              color="info"
                              onClick={() => {
                                handleOpenItemlistModalSubstitute(
                                  {
                                    ...dataCollection,
                                    index,
                                  },
                                  subitems
                                );
                              }}
                              // disabled={
                              //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                              // }
                            >
                              <Icon fontSize="small">swap_horiz</Icon>
                            </IconButton>
                          </MDBox>
                        </MDBox>
                      </DataTableBodyCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </MDBox>
        </Collapse>
      </DataTableBodyCell>
    </TableRow>
  );

  const generateSingleItemDisplay = (data = {}) => (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {row.cells.map(
          (cell) =>
            !cell.hidden && (
              <DataTableBodyCell
                // noBorder={noEndBorder && rows.length - 1 === key}
                hasBorderRight={cell.column?.hasBorderRight || false}
                align={cell.column.align ? cell.column.align : "left"}
                bgColor={cell.column.bgColor ? cell.column.bgColor : "none"}
                {...cell.getCellProps()}
              >
                {cell.column.type === "collapse" && (
                  <MDBox display="flex">
                    <MDBox mt={isMobile ? 0 : -0.5}>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                          setOpenSingleItem(!openSingleItem);
                        }}
                        // onClick={() => console.log(cell)}
                      >
                        {openSingleItem ? (
                          <Icon fontSize="small">keyboard_arrow_up</Icon>
                        ) : (
                          <Icon mt={-1} fontSize="small">
                            keyboard_arrow_down
                          </Icon>
                        )}
                      </IconButton>
                    </MDBox>
                    <MDBox
                      onClick={() => {
                        setOpenSingleItem(!openSingleItem);
                      }}
                    >
                      {cell.render("Cell")}
                    </MDBox>
                  </MDBox>
                )}

                {cell.column.type !== "collapse" && cell.render("Cell")}
              </DataTableBodyCell>
            )
        )}
      </TableRow>
      {openSingleItem && isMobile && collapseSingleItemDisplayMobile(openSingleItem)}
      {openSingleItem && !isMobile && collapseSingleItemDisplayWeb(openSingleItem)}
    </>
  );

  const generateKitSingleItemDisplay = (data = {}) => {
    // const [open, setOpen] = useState(false);
    let open = false;
    return (
      <>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          {/* {row.cells.map(
            (cell) =>
              !cell.hidden && ( */}
          <DataTableBodyCell
          // noBorder={noEndBorder && rows.length - 1 === key}
          // hasBorderRight={cell.column?.hasBorderRight || false}
          // align={cell.column.align ? cell.column.align : "left"}
          // bgColor={cell.column.bgColor ? cell.column.bgColor : "none"}
          // {...cell.getCellProps()}
          >
            {/* {cell.column.type === "collapse" && ( */}
            <MDBox display="flex">
              <MDBox mt={isMobile ? 0 : -0.5}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => {
                    open = !open;
                    // console.log(open);
                  }}
                  // onClick={() => console.log(cell)}
                >
                  {open ? (
                    <Icon fontSize="small">keyboard_arrow_up</Icon>
                  ) : (
                    <Icon mt={-1} fontSize="small">
                      keyboard_arrow_down
                    </Icon>
                  )}
                </IconButton>
              </MDBox>
              <MDBox
                onClick={() => {
                  open = !open;
                }}
              >
                <MDTypography
                  variant="button"
                  color={(data?.subitems?.length || 0) < data?.quantity ? "error" : "success"}
                  fontWeight="medium"
                >
                  {data?.item?.name || ""}
                </MDTypography>
              </MDBox>
            </MDBox>
            {/* )} */}

            {/* {cell.column.type !== "collapse" && cell.render("Cell")} */}
          </DataTableBodyCell>
          {/* )
          )} */}
        </TableRow>
        {/* {open && isMobile && collapseSingleItemDisplayMobile(open)}
        {open && !isMobile && collapseSingleItemDisplayWeb(open)} */}
      </>
    );
  };

  const generateKitItemDisplay = (cell = {}) => (
    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <DataTableBodyCell
      // noBorder={noEndBorder && rows.length - 1 === key}
      // hasBorderRight={cell.column?.hasBorderRight || false}
      // align={cell.column.align ? cell.column.align : "left"}
      // bgColor={cell.column.bgColor ? cell.column.bgColor : "none"}
      // {...cell.getCellProps()}
      >
        {cell?.item?.item_type?.value === "kit" && (
          <MDBox display="flex">
            <MDBox>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpenKitItem(!openKitItem);
                }}
                // onClick={() => console.log(cell)}
              >
                {openKitItem ? (
                  <Icon fontSize="small">keyboard_arrow_up</Icon>
                ) : (
                  <Icon mt={-1} fontSize="small">
                    keyboard_arrow_down
                  </Icon>
                )}
              </IconButton>
            </MDBox>
            <MDBox
              onClick={() => {
                setOpenKitItem(!openKitItem);
              }}
            >
              <MDTypography
                variant="caption"
                // color={(cell?.subitems?.length || 0) < cell?.quantity ? "error" : "success"}
                color={tabName === "pending" ? "error" : "success"}
                fontWeight="medium"
              >
                {cell?.item?.name || ""}
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
        {cell?.item?.item_type?.value !== "kit" && (
          <MDBox display="flex">
            <MDBox mt={isMobile ? 0 : -0.5}>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpenKitItem(!openKitItem);
                }}
                // onClick={() => console.log(cell)}
              >
                {openKitItem ? (
                  <Icon fontSize="small">keyboard_arrow_up</Icon>
                ) : (
                  <Icon mt={-1} fontSize="small">
                    keyboard_arrow_down
                  </Icon>
                )}
              </IconButton>
            </MDBox>
            <MDBox
              onClick={() => {
                setOpenKitItem(!openKitItem);
              }}
            >
              <MDTypography
                variant="button"
                color={(cell?.subitems?.length || 0) < cell?.quantity ? "error" : "success"}
                fontWeight="medium"
              >
                {cell?.item?.name || ""}
              </MDTypography>
            </MDBox>
          </MDBox>
        )}
      </DataTableBodyCell>
    </TableRow>
  );

  const collapseKitItemDisplayWeb = (data = {}) => (
    // console.log(data);
    <DataTableBodyCell style={{ paddingBottom: 0, paddingTop: 0 }}>
      <Collapse in={openKitItem} timeout="auto" unmountOnExit>
        {data?.collections?.collections.map((collection, index) => (
          <MDBox
          // bgColor="error"
          >
            {/* <MDBox mt={isMobile ? 0 : -0.5}></MDBox> */}
            <MDBox
              display="flex"
              ml={3}
              // bgColor="info"
            >
              {/* {generateKitSingleItemDisplay(collection)} */}
              {/* <CollapseKitDisplay
                  data={collection}
                  handleOpenScanBarcodeModal={handleOpenScanBarcodeModal}
                  generateSingleItemDisplay={generateSingleItemDisplay}
                /> */}
              {/* {collection?.item?.item_type?.value === "kit" && (
                <CollapseKitDisplay
                  data={collection}
                  generateSingleItemDisplay={generateSingleItemDisplay}
                  handleOpenScanBarcodeModal={handleOpenScanBarcodeModal}
                />
              )}
              {collection?.item?.item_type?.value !== "kit" && (
                <CollapseKitDisplay
                  data={collection}
                  generateSingleItemDisplay={generateSingleItemDisplay}
                  handleOpenScanBarcodeModal={handleOpenScanBarcodeModal}
                />
              )} */}
              <MDBox mt={-0.5}>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  // onClick={() => {
                  //   setOpen(!openKitItem);
                  // }}
                  // onClick={() => console.log(collection)}
                >
                  {/* {openKitItem ? (
                  <Icon fontSize="small">keyboard_arrow_up</Icon>
                ) : (
                  <Icon mt={-1} fontSize="small">
                    keyboard_arrow_down
                  </Icon>
                )} */}
                  <Icon fontSize="small">chevron_right</Icon>
                </IconButton>
              </MDBox>
              <MDTypography
                variant="button"
                // color={
                //   (collection?.subitems?.length || 0) < collection?.quantity ? "error" : "success"
                // }
                color={tabName === "pending" ? "error" : "success"}
                // color="inherit"
                fontWeight="medium"
              >
                {collection?.item?.name || ""} &nbsp;
                {collection.collections === null &&
                  `(${collection?.subitems?.length || 0} / ${collection?.quantity || 0})`}
              </MDTypography>

              {collection.collections === null && !isMobile && (
                <MDBox ml={2}>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => {
                      // loadDetails();
                      handleOpenItemlistModal({ ...collection, index });
                    }}
                    disabled={(collection?.subitems?.length || 0) >= collection?.quantity}
                  >
                    Assign barcode
                  </MDButton>
                </MDBox>
              )}

              {collection.collections === null && isMobile && (
                <MDBox ml={2}>
                  <Tooltip title="Manual Adding of Barcoded Items" placement="top">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      color="dark"
                      onClick={() => {
                        handleOpenItemlistModal({ ...collection, index });
                      }}
                      disabled={(collection?.subitems?.length || 0) >= collection?.quantity}
                    >
                      <Icon fontSize="small" color="dark">
                        assignment_turned_in
                      </Icon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
              )}
            </MDBox>

            {collection.collections === null &&
              !isMobile &&
              collapseSingleItemDisplayWebv2(true, collection)}

            {collection.collections === null &&
              isMobile &&
              collapseSingleItemDisplayMobileV2(true, collection)}

            {collection.collections !== null && collapseKitItemDisplayWeb(collection)}
          </MDBox>
        ))}
      </Collapse>
    </DataTableBodyCell>
  );

  return (
    <>
      {/* {generateSingleItemDisplay(row)} */}
      {row?.original?.collections?.collections !== undefined &&
        // KIT ITEM
        generateKitItemDisplay(row?.original)}

      {row?.original?.collections?.collections === undefined &&
        // SINGLE ITEM
        generateSingleItemDisplay(row)}

      {/* SINGLE ITEM DISSPLAY FOR TABLE */}
      {/* {openSingleItem && isMobile && collapseSingleItemDisplayMobile()}
      {openSingleItem && !isMobile && collapseSingleItemDisplayWeb()} */}

      {/* KIT ITEM DISSPLAY FOR TABLE */}
      <TableRow>
        {openKitItem && isMobile && collapseKitItemDisplayWeb(row?.original)}
        {openKitItem && !isMobile && collapseKitItemDisplayWeb(row?.original)}
      </TableRow>

      {showQrCodeScan && !isMobile && (
        <MDBox>
          <Dialog
            open={showQrCodeScan}
            onClose={() => {
              handleCloseScanBarcodeModal();
            }}
            fullWidth
          >
            <DialogTitle>Scan Barcode</DialogTitle>

            <DialogContent>
              {isLoading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center">
                  <MDBox mt={0.75}>
                    <CircularProgress />
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      Searching...
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox>
                  <MDInput
                    autoFocus
                    placeholder="Search..."
                    value={search}
                    // size="small"
                    type="search"
                    fullWidth
                    width="30rem"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // onSearchChange(e.target.value);
                        substituteItem(e.target.value);
                        // handleSearchKeywordChange(e.target);
                        // setShowQrCodeScan(false);
                        setSearch("");
                      }
                    }}
                    onChange={async ({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      // substituteItem(currentTarget.value);
                      // setSearch("");
                    }}
                  />

                  <MDTypography variant="caption" color={itemAvailableFlag ? "success" : "error"}>
                    {fakeResult}
                  </MDTypography>
                </MDBox>
              )}
            </DialogContent>
          </Dialog>
        </MDBox>
      )}
      {showQrCodeScan && isMobile && (
        <MDBox>
          <Dialog
            open={showQrCodeScan}
            onClose={() => {
              handleCloseScanBarcodeModal();
            }}
            fullWidth
          >
            <DialogTitle>Scan Barcode</DialogTitle>

            <DialogContent>
              {isLoading ? (
                <MDBox display="flex" justifyContent="center" alignItems="center">
                  <MDBox mt={0.75}>
                    <CircularProgress />
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      Searching...
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox>
                  <MDInput
                    autoFocus
                    placeholder="Search..."
                    value={search}
                    // size="small"
                    type="search"
                    fullWidth
                    width="30rem"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        // onSearchChange(e.target.value);
                        substituteItem(e.target.value);
                        // handleSearchKeywordChange(e.target);
                        // setShowQrCodeScan(false);
                        // setSearch("");
                      }
                    }}
                    onChange={async ({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      substituteItem(currentTarget.value);
                      setSearch("");
                    }}
                  />

                  <MDTypography variant="caption" color={itemAvailableFlag ? "success" : "error"}>
                    {fakeResult}
                  </MDTypography>
                </MDBox>
              )}
            </DialogContent>
          </Dialog>
        </MDBox>
      )}

      {showItemList && (
        <CustomModal
          showModal={showItemList}
          handleHideModal={handleCloseItemlistModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "98%",
            width: "98%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <SubstituteSubitemListModal
            handleClose={handleCloseItemlistModal}
            handleAddItem={handleSwapItem}
            subitemData={subitemDetail}
            originalSubItemData={originalSubItemData}
            itemUuid={subitemDetail?.item?.uuid || ""}
          />
        </CustomModal>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
  loadDetails: PropTypes.func.isRequired,
  tabName: PropTypes.string.isRequired,
  handleOpenItemlistModal: PropTypes.func.isRequired,
};

function RentItemTable({
  entriesPerPage,
  canSearch,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  hiddenCols,
  handleNextPage,
  handlePrevPage,
  handleSearch,
  handleSort,
  handleChangeEntries,
  totalCount,
  hasNextPage,
  hasPrevPage,
  title,
  totals,
  totalPercentage,
  loadDetails,
  handleOpenItemlistModal,
  tabName,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns: hiddenCols },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const [entryStart, setEntryStart] = useState(1);
  const [entryEnd, setEntryEnd] = useState(pageSize < totalCount ? totalCount : pageSize);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setEntryStart(1);
    setEntryEnd(value);
  };

  // Render the paginations

  // Handler for the input to set the pagination index

  // Customized page options starting from 1

  // Setting value for the pagination input

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const handleNextPageButton = () => {
    setEntryStart(entryStart + pageSize);
    if (entryEnd + pageSize < totalCount) {
      setEntryEnd(entryEnd + pageSize);
    } else {
      setEntryEnd(totalCount);
    }

    handleNextPage();
  };

  const handlePrevPageButton = () => {
    setEntryStart(entryStart - pageSize);
    if (entryEnd === totalCount) {
      setEntryEnd(entryEnd - (totalCount % pageSize));
    } else {
      setEntryEnd(entryEnd - pageSize);
    }
    handlePrevPage();
  };

  const handleSearchKeywordChange = (currentTarget) => {
    setEntryStart(1);
    setEntryEnd(pageSize);
    setSearch(currentTarget.value);
    handleSearch(currentTarget.value);
  };

  const handleSorting = (column) => {
    setEntryStart(1);
    setEntryEnd(pageSize);
    handleSort(column.id);
  };

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mt={2} mx={2}>
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              {title}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
            {entriesPerPage.show && (
              <MDBox display="flex" justifyContent="flex-start" alignItems="center" mr={2}>
                <MDTypography variant="caption" color="secondary" mr={1}>
                  &nbsp;&nbsp;entries per page
                </MDTypography>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                    handleChangeEntries(newValue);
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
              </MDBox>
            )}
            <MDBox>
              {canSearch && (
                <MDBox width="12rem">
                  <MDInput
                    autoFocus
                    placeholder="Search..."
                    value={search}
                    size="small"
                    type="search"
                    fullWidth
                    onChange={({ currentTarget }) => {
                      setSearch(search);
                      onSearchChange(currentTarget.value);
                      handleSearchKeywordChange(currentTarget);
                    }}
                  />
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      <Table {...getTableProps()}>
        <MDBox component="thead">
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) =>
                !column.hidden && column.hasSort ? (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                    hasBorderRight={column?.hasBorderRight || false}
                    width={column.width ? column.width : "auto"}
                    align={column.headerAlign ? column.headerAlign : "center"}
                    // align="center"
                    sorted={setSortedValue(column)}
                    onClick={() => handleSorting(column)}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                ) : (
                  <DataTableHeadCell
                    {...column.getHeaderProps(isSorted)}
                    hasBorderRight={column?.hasBorderRight || false}
                    width={column.width ? column.width : "auto"}
                    align={column.headerAlign ? column.headerAlign : "center"}
                    // align="center"
                    sorted={false}
                  >
                    {column.render("Header")}
                  </DataTableHeadCell>
                )
              )}
            </TableRow>
          ))}
        </MDBox>
        <TableBody {...getTableBodyProps()}>
          {page === []
            ? "No Data"
            : page.map((row) => {
                prepareRow(row);
                return (
                  <Row
                    key={row.name}
                    row={row}
                    handleOpenItemlistModal={handleOpenItemlistModal}
                    loadDetails={loadDetails}
                    tabName={tabName}
                  />
                );
              })}
        </TableBody>

        {/* <TableRow>
          {page === []
            ? "No Data"
            : page.map((row, key) => {
                prepareRow(row);
                return (
                  <DataTableBodyCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse
                      // in={open}
                      in
                      timeout="auto"
                      unmountOnExit
                    >
                      <MDBox sx={{ margin: 1 }}>
                        <MDTypography variant="h6" gutterBottom component="div">
                          History
                        </MDTypography>
                        <Table size="small" aria-label="purchases">
                          <DataTableHeadCell>
                            <TableRow>
                              <DataTableBodyCell>Date</DataTableBodyCell>
                              <DataTableBodyCell>Customer</DataTableBodyCell>
                              <DataTableBodyCell align="right">Amount</DataTableBodyCell>
                              <DataTableBodyCell align="right">Total price ($)</DataTableBodyCell>
                            </TableRow>
                          </DataTableHeadCell>
                          <TableBody>
                            {row.history.map((historyRow) => (
                              <TableRow key={historyRow.date}>
                                <DataTableBodyCell component="th" scope="row">
                                  {historyRow.date}
                                </DataTableBodyCell>
                                <DataTableBodyCell>{historyRow.customerId}</DataTableBodyCell>
                                <DataTableBodyCell align="right">
                                  {historyRow.amount}
                                </DataTableBodyCell>
                                <DataTableBodyCell align="right">
                                  {Math.round(historyRow.amount * row.price * 100) / 100}
                                </DataTableBodyCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </MDBox>
                    </Collapse>
                  </DataTableBodyCell>
                );
              })}
        </TableRow> */}
        {totals?.show === true && page.length !== 0 && (
          <TableBody {...getTableBodyProps()}>
            <TableRow>
              {totals?.totalRows.map(
                (cell, index) =>
                  !cell.hidden && (
                    <DataTableBodyCell
                      hasBorderTop={totals?.hasBorderTop || false}
                      hasBorderRight={
                        totals?.hasBorderRight
                          ? (totals?.totalRows.length || 0) - 6 === index + 1
                          : false
                      }
                      key={`${cell + index}`}
                      // align={cell === "Totals" ? "right" : "left"}

                      align={totals?.align || "left"}
                    >
                      <MDTypography variant="button" color="text" fontWeight="medium">
                        {cell}
                      </MDTypography>
                    </DataTableBodyCell>
                  )
              )}
            </TableRow>
          </TableBody>
        )}
        {totalPercentage?.show === true && page.length !== 0 && (
          <TableBody {...getTableBodyProps()}>
            <TableRow>
              {totalPercentage?.totalRows.map(
                (cell, index) =>
                  !cell.hidden && (
                    <DataTableBodyCell
                      hasBorderRight={
                        totalPercentage?.hasBorderRight
                          ? (totalPercentage?.totalRows.length || 0) - 6 === index + 1
                          : false
                      }
                      key={`${cell + index}`}
                      // align={
                      //   cell === "Percentage Rate" || cell === "Percentage Rate" ? "right" : "left"
                      // }
                      align="center"
                    >
                      <MDTypography variant="button" color="text" fontWeight="medium">
                        {cell}
                      </MDTypography>
                    </DataTableBodyCell>
                  )
              )}
            </TableRow>
          </TableBody>
        )}
      </Table>
      {page.length === 0 && (
        <MDBox display="flex" justifyContent="center" sx={{ mt: 5 }}>
          <MDTypography variant="h5" color="text">
            No records found
          </MDTypography>
        </MDBox>
      )}
      <MDBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && totalCount < pageSize ? 0 : 3}
      >
        <MDPagination
          variant={pagination.variant ? pagination.variant : "gradient"}
          color={pagination.color ? pagination.color : "info"}
        >
          {hasPrevPage && (
            <MDPagination item onClick={() => handlePrevPageButton() || previousPage()}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
            </MDPagination>
          )}
          {showTotalEntries && !(totalCount < pageSize) && (
            // && searchKeyword === ""
            <MDBox ml={3} mr={3} mb={{ xs: 3, sm: 0 }}>
              <MDTypography variant="button" color="text" fontWeight="regular">
                Showing {entryStart} to {entryEnd} of {totalCount} entries
              </MDTypography>
            </MDBox>
          )}

          {hasNextPage && (
            <MDPagination item onClick={() => handleNextPageButton() || nextPage()}>
              <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
            </MDPagination>
          )}
        </MDPagination>
      </MDBox>
    </TableContainer>
  );
}

// Setting default values for the props of RentItemTable
RentItemTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: true,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  hiddenCols: ["uuid"],
  hasNextPage: false,
  hasPrevPage: false,
  hasCollapse: false,
  totalCount: 0,
  details: () => {},
  handleNextPage: () => {},
  handlePrevPage: () => {},
  handleSearch: () => {},
  handleChangeEntries: () => {},
  handleSort: () => {},
  title: "",
  tabName: "",
  totals: {},
  totalPercentage: {},
};

// Typechecking props for the RentItemTable
RentItemTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  hiddenCols: PropTypes.arrayOf(PropTypes.string),
  totalCount: PropTypes.number,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool,
  hasCollapse: PropTypes.bool,
  details: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePrevPage: PropTypes.func,
  handleSearch: PropTypes.func,
  handleChangeEntries: PropTypes.func,
  handleSort: PropTypes.func,
  title: PropTypes.string,
  tabName: PropTypes.string,
  totals: PropTypes.objectOf(PropTypes.any),
  totalPercentage: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
  handleOpenItemlistModal: PropTypes.func.isRequired,
};

export default RentItemTable;
