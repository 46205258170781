/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useSound from "use-sound";

import {
  Autocomplete,
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDEditor from "components/MDEditor";

import Done from "assets/sounds/tada.mp3";
import Rectangles from "assets/images/reelmen/rectangleBg.png";

import SensorDrawing from "../Modals/Camera/SensorDrawing";
import DragAndDrop from "../Modals/Camera/DragAndDrop";
import Photo from "../Modals/Camera/Photo";

const {
  getDateToday,
  formatDate,
  getStorageItem,
  httpRequest,
  getResponseMsg,
  capitalizeFirstLetter,
  WAREHOUSE_QUALITY_CHECK,
  checkNullEmpty,
} = WarehouseMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUploadWeb = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "50%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUploadMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "98%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

function Camera({ data, loadDetails, disableFields }) {
  const { qcSheet } = data;
  const navigate = useNavigate();
  const newQcSheet = qcSheet?.uuid === undefined;
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const userLoggedin = useSelector((state) => state?.authentication?.userDetail);
  const [playDoneSound] = useSound(Done);
  const statusOptions = [
    { name: "Retired", value: "retired" },
    { name: "In Repair", value: "in_repair" },
    // { name: "Awaiting QC", value: "awaiting_qc" },
    { name: "Ready to Rent", value: "available" },
  ];

  const drawingStateOptions = ["customer-sensor", "internal-sensor"];

  const [qcSheetDetails, setQcSheetDetails] = useState(qcSheet);
  const [cameraChecklist, setCameraChecklist] = useState(qcSheet?.checklist);
  const [technicianValue, setTechnicianValue] = useState(
    newQcSheet ? `${userLoggedin?.first_name} ${userLoggedin?.last_name}` : qcSheet?.technician
  );
  const [sensorImgSrcCustomer, setSensorImgSrcCustomer] = useState(
    qcSheet?.drawings?.customer?.sensor?.file_url === null
      ? null
      : qcSheet?.drawings?.customer?.sensor?.file_url
  );
  const [sensorImgSrcInternal, setSensorImgSrcInternal] = useState(
    qcSheet?.drawings?.internal?.sensor?.file_url === null
      ? null
      : qcSheet?.drawings?.internal?.sensor?.file_url
  );
  const [sensorCbValueCustomer, setSensorCheckboxValueCustomer] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.customer?.sensor?.is_checked
  );
  const [sensorCbValueInternal, setSensorCheckboxValueInternal] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.internal?.sensor?.is_checked
  );
  const [operatingTimeValue, setOperatingTimeValue] = useState(
    qcSheet?.service_notes?.operating_time
  );
  const [showCameraDrawingModal, setShowCameraDrawingModal] = useState(false);
  const [currentDrawing, setCurrentDrawing] = useState("");
  const [licenseValue, setLicenseValue] = useState(qcSheet?.service_notes?.licenses);
  const [firmwareValue, setFirmwareValue] = useState(qcSheet?.service_notes?.firmware);
  const [otherValue, setOtherValue] = useState(qcSheet?.service_notes?.other);
  const [privateServiceNotes, setPrivateServerNotes] = useState(qcSheet?.private_service_notes);
  const [errMsg, setErrMsg] = useState("Failed to Save QC Sheet");
  const [successMsg, setSuccessMsg] = useState("Successfully Saved QC Sheet");
  const [notifTitle, setNotifTitle] = useState("Upload Document");
  const [showUpload, setShowUpload] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false);
  const [showCapturePhoto, setCapturePhoto] = useState(false);
  const [fileNameToRemove, setFilenameToRemove] = useState("");
  const [responseDisplay, setResponseDisplay] = useState({});
  const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
  const [noChangeInConditionCb, setNoChangeInConditionCb] = useState(
    qcSheet?.no_change_in_condition === true
    // qcSheet?.no_change_in_condition === true ? false : qcSheet?.no_change_in_condition
  );
  const [changeInConditionCb, setChangeInConditionCb] = useState(
    qcSheet?.change_in_condition === true
    // qcSheet?.change_in_condition === true ? false : qcSheet?.change_in_condition
  );
  const [showChangeInConditionModal, setShowChangeInConditionModal] = useState(false);
  const [statusValue, setStatusValue] = useState(statusOptions[0]);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditedImageInternal, setIsEditedImageInternal] = useState(false);
  const [isEditedImageCustomer, setIsEditedImageCustomer] = useState(false);

  const handleOpenChangeInConditionModal = () => setShowChangeInConditionModal(true);
  const handleCloseChangeInConditionModal = () => setShowChangeInConditionModal(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenDialog = (value = "") => {
    setFilenameToRemove(value);
    setShowConfirmationDialogue(true);
  };
  const handleCloseDialog = () => setShowConfirmationDialogue(false);

  const handleOpenCapturePhotoModal = () => {
    setCapturePhoto(true);
  };

  const handleOpenSuccessDialog = (value = "") => {
    setShowSuccessDialogue(true);
    playDoneSound();
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialogue(false);
    if (responseDisplay?.subitem_status === "awaiting_qc") {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE);
      setTimeout(() => {
        navigate(WAREHOUSE_QUALITY_CHECK.DETAIL_ROUTE, {
          state: { from: WAREHOUSE_QUALITY_CHECK?.LIST_ROUTE },
        });
      }, 100);
    } else {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE);
    }
  };

  const handleCloseCapturePhotoModal = (action = "", status = "") => {
    switch (action) {
      case "success":
        setCapturePhoto(false);
        setSuccessMsg("Successfully Uploaded Photo.");
        openSuccessSB(true);
        loadDetails();

        break;
      case "failed":
        setErrMsg("Failed to Upload File");
        openErrorSB(true);
        break;
      default:
        setCapturePhoto(false);
        break;
    }
  };

  const updateState = (index, name, value) => {
    // UPDATE with render
    const newArray = cameraChecklist.map((item, i) => {
      if (index === i) {
        return { ...item, value };
      }
      return item;
    });
    setCameraChecklist(newArray);
    // values.subitems = newArray;

    // UPDATE without render

    // lensChecklist[index] = {
    //   ...lensChecklist[index],
    //   value,
    // };

    // console.log(lensChecklist);
  };

  const generateChecklist = () => (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      {cameraChecklist.map((list, index) => (
        <Grid item xs={4} sm={4} md={6}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={3.5} sm={6} md={10}>
              <MDTypography variant="button" fontWeight="regular">
                {list?.name}&nbsp;
              </MDTypography>
            </Grid>
            <Grid item xs={0.5} sm={1} md={1}>
              <Checkbox
                sx={{ border: "1px solid #4287f5", borderRadius: "30%", margin: "0", p: 0 }}
                disabled={disableFields}
                onChange={(e) => {
                  // console.log(index, list?.name, e.target.checked);
                  updateState(index, list?.name, e.target.checked);
                  setIsEdited(true);
                }}
                checked={list?.value}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const generateImageList = () => (
    <ImageList
      sx={{
        width: "auto",
        maxHeight: "400px",
        marginLeft: 2,
      }}
      cols={2}
    >
      {qcSheet?.attachments.map((item) => (
        <ImageListItem key={item.img} height="150px" width="auto">
          <MDBox>
            <Card
              sx={{
                borderRadius: "5%",
                border: "1px solid #000",
              }}
            >
              <CardMedia
                sx={{
                  display: "flex",
                  width: "auto",
                  height: "150px",
                }}
                image={item.file_url}
                title={item.file_name}
                onClick={() => window.open(item.file_url, "_blank")}
              />
              <CardActions>
                <Typography gutterBottom variant="caption" component="div">
                  {item.file_name}
                </Typography>
                <IconButton
                  color="error"
                  aria-label={`info about ${item.file_name}`}
                  onClick={() => {
                    handleOpenDialog(item?.file_name);
                  }}
                  size="large">
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              </CardActions>
            </Card>
          </MDBox>
        </ImageListItem>
      ))}
    </ImageList>
  );

  const handleOpenFEDrawingModal = (action = "") => {
    setCurrentDrawing(action);
    setShowCameraDrawingModal(true);
  };

  const handleCloseDrawingModal = (action = "", value = "") => {
    switch (action) {
      case drawingStateOptions[1]:
        setSensorImgSrcInternal(value);
        setShowCameraDrawingModal(false);
        // setIsEdited(true);
        setIsEditedImageInternal(true);
        break;
      case drawingStateOptions[0]:
        setSensorImgSrcCustomer(value);
        setShowCameraDrawingModal(false);
        // setIsEdited(true);
        setIsEditedImageCustomer(true);
        break;
      default:
        setShowCameraDrawingModal(false);
        break;
    }
  };

  const handleOpenUpload = (attachmentType = "photo") => {
    setShowUpload(true);
  };

  const handleCloseupload = (action = "", status = "") => {
    setNotifTitle("Upload Document");
    if (status === "uploaded") {
      setSuccessMsg("Successfully Uploaded File");
      openSuccessSB(true);
      loadDetails();
    }
    setShowUpload(false);
  };

  const handleRemoveItem = async () => {
    const body = {
      file_name: fileNameToRemove,
      action: "remove",
    };
    const response = await httpRequest("remove-attachment-qc", body, { uuid: qcDetails?.uuid });

    if (getResponseMsg(response) === "Success") {
      setSuccessMsg("Successfully removed attachment.");
      openSuccessSB(true);
      loadDetails();
    } else {
      const responseErrMsg = "Failed to Remove attachment.";
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    }
    handleCloseDialog();
  };

  const noChangeInCondition = async () => {
    let response = {};
    if (qcSheetDetails?.uuid === undefined) {
      response = await httpRequest(
        "save-qc-sheet",
        {
          sheet_type: "camera",
          technician: technicianValue || "",
          checklist: cameraChecklist,
          private_service_notes: privateServiceNotes,
          service_notes: {
            firmware: firmwareValue,
            other: otherValue,
            operating_time: operatingTimeValue,
            licenses: licenseValue,
          },
          // drawings: {
          //   sensor: { is_checked: sensorCbValueInternal === true, file_url: sensorImgSrcInternal },
          // },
          drawings: {
            internal: {
              sensor: {
                is_checked: sensorCbValueInternal === true,
                file_url: sensorImgSrcInternal,
              },
            },
            customer: {
              sensor: {
                is_checked: sensorCbValueCustomer === true,
                file_url: sensorImgSrcCustomer,
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    } else {
      response = await httpRequest(
        "update-qc-sheet",
        {
          sheet: qcSheetDetails?.uuid,
          sheet_type: "camera",
          technician: technicianValue || "",
          checklist: cameraChecklist,
          service_notes: {
            firmware: firmwareValue,
            other: otherValue,
            operating_time: operatingTimeValue,
            licenses: licenseValue,
          },
          private_service_notes: privateServiceNotes,
          // drawings: {
          //   sensor: {
          //     is_checked: sensorCbValueInternal === true,
          //     file_url: sensorImgSrcInternal,
          //     edited: isEditedImage,
          //   },
          // },
          drawings: {
            internal: {
              sensor: {
                is_checked: sensorCbValueInternal === true,
                file_url: sensorImgSrcInternal,
                edited: isEditedImageInternal,
              },
            },
            customer: {
              sensor: {
                is_checked: sensorCbValueCustomer === true,
                file_url: sensorImgSrcCustomer,
                edited: isEditedImageCustomer,
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    }
    if (getResponseMsg(response) !== "Success") {
      let responseErrMsg = "QC Sheet not saved.";
      Object.entries(response.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    } else {
      setSuccessMsg("Successfully Saved all changes.");
      openSuccessSB(true);
      // loadDetails();
      setSensorImgSrcInternal(response?.data?.data?.drawings?.internal?.sensor?.file_url);
      setSensorImgSrcCustomer(response?.data?.data?.drawings?.customer?.sensor?.file_url);
      setIsEdited(false);
      setIsEditedImageInternal(false);
      setIsEditedImageCustomer(false);
      setQcSheetDetails(response?.data?.data);
      document.body.style.cursor = "default";
      document.body.style.cursor = "wait";
      const responseNoChange = await httpRequest(
        "conditions",
        {
          sheet: response?.data?.data?.uuid,
          change_in_condition: false,
        },
        { uuid: qcDetails?.uuid }
      );
      if (getResponseMsg(responseNoChange) === "Success") {
        setNoChangeInConditionCb(true);
        setResponseDisplay(responseNoChange?.data?.data);
        handleOpenSuccessDialog();
      } else {
        setNotifTitle("No Change in Condition");
        let responseErrMsg = "Failed to save changes.";
        Object.entries(responseNoChange.data).map((item) => {
          responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
          return 0;
        });
        setErrMsg(responseErrMsg);
        openErrorSB(true);
      }
      document.body.style.cursor = "default";
    }
  };

  const handleConfirmChangeInCondition = async () => {
    let response = {};
    if (qcSheetDetails?.uuid === undefined) {
      response = await httpRequest(
        "save-qc-sheet",
        {
          sheet_type: "camera",
          technician: technicianValue || "",
          checklist: cameraChecklist,
          private_service_notes: privateServiceNotes,
          service_notes: {
            firmware: firmwareValue,
            other: otherValue,
            operating_time: operatingTimeValue,
            licenses: licenseValue,
          },
          // drawings: {
          //   sensor: { is_checked: sensorCbValueInternal === true, file_url: sensorImgSrcInternal },
          // },
          drawings: {
            internal: {
              sensor: {
                is_checked: sensorCbValueInternal === true,
                file_url: sensorImgSrcInternal,
              },
            },
            customer: {
              sensor: {
                is_checked: sensorCbValueCustomer === true,
                file_url: sensorImgSrcCustomer,
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    } else {
      response = await httpRequest(
        "update-qc-sheet",
        {
          sheet: qcSheetDetails?.uuid,
          sheet_type: "camera",
          technician: technicianValue || "",
          checklist: cameraChecklist,
          service_notes: {
            firmware: firmwareValue,
            other: otherValue,
            operating_time: operatingTimeValue,
            licenses: licenseValue,
          },
          private_service_notes: privateServiceNotes,
          // drawings: {
          //   sensor: {
          //     is_checked: sensorCbValueInternal === true,
          //     file_url: sensorImgSrcInternal,
          //     edited: isEditedImage,
          //   },
          // },
          drawings: {
            internal: {
              sensor: {
                is_checked: sensorCbValueInternal === true,
                file_url: sensorImgSrcInternal,
                edited: isEditedImageInternal,
              },
            },
            customer: {
              sensor: {
                is_checked: sensorCbValueCustomer === true,
                file_url: sensorImgSrcCustomer,
                edited: isEditedImageCustomer,
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    }
    if (getResponseMsg(response) !== "Success") {
      let responseErrMsg = "QC Sheet not saved.";
      Object.entries(response.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    } else {
      setSuccessMsg("Successfully Saved all changes.");
      openSuccessSB(true);
      // loadDetails();
      setSensorImgSrcInternal(response?.data?.data?.drawings?.internal?.sensor?.file_url);
      setSensorImgSrcCustomer(response?.data?.data?.drawings?.customer?.sensor?.file_url);
      setIsEdited(false);
      setIsEditedImageInternal(false);
      setIsEditedImageCustomer(false);
      setQcSheetDetails(response?.data?.data);
      const responseChangeCondition = await httpRequest(
        "conditions",
        {
          sheet: response?.data?.data?.uuid,
          change_in_condition: true,
          status: statusValue?.value,
        },
        { uuid: qcDetails?.uuid }
      );
      if (getResponseMsg(responseChangeCondition) === "Success") {
        setShowChangeInConditionModal(false);
        setResponseDisplay(responseChangeCondition?.data?.data);
        handleOpenSuccessDialog();
      } else {
        setNotifTitle("Change in Condition");
        let responseErrMsg = "Failed to save changes.";
        Object.entries(responseChangeCondition.data).map((item) => {
          responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
          return 0;
        });
        setErrMsg(responseErrMsg);
        openErrorSB(true);
      }
      document.body.style.cursor = "default";
      // return response?.data?.data;
    }
  };

  useEffect(() => {
    document.body.style.cursor = "wait";
    if (newQcSheet) {
      setTechnicianValue(`${userLoggedin?.first_name} ${userLoggedin?.last_name}`);
    }
    document.body.style.cursor = "default";
  }, [userLoggedin]);

  useEffect(() => {
    document.body.style.cursor = "wait";
    if (newQcSheet) {
      setCameraChecklist([
        { name: "Body/Fans/Hardware", value: false },
        { name: "Image & SDI", value: false },
        { name: "AC/Battery Power/Electronic Ports", value: false },
        { name: "Read/Write & Cables", value: false },
        { name: "Sensor Cover Glass", value: false },
        { name: "Factory Reset", value: false },
        { name: "Flange Depth", value: false },
        { name: "Cage & Accessories", value: false },
        { name: "Internal NDs", value: false },
        { name: "Sensor Calibration (RED)", value: false },
        { name: "Lens Data", value: false },
        { name: "APR & Auto Black Balance (Sony)", value: false },
      ]);
      setTechnicianValue(`${userLoggedin?.first_name} ${userLoggedin?.last_name}`);
    } else {
      setCameraChecklist(qcSheet?.checklist);
    }
    document.body.style.cursor = "default";
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox m={2}>
      <MDBox display="flex" justifyContent="space-between">
        <MDTypography variant="h6" color="info">
          QC Checklist - Camera
        </MDTypography>
      </MDBox>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={6}>
          <MDTypography variant="button" fontWeight="regular">
            Date:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="medium">
            {formatDate(
              checkNullEmpty(qcSheet?.date) === true ? getDateToday() : qcSheet?.date,
              "MMMM DD, YYYY"
            )}
          </MDTypography>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <MDBox display="flex">
            <MDBox mr={1}>
              <MDTypography variant="button" fontWeight="regular">
                Technician:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDInput
                value={technicianValue}
                disabled={disableFields}
                variant="standard"
                onChange={(e) => {
                  setTechnicianValue(e.target.value);
                  setIsEdited(true);
                }}
              />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={8}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              {generateChecklist()}
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={5}>
                  <MDBox display="flex" justifyContent="space-evenly">
                    <MDBox>
                      <MDBox sx={{ textAlign: "center" }}>
                        <MDTypography variant="button" fontWeight="regular">
                          Sensor (Internal)
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" alignItems="flex-end" mt={-1}>
                        <MDBox>
                          <CardMedia
                            component="img"
                            sx={{
                              display: "flex",
                              width: "auto",
                              height: "100px",
                              borderRadius: "0%",
                            }}
                            image={sensorImgSrcInternal || Rectangles}
                            onClick={() => {
                              if (!disableFields) {
                                handleOpenFEDrawingModal(drawingStateOptions[1]);
                              }
                            }}
                            alt="sensor"
                          />
                        </MDBox>
                        <MDBox>
                          <Checkbox
                            sx={{
                              border: "1px solid #4287f5",
                              borderRadius: "30%",
                              margin: "0",
                              p: 0,
                            }}
                            disabled={disableFields}
                            onChange={(e) => {
                              setSensorCheckboxValueInternal(e.target.checked);
                              setIsEdited(true);
                            }}
                            checked={sensorCbValueInternal}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </MDBox>
                      </MDBox>
                      {isEditedImageInternal && (
                        <MDBox>
                          <MDTypography variant="caption" fontWeight="regular" color="warning">
                            * Image is edited. Please save to avoid data loss. *
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
                <hr
                  style={{
                    marginTop: "1%",
                    marginLeft: "2%",
                  }}
                />
                <Grid item xs={4} sm={8} md={6}>
                  <MDBox display="flex" justifyContent="space-evenly">
                    <MDBox>
                      <MDBox sx={{ textAlign: "center" }}>
                        <MDTypography variant="button" fontWeight="regular">
                          Sensor (Customer)
                        </MDTypography>
                      </MDBox>
                      <MDBox display="flex" alignItems="flex-end" mt={-1}>
                        <MDBox>
                          <CardMedia
                            component="img"
                            sx={{
                              display: "flex",
                              width: "auto",
                              height: "100px",
                              borderRadius: "0%",
                            }}
                            image={sensorImgSrcCustomer || Rectangles}
                            onClick={() => {
                              if (!disableFields) {
                                handleOpenFEDrawingModal(drawingStateOptions[0]);
                              }
                            }}
                            alt="sensor"
                          />
                        </MDBox>
                        <MDBox>
                          <Checkbox
                            sx={{
                              border: "1px solid #4287f5",
                              borderRadius: "30%",
                              margin: "0",
                              p: 0,
                            }}
                            disabled={disableFields}
                            onChange={(e) => {
                              setSensorCheckboxValueCustomer(e.target.checked);
                              setIsEdited(true);
                            }}
                            checked={sensorCbValueCustomer}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </MDBox>
                      </MDBox>
                      {isEditedImageCustomer && (
                        <MDBox>
                          <MDTypography variant="caption" fontWeight="regular" color="warning">
                            * Image is edited. Please save to avoid data loss. *
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                  Service Notes
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      Operating Time:&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={operatingTimeValue}
                      onChange={(e) => {
                        // console.log(e);
                        setOperatingTimeValue(e);
                        setIsEdited(true);
                      }}
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      Firmware:&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={firmwareValue}
                      onChange={(e) => {
                        setFirmwareValue(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      License(s):&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={licenseValue}
                      onChange={(e) => {
                        setLicenseValue(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      Other:&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={otherValue}
                      onChange={(e) => {
                        setOtherValue(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12} mt={2}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Private Service Notes
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={privateServiceNotes}
                      onChange={(e) => {
                        setPrivateServerNotes(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12} mt={2}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDTypography variant="h6" fontWeight="medium">
                  Item Condition
                </MDTypography>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={6}>
                  <Checkbox
                    checked={noChangeInConditionCb}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ border: "1px solid #4287f5", borderRadius: "30%", p: 0 }}
                    onClick={noChangeInCondition}
                    // disabled={qcSheet?.uuid === undefined || disableFields}
                  />
                  <MDTypography variant="caption" fontWeight="regular" ml={2}>
                    No change in condition&nbsp;
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={6}>
                  <Checkbox
                    checked={changeInConditionCb}
                    inputProps={{ "aria-label": "controlled" }}
                    // disabled={qcSheet?.uuid === undefined || disableFields}
                    onClick={handleOpenChangeInConditionModal}
                    sx={{ border: "1px solid #4287f5", borderRadius: "30%", p: 0 }}
                  />
                  <MDTypography variant="caption" fontWeight="regular" ml={2}>
                    Change in condition&nbsp;
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ textAlign: "center" }}
                mt={2}
              >
                <MDBox mr={2}>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleOpenCapturePhotoModal}
                    disabled={disableFields}
                  >
                    Capture
                  </MDButton>
                </MDBox>
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleOpenUpload}
                    disabled={disableFields}
                  >
                    Upload
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox>
                {qcSheet?.attachments?.length === 0 ? (
                  <MDBox mr={1} sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      No Attachments
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDBox mr={1} mt={2}>
                    {qcSheet?.attachments?.length > 0 && generateImageList()}
                  </MDBox>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <MDBox>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center">
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={saveQcSheet}
              disabled={disableFields}
            >
              Save
            </MDButton>
          </MDBox>
          {(isEdited || isEditedImageInternal || isEditedImageCustomer) && (
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular" color="warning">
                * You have unsave changes *
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </MDBox> */}
      {showCameraDrawingModal && (
        <Modal
          open={showCameraDrawingModal}
          onClose={handleCloseDrawingModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={style}>
            <SensorDrawing
              loadDetails={loadDetails}
              handleCloseModal={handleCloseDrawingModal}
              currentDrawing={currentDrawing}
              imgSrc={
                currentDrawing === drawingStateOptions[1]
                  ? sensorImgSrcInternal?.includes("data:image/png;base64")
                    ? sensorImgSrcInternal
                    : null
                  : sensorImgSrcCustomer?.includes("data:image/png;base64")
                  ? sensorImgSrcCustomer
                  : null
              }
            />
          </MDBox>
        </Modal>
      )}

      {showCapturePhoto && (
        <Modal
          open={showCapturePhoto}
          onClose={handleCloseCapturePhotoModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={style}>
            <Photo loadDetails={loadDetails} handleCloseModal={handleCloseCapturePhotoModal} />
          </MDBox>
        </Modal>
      )}

      {showUpload && (
        <Modal
          open={showUpload}
          onClose={handleCloseupload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={isMobile ? styleUploadMobile : styleUploadWeb}>
            <DragAndDrop
              uuid={qcDetails?.uuid}
              // attachmentType={uploadData}
              onClose={handleCloseupload}
              multiple
            />
          </MDBox>
        </Modal>
      )}

      {showConfirmationDialogue && (
        // <div>
        <Dialog open={showConfirmationDialogue} onClose={handleCloseDialog}>
          <DialogTitle>Remove Attachment</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to remove this attachment?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handleRemoveItem()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}
      {showSuccessDialogue && (
        <Dialog
          open={showSuccessDialogue}
          onClose={handleCloseSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{notifTitle}</DialogTitle>
          <DialogContent>
            {responseDisplay?.subitem_status === "awaiting_qc" && (
              <DialogContentText id="alert-dialog-description">
                ♦ QC sheet will reset.
              </DialogContentText>
            )}
            {responseDisplay?.subitem_status !== "awaiting_qc" && (
              <DialogContentText id="alert-dialog-description">
                ♦ Sub-item status is now&nbsp;
                {capitalizeFirstLetter(responseDisplay?.subitem_status).replaceAll("_", " ")}.
              </DialogContentText>
            )}
            {responseDisplay?.order_status === "ready_to_invoice" && (
              <DialogContentText id="alert-dialog-description">
                ♦ Order status is now&nbsp;
                {capitalizeFirstLetter(responseDisplay?.order_status).replaceAll("_", " ")}.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseSuccessDialog} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      {showChangeInConditionModal && (
        <Dialog open={showChangeInConditionModal} onClose={handleCloseChangeInConditionModal}>
          <DialogTitle>Change in condition</DialogTitle>
          <DialogContent>
            <DialogContentText>Change status of this sub-item.</DialogContentText>
            <MDBox>
              <Autocomplete
                scroll="body"
                value={statusValue || null}
                options={statusOptions}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setStatusValue(newValue);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => option.name || ""}
                fullWidth
                getOptionDisabled={(option) => option.value === statusValue.value}
                renderInput={(params) => <TextField {...params} />}
              />
            </MDBox>
            {/* </Box> */}
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseChangeInConditionModal}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={handleConfirmChangeInCondition}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title="QC Sheet"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="QC Sheet"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}
Camera.defaultProps = {
  data: {},
  disableFields: false,
};

Camera.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
  disableFields: PropTypes.bool,
};
export default Camera;
