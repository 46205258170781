/* eslint-disable react/prop-types */
import { Modal } from "@mui/material";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";

function CustomModal({ showModal, handleHideModal, children, ...rest }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <Modal
      keepMounted
      open={showModal}
      onClose={handleHideModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <MDBox
        bgColor={darkMode ? "dark" : "white"}
        sx={
          rest?.style || {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "29rem",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "20px",
            p: 4,
          }
        }
      >
        {children}
      </MDBox>
    </Modal>
  );
}

export default CustomModal;
