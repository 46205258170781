/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import Info from "layouts/Inventory/new-item/components/Info";
import Pricing from "layouts/Inventory/new-item/components/Pricing";
import SubItem from "layouts/Inventory/new-item/components/SubItem";
import form from "layouts/Inventory/new-item/schemas/form";
import initialValues from "layouts/Inventory/new-item/schemas/initialValues";
import validations from "layouts/Inventory/new-item/schemas/validations";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Kit from "./new-item/components/Kit";

const { rentalItemValidation } = validations;
const {
  INVENTORY,
  spacing,
  flex,
  flexEnd,
  spaceBetween,
  httpRequest,
  setStorageItem,
  getStorageItem,
  getResponseMsg,
  capitalizeFirstLetter,
} = InventoryMgr;

function InventoryNew() {
  const { formId, formField } = form;
  const navigate = useNavigate();
  const location = useLocation();

  const itemDetail = JSON.parse(getStorageItem("current_item_detail")) || { pageState: undefined };

  const [errMsg, setErrMsg] = useState("Failed to Create item");
  const [successMsg, setSuccessMsg] = useState("Successfully Created Item");

  const [showFieldError, setShowFieldError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [isKit, setIsKit] = useState(itemDetail?.item_type?.value === "kit" || false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const submitForm = async (values, actions) => {
    setLoading(true);
    let response = null;

    if (itemDetail?.pageState === "NEW") {
      response = await httpRequest("create-item", values);
      setSuccessMsg("Item has been created successfully!");
    } else if (itemDetail?.pageState === "EDIT") {
      response = await httpRequest("update-item", values, { uuid: itemDetail.uuid });
      setSuccessMsg("Item has been updated successfully!");
    }

    if (getResponseMsg(response) === "Success") {
      openSuccessSB(true);
      setTimeout(() => {
        setLoading(false);
        actions.resetForm();
        setStorageItem("current_item_detail", response.data.data);
        // console.log(uuid);
        navigate(INVENTORY.DETAIL_ROUTE, {
          replace: true,
          state: { searchKeyword: location.state?.searchKeyword || "" },
        });
      }, 1500);
    } else {
      let responseErrMsg = "";

      Object.entries(response.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });

      setErrMsg(responseErrMsg);
      setLoading(false);
      openErrorSB(true);
    }

    actions.setSubmitting(false);
  };

  const handleSave = (err) => {
    let responseErrMsg = "";
    setLoading(true);
    setShowFieldError(true);

    Object.entries(err).map((item) => {
      responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
      return 0;
    });

    if (responseErrMsg !== "") {
      openErrorSB(true);
      setErrMsg(responseErrMsg);
    }

    setLoading(false);
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    const tmpSubItems = [];
    const tmpCollections = [];
    if (values?.item_type === null) {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Item Type is required.");
    } else if (values?.item_category === null) {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Item Category is required.");
    } else if (values?.daily_price === "") {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Daily Price is required.");
    } else if (values?.weekly_price === "") {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Weekly Price is required.");
    } else if (values?.monthly_price === "") {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Monthly Price is required.");
    } else if (values?.item_sub_category === null) {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Item Sub Category is required.");
    } else {
      values?.subitems.map((subs) => {
        tmpSubItems.push({
          barcode_id: subs?.barcode_id,
          serial_number: subs?.serial_number,
          is_owned: subs?.is_owned,
          no_barcode_serial: subs?.no_barcode_serial,
          consigner: subs?.consigner?.uuid || null,
          uuid: subs?.uuid || "",
          notes: subs?.notes || "",
          status: subs?.status?.value || "available",
        });

        return tmpSubItems;
      });

      values?.collections.map((subs) => {
        // console.log(subs);
        tmpCollections.push({
          uuid: subs.parent_uuid || "",
          item_uuid: subs?.uuid || "",
          optional: subs?.optional || false,
          quantity: !subs?.delete ? Number(subs?.quantity || 0) : 1,
          delete: subs?.delete || false,
          custom_prices: {
            daily: Number(subs?.daily_price),
            weekly: Number(subs?.weekly_price),
            monthly: Number(subs?.monthly_price),
          },
        });

        return tmpCollections;
      });

      const body = {
        name: values.item_name,
        description: values.item_description,
        category: values.item_category?.uuid,
        subcategory: values.item_sub_category?.uuid,
        item_type: values?.item_type?.value,
        replacement_value:
          values?.item_type?.value !== "kit" ? Number(values.replacement_value || 1) : 1,
        price: {
          daily: Number(values.daily_price),
          weekly: Number(values.weekly_price),
          monthly: Number(values.monthly_price),
        },
        subitems: values?.item_type?.value !== "kit" ? tmpSubItems : [],
        collections: values?.item_type?.value === "kit" ? tmpCollections : [],
        manufacturer: values?.item_type?.value !== "kit" ? values.manufacturer || "" : "",
        model_number: values?.item_type?.value !== "kit" ? values.model_number || "" : "",
        is_qc_required: values.is_qc_required || false,
        notes: values.notes || "",
      };
      // console.log(body);
      submitForm(body, actions);
    }
  };

  const handleClose = () => {
    if (itemDetail?.pageState === "NEW") {
      navigate(INVENTORY.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(INVENTORY.DETAIL_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <SimpleBackdrop />}
      <MDBox mb={3}>
        <Grid container spacing={spacing}>
          <Grid item xs={12}>
            <MDBox display={flex} justifyContent={spaceBetween}>
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium" color="dark">
                  {itemDetail?.pageState === "NEW"
                    ? `New ${INVENTORY.TITLE}`
                    : `Edit ${INVENTORY.TITLE}`}
                </MDTypography>
              </MDBox>
              <MDBox display={flex} justifyContent={spaceBetween}>
                <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={
                itemDetail?.pageState === "NEW" ? initialValues : itemDetail || initialValues
              }
              validationSchema={rentalItemValidation}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card id="basic-info" sx={{ overflow: "visible" }}>
                    {itemDetail?.pageState && (
                      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4} sm={4} md={6}>
                          <Info
                            showFieldError={showFieldError}
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                            setIsKit={setIsKit}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={6}>
                          <Pricing
                            showFieldError={showFieldError}
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                          {isKit ? (
                            <Kit
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                              }}
                            />
                          ) : (
                            <SubItem
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    )}
                    <MDBox display={flex} justifyContent={flexEnd} my={2} mx={2}>
                      <MDButton
                        size="small"
                        variant="gradient"
                        color="error"
                        onClick={handleClose}
                        sx={{ marginRight: 1 }}
                      >
                        Cancel
                      </MDButton>
                      <MDButton
                        disabled={isSubmitting}
                        size="small"
                        type="submit"
                        variant="gradient"
                        color="info"
                        onClick={() => handleSave(errors)}
                      >
                        save
                      </MDButton>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Item Creation"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default InventoryNew;
