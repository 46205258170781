/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import useSound from "use-sound";

import {
  Autocomplete,
  Card,
  CardActions,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  Modal,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import MDEditor from "components/MDEditor";

import WhiteBg from "assets/images/bg/white.jpg";
import Done from "assets/sounds/tada.mp3";
// import Rectangles from "assets/images/reelmen/rectangles.png";
import Rectangles from "assets/images/reelmen/rectangleBg.png";

import DragAndDrop from "../Modals/Filters/DragAndDrop";
import Photo from "../Modals/Filters/Photo";
import BackDrawing from "../Modals/Filters/BackDrawing";
import FrontDrawing from "../Modals/Filters/FrontDrawing";

const {
  getDateToday,
  formatDate,
  getStorageItem,
  httpRequest,
  getResponseMsg,
  capitalizeFirstLetter,
  WAREHOUSE_QUALITY_CHECK,
  checkNullEmpty,
} = WarehouseMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUploadWeb = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "50%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUploadMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "98%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

function Filters({ data, loadDetails, disableFields }) {
  const { qcSheet } = data;
  // const newQcSheet = qcSheet?.checklist.length === 0;
  const newQcSheet = qcSheet?.uuid === undefined;
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const userLoggedin = useSelector((state) => state?.authentication?.userDetail);
  const [playDoneSound] = useSound(Done);
  const statusOptions = [
    { name: "Retired", value: "retired" },
    { name: "In Repair", value: "in_repair" },
    // { name: "Awaiting QC", value: "awaiting_qc" },
    { name: "Ready to Rent", value: "available" },
  ];

  const drawingStateOptions = [
    "internal-front",
    "internal-back",
    "customer-front",
    "customer-back",
  ];

  const [qcSheetDetails, setQcSheetDetails] = useState(qcSheet);
  const [EVFChecklist, setEVFChecklist] = useState(qcSheet?.checklist);
  const [technicianValue, setTechnicianValue] = useState(
    newQcSheet ? `${userLoggedin?.first_name} ${userLoggedin?.last_name}` : qcSheet?.technician
  );
  const [frontImgSrcInternal, setFrontImgSrcInternal] = useState(
    qcSheet?.drawings?.internal?.front?.file_url === null
      ? null
      : qcSheet?.drawings?.internal?.front?.file_url
  );
  const [backImgSrcInternal, setBackImgSrcInternal] = useState(
    qcSheet?.drawings?.internal?.back?.file_url === null
      ? null
      : qcSheet?.drawings?.internal?.back?.file_url
  );
  const [frontCbValueInternal, setFrontCheckboxValueInternal] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.internal?.front?.is_checked
  );
  const [backCbValueInternal, setBackCheckboxValueInternal] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.internal?.back?.is_checked
  );
  const [frontImgSrcCustomer, setFrontImgSrcCustomer] = useState(
    qcSheet?.drawings?.customer?.front?.file_url === null
      ? null
      : qcSheet?.drawings?.customer?.front?.file_url
  );
  const [backImgSrcCustomer, setBackImgSrcCustomer] = useState(
    qcSheet?.drawings?.customer?.back?.file_url === null
      ? null
      : qcSheet?.drawings?.customer?.back?.file_url
  );
  const [frontCbValueCustomer, setFrontCheckboxValueCustomer] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.customer?.front?.is_checked
  );
  const [backCbValueCustomer, setBackCheckboxValueCustomer] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.customer?.back?.is_checked
  );
  const [operatingTimeValue, setOperatingTimeValue] = useState(
    qcSheet?.service_notes?.operating_time
  );
  const [showFrontDrawingModal, setShowFrontDrawingModal] = useState(false);
  const [showBackDrawingModal, setShowBackDrawingModal] = useState(false);
  const [otherValue, setOtherValue] = useState(qcSheet?.service_notes?.other);
  const [privateServiceNotes, setPrivateServerNotes] = useState(qcSheet?.private_service_notes);
  const [errMsg, setErrMsg] = useState("Failed to Save QC Sheet");
  const [successMsg, setSuccessMsg] = useState("Successfully Saved QC Sheet");
  const [notifTitle, setNotifTitle] = useState("Upload Document");
  const [showUpload, setShowUpload] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false);
  const [showCapturePhoto, setCapturePhoto] = useState(false);
  const [fileNameToRemove, setFilenameToRemove] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [responseDisplay, setResponseDisplay] = useState({});
  const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
  const [noChangeInConditionCb, setNoChangeInConditionCb] = useState(
    qcSheet?.no_change_in_condition === true
    // qcSheet?.no_change_in_condition === true ? false : qcSheet?.no_change_in_condition
  );
  const [changeInConditionCb, setChangeInConditionCb] = useState(
    qcSheet?.change_in_condition === true
    // qcSheet?.change_in_condition === true ? false : qcSheet?.change_in_condition
  );
  const [showChangeInConditionModal, setShowChangeInConditionModal] = useState(false);
  const [statusValue, setStatusValue] = useState(statusOptions[0]);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditedFrontImageInternal, setIsEditedFrontImageInternal] = useState(false);
  const [isEditedBackImageInternal, setIsEditedBackImageInternal] = useState(false);
  const [isEditedFrontImageCustomer, setIsEditedFrontImageCustomer] = useState(false);
  const [isEditedBackImageCustomer, setIsEditedBackImageCustomer] = useState(false);
  const [currentDrawing, setCurrentDrawing] = useState("");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenChangeInConditionModal = () => setShowChangeInConditionModal(true);
  const handleCloseChangeInConditionModal = () => setShowChangeInConditionModal(false);

  const handleOpenDialog = (value = "") => {
    setFilenameToRemove(value);
    setShowConfirmationDialogue(true);
  };
  const handleCloseDialog = () => setShowConfirmationDialogue(false);

  const handleOpenSuccessDialog = (value = "") => {
    setShowSuccessDialogue(true);
    playDoneSound();
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialogue(false);
    if (responseDisplay?.subitem_status === "awaiting_qc") {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE);
      setTimeout(() => {
        navigate(WAREHOUSE_QUALITY_CHECK.DETAIL_ROUTE, {
          state: { from: WAREHOUSE_QUALITY_CHECK?.LIST_ROUTE },
        });
      }, 100);
    } else {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE);
    }
  };

  const handleOpenCapturePhotoModal = () => {
    setCapturePhoto(true);
  };

  const handleCloseCapturePhotoModal = (action = "", status = "") => {
    switch (action) {
      case "success":
        setCapturePhoto(false);
        setSuccessMsg("Successfully Uploaded Photo.");
        openSuccessSB(true);
        // if (status === "on_rent") {
        //   handleOpenConfirmationDialogue();
        // } else {
        loadDetails();
        // }

        break;
      case "failed":
        setErrMsg("Failed to Upload File");
        openErrorSB(true);
        break;
      default:
        setCapturePhoto(false);
        break;
    }
  };

  const generateImageList = () => (
    <ImageList
      sx={{
        width: "auto",
        maxHeight: "400px",
        marginLeft: 2,
      }}
      cols={2}
    >
      {/* <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div">Attachments:</ListSubheader>
      </ImageListItem> */}
      {qcSheet?.attachments.map((item) => (
        <ImageListItem
          key={item.img}
          // onClick={() => window.open(item.file_url, "_blank")}
          height="auto"
          width="auto"
        >
          <MDBox>
            <Card
              sx={{
                borderRadius: "5%",
                border: "1px solid #adb1b8",
              }}
            >
              <CardMedia
                sx={{
                  display: "flex",
                  width: "auto",
                  height: "150px",
                }}
                image={item.file_url}
                title={item.file_name}
                onClick={() => window.open(item.file_url, "_blank")}
              />
              <CardActions>
                <Typography gutterBottom variant="caption" component="div">
                  {item.file_name}
                </Typography>
                <IconButton
                  color="error"
                  aria-label={`info about ${item.file_name}`}
                  onClick={() => {
                    // console.log(item?.file_name);
                    handleOpenDialog(item?.file_name);
                    // console.log({
                    //   file_name: item.file_name,
                    //   action: "remove",
                    // });
                  }}
                  size="large">
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              </CardActions>
            </Card>
          </MDBox>
        </ImageListItem>
      ))}
    </ImageList>
  );

  const handleOpenFrontDrawingModal = (action = "") => {
    // if (newQcSheet) {
    setCurrentDrawing(action);
    setShowFrontDrawingModal(true);
    // }
  };

  const handleOpenBackDrawingModal = (action = "") => {
    // if (newQcSheet) {
    setCurrentDrawing(action);
    setShowBackDrawingModal(true);
    // }
  };

  const handleCloseDrawingModal = (action = "", value = "") => {
    switch (action) {
      case drawingStateOptions[0]: // internal front
        // console.log(value);
        setFrontImgSrcInternal(value);
        setShowFrontDrawingModal(false);
        setIsEdited(true);
        setIsEditedFrontImageInternal(true);
        break;
      case drawingStateOptions[1]: // internal back
        // console.log(value);
        setBackImgSrcInternal(value);
        setShowBackDrawingModal(false);
        setIsEdited(true);
        setIsEditedBackImageInternal(true);
        break;
      case drawingStateOptions[2]: // cusstomer front
        // console.log(value);
        setFrontImgSrcCustomer(value);
        setShowFrontDrawingModal(false);
        setIsEdited(true);
        setIsEditedFrontImageCustomer(true);
        break;
      case drawingStateOptions[3]: // customer back
        // console.log(value);
        setBackImgSrcCustomer(value);
        setShowBackDrawingModal(false);
        setIsEdited(true);
        setIsEditedBackImageCustomer(true);
        break;
      default:
        setShowFrontDrawingModal(false);
        setShowBackDrawingModal(false);
        break;
    }
  };

  const handleOpenUpload = (attachmentType = "photo") => {
    setShowUpload(true);
    // setUploadData(attachmentType);
  };

  const handleCloseupload = (action = "", status = "") => {
    setNotifTitle("Upload Document");
    if (status === "uploaded") {
      setSuccessMsg("Successfully Uploaded File");
      openSuccessSB(true);
      loadDetails();
    }

    // if (status === "on_rent") {
    //   handleOpenConfirmationDialogue();
    // } else {
    // loadDetails();
    // }
    setShowUpload(false);
  };

  const handleRemoveItem = async () => {
    const body = {
      file_name: fileNameToRemove,
      action: "remove",
    };
    // console.log(body);
    const response = await httpRequest("remove-attachment-qc", body, { uuid: qcDetails?.uuid });

    // console.log(response);
    if (getResponseMsg(response) === "Success") {
      setSuccessMsg("Successfully removed attachment.");
      openSuccessSB(true);
      loadDetails();
    } else {
      const responseErrMsg = "Failed to Remove attachment.";
      // Object.entries(response.data).map((item) => {
      //   responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
      //   return 0;
      // });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    }
    handleCloseDialog();
  };

  const saveQcSheetWithCondition = async (action = "") => {
    document.body.style.cursor = "wait";
    let response = {};
    let responseNoChange = {};
    let responseChangeCondition = {};
    if (qcSheetDetails?.uuid === undefined) {
      response = await httpRequest(
        "save-qc-sheet",
        {
          sheet_type: "filter",
          technician: technicianValue || "",
          checklist: [],
          private_service_notes: privateServiceNotes,
          service_notes: {
            other: otherValue,
          },
          drawings: {
            internal: {
              front: { is_checked: frontCbValueInternal === true, file_url: frontImgSrcInternal },
              back: { is_checked: backCbValueInternal === true, file_url: backImgSrcInternal },
            },
            customer: {
              front: { is_checked: frontCbValueCustomer === true, file_url: frontImgSrcCustomer },
              back: { is_checked: backCbValueCustomer === true, file_url: backImgSrcCustomer },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    } else {
      response = await httpRequest(
        "update-qc-sheet",
        {
          sheet: qcSheetDetails?.uuid,
          sheet_type: "filter",
          technician: technicianValue || "",
          checklist: [],
          private_service_notes: privateServiceNotes,
          service_notes: {
            other: otherValue,
          },
          drawings: {
            internal: {
              front: {
                is_checked: frontCbValueInternal === true,
                file_url: frontImgSrcInternal,
                edited: isEditedFrontImageInternal,
              },
              back: {
                is_checked: backCbValueInternal === true,
                file_url: backImgSrcInternal,
                edited: isEditedBackImageInternal,
              },
            },
            customer: {
              front: {
                is_checked: frontCbValueCustomer === true,
                file_url: frontImgSrcCustomer,
                edited: isEditedFrontImageCustomer,
              },
              back: {
                is_checked: backCbValueCustomer === true,
                file_url: backImgSrcCustomer,
                edited: isEditedBackImageCustomer,
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    }

    if (getResponseMsg(response) === "Success") {
      setSuccessMsg("Successfully Saved all changes.");
      openSuccessSB(true);
      // loadDetails();
      setFrontImgSrcInternal(response?.data?.data?.drawings?.internal?.front?.file_url);
      setBackImgSrcInternal(response?.data?.data?.drawings?.internal?.back?.file_url);
      setFrontImgSrcCustomer(response?.data?.data?.drawings?.customer?.front?.file_url);
      setBackImgSrcCustomer(response?.data?.data?.drawings?.customer?.back?.file_url);
      setIsEdited(false);
      setIsEditedFrontImageInternal(false);
      setIsEditedBackImageInternal(false);
      setIsEditedFrontImageCustomer(false);
      setIsEditedBackImageCustomer(false);
      setQcSheetDetails(response?.data?.data);
      switch (action) {
        case "no-change":
          responseNoChange = await httpRequest(
            "conditions",
            {
              sheet: response?.data?.data?.uuid,
              change_in_condition: false,
            },
            { uuid: qcDetails?.uuid }
          );
          if (getResponseMsg(responseNoChange) === "Success") {
            setNoChangeInConditionCb(true);
            setResponseDisplay(responseNoChange?.data?.data);
            handleOpenSuccessDialog();
          } else {
            setNotifTitle("No Change in Condition");
            let responseErrMsg = "Failed to save changes.";
            Object.entries(responseNoChange.data).map((item) => {
              responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
              return 0;
            });
            setErrMsg(responseErrMsg);
            openErrorSB(true);
          }
          break;
        case "change":
          setNotifTitle("Change in Condition");
          responseChangeCondition = await httpRequest(
            "conditions",
            {
              sheet: response?.data?.data?.uuid,
              change_in_condition: true,
              status: statusValue?.value,
            },
            { uuid: qcDetails?.uuid }
          );
          if (getResponseMsg(responseChangeCondition) === "Success") {
            setShowChangeInConditionModal(false);
            setResponseDisplay(responseChangeCondition?.data?.data);
            handleOpenSuccessDialog();
          } else {
            let responseErrMsg = "Failed to save changes.";
            Object.entries(responseChangeCondition.data).map((item) => {
              responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
              return 0;
            });
            setErrMsg(responseErrMsg);
            openErrorSB(true);
          }
          break;
        default:
          break;
      }
    } else {
      let responseErrMsg = "QC Sheet not saved.";
      Object.entries(response.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    }
    document.body.style.cursor = "default";
  };

  useEffect(() => {
    document.body.style.cursor = "wait";
    if (newQcSheet) {
      setTechnicianValue(`${userLoggedin?.first_name} ${userLoggedin?.last_name}`);
    }
    document.body.style.cursor = "default";
  }, [userLoggedin]);

  useEffect(() => {
    document.body.style.cursor = "default";
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox m={2}>
      <MDBox display="flex" justifyContent="space-between">
        <MDTypography variant="h6" color="info">
          QC Checklist - Filters
        </MDTypography>
      </MDBox>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={4} md={6}>
          <MDTypography variant="button" fontWeight="regular">
            Date:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="medium">
            {formatDate(
              checkNullEmpty(qcSheetDetails?.date) ? getDateToday() : qcSheetDetails?.date,
              "MMMM DD, YYYY"
            )}
          </MDTypography>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <MDBox display="flex">
            <MDBox mr={1}>
              <MDTypography variant="button" fontWeight="regular">
                Technician:&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox>
              {/* {newQcSheet ? ( */}
              <MDInput
                value={technicianValue}
                disabled={disableFields}
                variant="standard"
                onChange={(e) => {
                  setTechnicianValue(e.target.value);
                  setIsEdited(true);
                }}
              />
              {/* ) : (
                <MDTypography variant="button" fontWeight="regular">
                  {technicianValue}
                </MDTypography>
              )} */}
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={8}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            {/* <Grid item xs={4} sm={8} md={12}>
              {generateChecklist()}
            </Grid> */}
            <Grid item xs={4} sm={8} md={6}>
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                mr={2}
                // sx={{ display: "absolute", marginLeft: "auto", marginRight: "auto", width: "40% " }}
              >
                <MDBox>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      Front (Internal)
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox display="flex" alignItems="flex-end" mt={-1}>
                      <MDBox>
                        {/* <img src={frontImgSrc} alt="car" /> */}
                        <CardMedia
                          component="img"
                          sx={{
                            display: "flex",
                            width: "auto",
                            height: "150px",
                            // backgroundColor: "gray",
                            borderRadius: "0%",
                            // borderStyle: "solid",
                          }}
                          image={frontImgSrcInternal || Rectangles}
                          onClick={() => {
                            if (!disableFields) {
                              handleOpenFrontDrawingModal(drawingStateOptions[0]);
                            }
                          }}
                          alt="filter"
                        />
                      </MDBox>
                      <MDBox>
                        <Checkbox
                          sx={{
                            border: "1px solid #4287f5",
                            borderRadius: "30%",
                            margin: "0",
                            p: 0,
                          }}
                          disabled={disableFields}
                          onChange={(e) => {
                            setFrontCheckboxValueInternal(e.target.checked);
                            setIsEdited(true);
                            // console.log(index, list?.name, e.target.checked);
                            // updateState(index, list?.name, e.target.checked);
                          }}
                          checked={frontCbValueInternal}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </MDBox>
                    </MDBox>
                    {isEditedFrontImageInternal && (
                      <MDBox>
                        <MDTypography variant="caption" fontWeight="regular" color="warning">
                          * Image is edited. Please save to avoid data loss. *
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={8} md={6}>
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                // sx={{ display: "absolute", marginLeft: "auto", marginRight: "auto", width: "40% " }}
              >
                <MDBox>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      Back (Internal)
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox display="flex" alignItems="flex-end" mt={-1}>
                      <MDBox>
                        {/* <img src={frontImgSrc} alt="car" /> */}
                        <CardMedia
                          component="img"
                          sx={{
                            display: "flex",
                            width: "auto",
                            height: "150px",
                            // backgroundColor: "gray",
                            borderRadius: "0%",
                            // borderStyle: "solid",
                          }}
                          image={backImgSrcInternal || Rectangles}
                          onClick={() => {
                            if (!disableFields) {
                              handleOpenBackDrawingModal(drawingStateOptions[1]);
                            }
                          }}
                          alt="evf"
                        />
                      </MDBox>
                      <MDBox>
                        <Checkbox
                          sx={{
                            border: "1px solid #4287f5",
                            borderRadius: "30%",
                            margin: "0",
                            p: 0,
                          }}
                          disabled={disableFields}
                          onChange={(e) => {
                            setBackCheckboxValueInternal(e.target.checked);
                            setIsEdited(true);
                            // console.log(index, list?.name, e.target.checked);
                            // updateState(index, list?.name, e.target.checked);
                          }}
                          checked={backCbValueInternal}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </MDBox>
                    </MDBox>
                    {isEditedBackImageInternal && (
                      <MDBox>
                        <MDTypography variant="caption" fontWeight="regular" color="warning">
                          * Image is edited. Please save to avoid data loss. *
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            {/* <Grid item xs={4} sm={8} md={12}>
              {generateChecklist()}
            </Grid> */}
            <Grid item xs={4} sm={8} md={6}>
              <MDBox mt={2}>
                <hr
                  style={{
                    // background: "lime",
                    // color: "lime",
                    // borderColor: "lime",
                    height: "1px",
                  }}
                />
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                mr={2}
                // sx={{ display: "absolute", marginLeft: "auto", marginRight: "auto", width: "40% " }}
              >
                <MDBox>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      Front (Customer)
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox display="flex" alignItems="flex-end" mt={-1}>
                      <MDBox>
                        {/* <img src={frontImgSrc} alt="car" /> */}
                        <CardMedia
                          component="img"
                          sx={{
                            display: "flex",
                            width: "auto",
                            height: "150px",
                            // backgroundColor: "gray",
                            borderRadius: "0%",
                            // borderStyle: "solid",
                          }}
                          image={frontImgSrcCustomer || Rectangles}
                          onClick={() => {
                            if (!disableFields) {
                              handleOpenFrontDrawingModal(drawingStateOptions[2]);
                            }
                          }}
                          alt="filter"
                        />
                      </MDBox>
                      <MDBox>
                        <Checkbox
                          sx={{
                            border: "1px solid #4287f5",
                            borderRadius: "30%",
                            margin: "0",
                            p: 0,
                          }}
                          disabled={disableFields}
                          onChange={(e) => {
                            setFrontCheckboxValueCustomer(e.target.checked);
                            setIsEdited(true);
                            // console.log(index, list?.name, e.target.checked);
                            // updateState(index, list?.name, e.target.checked);
                          }}
                          checked={frontCbValueCustomer}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </MDBox>
                    </MDBox>
                    {isEditedFrontImageCustomer && (
                      <MDBox>
                        <MDTypography variant="caption" fontWeight="regular" color="warning">
                          * Image is edited. Please save to avoid data loss. *
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={8} md={6}>
              {!isMobile && (
                <MDBox mt={2}>
                  <hr
                    style={{
                      // background: "lime",
                      // color: "lime",
                      // borderColor: "lime",
                      height: "1px",
                    }}
                  />
                </MDBox>
              )}
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                // sx={{ display: "absolute", marginLeft: "auto", marginRight: "auto", width: "40% " }}
              >
                <MDBox>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      Back (Customer)
                    </MDTypography>
                  </MDBox>
                  <MDBox>
                    <MDBox display="flex" alignItems="flex-end" mt={-1}>
                      <MDBox>
                        {/* <img src={frontImgSrc} alt="car" /> */}
                        <CardMedia
                          component="img"
                          sx={{
                            display: "flex",
                            width: "auto",
                            height: "150px",
                            // backgroundColor: "gray",
                            borderRadius: "0%",
                            // borderStyle: "solid",
                          }}
                          image={backImgSrcCustomer || Rectangles}
                          onClick={() => {
                            if (!disableFields) {
                              handleOpenBackDrawingModal(drawingStateOptions[3]);
                            }
                          }}
                          alt="evf"
                        />
                      </MDBox>
                      <MDBox>
                        <Checkbox
                          sx={{
                            border: "1px solid #4287f5",
                            borderRadius: "30%",
                            margin: "0",
                            p: 0,
                          }}
                          disabled={disableFields}
                          onChange={(e) => {
                            setBackCheckboxValueCustomer(e.target.checked);
                            setIsEdited(true);
                            // console.log(index, list?.name, e.target.checked);
                            // updateState(index, list?.name, e.target.checked);
                          }}
                          checked={backCbValueCustomer}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </MDBox>
                    </MDBox>
                    {isEditedBackImageCustomer && (
                      <MDBox>
                        <MDTypography variant="caption" fontWeight="regular" color="warning">
                          * Image is edited. Please save to avoid data loss. *
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                  Service Notes
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      Other:&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    {/* {newQcSheet ? ( */}
                    <MDEditor
                      readOnly={disableFields}
                      value={otherValue}
                      onChange={(e) => {
                        setOtherValue(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                    {/* ) : (
                      <MDTypography variant="button" fontWeight="regular">
                        {otherValue}
                      </MDTypography>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12} mt={2}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Private Service Notes
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    {/* {newQcSheet ? ( */}
                    <MDEditor
                      readOnly={disableFields}
                      value={privateServiceNotes}
                      onChange={(e) => {
                        setPrivateServerNotes(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                    {/* ) : (
                      <MDTypography variant="button" fontWeight="regular">
                        {otherValue}
                      </MDTypography>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={12} mt={2}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDBox display="flex">
                  <MDTypography variant="h6" fontWeight="medium">
                    Item Condition
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={6}>
                  <Checkbox
                    checked={noChangeInConditionCb}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ border: "1px solid #4287f5", borderRadius: "30%", p: 0 }}
                    onClick={() => saveQcSheetWithCondition("no-change")}
                    // disabled={qcSheet?.uuid === undefined || disableFields}
                  />
                  <MDTypography variant="caption" fontWeight="regular" ml={2}>
                    No change in condition&nbsp;
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={6}>
                  <Checkbox
                    checked={changeInConditionCb}
                    inputProps={{ "aria-label": "controlled" }}
                    // disabled={qcSheet?.uuid === undefined || disableFields}
                    onClick={handleOpenChangeInConditionModal}
                    sx={{ border: "1px solid #4287f5", borderRadius: "30%", p: 0 }}
                  />
                  <MDTypography variant="caption" fontWeight="regular" ml={2}>
                    Change in condition&nbsp;
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ textAlign: "center" }}
                mt={2}
              >
                <MDBox mr={2}>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleOpenCapturePhotoModal}
                    disabled={disableFields}
                  >
                    Capture
                  </MDButton>
                </MDBox>
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleOpenUpload}
                    disabled={disableFields}
                  >
                    Upload
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox>
                {qcSheet?.attachments?.length === 0 ? (
                  <MDBox mr={1} sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      No Attachments
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDBox mr={1} mt={2}>
                    {qcSheet?.attachments?.length > 0 && generateImageList()}
                  </MDBox>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* <MDBox display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <MDBox>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center">
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={saveQcSheet}
              disabled={disableFields}
            >
              Save
            </MDButton>
          </MDBox>
          {(isEdited ||
            isEditedFrontImageInternal ||
            isEditedBackImageInternal ||
            isEditedFrontImageCustomer ||
            isEditedBackImageCustomer) && (
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular" color="warning">
                * You have unsave changes *
              </MDTypography>
            </MDBox>
          )}
        </MDBox>
      </MDBox> */}
      {showFrontDrawingModal && (
        <Modal
          open={showFrontDrawingModal}
          onClose={handleCloseDrawingModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={style}>
            <FrontDrawing
              loadDetails={loadDetails}
              handleCloseModal={handleCloseDrawingModal}
              // imgSrc={frontImgSrc}
              // imgSrc={frontImgSrc?.includes("data:image/png;base64") ? frontImgSrc : null}
              currentDrawing={currentDrawing}
              imgSrc={
                currentDrawing === drawingStateOptions[0] &&
                frontImgSrcInternal?.includes("data:image/png;base64")
                  ? frontImgSrcInternal
                  : currentDrawing === drawingStateOptions[2] &&
                    frontImgSrcCustomer?.includes("data:image/png;base64")
                  ? frontImgSrcCustomer
                  : ""
              }
            />
          </MDBox>
        </Modal>
      )}
      {showBackDrawingModal && (
        <Modal
          open={showBackDrawingModal}
          onClose={handleCloseDrawingModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={style}>
            <BackDrawing
              loadDetails={loadDetails}
              handleCloseModal={handleCloseDrawingModal}
              // imgSrc={backImgSrc}
              // imgSrc={backImgSrc?.includes("data:image/png;base64") ? backImgSrc : null}
              currentDrawing={currentDrawing}
              imgSrc={
                currentDrawing === drawingStateOptions[1] &&
                backImgSrcInternal?.includes("data:image/png;base64")
                  ? backImgSrcInternal
                  : currentDrawing === drawingStateOptions[3] &&
                    backImgSrcCustomer?.includes("data:image/png;base64")
                  ? backImgSrcCustomer
                  : ""
              }
            />
          </MDBox>
        </Modal>
      )}

      {showCapturePhoto && (
        <Modal
          open={showCapturePhoto}
          onClose={handleCloseCapturePhotoModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={style}>
            <Photo
              loadDetails={loadDetails}
              handleCloseModal={handleCloseCapturePhotoModal}
              // changeCamera={changeCamera}
              // facingModeValue={facingMode}
              // photoType={photoType}
            />
          </MDBox>
        </Modal>
      )}

      {showUpload && (
        <Modal
          open={showUpload}
          onClose={handleCloseupload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={isMobile ? styleUploadMobile : styleUploadWeb}>
            <DragAndDrop
              uuid={qcDetails?.uuid}
              // attachmentType={uploadData}
              onClose={handleCloseupload}
              multiple
            />
          </MDBox>
        </Modal>
      )}

      {showConfirmationDialogue && (
        // <div>
        <Dialog open={showConfirmationDialogue} onClose={handleCloseDialog}>
          <DialogTitle>Remove Attachment</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to remove this attachment?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handleRemoveItem()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}

      {showSuccessDialogue && (
        <Dialog
          open={showSuccessDialogue}
          onClose={handleCloseSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{notifTitle}</DialogTitle>
          <DialogContent>
            {responseDisplay?.subitem_status === "awaiting_qc" && (
              <DialogContentText id="alert-dialog-description">
                ♦ QC sheet will reset.
              </DialogContentText>
            )}
            {responseDisplay?.subitem_status !== "awaiting_qc" && (
              <DialogContentText id="alert-dialog-description">
                ♦ Sub-item status is now&nbsp;
                {capitalizeFirstLetter(responseDisplay?.subitem_status).replaceAll("_", " ")}.
              </DialogContentText>
            )}
            {responseDisplay?.order_status === "ready_to_invoice" && (
              <DialogContentText id="alert-dialog-description">
                ♦ Order status is now&nbsp;
                {capitalizeFirstLetter(responseDisplay?.order_status).replaceAll("_", " ")}.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseSuccessDialog} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      {showChangeInConditionModal && (
        <Dialog open={showChangeInConditionModal} onClose={handleCloseChangeInConditionModal}>
          <DialogTitle>Change in condition</DialogTitle>
          <DialogContent>
            <DialogContentText>Change status of this sub-item.</DialogContentText>
            <MDBox>
              <Autocomplete
                scroll="body"
                value={statusValue || null}
                options={statusOptions}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setStatusValue(newValue);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => option.name || ""}
                fullWidth
                getOptionDisabled={(option) => option.value === statusValue.value}
                renderInput={(params) => <TextField {...params} />}
              />
            </MDBox>
            {/* </Box> */}
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseChangeInConditionModal}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => saveQcSheetWithCondition("change")}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title="QC Sheet"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="QC Sheet"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}
Filters.defaultProps = {
  data: {},
  disableFields: false,
};

Filters.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
  disableFields: PropTypes.bool,
};
export default Filters;
