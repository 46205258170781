/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SuccessHuman from "assets/sounds/successhuman.mp3";

import CustomModal from "components/CustomModal";
import TabPanel from "components/CustomTabPanel";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import SubItemListModal from "./components/Modals/SubItemListModal";
import RentItemTable from "./components/RentItemTable";

const { hasPermission, getStorageItem } = InventoryMgr;
const { httpRequest, getResponseMsg, WAREHOUSE_STAGE } = WarehouseMgr;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RentItems({ data, setData, loadDetails }) {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const { items, staged_items, pending_items } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [playSuccessHuman] = useSound(SuccessHuman);

  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [subitemDetail, setSubitemDetail] = useState({});
  const [showItemList, setShowItemList] = useState(false);
  const [search, setSearch] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Failed");
  // const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item staging");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [confirmationDialogue, setConfirmationDialogue] = useState(false);

  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openSuccessSB = () => setSuccessSB(true);

  const handleOpenConfirmationDialogue = () => {
    setShowItemList(false);
    setConfirmationDialogue(true);
  };

  const handleCloseConfirmationDialogue = () => {
    setConfirmationDialogue(false);
    navigate(WAREHOUSE_STAGE?.LIST_ROUTE);
  };

  const handleOpenItemlistModal = (value) => {
    setSubitemDetail(value);
    setShowItemList(true);
  };

  const handleCloseItemlistModal = () => {
    setShowItemList(false);
  };

  const handleCloseScanBarcodeModal = () => {
    setSearch("");
  };

  const handleAddItem = (obj) => {
    if (obj?.uuid !== undefined) {
      pending_items[subitemDetail?.index].subitems.push(obj);
      setData({ ...data, items });

      if (
        pending_items[subitemDetail?.index].subitems?.length >=
        pending_items[subitemDetail?.index].quantity
      ) {
        handleCloseScanBarcodeModal();
        setShowItemList(false);
      }
      if (obj?.order_status === "staged") {
        handleOpenConfirmationDialogue();
      } else {
        loadDetails();
      }
    }
  };

  const dataTableDataPending = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox
              direction="column"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="button" color="error" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>

                {row?.original?.item?.item_type?.value !== "kit" && (
                  <MDTypography variant="button" color="inherit" fontWeight="regular">
                    &nbsp; ({row.original?.subitems?.length || 0} / {row.original?.quantity})
                  </MDTypography>
                )}
              </MDBox>
              <MDBox ml={2}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  // onClick={() => console.log(row.original)}
                  onClick={() => {
                    handleOpenItemlistModal({ ...row?.original, index: row?.index });
                  }}
                  disabled={(row.original?.subitems?.length || 0) >= row.original?.quantity}
                >
                  Assign barcode
                </MDButton>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : staged_items || [],
  };

  const dataTableDataStaged = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox
              direction="column"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="button" color="success" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>

                {row?.original?.item?.item_type?.value !== "kit" && (
                  <MDTypography variant="button" color="inherit" fontWeight="regular">
                    &nbsp; ({row.original?.subitems?.length || 0} / {row.original?.quantity})
                  </MDTypography>
                )}
              </MDBox>
              <MDBox ml={2}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  // onClick={() => console.log(row.original)}
                  onClick={() => {
                    handleOpenItemlistModal({ ...row?.original, index: row?.index });
                  }}
                  disabled={(row.original?.subitems?.length || 0) >= row.original?.quantity}
                >
                  Assign barcode
                </MDButton>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : staged_items || [],
  };

  const dataTableDataMobilePending = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox display="flex " justifyContent="space-between" alignItems="center">
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox>
                <MDTypography variant="overline" color="error" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
                <MDTypography variant="overline" color="inherit" fontWeight="regular">
                  &nbsp;({row.original?.subitems?.length || 0}/{row.original?.quantity})
                </MDTypography>
              </MDBox>
              <MDBox ml={2}>
                <Tooltip title="Manual Adding of Barcoded Items" placement="top">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    color="dark"
                    onClick={() => {
                      handleOpenItemlistModal({ ...row?.original, index: row?.index });
                    }}
                    disabled={(row.original?.subitems?.length || 0) >= row.original?.quantity}
                  >
                    <Icon fontSize="small" color="dark">
                      assignment_turned_in
                    </Icon>
                  </IconButton>
                </Tooltip>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : staged_items || [],
  };

  const dataTableDataMobileStaged = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox display="flex " justifyContent="space-between" alignItems="center">
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox>
                <MDTypography variant="overline" color="success" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
                <MDTypography variant="overline" color="inherit" fontWeight="regular">
                  &nbsp;({row.original?.subitems?.length || 0}/{row.original?.quantity})
                </MDTypography>
              </MDBox>
              <MDBox ml={2}>
                <Tooltip title="Manual Adding of Barcoded Items" placement="top">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    color="dark"
                    onClick={() => {
                      handleOpenItemlistModal({ ...row?.original, index: row?.index });
                    }}
                    disabled={(row.original?.subitems?.length || 0) >= row.original?.quantity}
                  >
                    <Icon fontSize="small" color="dark">
                      assignment_turned_in
                    </Icon>
                  </IconButton>
                </Tooltip>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : staged_items || [],
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const searchBarcode = async (value = "") => {
    const response = await httpRequest("stage-item", {
      order: data?.uuid || "",
      barcode_id: value || "",
    });
    if (getResponseMsg(response) === "Success") {
      if (response.data.data?.order_status === "staged") {
        handleOpenConfirmationDialogue();
      }

      // playSuccessHuman();
      setSuccessMsg("Staged item successfully.");
      openSuccessSB();
      loadDetails();
    } else {
      let responseErrMsg = "";
      Object.entries(response?.data).map((item) => {
        responseErrMsg = `${item[1]}`;
        return 0;
      });
      setErrMsg(responseErrMsg || "Item with this code is not found");
      openErrorSB();
    }
    setSearch("");
  };

  return (
    <MDBox>
      <MDBox sx={{ width: "100%" }}>
        <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Pending" {...a11yProps(0)} />
            <Tab label="Staged" {...a11yProps(1)} />
          </Tabs>
        </MDBox>
        {isMobile && (
          <MDBox
            direction="column"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox fullWidth width="100%" mt={1} mb={2}>
              <MDBox>
                <MDInput
                  autoFocus
                  placeholder="Scan Barcode Here..."
                  value={search}
                  type="search"
                  fullWidth
                  width="30rem"
                  onChange={async ({ currentTarget }) => {
                    searchBarcode(currentTarget.value);
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        {!isMobile && (
          <MDBox
            direction="column"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox fullWidth width="100%" mt={1} mb={2}>
              <MDBox>
                <MDInput
                  autoFocus
                  placeholder="Scan Barcode Here..."
                  value={search}
                  type="search"
                  fullWidth
                  width="30rem"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      searchBarcode(e.target.value);
                    }
                  }}
                  onChange={async ({ currentTarget }) => {
                    setSearch(currentTarget.value);
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        <MDBox ml={2}>
          <MDTypography variant="h6" color="info">
            Items to be rented
          </MDTypography>
        </MDBox>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={0}>
            <RentItemTable
              table={isMobile ? dataTableDataMobilePending : dataTableDataPending}
              entriesPerPage={false}
              isSorted={false}
              canSearch={false}
              hasCollapse
              loadDetails={loadDetails}
              handleOpenItemlistModal={handleOpenItemlistModal}
              tabName="pending"
            />
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={0}>
            <RentItemTable
              table={isMobile ? dataTableDataMobileStaged : dataTableDataStaged}
              entriesPerPage={false}
              isSorted={false}
              canSearch={false}
              hasCollapse
              loadDetails={loadDetails}
              handleOpenItemlistModal={handleOpenItemlistModal}
              tabName="staged"
            />
          </Grid>
        </TabPanel>
      </MDBox>
      {showItemList && (
        <CustomModal
          showModal={showItemList}
          handleHideModal={handleCloseItemlistModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "98%",
            width: "98%",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <SubItemListModal
            handleClose={handleCloseItemlistModal}
            handleAddItem={handleAddItem}
            subitemData={subitemDetail}
            itemUuid={subitemDetail?.item?.uuid || ""}
          />
        </CustomModal>
      )}
      {confirmationDialogue && (
        <Dialog
          open={confirmationDialogue}
          onClose={handleCloseConfirmationDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Order is now Staged</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All items has been staged. Order is now ready for Check-out.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseConfirmationDialogue} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
        verticalPosition={isMobile ? "top" : "bottom"}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
        verticalPosition={isMobile ? "top" : "bottom"}
      />
    </MDBox>
  );
}

RentItems.defaultProps = {
  data: {},
};

RentItems.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  setData: PropTypes.func.isRequired,
  loadDetails: PropTypes.func.isRequired,
};

export default RentItems;
