/* eslint-disable no-unused-vars */

import { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
// import DataTable from "components/Tables/DataTable";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  Switch,
} from "@mui/material";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import MDButton from "components/MDButton";

const { getStorageItem, preventNegativeInput } = InventoryMgr;

function SubItem({ formData, consignerListRedux }) {
  const { values } = formData;
  const { subitems: subitemsV } = values;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const [subItemList, setSubItemList] = useState(itemDetail?.subitems || subitemsV);
  const [tmpSubItemList, settmpSubItemList] = useState(subItemList);
  const [addRowSize, setAddRowSize] = useState(0);
  const subitemNew = {
    barcode_id: "",
    serial_number: "",
    name: "",
    consigner: null,
    is_owned: true,
    no_barcode_serial: true,
    is_new: true,
    status: { name: "Available to Rent", value: "available" },
  };

  const handleItems = (
    action = "",
    rest = {
      subitem: subitemNew,
    }
  ) => {
    document.body.style.cursor = "wait";
    let newSubItems = [];
    let temp = {};
    switch (action) {
      case "add-sub-item":
        setSubItemList([rest?.subitem, ...subItemList]);
        values.subitems = [rest?.subitem, ...subItemList];
        break;

      case "add-sub-item-multiple":
        for (let index = 0; index < addRowSize; index += 1) {
          newSubItems.push(rest?.subitem);
        }
        setSubItemList([...newSubItems, ...subItemList]);
        values.subitems = [...newSubItems, ...subItemList];
        break;

      case "edit-sub-item":
        newSubItems = subItemList.map((subitem, index) => {
          if (rest?.column === "is_owned" && rest?.row?.index === index) {
            if (rest?.value === true) {
              temp = {
                ...subitem,
                [rest?.column]: rest?.value,
                consigner: null,
                uuid: rest?.row?.original?.uuid || null,
              };
            } else {
              temp = {
                ...subitem,
                [rest?.column]: rest?.value,
                uuid: rest?.row?.original?.uuid || null,
              };
            }
            return temp;
          }

          if (rest?.column === "no_barcode_serial" && rest?.row?.index === index) {
            if (rest?.value === true) {
              temp = {
                ...subitem,
                [rest?.column]: rest?.value,
                barcode_id: "",
                serial_number: "",
                consigner: null,
                uuid: rest?.row?.original?.uuid || null,
              };
            } else {
              temp = {
                ...subitem,
                [rest?.column]: rest?.value,
                uuid: rest?.row?.original?.uuid || null,
              };
            }
            return temp;
          }

          if (rest?.row?.index === index) {
            return {
              ...subitem,
              [rest?.column]: rest?.value,
              uuid: rest?.row?.original?.uuid || null,
            };
          }

          return subitem;
        });
        setSubItemList(newSubItems);
        values.subitems = newSubItems;

        break;

      case "remove-sub-item":
        newSubItems = subItemList.filter((subitem, index) => index !== rest?.row?.index);
        setSubItemList(newSubItems);
        values.subitems = newSubItems;

        // newSubItems = subItemList;
        // newSubItems.splice(rest?.row?.index, 1);
        // setSubItemList(newSubItems);
        // values.subitems = newSubItems;

        break;

      default:
        break;
    }
    setTimeout(() => {
      document.body.style.cursor = "default";
    }, 1000);
  };

  const statusOptions = [
    { name: "Available to Rent", value: "available" },
    { name: "Reserved", value: "reserved" },
    { name: "Staged", value: "staged" },
    { name: "On Rent", value: "on_rent" },
    { name: "Late", value: "late" },
    { name: "Returned", value: "returned" },
    { name: "In Repair", value: "in_repair" },
    { name: "Retired", value: "retired" },
    { name: "Awaiting QC", value: "awaiting_qc" },
    { name: "Sold", value: "sold" },
  ];

  const updateState = (index, name, value) => {
    // UPDATE with render
    // const newArray = subItemList.map((item, i) => {
    //   // console.log(item, i, index, index === i);
    //   if (index === i) {
    //     return { ...item, [name]: value };
    //   }
    //   return item;
    // });
    // settmpSubItemList(newArray);
    // values.subitems = newArray;

    // UPDATE without render

    subItemList[index] = {
      ...subItemList[index],
      [name]: value,
    };

    values.subitems = subItemList;

    // console.log(subItemList);
  };

  const dataTableData = {
    columns: [
      {
        Header: "Item Info",
        accessor: "serial_number",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [noSerialBarcode, setNoSerialBarcode] = useState(row?.original?.no_barcode_serial);
          const [serialNuber, setSerialNumber] = useState(value);
          const [barcodeId, setBarcodeId] = useState(row?.original?.barcode_id);

          const handleOnCheck = (e) => {
            setNoSerialBarcode(e.target.checked);

            updateState(row?.index, "no_barcode_serial", e.target.checked);

            if (e.target.checked === true) {
              setSerialNumber("");
              setBarcodeId("");
              updateState(row?.index, "barcode_id", "");
              updateState(row?.index, "serial_number", "");
            } else {
              setSerialNumber("");
              setBarcodeId("");
            }

            // handleItems("edit-sub-item", {
            //   row,
            //   column: "no_barcode_serial",
            //   value: e.target.checked,
            // });
          };

          const status = (
            <MDBox>
              {row?.original?.status?.value !== "retired" ? (
                <MDBox
                  display="flex"
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <MDBox mr={2}>
                    <MDInput
                      value={barcodeId}
                      onChange={(e) => {
                        setBarcodeId(e.target.value);
                        updateState(row?.index, "barcode_id", e.target.value);
                      }}
                      label="Barcode No."
                      error={!noSerialBarcode && barcodeId === "" && serialNuber === ""}
                      disabled={noSerialBarcode}
                    />
                  </MDBox>
                  <MDBox>
                    <MDInput
                      value={serialNuber}
                      onChange={(e) => {
                        setSerialNumber(e.target.value);
                        updateState(row?.index, "serial_number", e.target.value);
                      }}
                      // disabled={row?.original?.no_barcode_serial}
                      error={!noSerialBarcode && barcodeId === "" && serialNuber === ""}
                      disabled={noSerialBarcode}
                      label="Serial No."
                      // onBlur={handleOnBlur}
                    />
                  </MDBox>

                  {/* {itemDetail?.pageState === "NEW" && ( */}
                  <MDBox ml={2}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox checked={noSerialBarcode} onChange={handleOnCheck} />}
                        label="No available barcode/serial number"
                      />
                    </FormGroup>
                  </MDBox>
                  {/* )} */}
                </MDBox>
              ) : (
                <MDBox>
                  {/* <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                      {barcodeId}
                    </MDTypography>
                  </MDBox> */}
                  <MDBox>
                    <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                      {serialNuber}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              )}
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Name",
        accessor: "name",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [name, setName] = useState(value);

          const status = (
            <MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                  {value}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Owned",
        accessor: "is_owned",
        width: "35%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [isOwned, setIsOwned] = useState(value);
          const [consignervalue, setConsignerValue] = useState(row?.original?.consigner);

          const handleOnCheck = (e) => {
            setIsOwned(e.target.checked);

            updateState(row?.index, column?.id, e.target.checked);
            if (e.target.checked === true) {
              setConsignerValue(null);
              updateState(row?.index, "consigner", null);
            }
            // handleItems("edit-sub-item", { row, column: column?.id, value: e.target.checked });
          };

          const status = (
            <MDBox display="flex" justifyContent="space-between">
              {row?.original?.status?.value !== "retired" ? (
                <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                  <MDBox>
                    <Switch checked={isOwned} onChange={(e) => handleOnCheck(e)} />
                  </MDBox>
                  <MDBox lineHeight={0}>
                    <MDTypography variant="button" color="text">
                      {isOwned ? "Owned" : "Consigned by "}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox display="flex" justifyContent="space-between">
                  <IconButton size="small" aria-label="edit" disabled>
                    <Icon color={value <= 0 ? "error" : "success"} fontSize="medium">
                      square
                    </Icon>
                  </IconButton>
                  <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                    {value <= 0 ? "Consigned by " : "Owned"}
                  </MDTypography>
                </MDBox>
              )}
              {isOwned !== true ? (
                <MDBox ml={1} width="10rem">
                  {row?.original?.status?.value !== "retired" ? (
                    <MDBox>
                      <Autocomplete
                        fullWidth
                        disablePortal
                        options={consignerListRedux || null}
                        value={consignervalue}
                        isOptionEqualToValue={(option, val) =>
                          val !== "" ? option.uuid === val.uuid : option.uuid
                        }
                        getOptionLabel={(option) => (option ? option.full_name || option.name : "")}
                        renderInput={(params) => (
                          <MDInput {...params} variant="standard" width={300} />
                        )}
                        onChange={(event, newValue) => {
                          // handleChangeAutoComplete(event, newValue);
                          setConsignerValue(newValue);
                          updateState(row?.index, "consigner", newValue);
                        }}
                      />
                      {consignervalue === null && (
                        <MDBox>
                          <MDTypography variant="caption" color="error">
                            Consigner cannot be empty
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  ) : (
                    <MDBox>
                      <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                        {consignervalue?.full_name}
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              ) : (
                <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                  {consignervalue?.name}
                </MDTypography>
              )}
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "status",
        accessor: "status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, column, value }) => {
          const [statusValue, setStatusValue] = useState(value);
          const [loading, setLoading] = useState(false);
          const handleChangeAutoComplete = async (e, val) => {
            // handleItems("edit-sub-item", {
            //   row,
            //   column: column?.id,
            //   value: val || { name: "Available to Rent", value: "available" },
            // });

            if (val?.value === "retired" || statusValue?.value === "retired") {
              handleItems("edit-sub-item", {
                row,
                column: column?.id,
                value: val || { name: "Available to Rent", value: "available" },
              });
            } else {
              updateState(
                row?.index,
                column?.id,
                val || { name: "Available to Rent", value: "available" }
              );
            }
          };

          const status = (
            <MDBox width="10rem">
              {loading ? (
                <CircularProgress color="secondary" />
              ) : (
                <MDBox>
                  <Autocomplete
                    fullWidth
                    // disablePortal
                    options={statusOptions || null}
                    value={statusValue}
                    isOptionEqualToValue={(option, val) =>
                      val !== "" ? option.name === val.name : option.name
                    }
                    getOptionLabel={(option) => (option ? option.name : "")}
                    renderInput={(params) => <MDInput {...params} variant="standard" />}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        setLoading(true);
                        setStatusValue(newValue);

                        // updateState(row?.index, column?.id, newValue);
                        handleChangeAutoComplete(event, newValue);
                        setTimeout(() => {
                          // window.scrollTo(0, 0);
                          setLoading(false);
                        }, 500);
                      }
                    }}
                    disabled={itemDetail?.pageState === "NEW"}
                  />
                  {statusValue === null && (
                    <MDBox>
                      <MDTypography variant="caption" color="error">
                        Status cannot be empty
                      </MDTypography>
                    </MDBox>
                  )}
                </MDBox>
              )}
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "notes",
        accessor: "notes",
        width: "20%",
        headerAlign: "left",
        Cell: ({ row, value, column }) => {
          const [notes, setNotes] = useState(value);

          const status = (
            <MDBox>
              <MDBox
                // display="flex"
                direction="column"
                justifyContent="center"
                alignItems="center"
                textAlign="center"
              >
                <MDBox>
                  <MDInput
                    value={notes}
                    onChange={(e) => {
                      setNotes(e.target.value);
                      updateState(row?.index, column?.id, e.target.value);
                    }}
                    // onBlur={handleOnBlur}
                  />
                </MDBox>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "action",
        accessor: "",
        width: "20%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const handleRemove = () => {
            handleItems("remove-sub-item", { row });
            // const newSubItems = subItemList.filter((subitem, index) => index !== row?.index);
            // // newSubItems.splice(row?.index, 1);
            // setSubItemList(newSubItems);
            // values.subitems = newSubItems;
          };

          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              {(row?.original?.uuid === null || row?.original?.uuid === undefined) && (
                <IconButton size="small" aria-label="edit" color="error" onClick={handleRemove}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              )}
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: subItemList,
  };

  useEffect(() => {
    if (itemDetail?.pageState === "NEW") {
      setSubItemList([subitemNew]);

      values.subitems = [subitemNew];
    }
  }, []);

  return (
    <MDBox>
      <MDBox pl={3} display="flex" justifyContent="space-between">
        <MDBox display="flex" justifyContent="flex-start">
          <MDTypography variant="h5" fontWeight="medium">
            Quantity ({subItemList?.length})
          </MDTypography>
          <MDBox mt={-0.5}>
            <IconButton
              size="small"
              aria-label="edit"
              color="info"
              onClick={() => handleItems("add-sub-item")}
            >
              <Icon fontSize="small">add_circle</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox width="5rem" mx={1}>
            <MDInput
              type="number"
              size="small"
              value={addRowSize}
              onChange={(e) => {
                const val = preventNegativeInput(e.target.value);
                setAddRowSize(val);
              }}
            />
          </MDBox>
          <MDBox>
            <MDButton
              size="small"
              variant="gradient"
              color="info"
              onClick={() => {
                handleItems("add-sub-item-multiple");
              }}
              disabled={addRowSize <= 0}
              sx={{ marginRight: 1 }}
            >
              Add Rows
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      {useMemo(
        () => (
          <DataTable
            table={dataTableData}
            // entriesPerPage={{ defaultValue: 100 }}
            // showTotalEntries={false}
            isSorted={false}
            canSearch={false}
            hiddenCols={["is_new"]}
          />
        ),
        [dataTableData]
      )}
    </MDBox>
  );
}

// Setting default values for the props of DashboardNavbar
SubItem.defaultProps = {
  consignerListRedux: [{}],
  itemData: {},
};

// typechecking props for UserInfo
SubItem.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  consignerListRedux: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  itemData: PropTypes.objectOf(PropTypes.any),
  setItemData: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  consignerListRedux: state?.helper?.consignerList,
});

export default connect(mapStateToProps, null)(SubItem);
