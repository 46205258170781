/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Card from "@mui/material/Card";
import { Icon } from "@mui/material";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import { RESPONSE_SUCCESS } from "services/context/response";

import CustomerMgr from "layouts/Customers/CustomerMgr";

const {
  getNextPage,
  getPrevPage,
  getSort,
  CUSTOMERS,
  setStorageItem,
  httpRequest,
  hasPermission,
  convertToCurrency,
  convertTimeZoneFormatDateTime,
} = CustomerMgr;

function CustomerSearch() {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const navigate = useNavigate();
  const location = useLocation();
  const stateKeyword = location.state?.searchKeyword || "";
  const userLoggedin = useSelector((state) => state?.authentication?.userDetail);

  const [customerList, setCustomerList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(stateKeyword);
  // const [prevSortId, setPrevSortId] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const controllerRequest = new AbortController();

  const processRows = (data = []) => {
    const custlist = [];

    data.map((cust) =>
      custlist.push({
        uuid: cust.uuid,
        identifier: cust.identifier,
        id: cust.identifier,
        company_name: cust.company_name || "Individual",
        address: `${cust.address.line_1} ${cust.address.line_2} ${cust.address.city} ${cust.address.state} ${cust.address.country.value} ${cust.address.zip_code}`,
        date_of_last_rental:
          cust.last_rental_date && userLoggedin
            ? convertTimeZoneFormatDateTime(
                cust.last_rental_date,
                userLoggedin?.location?.timezone?.name,
                userLoggedin?.location?.timezone?.abbreviation,
                "LL"
              )
            : "----/--/--",
        unpaid_balance: `${convertToCurrency(cust.unpaid_balance)}`,
      })
    );

    return custlist;
  };

  const processResponse = (response) => {
    if (response.status === RESPONSE_SUCCESS) {
      setCustomerList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const dataTableData = {
    columns: [
      { accessor: "uuid", width: "10%" },
      {
        Header: "identifier",
        accessor: "identifier",
        headerAlign: "left",
        width: "15%",
        hasSort: true,
      },
      {
        Header: "company name",
        accessor: "company_name",
        type: hasPermission("view_customer", permissions?.customers) ? "detail-link" : "text",
        width: "15%",
        headerAlign: "left",
        hasSort: true,
      },
      { Header: "address", accessor: "address", headerAlign: "left", width: "20%" },
      {
        Header: "date of last rental",
        accessor: "date_of_last_rental",
        headerAlign: "left",
        width: "15%",
      },
      { Header: "unpaid balance", accessor: "unpaid_balance", headerAlign: "left", width: "10%" },
    ],
    rows: customerList,
  };

  const showCustomerDetail = (values) => {
    setStorageItem("current_customer_detail", values);

    navigate(CUSTOMERS.DETAIL_ROUTE, {
      state: { from: location, uuid: values.uuid, searchKeyword },
      // replace: true,
    });
  };

  const newCustomer = () => {
    navigate(CUSTOMERS.CREATE_ROUTE, {
      state: { from: location, pageState: "NEW", searchKeyword },
      // replace: true,
    });
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    processResponse(await getNextPage(nextPageLink));
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    processResponse(await getPrevPage(prevPageLink));
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    processResponse(
      await httpRequest("customer-list", null, { searchKeyword: val, entriesPerPage })
    );
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);

    processResponse(
      await httpRequest("customer-list", null, { searchKeyword, entriesPerPage: val })
    );
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    processResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    processResponse(await httpRequest("customer-list", null, { searchKeyword, entriesPerPage }));
    setSearchKeyword(stateKeyword);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={newCustomer}
            disabled={!hasPermission("add_customer", permissions?.customers)}
          >
            new customer
          </MDButton>
          <MDBox display="flex">
            {false && (
              <MDBox ml={1}>
                <MDButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </MDButton>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            table={dataTableData}
            noEndBorder
            entriesPerPage={{ show: true }}
            details={showCustomerDetail}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handleSearch={handleSearchKeyword}
            handleSort={handleSort}
            handleChangeEntries={handleChangeEntries}
            hasNextPage={nextPageLink !== null}
            hasPrevPage={prevPageLink !== null}
            totalCount={totalRows}
            canRefresh
            title="Customer List"
            isLoading={isLoading}
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CustomerSearch;
