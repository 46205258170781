import * as Yup from "yup";

const phoneRegExp = /^(\([0-9]{3}\)|[0-9]{3}-) [0-9]{3}-[0-9]{4}$/;

const validations = Yup.object().shape({
  name: Yup.string().required("Location name is required."),
  taxRate: Yup.string().required("Tax rate is required."),
  email: Yup.string().required("Email address is required.").email("Your email address is invalid"),
  // timezone: Yup.mixed().required("Timezone is required."),
  line_1: Yup.string().required("Address is required."),
  city: Yup.string().required("City is required."),
  state: Yup.string().required("State is required."),
  zip_code: Yup.string().required("Zip is required.").min(4, "Must be 4 digits or more"),
  // country: Yup.mixed().required("Country is required."),

  mobile: Yup.string()
    .required("Telephone 1 number is required.")
    .matches(phoneRegExp, "Must be 3-3-4 format i.e, (123) 123-1234"),
  telephone: Yup.string().matches(phoneRegExp, "Must be 3-3-4 format i.e, (123) 123-1234"),
  fax: Yup.string().matches(phoneRegExp, "Must be 3-3-4 format i.e, (123) 123-1234"),
  other: Yup.string().matches(phoneRegExp, "Must be 3-3-4 format i.e, (123) 123-1234"),
});

export default validations;
