/* eslint-disable no-debugger */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { CardMedia, Grid, Icon, IconButton } from "@mui/material";

import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";

import pdfLogo from "assets/images/logos/pdf-pic.jpg";

// import "./styles.css";
import "react-dropzone-uploader/dist/styles.css";

import CustomerMgr from "layouts/Customers/CustomerMgr";
import MDDatePicker from "components/MDDatePicker";
import moment from "moment";

const {
  httpRequest,
  getDocumentTypeName,
  RESPONSE_SUCCESS,
  getDateToday,
  preventNegativeInput,
  limitInputLength,
  capitalizeFirstLetter,
} = CustomerMgr;

const tenMB = 10048576; // 10 MB

function Layout({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) {
  return (
    <div>
      <div {...dropzoneProps}>
        {previews}
        {files.length < maxFiles && input}
      </div>

      <MDBox display="flex" justifyContent="flex-end">
        {files.length > 0 && submitButton}
      </MDBox>
    </div>
  );
}

function Preview({ meta, fileWithMeta }) {
  const { name, previewUrl, type, size } = meta;
  const { remove } = fileWithMeta;
  return (
    <span style={{ alignSelf: "flex-start", margin: "10px 3%", fontFamily: "Helvetica" }}>
      <Grid container direction="row" display="flex" alignItems="center" spacing={1}>
        <Grid item xs={3}>
          <MDBox>
            <CardMedia
              // onClick={() => {
              //   console.log(meta, previewUrl);
              // }}
              src={type === "application/pdf" ? pdfLogo : previewUrl}
              component="img"
              title={name}
              sx={{
                maxWidth: "100%",
                margin: 0,
                maxHeight: "4rem",
                boxShadow: ({ boxShadows: { md } }) => md,
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </MDBox>
        </Grid>
        <Grid item xs={9}>
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium" color="dark">
              {name}
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium" color="dark">
              {(size / 1048576).toFixed(2)} MB
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <MDBox textAlign="center">
            <MDTypography variant="button" color="error">
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="error"
                fontWeight="medium"
                textGradient
                onClick={remove}
              >
                Remove
              </MDTypography>
            </MDTypography>
          </MDBox>
        </Grid>
      </Grid>
    </span>
  );
}

export default function DragAndDrop({ data, uuid, onClose }) {
  const [limit, setLimit] = useState(data?.limit || 1.0);
  const [expirationDate, setExpirationDate] = useState(getDateToday());
  const [isLoading, setIsLoading] = useState(false);

  const [errorSB, setErrorSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const setOnChange = (e) => {
    let value = "";
    switch (e.target.name) {
      case "limit":
        value = limitInputLength(preventNegativeInput(e.target.value), 4);
        setLimit(value);
        break;

      default:
        break;
    }
  };

  const handleChangeDate = (e) => {
    setExpirationDate(moment(new Date(e[0]).toISOString()).format("YYYY-MM-DD"));
  };

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files) => {
    setIsLoading(true);
    const body = new FormData();
    if (files) {
      files.map((f) => body.append("attachment", f.file));
    }
    // body.append("document_type", data?.document_name);
    body.append("limit", limit);
    body.append("expiration_date", expirationDate);
    const response = await httpRequest("customer-update-document", body, { uuid, id: data?.uuid });

    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      onClose("uploaded");
      setIsLoading(false);
    } else {
      openErrorSB(true);
      Object.entries(response.data).map((item) => {
        setErrMsg(
          `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${
            response.data?.attachment ||
            response.data?.document_type ||
            response?.data?.limit ||
            response?.data?.expiration_date ||
            response.data
          }`
        );
        return 0;
      });
      if (response?.data?.expiration_date) {
        setExpirationDate(getDateToday());
      }
      setIsLoading(false);
    }
  };

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox mb={1} ml={0.5} lineHeight={0}>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium" color="secondary">
              {getDocumentTypeName(data?.document_name) || "Insurance"}
            </MDTypography>
          </MDBox>
          <MDBox>
            <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
              <Icon fontSize="small">close</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <Dropzone
          onSubmit={handleSubmit}
          maxFiles={1}
          multiple={false}
          maxSizeBytes={tenMB}
          accept="image/jpeg,image/png,application/pdf"
          LayoutComponent={Layout}
          PreviewComponent={Preview}
          inputContent={(files, extra) =>
            extra.reject
              ? "(Only *.jpeg, *.png and *.pdf file, not more than 10MB)"
              : "Drag file or click here to open file explorer"
          }
          // SubmitButtonComponent={(props) => (
          //   <MDButton mt={2} {...props} variant="gradient" color="info" size="small" type="submit">
          //     update file
          //   </MDButton>
          // )}
          styles={{
            dropzone: { minHeight: "9rem" },
            dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
            inputLabel: (files, extra) =>
              extra.reject ? { color: "red" } : { textAlign: "center" },
          }}
        />
      </MDBox>
      <MDBox>
        <Grid container spacing={3} mt={2}>
          {data?.document_name === "insurance" && (
            <Grid item sm={6}>
              <MDInput
                variant="standard"
                type="number"
                color="dark"
                label="Limit"
                value={limit}
                name="limit"
                onChange={(e) => {
                  setOnChange(e);
                }}
              />
            </Grid>
          )}

          {data?.document_name === "insurance" && (
            <Grid item sm={5}>
              <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                <MDDatePicker
                  options={{
                    // mode: "range",
                    minDate: "today",
                    defaultDate: expirationDate,
                    dateFormat: "Y-m-d",
                    postition: "auto center",
                    monthSelectorType: "dropdown",
                  }}
                  input={{
                    label: "Expiration date",
                    placeholder: "Select date",
                    value: expirationDate,
                    style: { width: "100%" },
                    variant: "outlined",
                  }}
                  onChange={(e) => handleChangeDate(e)}
                />
              </MDBox>
            </Grid>
          )}

          {data?.document_name === "resale_certificate" && (
            <Grid item sm={5}>
              <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                <MDDatePicker
                  InputLabelProps={{ shrink: false }}
                  options={{
                    // mode: "range",
                    minDate: "today",
                    defaultDate: expirationDate,
                    dateFormat: "Y-m-d",
                    postition: "auto center",
                    monthSelectorType: "dropdown",
                  }}
                  input={{
                    label: "Expiration date",
                    placeholder: "Select date",
                    value: expirationDate,
                    style: { width: "100%" },
                    variant: "outlined",
                  }}
                  onChange={(e) => handleChangeDate(e)}
                />
              </MDBox>
            </Grid>
          )}
        </Grid>
        {(data?.document_name === "insurance" || data?.document_name === "resale_certificate") && (
          <MDBox my={2} display="flex" justifyContent="flex-end">
            <MDBox mx={2}>
              <MDButton variant="gradient" color="error" size="small" onClick={onClose}>
                cancel
              </MDButton>
            </MDBox>
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => handleSubmit()}
                disabled={limit === "" && expirationDate === ""}
              >
                Save
              </MDButton>
            </MDBox>
          </MDBox>
        )}
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Upload Document"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

DragAndDrop.defaultProps = {
  uuid: "",
  onClose: () => {},
};

DragAndDrop.propTypes = {
  uuid: PropTypes.string,
  onClose: PropTypes.func,
};
