/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import LocationMgr from "layouts/admin/ManageLocation/LocationMgr";

const { httpRequest, MANAGE_LOCATIONS, getResponseMsg } = LocationMgr;

function Delete({ data }) {
  const navigate = useNavigate();

  const [msg, setMsg] = useState("");

  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to fetch user detail.");
  const [notifTitle, setNotifTitle] = useState("Location Deletion");
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const handleOpenDeactivateDialog = () => setShowDeactivateDialog(true);
  const handleCloseDeactiVateDialog = () => setShowDeactivateDialog(false);

  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

  const handleConfirm = async (action) => {
    setIsLoading(true);

    let response = {};
    switch (action) {
      case "deactivate":
      case "delete":
        setNotifTitle("Location Deactivation");
        handleCloseDeactiVateDialog();
        handleCloseDeleteDialog();
        response = await httpRequest("locations-deactivate", null, { uuid: data.id });

        if (getResponseMsg(response) === "Success") {
          setMsg("Successfully Deactivated Location");
          // setMsg("Successfully Deleted Location");
          openSuccessSB(true);
          setTimeout(() => {
            setIsLoading(false);
            navigate(MANAGE_LOCATIONS.LIST_ROUTE, { state: { from: "" }, replace: true });
          }, 1500);
        } else {
          setErrMsg(response.data.data);
          openErrorSB(true);
          setIsLoading(false);
        }

        break;
      // case "delete":
      //   handleCloseDeleteDialog();
      //   await httpRequest("customer-delete", null, { uuid: data.uuid });
      //   setMsg("Successfully Deleted Location");
      //   break;
      default:
        break;
    }
  };

  return (
    <Card id="delete-account">
      {isLoading && <SimpleBackdrop />}
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Deactivate Location Account</MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="button" color="warning">
              ** Once you Deactivate Location account, you need to contact the admin to restore the
              Location account. **
            </MDTypography>
          </MDBox>
          {/* <MDBox mb={1}>
            <MDTypography variant="h5">Delete Location Account</MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="button" color="warning">
              ** Once you Deleted a Location account, it will be removed from the records. **
            </MDTypography>
          </MDBox> */}
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={handleOpenDeactivateDialog}
            disabled={data.status === "inactive"}
          >
            deactivate
          </MDButton>
          {/* <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
            <MDButton
              variant="gradient"
              color="error"
              sx={{ height: "100%" }}
              onClick={handleOpenDeleteDialog}
            >
              Delete
            </MDButton>
          </MDBox> */}
        </MDBox>
      </MDBox>

      {showDeactivateDialog && (
        <DraggableDialog
          title="Deactivate Location"
          description={`Are you sure you want to deactivate ${data.name}?`}
          buttonDesc="Deactivate"
          openDialog={showDeactivateDialog}
          handleClose={handleCloseDeactiVateDialog}
          handleConfirm={() => handleConfirm("deactivate")}
          fullWidth={false}
        />
      )}

      {showDeleteDialog && (
        <DraggableDialog
          title="Delete Location"
          description={`Are you sure you want to delete ${data.name}?`}
          buttonDesc="Delete"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={() => handleConfirm("delete")}
          fullWidth={false}
        />
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />

      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={msg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </Card>
  );
}
Delete.defaultProps = {
  data: {},
};

Delete.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default Delete;
