/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";

const { getStorageItem, httpRequest, getResponseMsg, capitalizeFirstLetter } = WarehouseMgr;

function BasicInfo({ data, loadDetails }) {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const { identifier, status, description } = data;
  const [apiBody, setApiBody] = useState({});
  const [apiRest, setApiRest] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Failed");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [notifTitle, setNotifTitle] = useState("Item Checkin");

  const handleOpenDialog = () => setShowConfirmationDialogue(true);
  const handleCloseDialog = () => setShowConfirmationDialogue(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const itemStatusOptions = [
    // ...status,
    { name: "On Rent", value: "on_rent" },
    { name: "Returned", value: "returned" },
    { name: "Received", value: "received" },
    { name: "Awaiting Check-In", value: "awaiting_check_in" },
    { name: "Checking In", value: "checking_in" },
    { name: "Incomplete", value: "incomplete" },
  ];

  const handleConfirm = async () => {
    setNotifTitle("Order Status Change");
    setIsLoading(true);

    const responseDetails = await httpRequest("change-order-status", apiBody, apiRest);

    if (getResponseMsg(responseDetails) === "Success") {
      loadDetails();
      handleCloseDialog();
      // setSuccessMsg(
      //   `${responseDetails?.data?.data?.barcode_id}'s status is changed to ${responseDetails?.data?.data?.status?.name}.`
      // );
      setSuccessMsg("Successfully changed the order status.");
      openSuccessSB();
    } else {
      let responseErrMsg = "";

      Object.entries(responseDetails.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      handleCloseDialog();
      setErrMsg(responseErrMsg);
      openErrorSB();
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isMobile ? (
        <MDBox p={2} display="flex" justifyContent="space-between">
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                {identifier}
              </MDTypography>
            </MDBox>
            <MDTypography variant="button" fontWeight="regular" color="text">
              {description}
            </MDTypography>
          </MDBox>
          <MDBox width="10rem">
            <Autocomplete
              disablePortal
              value={status || null}
              options={itemStatusOptions}
              onChange={(event, newValue) => {
                // console.log(
                //   data,
                //   newValue,
                //   {
                //     order_uuid: data?.uuid,
                //   },
                //   {
                //     status: newValue?.value,
                //   }
                // );
                setApiRest({
                  order_uuid: data?.uuid,
                });
                setApiBody({
                  status: newValue?.value,
                });
                handleOpenDialog();
              }}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => option.name || ""}
              fullWidth
              getOptionDisabled={(option) => option.value === status.value}
              renderInput={(params) => <TextField {...params} />}
            />
          </MDBox>
        </MDBox>
      ) : (
        <MDBox p={2} display="flex" justifyContent="space-between">
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDTypography variant="h5" fontWeight="medium">
              {identifier}
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              Description: &nbsp;{description}
            </MDTypography>
          </MDBox>

          <MDBox display="flex" justifyContent="flex-end">
            <MDBox display="flex">
              <MDBox pt={2}>
                <MDTypography variant="h5">Order Status:&nbsp;</MDTypography>
              </MDBox>

              <MDBox>
                <Autocomplete
                  disablePortal
                  value={status || null}
                  options={itemStatusOptions}
                  onChange={(event, newValue) => {
                    // console.log(
                    //   data,
                    //   newValue,
                    //   {
                    //     order_uuid: data?.uuid,
                    //   },
                    //   {
                    //     status: newValue?.value,
                    //   }
                    // );
                    setApiRest({
                      order_uuid: data?.uuid,
                    });
                    setApiBody({
                      status: newValue?.value,
                    });
                    handleOpenDialog();
                  }}
                  isOptionEqualToValue={(option, value) =>
                    value !== "" ? option.name === value.name : option.name
                  }
                  getOptionDisabled={(option) => option.value === status.value}
                  getOptionLabel={(option) => option.name || ""}
                  sx={{ width: 200, height: "auto" }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      )}

      {showConfirmationDialogue && (
        // <div>
        <Dialog open={showConfirmationDialogue} onClose={handleCloseDialog}>
          <DialogTitle>Order Status Change</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to change the order status?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handleConfirm()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

BasicInfo.defaultProps = {
  data: {},
  userDetail: {},
};

BasicInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  userDetail: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(BasicInfo);
