const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "first_name",
      label: "First Name *",
      type: "text",
      errorMsg: "First name is required.",
    },
    lastName: {
      name: "last_name",
      label: "Last Name *",
      type: "text",
      errorMsg: "Last name is required.",
    },
    email: {
      name: "email",
      label: "User Company Email Address *",
      type: "email",
      errorMsg: "User Company Email Address is required.",
      invalidMsg: "Your email address is invalid",
    },
    location: {
      name: "location",
      label: "Location *",
      type: "text",
      errorMsg: "Location is required.",
    },
    warehouse: {
      name: "warehouse",
      label: "Warehouse *",
      type: "text",
      errorMsg: "Warehouse is required.",
    },
    username: {
      name: "username",
      label: "Username *",
      type: "text",
      errorMsg: "Username is required.",
    },
    password: {
      name: "password",
      label: "Password *",
      type: "password",
      errorMsg: "Password is required.",
    },
    role: {
      name: "role",
      label: "Role *",
      type: "text",
      errorMsg: "Role is required.",
    },
    permission: {
      name: "permission",
      label: "Permission *",
      type: "text",
      errorMsg: "Permission is required.",
    },
  },
};

export default form;
