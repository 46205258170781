import {
  SET_AUTH_DATA,
  LOGOUT,
  SET_USER_DETAIL,
  UPDATE_USER_LOCATION,
  UPDATE_USER_WAREHOUSE,
} from "../actions/types";

const initialState = {
  userAuth: {
    rememberMe: localStorage.getItem("rememberMe") === "true",
    username: localStorage.getItem("username"),
    loggedIn: false,
  },
  userDetail: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_DATA:
      return {
        ...state,
        userAuth: {
          rememberMe: action.payload?.rememberMe,
          username: action.payload?.username,
          loggedIn: action.payload?.loggedIn,
        },
      };

    case SET_USER_DETAIL:
      return {
        ...state,
        userDetail: action.payload,
      };

    case LOGOUT:
      localStorage.setItem("refreshToken", "");
      localStorage.setItem("accessToken", "");
      localStorage.setItem("isLoggedIn", false);
      return initialState;

    case UPDATE_USER_LOCATION:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          location: action.payload,
        },
      };

    case UPDATE_USER_WAREHOUSE:
      return {
        ...state,
        userDetail: {
          ...state.userDetail,
          warehouse: action.payload,
        },
      };

    default:
      break;
  }
  return state;
};

export default authReducer;
