import axios, { axiosPrivate } from "./axios";
import pathUrl from "../context/pathUrl";
import utils from "../utils";

const { jsonToString, getAccessToken } = utils;
const { LOGIN, LOGOUT } = pathUrl;

axiosPrivate.defaults.headers.get.Authorization = `Bearer ${getAccessToken()}`;
axiosPrivate.defaults.baseURL = "/api";

const login = async ({ username, password }) => {
  try {
    const response = await axios.post(LOGIN.api, jsonToString({ username, password }));
    return response;
  } catch (error) {
    return error.response;
  }
};

const logout = async ({ refresh, access }) => {
  try {
    const response = await axios.post(LOGOUT.api, jsonToString({ refresh }), {
      headers: {
        Authorization: `Bearer ${access}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const getRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.get(URL, jsonToString(body));
    return response;
  } catch (error) {
    return error.response;
  }
};

const postRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.post(URL, jsonToString(body), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const patchRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.patch(URL, jsonToString(body), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const putRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.put(URL, jsonToString(body), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const deleteRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.delete(URL, jsonToString(body), {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const authRequest = async (URL, body = {}) => {
  try {
    const response = await axios.post(URL, jsonToString(body));
    return response;
  } catch (error) {
    return error.response;
  }
};

const uploadPostRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.post(URL, body, {
      headers: {
        "Content-type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const uploadPutRequest = async (URL, body = {}) => {
  try {
    const response = await axiosPrivate.put(URL, body, {
      headers: {
        "Content-type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
      },
    });
    return response;
  } catch (error) {
    return error.response;
  }
};

const HTTPSServices = {
  login,
  logout,
  getRequest,
  postRequest,
  authRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  uploadPostRequest,
  uploadPutRequest,
};

export default HTTPSServices;
