import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import style from "services/context/style";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";

const { postRequest, getRequest, patchRequest, putRequest, uploadPostRequest } = HTTPSServices;

const { fullXs, halfXs, fullSm, halfSm, spacing, flex, flexEnd, spaceBetween, h5, padding } = style;
const {
  CUSTOMERS,
  _DASHBOARD,
  REPORTS,
  WAREHOUSE_CHECKIN,
  WAREHOUSE_STAGE,
  INVENTORY,
  ORDERS,
  WAREHOUSE_CHECKOUT,
  WAREHOUSE_QUALITY_CHECK,
} = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  addDate,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  getCurrentTime,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  addDateFormatted,
  ellipsis,
  convertTimeZoneFormatDateTime,
  hasPermission,
  checkNullEmpty,
  convertToCurrency,
} = utils;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  switch (action) {
    // START
    // STAGING
    case "warehouse-staging-list":
      response = await getRequest(
        `${WAREHOUSE_STAGE.API}?key=staging&search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }`
      );
      break;

    case "warehouse-staging-detail":
      response = await getRequest(`${WAREHOUSE_STAGE.API}${rest?.uuid}/?key=staging`);
      break;

    case "single-subitem-list":
      response = await getRequest(
        `${INVENTORY.API}${rest?.uuid}/subitems/?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&status=available`
        // }`
      );
      break;

    case "stage-item":
      response = await postRequest(`${WAREHOUSE_STAGE.API}staging/`, body);
      break;

    // CHECKIN
    case "warehouse-checkin-list":
      response = await getRequest(
        `${WAREHOUSE_CHECKIN.API}?key=checkin&search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }`
      );
      break;

    case "warehouse-checkin-detail":
      response = await getRequest(`${WAREHOUSE_CHECKIN.API}${rest?.uuid}/?key=checkin`);
      break;

    case "quick-check-in":
      response = await postRequest(`${WAREHOUSE_CHECKIN.API}quick-checkin/`, body);
      break;

    case "check-in":
      response = await postRequest(`${WAREHOUSE_CHECKIN.API}checkin/`, body);
      break;

    // case "change-subitem-status":
    //   response = await putRequest(
    //     `${INVENTORY.API}${rest?.item_uuid}/subitems/${rest?.subitem_uuid}/`,
    //     body
    //   );
    //   break;

    case "change-subitem-status":
      response = await postRequest(`${WAREHOUSE_CHECKIN.API}checkin/`, body);
      break;

    case "change-order-status":
      response = await patchRequest(`${ORDERS.API}${rest?.order_uuid}/status/`, body);
      break;

    // CHECKOUT
    case "warehouse-checkout-list":
      response = await getRequest(
        `${WAREHOUSE_CHECKOUT.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }`
      );
      break;

    case "warehouse-checkout-detail":
      response = await getRequest(`${WAREHOUSE_CHECKOUT.API}${rest?.uuid}/`);
      break;

    case "upload-attachment":
      response = await uploadPostRequest(
        `${WAREHOUSE_CHECKOUT.API}${rest?.uuid}/attachments/`,
        body
      );
      break;

    case "remove-item":
      response = await putRequest(`${WAREHOUSE_CHECKOUT.API}${rest?.uuid}/remove-item/`, body);
      break;

    case "remove-subitem":
      response = await putRequest(`${WAREHOUSE_CHECKOUT.API}${rest?.uuid}/remove-subitem/`, body);
      break;

    case "skip-document":
      response = await postRequest(`${WAREHOUSE_CHECKOUT.API}${rest?.uuid}/attachments/`, body);
      break;

    // QUALITY CHECK

    case "warehouse-qc-list":
      response = await getRequest(
        `${WAREHOUSE_QUALITY_CHECK.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&status=${rest?.status || ""}`
      );
      break;

    case "warehouse-qc-detail":
      response = await getRequest(`${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/`);
      break;

    case "save-qc-sheet":
      response = await postRequest(`${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/sheets/`, body);
      break;

    case "update-qc-sheet":
      response = await putRequest(`${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/sheets/`, body);
      break;

    case "upload-attachment-qc":
      response = await uploadPostRequest(
        `${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/attachments/`,
        body
      );
      break;

    case "remove-attachment-qc":
      response = await postRequest(
        `${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/attachments/`,
        body
      );
      break;

    case "conditions":
      response = await putRequest(`${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/conditions/`, body);
      break;

    case "change-status-inrepair-items":
      response = await patchRequest(`${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/`, body);
      break;

    case "service-history":
      response = await getRequest(
        `${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/service-histories/?page_size=${
          rest?.entriesPerPage || ""
        }`,
        body
      );
      break;

    case "download-pdf":
      response = await getRequest(
        `${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/downloads/?source=${rest?.source}`,
        body
      );
      break;

    case "service-history-detail":
      response = await getRequest(`/service-histories/${rest?.uuid}/`);
      break;

    case "rental-history":
      response = await getRequest(
        `${WAREHOUSE_QUALITY_CHECK.API}${rest?.uuid}/rental-histories/?page_size=${
          rest?.entriesPerPage || ""
        }`,
        body
      );
      break;

    case "refund":
      response = await postRequest(`/refunds/`, body);
      break;
    // END

    case "create-order":
      response = await postRequest(REPORTS.API, body);
      break;

    case "update-order":
      response = await putRequest(`${REPORTS.API}${rest?.uuid}/`, body);
      break;

    case "update-sub-item":
      response = await putRequest(
        `${REPORTS.API}${rest?.uuid}/subitems/${rest?.subitem_uuid}`,
        body
      );
      break;

    case "set-status-retired":
      response = await patchRequest(
        `${REPORTS.API}${rest?.uuid}/subitems/${rest?.subitem_uuid}`,
        body
      );
      break;

    case "orders-list":
      response = await getRequest(
        `${REPORTS.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&date_from=${rest?.date_from || ""}&date_to=${rest?.date_to || ""}&status=${
          rest?.status || ""
        }`
      );
      break;

    case "orders-detail":
      response = await getRequest(`${REPORTS.API}${rest?.uuid}/?notif_read=${rest?.id || ""}`);
      break;

    case "orders-availability":
      response = await getRequest(
        `${REPORTS.API}${rest?.uuid}/availability-calendar/?start_date=${rest?.date}`,
        body
      );
      break;

    case "orders-soft-delete":
      response = await patchRequest(`${REPORTS.API}${rest?.uuid}/`);
      break;

    case "order-cancel":
      response = await patchRequest(`${REPORTS.API}${rest?.uuid}/cancel/`, body);
      break;

    case "get-rental-agent-list":
      response = await getRequest("/users/?status=active");
      break;

    case "customer-detail":
      response = await getRequest(`${CUSTOMERS.API}${rest?.uuid}`);

      break;

    case "customer-documents":
      response = await getRequest(`${CUSTOMERS.API}${rest?.uuid}/documents/`);

      break;

    case "revision-logs":
      response = await getRequest(`${REPORTS.API}${rest?.uuid}/revision-logs/`);

      break;

    case "clone-order":
      response = await postRequest(`${REPORTS.API}${rest?.uuid}/clone/`, body);
      break;

    case "payments":
      response = await postRequest(`/payments/`, body);
      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (sortType = "asc", id = "name", searchKeyword = "", entriesPerPage = 25) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${REPORTS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${id}`
    );
  } else {
    response = await getRequest(
      `${REPORTS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}`
    );
  }

  return response;
};

const dataValidation = (data = {}) => {
  /**
   * Billing Start time Checker
   * Checks if:
   * 1. Billing start Date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if  conditions are true
   */
  // if (data.billing_start_date <= getDateToday() && data.billing_start_time < getCurrentTime()) {
  //   return "Billing start time should be past the current time. ";
  // }

  /**
   * Billing End time Checker
   * Checks if:
   * 1. Billing end Date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if conditions are true
   */
  // if (data.billing_end_date <= getDateToday() && data.billing_end_time < getCurrentTime()) {
  //   return "Billing end time should be past the current time. ";
  // }

  /**
   * Pick up date Checker
   * Checks if:
   * 1. Pick up date is before the billing start date
   *
   * Will cause an error if conditions are true
   */
  if (data.pickup_date < data.billing_start_date) {
    return "Pick-up date should be past or the same as billing start date. ";
  }

  /**
   * Pick up date Checker
   * Checks if:
   * 1. Pick up date is after the billing end date
   *
   * Will cause an error if conditions are true
   */
  if (data.pickup_date > data.billing_end_date) {
    return "Pick-up date should not be past the Billing end date. ";
  }

  /**
   * Pick up time Checker
   * Checks if:
   * 1. Pickup date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if conditions are true
   */
  // if (data.pickup_date <= getDateToday() && data.pickup_time < getCurrentTime()) {
  //   return "Pick-up time should be past the current time. ";
  // }

  /**
   * Return date Checker
   * Checks if:
   * 1. Return date is before the billing start date
   *
   * Will cause an error if conditions are true
   */
  if (data.return_by_date < data.billing_start_date) {
    return "Return date should be past or the same as billing start date. ";
  }

  /**
   * Return by time Checker
   * Checks if:
   * 1. Return date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if conditions are true
   */
  // if (data.return_by_date <= data.billing_start_date && data.return_by_time < getCurrentTime()) {
  //   return "Return time should be past the billng start time. ";
  // }

  if (data.collections.length <= 0) {
    return "Rental item/s are empty.";
  }

  return null;
};

const WarehouseMgr = {
  REPORTS,
  fullXs,
  halfXs,
  fullSm,
  halfSm,
  spacing,
  flex,
  flexEnd,
  spaceBetween,
  h5,
  padding,
  getNextPage,
  getPrevPage,
  getSort,
  addDate,
  httpRequest,
  setStorageItem,
  getStorageItem,
  RESPONSE_SUCCESS,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  getCurrentTime,
  dataValidation,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  CUSTOMERS,
  addDateFormatted,
  ellipsis,
  convertTimeZoneFormatDateTime,
  hasPermission,
  _DASHBOARD,
  WAREHOUSE_CHECKIN,
  WAREHOUSE_STAGE,
  INVENTORY,
  WAREHOUSE_CHECKOUT,
  WAREHOUSE_QUALITY_CHECK,
  checkNullEmpty,
  ORDERS,
  convertToCurrency,
};

export default WarehouseMgr;
