import * as Yup from "yup";

const validations = Yup.object().shape({
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  email: Yup.string().required("Email address is required.").email("Your email address is invalid"),
  // locat/ion: Yup.string().required("Location is required."),
  username: Yup.string().required("Username is required."),
  // password: Yup.string().required("Password is required."),
  // role: Yup.string().required("Role is required."),
});

export default validations;
