import checkout from "layouts/admin/ManageUsers/new-user/schemas/form";

const {
  formField: { firstName, lastName, email, location, warehouse, username, password, role },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [email.name]: "",
  [location.name]: null,
  [warehouse.name]: null,
  [username.name]: "",
  [password.name]: "",
  [role.name]: null,
};

export default initialValues;
