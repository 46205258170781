/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/admin/ManageLocation/new/components/FormField";
import LocationMgr from "layouts/admin/ManageLocation/LocationMgr";
import { Autocomplete, Slider } from "@mui/material";
import MDInput from "components/MDInput";
import { ErrorMessage } from "formik";

const { limitInputLength, getStorageItem, httpRequest, getResponseMsg, preventNegativeInput } =
  LocationMgr;

function Info({ formData, timezoneError }) {
  const controllerRequest = new AbortController();
  const locationDetail = JSON.parse(getStorageItem("current_location_detail"));
  const { formField, values } = formData;
  const { name, taxRate, timezone, email } = formField;
  const { name: nameV, taxRate: taxRateV, timezone: timezoneV, email: emailV } = values;

  const [nameValue, setNameValue] = useState(nameV);
  const [taxRateValue, setTaxRateValue] = useState(taxRateV);
  const [timezoneValue, setTimezoneValue] = useState(timezoneV);
  const [emailValue, setEmailValue] = useState(emailV);
  const [timezoneOptions, setTimezoneOptions] = useState([]);
  const [showTimezoneError, setShowTimezoneError] = useState(false);

  useEffect(async () => {
    const timezoneListResponse = await httpRequest("timezone-list");
    if (getResponseMsg(timezoneListResponse) === "Success") {
      setTimezoneOptions(timezoneListResponse.data.data);
    }
    // debugger;
    if (locationDetail?.pageState === "NEW") {
      setNameValue("");
      values.name = "";

      setTaxRateValue(0);
      values.taxRate = 0;

      setTimezoneValue(null);
      values.timezone = null;

      setEmailValue("");
      values.email = "";
    }
    return () => {
      controllerRequest.abort();
    };
  }, []);

  const valueLabelFormat = (value) => `${value}%`;

  const handleChangeSlider = (event, newValue) => {
    if (typeof newValue === "number") {
      setTaxRateValue(newValue);
      values.taxRate = newValue;
    }
  };

  return (
    <MDBox mx={2}>
      <MDBox lineHeight={0}>
        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6">Basic Information</MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={6}>
            <FormField
              type={name.type}
              label={name.label}
              name={name.name}
              value={nameValue}
              placeholder={name.placeholder}
              // error={errors.name && touched.name}
              // success={nameV.length > 0 && !errors.name}
              onChange={(e) => {
                setNameValue(limitInputLength(e.target.value, 20));
                values.name = limitInputLength(e.target.value, 20);
              }}
              // onBlur={(e) => handleOnBlur(e)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <MDTypography variant="button" gutterBottom>
              {taxRate.label}: {valueLabelFormat(taxRateValue)}
            </MDTypography>
            <Slider
              value={taxRateValue}
              min={0}
              step={0.1}
              max={100}
              getAriaValueText={valueLabelFormat}
              valueLabelFormat={valueLabelFormat}
              onChange={handleChangeSlider}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6} mt={-2}>
            <Autocomplete
              disablePortal
              options={timezoneOptions || []}
              value={timezoneValue}
              name={timezone.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={timezone.label} />
              )}
              onChange={(event, newValue) => {
                // console.log(newValue);
                if (newValue !== null) {
                  setTimezoneValue(newValue);
                  values.timezone = newValue;
                } else {
                  setShowTimezoneError(true);
                }
              }}
            />
            {timezoneError && timezoneValue === null && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {timezone.errorMsg}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={6} mt={-2}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailValue}
              placeholder={email.placeholder}
              // error={errors.name && touched.name}
              // success={nameV.length > 0 && !errors.name}
              onChange={(e) => {
                setEmailValue(e.target.value);
                values.email = e.target.value;
              }}
              // onBlur={(e) => handleOnBlur(e)}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
Info.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  timezoneError: PropTypes.bool.isRequired,
};

export default Info;
