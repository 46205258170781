/* eslint-disable prefer-const */
/* eslint-disable no-new */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DraggableDialog from "components/DragableDialog";
import MDInput from "components/MDInput";
import CustomModal from "components/CustomModal";

import { Autocomplete, CircularProgress, Grid, Icon, Tooltip } from "@mui/material";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDButton from "components/MDButton";
import ItemList from "layouts/Order/new-order/components/ItemList";
import OrderMgr from "layouts/Order/OrderMgr";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
// import MaterialDataTable from "components/Tables/MaterialDataTable";
import { alpha } from "@mui/material/styles";
import FormField from "./FormField";

const { getStorageItem, preventNegativeInput, convertToPecentDecimal, convertToCurrency } =
  OrderMgr;
const { httpRequest, getResponseMsg } = InventoryMgr;

function OrderItems({ formData, userDetail, customerDetails, discountPercent, billDaysOrder }) {
  const { values, formField } = formData;
  const {
    pickupDate,
    pickupTime,
    billingStartDate,
    billingStartTime,
    billingEndDate,
    billingEndTime,
    returnByDate,
    returnByTime,
    orderDescription,
    billDays,
    defaultDiscount,
    rentalAgentAssigned,
    depositAmount,
    status,
  } = formField;
  const {
    collections: collectionsV,
    bill_days: billDaysV,
    deposit_amount: depositAmountV,
    status: statusV,
  } = values;

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));

  const [collectionItems, setCollectionItems] = useState(collectionsV || []);
  const [billDaysValue, setBillDaysValue] = useState(billDaysV);
  const [depositAmountValue, setdepositAmountValue] = useState(depositAmountV);
  const [totals, setTotals] = useState({
    totalDaily: 0,
    totalDiscountPrice: 0,
    totalPrice: 0,
    totalSales: 0,
    totalRental: 0,
    invoiceTotal: 0,
    taxPrice: 0,
    subtotal: 0,
    totalQty: 0,
    totalOrder: 0,
  });

  const [taxPriceValue, setTaxPriceValue] = useState(0);

  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [confirmAddItem, setConfirmAddItem] = useState(false);
  const [showAddItemWarning, setShowAddItemWarning] = useState(false);
  const [showItemList, setShowItemList] = useState(false);
  const [statusValue, setStatusValue] = useState(statusV);

  const orderStatusOption = [
    { name: "Quote", value: "quote" },
    { name: "Reservation", value: "reservation" },
    { name: "Confirmed", value: "confirmed" },
    { name: "Staging", value: "staging" },
    { name: "Staged", value: "staged" },
    { name: "On Rent", value: "on_rent" },
    { name: "Late", value: "late" },
    { name: "Awaiting Check In", value: "awaiting_check_in" },
    { name: "Check In", value: "checking_in" },
    { name: "Incomplete", value: "incomplete" },
    { name: "Returned", value: "returned" },
    { name: "Ready to Invoice", value: "ready_to_invoice" },
    { name: "Invoiced", value: "invoiced" },
    { name: "Complete", value: "complete" },
    { name: "Canceled", value: "canceled" },
    { name: "Closed", value: "closed" },
  ];

  const handleOpenWarningDialog = () => setShowWarningDialog(true);
  const handleCloseWarningDialog = () => setShowWarningDialog(false);

  const handleOpenAddItemWarningDialog = () => setShowAddItemWarning(true);
  const handleCloseAddItemWarningDialog = (action = "") => {
    switch (action) {
      case "close":
        setConfirmAddItem(false);
        setShowAddItemWarning(false);
        break;
      case "add":
        setConfirmAddItem(true);
        setShowAddItemWarning(false);
        setShowItemList(true);
        break;
      default:
        break;
    }
  };

  const handleOpenItemlistModal = () => {
    // console.log(values?.status?.value === "staged");
    if (orderDetail?.pageState === "EDIT" && values?.status?.value === "staged") {
      handleOpenAddItemWarningDialog();
    } else {
      setShowItemList(true);
    }
  };
  const handleCloseItemlistModal = () => setShowItemList(false);

  const handleCloseWarningAddItem = () => {
    if (confirmAddItem === true) {
      setShowItemList(true);
    }
  };

  const handleChangeOrderStatus = (e, val) => {
    if (val) {
      setStatusValue(val);
      values.status = val;
    }
  };

  const removeRedundantData = (data) =>
    data.reduce((acc, curr) => {
      if (!acc[curr.uuid]) {
        acc[curr.uuid] = curr;
        return acc;
      }
      return acc;
    }, {});

  const calculateTotals = (newSubItems) => {
    let daily = 0;
    let discountPrice = 0;
    let price = 0;
    let sales = 0;
    let rental = 0;
    let taxPrice = 0;
    let invoice = 0;
    let qty = 0;
    let subtotal = 0;
    if (newSubItems?.length > 0) {
      newSubItems.map((item) => {
        if (!item?.delete) {
          if (item?.item_type?.value === "sales" || item?.item_type === "sales") {
            // sales +=
            //   Number((item?.daily_price || 0) * item.quantity || 0) * Number(item?.bill_days || 0);
            sales += Number(item?.total_price || 0);
          } else if (item?.item_type?.value === "rental" || item?.item_type === "rental") {
            // rental +=
            //   Number((item?.daily_price || 0) * item.quantity || 0) * Number(item?.bill_days || 0);
            rental += Number(item?.total_price || 0);
          }

          if ((!item?.optional && item?.collections?.length >= 0) || !item?.collections) {
            daily += Number(item?.daily_price || 0);
            discountPrice += Number(item?.discounted_price || 0);
            price += Number(item?.total_price || 0);
            qty += Number(item?.quantity || 0);
          }
        }

        return 0;
      });
    }

    subtotal += Number(sales || 0) + Number(rental || 0);
    taxPrice = customerDetails?.tax_exempt
      ? 0
      : price * Number((userDetail?.location?.tax_rate || 0) / 100);
    invoice = price + taxPrice;

    values.invoice = invoice;
    values.discount_total = subtotal - price;
    values.tax_rate = userDetail?.location?.tax_rate || 0;
    values.subtotal = subtotal;
    values.order_total = price + taxPrice;
    values.invoice = invoice;
    values.total_sales = sales;
    values.total_rent = rental;
    values.taxPrice = taxPrice;

    setTotals({
      totalDaily: daily,
      totalDiscountPrice: discountPrice,
      totalPrice: price,
      totalOrder: price + taxPrice,
      totalSales: sales,
      totalRental: rental,
      invoiceTotal: invoice,
      taxPrice,
      subtotal,
      totalQty: qty,
    });
    setTaxPriceValue(taxPrice);
  };

  let testTmp = [];

  const getNestedCollections = (data) => {
    // console.log(Number(convertToPecentDecimal(discountPercent || 0)), "testsetses");
    // let discount =
    //   Number(data?.price?.daily || 0) * Number(convertToPecentDecimal(discountPercent || 0));
    let discount =
      Number(data?.price?.daily || 0) *
      Number(convertToPecentDecimal(values.default_discount || 0));
    let tmpDiscountedPrice = Number(data?.price?.daily || 0) - discount;
    // let tmpTotalPrice =
    //   Number(data?.quantity || 0) *
    //   Number(tmpDiscountedPrice || 0) *
    //   Number(values?.bill_days || 0);
    let tmpEach = 0;

    if (data?.item_type?.value === "sales") {
      // tmpEach =
      //   Number(data?.price?.daily || 0) *
      //   (1 - Number(convertToPecentDecimal(discountPercent || 0)));
      tmpEach =
        Number(data?.price?.daily || 0) *
        (1 - Number(convertToPecentDecimal(values.default_discount || 0)));
    } else {
      // tmpEach =
      //   Number(data?.price?.daily || 0) *
      //   Number(values?.bill_days || 0) *
      //   (1 - Number(convertToPecentDecimal(discountPercent || 0)));
      tmpEach =
        Number(data?.price?.daily || 0) *
        Number(values?.bill_days || 0) *
        (1 - Number(convertToPecentDecimal(values.default_discount || 0)));
    }

    let tmpTotalPrice = Number(data?.quantity || 0) * Number(tmpEach || 0);

    const list = [
      ...testTmp,
      {
        ...data,
        // id: testTmp.length - 1,
        item_name: data?.item?.name || data?.name,
        daily_price: data?.price?.daily,
        quantity: data?.quantity,
        // discount_rate: discountPercent,
        discount_rate: values.default_discount,
        discounted_price: tmpDiscountedPrice.toFixed(2),
        total_price: tmpTotalPrice.toFixed(2),
        each_amount: tmpEach.toFixed(2),
        bill_days: Number(values?.bill_days || 0),
        comments: "",
      },
    ];

    if (data?.collections?.collections?.length > 0) {
      data?.collections?.collections.map((nested) => {
        // discount =
        //   Number(nested?.custom_prices?.daily || 0) *
        //   Number(convertToPecentDecimal(discountPercent || 0));
        discount =
          Number(nested?.custom_prices?.daily || 0) *
          Number(convertToPecentDecimal(values.default_discount || 0));
        tmpDiscountedPrice = Number(nested?.custom_prices?.daily || 0) - discount;
        // tmpTotalPrice = Number(nested?.quantity || 0) * Number(tmpDiscountedPrice || 0);
        // tmpEach =
        //   Number(nested?.custom_prices?.daily.toFixed(2) || 0) *
        //   Number(values?.bill_days || 0) *
        //   (1 - Number(convertToPecentDecimal(discountPercent || 0)));
        tmpEach =
          Number(nested?.custom_prices?.daily.toFixed(2) || 0) *
          Number(values?.bill_days || 0) *
          (1 - Number(convertToPecentDecimal(values.default_discount || 0)));
        tmpTotalPrice = Number(nested?.quantity || 0) * Number(tmpEach || 0);

        testTmp = [
          ...testTmp,
          {
            ...nested,
            ...nested?.item,
            item_name: nested?.item?.name,
            daily_price: nested?.custom_prices?.daily.toFixed(2),
            quantity: nested?.quantity,
            // discount_rate: discountPercent,
            discount_rate: values.default_discount,
            discounted_price: tmpDiscountedPrice.toFixed(2),
            total_price: tmpTotalPrice.toFixed(2),
            each_amount: tmpEach.toFixed(2),
            bill_days: Number(values?.bill_days || 0),
            comments: "",
          },
        ];

        // console.log(nested);

        if (nested?.collections?.collections?.length > 0) {
          getNestedCollections(nested);
        }

        return 0;
      });
      if (data?.parent_id === null) {
        // discount =
        //   Number(data?.price?.daily || 0) * Number(convertToPecentDecimal(discountPercent || 0));
        discount =
          Number(data?.price?.daily || 0) *
          Number(convertToPecentDecimal(values.default_discount || 0));
        tmpDiscountedPrice = Number(data?.price?.daily || 0) - discount;
        // tmpTotalPrice = Number(data?.quantity || 0) * Number(tmpDiscountedPrice || 0);
        // tmpEach =
        //   Number(data?.price?.daily || 0) *
        //   Number(values?.bill_days || 0) *
        //   (1 - Number(convertToPecentDecimal(discountPercent || 0)));
        tmpEach =
          Number(data?.price?.daily || 0) *
          Number(values?.bill_days || 0) *
          (1 - Number(convertToPecentDecimal(values.default_discount || 0)));
        tmpTotalPrice = Number(data?.quantity || 0) * Number(tmpEach || 0);

        testTmp = [
          ...testTmp,
          {
            ...data,
            item_name: data?.item?.name || data?.name,
            daily_price: data?.price?.daily,
            quantity: data?.quantity,
            // discount_rate: discountPercent,
            discount_rate: values.default_discount,
            discounted_price: tmpDiscountedPrice.toFixed(2),
            total_price: tmpTotalPrice.toFixed(2),
            each_amount: tmpEach.toFixed(2),
            bill_days: Number(values?.bill_days || 0),
            comments: "",
          },
        ];
      }
      return testTmp;
    }

    return list;
  };

  const getNestedCollectionsEdit = (data) => {
    // let discount =
    //   Number(data?.price?.daily || 0) * Number(convertToPecentDecimal(discountPercent || 0));
    let discount =
      Number(data?.price?.daily || 0) *
      Number(convertToPecentDecimal(values.default_discount || 0));
    let tmpDiscountedPrice = Number(data?.price?.daily || 0) - discount;
    let tmpTotalPrice =
      Number(data?.quantity || 0) * Number(tmpDiscountedPrice || 0) * Number(data?.bill_days || 0);

    const list = [
      // ...testTmp,
      // {
      //   ...data,
      //   // id: testTmp.length - 1,
      //   item_name: data?.item?.name || data?.name,
      //   daily_price: data?.price?.daily,
      //   quantity: data?.quantity,
      //   discount_rate: values.default_discount,
      //   discounted_price: tmpDiscountedPrice.toFixed(2),
      //   total_price: tmpTotalPrice.toFixed(2),
      //   comments: "",
      // },
    ];

    if (data?.collections?.collections?.length > 0) {
      data?.collections?.collections?.map((nested) => {
        // discount =
        //   Number(nested?.custom_prices?.daily || 0) *
        //   Number(convertToPecentDecimal(discountPercent || 0));
        discount =
          Number(nested?.custom_prices?.daily || 0) *
          Number(convertToPecentDecimal(values.default_discount || 0));
        tmpDiscountedPrice = Number(nested?.custom_prices?.daily || 0) - discount;
        tmpTotalPrice = Number(nested?.quantity || 0) * Number(tmpDiscountedPrice || 0);

        testTmp = [
          ...testTmp,
          {
            ...nested,
            ...nested?.item,
            parent_uuid: nested?.uuid,
            item_name: nested?.item?.name,
            daily_price: nested?.price,
            quantity: nested?.quantity,
            discount_rate: nested?.discount_percent || 0,
            discounted_price: nested?.discounted_price,
            total_price: nested?.total_price,
            each_amount: nested?.each_amount,
            comments: nested?.comments,
            bill_days: nested?.bill_days,
          },
        ];

        // console.log(nested);

        if (nested?.collections?.collections?.length > 0) {
          getNestedCollectionsEdit(nested);
        }

        return 0;
      });

      return testTmp;
    }

    return list;
  };

  const handleItems = async (
    action = "",
    rest = {
      subitem: {
        optional: false,
        quantity: 1,
      },
    }
  ) => {
    let noRedundantData = [];
    let newSubItems = [];
    let responseDetails = {};
    const addList = [];
    let nestedList = [];

    switch (action) {
      case "add-sub-item":
        // setIsLoading(true);
        // responseDetails = await httpRequest("inventory-detail", null, {
        //   uuid: rest?.kitItem?.uuid,
        // });

        // if (getResponseMsg(responseDetails) === "Success") {
        nestedList = getNestedCollections({
          ...rest?.kitItem,
          quantity: rest?.kitItem?.quantity,
          bill_days: values.bill_days || 0,
          // each_amount: tmpEach,
        });
        //   setSuccessMsg(`${rest?.kitItem?.name} is added to the list.`);
        //   openSuccessSB();
        // } else {
        //   setErrMsg(`${rest?.kitItem?.name} is not added to the list.`);
        //   openErrorSB();
        // }

        newSubItems = [...collectionItems, ...nestedList];
        if (orderDetail?.pageState === "EDIT" && statusValue?.value === "staged") {
          values.status = { name: "Staging", value: "staging" };
          setStatusValue({ name: "Staging", value: "staging" });
        }

        // setIsLoading(false);
        break;

      case "remove-sub-item":
        setIsLoading(true);

        if (orderDetail?.pageState === "EDIT" && rest?.row?.original?.parent_uuid) {
          newSubItems = collectionItems.map((subitem, index) => {
            if (rest?.row?.index === index) {
              return {
                ...subitem,
                delete: true,
              };
            }

            return subitem;
          });
        } else {
          newSubItems = collectionItems.filter((subitem, index) => index !== rest?.row?.index);
        }

        setCollectionItems(newSubItems);
        values.collections = newSubItems;
        calculateTotals(newSubItems);
        setIsLoading(false);

        return;

      case "restore-sub-item":
        setIsLoading(true);

        newSubItems = collectionItems.map((subitem, index) => {
          if (rest?.row?.index === index) {
            return {
              ...subitem,
              delete: false,
            };
          }

          return subitem;
        });

        setCollectionItems(newSubItems);
        values.collections = newSubItems;
        calculateTotals(newSubItems);

        setIsLoading(false);

        return;

      default:
        break;
    }

    noRedundantData = Object.values(removeRedundantData(newSubItems.concat(addList)));

    setCollectionItems(noRedundantData);
    values.collections = noRedundantData;
    calculateTotals(noRedundantData);
  };

  const updateData = () => {
    let tmpDiscountedPrice = 0;
    let discount = 0;
    let tmpTotalPrice = 0;
    let tmpEach = 0;
    // setTimeout(() => {
    if (collectionItems?.length > 0) {
      const dataUpdate = collectionItems;
      dataUpdate?.map((item, index) => {
        discount =
          Number(item?.daily_price || 0) * Number(convertToPecentDecimal(discountPercent || 0));
        tmpDiscountedPrice = (Number(item?.daily_price) || 0) - discount;

        if (item?.item_type?.value === "sales") {
          tmpEach =
            Number(item?.daily_price || 0) *
            (1 - Number(convertToPecentDecimal(discountPercent || 0)));
        } else {
          tmpEach =
            Number(item?.daily_price || 0) *
            Number(billDaysValue || 0) *
            (1 - Number(convertToPecentDecimal(discountPercent || 0)));
        }
        tmpTotalPrice = Number(item?.quantity || 0) * Number(tmpEach || 0);

        dataUpdate[index] = {
          ...item,
          bill_days: item?.item_type?.value === "sales" ? 1 : billDaysValue,
          discount_rate: discountPercent,
          discounted_price: tmpDiscountedPrice.toFixed(2),
          each_amount: tmpEach.toFixed(2),
          total_price: tmpTotalPrice.toFixed(2),
        };

        return 0;
      });

      setTimeout(() => {
        setCollectionItems([]);
        calculateTotals(dataUpdate || []);
        setCollectionItems(dataUpdate);
        values.collections = dataUpdate;
      }, 100);
    }
  };

  useEffect(() => {
    let tmpCollections = [];
    if (orderDetail?.pageState === "NEW") {
      setCollectionItems(tmpCollections);
      values.collections = tmpCollections;

      setBillDaysValue(1);
      values.bill_days = 1;

      setdepositAmountValue(0);
      values.deposit_amount = 0;
    } else {
      tmpCollections = getNestedCollectionsEdit(orderDetail);
      // console.log(tmpCollections);
      setCollectionItems(tmpCollections);
      values.collections = tmpCollections;
      calculateTotals(tmpCollections);
    }
  }, []);

  useEffect(() => {
    calculateTotals(collectionItems || []);
  }, [customerDetails]);

  useEffect(() => {
    document.body.style.cursor = "wait";
    updateData();
    document.body.style.cursor = "default";
  }, [discountPercent]);

  useEffect(() => {
    updateData();
  }, [billDaysValue]);

  useEffect(
    () => () => {
      setCollectionItems([]);
      values.collections = [];
    },
    []
  );

  return (
    // <DashboardLayout>
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox px={3} pt={3}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} sm={3}>
            <FormField
              type={billDays.type}
              label={billDays.label}
              name={billDays.name}
              value={billDaysValue}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => {
                const value = preventNegativeInput(e.target.value);
                setBillDaysValue(value);
                values.bill_days = value;
              }}
              onBlur={(e) => {
                const value = preventNegativeInput(e.target.value);
                setBillDaysValue(value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  const value = preventNegativeInput(e.target.value);
                  setBillDaysValue(value);
                }
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField
              type={depositAmount.type}
              label={depositAmount.label}
              name={depositAmount.name}
              value={depositAmountValue}
              onChange={(e) => {
                const val = preventNegativeInput(e.target.value);
                setdepositAmountValue(val);
                values.deposit_amount = val;
              }}
            />
          </Grid>
          {orderDetail?.pageState === "EDIT" && (
            <Grid item xs={12} sm={3}>
              <Autocomplete
                options={orderStatusOption}
                value={statusValue}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.value === value.value : option.value
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                renderInput={(params) => (
                  <MDInput {...params} name={status.name} variant="standard" label={status.label} />
                )}
                onChange={(event, newValue) => {
                  handleChangeOrderStatus(event, newValue);
                }}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
      <MDBox pl={3} pt={3} display="flex" justifyContent="space-between">
        <MDBox display="flex" justifyContent="flex-start">
          <MDTypography variant="h5" fontWeight="medium">
            Items to be rented
          </MDTypography>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox sx={{ marginRight: 2, mb: 2 }}>
            <MDButton
              size="small"
              variant="gradient"
              color="info"
              onClick={handleOpenItemlistModal}
            >
              Add items
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        {/* <div className="material-icons-round"> */}
        {/* {circularLoading ? (
          <MDBox display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
          </MDBox>
        ) : ( */}
        {/* <MaterialDataTable
          rows={collectionItems || []}
          columns={[
            {
              title: "Item name",
              field: "item_name",
              render: (rowData) => (
                <MDBox>
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="secondary"
                    sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                  >
                    {`${rowData?.item_name || rowData?.item_name || ""}`}
                  </MDTypography>
                </MDBox>
              ),
              editable: "never",
            },
            {
              title: "Daily Rate",
              field: "daily_price",
              // editable: "never",
              render: (rowData) => (
                <MDBox>
                  <MDBox
                    // display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    width="5rem"
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="regular"
                      sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                    >
                      {convertToCurrency(rowData?.daily_price)}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              ),
              editComponent: (props) => {
                // let color = "";
                const render = (
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      // width="5rem"
                    >
                      <MDBox>
                        <MDInput
                          value={props.value}
                          type="number"
                          onChange={(e) => {
                            const value = preventNegativeInput(e.target.value);
                            props.onChange(value);
                          }}
                          // onKeyPress={handleKeyPress}
                          disabled={props?.rowData?.item_type?.value === "kit"}
                          // onBlur={handleOnBlur}
                          // disabled={row?.values?.delete === true}
                          // error={quantity < 0}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                );

                return render;
              },
            },
            {
              title: "Quantity",
              field: "quantity",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                      bgColor={rowData?.local_qty?.available < rowData?.quantity ? "#ffff39" : ""}
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {rowData?.quantity}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
              editComponent: (props) => {
                // let color = "";
                const [color, setColor] = useState("");
                const render = (
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      // width="5rem"
                      bgColor={color}
                    >
                      <MDBox>
                        <MDInput
                          value={props.value}
                          type="number"
                          onChange={(e) => {
                            const value = preventNegativeInput(e.target.value);
                            if (props?.rowData?.local_qty?.available < value) {
                              // color = "#ffff39";
                              handleOpenWarningDialog();
                              setColor("#ffff39");
                            } else {
                              setColor("");
                            }
                            props.onChange(value);
                          }}
                          // onKeyPress={handleKeyPress}
                          disabled={props?.rowData?.item_type?.value === "kit"}
                          // onBlur={handleOnBlur}
                          // disabled={row?.values?.delete === true}
                          // error={quantity < 0}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                );

                return render;
              },
            },
            {
              title: "Discount Rate",
              field: "discount_rate",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {rowData?.discount_rate} %
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
              editComponent: (props) => {
                const render = (
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                      // width="5rem"
                      // bgColor={quantity > row?.original?.local_qty?.available ? "#ffff39" : ""}
                    >
                      <MDBox>
                        <MDInput
                          value={props.value}
                          type="number"
                          onChange={(e) => {
                            const value = preventNegativeInput(e.target.value);
                            props.onChange(value);
                          }}
                          // onKeyPress={handleKeyPress}
                          disabled={props?.rowData?.item_type?.value === "kit"}
                          // onBlur={handleOnBlur}
                          // disabled={row?.values?.delete === true}
                          // error={quantity < 0}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                );
                return render;
              },
            },
            {
              title: "Discounted price",
              field: "discounted_price",
              editable: "never",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {convertToCurrency(rowData?.discounted_price)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
            },
            {
              title: "Bill Days",
              field: "bill_days",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {rowData?.bill_days}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
              editComponent: (props) => {
                const render = (
                  <MDBox>
                    <MDBox
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      textAlign="center"
                    >
                      <MDBox>
                        <MDInput
                          value={props.value}
                          type="number"
                          onChange={(e) => {
                            const value = preventNegativeInput(e.target.value);
                            props.onChange(value);
                          }}
                          // onKeyPress={handleKeyPress}
                          disabled={
                            props?.rowData?.item_type?.value === "kit" ||
                            props?.rowData?.item_type?.value === "sales"
                          }
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>
                );
                return render;
              },
            },
            {
              title: "Each",
              field: "each_amount",
              editable: "never",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {convertToCurrency(rowData?.each_amount)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
            },

            {
              title: "Price",
              field: "total_price",
              editable: "never",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {convertToCurrency(rowData?.total_price)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
            },
            {
              title: "Comment",
              field: "comments",
              render: (rowData) => (
                <MDBox>
                  <MDBox>
                    <MDBox
                      // display="flex"
                      direction="column"
                      justifyContent="flex-start"
                      width="5rem"
                    >
                      <MDTypography
                        variant="button"
                        fontWeight="regular"
                        sx={rowData?.delete === true ? { textDecoration: "line-through" } : ""}
                      >
                        {rowData?.comments}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </MDBox>
              ),
              editComponent: (props) => {
                const render = (
                  <MDBox>
                    <MDInput
                      value={props.value}
                      type="text"
                      onChange={(e) => {
                        props.onChange(e.target.value);
                      }}
                      // onKeyPress={handleKeyPress}
                    />
                  </MDBox>
                );
                return render;
              },
            },
          ]}
          title=""
          parentChildData={(row, rows) => rows.find((a) => a.lookup_id === row.parent_id)}
          actions={[
            (rowData) => ({
              // eslint-disable-next-line react/no-unstable-nested-components
              icon: () => (
                <Icon fontSize="small">
                  {rowData?.delete !== true ? "delete" : "restore_from_trash"}
                </Icon>
              ),
              // icon: rowData?.delete !== true ? "delete" : "restore_from_trash",
              tooltip: rowData?.delete !== true ? "Delete Item" : "Restore Item",
              onClick: (event, oldData) => {
                // collectionItems.map((item) => console.log(item));
                let dataDelete = [...collectionItems];
                const dataIndex = oldData.tableData.id;

                if (rowData?.delete !== true) {
                  new Promise((resolve) => {
                    if (orderDetail?.pageState === "EDIT" && rowData?.parent_uuid) {
                      dataDelete = dataDelete.map((subitem, index) => {
                        if (dataIndex === index || rowData?.lookup_id === subitem?.parent_id) {
                          return {
                            ...subitem,
                            delete: true,
                          };
                        }
                        return subitem;
                      });
                    } else {
                      dataDelete = [];
                      collectionItems.map((item) => {
                        if (item.lookup_id === oldData.lookup_id && item.parent_id === null) {
                          // console.log(item);
                          // DO NOTHING
                        } else if (oldData.lookup_id === item.parent_id) {
                          // console.log(item);
                        } else {
                          dataDelete.push(item);
                        }
                        return 0;
                      });
                    }

                    setCollectionItems([...dataDelete]);

                    resolve();
                  });
                } else {
                  new Promise((resolve) => {
                    dataDelete = dataDelete.map((subitem, index) => {
                      if (dataIndex === index || rowData?.lookup_id === subitem?.parent_id) {
                        return {
                          ...subitem,
                          delete: false,
                        };
                      }
                      return subitem;
                    });
                    resolve();
                  });
                }

                setCollectionItems([...dataDelete]);
                values.collections = [...dataDelete];
                calculateTotals([...dataDelete]);
              },
              disabled: rowData?.parent_id !== null,
            }),
          ]}
          editable={{
            // onRowAdd: (newData) =>
            //   new Promise((resolve) => {
            //     setTimeout(() => {
            //       setCollectionItems([...collectionItems, newData]);

            //       resolve();
            //     }, 1000);
            //   }),
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve) => {
                let tmpDiscountedPrice = 0;
                let discount = 0;
                let tmpTotalPrice = 0;
                let tmpEach = 0;
                // setTimeout(() => {
                const dataUpdate = [...collectionItems];
                const index = oldData.tableData.id;
                discount =
                  Number(newData?.daily_price || 0) *
                  Number(convertToPecentDecimal(newData?.discount_rate || 0));

                tmpDiscountedPrice = (Number(newData?.daily_price) || 0) - discount;
                // tmpTotalPrice =
                //   Number(newData?.quantity || 0) *
                //   Number(tmpDiscountedPrice || 0) *
                //   Number(newData?.bill_days || 0);

                debugger;
                if (newData?.item_type?.value === "sales") {
                  tmpEach =
                    Number(newData?.daily_price || 0) *
                    (1 - Number(convertToPecentDecimal(newData?.discount_rate || 0)));
                } else {
                  tmpEach =
                    Number(newData?.daily_price || 0) *
                    Number(newData?.bill_days || 0) *
                    (1 - Number(convertToPecentDecimal(newData?.discount_rate || 0)));
                }
                // tmpEach =
                //   Number(newData?.daily_price || 0) *
                //   Number(newData?.bill_days || 0) *
                //   (1 - Number(convertToPecentDecimal(newData?.discount_rate || 0)));

                tmpTotalPrice = Number(newData?.quantity || 0) * Number(tmpEach || 0);

                dataUpdate[index] = {
                  ...newData,
                  discounted_price: tmpDiscountedPrice.toFixed(2),
                  each_amount: tmpEach.toFixed(2),
                  total_price: tmpTotalPrice.toFixed(2),
                };

                setCollectionItems([...dataUpdate]);
                values.collections = [...dataUpdate];
                calculateTotals([...dataUpdate]);

                // console.log(oldData, newData);
                // const handleOnBlur = () => {
                //   if (quantity > row?.original?.local_qty?.available) {
                //     handleOpenWarningDialog();
                //   }
                //   handleItems("edit-sub-item", { row, column: column?.id, value: quantity });
                // };

                resolve();
                // }, 1000);
              }),
          }}
          options={{
            search: false,
            selection: false,
            sorting: false,
            paging: false,
            toolbar: false,
            style: { border: "red" },
            actionsColumnIndex: -1,
            rowStyle: () => ({
              fontSize: 12,
            }),
          }}
        /> */}
        {/* )} */}
      </MDBox>

      {collectionItems.length > 0 && (
        <MDBox pl={3} pt={4}>
          <MDBox>
            <MDBox>
              <MDTypography variant="h5">Order Summary</MDTypography>
            </MDBox>
            <MDBox p={1}>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={3}>
                  <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Rental:
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {convertToCurrency(totals?.totalRental || 0)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Sales:
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {convertToCurrency(totals?.totalSales || 0)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Sub-total:
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {convertToCurrency(totals?.subtotal || 0)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>

                <Grid item xs={12} md={6} xl={3}>
                  <MDBox
                    display="flex"
                    py={1}
                    pr={2}
                    // onClick={() => {
                    //   console.log(customerDetails);
                    // }}
                  >
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Tax:
                    </MDTypography>
                    <MDBox mx={1}>
                      <Tooltip
                        title={`Sum Total Price * ${userDetail?.location?.name}'s Tax`}
                        placement="top"
                      >
                        <MDTypography variant="body2" fontWeight="medium">
                          {/* {convertToCurrency(taxPriceValue?.taxPrice || 0)} */}
                          {convertToCurrency(totals?.taxPrice || 0)}
                        </MDTypography>
                      </Tooltip>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                      Deposit amount:
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="body2" fontWeight="medium">
                        {convertToCurrency(values?.deposit_amount || 0)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="h5" fontWeight="medium" color="text">
                      Invoice total:
                    </MDTypography>
                    <MDBox ml={1}>
                      <MDTypography variant="h5" fontWeight="medium">
                        {convertToCurrency(totals?.invoiceTotal || 0)}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
      )}

      {showWarningDialog && (
        <DraggableDialog
          title="Insufficient Available Items"
          description="The available number is less than the desired quantity. You will need to sub-rent to cover the insufficient items."
          buttonDesc="Confirm"
          openDialog={showWarningDialog}
          handleClose={handleCloseWarningDialog}
          handleConfirm={handleCloseWarningDialog}
          fullWidth={false}
          showCancelBtn={false}
        />
      )}

      {showAddItemWarning && (
        <DraggableDialog
          title="Order already Staged"
          description="The current Order is already staged, adding new item will change the status back to staging."
          buttonDesc="Confirm"
          openDialog={showAddItemWarning}
          handleClose={() => handleCloseAddItemWarningDialog("close")}
          handleConfirm={() => handleCloseAddItemWarningDialog("add")}
          fullWidth={false}
          // showCancelBtn={false}
        />
      )}

      {showItemList && (
        <CustomModal
          showModal={showItemList}
          handleHideModal={handleCloseItemlistModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "98%",
            width: "80%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <ItemList
            handleClose={handleCloseItemlistModal}
            setCollectionItems={handleItems}
            getNestedCollections={getNestedCollections}
            collectionItems={collectionItems}
          />
        </CustomModal>
      )}
    </MDBox>
    // </DashboardLayout>
  );
}

// Setting default values for the props of DashboardNavbar
OrderItems.defaultProps = {
  userDetail: {},
  customerDetails: {},
  discountPercent: "",
  billDaysOrder: "",
};

// typechecking props for UserInfo
OrderItems.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
  customerDetails: PropTypes.objectOf(PropTypes.any),
  discountPercent: PropTypes.string,
  billDaysOrder: PropTypes.string,
};

const mapStateToProps = (state) => ({
  consignerListRedux: state?.helper?.consignerList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(OrderItems);
