/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Card from "@mui/material/Card";
import { Autocomplete, Divider, Icon, IconButton, Menu, TextField } from "@mui/material";

import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import MDTypography from "components/MDTypography";

import VendorMgr from "./VendorMgr";

const {
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  httpRequest,
  MANAGE_VENDORS,
  getResponseMsg,
} = VendorMgr;

function VendorSearch() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const [vendorList, setVendorList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  // const [prevSortId, setPrevSortId] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [menu, setMenu] = useState(null);
  const [vendorViltervalue, setVendorFiltervalue] = useState(null);
  const [vendorStatusFiltervalue, setVendorStatusFiltervalue] = useState({
    name: "Active only",
    value: "active",
  });

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const controllerRequest = new AbortController();

  const processRows = (data = []) => {
    const list = [];

    data.map((item) =>
      list.push({
        uuid: item.uuid,
        identifier: item.identifier,
        full_name: item.full_name,
        email: item.email,
        company_name: item.company_name || "Individual",
        status: item.status || "",
        last_completed_contract: item.last_completed_contract || "-",
      })
    );

    return list;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setVendorList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
    setIsLoading(false);
  };

  useEffect(async () => {
    setIsLoading(true);
    proccessResponse(await httpRequest("vendors-list", null, { searchKeyword, entriesPerPage }));

    return () => {
      controllerRequest.abort();
      setVendorList([]);
    };
  }, []);

  const showItemDetail = (values) => {
    setStorageItem("current_vendor_detail", values);
    navigate(MANAGE_VENDORS.DETAIL_ROUTE, {
      state: { from: location, searchKeyword },
      // replace: true,
    });
  };

  const dataTableData = {
    columns: [
      { accessor: "uuid", headerAlign: "left", width: "10%" },
      {
        Header: "identifier",
        accessor: "identifier",
        headerAlign: "left",
        width: "15%",
        hasSort: true,
      },
      {
        Header: "Vendor name",
        accessor: "full_name",
        // type: "detail-link",
        headerAlign: "left",
        width: "15%",
        hasSort: true,
        Cell: ({ row }) => {
          const status = (
            <MDTypography variant="button" color="text" onClick={() => showItemDetail(row.values)}>
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color={row.values.status === "active" ? "info" : "secondary"}
                fontWeight="medium"
                textGradient
              >
                {row.values.full_name}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "status",
        accessor: "status",
        headerAlign: "left",
        width: "10%",
        Cell: ({ row }) => {
          const status = (
            <MDBox>
              <IconButton
                size="small"
                aria-label="edit"
                color={row.values.status === "active" ? "success" : "error"}
              >
                <Icon fontSize="small">
                  {row.values.status === "active" ? "person" : "person_off"}
                </Icon>
              </IconButton>
              <MDTypography variant="button" textGradient>
                {row.values.status}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "company name",
        accessor: "company_name",
        headerAlign: "left",
        width: "15%",
        hasSort: true,
      },
      { Header: "email", accessor: "email", headerAlign: "left", width: "20%" },
      {
        Header: "last completed contract",
        headerAlign: "left",
        accessor: "last_completed_contract",
        width: "15%",
      },
    ],
    rows: vendorList || [],
  };

  const newVendor = () => {
    const temp = { ...[], pageState: "NEW" };
    setStorageItem("current_vendor_detail", temp);
    // console.log(temp);
    navigate(MANAGE_VENDORS.CREATE_ROUTE, {
      state: { from: location, searchKeyword },
      // replace: true,
    });
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("vendors-list", null, {
        searchKeyword: val,
        entriesPerPage,
        vendorType: vendorViltervalue?.value,
        vendorStatus: vendorStatusFiltervalue?.value,
      })
    );
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);

    proccessResponse(
      await httpRequest("vendors-list", null, {
        searchKeyword,
        entriesPerPage: val,
        vendorType: vendorViltervalue?.value,
        vendorStatus: vendorStatusFiltervalue?.value,
      })
    );
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(
      await getSort(
        sortType,
        id,
        searchKeyword,
        entriesPerPage,
        vendorViltervalue?.value,
        vendorStatusFiltervalue?.value
      )
    );

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const vendorFilterOptions = [
    { name: "Consigner", value: "consignment" },
    { name: "Sub-Vendor", value: "subvendor" },
  ];

  const vendorStatusOptions = [
    { name: "All status", value: "all" },
    { name: "Active only", value: "active" },
    { name: "Inactive only", value: "inactive" },
  ];

  const handleFilter = async (action) => {
    setIsLoading(true);
    switch (action) {
      case "apply-filter":
        proccessResponse(
          await httpRequest(
            "vendors-list",
            {},
            {
              searchKeyword,
              entriesPerPage,
              vendorType: vendorViltervalue?.value,
              vendorStatus: vendorStatusFiltervalue?.value,
            }
          )
        );
        break;

      case "remove-filter":
        setVendorFiltervalue(null);
        setVendorStatusFiltervalue({
          name: "Active only",
          value: "active",
        });
        proccessResponse(
          await httpRequest(
            "vendors-list",
            {},
            {
              searchKeyword,
              entriesPerPage,
              vendorType: vendorViltervalue?.value,
              vendorStatus: "active",
            }
          )
        );
        break;
      default:
        break;
    }

    closeMenu();
    setIsLoading(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Autocomplete
        disablePortal
        value={vendorViltervalue}
        options={vendorFilterOptions}
        onChange={(event, newValue) => {
          setVendorFiltervalue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 4, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Vendor Type" />}
      />
      <Autocomplete
        disablePortal
        value={vendorStatusFiltervalue}
        options={vendorStatusOptions}
        onChange={(event, newValue) => {
          setVendorStatusFiltervalue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 8, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Status" />}
      />

      <Divider sx={{
        margin: "0.5rem 0",
        opacity: "0.6"
      }} />
      <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
        <MDButton
          variant="gradient"
          size="medium"
          color="info"
          onClick={() => handleFilter("apply-filter")}
        >
          Apply Filter
        </MDButton>

        <MDButton
          variant="gradient"
          size="medium"
          color="error"
          onClick={() => handleFilter("remove-filter")}
        >
          Reset Filter
        </MDButton>
      </MDBox>
    </Menu>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={newVendor}>
            new vendor
          </MDButton>
          <MDBox display="flex">
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color={darkMode ? "white" : "dark"}
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            table={dataTableData}
            noEndBorder
            entriesPerPage={{ show: true }}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handleSearch={handleSearchKeyword}
            handleSort={handleSort}
            handleChangeEntries={handleChangeEntries}
            hasNextPage={nextPageLink !== null}
            hasPrevPage={prevPageLink !== null}
            totalCount={totalRows}
            title="Vendors List"
            isLoading={isLoading}
            canRefresh
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}
VendorSearch.propTypes = {};

const mapDispatchToProps = () => ({});

export default connect(null, mapDispatchToProps)(VendorSearch);
