/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Icon, IconButton, Slider, TextField } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomModal from "components/CustomModal";

import FormField from "layouts/Inventory/new-item/components/FormField";
import OrderMgr from "layouts/Order/OrderMgr";
import CustomerList from "layouts/Order/new-order/components/CustomerList";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomerContactList from "./CustomerContactList";
import { min } from "date-fns";

const {
    getDateToday,
    getCurrentTime,
    preventNegativeInput,
    httpRequest,
    getResponseMsg,
    convertToDecimal,
    getStorageItem,
    addDateFormatted,
} = OrderMgr;

const dateRegex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;

function Info({
    formData,
    userDetail,
    setCustomerDetails,
    setContactDetails,
    setDiscountPercent,
    setBillDaysOrder,
}) {
    const { formField, values } = formData;
    const {
        pickupDate,
        pickupTime,
        billingStartDate,
        billingStartTime,
        billingEndDate,
        billingEndTime,
        returnByDate,
        returnByTime,
        orderDescription,
        billDays,
        defaultDiscount,
        rentalAgentAssigned,
        depositAmount,
        status,
    } = formField;
    const {
        customer_name: customerNameV,
        customer_uuid: customerUuidV,
        pickup_date: pickupDateV,
        pickup_time: pickupTimeV,
        billing_start_date: billingStartDateV,
        billing_start_time: billingStartTimeV,
        billing_end_date: billingEndDateV,
        billing_end_time: billingEndTimeV,
        return_by_date: returnByDateV,
        return_by_time: returnByTimeV,
        order_description: orderDescriptionV,
        bill_days: billDaysV,
        default_discount: defaultDiscountV,
        rental_agent_assigned: rentalAgentAssignedV,
        deposit_amount: depositAmountV,
        status: statusV,
    } = values;

    const orderDetails = JSON.parse(getStorageItem("current_order_detail"));

    const [customerNameValue, setCustomerNameValue] = useState(customerNameV);

    const [contactName, setContactName] = useState();

    const [pickupDateValue, setPickUpDate] = useState(pickupDateV);
    const [pickupTimeValue, setPickUpTime] = useState(pickupTimeV);
    const [billingStartDateValue, setBillingStartDateValue] = useState(billingStartDateV);
    const [billingStartTimeValue, setBillingStartTimeValue] = useState(billingStartTimeV);
    const [billingEndDateValue, setBillingEndDateValue] = useState(billingEndDateV);
    const [billingEndTimeValue, setBillingEndTimeValue] = useState(billingEndTimeV);
    const [returnByDateValue, setReturnByDateValue] = useState(returnByDateV);
    const [returnByTimeValue, setReturnByTimeValue] = useState(returnByTimeV);
    const [orderDescriptionValue, setOrderDescriptionValue] = useState(orderDescriptionV);
    const [billDaysValue, setBillDaysValue] = useState(billDaysV);
    const [defaultDiscountValue, setDefaultDiscountValue] = useState(defaultDiscountV);
    const [rentalAgentAssignedValue, setRentalAgentAssignedValue] = useState(rentalAgentAssignedV);
    const [depositAmountValue, setdepositAmountValue] = useState(depositAmountV);
    const [statusValue, setStatusValue] = useState(statusV);

    const [showCustomerListModal, setShowCustomerListModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleOpenCustomerListModal = () => setShowCustomerListModal(true);
    const handleCloseCustomerListModal = () => setShowCustomerListModal(false);

    const [rentalAgentList, setRentalAgentList] = useState([
        { name: "rental agent 1", value: "rental1" },
        { name: "rental agent 2", value: "rental2" },
        { name: "rental agent 3", value: "rental3" },
        { name: "rental agent 4", value: "rental4" },
    ]);

    const orderStatusOption = [
        { name: "Quote", value: "quote" },
        { name: "Reservation", value: "reservation" },
        { name: "Confirmed", value: "confirmed" },
        { name: "Staging", value: "staging" },
        { name: "Staged", value: "staged" },
        { name: "On Rent", value: "on_rent" },
        { name: "Late", value: "late" },
        { name: "Awaiting Check In", value: "awaiting_check_in" },
        { name: "Check In", value: "checking_in" },
        { name: "Incomplete", value: "incomplete" },
        { name: "Returned", value: "returned" },
        { name: "Ready to Invoice", value: "ready_to_invoice" },
        { name: "Invoiced", value: "invoiced" },
        { name: "Complete", value: "complete" },
        { name: "Canceled", value: "canceled" },
        { name: "Closed", value: "closed" },
    ];

    const valueLabelFormat = (value) => `${value}%`;

    const handleChangeSlider = (event, newValue) => {
        // if (typeof newValue === "number") {
        //   setDefaultDiscountValue(newValue);
        //   values.default_discount = newValue;
        // }
        if (typeof newValue === "number") {
            setDefaultDiscountValue(event.target.value);
            values.default_discount = event.target.value;
        }
    };

    const handleChangeRentalAgent = (e, val) => {
        if (val) {
            setRentalAgentAssignedValue(val);
            values.rental_agent_assigned = val;
        }
    };

    const handleChangeOrderStatus = (e, val) => {
        if (val) {
            setStatusValue(val);
            values.status = val;
        }
    };

    const handleContact = (contact) => {
        //TODO: Clean up later because this is a band-aid solution
        values.customer_uuid = contact?.id;
        values.customer_name = `${contact?.first_name} ${contact?.last_name}`;

        let tmpContactName = `${contact?.first_name} ${contact?.last_name} - ${contact?.entity?.company_name}`;

        setContactDetails(contact);
        setContactName(tmpContactName);
    };

    const handleItems = async (action = "", rest = {}) => {
        setLoading(true);
        let responseDetails = {};
        let tmpCustName = "";
        let tmpPercentage = 0;
        switch (action) {
            case "set-customer":
                responseDetails = await httpRequest("customer-detail", null, {
                    uuid: rest.customer?.uuid,
                });

                if (getResponseMsg(responseDetails) === "Success") {
                    tmpCustName = `${responseDetails?.data?.data?.first_name} ${responseDetails?.data?.data?.last_name}`;
                    setCustomerDetails(responseDetails?.data?.data);
                    setCustomerNameValue(tmpCustName);
                    // console.log(responseDetails?.data?.data?.name);
                    values.customer_uuid = responseDetails?.data?.data?.uuid;
                    values.customer_name = tmpCustName;

                    tmpPercentage = (responseDetails?.data?.data?.default_discount || 0) * 100;
                    setDefaultDiscountValue(tmpPercentage);
                    setDiscountPercent(tmpPercentage);
                    values.default_discount = tmpPercentage;

                    values.taxExempt = responseDetails?.data?.data?.tax_exempt;
                }
                break;
            default:
                break;
        }
        setLoading(false);
    };

    const processRow = (data = []) => {
        const tmp = [];

        data.map((item) => {
            tmp.push({
                name: item?.full_name,
                value: item?.uuid,
            });

            return true;
        });

        return tmp;
    };

    useEffect(async () => {
        setLoading(true);
        const defaultAgent = {
            name: `${userDetail?.first_name || ""} ${userDetail?.last_name || ""}`,
            value: userDetail?.uuid || "",
        };
        const agentListResponse = await httpRequest("get-rental-agent-list");
        if (getResponseMsg(agentListResponse) === "Success") {
            setRentalAgentList(processRow(agentListResponse?.data?.data?.results));
            // debugger;
        }

        if (orderDetails.pageState === "NEW") {
            setCustomerNameValue(undefined);
            values.customer_uuid = undefined;

            setBillingStartDateValue(getDateToday());
            setBillingStartTimeValue("09:00");

            setBillingEndDateValue(getDateToday());
            setBillingEndTimeValue("16:00");

            setPickUpDate(getDateToday());
            setPickUpTime("14:00");

            setReturnByDateValue(addDateFormatted(getDateToday(), 1, "days", "YYYY-MM-DD"));
            setReturnByTimeValue("11:00");

            setOrderDescriptionValue("");
            setBillDaysValue(1);

            setBillDaysOrder(1);
            values.bill_days = 1;

            setDefaultDiscountValue(0);
            values.default_discount = 0;

            setRentalAgentAssignedValue(defaultAgent || []);
            values.rental_agent_assigned = defaultAgent || [];

            setdepositAmountValue(0);
            values.deposit_amount = 0;
        }

        setLoading(false);
    }, []);

    return (
        <MDBox>
            <MDBox width="100%" display="flex" justifyContent="space-between" p={3}>
                <MDTypography variant="h5">Order Info</MDTypography>
                <MDTypography variant="caption" fontWeight="regular" color="warning">
                    * = Required Fields
                </MDTypography>
            </MDBox>
            <MDBox component="form" px={3}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={2} sm={3} md={3}>
                        {/* <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            <Grid item xs={6} sm={6} md={6}>
                                <MDTypography
                                    variant="button"
                                    color="text"
                                    fontWeight="regular"
                                    mr={0.5}
                                >
                                    Contact Name *:
                                </MDTypography>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                {contactName !== undefined ? (
                                    <MDBox display="flex" alignItems="center">
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <MDBox display="flex" alignItems="center">
                                                <MDTypography
                                                    variant="button"
                                                    color="text"
                                                    fontWeight="medium"
                                                >
                                                    {contactName || "SELECT CONTACT"}
                                                </MDTypography>
                                                <MDBox mt={-1}>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="edit"
                                                        color="info"
                                                        onClick={handleOpenCustomerListModal}
                                                    >
                                                        <Icon fontSize="small">edit</Icon>
                                                    </IconButton>
                                                </MDBox>
                                            </MDBox>
                                        )}
                                    </MDBox>
                                ) : (
                                    <MDBox display="flex">
                                        <MDTypography
                                            variant="button"
                                            color="text"
                                            onClick={handleOpenCustomerListModal}
                                        >
                                            <MDTypography
                                                component={Link}
                                                to="#"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                SELECT CONTACT
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                )}
                            </Grid>
                        </Grid> */}

                        <MDBox
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-start"
                            pt={2}
                        >
                            <MDTypography variant="button" color="text" fontWeight="regular" mr={2}>
                                Contact Name *:
                            </MDTypography>
                            <MDBox>
                                {contactName !== undefined ? (
                                    <MDBox display="flex" alignItems="center">
                                        {loading ? (
                                            <CircularProgress />
                                        ) : (
                                            <MDBox display="flex" alignItems="center">
                                                <MDTypography
                                                    variant="button"
                                                    color="text"
                                                    fontWeight="medium"
                                                >
                                                    {contactName || "SELECT CONTACT"}
                                                </MDTypography>
                                                <MDBox mt={-1}>
                                                    <IconButton
                                                        size="small"
                                                        aria-label="edit"
                                                        color="info"
                                                        onClick={handleOpenCustomerListModal}
                                                    >
                                                        <Icon fontSize="small">edit</Icon>
                                                    </IconButton>
                                                </MDBox>
                                            </MDBox>
                                        )}
                                    </MDBox>
                                ) : (
                                    <MDBox display="flex">
                                        <MDTypography
                                            variant="button"
                                            color="text"
                                            onClick={handleOpenCustomerListModal}
                                        >
                                            <MDTypography
                                                component={Link}
                                                to="#"
                                                variant="button"
                                                color="info"
                                                fontWeight="medium"
                                                textGradient
                                            >
                                                SELECT CONTACT
                                            </MDTypography>
                                        </MDTypography>
                                    </MDBox>
                                )}
                            </MDBox>
                        </MDBox>
                    </Grid>

                    <Grid item xs={2} sm={3} md={3}>
                        <FormField
                            type={orderDescription.type}
                            label={orderDescription.label}
                            name={orderDescription.name}
                            value={orderDescriptionValue}
                            placeholder={orderDescription.placeholder}
                            multiline
                            // rows={2}
                            onChange={(e) => {
                                setOrderDescriptionValue(e.target.value);
                                values.order_description = e.target.value;
                            }}
                        />
                    </Grid>

                    <Grid item xs={2} sm={3} md={3}>
                        <MDBox>
                            <Autocomplete
                                options={rentalAgentList}
                                value={rentalAgentAssignedValue}
                                isOptionEqualToValue={(option, value) =>
                                    value !== "" ? option.name === value.name : option.name
                                }
                                getOptionLabel={(option) => (option ? option.name : "")}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}
                                        name={rentalAgentAssigned.name}
                                        variant="standard"
                                        label={rentalAgentAssigned.label}
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    handleChangeRentalAgent(event, newValue);
                                }}
                            />
                        </MDBox>
                    </Grid>

                    <Grid item xs={2} sm={3} md={3} mt={-0.5}>
                        {customerNameValue !== undefined && loading ? (
                            <CircularProgress />
                        ) : (
                            <MDBox>
                                {/* <MDTypography variant="button" gutterBottom>
                  {defaultDiscount.label}: {valueLabelFormat(defaultDiscountValue)}
                </MDTypography>
                <Slider
                  value={defaultDiscountValue}
                  min={0}
                  step={1}
                  max={100}
                  getAriaValueText={valueLabelFormat}
                  valueLabelFormat={valueLabelFormat}
                  onChange={handleChangeSlider}
                /> */}
                                <FormField
                                    type={defaultDiscount.type}
                                    label={defaultDiscount.label}
                                    name={defaultDiscount.name}
                                    value={defaultDiscountValue}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                        const value = preventNegativeInput(e.target.value);
                                        setDefaultDiscountValue(value);
                                        values.default_discount = value;
                                    }}
                                    onBlur={(e) => {
                                        const value = preventNegativeInput(e.target.value);
                                        setDiscountPercent(value);
                                    }}
                                    onKeyPress={(e) => {
                                        if (e.key === "Enter") {
                                            const value = preventNegativeInput(e.target.value);
                                            setDiscountPercent(value);
                                        }
                                    }}
                                />
                            </MDBox>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={billingStartDate.label}
                                value={billingStartDateValue}
                                onChange={(newValue) => {
                                    const value = moment(newValue?.$d || getDateToday()).format(
                                        "YYYY-MM-DD"
                                    );

                                    setBillingStartDateValue(value);
                                    values.billing_start_date = value;

                                    if (dateRegex.test(value)) {
                                        setPickUpDate(value);
                                        values.pickup_date = value;
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{ width: "auto" }}
                                        error={!dateRegex.test(billingStartDateValue)}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {!dateRegex.test(billingStartDateValue) && (
                            <MDBox mt={-1}>
                                <MDTypography variant="caption" fontWeight="regular" color="error">
                                    Invalid Date Format
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <FormField
                            type={billingStartTime.type}
                            label={billingStartTime.label}
                            name={billingStartTime.name}
                            value={billingStartTimeValue}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setBillingStartTimeValue(e.target.value);
                                values.billing_start_time = e.target.value;
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={pickupDate.label}
                                value={pickupDateValue}
                                onChange={(newValue) => {
                                    const value = moment(
                                        newValue?.$d || billingStartDateValue || getDateToday()
                                    ).format("YYYY-MM-DD");

                                    setPickUpDate(value);
                                    values.pickup_date = value;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{ width: "auto" }}
                                        error={!dateRegex.test(pickupDateValue)}
                                    />
                                )}
                            />
                        </LocalizationProvider>{" "}
                        {!dateRegex.test(pickupDateValue) && (
                            <MDBox mt={-1}>
                                <MDTypography variant="caption" fontWeight="regular" color="error">
                                    Invalid Date Format
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <FormField
                            type={pickupTime.type}
                            label={pickupTime.label}
                            name={pickupTime.name}
                            value={pickupTimeValue}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const value =
                                    e.target.value === ""
                                        ? billingStartTimeValue || getCurrentTime()
                                        : e.target.value;
                                setPickUpTime(value);
                                values.pickup_time = value;
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={billingEndDate.label}
                                value={billingEndDateValue}
                                onChange={(newValue) => {
                                    // console.log(newValue);
                                    const value = moment(newValue?.$d || getDateToday()).format(
                                        "YYYY-MM-DD"
                                    );

                                    // if (newValue.$d.toString() !== "Invalid date") {
                                    setBillingEndDateValue(value);
                                    values.billing_end_date = value;

                                    if (dateRegex.test(value)) {
                                        setReturnByDateValue(
                                            addDateFormatted(
                                                value || getDateToday(),
                                                1,
                                                "days",
                                                "YYYY-MM-DD"
                                            )
                                        );
                                        values.return_by_date = addDateFormatted(
                                            value || getDateToday(),
                                            1,
                                            "days",
                                            "YYYY-MM-DD"
                                        );
                                    }
                                }}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}
                                        readOnly
                                        sx={{ width: "auto" }}
                                        error={!dateRegex.test(billingEndDateValue)}
                                    />
                                )}
                            />
                        </LocalizationProvider>

                        {!dateRegex.test(billingEndDateValue) && (
                            <MDBox mt={-1}>
                                <MDTypography variant="caption" fontWeight="regular" color="error">
                                    Invalid Date Format
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <FormField
                            type={billingEndTime.type}
                            label={billingEndTime.label}
                            name={billingEndTime.name}
                            value={billingEndTimeValue}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                setBillingEndTimeValue(e.target.value);
                                values.billing_end_time = e.target.value;
                            }}
                        />
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={returnByDate.label}
                                value={returnByDateValue}
                                onChange={(newValue) => {
                                    // console.log(newValue);

                                    const value = moment(
                                        newValue?.$d || billingStartTimeValue || getDateToday()
                                    ).format("YYYY-MM-DD");

                                    setReturnByDateValue(value);
                                    values.return_by_date = value;
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{ width: "auto" }}
                                        error={!dateRegex.test(returnByDateValue)}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                        {!dateRegex.test(returnByDateValue) && (
                            <MDBox mt={-1}>
                                <MDTypography variant="caption" fontWeight="regular" color="error">
                                    Invalid Date Format
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>
                    <Grid item xs={2} sm={3} md={3}>
                        <FormField
                            type={returnByTime.type}
                            label={returnByTime.label}
                            name={returnByTime.name}
                            value={returnByTimeValue}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => {
                                const value =
                                    e.target.value === ""
                                        ? billingEndTimeValue || getCurrentTime()
                                        : e.target.value;
                                setReturnByTimeValue(value);
                                values.return_by_time = value;
                            }}
                        />
                    </Grid>
                </Grid>
            </MDBox>

            {showCustomerListModal && (
                <CustomModal
                    showModal={showCustomerListModal}
                    handleHideModal={handleCloseCustomerListModal}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "90%",
                        width: "60%%",
                        minWidth: "500px",
                        // border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "5px",
                        padding: 4,
                        overflow: "auto",
                    }}
                >
                    {/* <CustomerList
                        handleClose={handleCloseCustomerListModal}
                        handleItems={handleItems}
                    /> */}

                    <CustomerContactList
                        handleClose={handleCloseCustomerListModal}
                        handleContact={handleContact}
                    />
                </CustomModal>
            )}
        </MDBox>
    );
}

// Setting default values for the props of DashboardNavbar
Info.defaultProps = {
    userDetail: {},
};

// typechecking props for UserInfo
Info.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    userDetail: PropTypes.objectOf(PropTypes.any),
    setCustomerDetails: PropTypes.func.isRequired,
    setDiscountPercent: PropTypes.func.isRequired,
    setBillDaysOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    categoriesListRedux: state?.helper?.categoryList,
    userDetail: state.authentication.userDetail,
});

export default connect(mapStateToProps, null)(Info);
