/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ReportsMgr from "layouts/Reports/ReportsMgr";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const { formatDate, getDateToday, convertToCurrency, addDateFormatted } = ReportsMgr;

const styleBotderTopBottom = {
  borderStyle: "solid none solid none",
  borderWidth: "medium",
};

function ConsignmentReport({ userDetail, reportsData }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const generateReportOptions = [
    { name: "Consignment report", value: "1" },
    { name: "Daily rentals", value: "2" },
    { name: "Weekly rentals", value: "3" },
    { name: "Monthly rentals", value: "4" },
    { name: "Sales tax report by site", value: "5" },
    { name: "Receivables report", value: "6" },
    // { name: "Payables  report", value: "1" },
  ];

  const [warehouseFilter, setWarehouseFilter] = useState(userDetail?.warehouse);
  const [dateFrom, setDateFrom] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [dateTo, setDateTo] = useState(addDateFormatted(getDateToday(), 6, "day", "MM/DD/YYYY"));
  const [monthOf, setMonthOf] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [reportToGenerate, setReportToGenerate] = useState(generateReportOptions[1] || null);

  return (
    <MDBox>
      {/* {itemList?.length > 0 && (
        <MDBox ml={1} mb={2} display="flex" justifyContent="flex-end">
          <MDBox mr={1}>
            <MDButton
              variant="outlined"
              color="dark"
              onClick={async () => {
                setIsLoading(true);
                let response = {};
                switch (reportToGenerate.value) {
                  case generateReportOptions[1].value:
                    response = await httpRequest(
                      "export-daily-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                        exportType: "pdf",
                      }
                    );
                    break;
                  case generateReportOptions[2].value:
                    response = await httpRequest(
                      "export-weekly-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                        exportType: "pdf",
                      }
                    );
                    break;
                  case generateReportOptions[3].value:
                    response = await httpRequest(
                      "export-monthly-rentals",
                      {},
                      {
                        monthOf: (moment(monthOf).month() || 0) + 1,
                        warehouse: warehouseFilter?.id,
                        exportType: "pdf",
                      }
                    );
                    break;
                  default:
                    break;
                }

                if (getResponseMsg(response) === "Success") {
                  window.open(response?.data?.data?.url, "_blank", "noreferrer");
                }
                setIsLoading(false);
              }}
            >
              <Icon>description</Icon>
              &nbsp;export PDF
            </MDButton>
          </MDBox>
        </MDBox>
      )} */}
      <MDBox>
        <Card width="100%">
          <MDBox sx={{ margin: 2 }}>
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium">
                CONSIGNOR STATEMENT REPORT
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              // bgColor="#9effe2"
              sx={{
                borderBottom: "2px solid #3053ff",
                borderTop: "2px solid #3053ff",
              }}
            >
              <MDBox display="flex">
                <MDTypography variant="body2">Issued to:&nbsp;</MDTypography>
                <MDTypography variant="h6">{reportsData?.issued_to?.name}</MDTypography>
              </MDBox>
              <MDBox display="flex">
                <MDTypography variant="body2">Statement Period:&nbsp;</MDTypography>
                <MDTypography variant="h6">{reportsData?.statement_period}</MDTypography>
              </MDBox>
            </MDBox>

            <MDBox display="flex" justifyContent="flex-end" alignItems="center">
              <MDBox width="30%">
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    Report Summary
                  </MDTypography>
                </MDBox>
                <MDBox width="100%" textAlign="right">
                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{
                      borderStyle: "solid",
                      borderWidth: "medium",
                      paddingLeft: 0.5,
                      paddingRight: 0.5,
                    }}
                  >
                    <Grid item xs={4} sm={8} md={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Net
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        House
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        Consignor
                      </MDTypography>
                    </Grid>
                    {/* <Grid item xs={4} sm={8} md={3}>
                  Paid
                </Grid>
                <Grid item xs={4} sm={8} md={3}>
                  Due
                </Grid> */}
                  </Grid>
                  <Grid
                    container
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    sx={{
                      borderStyle: "none solid solid solid",
                      borderWidth: "medium",
                      paddingLeft: 0.5,
                      paddingRight: 0.5,
                    }}
                  >
                    <Grid item xs={4} sm={8} md={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {convertToCurrency(reportsData?.totals?.net)}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {convertToCurrency(reportsData?.totals?.house)}
                      </MDTypography>
                    </Grid>
                    <Grid item xs={4} sm={8} md={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {convertToCurrency(reportsData?.totals?.consignor)}
                      </MDTypography>
                    </Grid>
                    {/* <Grid item xs={4} sm={8} md={3}>
                  Paid
                </Grid>
                <Grid item xs={4} sm={8} md={3}>
                  Due
                </Grid> */}
                  </Grid>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox marginTop={2}>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  borderStyle: "solid none solid none",
                  borderWidth: "medium",
                  paddingLeft: 0.5,
                  paddingRight: 0.5,
                }}
              >
                <Grid item xs={4} sm={8} md={1.5}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Date
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1.5}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Order Number
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Status
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2.5}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Billing Period
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={2.5}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Customer Name
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Net
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    House
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Consignor
                  </MDTypography>
                </Grid>
              </Grid>
              {reportsData?.results?.length > 0 ? (
                reportsData?.results.map((entry) => (
                  <MDBox>
                    <Grid
                      container
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{
                        paddingLeft: 0.5,
                        paddingRight: 0.5,
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={4} sm={8} md={1.5}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.invoice_date}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={1.5}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.order_number}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.status}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={2.5}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.billing_period}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={2.5}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.customer?.name}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={1} textAlign="right">
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.net}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={1} textAlign="right">
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.house}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={1} textAlign="right">
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.consignor}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    {entry?.consigned_subitems?.length > 0 &&
                      entry?.consigned_subitems.map((subitem) => (
                        <Grid
                          container
                          columns={{ xs: 4, sm: 8, md: 12 }}
                          sx={{
                            paddingLeft: 0.5,
                            paddingRight: 0.5,
                          }}
                        >
                          <Grid item xs={4} sm={8} md={1.5}>
                            <MDTypography variant="body2"> </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1.5}>
                            <MDTypography variant="body2"> </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={6}>
                            <MDTypography
                              variant="body2"
                              // fontWeight="medium"
                              sx={{ fontWeight: "regular", fontSize: "16px" }}
                            >
                              {subitem?.identifier}&nbsp;&nbsp;{subitem?.name}&nbsp;&nbsp;
                              {subitem?.description}
                              &nbsp;&nbsp;
                              {subitem?.barcode_id}
                            </MDTypography>
                          </Grid>
                          {/* <Grid item xs={4} sm={8} md={1}>
                            <MDTypography variant="body2">{subitem?.net}</MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1}>
                            <MDTypography variant="body2">{subitem?.house}</MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1}>
                            <MDTypography variant="body2">{subitem?.consignor}</MDTypography>
                          </Grid> */}
                        </Grid>
                      ))}
                    <MDBox textAlign="right">
                      <Grid
                        container
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{
                          paddingLeft: 0.5,
                          paddingRight: 0.5,
                          marginTop: 1,
                        }}
                      >
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={9}
                          display="flex"
                          justifyContent="flex-end"
                          paddingRight={5}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            Total for {reportsData?.issued_to?.name}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} sm={8} md={1} sx={styleBotderTopBottom}>
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(reportsData?.totals?.net)}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} sm={8} md={1} sx={styleBotderTopBottom}>
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(reportsData?.totals?.house)}
                          </MDTypography>
                        </Grid>
                        <Grid item xs={4} sm={8} md={1} sx={styleBotderTopBottom}>
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(reportsData?.totals?.consignor)}
                          </MDTypography>
                        </Grid>
                        {/* <Grid item xs={4} sm={8} md={3}>
                  Paid
                </Grid>
                <Grid item xs={4} sm={8} md={3}>
                  Due
                </Grid> */}
                      </Grid>
                    </MDBox>
                  </MDBox>
                ))
              ) : (
                <MDTypography variant="h5" textAlign="center">
                  No Record
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  );
}

ConsignmentReport.defaultProps = {
  userDetail: {},
  reportsData: [],
};

ConsignmentReport.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
  reportsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
});

export default connect(mapStateToProps, null)(ConsignmentReport);
