import {
  LOGIN,
  SET_AUTH_DATA,
  LOGOUT,
  SET_USER_DETAIL,
  UPDATE_USER_LOCATION,
  UPDATE_USER_WAREHOUSE,
} from "./types";

const logIn = (params) => ({ type: LOGIN, payload: params });
const logOut = () => ({ type: LOGOUT });
const setUserData = (params) => ({ type: SET_AUTH_DATA, payload: params });
const setMeDetails = (params) => ({ type: SET_USER_DETAIL, payload: params });
const updateUserLocation = (params) => ({ type: UPDATE_USER_LOCATION, payload: params });
const updateUserWarehouse = (params) => ({ type: UPDATE_USER_WAREHOUSE, payload: params });

const authActions = {
  logIn,
  setUserData,
  logOut,
  setMeDetails,
  updateUserLocation,
  updateUserWarehouse,
};

export default authActions;
