/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "components/CustomTimelineItem/TimelineList";
import TimelineItem from "components/CustomTimelineItem/TimelineItem";

// Data
import timelineData from "layouts/pages/projects/timeline/data/timelineData";
import OrderMgr from "layouts/Order/OrderMgr";
import MDTypography from "components/MDTypography";
import { CircularProgress, Icon, IconButton } from "@mui/material";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  convertTimeZoneFormatDateTime,
  setStorageItem,
  ORDERS,
  capitalizeFirstLetter,
  formatDate,
  convertToCurrency,
} = OrderMgr;

function RevisionLog({ onClose, userDetail, loadDetails }) {
  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));

  const [isLoading, setIsLoading] = useState(false);
  const [revisionLogData, setRevisionLogData] = useState([]);

  const processRows = (data = []) => {
    const revisionLogs = [];

    data.map((revision, index) => {
      let icon = "";
      let color = "";
      // let description = {};
      const description = [];
      if (revision?.action === "canceled") {
        icon = "cancel";
        color = "error";
      } else if (revision?.action === "created") {
        icon = "note_add";
        color = "success";
      } else {
        icon = "edit";
        color = "info";
      }

      if (revision?.action === "updated") {
        Object.entries(revision?.changes).map((change) => {
          // console.log(change);
          const added = [];
          const deleted = [];
          const updated = [];
          switch (change[0]) {
            case "billing":
              if (change[1]?.end_date) {
                description.push(
                  `${capitalizeFirstLetter("end_date").replaceAll(
                    "_",
                    " "
                  )} changed from (${formatDate(change[1]?.end_date.from, "lll")}) to (${formatDate(
                    change[1]?.end_date.to,
                    "lll"
                  )})`
                );
              }
              if (change[1]?.start_date) {
                description.push(
                  `${capitalizeFirstLetter("start_date").replaceAll(
                    "_",
                    " "
                  )} changed from (${formatDate(
                    change[1]?.start_date.from,
                    "lll"
                  )}) to (${formatDate(change[1]?.start_date.to, "lll")})`
                );
              }
              break;

            case "status":
              description.push(
                `${capitalizeFirstLetter(change[0]).replaceAll(
                  "_",
                  " "
                )} changed from (${capitalizeFirstLetter(change[1]?.from).replaceAll(
                  "_",
                  " "
                )}) to (${capitalizeFirstLetter(change[1]?.to).replaceAll("_", " ")})`
              );
              break;

            case "items":
              if (change[1]?.added && change[1]?.added.length > 0) {
                change[1]?.added.map((add) => {
                  added.push(add?.name);
                  return added;
                });
              } else {
                added.push("None");
              }

              if (change[1]?.deleted && change[1]?.deleted.length > 0) {
                change[1]?.deleted.map((del) => {
                  deleted.push(
                    `${del?.name} ${del?.quantity !== undefined && `(${del?.quantity})`} `
                  );
                  return deleted;
                });
              } else {
                deleted.push("None");
              }

              if (change[1]?.updated && change[1]?.updated.length > 0) {
                change[1]?.updated.map((ups) => {
                  Object.entries(ups).map((ent) => {
                    updated.push(`
                  ${capitalizeFirstLetter(ent[0]).replaceAll("_", " ")}'s`);
                    Object.entries(ent[1]).map((ent2) => {
                      updated.push(
                        `${capitalizeFirstLetter(ent2[0]).replaceAll("_", " ")} from (${
                          ent2[0] === "total_price" ||
                          ent2[0] === "price" ||
                          ent2[0] === "each_amount" ||
                          ent2[0] === "discounted_price"
                            ? convertToCurrency(ent2[1]?.from)
                            : ent2[1]?.from
                        }) to (${
                          ent2[0] === "total_price" ||
                          ent2[0] === "price" ||
                          ent2[0] === "each_amount" ||
                          ent2[0] === "discounted_price"
                            ? convertToCurrency(ent2[1]?.to)
                            : ent2[1]?.to
                        }), 
                        `
                      );
                      return ent2;
                    });
                    return ent;
                  });

                  // updated.push(ups?.name);
                  return updated;
                });
              } else {
                updated.push("None");
              }

              description.push(
                `${capitalizeFirstLetter(change[0]).replaceAll("_", " ")} added: ${added.join(", ")}
              Items removed: ${deleted.join(", ")}
              Items updated: ${updated.join(" ")}`
              );
              //   description = `${capitalizeFirstLetter(change[0]).replaceAll("_", " ")}:
              // \n Added: ${added.join(", ")}
              // \n Deleted: ${deleted.join(", ")}`;
              // description = {
              //   title: capitalizeFirstLetter(change[0]).replaceAll("_", " "),
              //   body1: { label: "Added : ", value: `${added.join()}` },
              //   body2: { label: "Deleted : ", value: `${deleted.join(", ")}` },
              // };
              break;

            case "subitems":
              if (change[1]?.deleted && change[1]?.deleted.length > 0) {
                change[1]?.deleted.map((del) => {
                  deleted.push(`${del?.item?.name} (${del?.name})`);
                  return deleted;
                });
              } else {
                deleted.push("None");
              }
              description.push(
                `${capitalizeFirstLetter(change[0]).replaceAll("_", " ")} removed: ${deleted.join(
                  ", "
                )}
                `
              );
              break;

            default:
              if (change[0] === "pickup_date" || change[0] === "return_date") {
                description.push(
                  `${capitalizeFirstLetter(change[0]).replaceAll(
                    "_",
                    " "
                  )} changed from (${formatDate(change[1]?.from, "lll")}) to (${formatDate(
                    change[1]?.to,
                    "lll"
                  )})`
                );
              } else if (change[0] === "total") {
                description.push(
                  `${capitalizeFirstLetter(change[0]).replaceAll(
                    "_",
                    " "
                  )} changed from (${convertToCurrency(change[1]?.from)}) to (${convertToCurrency(
                    change[1]?.to
                  )})`
                );
              } else {
                description.push(
                  `${capitalizeFirstLetter(change[0]).replaceAll("_", " ")} changed from (${
                    change[1]?.from
                  }) to (${change[1]?.to})`
                );
              }
              break;
          }

          return 0;
        });
      } else if (revision?.action === "payment") {
        description.push(
          `Payment of ${convertToCurrency(revision?.changes?.amount_paid || 0)} ${
            revision?.changes?.payment_method.includes("VISA")
              ? `with processing fee of ${convertToCurrency(
                  revision?.changes?.process_fee?.amount
                )} `
              : ""
          }through **${
            revision?.changes?.payment_method
          }** has been made on ${convertTimeZoneFormatDateTime(
            revision?.changes?.payment_date,
            userDetail?.location?.timezone?.name,
            userDetail?.location?.timezone?.abbreviation,
            "lll"
          )}, remaining balance is ${convertToCurrency(revision?.changes?.remaining_balance)}.
          Available Refund amount is ${convertToCurrency(
            revision?.changes?.available_refund_amount
          )}`
        );
      } else if (revision?.action === "refund") {
        description.push(
          `Date and Time: ${convertTimeZoneFormatDateTime(
            revision?.changes?.refunded_date,
            userDetail?.location?.timezone?.name,
            userDetail?.location?.timezone?.abbreviation,
            "lll"
          )}
          Refund amount is ${convertToCurrency(revision?.changes?.refunded_amount)}
          Refunded through ${revision?.changes?.refunded_through || "Stripe"}`
        );
      } else if (revision?.action === "deleted") {
        // FROM CHECKOUT
        Object.entries(revision?.changes).map((change) => {
          const deleted = [];
          if (change[1]?.deleted && change[1]?.deleted.length > 0) {
            change[1]?.deleted.map((del) => {
              deleted.push(`${del?.name} (${del?.quantity}) `);
              return deleted;
            });
          } else {
            deleted.push("None");
          }
          return 0;
        });
      } else {
        description.push(`Order has been Created.`);
      }

      revisionLogs.push({
        ...revision,
        color,
        icon,
        title: revision?.user?.name,
        action: revision?.action,
        dateTime: convertTimeZoneFormatDateTime(
          revision.date,
          userDetail?.location?.timezone?.name,
          userDetail?.location?.timezone?.abbreviation,
          "lll"
        ),
        date: revision.date,
        // dateTime: "22 DEC 7:20 PM",
        description: description.join("\n"),
        badges: ["design"],
        status: revision?.changes?.status || "",
        lastItem: data.length - 1 === index,
        paymentLink: revision?.changes?.payment_url,
        paymentMethod: revision?.changes?.payment_method,
        paymentId: revision?.changes?.payment_id,
        availableRefundAmount: revision?.changes?.available_refund_amount,
      });
      return 0;
    });

    return revisionLogs;
  };

  const getTransactionList = async () => {
    setIsLoading(true);

    const response = await httpRequest("revision-logs", null, { uuid: orderDetail?.uuid });

    if (getResponseMsg(response) === "Success") {
      setRevisionLogData(processRows(response?.data?.data?.results));
    }

    setIsLoading(false);
    return 0;
  };

  useEffect(() => {
    getTransactionList();
  }, []);

  const renderTimelineItems = revisionLogData.map(
    (
      {
        color,
        icon,
        title,
        dateTime,
        description,
        badges,
        lastItem,
        action,
        date,
        status,
        paymentLink,
        paymentMethod,
        availableRefundAmount,
      },
      index
    ) => (
      <TimelineItem
        key={title + date}
        color={color}
        icon={icon}
        title={title || ""}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
        status={status}
        paymentLink={paymentLink}
        paymentMethod={paymentMethod}
        action={capitalizeFirstLetter(action)}
        revisionData={revisionLogData[index]}
        loadDetails={loadDetails}
        closeRevisionLog={onClose}
        availableRefundAmount={availableRefundAmount}
      />
    )
  );

  return (
    <MDBox>
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Revision Log
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox mt={-2}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            {isLoading ? (
              <MDBox mt="30%" display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
              </MDBox>
            ) : (
              <TimelineList>{renderTimelineItems}</TimelineList>
            )}
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

RevisionLog.defaultProps = {
  userDetail: {},
  loadDetails: () => {},
};

RevisionLog.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func,
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(RevisionLog);
