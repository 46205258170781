import {
  SET_COUNTRY_LIST,
  SET_LOCATION_LIST,
  SET_WAREHOUSE_LIST,
  RESET_COMMON_LIST,
  SET_CATEGORY_LIST,
  SET_CONSIGNER_LIST,
} from "../actions/types";

const initialState = {
  country: [],
  locations: [],
  warehouse: [],
  categoryList: [],
  consignerList: [],
};

const helperReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COUNTRY_LIST:
      return {
        ...state,
        country: action.payload,
      };

    case SET_LOCATION_LIST:
      return {
        ...state,
        locations: action.payload,
      };

    case SET_WAREHOUSE_LIST:
      return {
        ...state,
        warehouse: action.payload,
      };

    case SET_CATEGORY_LIST:
      return {
        ...state,
        categoryList: action.payload,
      };

    case SET_CONSIGNER_LIST:
      return {
        ...state,
        consignerList: action.payload,
      };

    case RESET_COMMON_LIST:
      return initialState;
    default:
      break;
  }
  return state;
};

export default helperReducer;
