/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import Card from "@mui/material/Card";
import { Autocomplete, Divider, Icon, IconButton, Menu, TextField, Tooltip } from "@mui/material";

import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";

import LocationMgr from "./LocationMgr";

const {
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  httpRequest,
  MANAGE_LOCATIONS,
  getResponseMsg,
} = LocationMgr;

function LocationSearch() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  // const [prevSortId, setPrevSortId] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Successfully Deactivated Location!");
  const [successSB, setSuccessSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to Deactivate Location.");
  const [errorSB, setErrorSB] = useState(false);
  const [userToBeDeactivated, setUserToBeDeactivated] = useState({});
  const [notifTitle, setNotifTitle] = useState("Location Deletion");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [menu, setMenu] = useState(null);
  const [userFilterValue, setUserFilterValue] = useState(null);
  const [userStatusFilterValue, setUserStatusFilterValue] = useState({
    name: "Active only",
    value: "active",
  });

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const handleOpenDeactivateDialog = () => setShowDeactivateDialog(true);
  const handleCloseDeactiVateDialog = () => setShowDeactivateDialog(false);
  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const controllerRequest = new AbortController();

  const processRows = (data = []) => {
    const list = [];

    data.map((item) => {
      list.push({ ...item?.address, id: item?.id, name: item?.name, status: item?.status });
      return 0;
    });

    // console.log(list);
    return list;
  };

  const proccessResponse = (response) => {
    setLocationList(processRows(response?.data?.data?.results));
    setNextPageLink(response.data.data?.next);
    setPrevPageLink(response.data.data?.previous);
    setTotalRows(response.data.data?.count);
  };

  useEffect(async () => {
    setIsLoading(true);
    const response = await httpRequest("locations-list", null, { searchKeyword, entriesPerPage });
    if (getResponseMsg(response) === "Success") {
      proccessResponse(response);
    }

    setIsLoading(false);
    return () => {
      controllerRequest.abort();
      // setLocationList([]);
    };
  }, []);

  const showItemDetail = (values) => {
    // console.log(values);
    setStorageItem("current_location_detail", values);
    navigate(MANAGE_LOCATIONS.DETAIL_ROUTE, {
      state: { from: location, searchKeyword },
      // replace: true,
    });
  };

  const editLocation = async (val = {}) => {
    setIsLoading(true);

    const responseDetails = await httpRequest("locations-detail", null, {
      uuid: val?.id,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      const locationDetails = responseDetails?.data?.data;

      const item = {
        id: locationDetails?.id,
        name: locationDetails?.name,
        taxRate: locationDetails?.tax_rate,
        timezone: locationDetails?.timezone,
        mobile: locationDetails?.contact.mobile,
        telephone: locationDetails?.contact.telephone,
        fax: locationDetails?.contact.fax,
        other: locationDetails?.contact.other,
        line_1: locationDetails?.address.line_1,
        line_2: locationDetails?.address.line_2,
        city: locationDetails?.address.city,
        state: locationDetails?.address.state,
        country: locationDetails?.address.country,
        zip_code: locationDetails?.address.zip_code,
      };
      const temp = { ...item, pageState: "EDIT" };
      setStorageItem("current_location_detail", temp);
      navigate(MANAGE_LOCATIONS.EDIT_ROUTE, {
        state: { from: location, searchKeyword },
        // replace: true,
      });
    } else {
      setErrMsg("Failed to fetch Location Detail.");
      openErrorSB(true);
    }
    setIsLoading(false);
  };

  const dataTableData = {
    columns: [
      {
        Header: "Location name",
        accessor: "name",
        // type: "detail-link",
        width: "15%",
        headerAlign: "left",
        // hasSort: true,
        Cell: ({ row }) => {
          const status = (
            <MDTypography
              variant="button"
              color="text"
              onClick={() => showItemDetail(row.original)}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {row.values.name}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "Address",
        accessor: "",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDTypography variant="body" color="inherit" fontSize="small">
              {`${row?.original?.line_1} ${row?.original?.line_2} ${row?.original?.country?.name}, ${row?.original?.city}, ${row?.original?.state}, ${row?.original?.zip_code}`}
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "action",
        accessor: "",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <IconButton
                size="small"
                aria-label="edit"
                color="info"
                onClick={() => editLocation(row?.original)}
                // disabled
              >
                <Tooltip title="Edit Location" placement="top">
                  <Icon fontSize="small">edit</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                aria-label="edit"
                color="error"
                onClick={() => {
                  // console.log(row?.original);
                  setUserToBeDeactivated(row?.original);
                  handleOpenDeactivateDialog();
                  // handleOpenDeleteDialog();
                }}
                disabled={row?.original?.status === "inactive"}
              >
                <Tooltip title="Deactivate Location" placement="top">
                  <Icon fontSize="small">location_off</Icon>
                </Tooltip>
                {/* <Icon fontSize="small">person_off</Icon> */}
              </IconButton>
            </MDBox>
          );
          return status;
        },
      },
      // { Header: "last completed contract", accessor: "last_completed_contract", width: "15%" },
    ],
    rows: locationList || [],
  };

  const newVendor = () => {
    const temp = { ...[], pageState: "NEW" };
    setStorageItem("current_location_detail", temp);
    // console.log(temp);
    navigate(MANAGE_LOCATIONS.CREATE_ROUTE, {
      state: { from: location, searchKeyword },
      // replace: true,
    });
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));

    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));

    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("locations-list", null, {
        searchKeyword: val,
        entriesPerPage,
        // vendorType: userFilterValue?.value,
        // vendorStatus: userStatusFilterValue?.value,
      })
    );
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);

    proccessResponse(
      await httpRequest("locations-list", null, {
        searchKeyword,
        entriesPerPage: val,
        // vendorType: userFilterValue?.value,
        // vendorStatus: userStatusFilterValue?.value,
      })
    );
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(
      await getSort(
        sortType,
        id,
        searchKeyword,
        entriesPerPage
        // userFilterValue?.value,
        // userStatusFilterValue?.value
      )
    );

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const vendorFilterOptions = [
    { name: "Consigner", value: "consignment" },
    { name: "Sub-Vendor", value: "subvendor" },
  ];

  const vendorStatusOptions = [
    { name: "All status", value: "all" },
    { name: "Active only", value: "active" },
    { name: "Inactive only", value: "inactive" },
  ];

  const handleFilter = async (action) => {
    setIsLoading(true);
    switch (action) {
      case "apply-filter":
        proccessResponse(
          await httpRequest(
            "locations-list",
            {},
            {
              searchKeyword,
              entriesPerPage,
              vendorType: userFilterValue?.value,
              vendorStatus: userStatusFilterValue?.value,
            }
          )
        );
        break;

      case "remove-filter":
        setUserFilterValue(null);
        setUserStatusFilterValue({
          name: "Active only",
          value: "active",
        });
        proccessResponse(
          await httpRequest(
            "locations-list",
            {},
            {
              searchKeyword,
              entriesPerPage,
              vendorType: userFilterValue?.value,
              vendorStatus: "active",
            }
          )
        );
        break;
      default:
        break;
    }

    closeMenu();
    setIsLoading(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Autocomplete
        disablePortal
        value={userFilterValue}
        options={vendorFilterOptions}
        onChange={(event, newValue) => {
          setUserFilterValue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 4, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Vendor Type" />}
      />
      <Autocomplete
        disablePortal
        value={userStatusFilterValue}
        options={vendorStatusOptions}
        onChange={(event, newValue) => {
          setUserStatusFilterValue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 8, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Status" />}
      />

      <Divider sx={{
        margin: "0.5rem 0",
        opacity: "0.6"
      }} />
      <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
        <MDButton
          variant="gradient"
          size="medium"
          color="info"
          onClick={() => handleFilter("apply-filter")}
        >
          Apply Filter
        </MDButton>

        <MDButton
          variant="gradient"
          size="medium"
          color="error"
          onClick={() => handleFilter("remove-filter")}
        >
          Reset Filter
        </MDButton>
      </MDBox>
    </Menu>
  );

  const handleConfirm = async (action) => {
    // console.log(action, userToBeDeactivated);
    setIsLoading(true);

    let response = {};
    switch (action) {
      case "deactivate":
      case "delete":
        handleCloseDeactiVateDialog();
        setNotifTitle("Location Deactivation");
        // handleCloseDeleteDialog();
        response = await httpRequest("locations-deactivate", null, {
          uuid: userToBeDeactivated.id,
        });

        if (getResponseMsg(response) === "Success") {
          setSuccessMsg("Successfully Deactivated Location");
          // setSuccessMsg("Successfully Deleted Location");
          openSuccessSB(true);

          setIsLoading(true);
          const locationListResponse = await httpRequest("locations-list", null, {
            searchKeyword,
            entriesPerPage,
          });
          if (getResponseMsg(locationListResponse) === "Success") {
            proccessResponse(locationListResponse);
          }

          setIsLoading(false);
        } else {
          setErrMsg(response.data.data);
          openErrorSB(true);
          setIsLoading(false);
        }

        break;
      // case "delete":
      //   handleCloseDeleteDialog();
      //   await httpRequest("customer-delete", null, { uuid: data.uuid });
      //   setMsg("Successfully Deleted Location");
      //   break;
      default:
        break;
    }

    setIsLoading(false);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton
            variant="gradient"
            color="info"
            onClick={newVendor}
            // disabled
          >
            new location
          </MDButton>
          {/* <MDBox display="flex">
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color={darkMode ? "white" : "dark"}
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </MDBox> */}
        </MDBox>
        <Card>
          <DataTable
            table={dataTableData}
            noEndBorder
            entriesPerPage={{ show: true }}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handleSearch={handleSearchKeyword}
            handleSort={handleSort}
            handleChangeEntries={handleChangeEntries}
            hasNextPage={nextPageLink !== null}
            hasPrevPage={prevPageLink !== null}
            totalCount={totalRows}
            title="Locations List"
            isLoading={isLoading}
            canRefresh
          />
        </Card>
      </MDBox>

      {showDeactivateDialog && (
        <DraggableDialog
          title="Deactivate Location"
          description={`Are you sure you want to deactivate ${userToBeDeactivated.name}?`}
          buttonDesc="Deactivate"
          openDialog={showDeactivateDialog}
          handleClose={handleCloseDeactiVateDialog}
          handleConfirm={() => handleConfirm("deactivate")}
          fullWidth={false}
        />
      )}
      {showDeleteDialog && (
        <DraggableDialog
          title="Delete Location"
          description={`Are you sure you want to delete ${userToBeDeactivated.name}?`}
          buttonDesc="Delete"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={() => handleConfirm("delete")}
          fullWidth={false}
        />
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />

      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />

      <Footer />
    </DashboardLayout>
  );
}
LocationSearch.propTypes = {};

const mapDispatchToProps = () => ({});

export default connect(null, mapDispatchToProps)(LocationSearch);
