/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import { Link, useLocation, useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import moment from "moment/moment";
import OrderMgr from "../OrderMgr";

const { convertToDecimal, setStorageItem, CUSTOMERS, capitalizeFirstLetter, formatDate } = OrderMgr;

function BasicInfo({ data, userDetail }) {
    const {
        identifier,
        date_ordered,
        customer,
        agent,
        billing,
        pickup_date,
        return_date,
        discount_rate,
        status,

        name,
        description,
        category,
        subcategory,
        item_type,
        warehouse,
        replacement_value,
        price,
        local_qty,
        global_available_qty,
        manufacturer,
        model_number,
        is_qc_required,
        // notes,
    } = data;

    const navigate = useNavigate();
    const location = useLocation();

    const changeTimeZone = (date, timeZone) => {
        if (typeof date === "string") {
            return new Date(
                new Date(date).toLocaleString("en-US", {
                    timeZone,
                })
            );
        }

        return new Date(
            date.toLocaleString("en-US", {
                timeZone,
            })
        );
    };

    const formatDateTime = (dateTime = "", format = "LLL") =>
        `${moment(
            changeTimeZone(dateTime || "", userDetail?.location?.timezone?.name || "Asia/Manila")
        ).format(format)} (${userDetail?.location?.timezone?.abbreviation || "UTC"})`;

    const capitalizeName = (nameToFormat = "") => {
        let formattedName = "";
        nameToFormat.split(" ").map((val) => {
            formattedName += `${capitalizeFirstLetter(val)} `;
            return val;
        });
        return formattedName;
    };

    const basicInfoDetails = [
        // { label: "Description", value: description },
        { label: "Rental Agent", value: capitalizeName(agent?.name || "") },
        { label: "Bill Days", value: billing?.bill_days || 0 },
        { label: "Default Discount Rate", value: `${(discount_rate || 0) * 100} %` },
        // { label: "", value: "" },
    ];

    const dateAndTime = [
        // {
        //   label: "Billing start date",
        //   value: billing?.start_date && formatDateTime(billing?.start_date),
        // },
        // { label: "Billing end date", value: billing?.end_date && formatDateTime(billing?.end_date) },
        // { label: "Prep/Pickup Date", value: pickup_date && formatDateTime(pickup_date) },
        // { label: "Return by date", value: return_date && formatDateTime(return_date) },

        {
            label: "Billing start date",
            value: billing?.start_date && formatDate(billing?.start_date, "LLL"),
        },
        {
            label: "Billing end date",
            value: billing?.end_date && formatDate(billing?.end_date, "LLL"),
        },
        { label: "Prep/Pickup Date", value: pickup_date && formatDate(pickup_date, "LLL") },
        { label: "Return by date", value: return_date && formatDate(return_date, "LLL") },
    ];

    const pricingDetails = [
        { label: "Daily", value: price?.daily },
        { label: "Weekly", value: price?.weekly },
        { label: "Monthly", value: price?.monthly },
    ];

    const OtherDetails = [
        { label: "Owned", value: local_qty?.owned },
        { label: "Available", value: local_qty?.available },
        { label: "On rent", value: local_qty?.on_rent },
        { label: "On repair", value: local_qty?.in_repair },
        { label: "Global Available Qty", value: global_available_qty },
    ];

    const renderHalfWidthItems = (items) =>
        items.map((item) => (
            <Grid item xs={4} sm={4} md={6}>
                <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        {item.label}: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{item.value}
                    </MDTypography>
                </MDBox>
            </Grid>
        ));

    const renderFullWidthItems = (items) =>
        items.map((item) => (
            <Grid item xs={12} md={6} xl={4}>
                <MDBox display="flex" py={1} pr={2}>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        {item.label}: &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{item.value}
                    </MDTypography>
                </MDBox>
            </Grid>
        ));

    const showCustomerDetail = (values) => {
        // console.log(values.customer.uuid);
        setStorageItem("current_customer_detail", values.customer);

        navigate(CUSTOMERS.DETAIL_ROUTE, {
            state: { from: location, uuid: values.customer.uuid },
            replace: true,
        });
    };

    return (
        <MDBox>
            <MDBox p={2} display="flex" justifyContent="space-between">
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        {identifier}
                    </MDTypography>
                    {date_ordered && (
                        <MDTypography variant="button" color="text" fontWeight="regular">
                            {/* {`Date ordered: ${date_ordered || "2022-10-19"} ${
              userDetail?.location?.timezone?.name
            } ${userDetail?.location?.timezone?.abbreviation}`} */}
                            {`Date ordered: ${moment(
                                changeTimeZone(
                                    date_ordered || "",
                                    userDetail?.location?.timezone?.name || "Asia/Manila"
                                )
                            ).format("LLL")} (${
                                userDetail?.location?.timezone?.abbreviation || "UTC"
                            })`}
                        </MDTypography>
                    )}
                </MDBox>
                <MDBox p={2} display="flex" justifyContent="flex-end">
                    <MDBox display="flex">
                        <MDTypography variant="h5">Order Status:&nbsp;</MDTypography>

                        <MDTypography
                            variant="h5"
                            fontWeight="medium"
                            color={status?.value === "canceled" ? "error" : "success"}
                        >
                            &nbsp;{status?.name}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>

            <Grid container spacing={1}>
                <Grid item xs={12} md={6} xl={12}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Order Info
                        </MDTypography>
                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={4} sm={4} md={12}>
                                <MDBox display="flex" py={1} pr={2}>
                                    <MDTypography
                                        variant="button"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Description: &nbsp;
                                    </MDTypography>
                                    <MDTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                    >
                                        &nbsp;{description}
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            <Grid item xs={4} sm={4} md={6}>
                                <MDBox display="flex" py={1} pr={2}>
                                    <MDTypography
                                        variant="button"
                                        fontWeight="bold"
                                        textTransform="capitalize"
                                    >
                                        Company Name: &nbsp;
                                    </MDTypography>
                                    {/* <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp; {customer?.name || ""}
                  </MDTypography> */}
                                    <MDTypography
                                        variant="button"
                                        color="text"
                                        onClick={() => {
                                            showCustomerDetail(data);
                                        }}
                                    >
                                        <MDTypography
                                            component={Link}
                                            to="#"
                                            variant="button"
                                            color="info"
                                            fontWeight="medium"
                                            textGradient
                                        >
                                            &nbsp; {customer?.company_name || ""}
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                            </Grid>
                            {renderHalfWidthItems(basicInfoDetails)}
                            {renderFullWidthItems(dateAndTime)}
                        </Grid>
                    </MDBox>
                </Grid>
            </Grid>
        </MDBox>
    );
}

BasicInfo.defaultProps = {
    data: {},
    userDetail: {},
};

BasicInfo.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    userDetail: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
    categoriesListRedux: state?.helper?.categoryList,
    userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(BasicInfo);
