/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import { CardMedia, Grid, Icon, IconButton } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";

// import "./styles.css";
import "react-dropzone-uploader/dist/styles.css";

import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDButton from "components/MDButton";

const { httpRequest, getResponseMsg, capitalizeFirstLetter, getStorageItem } = WarehouseMgr;

const acceptedMB = 21048576; // 21 MB

function Layout({ input, previews, submitButton, dropzoneProps, files, extra: { maxFiles } }) {
  return (
    <div>
      <div {...dropzoneProps}>
        {previews}
        {files.length < maxFiles && input}
      </div>

      <MDBox
        display="flex"
        justifyContent="flex-end"
        // onClick={() => console.log(files[0]?.meta?.type)}
      >
        {files.length > 0 &&
          (files[0]?.meta?.type === "image/png" ||
            files[0]?.meta?.type === "image/jpg" ||
            files[0]?.meta?.type === "image/jpeg" ||
            files[0]?.meta?.type === "") &&
          submitButton}
      </MDBox>
    </div>
  );
}

function Preview({ meta, fileWithMeta }) {
  const { name, previewUrl, size, type } = meta;
  const { remove } = fileWithMeta;
  return (
    <MDBox alignItems="center">
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={1}>
        <Grid item xs={12}>
          <CardMedia
            src={previewUrl}
            component="img"
            title={name}
            sx={{
              // margin: 0,
              boxShadow: ({ boxShadows: { md } }) => md,
              objectFit: "cover",
              objectPosition: "center",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              // width: "50%",
              width: "150px",
              height: "100px",
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <MDBox alignItems="center">
            <MDBox
            //  onClick={() => console.log(meta)}
            >
              <MDTypography variant="h6" fontWeight="medium" color="secondary">
                Filename: {name}
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium" color="secondary">
                Size: {(size / 1048576).toFixed(2)} MB
              </MDTypography>
            </MDBox>
            {size > acceptedMB && (
              <MDTypography variant="caption" fontWeight="medium" color="error">
                {`**File should not be more than 20MB** `}
              </MDTypography>
            )}
            {type !== "image/png" && type !== "image/jpg" && type !== "image/jpeg" && type !== "" && (
              <MDTypography
                variant="caption"
                fontWeight="medium"
                color="error"
                // onClick={() => console.log(files[0].file.size)}
              >
                **Only *.jpeg, *.png, *.jpg or *.heic file**
              </MDTypography>
            )}
          </MDBox>
        </Grid>
        <Grid item xs={12} mb={2}>
          <MDBox textAlign="center">
            <MDButton variant="gradient" color="error" size="small" onClick={remove}>
              remove file
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default function DragAndDrop({ attachmentType, uuid, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const [errorSB, setErrorSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");
  const [uploadDesc, setUploadDesc] = useState("");
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = async (files) => {
    setIsLoading(true);

    // console.log(uuid, { attachment: files, action: "upload" });

    // START HERE
    const body = new FormData();

    if (files) {
      files.map((f) => body.append("attachment", f.file));
    }
    // body.append("attachment_type", attachmentType);
    body.append("action", "upload");

    const response = await httpRequest("upload-attachment-qc", body, { uuid });

    if (getResponseMsg(response) === "Success") {
      // onClose("uploaded", response?.data?.data?.order_status);
      onClose("uploaded", "uploaded");
      setIsLoading(false);
    } else {
      openErrorSB(true);
      Object.entries(response.data).map((item) => {
        setErrMsg(
          `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${
            response.data?.action ||
            response.data?.attachment ||
            response?.data?.attachment_type ||
            response?.data?.file_type
          }`
        );
        return 0;
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setUploadDesc(`Drag file or click here to open file explorer. 
              (Only *.jpeg, *.png, *.jpg or *.heic, not more than 20MB)`);
    } else {
      setUploadDesc(`Click here to open file explorer.
      (Images less than 20MB)`);
    }
  }, [isMobile]);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox mb={1} ml={0.5} lineHeight={0}>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium" color="secondary">
              Upload
              {/* Upload {capitalizeFirstLetter(attachmentType).replaceAll("_", " ")} */}
            </MDTypography>
          </MDBox>
          <MDBox>
            <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
              <Icon fontSize="small">close</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        <Dropzone
          onSubmit={handleSubmit}
          maxFiles={1}
          multiple={false}
          maxSizeBytes={acceptedMB}
          // accept="image/jpeg,image/png,image/jpg,image/heic,image/heif, null"
          // accept="image/*"
          // accept={{
          //   "image/jpeg": [],
          //   "image/png": [],
          // }}
          LayoutComponent={Layout}
          PreviewComponent={Preview}
          inputContent={(files, extra) =>
            extra.reject
              ? "(Only *.jpeg, *.png, *.jpg or *.heic file, not more than 20MB)"
              : uploadDesc
          }
          // SubmitButtonComponent={(props) => (
          //   <MDButton mt={2} {...props} variant="gradient" color="info" size="small" type="submit">
          //     update file
          //   </MDButton>
          // )}
          styles={{
            dropzone: { minHeight: "9rem", width: "98%" },
            dropzoneReject: { borderColor: "red", backgroundColor: "#DAA" },
            inputLabel: (files, extra) =>
              extra.reject ? { color: "red" } : { textAlign: "center" },
          }}
        />
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Upload Document"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

DragAndDrop.defaultProps = {
  uuid: "",
  onClose: () => {},
};

DragAndDrop.propTypes = {
  uuid: PropTypes.string,
  onClose: PropTypes.func,
};
