/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import CustomerInfo from "layouts/Customers/new-user/components/CustomerInfo";
import CustomerNotes from "layouts/Customers/new-user/components/CustomerNotes";
import Address from "layouts/Customers/new-user/components/Address";
import Contact from "layouts/Customers/new-user/components/Contact";
import form from "layouts/Customers/new-user/schemas/form";
import initialValues from "layouts/Customers/new-user/schemas/initialValues";
import validations from "layouts/Customers/new-user/schemas/validations";
import CustomerMgr from "layouts/Customers/CustomerMgr";

import { RESPONSE_SUCCESS } from "services/context/response";

const { CUSTOMERS, setStorageItem, capitalizeFirstLetter, httpRequest, validateEmail } =
  CustomerMgr;

function CustomerNew({ handleClose }) {
  const { formId, formField } = form;

  const [errMsg, setErrMsg] = useState("Failed to Create Customer Profile");
  const successMsg = "Successfully Created Customer Profile";

  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const submitForm = async (values, actions) => {
    let response = null;

    response = await httpRequest("customer-create", values);

    if (response !== null) {
      openSuccessSB(true);
      setTimeout(() => {
        setLoading(false);
        actions.resetForm();
        setStorageItem("current_customer_detail", response.data.data);
        handleClose();
      }, 2000);
    } else {
      setLoading(false);
      openErrorSB(true);
    }

    actions.setSubmitting(false);
  };

  const emailExist = async (email) => {
    const response = await validateEmail(email);
    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      return false;
    }
    return true;
  };

  const handleSave = (err) => {
    if (JSON.stringify(err, null, 2) !== "{}") {
      openErrorSB(true);
      setErrMsg("Please fill in the required fields");
    }
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);

    if ((await emailExist(values.email)) === true) {
      setErrMsg("Email Already Exist");
      openErrorSB(true);
      setLoading(false);
    } else {
      const body = {
        first_name: capitalizeFirstLetter(values.first_name),
        last_name: capitalizeFirstLetter(values.last_name),
        email: values.email,
        company_name: values.company_name,
        payment_type: values.payment_type.value,
        classification: values.classification.value,
        default_discount: (values.default_discount / 100).toFixed(2),
        notes: values.notes,
        contact: {
          mobile: values.mobile,
          telephone: values.telephone,
          fax: values.fax,
          other: values.other,
        },
        address: {
          line_1: values.line_1,
          line_2: values.line_2,
          city: values.city,
          state: values.state,
          country: values.country.value,
          zip_code: values.zip_code,
        },
        tax_exempt: values?.taxExempt,
      };
      submitForm(body, actions);
    }
  };

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium" color="dark">
                  New Customer
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validations}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    {/* <Card> */}
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <MDBox ml={2}>
                          <CustomerInfo
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={6}>
                        <Address
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MDBox ml={2}>
                          <Contact
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={6}>
                        <CustomerNotes
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <MDBox display="flex" justifyContent="flex-end">
                      <MDBox mr={2}>
                        <MDButton
                          size="small"
                          variant="gradient"
                          color="error"
                          onClick={handleClose}
                        >
                          Cancel
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          disabled={isSubmitting}
                          size="small"
                          type="submit"
                          variant="gradient"
                          color="info"
                          onClick={() => handleSave(errors)}
                        >
                          save
                        </MDButton>
                      </MDBox>
                    </MDBox>
                    {/* </Card> */}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Customer Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Customer Creation"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      {/* <Footer /> */}
    </MDBox>
  );
}

CustomerNew.defaultProps = {};

CustomerNew.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
});

export default connect(mapStateToProps, null)(CustomerNew);
