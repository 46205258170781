/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useMemo } from "react";
import { connect, useSelector } from "react-redux";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import FormField from "layouts/admin/ManageUsers/new-user/components/FormField";
import UsersMgr from "layouts/admin/ManageUsers/UsersMgr";
import { CircularProgress, Icon, Switch, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";
import CustomModal from "components/CustomModal";
import MDSnackbar from "components/MDSnackbar";
import ResetUserPassword from "./ResetUserPassword";

const { getStorageItem, httpRequest, getResponseMsg, hasPermission } = UsersMgr;

function OtherInfo({ formData, roleError, passwordError, userDetailRedux }) {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const locationList = JSON.parse(getStorageItem("locations_list"));
    const userDetail = JSON.parse(getStorageItem("current_user_detail"));

    const { formField, values } = formData;

    const { username, password, role } = formField;
    const { username: usernameV, password: passwordV, role: roleV, group: groupV } = values;

    const [usernameValue, setUsernameValue] = useState(usernameV);
    const [passwordValue, setPasswordValue] = useState(passwordV);
    const [testData, setTestData] = useState([
        { name: "jason", checked: true },
        { name: "illiad", checked: false },
        { name: "antoine", checked: false },
    ]);

    const [roleValue, setRoleValue] = useState(groupV);
    const [customerPermissionList, setCustomerPermissionList] = useState([]);
    const [inventoryPermissionList, setInventoryPermissionList] = useState([]);
    const [ordersPermissionList, setOrdersPermissionList] = useState([]);
    const [paymentsPermissionList, setPaymentsPermissionList] = useState([]);
    const [usersPermissionList, setUsersPermissionList] = useState([]);
    const [allPermissionList, setAllPermissionsList] = useState([]);
    const [loadingPermissions, setLoadingPermissions] = useState(false);
    const [showResetPasswordModal, setShowNotifModal] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");
    const [errMsg, setErrMsg] = useState("");
    const [notifTitle, setNotifTitle] = useState("");

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const openNotifModal = () => setShowNotifModal(true);
    const closeResetUserPassword = (action = "") => {
        setNotifTitle("Reset User Password");
        switch (action) {
            case "success":
                setSuccessMsg("User password reset successfully.");
                openSuccessSB();
                break;
            case "error":
                setErrMsg("User password reset failed.");
                openErrorSB();
                break;
            default:
                break;
        }
        setShowNotifModal(false);
    };
    // const [passwordValue, setInputZip] = useState(zipV);

    const roleList = [
        { name: "Admin", value: "admin" },
        { name: "Agent", value: "agent" },
        { name: "Manager", value: "manager" },
        { name: "Operator", value: "operator" },
    ];
    const handleToggleSwitch = (data, event, i) => {
        const tmpChecked = [];
        data.map((c, index) => {
            // debugger;
            if (event.target.name === c.name) {
                tmpChecked.push({ ...c, has_permission: event.target.checked });
            } else {
                tmpChecked.push({ ...c });
            }
            return true;
        });

        return tmpChecked;
    };

    const handleToggleSwitch2 = (data, event, i) => {
        const tmpChecked = [];
        let tmpAllPermissionList = allPermissionList;
        allPermissionList[i].value.map((c, index) => {
            // debugger;
            if (event.target.name === c.name) {
                tmpChecked.push({ ...c, has_permission: event.target.checked });
            } else {
                tmpChecked.push({ ...c });
            }
            return true;
        });

        allPermissionList[i].value = tmpChecked;
        tmpAllPermissionList = allPermissionList;

        return tmpAllPermissionList;
    };

    const renderInventoryPermissions = (items = []) =>
        items.map((item, index) => {
            const disabled =
                item?.is_role_default_permission === true ||
                !hasPermission("set_role_and_permission", permissions?.users);
            return (
                <Grid container xs={12} md={12} xl={4}>
                    <MDBox display="flex" alignItems="center" mb={0.5}>
                        {disabled && (
                            <Tooltip
                                title="Role default permission cannot be change"
                                placement="top"
                            >
                                <Icon>error</Icon>
                            </Tooltip>
                        )}
                        <MDBox mt={0.5}>
                            <Switch
                                checked={item?.has_permission}
                                name={item?.name}
                                onChange={(e) => {
                                    const permission = handleToggleSwitch(items, e);
                                    setInventoryPermissionList(permission);
                                    values.inventoryPermissions = permission;
                                }}
                                disabled={disabled}
                            />
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                                {item?.name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
            );
        });

    const renderCustomerPermissionItems = (items = []) =>
        items.map((item, index) => {
            const disabled =
                item?.is_role_default_permission === true ||
                !hasPermission("set_role_and_permission", permissions?.users);
            return (
                <Grid container xs={12} md={12} xl={4}>
                    <MDBox display="flex" alignItems="center" mb={0.5}>
                        {disabled && (
                            <Tooltip
                                title="Role default permission cannot be change"
                                placement="top"
                            >
                                <Icon>error</Icon>
                            </Tooltip>
                        )}
                        <MDBox mt={0.5}>
                            <Switch
                                checked={item?.has_permission}
                                name={item?.name}
                                onChange={(e) => {
                                    const permission = handleToggleSwitch(items, e);
                                    setCustomerPermissionList(permission);
                                    values.customerPermissions = permission;
                                }}
                                disabled={disabled}
                            />
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                                {item?.name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
            );
        });

    const renderOrdersPermissionItems = (items = []) =>
        items.map((item, index) => {
            const disabled =
                item?.is_role_default_permission === true ||
                !hasPermission("set_role_and_permission", permissions?.users);

            return (
                <Grid container xs={12} md={12} xl={4}>
                    <MDBox display="flex" alignItems="center" mb={0.5}>
                        {disabled && (
                            <Tooltip
                                title="Role default permission cannot be change"
                                placement="top"
                            >
                                <Icon>error</Icon>
                            </Tooltip>
                        )}
                        <MDBox mt={0.5}>
                            <Switch
                                checked={item?.has_permission}
                                name={item?.name}
                                onChange={(e) => {
                                    const permission = handleToggleSwitch(items, e);
                                    setOrdersPermissionList(permission);
                                    values.ordersPermissions = permission;
                                }}
                                disabled={disabled}
                            />
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                                {item?.name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
            );
        });

    const renderPaymentsPermissionItems = (items = []) =>
        items.map((item, index) => {
            const disabled =
                item?.is_role_default_permission === true ||
                !hasPermission("set_role_and_permission", permissions?.users);

            return (
                <Grid container xs={12} md={12} xl={6}>
                    <MDBox display="flex" alignItems="center" mb={0.5}>
                        {disabled && (
                            <Tooltip
                                title="Role default permission cannot be change"
                                placement="top"
                            >
                                <Icon>error</Icon>
                            </Tooltip>
                        )}
                        <MDBox mt={0.5}>
                            <Switch
                                checked={item?.has_permission}
                                name={item?.name}
                                onChange={(e) => {
                                    const permission = handleToggleSwitch(items, e);
                                    setPaymentsPermissionList(permission);
                                    values.paymentsPermissions = permission;
                                }}
                                disabled={disabled}
                            />
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                                {item?.name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
            );
        });

    const renderUsersPermissionItems = (items = []) =>
        items.map((item, index) => {
            const disabled =
                item?.is_role_default_permission === true ||
                !hasPermission("set_role_and_permission", permissions?.users);

            return (
                <Grid container xs={12} md={12} xl={6}>
                    <MDBox display="flex" alignItems="center" mb={0.5}>
                        {disabled && (
                            <Tooltip
                                title="Role default permission cannot be change"
                                placement="top"
                            >
                                <Icon>error</Icon>
                            </Tooltip>
                        )}
                        <MDBox mt={0.5}>
                            <Switch
                                checked={item?.has_permission}
                                name={item?.name}
                                onChange={(e) => {
                                    const permission = handleToggleSwitch(items, e);
                                    setUsersPermissionList(permission);
                                    values.usersPermission = permission;
                                }}
                                disabled={disabled}
                            />
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                                {item?.name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
            );
        });

    const renderAllPermissions = (items = [], i = 0) =>
        items.map((item, index) => {
            const disabled = item?.is_role_default_permission === true;

            return (
                <Grid container xs={12} md={12} xl={4}>
                    <MDBox display="flex" alignItems="center" mb={0.5}>
                        {disabled && (
                            <Tooltip
                                title="Role default permission cannot be change"
                                placement="top"
                            >
                                <Icon>error</Icon>
                            </Tooltip>
                        )}
                        <MDBox mt={0.5}>
                            <Switch
                                checked={item?.has_permission}
                                name={item?.name}
                                onChange={(e) => {
                                    // console.log(i);
                                    const permission = handleToggleSwitch2(items, e, i);
                                    setAllPermissionsList(permission);
                                    values.usersPermission = permission;
                                }}
                                disabled={disabled}
                            />
                        </MDBox>
                        <MDBox>
                            <MDTypography variant="button" fontWeight="regular" color="text">
                                {item?.name}
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                </Grid>
            );
        });

    const renderHeaderPermissions = (items = [], i = 0) =>
        allPermissionList.map((item, index) => {
            const disabled = item?.is_role_default_permission === true;

            return (
                <Grid item xs={12} md={6} xl={12}>
                    <MDBox py={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            {item.name} Permissions
                        </MDTypography>
                        <Grid container>{renderAllPermissions(item.value, index)}</Grid>
                    </MDBox>
                </Grid>
            );
        });

    const handleChangeRole = async (val = {}) => {
        const tmpPermissions = [];
        setLoadingPermissions(true);
        if (val !== null) {
            setRoleValue(val);
            values.role = val;

            const permissionListResponse = await httpRequest("get-permission-list-by-role", null, {
                role: val?.value,
            });

            if (getResponseMsg(permissionListResponse) === "Success") {
                Object.entries(permissionListResponse?.data?.data).map((item, key, value) => {
                    // responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
                    // console.log(value[0]);
                    tmpPermissions.push({ name: item[0], value: item[1] });
                    // console.log({ [item[0]]: item[1] });
                    return 0;
                });

                // console.log(tmpPermissions);
                // setAllPermissionsList(tmpPermissions);
                // const [allPermissionList, setAllPermissionsList] = useState([]);

                setCustomerPermissionList(permissionListResponse?.data?.data?.customers);
                values.customerPermissions = permissionListResponse?.data?.data?.customers;

                setInventoryPermissionList(permissionListResponse?.data?.data?.inventory);
                values.inventoryPermissions = permissionListResponse?.data?.data?.inventory;

                setOrdersPermissionList(permissionListResponse?.data?.data?.orders);
                values.ordersPermissions = permissionListResponse?.data?.data?.orders;

                setPaymentsPermissionList(permissionListResponse?.data?.data?.payments);
                values.paymentsPermissions = permissionListResponse?.data?.data?.payments;

                setUsersPermissionList(permissionListResponse?.data?.data?.users);
                values.usersPermission = permissionListResponse?.data?.data?.users;
            }
        }
        setLoadingPermissions(false);
    };

    useEffect(() => {
        if (userDetail?.pageState === "NEW") {
            setUsernameValue("");
            values.username = "";

            setPasswordValue("");
            values.password = "";

            setRoleValue(null);
            values.role = null;
        } else {
            setCustomerPermissionList(values.permissions.customers);
            values.customerPermissions = values.permissions.customers;

            setInventoryPermissionList(values.permissions.inventory);
            values.inventoryPermissions = values.permissions.inventory;

            setOrdersPermissionList(values.permissions.orders);
            values.ordersPermissions = values.permissions.orders;

            setPaymentsPermissionList(values.permissions.payments);
            values.paymentsPermissions = values.permissions.payments;

            setUsersPermissionList(values.permissions.users);
            values.usersPermission = values.permissions.users;
        }
    }, []);

    return (
        <MDBox mx={2} my={0} px={1}>
            <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                <MDTypography variant="h6" fontWeight="bold">
                    Account details
                </MDTypography>
                {/* <MDTypography variant="caption" fontWeight="regular" color="warning">
          * = Required Fields
        </MDTypography> */}
            </MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <FormField
                            type={username.type}
                            label={username.label}
                            name={username.name}
                            value={usernameValue}
                            placeholder={username.placeholder}
                            onChange={(e) => {
                                setUsernameValue(e.target.value);
                                values.username = e.target.value;
                            }}
                            // error={errors.username && touched.username}
                            // success={locationV.length > 0 && !errors.username}
                        />
                    </Grid>
                    {userDetail?.pageState === "NEW" && (
                        <Grid item xs={4}>
                            <FormField
                                type={password.type}
                                label={password.label}
                                name={password.name}
                                value={passwordValue}
                                placeholder={password.placeholder}
                                onChange={(e) => {
                                    setPasswordValue(e.target.value);
                                    values.password = e.target.value;
                                }}
                            />
                            {passwordError && passwordValue === "" && (
                                <MDBox mt={0.75}>
                                    <MDTypography
                                        component="div"
                                        variant="caption"
                                        color="error"
                                        fontWeight="regular"
                                    >
                                        {password.errorMsg}
                                    </MDTypography>
                                </MDBox>
                            )}
                        </Grid>
                    )}
                    <Grid item xs={4}>
                        <Autocomplete
                            disablePortal
                            options={roleList || []}
                            value={roleValue}
                            name={role.name}
                            isOptionEqualToValue={(option, value) =>
                                value !== "" ? option.name === value.name : option.name
                            }
                            getOptionLabel={(option) => (option ? option.name : "")}
                            renderInput={(params) => (
                                <MDInput {...params} variant="standard" label={role.label} />
                            )}
                            onChange={(event, newValue) => {
                                // console.log(newValue);
                                handleChangeRole(newValue);
                            }}
                        />
                        {roleError && roleValue === null && (
                            <MDBox mt={0.75}>
                                <MDTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                    fontWeight="regular"
                                >
                                    {role.errorMsg}
                                </MDTypography>
                            </MDBox>
                        )}
                    </Grid>

                    {userDetail?.pageState === "EDIT" && userDetailRedux?.role?.value === "admin" && (
                        <Grid item xs={4} mt={1}>
                            <MDButton
                                fullWidth
                                size="small"
                                variant="gradient"
                                color="secondary"
                                onClick={openNotifModal}
                            >
                                reset password
                            </MDButton>
                        </Grid>
                    )}
                </Grid>
                {loadingPermissions ? (
                    <MDBox direction="column" justifyContent="center" alignItems="center">
                        <MDBox display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress />
                        </MDBox>
                        <MDBox display="flex" justifyContent="center" alignItems="center">
                            <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                            >
                                Loading permissions...
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                ) : (
                    roleValue !== null && (
                        <Grid container spacing={2}>
                            <MDBox pt={2}>
                                <Grid container spacing={1}>
                                    {/* {renderHeaderPermissions()} */}

                                    <Grid item xs={12} md={6} xl={12}>
                                        <MDBox py={1} pl={2}>
                                            <MDTypography variant="h6" color="info">
                                                Inventory Permissions
                                            </MDTypography>
                                            <Grid container>
                                                {renderInventoryPermissions(
                                                    inventoryPermissionList
                                                )}
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={12}>
                                        <MDBox py={1} pl={2}>
                                            <MDTypography variant="h6" color="info">
                                                Customer Permissions
                                            </MDTypography>
                                            <Grid container>
                                                {renderCustomerPermissionItems(
                                                    customerPermissionList
                                                )}
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={12}>
                                        <MDBox pb={1} pl={2}>
                                            <MDTypography variant="h6" color="info">
                                                Orders Permissions
                                            </MDTypography>
                                            <Grid container>
                                                {renderOrdersPermissionItems(ordersPermissionList)}
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <MDBox pb={1} pl={2}>
                                            <MDTypography variant="h6" color="info">
                                                Payments Permissions
                                            </MDTypography>
                                            <Grid container>
                                                {renderPaymentsPermissionItems(
                                                    paymentsPermissionList
                                                )}
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={6}>
                                        <MDBox pb={1} pl={2}>
                                            <MDTypography variant="h6" color="info">
                                                Users Permissions
                                            </MDTypography>
                                            <Grid container>
                                                {renderUsersPermissionItems(usersPermissionList)}
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                        </Grid>
                    )
                )}
            </MDBox>
            {showResetPasswordModal && (
                <CustomModal
                    showModal={showResetPasswordModal}
                    handleHideModal={closeResetUserPassword}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "auto",
                        width: "auto",
                        // border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "5px",
                        p: 4,
                        overflow: "auto",
                    }}
                >
                    <ResetUserPassword onClose={closeResetUserPassword} />

                    {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
                </CustomModal>
            )}
            <MDSnackbar
                color="error"
                icon="warning"
                title={notifTitle}
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />
            <MDSnackbar
                color="success"
                icon="check"
                title={notifTitle}
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />
        </MDBox>
    );
}

OtherInfo.defaultProps = {
    userDetailRedux: {},
};

// typechecking props for OtherInfo
OtherInfo.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    roleError: PropTypes.bool.isRequired,
    passwordError: PropTypes.bool.isRequired,
    userDetailRedux: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
    userDetailRedux: state.authentication.userDetail,
});

export default connect(mapStateToProps, null)(OtherInfo);
