import { useState } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, FormControlLabel } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import FormField from "layouts/Customers/new-user/components/FormField";

// import utils from "services/utils";

// const { capitalizeFirstLetter } = utils;

function CustomerInfo({ formData }) {
    const { formField, values, errors, touched } = formData;

    const { company, classification, isIndividual, defaultDiscount, paymentType, notes } =
        formField;

    const {
        company_name: companyV,
        isIndividual: isIndividualV,
        taxExempt: taxExemptV,
        classification: classificationV,
        default_discount: defaultDiscountV,
        payment_type: paymentTypeV,
        notes: notesV,
    } = values;

    const [custClassification, setCustClassification] = useState(classificationV);
    const [custPaymentType, setCustPaymentType] = useState(paymentTypeV);
    const [withCompany, setWithCompany] = useState(isIndividualV || false);
    const [taxExemptCb, setTaxExemptCb] = useState(taxExemptV || false);

    const classificationOptions = [
        { name: "Production Company", value: "production_company" },
        { name: "Sub-rental", value: "subrental" },
        { name: "End User", value: "end_user" },
        { name: "Internal", value: "internal" },
        { name: "Walk-In", value: "walk_in" },
    ];

    const classificationOptionsIndividual = [
        { name: "Sub-rental", value: "subrental" },
        { name: "End User", value: "end_user" },
        { name: "Internal", value: "internal" },
        { name: "Walk-In", value: "walk_in" },
    ];

    const paymentTypesOptions = [
        { name: "Prepay", value: "prepay" },
        { name: "Net 30", value: "net30" },
    ];

    const handleChangeClassification = (e, val) => {
        if (val) {
            setCustClassification(val);
            values.classification = val;
        }
    };

    const handleChangePaymentType = (e, val) => {
        if (val) {
            setCustPaymentType(val);
            values.payment_type = val;
        }
    };

    const handleChangeWithCompanyCB = (e) => {
        if (e.target.checked === true) {
            values.company_name = "N/A";
            values.classification = { name: "End User", value: "end_user" };
            setCustClassification({ name: "End User", value: "end_user" });
        } else {
            values.classification = { name: "Production Company", value: "production_company" };
            setCustClassification({ name: "Production Company", value: "production_company" });
            values.company_name = "";
        }
        values.isIndividual = e.target.checked;
        setWithCompany(e.target.checked);
    };

    const handleChangeTaxExemptCb = (e) => {
        values.taxExempt = e.target.checked;
        setTaxExemptCb(e.target.checked);
    };

    // const handleOnBlur = (e) => {
    //   switch (e.target.name) {
    //     case firstName.name:
    //       values.first_name = values.first_name || capitalizeFirstLetter(values.first_name);
    //       break;

    //     case lastName.name:
    //       values.last_name = values.last_name || capitalizeFirstLetter(values.last_name);
    //       break;

    //     default:
    //       break;
    //   }
    // };

    return (
        <MDBox>
            <MDBox lineHeight={0}>
                <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
                    <MDTypography variant="h6">Basic Info</MDTypography>
                </MDBox>
            </MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 12, sm: 12, md: 12 }}
                        >
                            <Grid item xs={7} sm={7} md={7}>
                                {!withCompany ? (
                                    <MDBox>
                                        <FormField
                                            type={company.type}
                                            label={company.label}
                                            name={company.name}
                                            value={companyV}
                                            placeholder={company.placeholder}
                                            disabled={isIndividualV}
                                            error={errors.company && touched.company}
                                        />
                                    </MDBox>
                                ) : (
                                    <MDBox mt={1.5}>
                                        <MDTypography variant="button" color="text">
                                            Individual
                                        </MDTypography>
                                    </MDBox>
                                )}
                            </Grid>
                            <Grid item xs={5} sm={5} md={5} mt={2}>
                                <FormControlLabel
                                    label={
                                        <MDTypography variant="caption" color="text">
                                            {isIndividual.label}
                                        </MDTypography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={withCompany}
                                            onChange={handleChangeWithCompanyCB}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Autocomplete
                            options={paymentTypesOptions}
                            value={custPaymentType}
                            name={paymentType.name}
                            isOptionEqualToValue={(option, value) =>
                                value !== "" ? option.value === value.value : option.value
                            }
                            getOptionLabel={(option) => (option ? option.name : "")}
                            renderInput={(params) => (
                                <MDInput {...params} variant="standard" label={paymentType.label} />
                            )}
                            onChange={(event, newValue) => {
                                handleChangePaymentType(event, newValue);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={defaultDiscount.type}
                            label={defaultDiscount.label}
                            name={defaultDiscount.name}
                            value={defaultDiscountV}
                            placeholder={defaultDiscount.placeholder}
                            error={errors.defaultDiscount && touched.defaultDiscount}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <MDBox>
                            <Autocomplete
                                options={
                                    isIndividualV
                                        ? classificationOptionsIndividual
                                        : classificationOptions
                                }
                                value={custClassification}
                                isOptionEqualToValue={(option, value) =>
                                    value !== "" ? option.name === value.name : option.name
                                }
                                getOptionLabel={(option) => (option ? option.name : "")}
                                renderInput={(params) => (
                                    <MDInput
                                        {...params}
                                        name={classification.name}
                                        variant="standard"
                                        label={classification.label}
                                    />
                                )}
                                onChange={(event, newValue) => {
                                    handleChangeClassification(event, newValue);
                                }}
                            />
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormControlLabel
                            label={
                                <MDTypography variant="caption" color="text">
                                    Tax Exempt
                                </MDTypography>
                            }
                            control={
                                <Checkbox
                                    checked={taxExemptCb}
                                    onChange={handleChangeTaxExemptCb}
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormField
                            type={notes.type}
                            label={notes.label}
                            name={notes.name}
                            value={notesV}
                            placeholder={notes.placeholder}
                            multiline
                            rows={4}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

// typechecking props for UserInfo
CustomerInfo.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CustomerInfo;
