/* eslint-disable no-unused-expressions */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";

import App from "App";

// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";

// Auth provider
import { AxiosInterceptor } from "services/https/axios";
import { ReduxProvider } from "services/providers/ReduxProvider";

// process.env.NODE_ENV === "production" &&
// Sentry.init({
//   dsn: "https://8afd05a5342c4460895e833b1d636ca2@o1365522.ingest.sentry.io/4504200539275264",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   environment: process.env.NODE_ENV,
// });

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <ReduxProvider>
        <AxiosInterceptor>
          {/* <React.StrictMode> */}
          <App />
          {/* </React.StrictMode> */}
        </AxiosInterceptor>
      </ReduxProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
