/* eslint-disable no-unused-vars */
import * as Yup from "yup";
import checkout from "layouts/Order/new-order/schemas/form";
import OrderMgr from "layouts/Order/OrderMgr";

const { getDateToday, getCurrentTime, addDate } = OrderMgr;

const {
  formField: {
    customerName,
    pickupDate,
    pickupTime,
    billingStartDate,
    billingStartTime,
    billingEndDate,
    billingEndTime,
    returnByDate,
    returnByTime,
    orderDescription,
    billDays,
    defaultDiscount,
    rentalAgentAssigned,
    depositAmount,
    totalCostOrder,
    taxRate,
    discountTotal,
    invoiceTotal,
    collections,
  },
} = checkout;

const orderValidation = Yup.object().shape({
  [customerName.name]: Yup.string().required(customerName.errorMsg),
  // [pickupDate.name]: Yup.string().required(pickupDate.errorMsg),
  // [pickupDate.name]: Yup.date().min(
  //   getDateToday(),
  //   `Min. pick-up date is ${getDateToday("DD/MM/YYYY")}`
  // ),
  // [pickupTime.name]: Yup.string()
  //   .test("minTime", `Min. pick-up time is ${getCurrentTime()}`, (value) => {
  //     console.log(value, "here", [pickupDate.name]);
  //     return value > getCurrentTime();
  //   })
  //   .required(pickupTime.errorMsg),
  [billingStartDate.name]: Yup.string().required(billingStartDate.errorMsg),
  // .min(getDateToday(), `Min start date is ${getDateToday("DD/MM/YYYY")}`)
  [billingStartTime.name]: Yup.string().nullable().required(billingStartTime.errorMsg),
  [billingEndDate.name]: Yup.date()
    // .min(getDateToday(), `Min End date is ${getDateToday("DD/MM/YYYY")}`)
    .required(billingEndDate.errorMsg),
  [billingEndTime.name]: Yup.string().nullable().required(billingEndTime.errorMsg),
  [returnByDate.name]: Yup.date()
    // .min(getDateToday(), `Min Return date is ${getDateToday("DD/MM/YYYY")}`)
    .required(returnByDate.errorMsg),
  [returnByTime.name]: Yup.string().nullable().required(returnByTime.errorMsg),
  [orderDescription.name]: Yup.string().required(orderDescription.errorMsg),
  // [returnByTime.name]: Yup.string().required(returnByTime.errorMsg),
  // [orderDescription.name]: Yup.string().required(orderDescription.errorMsg),
  // [billDays.name]: Yup.string().required(billDays.errorMsg),
  // [defaultDiscount.name]: Yup.string().required(defaultDiscount.errorMsg),
  // [rentalAgentAssigned.name]: Yup.string().required(rentalAgentAssigned.errorMsg),
  // [depositAmount.name]: Yup.string().required(depositAmount.errorMsg),
  // [billingStartTime.name]: Yup.string().required(billingStartTime.errorMsg),
  // [taxRate.name]: Yup.string().required(taxRate.errorMsg),
  // [discountTotal.name]: Yup.string().required(discountTotal.errorMsg),
  // [invoiceTotal.name]: Yup.string().required(invoiceTotal.errorMsg),
  // [collections.name]: Yup.string().required(collections.errorMsg),
  // [totalCostOrder.name]: Yup.string().required(totalCostOrder.errorMsg),
});

const validations = { orderValidation };

export default validations;
