const style = {
  fullXs: 12,
  halfXs: 6,
  fullSm: 12,
  halfSm: 6,
  spacing: 3,
  padding: {
    left: 3,
    right: 3,
    top: 3,
    bottom: 3,
    x: 3,
    y: 3,
  },

  //   values for display
  flex: "flex",

  // Values for justifyContent
  flexEnd: "flex-end",
  spaceBetween: "space-between",

  // Values for typography variant
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  subtitle1: "subtitle1",
  subtitle2: "subtitle2",
  body1: "body1",
  body2: "body2",
  button: "button",
  caption: "caption",
  overline: "overline",
  inherit: "inherit",

  GRID_ITEM: { full_xs: 12, half_xs: 6, spacing: 1 },
  MDBOX: { xs: 6, spacing: 1 },
  MANAGER: { title: "manager", level: 999 },
  AGENT: { title: "agent", level: 10 },
  OPERATOR: { title: "operator", level: 9 },
  CUSTOMER: { title: "customer", level: 1 },
  SUBVENDOR: { title: "subvendor", level: 7 },
  CONSIGNER: { title: "consigner", level: 6 },
};

export default style;
