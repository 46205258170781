import {
  SET_COUNTRY_LIST,
  SET_LOCATION_LIST,
  SET_WAREHOUSE_LIST,
  RESET_COMMON_LIST,
  SET_CATEGORY_LIST,
  SET_CONSIGNER_LIST,
} from "./types";

const setCountryList = (params) => ({ type: SET_COUNTRY_LIST, payload: params });
const setLocationList = (params) => ({ type: SET_LOCATION_LIST, payload: params });
const setWarehouseList = (params) => ({ type: SET_WAREHOUSE_LIST, payload: params });
const resetCommonList = () => ({ type: RESET_COMMON_LIST });
const setCategoryReduxList = (params) => ({ type: SET_CATEGORY_LIST, payload: params });
const setConsignerList = (params) => ({ type: SET_CONSIGNER_LIST, payload: params });

const helperActions = {
  setCountryList,
  setLocationList,
  setWarehouseList,
  resetCommonList,
  setCategoryReduxList,
  setConsignerList,
};

export default helperActions;
