/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Modal } from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import BasicInfo from "layouts/Customers/customer-detail/BasicInfo";
import AttachmentsInfo from "layouts/Customers/customer-detail/AttachmentsInfo";
import DeleteCustomer from "layouts/Customers/customer-detail/DeleteCustomer";
import MDSnackbar from "components/MDSnackbar";
import CustomerOrders from "layouts/Customers/customer-detail/CustomerOrders";
import { useMaterialUIController } from "context";
import DraggableDialog from "components/DragableDialog";

import ComingSoon from "assets/images/reelmen/ComingSoon.jpg";
import Email from "components/Email";
import CustomerMgr from "layouts/Customers/CustomerMgr";

const { CUSTOMERS, getStorageItem, httpRequest, hasPermission, setStorageItem, formatDate } =
    CustomerMgr;

function CustomerDetail() {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const [custData, setCustomerDetail] = useState({});
    const [unpaidContractList, setUnpaidContractList] = useState([]);

    const [errMsg, setErrMsg] = useState("Failed");
    const [successMsg, setSuccessMsg] = useState("Sucess");
    const [notifTitle, setNotifTitle] = useState("Customer Creation");

    const [showEmailModal, setShowEmailModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [showComingSoonDialog, setShowComingSoonDialog] = useState(false);

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const handleCloseComingSoonDialog = () => setShowComingSoonDialog(false);

    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from?.pathname || CUSTOMERS.LIST_ROUTE;
    const stateKeyword = location.state?.searchKeyword || "";
    const { uuid } = JSON.parse(getStorageItem("current_customer_detail")) || { uuid: "" };
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        height: "auto",
        width: "29rem",
        border: "2px solid #000",
        boxShadow: 24,
        borderRadius: "20px",
        p: 4,
    };

    const emailTypeOptions = [
        {
            name: "Missing Paperworks",
            disabled: custData?.has_missing_paperworks === false || false,
        },
        { name: "Invoice", disabled: false },
    ];

    const processRows = (data = []) => {
        const unpaidList = [];

        data.map((unpaid) =>
            unpaidList.push({
                ...unpaid,
                date: formatDate(unpaid?.date, "ll") || "----/--/--",
                file_name: unpaid.file_name,
                file_url: unpaid.file_url,
            })
        );

        return unpaidList;
    };

    const loadDetails = async () => {
        setLoading(true);

        const responseDetails = await httpRequest("customer-detail", null, { uuid });

        setCustomerDetail(responseDetails?.data?.data);
        setStorageItem("current_customer_detail", responseDetails?.data?.data);

        const responseUnpaidContracts = await httpRequest("customer-unpaid-contracts", null, {
            uuid,
        });

        setUnpaidContractList(processRows(responseUnpaidContracts?.data?.data?.results));

        setLoading(false);

        return 0;
    };

    useEffect(() => {
        setNotifTitle("Customer Details");
        if (uuid === undefined) {
            navigate(from, { replace: true });
        }
        loadDetails();
    }, []);

    const handleClose = () => {
        if (from === CUSTOMERS.CREATE_ROUTE) {
            navigate(from, {
                replace: true,
                state: {
                    from: location,
                    searchKeyword: stateKeyword,
                },
            });
        } else {
            navigate(from, { replace: true, state: { searchKeyword: stateKeyword } });
        }
    };

    const editCustomer = () => {
        navigate(CUSTOMERS.EDIT_ROUTE, {
            state: {
                from: location,
                pageState: "EDIT",
                searchKeyword: stateKeyword,
                custData: {
                    uuid: custData?.uuid,
                    email: custData?.email,
                    company_name: custData?.company_name || "",
                    isIndividual: custData?.company_name === "N/A",
                    payment_type: custData?.payment_type,
                    classification: custData?.classification,
                    default_discount: (custData?.default_discount || 0) * 100,
                    notes: custData?.notes,

                    line_1: custData?.address.line_1,
                    line_2: custData?.address.line_2,
                    city: custData?.address.city,
                    state: custData?.address.state,
                    country: custData?.address.country,
                    zip_code: custData?.address.zip_code,
                    taxExempt: custData?.tax_exempt || false,
                },
            },

            // replace: true,
        });
    };

    const handleSendEmail = async () => {
        setShowEmailModal(true);
    };

    const handleCloseEmailModal = (type = "close", status) => {
        setNotifTitle("Email");
        switch (type) {
            case emailTypeOptions[0]:
            case emailTypeOptions[1]:
                if (status === true) {
                    setSuccessMsg(`${type.name} Sent Successfully on Customer's email`);
                    openSuccessSB(true);
                } else {
                    setErrMsg(`Failed to send ${type.name} email`);
                    openErrorSB(true);
                }
                break;
            default:
                setShowEmailModal(false);
                break;
        }
        setShowEmailModal(false);
    };

    return (
        <DashboardLayout>
            {isLoading && <SimpleBackdrop />}
            <DashboardNavbar />
            <MDBox>
                <MDBox mb={1}>
                    <MDBox display="flex" justifyContent="flex-end">
                        <MDButton
                            size="small"
                            variant="outlined"
                            color="info"
                            onClick={() => handleSendEmail()}
                            sx={{ marginRight: 1 }}
                            disabled={!hasPermission("send_email", permissions?.customers)}
                        >
                            Email
                        </MDButton>
                        {/* <MDButton
              size="small"
              variant="outlined"
              color="info"
              onClick={handleOpenComingSoonDialog}
              sx={{ marginRight: 1 }}
            >
              New Order
            </MDButton> */}

                        <MDBox>
                            <MDButton
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={editCustomer}
                                sx={{ marginRight: 1 }}
                                disabled={!hasPermission("change_customer", permissions?.customers)}
                            >
                                Edit
                            </MDButton>
                        </MDBox>
                        <MDButton
                            variant="outlined"
                            color="error"
                            size="small"
                            onClick={handleClose}
                        >
                            back
                        </MDButton>
                    </MDBox>
                </MDBox>
                <MDBox>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <BasicInfo data={custData} editCustomer={editCustomer} />
                        </Grid>
                        <Grid item xs={12}>
                            <AttachmentsInfo uuid={uuid} loadDetails={loadDetails} />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomerOrders unpaidList={unpaidContractList} />
                        </Grid>
                        <Grid item xs={12}>
                            <DeleteCustomer data={custData} />
                        </Grid>
                    </Grid>
                </MDBox>
            </MDBox>
            <MDSnackbar
                color="error"
                icon="warning"
                title={notifTitle}
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />
            <MDSnackbar
                color="success"
                icon="check"
                title={notifTitle}
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />
            <DraggableDialog
                title="New Order"
                description={
                    <MDBox component="img" src={ComingSoon} alt="coming soon" width={500} />
                }
                buttonDesc="Confirm"
                openDialog={showComingSoonDialog}
                handleClose={handleCloseComingSoonDialog}
                handleConfirm={handleCloseComingSoonDialog}
                fullWidth={false}
            />
            {showEmailModal && (
                <Modal
                    open={showEmailModal}
                    onClose={handleCloseEmailModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
                        <Email
                            onClose={handleCloseEmailModal}
                            uuid={uuid}
                            defaultEmailType={emailTypeOptions[0]}
                            emailOptions={emailTypeOptions}
                            showCc
                            // showTo
                            showInitialTo={custData?.email || ""}
                        />
                    </MDBox>
                </Modal>
            )}
            <Footer />
        </DashboardLayout>
    );
}

export default CustomerDetail;
