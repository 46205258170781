/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment/moment";

import Card from "@mui/material/Card";
import { Autocomplete, Icon } from "@mui/material";
import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import RMRDateRangePicker from "components/RMRDateRangePicker";
import RMRDatePicker from "components/RMRDatePicker";
import MDSnackbar from "components/MDSnackbar";
import RangePicker from "components/RangePicker";

import ReportsMgr from "layouts/Reports/ReportsMgr";
import FormField from "layouts/pages/account/components/FormField";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import DailyReport from "./components/DailyReport";
import ConsignmentReport from "./components/ConsignmentReport";
import WeeklyReport from "./components/WeeklyReport";
import MonthyReport from "./components/MonthyReport";
import SalesTaxBySite from "./components/SalesTaxBySite";
import AgedReport from "./components/AgedReport";
import AgedReportSummary from "./components/AgedReportSummary";

const {
  httpRequest,
  getResponseMsg,
  formatDate,
  getDateToday,
  addDateFormatted,
  capitalizeFirstLetter,
} = ReportsMgr;

function ReportSearch({ warehouseReduxList }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const user = useSelector((state) => state?.authentication?.userDetail);
  const warehouseList = useSelector((state) => state?.helper?.warehouse);
  const locationsList = useSelector((state) => state?.helper?.locations);
  const consignerList = useSelector((state) => state?.helper?.consignerList);

  const [reportsData, setReportsData] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [itemTotalList, setItemTotalList] = useState([]);
  const [itemPercentageList, setItemPercentageList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [prevPageLink, setPrevPageLink] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(null);
  const [warehouseOptions, setWarehouseOptions] = useState(warehouseList || []);
  const [locationOptions, setLocationOptions] = useState(locationsList || []);
  const [consignerOptions, setConsignerOptions] = useState(consignerList || []);

  const generateReportOptions = [
    { name: "Consignment report", value: "0" },
    { name: "Daily rentals", value: "1" },
    { name: "Weekly rentals", value: "2" },
    { name: "Monthly rentals", value: "3" },
    { name: "Sales tax report by site", value: "4" },
    { name: "Receivables Aging Report", value: "5" },
    // { name: "Payables  report", value: "1" },
  ];

  const [warehouseFilter, setWarehouseFilter] = useState({ name: "warehouse", id: 0 });
  const [locationFilter, setLocationFilter] = useState({ name: "location", id: 0 });
  const [consignerFilter, setConsignerFilter] = useState({ name: "consigner", id: 0 });
  const [dateFrom, setDateFrom] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [dateTo, setDateTo] = useState(addDateFormatted(getDateToday(), 6, "day", "MM/DD/YYYY"));
  const [monthOf, setMonthOf] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [reportToGenerate, setReportToGenerate] = useState(generateReportOptions[1] || null);
  const [showTable, setShowTable] = useState(null);

  const [showNewbtnTooltip, setShoWNewbtmTooltip] = useState(false);
  const [showViewInventoryTooltip, setShowViewInventoryTooltip] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to Create Report");
  const [successMsg, setSuccessMsg] = useState("Successfully Created Report");

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);

    // setDateFrom(formatDate(getDateToday(), "MM/DD/YYYY"));
    // debugger;
    // const orderListResponse = await loadList(searchKeyword, entriesPerPage);
    // proccessResponse(orderListResponse);
    setIsLoading(false);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleChangeFrom = (newValue) => {
    setDateFrom(formatDate(newValue?.$d, "MM/DD/YYYY"));
    if (reportToGenerate.value === generateReportOptions[4].value) {
      setDateTo(addDateFormatted(newValue?.$d, 30, "day", "MM/DD/YYYY"));
    }
    setShowTable(false);
  };

  const handleChangeTo = (newValue) => {
    setDateTo(formatDate(newValue?.$d, "MM/DD/YYYY"));
    // setDateFrom(subtractDateFormatted(newValue?.$d, 6, "day", "MM/DD/YYYY"));
    setShowTable(false);
  };

  const handleChangeMonth = (newValue) => {
    setMonthOf(formatDate(newValue?.$d, "MM/DD/YYYY"));
    setShowTable(false);
  };

  useEffect(() => {
    setWarehouseFilter(user?.warehouse);
    setLocationFilter(user?.location);
  }, [user]);

  useEffect(() => {
    // console.log([{ name: "All", id: "all" ,}, ...warehouseReduxList]);
    setWarehouseOptions([{ name: "All", id: "all" }, ...warehouseReduxList]);
    setWarehouseFilter(warehouseReduxList[0]);
  }, [warehouseReduxList]);

  useEffect(() => {
    setLocationOptions([{ name: "All", id: "all" }, ...locationsList]);
  }, [locationsList]);

  useEffect(() => {
    setConsignerOptions([...consignerList]);
    // setConsignerOptions([{ full_name: "All", uuid: "all" }, ...consignerList]);
    setConsignerFilter(consignerList[0]);
  }, [consignerList]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="flex-end" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
            <Autocomplete
              // multiple
              disablePortal
              id="combo-box-demo"
              value={reportToGenerate}
              options={generateReportOptions}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <FormField {...params} label="SELECT A REPORT TO GENERATE" />
              )}
              sx={{ width: 250, height: "100%", lineHeight: 1, m: 1 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setReportToGenerate(newValue);
                  setShowTable(false);
                }
              }}
            />
          </MDBox>
          {/* CONSIGNER HAS DIFFERENT JASON FORMAT THAN LOCATION AND WAREHOUSE */}
          {reportToGenerate.value === generateReportOptions[0].value && (
            <MDBox>
              <Autocomplete
                disablePortal
                value={consignerFilter || null}
                options={consignerOptions}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.full_name === value.full_name : option.full_name
                }
                getOptionLabel={(option) => (option ? option.full_name : "")}
                renderInput={(params) => (
                  <FormField {...params} label="Consigner" InputLabelProps={{ shrink: true }} />
                )}
                sx={{ width: 200, height: "100%", lineHeight: 1, m: 1 }}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  if (newValue !== null) {
                    setConsignerFilter(newValue);
                    setShowTable(false);
                  }
                }}
              />
            </MDBox>
          )}
          {(reportToGenerate.value === generateReportOptions[1].value ||
            reportToGenerate.value === generateReportOptions[2].value ||
            reportToGenerate.value === generateReportOptions[3].value ||
            reportToGenerate.value === generateReportOptions[5].value) && (
            <MDBox>
              <Autocomplete
                disablePortal
                value={warehouseFilter || null}
                options={warehouseOptions || warehouseReduxList}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                renderInput={(params) => (
                  <FormField {...params} label="Warehouse" InputLabelProps={{ shrink: true }} />
                )}
                sx={{ width: 200, height: "100%", lineHeight: 1, m: 1 }}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  if (newValue !== null) {
                    setWarehouseFilter(newValue);
                    setShowTable(false);
                  }
                }}
              />
            </MDBox>
          )}
          {reportToGenerate.value === generateReportOptions[4].value && (
            <MDBox>
              <Autocomplete
                disablePortal
                value={locationFilter}
                options={locationOptions}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                renderInput={(params) => (
                  <FormField {...params} label="Location" InputLabelProps={{ shrink: true }} />
                )}
                sx={{ width: 200, height: "100%", lineHeight: 1, m: 1 }}
                onChange={(event, newValue) => {
                  // console.log(newValue);
                  if (newValue !== null) {
                    setLocationFilter(newValue);
                    setShowTable(false);
                  }
                }}
              />
            </MDBox>
          )}
          {(reportToGenerate.value === generateReportOptions[1].value ||
            reportToGenerate.value === generateReportOptions[2].value ||
            reportToGenerate.value === generateReportOptions[0].value) && (
            <MDBox display="flex">
              <MDBox mt={2}>
                <RMRDatePicker
                  value={dateFrom}
                  handleChange={handleChangeFrom}
                  label={
                    reportToGenerate.value === generateReportOptions[1].value ? "Date" : "Date From"
                  }
                  inputFormat="MM/DD/YYYY"
                />
              </MDBox>
            </MDBox>
          )}
          {(reportToGenerate.value === generateReportOptions[2].value ||
            reportToGenerate.value === generateReportOptions[0].value) && (
            <MDBox display="flex">
              <MDBox mt={2} ml={2}>
                <RMRDatePicker
                  value={dateTo}
                  handleChange={handleChangeTo}
                  label="Date To"
                  inputFormat="MM/DD/YYYY"
                  disabled={reportToGenerate.value === generateReportOptions[4].value}
                />
              </MDBox>
            </MDBox>
          )}
          {(reportToGenerate.value === generateReportOptions[3].value ||
            reportToGenerate.value === generateReportOptions[4].value) && (
            <MDBox display="flex">
              <MDBox mt={2} ml={2}>
                <RMRDatePicker
                  value={monthOf}
                  views={["month"]}
                  handleChange={handleChangeMonth}
                  label="Month"
                  inputFormat="MMMM"
                  // disabled
                />
              </MDBox>
            </MDBox>
          )}
          {/* <RMRDateRangePicker /> */}
          <MDBox m={2}>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={async () => {
                setIsLoading(true);

                document.body.style.cursor = "wait";
                let response = {};
                switch (reportToGenerate.value) {
                  case generateReportOptions[0].value:
                    response = await httpRequest(
                      "consignment-report",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                        consigner: consignerFilter?.uuid,
                      }
                    );
                    break;
                  case generateReportOptions[1].value:
                    response = await httpRequest(
                      "daily-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  case generateReportOptions[2].value:
                    response = await httpRequest(
                      "weekly-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  case generateReportOptions[3].value:
                    response = await httpRequest(
                      "monthly-rentals",
                      {},
                      {
                        monthOf: (moment(monthOf).month() || 0) + 1,
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  case generateReportOptions[4].value:
                    response = await httpRequest(
                      "sales-tax-by-site-rentals",
                      {},
                      {
                        monthOf: (moment(monthOf).month() || 0) + 1,
                        location: locationFilter?.id,
                      }
                    );
                    break;
                  case generateReportOptions[5].value:
                    response = await httpRequest(
                      "receivables-aging",
                      {},
                      {
                        warehouse: warehouseFilter?.id,
                      }
                    );
                    break;
                  default:
                    break;
                }

                if (getResponseMsg(response) === "Success") {
                  switch (reportToGenerate.value) {
                    case generateReportOptions[1].value:
                    case generateReportOptions[2].value:
                    case generateReportOptions[3].value:
                      setReportsData({
                        ...response.data.data,
                      });
                      break;
                    case generateReportOptions[0].value:
                    case generateReportOptions[4].value:
                    case generateReportOptions[5].value:
                      setReportsData({
                        ...response.data.data,
                      });
                      break;
                    default:
                      break;
                  }
                  setItemList(response.data.data.day_totals);
                  setItemTotalList(response.data.data.column_totals);
                  setItemPercentageList(response.data.data.column_percentage);
                  setShowTable(true);
                  // console.log(response.data.data.day_totals);
                } else {
                  //   console.log(response);
                  let responseErrMsg = "";
                  Object.entries(
                    response?.data?.date_from ||
                      response?.data?.date_to ||
                      response?.data?.warehouse ||
                      response?.data?.date ||
                      []
                  ).map((item) => {
                    responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${
                      item[1]
                    }`;
                    openErrorSB(true);
                    setErrMsg(responseErrMsg);
                    return 0;
                  });
                }
                setIsLoading(false);
                document.body.style.cursor = "default";
              }}
              // disabled={!hasPermission("add_order", permissions?.orders)}
              disabled={
                reportToGenerate === null ||
                // reportToGenerate.value === generateReportOptions[0].value ||
                // reportToGenerate.value === generateReportOptions[1].value ||
                // reportToGenerate.value === generateReportOptions[2].value ||
                // reportToGenerate.value === generateReportOptions[3].value ||
                // reportToGenerate.value === generateReportOptions[4].value ||
                // reportToGenerate.value === generateReportOptions[5].value ||
                (reportToGenerate.value === generateReportOptions[1].value && dateFrom === null) ||
                (reportToGenerate.value === generateReportOptions[2].value && dateFrom === null) ||
                dateTo === null ||
                (reportToGenerate.value === generateReportOptions[2].value &&
                  (dateFrom === null || dateTo === null)) ||
                (reportToGenerate.value === generateReportOptions[3].value && monthOf === null) ||
                dateTo === "" ||
                warehouseFilter === null
              }
            >
              Generate report
            </MDButton>
          </MDBox>
        </MDBox>

        {showTable && (
          <MDBox ml={1} mb={2} display="flex" justifyContent="flex-end">
            <MDBox mr={1}>
              <MDButton
                variant="outlined"
                color="dark"
                onClick={async () => {
                  document.body.style.cursor = "wait";

                  let response = {};
                  switch (reportToGenerate.value) {
                    case generateReportOptions[0].value:
                      response = await httpRequest(
                        "export-consignment-report",
                        {},
                        {
                          dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                          dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                          consigner: consignerFilter?.uuid,
                          exportType: "pdf",
                        }
                      );
                      break;

                    case generateReportOptions[1].value:
                      response = await httpRequest(
                        "export-daily-rentals",
                        {},
                        {
                          dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                          warehouse: warehouseFilter?.id,
                          exportType: "pdf",
                        }
                      );
                      break;
                    case generateReportOptions[2].value:
                      response = await httpRequest(
                        "export-weekly-rentals",
                        {},
                        {
                          dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                          dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                          warehouse: warehouseFilter?.id,
                          exportType: "pdf",
                        }
                      );
                      break;
                    case generateReportOptions[3].value:
                      response = await httpRequest(
                        "export-monthly-rentals",
                        {},
                        {
                          monthOf: (moment(monthOf).month() || 0) + 1,
                          warehouse: warehouseFilter?.id,
                          exportType: "pdf",
                        }
                      );
                      break;
                    case generateReportOptions[4].value:
                      response = await httpRequest(
                        "export-sales-tax-by-site-rentals",
                        {},
                        {
                          monthOf: (moment(monthOf).month() || 0) + 1,
                          location: locationFilter?.id,
                          exportType: "pdf",
                        }
                      );
                      break;
                    case generateReportOptions[5].value:
                      response = await httpRequest(
                        "export-receivables-aging",
                        {},
                        {
                          warehouse: warehouseFilter?.id,
                          exportType: "pdf",
                        }
                      );
                      break;
                    default:
                      break;
                  }

                  if (getResponseMsg(response) === "Success") {
                    window.open(response?.data?.data?.url, "_blank", "noreferrer");
                  }
                  document.body.style.cursor = "default";
                }}
                // disabled={
                //   reportToGenerate === null ||
                //   reportToGenerate.value === generateReportOptions[0].value ||
                //   // reportToGenerate.value === generateReportOptions[1].value ||
                //   // reportToGenerate.value === generateReportOptions[2].value ||
                //   // reportToGenerate.value === generateReportOptions[3].value ||
                //   reportToGenerate.value === generateReportOptions[4].value ||
                //   reportToGenerate.value === generateReportOptions[5].value
                // }
              >
                <Icon>description</Icon>
                &nbsp;export PDF
              </MDButton>
            </MDBox>
          </MDBox>
        )}
        {reportToGenerate.value === generateReportOptions[1].value &&
          showTable &&
          reportsData?.results.map((warehouse) => (
            <DailyReport
              reportsData={{
                ...warehouse,
                ...warehouse?.report,
                date: dateFrom,
                dateFrom,
                dateTo,
                monthOf,
                warehouseFilter,
              }}
              itemTotalList={itemTotalList}
            />
          ))}
        {reportToGenerate.value === generateReportOptions[2].value &&
          showTable &&
          reportsData?.results.map((warehouse) => (
            <WeeklyReport
              reportsData={{
                ...warehouse,
                ...warehouse?.report,
                date: dateFrom,
                dateFrom,
                dateTo,
                monthOf,
                warehouseFilter,
              }}
              itemTotalList={itemTotalList}
            />
          ))}
        {reportToGenerate.value === generateReportOptions[3].value &&
          showTable &&
          reportsData?.results.map((warehouse) => (
            <MonthyReport
              reportsData={{
                ...warehouse,
                ...warehouse?.report,
                date: dateFrom,
                dateFrom,
                dateTo,
                monthOf,
                warehouseFilter,
              }}
              itemTotalList={itemTotalList}
            />
          ))}
        {reportToGenerate.value === generateReportOptions[0].value && showTable && (
          <ConsignmentReport reportsData={reportsData} />
        )}
        {/* {reportToGenerate.value === generateReportOptions[1].value && showTable && (
          <DailyReport reportsData={reportsData} itemTotalList={itemTotalList} />
        )} */}
        {/* {reportToGenerate.value === generateReportOptions[2].value && showTable && (
          <WeeklyReport reportsData={reportsData} itemTotalList={itemTotalList} />
        )}
        {reportToGenerate.value === generateReportOptions[3].value && showTable && (
          <MonthyReport reportsData={reportsData} itemTotalList={itemTotalList} />
        )} */}
        {reportToGenerate.value === generateReportOptions[4].value &&
          showTable &&
          reportsData?.results.map((site, index) => (
            <SalesTaxBySite
              reportsData={{ ...site, date: reportsData?.date, month: reportsData?.month, index }}
            />
          ))}
        {reportToGenerate.value === generateReportOptions[5].value &&
          showTable &&
          reportsData?.results.map((aged, index) => (
            <MDBox>
              <AgedReport
                reportsData={{
                  ...aged,
                  date: reportsData?.date,
                  month: reportsData?.month,
                  index,
                  reportsTotal: reportsData?.report_totals,
                }}
              />
            </MDBox>
          ))}

        {reportToGenerate.value === generateReportOptions[5].value && showTable && (
          <MDBox>
            <AgedReportSummary
              reportsData={{
                date: reportsData?.date,
                month: reportsData?.month,
                reportsTotal: reportsData?.report_totals,
              }}
            />
          </MDBox>
        )}
        {/* {reportToGenerate.value === generateReportOptions[4].value && showTable && (
          <SalesTaxBySite reportsData={reportsData} />
        )} */}
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Item Creation"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

ReportSearch.defaultProps = {
  userDetail: {},
  warehouseReduxList: [],
  locationReduxList: [],
};

ReportSearch.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
  warehouseReduxList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  locationReduxList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
  locationReduxList: state?.helper?.locations,
});

export default connect(mapStateToProps, null)(ReportSearch);
