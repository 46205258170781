/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */

import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Switch } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import CustomModal from "components/CustomModal";

import FormField from "layouts/Inventory/new-item/components/FormField";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";
import CategoriesNew from "layouts/admin/Categories/CategoriesNew";
import CategoryMgr from "layouts/admin/Categories/CategoryMgr";
import MDSnackbar from "components/MDSnackbar";
import SubcategoriesNew from "layouts/admin/Subcategories/SubcategoriesNew";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

const { getStorageItem, setStorageItem, getSubCategoryList } = SubcategoryMgr;
const { getCategoryList, RESPONSE_SUCCESS } = CategoryMgr;
const { limitInputLength, hasPermission } = InventoryMgr;

function Info({ formData, setIsKit, showFieldError }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const { formField, values, errors, touched } = formData;
  const {
    itemName,
    itemDescription,
    itemCategory,
    itemSubCategory,
    itemType,
    manufacturer,
    modelNumber,
  } = formField;
  const {
    item_name: itemNameV,
    item_description: itemDescriptionV,
    item_type: itemTypeV,
    item_category: itemCategoryV,
    item_sub_category: itemSubCategoryV,
    is_qc_required: qcRequiredV,
    manufacturer: manufacturerV,
    model_number: modelNumberV,
  } = values;

  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const itemTypeList = [
    { name: "Rental", value: "rental" },
    { name: "Kit", value: "kit" },
    { name: "Sales", value: "sales" },
  ];

  const [itemNameValue, setItemNameValue] = useState(itemNameV);
  const [itemDescValue, setItemDescValue] = useState(itemDescriptionV);
  const [itemManufacturerValue, setItemManufacturerValue] = useState(manufacturerV);
  const [itemModelNumberValue, setModelNumberValue] = useState(modelNumberV);
  const [itemTypeValue, setItemTypeValue] = useState(itemTypeV);
  const [itemCategoryValue, setItemCategoryValue] = useState(itemCategoryV || null);
  const [itemSubCategoryValue, setItemSubCategoryValue] = useState(itemSubCategoryV || null);
  const [qcRequiredValue, setQcRequiredValue] = useState(qcRequiredV || false);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showAddNewCatModal, setShowAddNewCatModal] = useState(false);
  const [showAddNewSubCatModal, setShowAddNewSubCatModal] = useState(false);

  const [successMsg, setSuccessMsg] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const handleShowAddNewCatModal = () => setShowAddNewCatModal(true);
  const handleOpenNewSubCategoryModal = () => setShowAddNewSubCatModal(true);

  const handleQcCB = (e) => {
    setQcRequiredValue(e.target.checked);
    values.is_qc_required = e.target.checked;
  };

  const processSubCategoryOptions = (data = []) => {
    if (hasPermission("add_inventorysubcategory", permissions?.inventory)) {
      setSubCategoryListOptions([
        ...data,
        {
          uuid: 0,
          name: "Add New Sub-Category",
          total_items: 0,
        },
      ]);
    } else {
      setSubCategoryListOptions([...data]);
    }
    setItemSubCategoryValue(data[0] || null);
    values.item_sub_category = data[0] || null;
  };

  const saveSubCategories = async (val = "") => {
    // const response = await getSubCategoryList(itemCategoryValue.uuid, "", 0);
    const response = await getSubCategoryList(val, "", 0);

    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      setSuccessMsg("Successfully added Sub-category!");

      processSubCategoryOptions(response.data.data);

      openSuccessSB();
    } else {
      setErrMsg(response.data.category || "Failed to added category!");
      openErrorSB();
    }

    return response;
  };

  const processCategoryOptions = async (data = []) => {
    if (hasPermission("add_inventorycategory", permissions?.inventory)) {
      setCategoryOptions([
        ...data,
        {
          uuid: 0,
          // name: (
          //   <MDTypography variant="body" color="info">
          //     Add New Category
          //   </MDTypography>
          // ),
          name: "Add New Category",
          total_items: 0,
        },
      ]);
    } else {
      setCategoryOptions([...data]);
    }

    // setItemCategoryValue(data[0] || null);
    // values.item_category = data[0] || null;
  };

  const handleChangeAutoComplete = async (e, val, field) => {
    if (val) {
      switch (field) {
        case "itemType":
          values.item_type = val;
          setItemTypeValue(val);
          setIsKit(val.value === "kit");
          break;
        case "category":
          if (val.uuid === 0) {
            const data = { ...[], pageState: "NEW" };

            setStorageItem("current_categories_detail", data);
            handleShowAddNewCatModal();
            setItemCategoryValue(null);
          } else {
            values.item_category = val;
            setItemCategoryValue(val);

            if (val !== null && val?.uuid !== "") {
              setLoading(true);
              setStorageItem("current_categories_detail", val);
              const response = await getSubCategoryList(val?.uuid, "", 0);
              processSubCategoryOptions(response.data.data);

              setLoading(false);
            }

            values.item_sub_category = null;
            setItemSubCategoryValue(null);
          }

          break;
        case "subCategory":
          if (val.uuid === 0) {
            const tempData = { pageState: "NEW" };
            setStorageItem("current_sub_categories_detail", tempData);
            handleOpenNewSubCategoryModal();
            setItemSubCategoryValue(null);
          } else {
            values.item_sub_category = val;
            setItemSubCategoryValue(val);
          }
          break;
        default:
          break;
      }
    }
  };

  const saveCategories = async () => {
    const response = await getCategoryList("", 0);

    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      setSuccessMsg("Successfully added category!");
      processCategoryOptions(response.data.data);
      handleChangeAutoComplete(null, response.data.data[0], "category");

      openSuccessSB();
    } else {
      setErrMsg(response.data.category || "Failed to added category!");
      openErrorSB();
    }

    return response;
  };

  const handleHideAddNewCatModal = async (action = "") => {
    setShowAddNewCatModal(false);
    switch (action) {
      case "save":
        saveCategories();
        break;
      default:
        break;
    }
  };

  const handleHideNewSubCategoryModal = async (action = "") => {
    setShowAddNewSubCatModal(false);

    switch (action) {
      case "save":
        saveSubCategories(itemCategoryValue.uuid);
        break;
      default:
        break;
    }
  };

  useEffect(async () => {
    const updatedCategoryListResponse = await getCategoryList("", 0);
    processCategoryOptions(updatedCategoryListResponse.data.data);

    if (itemDetail.pageState === "NEW") {
      values.item_type = null;
      setItemCategoryValue(null);
      setItemSubCategoryValue(null);
      setItemTypeValue(null);
      setItemNameValue("");
      setItemDescValue("");
      setItemManufacturerValue("");
      setModelNumberValue("");

      values.item_name = "";
      values.item_description = "";
      values.item_category = null;
      values.item_sub_category = null;
      values.is_qc_required = false;
      values.manufacturer = "";
      values.model_number = "";
    } else {
      const response = await getSubCategoryList(itemCategoryValue.uuid, "", 0);

      if (response.status === RESPONSE_SUCCESS || response.status === 201) {
        processSubCategoryOptions(response.data.data);
      } else {
        setErrMsg(response.data.category || "Failed to added category!");
        openErrorSB();
      }
    }
  }, []);

  return (
    <MDBox>
      <MDBox p={3}>
        <MDTypography variant="h5">Item Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={6}>
            <FormField
              type={itemName.type}
              label={itemName.label}
              name={itemName.name}
              value={itemNameValue}
              placeholder={itemName.placeholder}
              // error={itemNameValue === ""}
              onChange={(e) => {
                const input = limitInputLength(e.target.value, 150);
                setItemNameValue(input);
                values.item_name = input;
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <FormField
              type={itemDescription.type}
              label={itemDescription.label}
              name={itemDescription.name}
              value={itemDescValue}
              placeholder={itemDescription.placeholder}
              // error={itemDescValue === ""}
              onChange={(e) => {
                const input = limitInputLength(e.target.value, 100);
                setItemDescValue(input);
                values.item_description = input;
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Autocomplete
              options={categoryOptions || null}
              value={itemCategoryValue}
              name={itemCategory.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.uuid === value.uuid : option.uuid
              }
              getOptionLabel={(option) => (option ? option?.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={itemCategory.label} />
              )}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, "category");
              }}
            />

            {showFieldError && itemCategoryValue === null && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {itemCategory.errorMsg}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Autocomplete
              options={subCategoryListOptions || null}
              value={itemSubCategoryValue}
              name={itemSubCategory.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option?.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={itemSubCategory.label} />
              )}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, "subCategory");
              }}
              loading={isLoading}
              disabled={itemCategoryValue === null}
            />

            {showFieldError && itemSubCategoryValue === null && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {itemSubCategory.errorMsg}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Autocomplete
              options={itemTypeList}
              value={itemTypeValue}
              name={itemType.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.value === value.value : option.value
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={itemType.label} />
              )}
              onChange={(event, newValue) => {
                handleChangeAutoComplete(event, newValue, "itemType");
              }}
            />

            {showFieldError && itemTypeValue === null && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {itemType.errorMsg}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          {itemTypeValue?.value !== "kit" && (
            <Grid item xs={4} sm={4} md={6} mt={1.5}>
              <MDBox display="flex" alignItems="center" justifyContent="flex-start">
                <MDBox>
                  <Switch checked={qcRequiredValue} onChange={handleQcCB} />
                </MDBox>
                <MDBox lineHeight={0}>
                  <MDTypography variant="button" color="text">
                    {qcRequiredValue ? "QC Required" : "QC not Required"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
          )}
          {itemTypeValue?.value !== "kit" && (
            <Grid item xs={4} sm={4} md={6}>
              <FormField
                type={manufacturer.type}
                label={manufacturer.label}
                name={manufacturer.name}
                value={itemManufacturerValue}
                placeholder={manufacturer.placeholder}
                // error={errors.manufacturer && touched.manufacturer}
                onChange={(e) => {
                  const input = limitInputLength(e.target.value, 20);
                  setItemManufacturerValue(input);
                  values.manufacturer = input;
                }}
              />
            </Grid>
          )}
          {itemTypeValue?.value !== "kit" && (
            <Grid item xs={4} sm={4} md={6}>
              <FormField
                type={modelNumber.type}
                label={modelNumber.label}
                name={modelNumber.name}
                value={itemModelNumberValue}
                placeholder={modelNumber.placeholder}
                // error={errors.modelNumber && touched.modelNumber}
                onChange={(e) => {
                  const input = limitInputLength(e.target.value, 20);
                  setModelNumberValue(input);
                  values.model_number = input;
                }}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
      {showAddNewCatModal && (
        <CustomModal showModal={showAddNewCatModal} handleHideModal={handleHideAddNewCatModal}>
          <CategoriesNew handleClose={handleHideAddNewCatModal} />
        </CustomModal>
      )}

      {showAddNewSubCatModal && (
        <CustomModal
          showModal={showAddNewSubCatModal}
          handleHideModal={handleHideNewSubCategoryModal}
        >
          <SubcategoriesNew handleClose={handleHideNewSubCategoryModal} />
        </CustomModal>
      )}

      <MDSnackbar
        color="success"
        icon="check"
        title="Item List"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title="Item List"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

// Setting default values for the props of DashboardNavbar
Info.defaultProps = {
  categoriesListRedux: [{}],
};

// typechecking props for UserInfo
Info.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  categoriesListRedux: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  setIsKit: PropTypes.func.isRequired,
  showFieldError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
});

export default connect(mapStateToProps, null)(Info);
