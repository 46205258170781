/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ReportsMgr from "layouts/Reports/ReportsMgr";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";

const { formatDate, getDateToday, convertToCurrency, addDateFormatted } = ReportsMgr;

const styleBotderTopBottom = {
  borderStyle: "solid none solid none",
  borderWidth: "medium",
};

const topBorder = {
  borderStyle: "solid none none none",
  borderWidth: "2px",
  borderColor: "#667a6b",
};

const btmBorder = {
  borderStyle: "none none solid none",
  borderWidth: "2px",
  borderColor: "#667a6b",
};

function AgedReport({ userDetail, reportsData }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const generateReportOptions = [
    { name: "Consignment report", value: "1" },
    { name: "Daily rentals", value: "2" },
    { name: "Weekly rentals", value: "3" },
    { name: "Monthly rentals", value: "4" },
    { name: "Sales tax report by site", value: "5" },
    { name: "Receivables report", value: "6" },
    // { name: "Payables  report", value: "1" },
  ];

  const [warehouseFilter, setWarehouseFilter] = useState(userDetail?.warehouse);
  const [dateFrom, setDateFrom] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [dateTo, setDateTo] = useState(addDateFormatted(getDateToday(), 6, "day", "MM/DD/YYYY"));
  const [monthOf, setMonthOf] = useState(formatDate(getDateToday(), "MM/DD/YYYY"));
  const [reportToGenerate, setReportToGenerate] = useState(generateReportOptions[1] || null);

  return (
    <MDBox mt={2}>
      {/* {itemList?.length > 0 && (
        <MDBox ml={1} mb={2} display="flex" justifyContent="flex-end">
          <MDBox mr={1}>
            <MDButton
              variant="outlined"
              color="dark"
              onClick={async () => {
                setIsLoading(true);
                let response = {};
                switch (reportToGenerate.value) {
                  case generateReportOptions[1].value:
                    response = await httpRequest(
                      "export-daily-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                        exportType: "pdf",
                      }
                    );
                    break;
                  case generateReportOptions[2].value:
                    response = await httpRequest(
                      "export-weekly-rentals",
                      {},
                      {
                        dateFrom: formatDate(dateFrom, "YYYY-MM-DD"),
                        dateTo: formatDate(dateTo, "YYYY-MM-DD"),
                        warehouse: warehouseFilter?.id,
                        exportType: "pdf",
                      }
                    );
                    break;
                  case generateReportOptions[3].value:
                    response = await httpRequest(
                      "export-monthly-rentals",
                      {},
                      {
                        monthOf: (moment(monthOf).month() || 0) + 1,
                        warehouse: warehouseFilter?.id,
                        exportType: "pdf",
                      }
                    );
                    break;
                  default:
                    break;
                }

                if (getResponseMsg(response) === "Success") {
                  window.open(response?.data?.data?.url, "_blank", "noreferrer");
                }
                setIsLoading(false);
              }}
            >
              <Icon>description</Icon>
              &nbsp;export PDF
            </MDButton>
          </MDBox>
        </MDBox>
      )} */}
      <MDBox>
        <Card width="100%">
          <MDBox sx={{ margin: 2 }}>
            <MDBox>
              <MDTypography variant="h5" fontWeight="medium">
                {reportsData?.warehouse}
              </MDTypography>
              <MDTypography variant="h6" fontWeight="medium">
                Aged Customer Balance Report as of {formatDate(getDateToday(), "MM/DD/YYYY")}
                &nbsp;at&nbsp;
                {moment().format("hh:mm a")}
              </MDTypography>
            </MDBox>

            <MDBox marginTop={2}>
              <Grid
                container
                columns={{ xs: 4, sm: 8, md: 12 }}
                sx={{
                  borderStyle: "solid none solid none",
                  borderWidth: "medium",
                  paddingLeft: 0.5,
                  paddingRight: 0.5,
                }}
              >
                <Grid item xs={4} sm={8} md={1.5}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Cust #
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1.5}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Name
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Order #
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Status
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Date
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Credits
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Under 31
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    31 - 60
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    61 - 90
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Over 90
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={1} textAlign="right">
                  <MDTypography variant="h6" fontWeight="medium">
                    Total
                  </MDTypography>
                </Grid>
              </Grid>
              {reportsData?.report?.length > 0 ? (
                reportsData?.report.map((entry) => (
                  <MDBox>
                    <Grid
                      container
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      sx={{
                        paddingLeft: 0.5,
                        paddingRight: 0.5,
                        marginTop: 1,
                      }}
                    >
                      <Grid item xs={4} sm={8} md={1.5}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.idenfitier}
                        </MDTypography>
                      </Grid>
                      <Grid item xs={4} sm={8} md={10.5}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {entry?.full_name}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    {entry?.contracts?.length > 0 &&
                      entry?.contracts.map((contract) => (
                        <Grid
                          container
                          columns={{ xs: 4, sm: 8, md: 12 }}
                          sx={{
                            paddingLeft: 0.5,
                            paddingRight: 0.5,
                          }}
                        >
                          <Grid item xs={4} sm={8} md={1.5}>
                            <MDTypography variant="body2"> </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1.5}>
                            <MDTypography variant="body2"> </MDTypography>
                          </Grid>

                          <Grid item xs={4} sm={8} md={1} sx={topBorder}>
                            <MDTypography
                              variant="body2"
                              // fontWeight="medium"
                              sx={{ fontWeight: "regular", fontSize: "16px" }}
                            >
                              {contract?.identifier}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} sx={topBorder}>
                            <MDTypography variant="body2">{contract?.status}</MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} sx={topBorder}>
                            <MDTypography variant="body2">
                              {formatDate(contract?.date_closed, "MM/DD/YYYY")}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} textAlign="right" sx={topBorder}>
                            <MDTypography variant="h6">
                              {convertToCurrency(contract?.credits)}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} textAlign="right" sx={topBorder}>
                            <MDTypography variant="h6">
                              {convertToCurrency(contract?.under_31)}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} textAlign="right" sx={topBorder}>
                            <MDTypography variant="h6">
                              {convertToCurrency(contract?.bet_31_to_60)}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} textAlign="right" sx={topBorder}>
                            <MDTypography variant="h6">
                              {convertToCurrency(contract?.bet_61_to_90)}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} textAlign="right" sx={topBorder}>
                            <MDTypography variant="h6">
                              {convertToCurrency(contract?.over_90)}
                            </MDTypography>
                          </Grid>
                          <Grid item xs={4} sm={8} md={1} textAlign="right" sx={topBorder}>
                            <MDTypography variant="h6"> </MDTypography>
                          </Grid>
                        </Grid>
                      ))}
                    <MDBox textAlign="right">
                      <Grid
                        container
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{
                          paddingLeft: 0.5,
                          paddingRight: 0.5,
                          marginTop: 1,
                        }}
                      >
                        <Grid item xs={4} sm={8} md={6} display="flex" justifyContent="flex-end">
                          <MDTypography variant="h6" fontWeight="medium">
                            &nbsp;
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={1}
                          textAlign="right"
                          sx={{
                            backgroundColor: "#f2f0e9",
                            ...styleBotderTopBottom,
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(entry?.total_amounts?.credits)}
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={1}
                          textAlign="right"
                          sx={{
                            backgroundColor: "#f2f0e9",
                            ...styleBotderTopBottom,
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(entry?.total_amounts?.under_31)}
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={1}
                          textAlign="right"
                          sx={{
                            backgroundColor: "#f2f0e9",
                            ...styleBotderTopBottom,
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(entry?.total_amounts?.bet_31_to_60)}
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={1}
                          textAlign="right"
                          sx={{
                            backgroundColor: "#f2f0e9",
                            ...styleBotderTopBottom,
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(entry?.total_amounts?.bet_61_to_90)}
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={1}
                          textAlign="right"
                          sx={{
                            backgroundColor: "#f2f0e9",
                            ...styleBotderTopBottom,
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(entry?.total_amounts?.over_90)}
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={8}
                          md={1}
                          textAlign="right"
                          sx={{
                            backgroundColor: "#f2f0e9",
                            ...styleBotderTopBottom,
                          }}
                        >
                          <MDTypography variant="h6" fontWeight="medium">
                            {convertToCurrency(entry?.total_amounts?.total)}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>
                ))
              ) : (
                <MDTypography variant="h5" textAlign="center">
                  No Record
                </MDTypography>
              )}
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </MDBox>
  );
}

AgedReport.defaultProps = {
  userDetail: {},
  reportsData: [],
};

AgedReport.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
  reportsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
});

export default connect(mapStateToProps, null)(AgedReport);
