import * as Yup from "yup";

const rentalItemValidation = Yup.object().shape({
  item_name: Yup.string().required("Item name is required."),
  item_description: Yup.string().required("Item Description is required."),
  subitems: Yup.mixed().required("Item Sub-Category is required."),
  replacement_value: Yup.number().nullable().required("Replacement Value is required."),
  // daily_price: Yup.string().required("Daily Price is required."),
  // weekly_price: Yup.string().required("Weekly Price is required."),
  // monthly_price: Yup.string().required("Monthly Price is required."),
});

const kitsValidation = Yup.object().shape({
  item_name: Yup.string().required("Item name is required."),
  item_description: Yup.string().required("Item Description is required."),
  subitems: Yup.mixed().required("Item Sub-Category is required."),
  replacement_value: Yup.number().nullable().required("Replacement Value is required."),
  // daily_price: Yup.string().required("Daily Price is required."),
  // weekly_price: Yup.string().required("Weekly Price is required."),
  // monthly_price: Yup.string().required("Monthly Price is required."),
});

const validations = {
  rentalItemValidation,
  kitsValidation,
};

export default validations;
