/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Card, Icon, IconButton, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import MDSnackbar from "components/MDSnackbar";

import { useMaterialUIController } from "context";
import OrderMgr from "layouts/Order/OrderMgr";
import WarehouseMgr from "../WarehouseMgr";
import BasicInfo from "./checkout-detail-components/BasicInfo";
import RentItems from "./checkout-detail-components/RentItems";
import AttachmentsInfo from "./checkout-detail-components/AttachmentsInfo";

const { ORDERS } = OrderMgr;

const { httpRequest, setStorageItem, getResponseMsg, getStorageItem, WAREHOUSE_CHECKOUT } =
  WarehouseMgr;

function CheckoutDetails() {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || ORDERS.LIST_ROUTE;
  const checkoutDetails = JSON.parse(getStorageItem("current_checkout_detail"));

  const [orderData, setOrderData] = useState(null);

  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item Creation");

  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const closeErrorSB = () => setErrorSB(false);
  const closeSuccessSB = () => setSuccessSB(false);

  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("warehouse-checkout-detail", null, {
      uuid: checkoutDetails?.uuid,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      // setOrderData(processRows(responseDetails?.data?.data));
      setOrderData(responseDetails?.data?.data);
      // console.log(responseDetails?.data?.data);

      setStorageItem("current_checkout_detail", responseDetails?.data?.data);
    } else {
      setStorageItem("current_checkout_detail", null);
      navigate(WAREHOUSE_CHECKOUT.LIST_ROUTE, {
        state: { from: location },
        // replace: true
      });
    }

    setLoading(false);

    return 0;
  };

  useEffect(() => {
    const controllerRequest = new AbortController();
    setNotifTitle("Checkout Details");
    if (checkoutDetails === {}) {
      navigate(WAREHOUSE_CHECKOUT.LIST_ROUTE, {
        // replace: true
      });
    }
    loadDetails();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleClose = () => {
    // console.log(location, from);
    if (from && from !== WAREHOUSE_CHECKOUT.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(WAREHOUSE_CHECKOUT?.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1} display="flex" justifyContent="flex-end">
          {isMobile ? (
            <MDBox>
              <Tooltip title="Back" placement="top">
                <span>
                  <IconButton
                    aria-label="back"
                    size="small"
                    onClick={handleClose}
                    color="error"
                    // disabled
                  >
                    <Icon fontSize="small">keyboard_backspace</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </MDBox>
          ) : (
            <MDBox mr={1}>
              <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
                back
              </MDButton>
            </MDBox>
          )}
        </MDBox>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12}>
                <BasicInfo data={orderData || {}} loadDetails={loadDetails} />
              </Grid>
              <Grid item xs={12}>
                <RentItems
                  data={orderData || {}}
                  setData={setOrderData}
                  loadDetails={loadDetails}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Card>

        <Card id="basic-info" sx={{ overflow: "visible", height: "auto", marginTop: 2 }}>
          <MDBox>
            <AttachmentsInfo data={orderData || {}} loadDetails={loadDetails} />
          </MDBox>
        </Card>
      </MDBox>

      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default CheckoutDetails;
