const form = {
  formId: "new-category-form",
  formField: {
    categoryName: {
      name: "name",
      label: "Category Name *",
      type: "text",
      errorMsg: "Category name is required.",
    },
  },
};

export default form;
