/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Autocomplete, Divider, Icon, Menu, TextField, Tooltip } from "@mui/material";

import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";

const {
  INVENTORY,
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getResponseMsg,
  hasPermission,
  convertToCurrency,
} = InventoryMgr;
const { getSubCategoryList } = SubcategoryMgr;

function InventorySearch({ categoriesListRedux }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const [itemList, setItemList] = useState([]);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);
  const [categoryFilterValue, setCategoryFilterValue] = useState(null);
  const [subCategoryFilterValue, setSubCategoryFilterValue] = useState(null);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [menu, setMenu] = useState(null);
  const [showNewbtnTooltip, setShoWNewbtmTooltip] = useState(false);
  const [showViewInventoryTooltip, setShowViewInventoryTooltip] = useState(false);

  const openNewBtnTooltip = () => setShoWNewbtmTooltip(true);
  const closeNewTtnTooltip = () => setShoWNewbtmTooltip(false);
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const showItemDetail = (values) => {
    setStorageItem("current_item_detail", values);
    navigate(INVENTORY.DETAIL_ROUTE, {
      state: { from: location, searchKeyword },
      // replace: true
    });
  };

  const dataTableData = {
    columns: [
      { accessor: "uuid", width: "10%" },
      {
        Header: "item name",
        accessor: "name",
        width: "15%",
        headerAlign: "left",
        hasSort: true,
        Cell: ({ row }) => {
          const canViewInventoryDetail = hasPermission(
            "view_inventoryitem",
            permissions?.inventory
          );
          const status = (
            // <Tooltip
            //   title="You don't have permission to do this"
            //   placement="top"
            //   followCursor
            //   open={showViewInventoryTooltip && !canViewInventoryDetail}
            //   onClose={closeViewInventoryTooltip}
            //   onOpen={openViewInventoryTooltip}
            // >
            <MDBox>
              {!canViewInventoryDetail && (
                // <MDBox mr={2}>
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
                // </MDBox>
              )}
              <MDTypography
                ml={!canViewInventoryDetail ? 1 : 0}
                variant="button"
                color="text"
                onClick={() => canViewInventoryDetail && showItemDetail(row?.original)}
              >
                <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color={!canViewInventoryDetail ? "text" : "info"}
                  fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                  textGradient
                >
                  {row.values.name}
                </MDTypography>
              </MDTypography>
            </MDBox>
            // </Tooltip>
          );

          return status;
        },
      },
      { Header: "description", accessor: "description", width: "15%" },
      {
        Header: "Daily Rate",
        accessor: "daily",
        headerAlign: "left",
        width: "10%",
        Cell: ({ value }) => {
          const status = (
            <MDBox
              display="flex"
              justifyContent="center"
              textAlign="center"
              width="4rem"
              sx={{ border: "2px ", borderRadius: "5px" }}
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(value || 0)}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Quantity",
        accessor: "owned",
        headerAlign: "left",
        width: "15%",
        Cell: ({ value }) => {
          const status = (
            <MDBox
              bgColor={value <= 0 ? "error" : "success"}
              display="flex"
              justifyContent="center"
              textAlign="center"
              width="4rem"
              sx={{ border: "2px ", borderRadius: "5px" }}
            >
              <MDTypography variant="button" fontWeight="regular" color="white">
                {value}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      { Header: "availability (local)", accessor: "available", headerAlign: "left", width: "10%" },
      {
        Header: "availability (globally)",
        headerAlign: "left",
        accessor: "global_available_qty",
        width: "10%",
      },
    ],
    rows: itemList,
  };

  const processRows = (data = []) => {
    const items = [];

    data.map((item) =>
      items.push({
        uuid: item.uuid,
        name: item.name,
        daily: `$${item?.price?.daily}`,
        description: item.description,
        item_type: item.item_type,
        owned: item.local_qty?.owned,
        available: item.local_qty?.available,
        on_rent: item.local_qty?.on_rent,
        in_repair: item.local_qty?.in_repair,
        global_available_qty: item.global_available_qty,
      })
    );

    return items;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setItemList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const handleOnChangeCategoryFilter = async (e, val) => {
    if (val !== null && val?.uuid !== "") {
      setIsFilterLoading(true);
      setCategoryFilterValue(val);
      const response = await getSubCategoryList(val?.uuid, "", 0);
      setSubCategoryListOptions(response?.data?.data);
      setIsFilterLoading(false);
    }

    setSubCategoryFilterValue(null);
  };

  const handleFilter = async (action) => {
    setIsLoading(true);
    switch (action) {
      case "apply-filter":
        proccessResponse(
          await httpRequest(
            "inventory-list",
            {},
            {
              searchKeyword,
              entriesPerPage,
              categoryId: categoryFilterValue?.uuid,
              subCategoryId: subCategoryFilterValue?.uuid,
            }
          )
        );
        break;

      case "remove-filter":
        setCategoryFilterValue(null);
        setSubCategoryFilterValue(null);
        proccessResponse(
          await httpRequest("inventory-list", {}, { searchKeyword, entriesPerPage })
        );
        break;
      default:
        break;
    }

    closeMenu();
    setIsLoading(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Autocomplete
        disablePortal
        value={categoryFilterValue}
        options={categoriesListRedux}
        onChange={(event, newValue) => {
          handleOnChangeCategoryFilter(event, newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 5, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Categories" />}
      />

      <Autocomplete
        disablePortal
        options={subCategoryListOptions || [{}]}
        value={subCategoryFilterValue}
        onChange={(event, newValue) => {
          setSubCategoryFilterValue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.id === value.id : option.id
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 9, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Sub-Category" />}
        loading={isFilterLoading}
      />

      <Divider sx={{
        margin: "0.5rem 0",
        opacity: "0.6"
      }} />
      <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
        <MDButton
          variant="gradient"
          size="medium"
          color="info"
          onClick={() => handleFilter("apply-filter")}
        >
          Apply Filter
        </MDButton>

        <MDButton
          variant="gradient"
          size="medium"
          color="error"
          onClick={() => handleFilter("remove-filter")}
        >
          Reset Filter
        </MDButton>
      </MDBox>
    </Menu>
  );

  const newItem = () => {
    const temp = { ...[], pageState: "NEW" };
    setStorageItem("current_item_detail", temp);
    navigate(INVENTORY.CREATE_ROUTE, {
      state: { from: location, searchKeyword, hasModal: true },
      // replace: true,
    });
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest(
        "inventory-list",
        {},
        {
          searchKeyword: val,
          entriesPerPage,
          categoryId: categoryFilterValue?.uuid,
          subCategoryId: subCategoryFilterValue?.uuid,
        }
      )
    );
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("inventory-list", null, { searchKeyword, entriesPerPage: val })
    );
    setIsLoading(false);
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);
    proccessResponse(await httpRequest("inventory-list", {}, { searchKeyword, entriesPerPage }));
    setIsLoading(false);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <Tooltip
            title="You don't have permission to do this"
            placement="top"
            followCursor
            open={showNewbtnTooltip && !hasPermission("add_inventoryitem", permissions?.inventory)}
            onClose={closeNewTtnTooltip}
            onOpen={openNewBtnTooltip}
          >
            <MDBox>
              <MDButton
                variant="gradient"
                color="info"
                onClick={newItem}
                disabled={!hasPermission("add_inventoryitem", permissions?.inventory)}
              >
                new item
              </MDButton>
            </MDBox>
          </Tooltip>
          <MDBox display="flex">
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color={darkMode === true ? "white" : "dark"}
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable
            table={dataTableData}
            noEndBorder
            details={showItemDetail}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handleSearch={handleSearchKeyword}
            handleChangeEntries={handleChangeEntries}
            handleSort={handleSort}
            hasNextPage={nextPageLink !== null}
            hasPrevPage={prevPageLink !== null}
            totalCount={totalRows}
            entriesPerPage={{ show: true, defaultValue: 25, entries: [10, 25, 50, 100] }}
            title="Inventory List"
            isLoading={isLoading}
            canRefresh
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

InventorySearch.defaultProps = {
  categoriesListRedux: [{}],
};

InventorySearch.propTypes = {
  categoriesListRedux: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
});

export default connect(mapStateToProps, null)(InventorySearch);
