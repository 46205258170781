/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { CompactPicker } from "react-color";
import svgToDataURL from "svg-to-dataurl";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDButton from "components/MDButton";
import { Menu } from "@mui/material";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import WhiteBg from "assets/images/bg/white.jpg";
import BgSalmple from "assets/images/reelmen/bgsample.avif";
import Rectangles from "assets/images/reelmen/rectangles.png";

const { getStorageItem, httpRequest, getResponseMsg } = WarehouseMgr;
const styles = {
  // backgroundColor: "gray",
  borderRadius: "0%",
  // borderStyle: "solid",
  width: "250px",
  height: "auto",
  // padding: "5px",
};
function SensorDrawing({ handleCloseModal, imgSrc, currentDrawing }) {
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [bgImmage, setBgImmage] = useState(imgSrc || Rectangles);
  const [penColorMenu, setPenColorMenu] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [color, setColor] = useState("black");

  let drawRef = "";

  const clear = () => {
    drawRef.clearCanvas();
    setBgImmage(Rectangles);
  };

  const trim = () => {
    setLoading(true);
    // console.log(drawRef.exportSvg());
    // drawRef
    //   .exportSvg()
    //   .then((data) => {
    //     // console.log(data);
    //     const dataUrl = svgToDataURL(data);
    //     // console.log(dataUrl);
    //     handleCloseModal("fe", dataUrl);
    //   })
    //   .catch((e) => {});

    drawRef
      .exportImage("jpg")
      .then((data) => {
        // console.log(currentDrawing);
        handleCloseModal(currentDrawing, data);
      })
      .catch((e) => {});
    // START HERE
    // const body = new FormData();

    // body.append("attachment_type", "signature");
    // body.append("attachment", drawRef.getTrimmedCanvas().toDataURL("image/png"));
    // body.append("action", "capture");

    // const responseDetails = await httpRequest("upload-attachment", body, {
    //   uuid: qcDetails?.uuid,
    // });
    // if (getResponseMsg(responseDetails) === "Success") {
    //   handleCloseModal("success", responseDetails?.data?.data?.order_status);
    // } else {
    //   handleCloseModal("failed");
    // }
    setLoading(false);
  };

  const openSlackBotMenu = (event) => {
    setPenColorMenu(event.currentTarget);
  };

  const closeSlackBotMenu = () => setPenColorMenu(null);

  const handleChange = (value) => {
    setColor(value.hex);
    closeSlackBotMenu();
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "bottom-start", horizontal: "bottom-start" }}
      transformOrigin={{ vertical: "bottom-start", horizontal: "bottom-start" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <CompactPicker color={color} onChangeComplete={handleChange} />
    </Menu>
  );

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium" color="secondary">
              Sensor Drawing
            </MDTypography>
          </MDBox>

          <MDBox my={1}>
            <MDBox ml={1}>
              <MDButton color="error" size="small" onClick={handleCloseModal}>
                close
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        {currentDrawing?.includes("internal") && (
          <MDBox mb={1} display="flex">
            <MDBox mx={1}>
              <MDTypography variant="h6" fontWeight="medium" color="secondary">
                Pen color:
              </MDTypography>
            </MDBox>
            <MDBox
              bgColor={color}
              sx={{
                width: "25px",
                height: "25px",
                borderStyle: "solid",
              }}
              onClick={openSlackBotMenu}
            />
            <MDBox>{renderMenu(penColorMenu, closeSlackBotMenu)}</MDBox>
          </MDBox>
        )}
        <MDBox
          sx={{ display: "block" }}
          // sx={{
          //   borderRadius: "50%",
          //   borderStyle: "solid",
          //   width: "200px",
          //   height: "200px",
          //   lineHeight: "200px",
          //   textAlign: "center",
          // }}
          // bgColor="info"
        >
          {/* <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: isMobile ? 300 : 500,
              height: isMobile ? 100 : 200,
              className: "sigCanvas",
            }}
            ref={(ref) => {
              drawRef = ref;
            }}
          /> */}
          {/* asdasd */}
          <ReactSketchCanvas
            style={styles}
            width="auto"
            height="auto"
            // sx={{
            //   borderRadius: "50%",
            //   borderStyle: "solid",
            //   width: "200px",
            //   height: "200px",
            //   lineHeight: "200px",
            //   textAlign: "center",
            // }}
            // backgroundImage="https://img.freepik.com/free-photo/hand-painted-watercolor-background-with-sky-clouds-shape_24972-1095.jpg?w=1060&t=st=1686710338~exp=1686710938~hmac=b587a1055d3bc67f74b970eeca2787d0c31e018cc3d9929a52528c6e4a67aeba"
            backgroundImage={bgImmage}
            strokeWidth={2}
            strokeColor={color || "black"}
            ref={(ref) => {
              drawRef = ref;
            }}
            // canvasColor="white"
            // exportWithBackgroundImage
            // withTimestamp
          />
        </MDBox>
        <MDBox mt={2} display="flex" justifyContent="flex-end">
          <MDBox>
            <MDButton color="info" onClick={clear}>
              reset
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton color="success" onClick={trim}>
              save
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          flexDirection="column"
          display="center"
          justifyContent="center"
          direction="column"
          alignItems={{ xs: "flex-start", sm: "center" }}
          textAlign="center"
        >
          {/* <img src={imgSrc} alt="signature" /> */}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

SensorDrawing.defaultProps = {
  // userDetail: {},
  imgSrc: "",
  currentDrawing: "",
};

SensorDrawing.propTypes = {
  // userDetail: PropTypes.objectOf(PropTypes.any),
  handleCloseModal: PropTypes.func.isRequired,
  imgSrc: PropTypes.string,
  currentDrawing: PropTypes.string,
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(SensorDrawing);
