import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import helper from "services/helpers";
import HTTPSServices from "services/https/HTTPSServices";
import helperActions from "services/redux/actions/helperActions";
import utils from "services/utils";

const { postRequest, putRequest, getRequest, patchRequest, deleteRequest } = HTTPSServices;
const { VENDORS } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  limitInputLength,
  getResponseMsg,
  getErrorMessage,
} = utils;
const { setConsignerList } = helperActions;
const { validateEmail } = helper;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  const API = `${VENDORS.API}${rest?.uuid}`;

  switch (action) {
    case "vendors-list":
      response = await getRequest(
        `${VENDORS.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&vendor_type=${rest.vendorType || ""}&status=${rest.vendorStatus || "active"}`
      );

      break;

    case "vendors-create":
      response = await postRequest(VENDORS.API, body);

      break;

    case "vendors-update":
      response = await putRequest(`${API}/`, body);

      break;

    case "vendors-detail":
      response = await getRequest(`${API}`);

      break;

    case "vendor-deactivate":
      response = await patchRequest(`${API}/`);

      break;

    case "vendor-delete":
      response = await deleteRequest(`${API}/`);

      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (
  sortType = "asc",
  id = "first_name",
  searchKeyword = "",
  entriesPerPage = 10,
  vendorType = "",
  vendorStatus = "active"
) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${VENDORS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${
        id === "full_name" ? "first_name" : id
      }&vendor_type=${vendorType}&status=${vendorStatus}`
    );
  } else {
    response = await getRequest(
      `${VENDORS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}&vendor_type=${vendorType}&status=${vendorStatus}`
    );
  }

  return response;
};

const getConsignerList = async () => {
  const response = await httpRequest("vendors-list", null, {
    searchKeyword: "",
    entriesPerPage: "0",
    vendorType: "consignment",
  });

  return response;
};

const VendorMgr = {
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  httpRequest,
  RESPONSE_SUCCESS,
  validateEmail,

  VENDORS,
  limitInputLength,
  getConsignerList,
  getResponseMsg,
  getErrorMessage,
  setConsignerList,
};

export default VendorMgr;
