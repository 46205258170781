/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";

import { useMaterialUIController } from "context";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

import OrderMgr from "layouts/Order/OrderMgr";
// Material Dashboard 2 PRO React themes

// Material Dashboard 2 PRO React Dark Mode themes

const { convertToCurrency } = InventoryMgr;

const { convertToDecimal } = OrderMgr;

function RentItems({ data }) {
  const { item_type, items } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const itemRentedColumns = [
    {
      Header: "Item name",
      accessor: "item_name",
      width: "15%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
            >
              <MDTypography variant="button" fontWeight="regular">
                {row.original?.item?.name || ""}
              </MDTypography>
            </MDBox>
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: "Daily Rate",
      accessor: "daily_price",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {/* TODO:  Change to Daily price  */}
                {convertToCurrency(row?.original?.price)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    { Header: "Quantity", accessor: "quantity", headerAlign: "left", width: "10%" },
    {
      Header: "Discount Rate",
      accessor: "discount_rate",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {Number(convertToDecimal(row?.original?.discount_rate)).toFixed(0)} %
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "Discounted price",
      accessor: "discounted_price",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.discounted_price)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "Total Price",
      accessor: "total_price",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.total_price)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    { Header: "comment", accessor: "comments", headerAlign: "left", width: "20%" },
  ];

  const dataTableData = {
    columns: itemRentedColumns,
    rows: items || [],
  };

  return (
    <MDBox>
      <MDBox>
        <MDBox pl={2} pb={2} display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" color="info">
              Items to be rented
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" px={4}>
          <Grid container spacing={1}>
            <DataTable
              height="20rem"
              table={dataTableData}
              entriesPerPage={{
                show: false,
                defaultValue: 999,
                entries: [10, 25, 50, 100, 500, 1000, 5000],
              }}
              // showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              hiddenCols={["file_url"]}
              totals={{
                show: item_type?.value === "kit",
                totalRows: [
                  "Total",
                  `${convertToCurrency(data?.dailyTotal)}`,
                  `${convertToCurrency(data?.weeklyTotal)}`,
                  `${convertToCurrency(data?.monthlyTotal)}`,
                ],
              }}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

RentItems.defaultProps = {
  data: [{}],
};

RentItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default RentItems;
