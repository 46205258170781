/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import { useMaterialUIController } from "context";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SuccessScan from "assets/sounds/success.mp3";
import FailedScan from "assets/sounds/trimmedErrorSouund.mp3";
import Done from "assets/sounds/tada.mp3";

import CustomModal from "components/CustomModal";
import TabPanel from "components/CustomTabPanel";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import RentItemTable from "./RentItemTable";
import SubItemListModal from "./Modals/SubItemListModal";

const { hasPermission, getStorageItem } = InventoryMgr;
const { httpRequest, getResponseMsg, WAREHOUSE_CHECKIN, setStorageItem } = WarehouseMgr;

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RentItems({ data, loadDetails }) {
  const navigate = useNavigate();
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const { checked_in, pending_items } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [playSuccessSound] = useSound(SuccessScan);
  const [playFailedSound] = useSound(FailedScan);
  const [playDoneSound] = useSound(Done);

  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [subitemDetail, setSubitemDetail] = useState({});
  const [showItemList, setShowItemList] = useState(false);
  const [search, setSearch] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Failed");
  // const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item Checkin");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [confirmationDialogue, setConfirmationDialogue] = useState(false);

  const closeErrorSB = () => setErrorSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openSuccessSB = () => setSuccessSB(true);

  const handleOpenConfirmationDialogue = () => {
    setShowItemList(false);
    setConfirmationDialogue(true);
  };

  const handleCloseConfirmationDialogue = () => {
    setConfirmationDialogue(false);
    navigate(WAREHOUSE_CHECKIN?.LIST_ROUTE);
  };

  const handleOpenItemlistModal = (value) => {
    setSubitemDetail(value);
    setShowItemList(true);
  };

  const handleCloseItemlistModal = () => {
    setShowItemList(false);
  };

  const handleAddItem = (obj) => {
    if (obj?.uuid !== undefined) {
      // pending_items[subitemDetail?.index].subitems.push(obj);
      // setData({ ...data, items });

      if (obj?.order_status === "ready_to_invoice") {
        playDoneSound();
        handleOpenConfirmationDialogue();
      } else {
        playSuccessSound();
        loadDetails();
      }
    }
  };

  const dataTableDataPending = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox
              direction="column"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="button" color="error" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : checked_in || [],
  };

  const dataTableDataStaged = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox
              direction="column"
              display="flex "
              justifyContent="space-between"
              alignItems="center"
            >
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox display="flex" justifyContent="space-between">
                <MDTypography variant="button" color="success" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : checked_in || [],
  };

  const dataTableDataMobilePending = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox display="flex " justifyContent="space-between" alignItems="center">
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox>
                <MDTypography variant="overline" color="error" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : checked_in || [],
  };

  const dataTableDataMobileStaged = {
    columns: [
      {
        Header: "Item name",
        accessor: "item_name",
        width: "auto",
        headerAlign: "left",
        type: "collapse",
        Cell: ({ row }) => {
          const canViewOrderDetail = hasPermission("view_inventoryitem", permissions?.inventory);

          const status = (
            <MDBox display="flex " justifyContent="space-between" alignItems="center">
              {!canViewOrderDetail && (
                <Tooltip title="You don't have permission to View Details" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
              )}
              <MDBox>
                <MDTypography variant="overline" color="success" fontWeight="medium">
                  {row.original?.item?.name || ""}
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
    ],
    rows: tabValue === 0 ? pending_items || [] : checked_in || [],
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const showItemDetail = (values) => {
    setStorageItem("current_checkin_detail", values);
    navigate(WAREHOUSE_CHECKIN.LIST_ROUTE);
    navigate(WAREHOUSE_CHECKIN.DETAIL_ROUTE, { state: { from: WAREHOUSE_CHECKIN?.LIST_ROUTE } });
  };

  const searchBarcode = async (value = "") => {
    setIsLoading(true);
    const response = await httpRequest("check-in", {
      order: data?.uuid || "",
      barcode_id: value || "",
    });
    if (getResponseMsg(response) === "Success") {
      setSuccessMsg(
        `${
          response.data.data?.barcode_id ||
          response.data.data?.serial_number ||
          response.data.data?.name
        } is now ${response.data.data?.status?.name}.`
      );
      openSuccessSB();
      handleAddItem(response.data.data);
    } else {
      let responseErrMsg = "";
      Object.entries(response?.data).map((item) => {
        responseErrMsg = `${item[1]}`;
        return 0;
      });
      if (responseErrMsg === "Barcode ID does not exist.") {
        playFailedSound();
        setErrMsg(`${responseErrMsg}`);
        openErrorSB();
      } else {
        // setErrMsg(`${responseErrMsg} Checking for orders with this barcode.`);
        // openErrorSB();

        const responseSearch = await httpRequest(
          "warehouse-checkin-list",
          {},
          {
            searchKeyword: value,
            entriesPerPage: 1,
            // status: statusFilterList,
          }
        );
        if (getResponseMsg(responseSearch) === "Success") {
          if (responseSearch.data.data?.results[0]?.uuid !== undefined) {
            setSuccessMsg("Redirecting you to another order.");
            openSuccessSB();
            const responseDetails = await httpRequest("warehouse-checkin-detail", null, {
              uuid: responseSearch.data.data?.results[0]?.uuid,
            });
            if (getResponseMsg(responseDetails) === "Success") {
              // console.log(responseDetails.data.data);
              showItemDetail(responseDetails.data.data);
            }
          } else {
            playFailedSound();
            setErrMsg(`Barcode ${value} is not associated with any order.`);
            openErrorSB();
          }
        }
      }
    }
    setIsLoading(false);
    setSearch("");
  };

  return (
    <MDBox>
      <MDBox sx={{ width: "100%" }}>
        <MDBox sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
            <Tab label="Pending" {...a11yProps(0)} />
            <Tab label="Checked-in" {...a11yProps(1)} />
          </Tabs>
        </MDBox>
        {isMobile && (
          <MDBox
            direction="column"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox fullWidth width="100%" mt={1} mb={2}>
              <MDBox>
                <MDInput
                  autoFocus
                  placeholder="Scan Barcode Here..."
                  value={search}
                  // size="small"
                  type="search"
                  fullWidth
                  width="30rem"
                  onChange={async ({ currentTarget }) => {
                    // setSearch(currentTarget.value);
                    searchBarcode(currentTarget.value);
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        {!isMobile && (
          <MDBox
            direction="column"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox fullWidth width="100%" mt={1} mb={2}>
              <MDBox>
                <MDInput
                  autoFocus
                  placeholder="Scan Barcode Here..."
                  value={search}
                  // size="small"
                  type="search"
                  fullWidth
                  width="30rem"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      // onSearchChange(e.target.value);
                      searchBarcode(e.target.value);
                      // handleSearchKeywordChange(e.target);
                      // setShowQrCodeScan(false);
                      // setSearch("");
                    }
                  }}
                  onChange={async ({ currentTarget }) => {
                    setSearch(currentTarget.value);
                  }}
                />
              </MDBox>
            </MDBox>
          </MDBox>
        )}
        {/* )} */}

        <MDBox ml={2}>
          <MDTypography variant="h6" color="info">
            Items to be rented
          </MDTypography>
        </MDBox>
        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={0}>
            <RentItemTable
              // height="30rem"
              table={isMobile ? dataTableDataMobilePending : dataTableDataPending}
              entriesPerPage={false}
              // showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              hasCollapse
              loadDetails={loadDetails}
              handleOpenItemlistModal={handleOpenItemlistModal}
              tabName="pending"
            />
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Grid container spacing={0}>
            <RentItemTable
              // height="30rem"
              table={isMobile ? dataTableDataMobileStaged : dataTableDataStaged}
              entriesPerPage={false}
              // showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              hasCollapse
              loadDetails={loadDetails}
              handleOpenItemlistModal={handleOpenItemlistModal}
              tabName="staged"
            />
          </Grid>
        </TabPanel>
      </MDBox>
      {showItemList && (
        <CustomModal
          showModal={showItemList}
          handleHideModal={handleCloseItemlistModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "98%",
            width: "98%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <SubItemListModal
            handleClose={handleCloseItemlistModal}
            handleAddItem={handleAddItem}
            subitemData={subitemDetail}
            itemUuid={subitemDetail?.item?.uuid || ""}
          />
        </CustomModal>
      )}
      {confirmationDialogue && (
        <Dialog
          open={confirmationDialogue}
          onClose={handleCloseConfirmationDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Order is now ready to invoice</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All items has been Checked-in. Order is now ready to invoice.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseConfirmationDialogue} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

RentItems.defaultProps = {
  data: [{}],
};

RentItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
  setData: PropTypes.func.isRequired,
  loadDetails: PropTypes.func.isRequired,
};

export default RentItems;
