/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import FormField from "layouts/admin/ManageUsers/new-user/components/FormField";
import UsersMgr from "layouts/admin/ManageUsers/UsersMgr";

const { getStorageItem, httpRequest, getResponseMsg } = UsersMgr;

function OtherInfo({ formData }) {
  const locationList = JSON.parse(getStorageItem("locations_list"));
  const userDetail = JSON.parse(getStorageItem("current_user_detail"));

  const { formField, values } = formData;

  const { location, warehouse, username, password } = formField;
  const {
    location: locationV,
    warehouse: warehouseV,
    username: usernameV,
    password: passwordV,
  } = values;

  const [locationValue, setLocationValue] = useState(locationV);
  const [warehouseValue, setWarehouseValue] = useState(warehouseV);
  const [usernameValue, setUsernameValue] = useState(usernameV);
  const [passwordValue, setPasswordValue] = useState(passwordV);

  const [warehouseList, setWareouseList] = useState(null);
  // const [passwordValue, setInputZip] = useState(zipV);

  const handelChangeLocation = async (val) => {
    setLocationValue(val);
    values.location = val;

    const response = await httpRequest("warehouse-list", null, { uuid: val?.id });

    if (getResponseMsg(response) === "Success") {
      setWarehouseValue(null);
      values.warehouse = null;

      setWareouseList(response?.data?.data?.results);
    }
  };

  useEffect(() => {
    if (userDetail?.pageState === "NEW") {
      setLocationValue(null);
      values.location = null;

      setWarehouseValue(null);
      values.warehouse = null;

      setUsernameValue("");
      values.username = "";

      setPasswordValue(null);
      values.password = "";
    }
  }, []);

  return (
    <MDBox mx={2} my={0}>
      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="bold">
          Other Information
        </MDTypography>
        <MDTypography variant="caption" fontWeight="regular" color="warning">
          * = Required Fields
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormField
              type={username.type}
              label={username.label}
              name={username.name}
              value={usernameValue}
              placeholder={username.placeholder}
              onChange={(e) => {
                setUsernameValue(e.target.value);
                values.username = e.target.value;
              }}
              // error={errors.username && touched.username}
              // success={locationV.length > 0 && !errors.username}
            />
          </Grid>
          <Grid item xs={6}>
            <FormField
              type={password.type}
              label={password.label}
              name={password.name}
              value={passwordValue}
              placeholder={password.placeholder}
              onChange={(e) => {
                setPasswordValue(e.target.value);
                values.password = e.target.value;
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              options={locationList || ""}
              value={locationValue}
              name={location.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={location.label} />
              )}
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  handelChangeLocation(newValue);
                }
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              disablePortal
              options={warehouseList || ""}
              value={warehouseValue}
              name={warehouse.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={warehouse.label} />
              )}
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setWarehouseValue(newValue);
                  values.warehouse = newValue;
                }
              }}
              disabled={locationValue === null}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for OtherInfo
OtherInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default OtherInfo;
