export const SIGN_IN_FAILED = "Invalid Credentials.";
export const SIGN_IN_SUCCESS = "Signed-in Successfully!";
export const PASSWORD_NOT_MATCH = "Password does not match.";
export const PASSWORD_EMPTY = "Password should not be empty.";
export const PASSWORD_CHANGE_SUCCESS = "Changed Password Successfully!";
export const PASSWORD_CHANGE_FAILED = "Failed to change password.";
export const SIGN_UP_REDIRECT = "You can now log-in using your new password.";
export const EMAIL_SENT = "Reset link has been sent to your email.";
export const MISSING_FIELDS = "Required fields should not be empty";

// HTTPS responses
export const SUCCESS = "Sucess!"; // 200
export const NO_SERVER_RESPONSE = "No server Response"; // 400
