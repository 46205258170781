/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";

import { RESPONSE_SUCCESS } from "services/context/response";
import { useMaterialUIController } from "context";

import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import {
  Autocomplete,
  Divider,
  Grid,
  Icon,
  IconButton,
  Menu,
  Modal,
  TextField,
  Tooltip,
} from "@mui/material";
import CategoryMgr from "layouts/admin/Categories/CategoryMgr";
import MDSnackbar from "components/MDSnackbar";
import DraggableDialog from "components/DragableDialog";
import MDTypography from "components/MDTypography";

import SubcategoriesSearch from "../Subcategories/SubcategoriesSearch";
import CategoriesNew from "./CategoriesNew";

const { httpRequest, getNextPage, getPrevPage, getSort, setStorageItem, setCategoryReduxList } =
  CategoryMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "29rem",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

function CategoriesSearch({ setCategoriesReduxList }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  // const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);

  const [categoryList, setCategoryList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [prevSortId, setPrevSortId] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [itemNameToBeDeleted, setItemNameToBeDeleted] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [errorSB, setErrorSB] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showSubCategory, setShowSubCategory] = useState(false);
  const [showAddNewCatModal, setShowAddNewCatModal] = useState(false);
  const [menu, setMenu] = useState(null);
  const [categoryStatus, setCategoryStatus] = useState({ name: "Active", value: "active" });

  const categoryStatusOption = [
    { name: "Active", value: "active" },
    { name: "Inactive", value: "inactive" },
    { name: "All Status", value: "all" },
  ];

  const controllerRequest = new AbortController();

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const processRows = (data = []) => {
    const custlist = [];

    data.map((item) =>
      custlist.push({
        uuid: item.uuid,
        name: item.name,
        total_items: item.total_items,
        status: item.status,
      })
    );
    return custlist;
  };

  const proccessResponse = (response) => {
    if (response.status === RESPONSE_SUCCESS) {
      setCategoryList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const processRedux = (response) => {
    if (response.status === RESPONSE_SUCCESS) {
      setCategoriesReduxList(processRows(response?.data?.data?.results));
    }
  };

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);
  const handleShowSubCategory = () => setShowSubCategory(true);
  const handleHideSubCategory = () => setShowSubCategory(false);
  const handleShowAddNewCatModal = () => setShowAddNewCatModal(true);

  const handleHideAddNewCatModal = async (action = "", rest = {}) => {
    setShowAddNewCatModal(false);
    switch (action) {
      case "save":
        setIsLoading(true);
        proccessResponse(
          await httpRequest("category-list", null, {
            searchKeyword,
            entriesPerPage,
            status: categoryStatus?.value,
          })
        );
        setSuccessMsg(rest?.msg);
        openSuccessSB();
        handleShowSubCategory();
        break;
      default:
        break;
    }
  };

  // const hasPermission = (permissionLookUp = [], permission = {}) =>
  //   permissionLookUp.findIndex((x) => x.value === permission?.value);

  useEffect(async () => {
    setIsLoading(true);
    proccessResponse(
      await httpRequest("category-list", null, {
        searchKeyword,
        entriesPerPage,
        status: categoryStatus?.value,
      })
    );
    // processRedux(await httpRequest("category-list", null, { searchKeyword, entriesPerPage: 100 }));
    return () => {
      controllerRequest.abort();
    };
  }, []);

  const editCategory = (row) => {
    const tempData = { ...row, pageState: "EDIT" };
    setStorageItem("current_categories_detail", tempData);
    handleShowAddNewCatModal();
    // handleHideSubCategory();
  };

  const newCategory = () => {
    const data = { ...[], pageState: "NEW" };
    setStorageItem("current_categories_detail", data);
    handleShowAddNewCatModal();
    handleHideSubCategory();
  };

  const deleteCategory = async () => {
    setIsLoading(true);
    handleHideSubCategory();
    handleCloseDeleteDialog();
    const response = await httpRequest("category-delete", null, {
      uuid: itemNameToBeDeleted?.uuid,
    });
    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      setSuccessMsg(`Deleted ${itemNameToBeDeleted?.name} successfully`);
      openSuccessSB();
      proccessResponse(
        await httpRequest("category-list", null, {
          searchKeyword,
          entriesPerPage,
          status: categoryStatus?.value,
        })
      );
      processRedux(
        await httpRequest("category-list", null, { searchKeyword, entriesPerPage: 100 })
      );
    } else {
      openErrorSB();
      setErrMsg(response.data.data);
    }
    setIsLoading(false);
  };

  // eslint-disable-next-line no-unused-vars
  const showCategoryDetail = (values) => {
    setStorageItem("current_categories_detail", values);
    handleShowSubCategory();
  };

  const dataTableData = {
    columns: [
      {
        Header: "category name",
        accessor: "name",
        width: "20%",
        hasSort: false,
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDTypography
              variant="button"
              color="text"
              onClick={() => {
                if (row?.original?.status === "active") {
                  showCategoryDetail(row.original);
                }
                // console.log(row);
              }}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color={row?.original?.status === "active" ? "info" : "text"}
                fontWeight="medium"
                textGradient
              >
                {row.values.name}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      { Header: "Associated Items", accessor: "total_items", headerAlign: "left", width: "10%" },
      {
        Header: "action",
        accessor: "",
        headerAlign: "left",
        width: "15%",
        Cell: ({ row }) => {
          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <IconButton
                size="small"
                aria-label="edit"
                color="info"
                onClick={() => editCategory(row?.original)}
                disabled={row?.original?.status !== "active"}
              >
                <Tooltip title="Edit Category" placement="top">
                  <Icon fontSize="small">edit</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                aria-label="edit"
                color="error"
                onClick={() => {
                  handleOpenDeleteDialog();
                  setItemNameToBeDeleted(row?.original);
                }}
                disabled={row?.original?.status !== "active"}
              >
                <Tooltip title="Delete Category" placement="top">
                  <Icon fontSize="small">delete</Icon>
                </Tooltip>
              </IconButton>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: categoryList,
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("category-list", null, {
        searchKeyword: val,
        entriesPerPage,
        status: categoryStatus?.value,
      })
    );
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("category-list", null, {
        searchKeyword,
        entriesPerPage: val,
        status: categoryStatus?.value,
      })
    );
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    handleHideSubCategory();
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    handleHideSubCategory();
  };

  const handleSort = async (id) => {
    setIsLoading(true);
    let sortId = id;
    let sortType = "asc";

    if (id === "full_name") {
      sortId = "first_name";
    }
    if (prevSortId === sortId && sortType === "asc") {
      sortType = "desc";
    } else {
      sortType = "asc";
    }

    setPrevSortId(sortId);
    proccessResponse(await getSort(sortType, sortId, searchKeyword, entriesPerPage));
  };

  const handleFilter = async (action) => {
    setIsLoading(true);
    switch (action) {
      case "apply-filter":
        proccessResponse(
          await httpRequest("category-list", null, {
            searchKeyword,
            entriesPerPage,
            status: categoryStatus?.value,
          })
        );
        break;

      case "remove-filter":
        setCategoryStatus({ name: "Active", value: "active" });
        proccessResponse(
          await httpRequest(
            "category-list",
            {},
            { searchKeyword, entriesPerPage, status: "active" }
          )
        );
        break;
      default:
        break;
    }

    closeMenu();
    setIsLoading(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MDBox sx={{ width: "100%", height: "100%", lineHeight: 1, p: 1 }}>
        <Autocomplete
          disablePortal
          value={categoryStatus}
          options={categoryStatusOption}
          onChange={(event, newValue) => {
            setCategoryStatus(newValue);
          }}
          isOptionEqualToValue={(option, value) =>
            value !== "" ? option.name === value.name : option.name
          }
          getOptionLabel={(option) => (option ? option.name : "")}
          sx={{ width: 300, height: "100%", lineHeight: 5, m: 1 }}
          renderInput={(params) => <TextField {...params} label="Status" />}
        />
      </MDBox>

      <Divider sx={{
        margin: "0.5rem 0",
        opacity: "0.6"
      }} />
      <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
        <MDBox mx={1}>
          <MDButton
            variant="gradient"
            size="medium"
            color="error"
            onClick={() => handleFilter("remove-filter")}
          >
            Reset Filter
          </MDButton>
        </MDBox>
        <MDBox mx={1}>
          <MDButton
            variant="gradient"
            size="medium"
            color="info"
            onClick={() => handleFilter("apply-filter")}
          >
            Apply Filter
          </MDButton>
        </MDBox>
      </MDBox>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDButton variant="gradient" color="info" onClick={newCategory}>
            new category
          </MDButton>

          <MDBox display="flex">
            <MDButton
              variant={menu ? "contained" : "outlined"}
              color={darkMode === true ? "white" : "dark"}
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
            {renderMenu}
          </MDBox>
        </MDBox>
        <Grid container spacing={1}>
          <Grid item xs={showSubCategory === true ? 6 : 12}>
            <Card>
              <DataTable
                table={dataTableData}
                noEndBorder
                entriesPerPage={{ show: true }}
                handleNextPage={handleNextPage}
                handlePrevPage={handlePrevPage}
                handleSearch={handleSearchKeyword}
                handleSort={handleSort}
                handleChangeEntries={handleChangeEntries}
                hasNextPage={nextPageLink !== null}
                hasPrevPage={prevPageLink !== null}
                totalCount={totalRows}
                canSearch
                title="Category List"
                isLoading={isLoading}
                canRefresh
              />
            </Card>
          </Grid>
          {showSubCategory && (
            <Grid item xs={6}>
              <Card>
                <SubcategoriesSearch handleClose={handleHideSubCategory} />
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />

      {showAddNewCatModal && (
        <Modal
          keepMounted
          open={showAddNewCatModal}
          onClose={handleHideAddNewCatModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
            <CategoriesNew handleClose={handleHideAddNewCatModal} />
          </MDBox>
        </Modal>
      )}

      {showDeleteDialog && (
        <DraggableDialog
          title="Delete Category"
          description={`Are you sure you want to delete ${itemNameToBeDeleted?.name}?`}
          buttonDesc="Delete"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={deleteCategory}
          fullWidth={false}
        />
      )}

      <MDSnackbar
        color="success"
        icon="check"
        title="Item List"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title="Item List"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </DashboardLayout>
  );
}

CategoriesSearch.propTypes = {
  setCategoriesReduxList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setCategoriesReduxList: (data) => dispatch(setCategoryReduxList(data)),
});

export default connect(null, mapDispatchToProps)(CategoriesSearch);
