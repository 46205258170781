/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";

import { Grid } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DataTable from "examples/Tables/DataTable";
// import DataTable from "components/Tables/DataTable";

import { useMaterialUIController } from "context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

const { INVENTORY, setStorageItem } = InventoryMgr;

function SubItems({ data }) {
  const { consigned_items } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const showItemDetail = (values) => {
    setStorageItem("current_item_detail", values);
    navigate(INVENTORY.DETAIL_ROUTE, { state: { from: location }, replace: true });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Name",
        accessor: "",
        width: "20%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDTypography
              variant="button"
              color="text"
              onClick={() => {
                showItemDetail(row.original.parent_item);
              }}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {row.original?.parent_item?.name || ""}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      { Header: "Barcode", accessor: "barcode_id", headerAlign: "left", width: "20%" },
      { Header: "Serial Number", accessor: "serial_number", headerAlign: "left", width: "20%" },
      { Header: "status", accessor: "status", headerAlign: "left", width: "10%" },
    ],
    rows: consigned_items || [],
  };

  return (
    <MDBox mx={1}>
      <MDBox>
        <MDBox px={2} pb={2} display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" color="info">
              Consigned Items
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" px={4}>
          <Grid container spacing={1}>
            <DataTable
              height="20rem"
              table={dataTableData}
              entriesPerPage={false}
              isSorted={false}
              canSearch={false}
              hiddenCols={["file_url"]}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

SubItems.defaultProps = {
  data: [{}],
};

SubItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default SubItems;
