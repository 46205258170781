/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { CircularProgress, Icon, Tooltip } from "@mui/material";
import { connect, useSelector } from "react-redux";
import DashboardMgr from "layouts/dashboards//DashboardMgr";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

const {
  httpRequest,
  getResponseMsg,
  setStorageItem,
  ORDERS,
  getNextPage,
  ellipsis,
  _DASHBOARD,
  hasPermission,
} = DashboardMgr;

function Notifications({ onClose }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const location = useLocation();
  const navigate = useNavigate();

  const [notificationData, setNotificationData] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");

  const [bgLoading, setBgLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to load");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const processNotificationResult = (
    data = [
      {
        id: 1,
        content: {
          identifier: "R1000003",
          status: "Reservation",
          customer_name: "customer1 user",
          summary: "Lorem ipsum dolor sit amet 1",
          deficiency: ["missing paperworks", "unconfirmed"],
        },
        is_read: false,
        remarks: "created",
      },
    ]
  ) => {
    let tmpData = [];
    data.map((notif) => {
      tmpData = tmpData.concat({
        id: notif?.id,
        is_read: notif?.is_read,
        // remarks: notif?.remarks,
        uuid: notif?.content?.uuid,
        identifier: notif?.content?.identifier,
        customer_name: notif?.remarks?.customer_name,
        description: ellipsis(notif?.remarks?.description),
        deficiency: notif?.remarks?.deficiency.join(", "),
        status: notif?.remarks?.status,
      });
      return 0;
    });

    return tmpData;
  };

  const showOrderDetail = async (values) => {
    // console.log(values);
    setBgLoading(true);
    onClose();
    setStorageItem("current_order_detail", values);
    const response = await httpRequest("orders-detail", {
      uuid: values?.uuid,
      id: values?.id,
    });
    if (getResponseMsg(response) === "Success") {
      setBgLoading(false);
      if (location?.pathname !== "/orders/details/") {
        navigate(ORDERS.DETAIL_ROUTE, { state: { from: _DASHBOARD?.HOMEPAGE } });
      } else {
        // window.location.reload(true);
        navigate(ORDERS.LIST_ROUTE);
        navigate(ORDERS.DETAIL_ROUTE, { state: { from: _DASHBOARD?.HOMEPAGE } });
      }
    }
  };

  const dataTableData = {
    columns: [
      {
        Header: "Order number",
        accessor: "identifier",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewInventoryDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox>
              <MDBox
                ml={!canViewInventoryDetail ? 1 : 0}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
              >
                <MDBox mr={2}>
                  {!canViewInventoryDetail && (
                    // <MDBox mr={2}>
                    <Tooltip title="You don't have permission to View Orders" placement="top">
                      <Icon>error_outlined</Icon>
                    </Tooltip>
                    // </MDBox>
                  )}
                  <Icon
                    color={row?.original?.is_read === true ? "inherit" : "info"}
                    fontSize="medium"
                  >
                    {row?.original?.is_read === true ? "drafts" : "markunread"}
                  </Icon>
                </MDBox>
                <MDBox>
                  <MDTypography
                    variant="button"
                    color="text"
                    onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
                  >
                    <MDTypography
                      component={Link}
                      to="#"
                      variant="button"
                      color={!canViewInventoryDetail ? "text" : "info"}
                      fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                      textGradient
                    >
                      {/* {row.values.name} */}
                      {value}
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
      { Header: "customer name", accessor: "customer_name", headerAlign: "left", width: "15%" },
      { Header: "Job summary", accessor: "description", headerAlign: "left", width: "20%" },
      { Header: "Deficiency ", accessor: "deficiency", headerAlign: "left", width: "30%" },
      // { Header: "Status", accessor: "status" },
    ],
    rows: notificationData || [],
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setNotificationData(
        notificationData.concat(processNotificationResult(response?.data?.data?.results))
      );
      setNextPageLink(response.data.data?.next);
    }
  };

  const handleNextPage = async () => {
    setLoadMore(true);
    proccessResponse(await getNextPage(nextPageLink));
    setLoadMore(false);
  };

  const loadNotifications = async () => {
    setIsLoading(true);
    const response = await httpRequest("get-notification", { entriesPerPage: 13 });
    if (getResponseMsg(response) === "Success") {
      setNotificationData(processNotificationResult(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
    } else {
      setErrMsg("Failed to load Notifications");
      openErrorSB(true);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  return (
    <MDBox>
      {/* {bgLoading && <SimpleBackdrop />} */}
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <MDBox display="flex">
            <MDTypography>
              <Icon color="inherit" fontSize="medium">
                notifications
              </Icon>
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium">
              Notifications
            </MDTypography>
          </MDBox>
        </MDBox>

        <MDBox>
          <MDButton size="large" aria-label="close" color="error" onClick={onClose}>
            close
          </MDButton>
        </MDBox>
      </MDBox>
      {isLoading ? (
        <MDBox mt="30%" display="flex" direction="row" justifyContent="center" alignItems="center">
          <CircularProgress />
        </MDBox>
      ) : (
        <MDBox>
          <DataTable
            table={dataTableData}
            entriesPerPage={{ show: false, defaultValue: 9999 }}
            showTotalEntries={false}
            isSorted={false}
            canSearch={false}
            hiddenCols={["file_url"]}
            isLoading={isLoading}
          />
        </MDBox>
      )}

      {loadMore ? (
        <MDBox display="flex" direction="row" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </MDBox>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>
          {!isLoading && nextPageLink !== null && (
            <MDButton variant="text" size="small" onClick={() => handleNextPage()}>
              <MDTypography size="small" variant="body2" fontWeight="medium">
                Show more...
              </MDTypography>
            </MDButton>
          )}
        </MDBox>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title="Sign-in"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}
Notifications.defaultProps = {};

Notifications.propTypes = {
  onClose: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(Notifications);
