/* eslint-disable no-unused-vars */
import { useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/Customers/new-user/components/FormField";
import utils from "services/utils";

const { formatPhoneNumber } = utils;

function Contact({ formData }) {
    const { formField, values, errors, touched } = formData;
    const { firstName, lastName, email, mobile, telephone, fax, other } = formField;
    const {
        firstName: firstNameV,
        lastName: lastNameV,
        email: emailV,
        mobile: mobileV,
        telephone: telephoneV,
        fax: faxV,
        other: otherV,
    } = values;

    const [inputMobile, setInputMobile] = useState(mobileV);
    const [inputTelephone, setInputTelephone] = useState(telephoneV);
    const [inputFax, setInputFax] = useState(faxV);
    const [inputOther, setInputOther] = useState(otherV);

    const handleInput = (e) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);

        switch (e.target.name) {
            case "mobile":
                setInputMobile(formattedPhoneNumber);
                values.mobile = formattedPhoneNumber;
                break;
            case "telephone":
                setInputTelephone(formattedPhoneNumber);
                values.telephone = formattedPhoneNumber;
                break;
            case "fax":
                setInputFax(formattedPhoneNumber);
                values.fax = formattedPhoneNumber;
                break;
            case "other":
                setInputOther(formattedPhoneNumber);
                values.other = formattedPhoneNumber;
                break;
            default:
                break;
        }
    };

    return (
        <MDBox>
            <MDBox width="100%" display="flex" justifyContent="space-between">
                <MDTypography variant="h6" fontWeight="bold">
                    Contact Information
                </MDTypography>
            </MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={firstName.type}
                            label={firstName.label}
                            name={firstName.name}
                            value={firstNameV}
                            placeholder={firstName.placeholder}
                            error={errors.firstName && touched.firstName}
                            // success={firstNameV.length > 0 && !errors.firstName}
                            // onBlur={(e) => handleOnBlur(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={lastName.type}
                            label={lastName.label}
                            name={lastName.name}
                            value={lastNameV}
                            placeholder={lastName.placeholder}
                            error={errors.lastName && touched.lastName}
                            // success={lastNameV.length > 0 && !errors.lastName}
                            // onBlur={(e) => handleOnBlur(e)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={email.type}
                            label={email.label}
                            name={email.name}
                            value={emailV}
                            placeholder={email.placeholder}
                            // error={errors.email && touched.email}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={mobile.type}
                            label={mobile.label}
                            name={mobile.name}
                            value={inputMobile}
                            placeholder="(123) 123-5075"
                            onChange={(e) => handleInput(e)}
                            // error={errors.mobile && touched.mobile}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={telephone.type}
                            label={telephone.label}
                            name={telephone.name}
                            value={inputTelephone}
                            placeholder="(123) 123-5075"
                            onChange={(e) => handleInput(e)}
                            // error={errors.telephone && touched.telephone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={fax.type}
                            label={fax.label}
                            name={fax.name}
                            value={inputFax}
                            onChange={(e) => handleInput(e)}
                            placeholder="(123) 123-5075"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <FormField
                            type={other.type}
                            label={other.label}
                            name={other.name}
                            value={inputOther}
                            onChange={(e) => handleInput(e)}
                            placeholder="(123) 123-5075"
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </MDBox>
    );
}

// typechecking props for Socials
Contact.propTypes = {
    formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Contact;
