import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import helper from "services/helpers";
import HTTPSServices from "services/https/HTTPSServices";
import helperActions from "services/redux/actions/helperActions";
import utils from "services/utils";

const { postRequest, putRequest, getRequest, patchRequest, deleteRequest } = HTTPSServices;
const { MANAGE_LOCATIONS } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  limitInputLength,
  getResponseMsg,
  getErrorMessage,
  preventNegativeInput,
  formatPhoneNumber,
  formatZip,
} = utils;
const { setLocationList } = helperActions;
const { validateEmail } = helper;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  const API = `${MANAGE_LOCATIONS.API}${rest?.uuid}`;

  switch (action) {
    case "locations-list":
      response = await getRequest(
        `${MANAGE_LOCATIONS.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }`
      );

      break;

    case "locations-list-all-active":
      response = await getRequest(`${MANAGE_LOCATIONS.API}?status=active`);

      break;

    case "locations-create":
      response = await postRequest(MANAGE_LOCATIONS.API, body);

      break;

    case "locations-update":
      response = await putRequest(`${API}/`, body);

      break;

    case "locations-detail":
      response = await getRequest(`${API}`);

      break;

    case "locations-deactivate":
      response = await patchRequest(`${API}/deactivate/`);

      break;

    case "locations-delete":
      response = await deleteRequest(`${API}/`);

      break;

    case "timezone-list":
      response = await getRequest("/helpers/timezones/");

      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (
  sortType = "asc",
  id = "first_name",
  searchKeyword = "",
  entriesPerPage = 10
  // vendorType = "",
  // vendorStatus = "active"
) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${
        MANAGE_LOCATIONS.API
      }?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${
        id === "full_name" ? "first_name" : id
        // id
      }`
    );
  } else {
    response = await getRequest(
      `${
        MANAGE_LOCATIONS.API
      }?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${
        id === "full_name" ? "first_name" : id
        // id
      }`
    );
  }

  return response;
};

const getConsignerList = async () => {
  const response = await httpRequest("locations-list", null, {
    searchKeyword: "",
    entriesPerPage: "0",
    vendorType: "consignment",
  });

  return response;
};

const LocationMgr = {
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  httpRequest,
  RESPONSE_SUCCESS,
  validateEmail,

  MANAGE_LOCATIONS,
  limitInputLength,
  getConsignerList,
  getResponseMsg,
  getErrorMessage,
  setLocationList,
  preventNegativeInput,
  formatPhoneNumber,
  formatZip,
};

export default LocationMgr;
