/* eslint-disable no-unused-vars */
// prop-types is a library for typechecking of props
import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Timeline context
import { useTimeline } from "components/CustomTimelineItem/context";

// Custom styles for the TimelineItem
import timelineItem from "components/CustomTimelineItem/TimelineItem/styles";
import { Grid } from "@mui/material";
import RefundPayment from "layouts/Order/order-detail/components/RefundPayment";
import CustomModal from "components/CustomModal";
import MDSnackbar from "components/MDSnackbar";

function TimelineItem({
  color,
  icon,
  title,
  dateTime,
  description,
  lastItem,
  action,
  status,
  paymentLink,
  paymentMethod,
  revisionData,
  loadDetails,
  closeRevisionLog,
  availableRefundAmount,
}) {
  const isDark = useTimeline();

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Refund");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenPaymentModal = () => {
    setShowPaymentModal(true);
    // console.log(paymentMethod, status, revisionData);
  };
  const handleClosePaymentModal = (output = "") => {
    setLoading(true);
    switch (output) {
      case "Success":
        closeRevisionLog("Success-refund");
        break;
      case "failed-payment":
        setErrMsg("Failed to send refund.");
        openErrorSB(true);
        // setTimeout(() => {
        //   loadDetails();
        //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
        //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
        // }, 1000);

        break;
      default:
        break;
    }
    setShowPaymentModal(false);
    setLoading(false);
  };

  return (
    <MDBox position="relative" mb={3} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MDBox>
      <MDBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MDTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title} # {action}
          {action === "Payment" || action === "Refund"
            ? ` (${status.toString().toUpperCase() || ""})`
            : ""}
        </MDTypography>
        <MDBox mt={0.5}>
          <MDTypography variant="caption" color={isDark ? "secondary" : "text"}>
            {dateTime}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1.5}>
          {description ? (
            <MDBox>
              <MDTypography
                variant="button"
                color={isDark ? "white" : "dark"}
                sx={{ whiteSpace: "pre-line" }}
              >
                {description}
              </MDTypography>
              {action === "Payment" && paymentMethod !== "CASH" && paymentMethod !== "CHECK" && (
                <MDBox>
                  <MDBox>
                    <MDTypography
                      variant="button"
                      color="text"
                      onClick={() => window.open(paymentLink, "_blank", "noreferrer")}
                    >
                      <MDTypography
                        component={Link}
                        to="#"
                        variant="caption"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        Click here to view transaction details.
                      </MDTypography>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              )}
              {action === "Payment" && status === "paid" && (
                <MDBox>
                  <MDTypography
                    variant="button"
                    color="text"
                    onClick={() => {
                      if (availableRefundAmount > 0) handleOpenPaymentModal();
                    }}
                  >
                    <MDTypography
                      component={Link}
                      to="#"
                      variant="caption"
                      color={availableRefundAmount > 0 ? "info" : "secondary"}
                      fontWeight="medium"
                      textGradient
                    >
                      REFUND
                    </MDTypography>
                  </MDTypography>
                </MDBox>
              )}
            </MDBox>
          ) : // <Grid container spacing={1} alignItems="center">
          //   <Grid item xs={12} md={6} xl={12}>
          //     <MDTypography
          //       variant="button"
          //       fontWeight="medium"
          //       color={isDark ? "white" : "dark"}
          //     >
          //       {description?.body1?.label || ""}
          //     </MDTypography>
          //     <MDTypography variant="button" color={isDark ? "white" : "dark"}>
          //       {description?.body1?.value || ""}
          //     </MDTypography>
          //   </Grid>
          //   <Grid item xs={12} md={6} xl={12}>
          //     <MDTypography
          //       variant="button"
          //       fontWeight="medium"
          //       color={isDark ? "white" : "dark"}
          //     >
          //       {description?.body2?.label || ""}
          //     </MDTypography>
          //     <MDTypography variant="button" color={isDark ? "white" : "dark"}>
          //       {description?.body2?.value || ""}
          //     </MDTypography>
          //   </Grid>
          // </Grid>
          null}
        </MDBox>
      </MDBox>
      {showPaymentModal && (
        <CustomModal
          showModal={showPaymentModal}
          handleHideModal={handleClosePaymentModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "30%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 2,
            overflow: "auto",
          }}
        >
          <RefundPayment onClose={handleClosePaymentModal} revisionData={revisionData} />

          {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
        </CustomModal>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
  status: "",
  action: "",
  paymentLink: "",
  paymentMethod: "",
  title: "",
  availableRefundAmount: "",
  revisionData: {},
  loadDetails: () => {},
  closeRevisionLog: () => {},
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string,
  dateTime: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.string,
  action: PropTypes.string,
  availableRefundAmount: PropTypes.string,
  paymentLink: PropTypes.string,
  paymentMethod: PropTypes.string,
  lastItem: PropTypes.bool,
  revisionData: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func,
  closeRevisionLog: PropTypes.func,
};

export default TimelineItem;
