/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState, useEffect, useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Autocomplete, Card, CircularProgress, Icon } from "@mui/material";
import Calendar from "examples/Calendar";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";
import CustomModal from "components/CustomModal";

import DashboardMgr from "layouts/dashboards/DashboardMgr";
import DashboardCalendarModal from "./DashboardCalendarModal";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  setStorageItem,
  ORDERS,
  getDateToday,
  formatDate,
  capitalizeFirstLetter,
} = DashboardMgr;

function DashboardCalendarWidget({ defaultPickupFilter, locations }) {
  const location = useLocation();
  const navigate = useNavigate();

  const locationList = JSON.parse(getStorageItem("locations_list")) || locations;
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().add(1, "M").startOf("month").format("YYYY-MM-DD");

  const [calendarData, setCalendarData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [calendarDataCount, setCalendarDataCount] = useState(0);
  const [locationFilter, setLocationFilter] = useState(defaultPickupFilter);
  const [calendarDataLoading, setCalendarDataLoading] = useState(false);
  const [showCalendarDataModal, setShowCalendarModal] = useState(false);

  const [errMsg, setErrMsg] = useState("Failed to load");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openTodaysReturnModal = () => setShowCalendarModal(true);
  const closeTodaysReturnModal = () => setShowCalendarModal(false);

  const processRow = (data = []) => {
    let tmpData = [];
    data.map((calendar) => {
      Object.entries(calendar).map((item) => {
        if (item[0] === "pickup" && item[1].total > 0) {
          tmpData = tmpData.concat({
            title: `${capitalizeFirstLetter(item[0])}: ${item[1].total}`,
            type: capitalizeFirstLetter(item[0]),
            data: item[1].orders,
            start: calendar?.date,
            end: calendar?.date,
            className: "info",
          });
        } else if (item[0] === "returning" && item[1].total > 0) {
          tmpData = tmpData.concat({
            title: `${capitalizeFirstLetter(item[0])}: ${item[1].total}`,
            type: capitalizeFirstLetter(item[0]),
            data: item[1].orders,
            start: calendar?.date,
            end: calendar?.date,
            className: "success",
          });
        } else if (item[0] === "late" && item[1].total > 0) {
          tmpData = tmpData.concat({
            title: `${capitalizeFirstLetter(item[0])}: ${item[1].total}`,
            type: capitalizeFirstLetter(item[0]),
            data: item[1].orders,
            start: calendar?.date,
            end: calendar?.date,
            className: "error",
          });
        }
        return 0;
      });

      return 0;
    });

    return tmpData;
  };

  const showOrderDetail = (values) => {
    setStorageItem("current_order_detail", values);
    navigate(ORDERS.DETAIL_ROUTE, {
      state: { from: location },
      //  replace: true
    });
  };

  const loadCalendarView = async (locationId) => {
    setCalendarDataLoading(true);

    const response = await httpRequest("calendar-data", {
      startDate: startOfMonth,
      locationId,
    });

    if (getResponseMsg(response) === "Success") {
      setCalendarData(processRow(response.data.data));
      setCalendarDataCount(response.data.data.count);
    } else {
      setErrMsg("Failed to load Calendar Data");
      openErrorSB(true);
    }

    setCalendarDataLoading(false);
  };

  const handleChange = async (e, val) => {
    setLocationFilter(val);
    loadCalendarView(val?.id);
  };

  useEffect(() => {
    // console.log(startOfMonth, startOfNextMonth);
    loadCalendarView(locationFilter?.id);
  }, []);

  return (
    <Card>
      <MDBox>
        <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
          <MDBox display="flex">
            <MDTypography>
              <Icon color="inherit" fontSize="medium">
                today
              </Icon>
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium">
              CALENDAR VIEW ({formatDate(getDateToday(), "MMMM YYYY")})
            </MDTypography>
          </MDBox>
          <MDBox>
            <Autocomplete
              disablePortal
              value={locationFilter}
              options={locationList}
              sx={{ width: 180 }}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              onChange={(event, newValue) => {
                // console.log(newValue);
                if (newValue !== null) {
                  handleChange(event, newValue);
                }
              }}
              renderInput={(params) => <MDInput {...params} label="Filter by Location" />}
            />
          </MDBox>
        </MDBox>

        {useMemo(
          () =>
            calendarDataLoading ? (
              <MDBox
                display="flex"
                direction="row"
                justifyContent="center"
                alignItems="center"
                pb={4}
                mt={3}
              >
                <CircularProgress />
              </MDBox>
            ) : (
              <Calendar
                initialView="dayGridMonth"
                initialDate="2022-08-10"
                events={calendarData}
                selectable
                editable
                validRange={{
                  start: startOfMonth,
                  end: endOfMonth,
                }}
                headerToolbar={false}
                eventClick={(info) => {
                  setViewData({
                    title: info?.event?.extendedProps?.type,
                    data: info?.event?.extendedProps?.data,
                  });
                  openTodaysReturnModal();
                }}
              />
            ),
          [calendarData, calendarDataLoading]
        )}

        {showCalendarDataModal && (
          <CustomModal
            showModal={showCalendarDataModal}
            handleHideModal={closeTodaysReturnModal}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              height: calendarDataCount > 12 ? "98%" : "auto",
              width: "30%",
              // border: "2px solid #000",
              boxShadow: 24,
              borderRadius: "5px",
              p: 4,
              overflow: "auto",
            }}
          >
            <DashboardCalendarModal
              onClose={closeTodaysReturnModal}
              defaultPickupFilter={locationFilter}
              viewData={viewData}
            />
          </CustomModal>
        )}

        <MDSnackbar
          color="error"
          icon="warning"
          title="Vendor Creation"
          dateTime=""
          content={errMsg}
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          bgWhite={false}
        />
      </MDBox>
    </Card>
  );
}
DashboardCalendarWidget.defaultProps = {
  locations: [],
};

DashboardCalendarWidget.propTypes = {
  defaultPickupFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  userDetail: state.authentication?.userDetail,
  locations: state.helper?.locations,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCalendarWidget);
