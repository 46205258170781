/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Grid } from "@mui/material";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import ReportsMgr from "layouts/Reports/ReportsMgr";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const { convertToCurrency } = ReportsMgr;

function SalesTaxBySite({ reportsData }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <MDBox>
      <Card sx={{ marginBottom: 2 }}>
        <MDBox sx={{ margin: 2 }}>
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              Sales Tax Report - {reportsData.location}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              {reportsData.month}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              {reportsData.date}
            </MDTypography>
          </MDBox>
          <MDBox>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2"> </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  sx={{ borderBottom: "2px solid #000" }}
                >
                  Taxable
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  sx={{ borderBottom: "2px solid #000" }}
                >
                  Non-Taxable
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography
                  variant="body2"
                  fontWeight="medium"
                  sx={{ borderBottom: "2px solid #000" }}
                >
                  Total
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          {/* RENTALS */}
          <MDBox>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">Rental</MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.rental?.taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.rental?.non_taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.rental?.total)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          {/* SALES */}
          <MDBox>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">Sales</MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.sales?.taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.sales?.non_taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.sales?.total)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
          {/* OTHER AMOUNT */}
          <MDBox>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">Other Amount</MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3} sx={{ borderBottom: "2px solid #000" }}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.other_amount?.taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3} sx={{ borderBottom: "2px solid #000" }}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.other_amount?.non_taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3} sx={{ borderBottom: "2px solid #000" }}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.other_amount?.total)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>

          {/* TOTAL */}
          <MDBox>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">Total</MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.totals?.taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.totals?.non_taxable)}
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.totals?.total)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox sx={{ marginTop: 2 }}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2"> </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2"> </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2">Tax Amount</MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3} sx={{ borderBottom: "2px solid #000" }}>
                <MDTypography variant="body2">
                  {convertToCurrency(reportsData?.report?.tax_amount)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2"> </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body2"> </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body1">Total {reportsData.location} Tax:</MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <MDTypography variant="body1">
                  {convertToCurrency(reportsData?.report?.total_site_tax)}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </MDBox>
  );
}

SalesTaxBySite.defaultProps = {
  reportsData: {},
};

SalesTaxBySite.propTypes = {
  reportsData: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
});

export default connect(mapStateToProps, null)(SalesTaxBySite);
