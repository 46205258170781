import HTTPSServices from "services/https/HTTPSServices";
import pathUrl from "services/context/pathUrl";

const { postRequest, getRequest } = HTTPSServices;
const { VALIDATE_EMAIL, VALIDATE_USERNAME, COUNTRY_LIST, LOCATION_LIST } = pathUrl;

const validateEmail = async (email) => {
  const response = await postRequest(VALIDATE_EMAIL.api, { email });
  return response;
};

const validateUsername = async (username) => {
  const response = await postRequest(VALIDATE_USERNAME.api, { username });
  return response;
};

const getCountryList = async () => {
  const response = await getRequest(COUNTRY_LIST.api);
  return response;
};

const getLocationList = async () => {
  const response = await getRequest(`${LOCATION_LIST.api}/?status=active`);
  return response;
};

const getWarehouseList = async (id) => {
  const response = await getRequest(`/locations/${id}/warehouses`);
  return response;
};

const getAllWarehouseList = async () => {
  const response = await getRequest(`warehouses/`);
  return response;
};

const helper = {
  validateEmail,
  validateUsername,
  getCountryList,
  getLocationList,
  getWarehouseList,
  getAllWarehouseList,
};

export default helper;
