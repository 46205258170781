/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import { CardMedia, Grid, Icon, IconButton, Switch, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import DragAndDrop from "layouts/Customers/customer-detail/components/DragAndDrop";
import CustomerMgr from "layouts/Customers/CustomerMgr";

import pdfLogo from "assets/images/logos/pdf-pic.jpg";

const {
  getAttachedDocuments,
  httpRequest,
  RESPONSE_SUCCESS,
  getDocumentTypeName,
  getStorageItem,
  hasPermission,
} = CustomerMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "29rem",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function AttachmentsInfo({ loadDetails }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const customerDetails = JSON.parse(getStorageItem("current_customer_detail"));

  const theme = {
    color: darkMode ? "white" : "dark",
  };

  const [showUpload, setShowUpload] = useState(false);
  const [uploadData, setUploadData] = useState({
    document_name: "insurance",
    id: "",
    file_name: "",
    file_url: "",
    document_type: "insurance",
    limit: 1.0,
    expiration_date: "2022-12-12",
    status: null,
    is_waived: false,
  });

  const [waivedDoc, setWaivedDoc] = useState([]);
  const [docStatus, setDocStatus] = useState([]);

  const [notifTitle, setNotifTitle] = useState("Upload Document");

  const [uploadDocuments, setUploadDocuments] = useState({});

  const [showStatus, setShowStatus] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [errorSB, setErrorSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const getDocuments = async () => {
    if (!customerDetails?.uuid) {
      return {};
    }

    setUploadDocuments(await getAttachedDocuments(customerDetails?.uuid));

    return 0;
  };

  const handleOpenUpload = (index) => {
    setShowUpload(true);
    setUploadData(uploadDocuments[index]);
  };

  const handleCloseDialog = (status) => {
    if (status === "uploaded") {
      openSuccessSB(true);
      setNotifTitle("Upload Document");
      setSuccessMsg("Successfully Uploaded File");
      loadDetails();
      getDocuments();
    }
    setShowUpload(false);
  };

  const showLimit = (docType) => {
    switch (docType) {
      case "insurance":
        return true;
      default:
        return false;
    }
  };

  const showExpirationDate = (docType) => {
    switch (docType) {
      case "insurance":
        return true;
      case "resale_certificate":
        return true;
      default:
        return false;
    }
  };

  const handleChange = async (data, index, e) => {
    const waive = e.target.checked;
    setNotifTitle("Waive Document");

    if (waive === true) {
      setSuccessMsg(`Successfully Waive ${getDocumentTypeName(data?.document_name)}`);
    } else {
      setSuccessMsg(`Successfully Unwaive ${getDocumentTypeName(data?.document_name)}`);
    }

    const response = await httpRequest("customer-waive-document", null, {
      uuid: customerDetails?.uuid,
      data,
      waive,
    });
    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      setWaivedDoc(waive);
      loadDetails();
      getDocuments();
      openSuccessSB(true);
    } else {
      setErrMsg("Failed to waive document");
      openErrorSB();
    }
  };

  const handleOnChangeStatus = async (e, data) => {
    setDocStatus(e.target.checked);
    setNotifTitle("Document Status");
    const value = e.target.checked === true ? "good" : "bad";

    const response = await httpRequest("customer-update-status", null, {
      uuid: customerDetails?.uuid,
      data,
      value,
    });

    if (response !== null) {
      getDocuments();
      openSuccessSB(true);
      setSuccessMsg("Successfully changed status");
    } else {
      setSuccessMsg("Failed to changed status");
    }
  };

  useEffect(() => {
    getDocuments();
  }, []);

  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" py={2} pl={3}>
        <MDTypography variant="h5">Attachments</MDTypography>
      </MDBox>
      <MDBox height="auto">
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {uploadDocuments.length > 0 &&
            uploadDocuments.map((document, index) => (
              <Grid item xs={12} sm={4} md={3} key={document?.document_name}>
                <MDBox textAlign="center">
                  <Grid container>
                    <Grid item xs={12}>
                      <MDBox mx={3} display="flex" justifyContent="space-between">
                        <MDBox display="flex" direction="row">
                          <MDTypography fontWeight="bold" variant="h6" color={theme.color}>
                            {getDocumentTypeName(document?.document_name) || ""}
                          </MDTypography>
                          {getDocumentTypeName(document?.document_name) === "Rental Agreement" && (
                            <MDTypography variant="h6" fontWeight="regular" color="error">
                              &nbsp;*
                            </MDTypography>
                          )}
                        </MDBox>

                        <MDBox display="flex" justifyContent="space-between">
                          {document?.file_url && (
                            <MDBox>
                              <IconButton
                                size="small"
                                aria-label="edit"
                                color="info"
                                onClick={() => window.open(document?.file_url)}
                              >
                                <Icon fontSize="small">visibility</Icon>
                              </IconButton>
                            </MDBox>
                          )}

                          <MDBox>
                            <IconButton
                              size="small"
                              aria-label="edit"
                              color="info"
                              onClick={() => handleOpenUpload(index)}
                              disabled={document?.is_waived}
                            >
                              <Icon fontSize="small">{!document?.file_name ? "add" : "edit"}</Icon>
                            </IconButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                      <MDBox mx={3}>
                        {document?.file_url && (
                          <MDBox
                            flexDirection="column"
                            display="center"
                            justifyContent="center"
                            direction="column"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            textAlign="center"
                          >
                            <CardMedia
                              src={
                                document?.file_name.substring(
                                  document?.file_name?.lastIndexOf("."),
                                  document?.file_name.length
                                ) === ".pdf"
                                  ? pdfLogo
                                  : document?.file_url
                              }
                              component="img"
                              title={getDocumentTypeName(document?.document_name) || ""}
                              sx={{
                                maxWidth: "100%",
                                margin: 0,
                                maxHeight: "9rem",
                                boxShadow: ({ boxShadows: { md } }) => md,
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                              onClick={() => window.open(document?.file_url)}
                            />
                          </MDBox>
                        )}
                      </MDBox>
                    </Grid>
                    {document?.document_name !== "rental_agreement" && (
                      <Grid ml={3} item xs={12}>
                        <Grid container>
                          <Grid item xs={6}>
                            <MDBox textAlign="right">
                              {!hasPermission("waive_customerdocument", permissions?.customers) && (
                                <Tooltip title="No permission to waive documents" placement="top">
                                  <Icon>error</Icon>
                                </Tooltip>
                              )}

                              <Switch
                                name="waiveDocument"
                                // defaultChecked={document?.is_waived || false}
                                checked={waivedDoc[index] || document?.is_waived}
                                disabled={
                                  !hasPermission("waive_customerdocument", permissions?.customers)
                                }
                                onChange={(e) => {
                                  // console.log(document);
                                  handleChange(document, index, e);
                                }}
                              />
                            </MDBox>
                          </Grid>
                          <Grid item xs={6}>
                            <MDBox textAlign="left">
                              <MDTypography variant="caption" fontWeight="regular" color="text">
                                {document?.is_waived ? "Waived" : "Not waived"}
                              </MDTypography>
                            </MDBox>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    {showLimit(document?.document_name) === true && document?.file_name && (
                      <Grid item xs={12}>
                        <MDBox ml={3}>
                          <Grid container>
                            <Grid item xs={6}>
                              <MDBox textAlign="right">
                                <MDTypography
                                  variant="button"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Limit : &nbsp;
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={6}>
                              <MDBox textAlign="left">
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  &nbsp;{document?.limit}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                    )}

                    {showExpirationDate(document?.document_name) && document?.expiration_date && (
                      <Grid item xs={12}>
                        <MDBox ml={3}>
                          <Grid container>
                            <Grid item xs={3} sm={6}>
                              <MDBox textAlign="right">
                                <MDTypography
                                  variant="button"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Expiration :&nbsp;
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <MDBox textAlign="left">
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  &nbsp;{document?.expiration_date}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                    )}
                    {document?.file_name && (
                      <Grid item xs={12}>
                        <MDBox ml={3}>
                          <Grid container>
                            <Grid item xs={6}>
                              <MDBox textAlign="right">
                                <MDTypography
                                  variant="button"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  File name :&nbsp;
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={6}>
                              <MDBox textAlign="left">
                                <MDTypography variant="button" fontWeight="regular" color="text">
                                  &nbsp;
                                  {document?.file_name.length > 13
                                    ? `${document?.file_name
                                        .substring(0, 4)
                                        .concat("...")}${document?.file_name.substring(
                                        document?.file_name?.lastIndexOf("."),
                                        document?.file_name.length
                                      )}`
                                    : document?.file_name || ""}
                                </MDTypography>
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                    )}
                    {document?.file_name && (
                      <Grid item xs={12}>
                        <MDBox ml={3}>
                          <Grid container>
                            <Grid item xs={6}>
                              <MDBox textAlign="right">
                                <MDTypography
                                  variant="button"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  Status :&nbsp;
                                </MDTypography>
                              </MDBox>
                            </Grid>
                            <Grid item xs={6}>
                              <MDBox textAlign="left">
                                {document?.status?.value === "good" && (
                                  <IconButton size="small" aria-label="edit" disabled>
                                    <Icon color="success" fontSize="small">
                                      circle_outlined
                                    </Icon>
                                  </IconButton>
                                )}
                                {document?.status?.value === "bad" && (
                                  <IconButton size="small" aria-label="edit" disabled>
                                    <Icon color="error" fontSize="small">
                                      circle_outlined
                                    </Icon>
                                  </IconButton>
                                )}
                                {document?.status?.value === "warning" && (
                                  <IconButton size="small" aria-label="edit" disabled>
                                    <Icon sx={{ color: "#ffff39" }} fontSize="small">
                                      circle_outlined
                                    </Icon>
                                  </IconButton>
                                )}

                                <Switch
                                  disabled={document?.status?.value === "warning"}
                                  defaultChecked={document?.status?.value === "good" || false}
                                  checked={docStatus[index]}
                                  onChange={(e) => handleOnChangeStatus(e, document)}
                                />
                              </MDBox>
                            </Grid>
                          </Grid>
                        </MDBox>
                      </Grid>
                    )}

                    {document?.document_name === "rental_agreement" && !document?.file_url && (
                      <Grid item xs={12} mb={1.5}>
                        <MDBox textAlign="center">
                          <MDTypography variant="h6" fontWeight="regular" color="error">
                            &nbsp;
                          </MDTypography>
                        </MDBox>
                        {/* <MDBox display="flex" alignItems="center" mx={3}>
                        <MDBox mt={0.5}></MDBox>
                        <MDBox width="80%" ml={0.5}></MDBox>
                      </MDBox> */}
                      </Grid>
                    )}

                    {!document?.file_url && (
                      <Grid item xs={12}>
                        <MDBox>
                          <MDBox mb={2}>
                            <MDTypography variant="button" color="text" fontWeight="regular">
                              No File Uploaded Yet
                            </MDTypography>
                            <br />
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              onClick={() => handleOpenUpload(index)}
                              disabled={document?.is_waived}
                            >
                              add
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    )}
                  </Grid>
                </MDBox>
              </Grid>
            ))}
        </Grid>

        {showUpload && (
          <Modal
            open={showUpload}
            onClose={handleCloseDialog}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
              <DragAndDrop
                uuid={customerDetails?.uuid}
                data={uploadData}
                onClose={handleCloseDialog}
                multiple
              />
            </MDBox>
          </Modal>
        )}

        <MDSnackbar
          color="success"
          icon="check"
          title={notifTitle}
          dateTime=""
          content={successMsg}
          open={successSB}
          onClose={closeSuccessSB}
          close={closeSuccessSB}
          bgWhite={false}
        />

        <MDSnackbar
          color="error"
          icon="warning"
          title={notifTitle}
          dateTime=""
          content={errMsg}
          open={errorSB}
          onClose={closeErrorSB}
          close={closeErrorSB}
          bgWhite={false}
        />
      </MDBox>
    </Card>
  );
}

AttachmentsInfo.defaultProps = {};

AttachmentsInfo.propTypes = {
  loadDetails: PropTypes.func.isRequired,
};

export default AttachmentsInfo;
