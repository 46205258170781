const roles = {
  HIDDEN: { title: "hidden", level: 401 },
  ADMIN: { title: "admin", level: 999 },
  MANAGER: { title: "manager", level: 999 },
  AGENT: { title: "agent", level: 10 },
  OPERATOR: { title: "operator", level: 9 },
  CUSTOMER: { title: "customer", level: 1 },
  SUBVENDOR: { title: "subvendor", level: 7 },
  CONSIGNER: { title: "consigner", level: 6 },
};

export default roles;
