import { configureStore } from "@reduxjs/toolkit";
import reducers from "services/redux/reducers";
import { Provider } from "react-redux";

const store = configureStore(
  { reducer: reducers },
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

// eslint-disable-next-line react/prop-types
export function ReduxProvider({ children }) {
  return <Provider store={store}>{children}</Provider>;
}

export default store;
