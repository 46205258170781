/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment/moment";

import Card from "@mui/material/Card";
import {
    Autocomplete,
    Checkbox,
    Divider,
    Grid,
    Icon,
    Menu,
    TextField,
    Tooltip,
} from "@mui/material";
import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import OrderMgr from "layouts/Order/OrderMgr";
import FormField from "layouts/pages/account/components/FormField";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const dateRegex = /null|^$|^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$/;

const {
    httpRequest,
    ORDERS,
    getNextPage,
    getPrevPage,
    getSort,
    setStorageItem,
    getResponseMsg,
    ellipsis,
    hasPermission,
    convertToCurrency,
} = OrderMgr;

function OrderSearch({ userDetail }) {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const orderStatusOption = [
        { name: "Quote", value: "quote" },
        { name: "Confirmed", value: "confirmed" },
        { name: "Reservation", value: "reservation" },
        { name: "Staging", value: "staging" },
        { name: "Staged", value: "staged" },
        { name: "On Rent", value: "on_rent" },
        { name: "Late", value: "late" },
        { name: "Awaiting Check In", value: "awaiting_check_in" },
        { name: "Check In", value: "checking_in" },
        { name: "Incomplete", value: "incomplete" },
        { name: "Returned", value: "returned" },
        { name: "Ready to Invoice", value: "ready_to_invoice" },
        { name: "Invoiced", value: "invoiced" },
        { name: "Complete", value: "complete" },
        { name: "Closed", value: "closed" },
        { name: "Canceled", value: "canceled" },
    ];

    const navigate = useNavigate();
    const location = useLocation();

    const [itemList, setItemList] = useState([]);
    const [nextPageLink, setNextPageLink] = useState("");
    const [prevPageLink, setPrevPageLink] = useState("");
    const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
    const [entriesPerPage, setEntriesPerPage] = useState(500);
    const [sortType, setSortType] = useState("asc");
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [menu, setMenu] = useState(null);

    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [orderStatus, setOrderStatus] = useState([
        orderStatusOption[0],
        orderStatusOption[1],
        orderStatusOption[2],
        orderStatusOption[3],
        orderStatusOption[4],
        orderStatusOption[5],
        orderStatusOption[6],
        orderStatusOption[7],
        orderStatusOption[8],
        orderStatusOption[9],
        orderStatusOption[10],
        orderStatusOption[11],
        orderStatusOption[12],
        orderStatusOption[13],
        orderStatusOption[14],
    ]);
    const [showNewbtnTooltip, setShoWNewbtmTooltip] = useState(false);
    const [showViewInventoryTooltip, setShowViewInventoryTooltip] = useState(false);

    const openNewBtnTooltip = () => setShoWNewbtmTooltip(true);
    const closeNewTtnTooltip = () => setShoWNewbtmTooltip(false);

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const loadList = async (searchKeywordVal = "", entriesPerPageVal = 25) => {
        let statusFilterList = "";
        orderStatus.map((status) => {
            statusFilterList += `${status.value},`;
            return statusFilterList;
        });

        return httpRequest(
            "orders-list",
            {},
            {
                searchKeyword: searchKeywordVal,
                entriesPerPage: entriesPerPageVal,
                date_from: dateFrom,
                date_to: dateTo,
                status: statusFilterList,
            }
        );
    };

    const showItemDetail = (values) => {
        setStorageItem("current_order_detail", values);
        navigate(ORDERS.DETAIL_ROUTE, {
            state: { from: location, searchKeyword },
            // replace: true
        });
    };

    const dataTableData = {
        columns: [
            {
                Header: "identifier",
                accessor: "identifier",
                width: "15%",
                headerAlign: "left",
                hasSort: true,
                Cell: ({ row, value }) => {
                    const canViewInventoryDetail = hasPermission("view_order", permissions?.orders);
                    const status = (
                        <MDBox>
                            {!canViewInventoryDetail && (
                                // <MDBox mr={2}>
                                <Tooltip
                                    title="You don't have permission to View Orders"
                                    placement="top"
                                >
                                    <Icon>error_outlined</Icon>
                                </Tooltip>
                                // </MDBox>
                            )}
                            <MDTypography
                                ml={!canViewInventoryDetail ? 1 : 0}
                                variant="button"
                                color="text"
                                onClick={() =>
                                    canViewInventoryDetail && showItemDetail(row?.original)
                                }
                            >
                                <MDTypography
                                    component={Link}
                                    to="#"
                                    variant="button"
                                    color={!canViewInventoryDetail ? "text" : "info"}
                                    fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                                    textGradient
                                >
                                    {/* {row.values.name} */}
                                    {value}
                                </MDTypography>
                            </MDTypography>
                        </MDBox>
                    );

                    return status;
                },
            },
            {
                Header: "Pick-up Date",
                accessor: "pickup_date",
                headerAlign: "left",
                width: "15%",
            },
            {
                Header: "Company Name",
                accessor: "company_name",
                headerAlign: "left",
                width: "10%",
            },
            { Header: "Summary", accessor: "description", headerAlign: "left", width: "10%" },
            {
                Header: "Bill Days",
                accessor: "bill_days",
                headerAlign: "left",
                width: "10%",
                align: "center",
            },
            {
                Header: "Order Total",
                accessor: "invoice_total",
                width: "auto",
                headerAlign: "left",
                Cell: ({ value }) => {
                    const status = (
                        <MDBox>
                            <MDBox direction="column" justifyContent="flex-start" width="auto">
                                <MDTypography variant="button" fontWeight="regular">
                                    {convertToCurrency(value || 0)}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    );
                    return status;
                },
            },
            {
                Header: "Amount Paid",
                accessor: "total_amount_paid",
                width: "10%",
                headerAlign: "left",
                Cell: ({ value }) => {
                    const status = (
                        <MDBox>
                            <MDBox direction="column" justifyContent="flex-start" width="5rem">
                                <MDTypography variant="button" fontWeight="regular">
                                    {convertToCurrency(value || 0)}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    );
                    return status;
                },
            },
            {
                Header: "Rental agent assigned",
                headerAlign: "left",
                accessor: "agent_name",
                width: "10%",
            },
            { Header: "Status", headerAlign: "left", accessor: "status", width: "10%" },
        ],
        rows: itemList || [],
        // rows: [],
    };

    const changeTimeZone = (date, timeZone) => {
        if (typeof date === "string") {
            return new Date(
                new Date(date).toLocaleString("en-US", {
                    timeZone,
                })
            );
        }

        return new Date(
            date.toLocaleString("en-US", {
                timeZone,
            })
        );
    };

    const processRows = (data = []) => {
        // setIsLoading(true);
        const items = [];

        data.map((item) => {
            items.push({
                // item,
                identifier: item.identifier,
                pickup_date: `${moment(
                    changeTimeZone(item?.pickup_date, userDetail?.location?.timezone?.name)
                ).format("LLL")} (${userDetail?.location?.timezone?.abbreviation || "UTC"})`,
                uuid: item.uuid,
                company_name: item?.customer?.company_name,
                description: ellipsis(item?.description),
                order_total: item?.order_total,
                invoice_total: item?.invoice_total,
                total_amount_paid: item?.total_amount_paid,
                agent_name: item?.agent?.name,
                status: item?.status?.name,
                bill_days: item?.bill_days,
            });
            return true;
        });

        // setIsLoading(false);
        return items;
    };

    const processResponse = (response) => {
        if (getResponseMsg(response) === "Success") {
            setItemList(processRows(response?.data?.data?.results));
            setNextPageLink(response.data.data?.next);
            setPrevPageLink(response.data.data?.previous);
            setTotalRows(response.data.data?.count);
        }
    };

    const handleFilter = async (action) => {
        let statusFilterList = "";
        let filters = [];
        setIsLoading(true);
        switch (action) {
            case "apply-filter":
                processResponse(await loadList());
                break;

            case "remove-filter":
                filters = [
                    orderStatusOption[0],
                    orderStatusOption[1],
                    orderStatusOption[2],
                    orderStatusOption[3],
                    orderStatusOption[4],
                    orderStatusOption[5],
                    orderStatusOption[6],
                    orderStatusOption[7],
                    orderStatusOption[8],
                    orderStatusOption[9],
                    orderStatusOption[10],
                    orderStatusOption[11],
                    orderStatusOption[12],
                    orderStatusOption[13],
                    orderStatusOption[14],
                ];
                setDateFrom(null);
                setDateTo(null);
                setOrderStatus(filters);

                filters.map((status) => {
                    statusFilterList += `${status.value},`;
                    return statusFilterList;
                });

                processResponse(
                    await httpRequest(
                        "orders-list",
                        {},
                        {
                            searchKeyword,
                            entriesPerPage,
                            date_from: "",
                            date_to: "",
                            status: statusFilterList,
                        }
                    )
                );
                break;
            default:
                break;
        }

        closeMenu();
        setIsLoading(false);
    };

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MDBox sx={{ width: "100%", height: "100%", lineHeight: 1, p: 1 }}>
                <Grid item xs={12} md={12}>
                    <Autocomplete
                        multiple
                        disableCloseOnSelect
                        value={orderStatus}
                        limitTags={0}
                        options={orderStatusOption}
                        isOptionEqualToValue={(option, value) =>
                            value !== "" ? option.name === value.name : option.name
                        }
                        getOptionLabel={(option) => (option ? option.name : "")}
                        renderInput={(params) => (
                            <FormField
                                {...params}
                                label="Status"
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        sx={{ width: 300, height: "100%", lineHeight: 1, m: 1 }}
                        onChange={(event, newValue) => {
                            // console.log(newValue);
                            setOrderStatus(newValue);
                        }}
                    />
                </Grid>
                {/* <Autocomplete
          disablePortal
          value={orderStatus}
          options={orderStatusOption}
          onChange={(event, newValue) => {
            setOrderStatus(newValue);
          }}
          isOptionEqualToValue={(option, value) =>
            value !== "" ? option.name === value.name : option.name
          }
          getOptionLabel={(option) => (option ? option.name : "")}
          // sx={{ width: 300, height: "100%", lineHeight: 8, m: 1 }}
          renderInput={(params) => <TextField {...params} label="Status" />}
        /> */}
            </MDBox>
            <MDBox sx={{ width: "100%", height: "100%", lineHeight: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date From (mm/dd/yyyy)"
                        value={dateFrom}
                        onChange={(newValue) => {
                            const value =
                                newValue === null
                                    ? null
                                    : moment(newValue?.$d || "").format("YYYY-MM-DD");

                            if ((dateRegex.test(value) && dateTo === null) || value > dateTo) {
                                setDateTo(value);
                            }
                            setDateFrom(value);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ width: "20rem" }}
                                error={!dateRegex.test(dateFrom)}
                            />
                        )}
                    />
                </LocalizationProvider>
            </MDBox>
            <MDBox sx={{ width: "100%", height: "100%", lineHeight: 5, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label="Date To (mm/dd/yyyy)"
                        value={dateTo}
                        onChange={(newValue) => {
                            const value =
                                newValue === null
                                    ? null
                                    : moment(newValue?.$d || "").format("YYYY-MM-DD");

                            if (dateRegex.test(value) && value >= dateFrom) {
                                setDateTo(value);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ width: "20rem" }}
                                error={!dateRegex.test(dateTo)}
                            />
                        )}
                    />
                </LocalizationProvider>
            </MDBox>

            <Divider
                sx={{
                    margin: "0.5rem 0",
                    opacity: "0.6",
                }}
            />
            <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
                <MDBox mx={1}>
                    <MDButton
                        variant="gradient"
                        size="medium"
                        color="error"
                        onClick={() => handleFilter("remove-filter")}
                    >
                        Reset Filter
                    </MDButton>
                </MDBox>
                <MDBox mx={1}>
                    <MDButton
                        variant="gradient"
                        size="medium"
                        color="info"
                        onClick={() => handleFilter("apply-filter")}
                    >
                        Apply Filter
                    </MDButton>
                </MDBox>
            </MDBox>
        </Menu>
    );

    const newItem = () => {
        const temp = { ...[], pageState: "NEW" };
        setStorageItem("current_order_detail", temp);
        navigate(ORDERS.CREATE_ROUTE, {
            state: { from: location, searchKeyword, hasModal: true },
            // replace: true,
        });
    };

    const handleNextPage = async () => {
        setIsLoading(true);
        processResponse(await getNextPage(nextPageLink));
        setIsLoading(false);
    };

    const handlePrevPage = async () => {
        setIsLoading(true);
        processResponse(await getPrevPage(prevPageLink));
        setIsLoading(false);
    };

    const handleSearchKeyword = async (val) => {
        setSearchKeyword(val);
        setIsLoading(true);
        processResponse(await loadList(val, entriesPerPage));
        setIsLoading(false);
    };

    const handleSort = async (id) => {
        setIsLoading(true);

        processResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

        if (sortType === "asc") {
            setSortType("desc");
        } else {
            setSortType("asc");
        }
        setIsLoading(false);
    };

    const handleChangeEntries = async (val) => {
        setEntriesPerPage(val);
        setIsLoading(true);
        processResponse(await loadList(searchKeyword, val));
        setIsLoading(false);
    };

    const controllerRequest = new AbortController();

    useEffect(async () => {
        setIsLoading(true);
        const orderListResponse = await loadList(searchKeyword, entriesPerPage);
        processResponse(orderListResponse);
        setIsLoading(false);

        return () => {
            controllerRequest.abort();
        };
    }, []);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* {isLoading && <SimpleBackdrop />} */}
            <MDBox mb={2}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <Tooltip
                        title="You don't have permission to do this"
                        placement="top"
                        followCursor
                        open={showNewbtnTooltip && !hasPermission("add_order", permissions?.orders)}
                        onClose={closeNewTtnTooltip}
                        onOpen={openNewBtnTooltip}
                    >
                        <MDBox>
                            <MDButton
                                variant="gradient"
                                color="info"
                                onClick={newItem}
                                disabled={!hasPermission("add_order", permissions?.orders)}
                            >
                                new order
                            </MDButton>
                        </MDBox>
                    </Tooltip>
                    <MDBox display="flex">
                        <MDButton
                            variant={menu ? "contained" : "outlined"}
                            color={darkMode === true ? "white" : "dark"}
                            onClick={openMenu}
                        >
                            filters&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                    </MDBox>
                </MDBox>
                <Card>
                    <DataTable
                        table={dataTableData}
                        noEndBorder
                        details={showItemDetail}
                        handleNextPage={handleNextPage}
                        handlePrevPage={handlePrevPage}
                        handleSearch={handleSearchKeyword}
                        handleChangeEntries={handleChangeEntries}
                        handleSort={handleSort}
                        hasNextPage={nextPageLink !== null}
                        hasPrevPage={prevPageLink !== null}
                        totalCount={totalRows}
                        entriesPerPage={{
                            show: true,
                            defaultValue: 500,
                            entries: [10, 25, 50, 100, 500, 1000, 5000],
                        }}
                        title="Orders List"
                        canRefresh
                        isLoading={isLoading}
                    />
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

OrderSearch.defaultProps = {
    userDetail: {},
};

OrderSearch.propTypes = {
    userDetail: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
    categoriesListRedux: state?.helper?.categoryList,
    userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(OrderSearch);
