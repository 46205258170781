/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TimelineList from "components/CustomTimelineItem/TimelineList";
import TimelineItem from "components/CustomTimelineItem/TimelineItem";

// Data
import timelineData from "layouts/pages/projects/timeline/data/timelineData";
import OrderMgr from "layouts/Order/OrderMgr";
import MDTypography from "components/MDTypography";
import { Icon, IconButton } from "@mui/material";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  convertTimeZoneFormatDateTime,
  setStorageItem,
  ORDERS,
  capitalizeFirstLetter,
} = OrderMgr;

function RevisionLog({ onClose, userDetail }) {
  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));

  const [isLoading, setIsLoading] = useState(false);
  const [revisionLogData, setRevisionLogData] = useState([]);

  const processRows = (data = []) => {
    const revisionLogs = [];

    data.map((revision, index) => {
      let icon = "";
      let color = "";
      // let description = {};
      const description = [];
      if (revision?.action === "canceled") {
        icon = "cancel";
        color = "errpr";
      } else if (revision?.action === "created") {
        icon = "note_add";
        color = "success";
      } else {
        icon = "edit";
        color = "info";
      }

      if (revision?.action === "updated") {
        Object.entries(revision?.changes).map((change) => {
          // console.log(change);
          const added = [];
          const deleted = [];
          if (change[0] !== "items") {
            // description = `${capitalizeFirstLetter(change[0]).replaceAll( "_", " ")} changed  From: ${change[1].from} To: ${change[1].to}`;
            description.push(
              `${capitalizeFirstLetter(change[0]).replaceAll("_", " ")} changed from (${
                change[1].from
              }) to (${change[1].to})`
            );
            // description = {
            //   title: capitalizeFirstLetter(change[0]).replaceAll("_", " "),
            //   body1: { label: "From : ", value: `${change[1].from}` },
            //   body2: { label: "To : ", value: `${change[1].to}` },
            // };
          } else {
            if (change[1].added.length > 0) {
              change[1]?.added.map((add) => {
                added.push(add?.name);
                return added;
              });
            } else {
              added.push("None");
            }

            if (change[1].deleted.length > 0) {
              change[1]?.deleted.map((del) => {
                deleted.push(del?.name);
                return deleted;
              });
            } else {
              deleted.push("None");
            }
            description.push(
              `${capitalizeFirstLetter(change[0]).replaceAll(
                "_",
                " "
              )} added (${added.join()}), items removed (${deleted.join(", ")})`
            );
            //   description = `${capitalizeFirstLetter(change[0]).replaceAll("_", " ")}:
            // \n Added: ${added.join(", ")}
            // \n Deleted: ${deleted.join(", ")}`;
            // description = {
            //   title: capitalizeFirstLetter(change[0]).replaceAll("_", " "),
            //   body1: { label: "Added : ", value: `${added.join()}` },
            //   body2: { label: "Deleted : ", value: `${deleted.join(", ")}` },
            // };
          }

          return 0;
        });
      }

      revisionLogs.push({
        color,
        icon,
        title: revision?.user?.name,
        action: revision?.action,
        dateTime: convertTimeZoneFormatDateTime(
          revision.date,
          userDetail?.location?.timezone?.name,
          userDetail?.location?.timezone?.abbreviation,
          "lll"
        ),
        // dateTime: "22 DEC 7:20 PM",
        description: description.join("\n"),
        badges: ["design"],
        lastItem: data.length - 1 === index,
      });
      return 0;
    });

    return revisionLogs;
  };

  const getTransactionList = async () => {
    setIsLoading(true);

    const response = await httpRequest("revision-logs", null, { uuid: orderDetail?.uuid });

    if (getResponseMsg(response) === "Success") {
      setRevisionLogData(processRows(response?.data?.data?.results));
    }

    setIsLoading(false);
    return 0;
  };

  useEffect(() => {
    getTransactionList();
  }, []);

  const renderTimelineItems = revisionLogData.map(
    ({ color, icon, title, dateTime, description, badges, lastItem, action }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
        action={capitalizeFirstLetter(action)}
      />
    )
  );

  return (
    <MDBox>
      {" "}
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Revision Log
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox mt={-2}>
        <Grid container>
          <Grid item xs={12} lg={12}>
            <TimelineList>{renderTimelineItems}</TimelineList>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

RevisionLog.defaultProps = {
  userDetail: {},
};

RevisionLog.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(RevisionLog);
