/* eslint-disable no-await-in-loop */
/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import moment from "moment";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Card, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import CustomModal from "components/CustomModal";

import BasicInfo from "layouts/Inventory/inventory-detail/BasicInfo";
import Availability from "layouts/Inventory/inventory-detail/Availability";
import DeleteItem from "layouts/Inventory/inventory-detail/DeleteItem";
import MDSnackbar from "components/MDSnackbar";
import SubItems from "layouts/Inventory/inventory-detail/SubItems";
import RentalHitory from "layouts/Inventory/inventory-detail/components/RentalHitory";

import { useMaterialUIController } from "context";
import InventoryMgr from "./InventoryMgr";

const {
  httpRequest,
  INVENTORY,
  getStorageItem,
  setStorageItem,
  getResponseMsg,
  addDateFormatted,
  getDateToday,
  hasPermission,
  convertToCurrency,
} = InventoryMgr;

function InventoryDetail() {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [itemData, setItemData] = useState([]);

  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item Creation");

  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [reloadDetails, setReloadDetails] = useState(false);
  const [showRentalHistory, setShowRentalHistory] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [showTooltip, setShoWTooltip] = useState(false);

  const openTooltip = () => setShoWTooltip(true);
  const closeTooltip = () => setShoWTooltip(false);
  const closeErrorSB = () => setErrorSB(false);
  const closeSuccessSB = () => setSuccessSB(false);
  const closeRentalHistory = () => setShowRentalHistory(false);

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || INVENTORY.LIST_ROUTE;
  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));
  const dateToday = getDateToday();

  const processData = (data = []) => {
    const tmpList = [];

    data?.map((item) => {
      tmpList.push({
        title: `${item.local} available`,
        start: item?.date,
        end: item?.date,
        global: item?.global,
        local: item?.local,
        is_available: item?.is_available,
        className: item?.is_available === true ? "success" : "error",
      });
      return 0;
    });

    return tmpList;
  };

  const processRows = (data = []) => {
    let dailyTotal = 0;
    let weeklyTotal = 0;
    let monthlyTotal = 0;

    if (data?.item_type?.value === "kit") {
      data?.collections?.collections.map((item) => {
        if (!item?.optional) {
          dailyTotal += Number(item?.custom_prices?.daily || 0);
          weeklyTotal += Number(item?.custom_prices?.weekly || 0);
          monthlyTotal += Number(item?.custom_prices?.monthly || 0);
        }
        // tmpList.push({
        //   available: item?.available,
        //   item_name: item?.name,
        //   item_price: {
        //     daily: item?.item_price?.daily,
        //     weekly: item?.item_price?.weekly,
        //     monthly: item?.item_price?.monthly,
        //   },
        //   item_type: item?.item_type,
        //   item_uuid: item?.item_uuid,
        //   optional: item?.optional,
        //   quantity: item?.quantity,
        //   uuid: item?.uuid,
        // });
        return 0;
      });
    }

    return { ...data, dailyTotal, weeklyTotal, monthlyTotal };
  };

  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("inventory-detail", null, {
      uuid: itemDetail?.uuid,
    });
    // debugger;
    if (getResponseMsg(responseDetails) === "Success") {
      setItemData(processRows(responseDetails?.data?.data));
    }

    let responseAvailability = {};
    let tmpCalendarData = [];
    for (let index = 0; index < 4; index += 1) {
      if (index === 0) {
        responseAvailability = await httpRequest("inventory-availability", null, {
          uuid: itemDetail?.uuid,
          date: dateToday,
          // date: "2022-11-1",
        });
      } else {
        responseAvailability = await httpRequest("inventory-availability", null, {
          uuid: itemDetail?.uuid,
          date: addDateFormatted(
            moment().startOf("month").format("YYYY-MM-DD"),
            index,
            "month",
            "YYYY-MM-DD"
          ),
          // date: "2022-11-1",
        });
      }
      tmpCalendarData = tmpCalendarData.concat(processData(responseAvailability?.data?.data));
      // if (getResponseMsg(responseAvailability) === "Success") {
      //   setCalendarDat([...calendarData, { ...processData(responseAvailability?.data?.data) }]);
      // }
      // console.log(tmpCalendarData);
      if (index === 0) {
        setCalendarData(tmpCalendarData);
        setLoading(false);
      }
    }

    setCalendarData(tmpCalendarData);

    setLoading(false);

    return 0;
  };

  useEffect(() => {
    setNotifTitle("Inventory Details");
    if (itemDetail === {}) {
      navigate(INVENTORY.LIST_ROUTE, { replace: true });
    }
    loadDetails();
  }, []);

  // useEffect(() => {
  //   setNotifTitle("Inventory Details");
  //   if (itemDetail === {}) {
  //     navigate(INVENTORY.LIST_ROUTE, { replace: true });
  //   }
  //   loadDetails();
  // }, [itemDetail]);

  const handleClose = () => {
    if (from && from !== INVENTORY.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(INVENTORY.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  const editItem = () => {
    setLoading(true);
    const tmpCollection = [];

    if (itemData?.collections?.collections !== undefined) {
      itemData?.collections?.collections.map((collection) =>
        tmpCollection.push({
          ...collection.item,
          optional: collection.optional,
          quantity: collection.quantity,
          daily_price: collection?.custom_prices?.daily,
          weekly_price: collection?.custom_prices?.weekly,
          monthly_price: collection?.custom_prices?.monthly,
          parent_uuid: collection?.uuid,
        })
      );
    }

    const item = {
      uuid: itemData.uuid,
      item_name: itemData.name || "",
      item_description: itemData.description || "",
      item_category: itemData.category,
      item_sub_category: itemData.subcategory,
      item_type: itemData.item_type,
      replacement_value: itemData.replacement_value,
      daily_price: itemData.price?.daily,
      weekly_price: itemData.price?.weekly,
      monthly_price: itemData.price?.monthly,
      subitems: itemData.subitems,
      collections: tmpCollection,
      manufacturer: itemData.manufacturer,
      model_number: itemData.model_number,
      is_qc_required: itemData.is_qc_required,
      notes: itemData.notes,
      warehouse: itemData.warehouse,
    };
    // console.log(item);

    const temp = { ...item, pageState: "EDIT" };
    setStorageItem("current_item_detail", temp);
    // setTimeout(() => {
    setLoading(false);
    navigate(INVENTORY.EDIT_ROUTE, {
      state: {
        from: location,
        searchKeyword: location.state?.searchKeyword || "",
        hasModal: true,
      },
      // replace: true,
    });
    // }, 1000);
  };

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1}>
          <MDBox display="flex" justifyContent="flex-end">
            {/* <MDButton
              size="small"
              variant="outlined"
              color="info"
              onClick={openRentalHistory}
              sx={{ marginRight: 1 }}
              // disabled
            >
              Rental History
            </MDButton> */}
            <MDBox>
              {/* <Tooltip
                title="You don't have permission to do this"
                placement="top"
                // open={showTooltip}
                // onClose={closeTooltip}
                // onOpen={openTooltip}
                // followCursor
              > */}
              <Tooltip
                title="You don't have permission to do this"
                placement="top"
                followCursor
                open={showTooltip && !hasPermission("change_inventoryitem", permissions?.inventory)}
                onClose={closeTooltip}
                onOpen={openTooltip}
              >
                <MDBox>
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={editItem}
                    sx={{ marginRight: 1 }}
                    disabled={!hasPermission("change_inventoryitem", permissions?.inventory)}
                  >
                    Edit
                  </MDButton>
                </MDBox>
              </Tooltip>
            </MDBox>
            <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
              back
            </MDButton>
          </MDBox>
        </MDBox>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox display="flex">
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={7}>
                <BasicInfo data={itemData || {}} />
              </Grid>
              <Grid item xs={4} sm={8} md={5}>
                <Availability calendarData={calendarData} />
              </Grid>
              <Grid item xs={4} sm={8} md={12}>
                <SubItems
                  data={itemData || []}
                  setItemData={setItemData}
                  loadDetails={loadDetails}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto", mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <DeleteItem data={itemData || null} />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      {showRentalHistory && (
        <CustomModal
          showModal={showRentalHistory}
          handleHideModal={closeRentalHistory}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            width: "auto",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <RentalHitory onClose={closeRentalHistory} />

          {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
        </CustomModal>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default InventoryDetail;
