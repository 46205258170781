const LOGIN = "LOG_IN";
const SET_AUTH_DATA = "SET_USER_DATA";
const LOGOUT = "LOG_OUT";
const SET_USER_DETAIL = "GET_USER_DETAIL";
const UPDATE_USER_LOCATION = "UPDATE_USER_LOCATION";
const UPDATE_USER_WAREHOUSE = "UPDATE_USER_WAREHOUSE";

const SET_COUNTRY_LIST = "SET_COUNTRY_LIST";
const SET_LOCATION_LIST = "SET_LOCATION_LIST";
const SET_WAREHOUSE_LIST = "SET_WAREHOUSE_LIST";
const RESET_COMMON_LIST = "RESET_COMMON_LIST";

const SET_CATEGORY_LIST = "SET_CATEGORY_LIST";
const SET_CONSIGNER_LIST = "SET_CONSIGNER_LIST";

export {
  LOGIN,
  SET_AUTH_DATA,
  LOGOUT,
  SET_USER_DETAIL,
  UPDATE_USER_LOCATION,
  SET_COUNTRY_LIST,
  SET_LOCATION_LIST,
  SET_WAREHOUSE_LIST,
  RESET_COMMON_LIST,
  UPDATE_USER_WAREHOUSE,
  SET_CATEGORY_LIST,
  SET_CONSIGNER_LIST,
};
