/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HTTPSServices from "services/https/HTTPSServices";
import { RESPONSE_SUCCESS } from "services/context/response";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import DataTable from "components/Tables/DataTable";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import { CircularProgress, Icon, IconButton } from "@mui/material";
import CustomerMgr from "layouts/Customers/CustomerMgr";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import moment from "moment/moment";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";

const {
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  getStorageItem,
  getResponseMsg,
  convertTimeZoneFormatDateTime,
  setStorageItem,
  ORDERS,
  WAREHOUSE_QUALITY_CHECK,
} = InventoryMgr;

function ServiceHistory({ onClose, userDetail, subitemData }) {
  const location = useLocation();
  const navigate = useNavigate();

  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const [successSB, setSuccessSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPayInvoice, setShowPayInvoice] = useState(false);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [prevPageLink, setPrevPageLink] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [sortType, setSortType] = useState("asc");

  const [transactionList, setTransactionList] = useState([]);
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  const [entriesPerPage, setEntriesPerPage] = useState(25);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenDialog = () => setShowPayInvoice(true);
  const handleCloseDialog = () => setShowPayInvoice(false);

  const showOrderDetail = (values) => {
    // console.log(values);
    // loadDetails(values);
    setStorageItem("current_qc_svc_history_detail", values);

    window.open(WAREHOUSE_QUALITY_CHECK.DETAIL_HISTORY_ROUTE, "_blank", "noreferrer");
    // navigate(WAREHOUSE_QUALITY_CHECK.DETAIL_ROUTE, {
    //   state: { from: location, searchKeyword },
    //   replace: true,
    // });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Date",
        accessor: "date",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const status = (
            <MDTypography
              variant="button"
              color="text"
              onClick={() => showOrderDetail(row?.original)}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
                disabled
              >
                {/* {row.values.name} */}
                {/* {formatDate(value, "LLL")} */}
                {convertTimeZoneFormatDateTime(
                  value,
                  userDetail?.location?.timezone?.name,
                  userDetail?.location?.timezone?.abbreviation,
                  "LLL"
                )}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        headerAlign: "left",
        width: "20%",
        Cell: ({ row, value }) => {
          const status = (
            <MDTypography variant="button" fontWeight="regular">
              {value}
            </MDTypography>
          );

          return status;
        },
      },

      {
        Header: "action",
        accessor: "action",
        headerAlign: "left",
        width: "15%",
        Cell: ({ row, value }) => {
          const textColor = () => {
            if (value?.value === "change_in_condition") {
              return "error";
            }
            if (value?.value === "no_change_in_condition") {
              return "success";
            }
            return "dark";
          };
          const status = (
            <MDTypography variant="button" fontWeight="regular" color={textColor()}>
              {value?.name}
            </MDTypography>
          );

          return status;
        },
      },
    ],
    rows: transactionList || [],
  };

  const dataTableDataMobile = {
    columns: [
      {
        Header: "Info",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const textColor = () => {
            if (row?.original?.action?.value === "change_in_condition") {
              return "error";
            }
            if (row?.original?.action?.value === "no_change_in_condition") {
              return "success";
            }
            return "dark";
          };
          const status = (
            <MDBox
            // onClick={() => console.log(row?.original?.date)}
            >
              <MDBox>
                <MDTypography
                  variant="button"
                  color="text"
                  //   onClick={() => showOrderDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                    disabled
                  >
                    {/* {row.values.name} */}
                    {/* {formatDate(row?.original?.date, "LLL")} */}

                    {convertTimeZoneFormatDateTime(
                      row?.original?.date,
                      userDetail?.location?.timezone?.name,
                      userDetail?.location?.timezone?.abbreviation,
                      "LLL"
                    )}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular">
                  {row?.original?.created_by}
                </MDTypography>
              </MDBox>
              <MDBox>
                <MDTypography variant="button" fontWeight="regular" color={textColor()}>
                  {row?.original?.action?.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },

      //   {
      //     Header: "Created By",
      //     headerAlign: "left",
      //     width: "15%",
      //     Cell: ({ row, value }) => {
      //       const status = (
      //         <MDBox>
      //           <MDTypography variant="button" fontWeight="regular">
      //             {row?.original?.created_by}
      //           </MDTypography>
      //         </MDBox>
      //       );

      //       return status;
      //     },
      //   },
    ],
    rows: transactionList || [],
  };

  const loadList = async (searchKeywordVal = "", entriesPerPageVal = 25) =>
    // console.log(statusFilterList);
    httpRequest("service-history", null, {
      uuid: itemDetail?.uuid,
      subitemUuid: subitemData?.uuid,
      entriesPerPage: entriesPerPageVal,
    });

  const processRows = (data = []) => {
    const rentalHistory = [];

    data.map((history) => rentalHistory.push({ ...history, created_by: history?.user?.name }));

    return rentalHistory;
  };

  const getTransactionList = async () => {
    // console.log(itemDetail);
    setIsLoading(true);

    const response = await httpRequest("service-history", null, {
      uuid: itemDetail?.uuid,
      subitemUuid: subitemData?.uuid,
      entriesPerPage,
    });

    // debugger;
    if (getResponseMsg(response) === "Success") {
      //   console.log(processRows(response?.data?.data?.results));
      setTransactionList(processRows(response?.data?.data?.results));
    }

    setIsLoading(false);
    return 0;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setTransactionList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(await loadList(val, entriesPerPage));
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(await loadList(searchKeyword, val));
    setIsLoading(false);
  };

  useEffect(async () => {
    // getTransactionList();

    setIsLoading(true);
    const qcListResponse = await loadList(searchKeyword, entriesPerPage);
    proccessResponse(qcListResponse);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Service History
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      {/* {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </MDBox>
      ) : ( */}
      {/* <DataTable
        table={isMobile ? dataTableDataMobile : dataTableData}
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        canSearch={false}
        hiddenCols={["file_url"]}
        isLoading={isLoading}
      /> */}
      <DataTable
        table={!isMobile ? dataTableData : dataTableDataMobile}
        noEndBorder
        // details={showItemDetail}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        // handleSearch={handleSearchKeyword}
        // popupDetailScan={handleSearchBarcodePopUpDetail}
        handleChangeEntries={handleChangeEntries}
        handleSort={handleSort}
        hasNextPage={nextPageLink !== null}
        hasPrevPage={prevPageLink !== null}
        totalCount={totalRows}
        keyPressSearch
        // canScanQr
        isMobile={isMobile}
        canRefresh={false}
        canSearch={false}
        entriesPerPage={{
          show: true,
          defaultValue: 25,
          entries: [10, 25, 50, 100],
        }}
        // title="Quality-Check"
        isLoading={isLoading}
      />
      {/* )} */}
    </MDBox>
  );
}
ServiceHistory.defaultProps = {
  userDetail: {},
  subitemData: {},
};

ServiceHistory.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
  subitemData: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(ServiceHistory);
