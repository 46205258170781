/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Autocomplete, Divider, Icon, IconButton, Menu, TextField } from "@mui/material";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import InventoryMgr from "layouts/Inventory/InventoryMgr";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";

const {
  INVENTORY,
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getResponseMsg,
  getStorageItem,
} = InventoryMgr;
const { getSubCategoryList } = SubcategoryMgr;

function KitsItems({ categoriesListRedux, handleClose, setKitItemList, kitItemList }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const [itemList, setItemList] = useState([]);
  const [subCategoryListOptions, setSubCategoryListOptions] = useState([]);
  const [categoryFilterValue, setCategoryFilterValue] = useState(null);
  const [subCategoryFilterValue, setSubCategoryFilterValue] = useState(null);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [menu, setMenu] = useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const showItemDetail = (values) => {
    setStorageItem("current_item_detail", values);
    navigate(INVENTORY.DETAIL_ROUTE, { state: { from: location }, replace: true });
  };

  const dataTableData = {
    columns: [
      { accessor: "uuid", width: "10%" },
      { Header: "item name", headerAlign: "left", accessor: "name", width: "15%", hasSort: true },
      {
        Header: "Quantity Owned",
        accessor: "owned",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox
              bgColor={value <= 0 ? "error" : "success"}
              display="flex"
              justifyContent="center"
              textAlign="center"
              width="4rem"
              sx={{ border: "2px ", borderRadius: "5px" }}
            >
              <MDTypography variant="button" fontWeight="regular" color="white">
                {value}
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },

      { Header: "category", accessor: "category.name", width: "15%" },
      { Header: "sub-category", headerAlign: "left", accessor: "subcategory.name", width: "15%" },
      {
        Header: "action",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const handleAdd = () => {
            setKitItemList("add-sub-item", { kitItem: { ...row?.original, quantity: 0 } });
            handleClose();
          };

          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <IconButton
                size="small"
                aria-label="edit"
                color="info"
                onClick={() => {
                  handleAdd();
                }}
              >
                <Icon fontSize="small">add</Icon>
              </IconButton>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList,
  };

  const processRows = (data = []) => {
    const items = [];
    let newData = data;
    // console.log("called");
    for (let i = 0; i < data.length; i += 1) {
      for (let j = 0; j < kitItemList.length; j += 1) {
        newData = newData.filter((item) => item?.uuid !== kitItemList[j]?.uuid);
      }
    }

    newData = newData.filter((item) => item?.uuid !== itemDetail?.uuid);

    newData.map((item) =>
      items.push({
        uuid: item.uuid,
        name: item.name,
        price: item?.price,
        local_qty: item?.local_qty,
        daily: `$${item?.price?.daily}`,
        description: item.description,
        item_type: item.item_type,
        owned: item.local_qty?.owned,
        available: item.local_qty?.available,
        on_rent: item.local_qty?.on_rent,
        in_repair: item.local_qty?.in_repair,
        global_available_qty: item.global_available_qty,
        category: item?.category,
        subcategory: item?.subcategory,
      })
    );

    return items;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setItemList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const handleOnChangeCategoryFilter = async (e, val) => {
    if (val !== null && val?.uuid !== "") {
      setIsFilterLoading(true);
      setCategoryFilterValue(val);
      const response = await getSubCategoryList(val?.uuid, "", 0);
      setSubCategoryListOptions(response?.data?.data);
      setIsFilterLoading(false);
    }

    setSubCategoryFilterValue(null);
  };

  const handleFilter = async (action) => {
    setIsLoading(true);
    switch (action) {
      case "apply-filter":
        proccessResponse(
          await httpRequest(
            "inventory-list",
            {},
            {
              searchKeyword,
              entriesPerPage,
              categoryId: categoryFilterValue?.uuid,
              subCategoryId: subCategoryFilterValue?.uuid,
            }
          )
        );
        break;

      case "remove-filter":
        setCategoryFilterValue(null);
        setSubCategoryFilterValue(null);
        proccessResponse(
          await httpRequest("inventory-list", {}, { searchKeyword, entriesPerPage })
        );
        break;
      default:
        break;
    }

    closeMenu();
    setIsLoading(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <Autocomplete
        disablePortal
        value={categoryFilterValue}
        options={categoriesListRedux}
        onChange={(event, newValue) => {
          handleOnChangeCategoryFilter(event, newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 5, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Categories" />}
      />

      <Autocomplete
        disablePortal
        options={subCategoryListOptions || [{}]}
        value={subCategoryFilterValue}
        onChange={(event, newValue) => {
          setSubCategoryFilterValue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.id === value.id : option.id
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 9, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Sub-Category" />}
        loading={isFilterLoading}
      />

      <Divider sx={{
        margin: "0.5rem 0",
        opacity: "0.6"
      }} />
      <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
        <MDButton
          variant="gradient"
          size="medium"
          color="info"
          onClick={() => handleFilter("apply-filter")}
        >
          Apply Filter
        </MDButton>

        <MDButton
          variant="gradient"
          size="medium"
          color="error"
          onClick={() => handleFilter("remove-filter")}
        >
          Reset Filter
        </MDButton>
      </MDBox>
    </Menu>
  );

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest(
        "inventory-list",
        {},
        {
          searchKeyword: val,
          entriesPerPage,
          categoryId: categoryFilterValue?.uuid,
          subCategoryId: subCategoryFilterValue?.uuid,
        }
      )
    );
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("inventory-list", null, { searchKeyword, entriesPerPage: val })
    );
    setIsLoading(false);
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);
    proccessResponse(await httpRequest("inventory-list", {}, { searchKeyword, entriesPerPage }));
    setIsLoading(false);
    setSearchKeyword("");

    return () => {
      controllerRequest.abort();
    };
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-start">
        <MDBox display="flex">
          <MDButton
            variant={menu ? "contained" : "outlined"}
            color={darkMode === true ? "white" : "dark"}
            onClick={openMenu}
          >
            filters&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </MDBox>
        <MDBox display="flex">
          <MDBox>
            <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
              <Icon fontSize="small">close</Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox display="flex">
        <DataTable
          table={dataTableData}
          // noEndBorder
          details={showItemDetail}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handleSearch={handleSearchKeyword}
          handleChangeEntries={handleChangeEntries}
          handleSort={handleSort}
          hasNextPage={nextPageLink !== null}
          hasPrevPage={prevPageLink !== null}
          totalCount={totalRows}
          entriesPerPage={{ defaultValue: 10, entries: [10, 25, 50, 100] }}
          title="Item List"
          isLoading={isLoading}
          canRefresh
        />
      </MDBox>
    </MDBox>
  );
}

KitsItems.defaultProps = {
  categoriesListRedux: [{}],
  kitItemList: [{}],
};

KitsItems.propTypes = {
  categoriesListRedux: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  kitItemList: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  handleClose: PropTypes.func.isRequired,
  setKitItemList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
});

export default connect(mapStateToProps, null)(KitsItems);
