/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";

import Info from "layouts/admin/Subcategories/new-sub-category/components/Info";
import form from "layouts/admin/Subcategories/new-sub-category/schemas/form";
import initialValues from "layouts/admin/Subcategories/new-sub-category/schemas/initialValues";
import validations from "layouts/admin/Subcategories/new-sub-category/schemas/validations";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";

const { SUBCATEGORIES, getStorageItem, httpRequest, RESPONSE_SUCCESS } = SubcategoryMgr;

function SubcategoriesNew({ handleClose }) {
  const { formId, formField } = form;

  const categoryData = JSON.parse(getStorageItem("current_categories_detail"));
  const subcategoriesData = JSON.parse(getStorageItem("current_sub_categories_detail"));

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setLoading] = useState(false);

  const submitForm = async (values, actions) => {
    let response = null;
    let msg = "Successfully Created sub-category";

    const tmpBody = {
      ...values,
      category: categoryData.uuid,
    };

    if (subcategoriesData?.pageState === "NEW") {
      response = await httpRequest("subcategory-create", values, { uuid: categoryData.uuid });
    } else if (subcategoriesData?.pageState === "EDIT") {
      response = await httpRequest("subcategory-update", values, {
        uuid: categoryData.uuid,
        subId: subcategoriesData?.uuid,
      });
      msg = "Successfully Updated sub-category";
    }

    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      handleClose("save", { msg });
    } else {
      setShowError(true);
      setErrMsg(response?.data?.name || response?.data?.detail);
    }

    setLoading(false);
    actions.setSubmitting(false);
  };

  const handleSave = () => {
    setShowError(false);
  };

  const handleSubmit = async (values, actions) => {
    if (values?.name === subcategoriesData?.name) {
      handleClose();
    } else {
      setShowError(false);
      setLoading(true);

      submitForm(
        {
          name: values?.name,
          category: categoryData.uuid,
        },
        actions
      );
    }
  };

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MDBox display="flex" justifyContent="space-between">
            <MDBox>
              <MDTypography variant="h6" fontWeight="medium" color="dark">
                {subcategoriesData?.pageState === "NEW"
                  ? `New ${SUBCATEGORIES.TITLE} `
                  : `Edit ${SUBCATEGORIES.TITLE}`}{" "}
                {`for ${categoryData?.name}`}
              </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between">
              <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
                <Icon fontSize="small">close</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={
              subcategoriesData?.pageState === "NEW"
                ? initialValues
                : subcategoriesData || initialValues
            }
            validationSchema={validations}
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <MDBox>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <MDBox ml={2}>
                        <Info
                          formData={{
                            values,
                            touched,
                            formField,
                            errors,
                          }}
                        />
                        {showError && (
                          <MDBox mt={-2}>
                            <MDTypography variant="caption" fontWeight="regular" color="error">
                              {errMsg}
                            </MDTypography>
                          </MDBox>
                        )}
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox display="flex" justifyContent="flex-end" mx={2}>
                    <MDButton
                      size="small"
                      variant="gradient"
                      color="error"
                      onClick={handleClose}
                      sx={{ marginRight: 1 }}
                    >
                      Cancel
                    </MDButton>
                    <MDButton
                      disabled={isSubmitting}
                      size="small"
                      type="submit"
                      variant="gradient"
                      color="info"
                      onClick={() => handleSave(errors)}
                    >
                      save
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
}

SubcategoriesNew.defaultProps = {
  handleClose: () => {},
};

SubcategoriesNew.propTypes = {
  handleClose: PropTypes.func,
};

export default SubcategoriesNew;
