import { Icon, IconButton } from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "components/Tables/DataTable";
import { useMaterialUIController } from "context";
import { debounce } from "lodash";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import OrderMgr from "layouts/Order/OrderMgr";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HTTPSServices from "services/https/HTTPSServices";

import MDTypography from "components/MDTypography";

const { INVENTORY } = InventoryMgr;
const { httpRequest, getSort, setStorageItem, getResponseMsg, hasPermission, convertToCurrency } =
    OrderMgr;

const { getRequest } = HTTPSServices;

const CustomerContactList = ({ handleClose, handleContact }) => {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const [controller] = useMaterialUIController();

    const { darkMode } = controller;

    const controllerRequest = new AbortController();

    const [contactList, setContactList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();

    const [nextPageLink, setNextPageLink] = useState("");
    const [prevPageLink, setPrevPageLink] = useState("");
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    //Saves selected item to local storage and navigates to detail page
    const showItemDetail = (values) => {
        setStorageItem("current_item_detail", values);
        navigate(INVENTORY.DETAIL_ROUTE, { state: { from: location }, replace: true });
    };

    const dataTableData = {
        columns: [
            {
                Header: "Contact Name",
                accessor: (row) => `${row?.first_name} ${row?.last_name}`,
                hasSort: true,
                width: "45%",
                headerAlign: "left",
            },

            {
                Header: "Company Name",
                accessor: (row) => row?.entity?.company_name,
                hasSort: true,
                width: "45%",
                headerAlign: "left",
            },
            {
                Header: "Actions",
                width: "10%",
                headerAlign: "center",
                align: "center",
                Cell: ({ row }) => {
                    const handleAdd = () => {
                        handleContact(row.original);
                        handleClose();
                    };

                    const AddButton = (
                        <MDBox display="flex" alignItems="center" justifyContent="center">
                            <IconButton
                                size="small"
                                aria-label="add"
                                color="info"
                                onClick={() => {
                                    handleAdd();
                                }}
                            >
                                <Icon fontSize="small">add</Icon>
                            </IconButton>
                        </MDBox>
                    );

                    return AddButton;
                },
            },
        ],
        rows: contactList,
    };

    //Processes the response from the API
    const processResponse = (response) => {
        setContactList(response?.data?.data?.results);
        setNextPageLink(response?.data?.data?.next);
        setPrevPageLink(response?.data?.data?.previous);
        setTotalRows(response?.data?.data?.count);
    };

    const loadContactList = async (searchTxt) => {
        setIsLoading(true);

        try {
            const response = await httpRequest(
                "contact-list",
                {},
                {
                    page: 1,
                    entriesPerPage: 10,
                    entity: "customer",
                    searchKeyword: searchTxt,
                }
            );

            processResponse(response);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = debounce(async (searchKeyword) => {
        loadContactList(searchKeyword);
    }, 300);

    const handleNextPage = async () => {
        setIsLoading(true);
        processResponse(await getNextPage(nextPageLink));
        setIsLoading(false);
        console.log(contactList);
    };

    const handlePrevPage = async () => {
        setIsLoading(true);
        processResponse(await getPrevPage(prevPageLink));
        setIsLoading(false);
    };

    const getNextPage = async (nextPageLink = "") => {
        return await getRequest(nextPageLink);
    };

    const getPrevPage = async (prevPageLink = "") => {
        return await getRequest(prevPageLink);
    };

    useEffect(() => {
        loadContactList();

        return () => {
            controllerRequest.abort();
        };
    }, []);

    return (
        <MDBox>
            <MDBox
                display="flex"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <MDBox>
                    <MDTypography variant="h6">Select Customer Contact</MDTypography>
                </MDBox>
                <MDBox>
                    <MDBox>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color={darkMode ? "light" : "dark"}
                            onClick={handleClose}
                        >
                            <Icon fontSize="small">close</Icon>
                        </IconButton>
                    </MDBox>
                </MDBox>
            </MDBox>
            <MDBox display="flex" style={{ paddingTop: "20px" }}>
                <DataTable
                    isLoading={isLoading}
                    table={dataTableData}
                    title="Contact List"
                    totalCount={totalRows}
                    entriesPerPage={{ defaultValue: 10, entries: [10, 25, 50, 100] }}
                    handleSearch={handleSearch}
                    handlePrevPage={handlePrevPage}
                    handleNextPage={handleNextPage}
                    hasPrevPage={prevPageLink !== null}
                    hasNextPage={nextPageLink !== null}
                    canRefresh
                />
            </MDBox>
        </MDBox>
    );
};

export default CustomerContactList;
