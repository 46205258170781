/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/admin/Subcategories/new-sub-category/components/FormField";

function Info({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { subcategoryName } = formField;
  const { name: subcategoryNameV } = values;

  return (
    <MDBox>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <FormField
            autoFocus
            type={subcategoryName.type}
            label={subcategoryName.label}
            name={subcategoryName.name}
            value={subcategoryNameV}
            placeholder={subcategoryName.placeholder}
            error={errors.subcategoryName && touched.subcategoryName}
            success={subcategoryNameV.length > 0 && !errors.subcategoryName}
          />
        </Grid>
      </Grid>
    </MDBox>
  );
}

// typechecking props for UserInfo
Info.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Info;
