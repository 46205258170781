import checkout from "layouts/Customers/new-user/schemas/form";

const {
    formField: {
        firstName,
        lastName,
        company,
        isIndividual,
        taxExempt,
        email,
        role,
        classification,
        paymentType,
        defaultDiscount,
        notes,

        line1,
        line2,
        city,
        state,
        country,
        zip,

        mobile,
        telephone,
        fax,
        other,
    },
} = checkout;

const initialValues = {
    [role.name]: "customer",
    [company.name]: "",
    [paymentType.name]: { name: "Prepay (Cash)", value: "prepay" },
    [classification.name]: { name: "Production Company", value: "production_company" },
    [defaultDiscount.name]: "0",
    [notes.name]: "",
    [isIndividual.name]: false,
    [taxExempt.name]: false,

    [line1.name]: "",
    [line2.name]: "",
    [city.name]: "",
    [state.name]: "",
    [country.name]: { name: "United States of America", value: "US" },
    [zip.name]: "",

    // Contact Information
    contacts: [
        {
            [firstName.name]: "",
            [lastName.name]: "",
            [email.name]: "",
            [mobile.name]: "",
            [telephone.name]: "",
            [fax.name]: "",
            [other.name]: "",
        },
    ],
};

export default initialValues;
