/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, IconButton, Switch } from "@mui/material";

function Info({ data }) {
    const {
        uuid,
        identifier,
        first_name,
        last_name,
        email,
        location,
        company_name,
        percentage,
        vendor_type,
        last_completed_contract,
        unpaid_balance,
        address,
        contact,
        status,
        username,
        group,
        permissions,
        warehouse,
    } = data;

    const basicInfoDetails = [
        // { label: "Identifier :", value: identifier },
        { label: "First Name :", value: first_name },
        { label: "Last Name :", value: last_name },
        { label: "User Company Email Address :", value: email },
    ];

    const otherInfo = [
        { label: "Location :", value: location?.name },
        { label: "Warehouse :", value: warehouse?.name },
        { label: "Username:", value: username },
        { label: "Group:", value: group?.name },
    ];

    const permissionDetails = [
        { label: "Permissions 1 ", value: permissions },
        { label: "Permissions 2", value: permissions },
        { label: "Permissions 3", value: permissions },
        { label: "Permissions 4", value: permissions },
        { label: "Permissions 52", value: permissions },
        { label: "Permissions 6", value: permissions },
        { label: "Permissions 7", value: permissions },
        { label: "Permissions 8", value: permissions },
        { label: "Permissions 9", value: permissions },
    ];

    const renderItems = (items) =>
        items.map((item) => (
            <MDBox display="flex" key={item.label} py={0.5} pr={2}>
                {/* {item.value !== "" && ( */}
                <MDBox>
                    <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                        {item.label} &nbsp;
                    </MDTypography>
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        &nbsp;{item.value || "-"}
                    </MDTypography>
                </MDBox>
                {/* )} */}
            </MDBox>
        ));

    const renderPermissionItems = (
        items = [{ name: "permission 1", value: 1, isPermitted: true }]
    ) =>
        items.map((item) => (
            <Grid container xs={12} md={12} xl={6}>
                <MDBox display="flex" alignItems="center" mb={0.5}>
                    <MDBox mt={0.5}>
                        <Switch checked={item?.has_permission} disabled />
                    </MDBox>
                    <MDBox width="80%" ml={0.5}>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            {item?.name}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </Grid>
        ));

    return (
        // <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
        <MDBox mx={1}>
            <MDBox display="flex" justifyContent="space-between">
                <MDBox p={2} height="100%" mt={0.5} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                        {`${first_name || ""} ${last_name || ""}`}
                    </MDTypography>
                    {/* <MDTypography variant="button" color="text" fontWeight="regular">
            {company_name === "N/A" ? "Individual" : `Company name: ${company_name || ""}`}
          </MDTypography> */}
                    {/* <MDTypography variant="button" color="text" fontWeight="regular">
            {email}
          </MDTypography> */}
                    {/* <MDBox>
            <MDTypography variant="caption" fontWeight="bold" textTransform="capitalize">
              Status: &nbsp;
            </MDTypography>
            <IconButton
              size="small"
              aria-label="edit"
              color={status === "active" ? "success" : "error"}
            >
              <Icon fontSize="small">{status === "active" ? "person" : "person_off"}</Icon>
            </IconButton>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{status || "----/--/--"}
            </MDTypography>
          </MDBox> */}
                </MDBox>
                <MDBox p={2} lineHeight={1}>
                    {/* <MDBox>
            <MDTypography variant="caption" fontWeight="bold" textTransform="capitalize">
              Last completed contract: &nbsp;
            </MDTypography>

            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{last_completed_contract || "----/--/--"}
            </MDTypography>
          </MDBox> */}
                    <MDBox>
                        <MDTypography
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Status: &nbsp;
                        </MDTypography>
                        <IconButton
                            size="small"
                            aria-label="edit"
                            color={status === "active" ? "success" : "error"}
                        >
                            <Icon fontSize="small">
                                {status === "active" ? "person" : "person_off"}
                            </Icon>
                        </IconButton>
                        <MDTypography variant="button" fontWeight="regular" color="text">
                            &nbsp;{status || "----/--/--"}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>

            <Grid container spacing={1}>
                <Grid item xs={12} md={12} xl={5}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            User Info
                        </MDTypography>

                        <Grid container>{renderItems(basicInfoDetails)}</Grid>
                    </MDBox>
                </Grid>

                <Grid item xs={12} md={12} xl={5}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Other Info
                        </MDTypography>
                        <Grid container>{renderItems(otherInfo)}</Grid>
                    </MDBox>
                </Grid>

                <Grid item xs={12} md={6} xl={6}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Inventory Permissions
                        </MDTypography>
                        {/* <Grid container>{renderItems(permissionDetails)}</Grid> */}
                        <Grid container>{renderPermissionItems(permissions?.inventory)}</Grid>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Customer Permissions
                        </MDTypography>
                        {/* <Grid container>{renderItems(permissionDetails)}</Grid> */}
                        <Grid container>{renderPermissionItems(permissions?.customers)}</Grid>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Orders Permissions
                        </MDTypography>
                        {/* <Grid container>{renderItems(permissionDetails)}</Grid> */}
                        <Grid container>{renderPermissionItems(permissions?.orders)}</Grid>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Payments Permissions
                        </MDTypography>
                        {/* <Grid container>{renderItems(permissionDetails)}</Grid> */}
                        <Grid container>{renderPermissionItems(permissions?.payments)}</Grid>
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                    <MDBox pb={1} pl={2}>
                        <MDTypography variant="h6" color="info">
                            Users Permissions
                        </MDTypography>
                        {/* <Grid container>{renderItems(permissionDetails)}</Grid> */}
                        <Grid container>{renderPermissionItems(permissions?.users)}</Grid>
                    </MDBox>
                </Grid>
                {/* <Grid item xs={12} md={6} xl={12}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Admin Permissions
            </MDTypography>
            <Grid container>{renderPermissionItems()}</Grid>
          </MDBox>
        </Grid> */}
            </Grid>
        </MDBox>
    );
}

Info.defaultProps = {
    data: {},
    address: {},
    contact: {},
};

Info.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    address: PropTypes.objectOf(PropTypes.any),
    contact: PropTypes.objectOf(PropTypes.any),
};

export default Info;
