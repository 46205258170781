/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Material Dashboard 2 PRO React components
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import OrderMgr from "layouts/Order/OrderMgr";

const { convertToCurrency } = OrderMgr;

function OrderSummary({ data }) {
  const {
    deposit_amount,
    order_total,
    sales_total,
    rental_total,
    tax_rate,
    invoice_total,
    status,
    subtotal,
    total_amount_paid,
    remaining_balance,
    // notes,
  } = data;

  const tax_price = subtotal * Number(tax_rate / 100 || 0);

  const pricingDetails = [
    { label: "Sales Total", value: `${convertToCurrency(sales_total)}` },
    { label: "Inventory Total", value: `${convertToCurrency(rental_total)}` },
    { label: "Subtotal", value: `${convertToCurrency(subtotal)}` },
    { label: "Tax", value: `${convertToCurrency(tax_price) || 0}` },
    // { label: "Total cost of Order", value: `${order_total || 0}` },
    // { label: "Discount total", value: `${tax_rate || 0} %` },
    { label: "Invoice Total", value: `${convertToCurrency(invoice_total || 0)}` },
    { label: "Amount Paid", value: `${convertToCurrency(total_amount_paid)}` },
    {
      label: "Remaining Balance",
      value: `${convertToCurrency(remaining_balance)}`,
    },
  ];
  const renderFullWidthItems = (items) =>
    items.map((item) => (
      <Grid item xs={12} md={6} xl={12} key={item.name}>
        <MDBox display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value}
          </MDTypography>
        </MDBox>
      </Grid>
    ));

  return (
    <MDBox>
      <MDBox p={2} display="flex" justifyContent="flex-end">
        <MDBox display="flex">
          <MDTypography variant="button" fontWeight="medium">
            Order Status:
          </MDTypography>

          <MDTypography
            variant="button"
            fontWeight="medium"
            color={status?.value === "canceled" ? "error" : "success"}
          >
            &nbsp;{status?.name}
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={3.5}>
        <MDTypography variant="h6" color="info">
          Order Summary
        </MDTypography>
      </MDBox>
      <Grid container>
        {/* <Grid item xs={12} md={6} xl={6}>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Product Price: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;$90
            </MDTypography>
          </MDBox>
        </Grid> */}
        {renderFullWidthItems(pricingDetails)}
      </Grid>
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Product Price:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $90
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Delivery:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $14
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Taxes:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $1.95
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mt={3}>
        <MDTypography variant="body1" fontWeight="light" color="text">
          Total:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body1" fontWeight="medium">
            $1.95
          </MDTypography>
        </MDBox>
      </MDBox> */}
    </MDBox>
  );
}

OrderSummary.defaultProps = {
  data: {},
};

OrderSummary.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default OrderSummary;
