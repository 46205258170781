/* eslint-disable prefer-const */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import OrderMgr from "layouts/Order/OrderMgr";

import InventoryMgr from "layouts/Inventory/InventoryMgr";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import MDInput from "components/MDInput";

const { INVENTORY } = InventoryMgr;
const { httpRequest, ORDERS, getResponseMsg, hasPermission } = OrderMgr;

function CancelOrder({ data }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const { status } = data;
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const [succMsg, setSuccMsg] = useState("");
  const [cancelMessage, setCancelMessage] = useState("");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errSb, setErrSb] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelErrMsg, setShowCancelErrMsg] = useState(false);
  const [showTooltip, setShoWTooltip] = useState(false);

  const openTooltip = () => setShoWTooltip(true);
  const closeTooltip = () => setShoWTooltip(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrSB = () => setErrSb(true);
  const closeErrSB = () => setErrSb(false);

  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

  const handleConfirm = async (action) => {
    setIsLoading(true);
    let cancelResponse = {};

    switch (action) {
      case "cancel":
        // console.log(cancelMessage.length === 0);
        if (cancelMessage.length === 0) {
          setShowCancelErrMsg(true);
        } else {
          setShowCancelErrMsg(false);

          handleCloseDeleteDialog();
          cancelResponse = await httpRequest(
            "order-cancel",
            { cancellation_reason: cancelMessage },
            {
              uuid: data.uuid,
            }
          );

          if (getResponseMsg(cancelResponse) === "Success") {
            setSuccMsg("Order Canceled Successfully.");
            openSuccessSB(true);

            setTimeout(() => {
              setIsLoading(false);
              navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
            }, 1000);
          } else {
            setErrMsg(cancelResponse.data.cancellation_reason[0]);
            openErrSB(true);
          }
        }

        break;
      default:
        break;
    }

    // debugger;

    setIsLoading(false);
  };

  return (
    <Card id="delete-account">
      {isLoading && <SimpleBackdrop />}
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Cancel Order </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="button" color="warning">
              ** Once you Cancel the order, we will notify you through your email. **
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <Tooltip
            title="You don't have permission to do this"
            placement="top"
            followCursor
            open={showTooltip && !hasPermission("cancel_order", permissions?.orders)}
            onClose={closeTooltip}
            onOpen={openTooltip}
          >
            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <MDButton
                variant="gradient"
                color="error"
                sx={{ height: "100%" }}
                onClick={handleOpenDeleteDialog}
                disabled={
                  status?.value === "canceled" ||
                  !hasPermission("cancel_order", permissions?.orders)
                }
              >
                Cancel Order
              </MDButton>
            </MDBox>
          </Tooltip>
        </MDBox>
      </MDBox>

      {/* {showDeleteDialog && (
        <DraggableDialog
          title="Cancel Order"
          description="Are you sure you want to cancel this order?"
          buttonDesc="Confirm"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={() => handleConfirm("cancel")}
          fullWidth={false}
        />
      )} */}
      {showDeleteDialog && (
        // <div>
        <Dialog open={showDeleteDialog} onClose={handleCloseDeleteDialog}>
          <DialogTitle>Cancel Order</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to cancel this order?</DialogContentText>
            <DialogContentText>(Please specify a reason for cancelation.)</DialogContentText>
            <MDInput
              autoComplete="off"
              autoFocus
              margin="dense"
              id="name"
              // label="Reason for cancelation"
              type="email"
              fullWidth
              variant="standard"
              multiline
              rows={2}
              onChange={(e) => {
                setCancelMessage(e.target.value);
                // if (e.target.value.length === 0) {
                //   setShowCancelErrMsg(true);
                // } else {
                //   setShowCancelErrMsg(false);
                // }
              }}
              row
            />
            {showCancelErrMsg && (
              <MDTypography variant="caption" fontWeight="regular" color="error">
                Reason is required for cancelation.
              </MDTypography>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDeleteDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handleConfirm("cancel")}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}

      <MDSnackbar
        color="success"
        icon="check"
        title="Item Deactivation"
        dateTime=""
        content={succMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />

      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Deactivation"
        dateTime=""
        content={errMsg}
        open={errSb}
        onClose={closeErrSB}
        close={closeErrSB}
        bgWhite={false}
      />
    </Card>
  );
}
CancelOrder.defaultProps = {
  data: {},
};

CancelOrder.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default CancelOrder;
