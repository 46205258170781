/* eslint-disable no-unused-vars */
/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// @mui icons
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React layouts
import Analytics from "./layouts/dashboards/analytics";
import CustomerSearch from "./layouts/Customers/CustomerSearch";
import CustomerNew from "./layouts/Customers/CustomerNew";
import CustomerDetail from "./layouts/Customers/CustomerDetail";
import InventorySearch from "./layouts/Inventory/InventorySearch";
// import CustomerNew from "./layouts/Customers/CustomerNew";
// import CustomerDetail from "./layouts/Customers/CustomerDetail";
// import CustomerEdit from "./layouts/Customers/CustomerEdit";
// import InventoryTypeSearch from "./layouts/admin/InventoryType/InventoryTypeSearch";
// import CustomerNew from "./layouts/Customers/CustomerNew";
// import CustomerDetail from "./layouts/Customers/CustomerDetail";
// import CustomerEdit from "./layouts/Customers/CustomerEdit";
import SignIn from "./layouts/authentication/pages/Signin";
import ResetPassword from "./layouts/authentication/pages/ResetPassword";
import SetPassword from "./layouts/authentication/pages/SetPassword";

// import Sales from "./layouts/dashboards/sales";
// import ProfileOverview from "./layouts/pages/profile/profile-overview";
// import AllProjects from "./layouts/pages/profile/all-projects";
// import NewUser from "./layouts/pages/users/new-user";
// import Settings from "./layouts/pages/account/settings";
// import Billing from "./layouts/pages/account/billing";
// import Invoice from "./layouts/pages/account/invoice";
// import Timeline from "./layouts/pages/projects/timeline";
// import PricingPage from "./layouts/pages/pricing-page";
// import Widgets from "./layouts/pages/widgets";
// import RTL from "./layouts/pages/rtl";
// import Charts from "./layouts/pages/charts";
// import Notifications from "./layouts/pages/notifications";
// import Kanban from "./layouts/applications/kanban";
// import Wizard from "./layouts/applications/wizard";
// import Calendar from "./layouts/applications/calendar";
// import NewProduct from "./layouts/ecommerce/products/new-product";
// import EditProduct from "./layouts/ecommerce/products/edit-product";
// import ProductPage from "./layouts/ecommerce/products/product-page";
// import OrderList from "./layouts/ecommerce/orders/order-list";
// import OrderDetails from "./layouts/ecommerce/orders/order-details";

// Material Dashboard 2 PRO React components
// import MDAvatar from "./components/MDAvatar";

// Services
import roles from "./services/context/roles";
import pathUrl from "./services/context/pathUrl";
import TestUi from "./layouts/TestUi";

import CustomerMgr from "./layouts/Customers/CustomerMgr";
import CategoriesSearch from "./layouts/admin/Categories/CategoriesSearch";
// import CategoriesNew from "./layouts/admin/Categories/CategoriesNew";
import InventoryNew from "./layouts/Inventory/InventoryNew";
import InventoryDetail from "./layouts/Inventory/InventoryDetail";
import InventoryMgr from "./layouts/Inventory/InventoryMgr";
import CategoryMgr from "./layouts/admin/Categories/CategoryMgr";
import ManageVendorSearch from "./layouts/admin/ManageVendors/VendorSearch";
import ManageVendorNew from "./layouts/admin/ManageVendors/VendorNew";
import ManageVendorDetail from "./layouts/admin/ManageVendors/VendorDetail";
import VendorSearch from "./layouts/Vendors/VendorSearch";
import VendorNew from "./layouts/Vendors/VendorNew";
import VendorDetail from "./layouts/Vendors/VendorDetail";
import OrderSearch from "./layouts/Order/OrderSearch";
import OrderNew from "./layouts/Order/OrderNew";
import OrderDetail from "./layouts/Order/OrderDetail";
import UsersMgr from "./layouts/admin/ManageUsers/UsersMgr";
import UserSearch from "./layouts/admin/ManageUsers/UserSearch";
import UserDetail from "./layouts/admin/ManageUsers/UserDetail";
import LocationSearch from "./layouts/admin/ManageLocation/LocationSearch";
import LocationDetail from "./layouts/admin/ManageLocation/LocationDetail";
import LocationNew from "./layouts/admin/ManageLocation/LocationNew";
import UserNew from "./layouts/admin/ManageUsers/UserNew";
import PrintOrderDetail from "./layouts/Order/PrintOrderDetail";
import OrderMgr from "./layouts/Order/OrderMgr";
import VendorMgr from "./layouts/Vendors/VendorMgr";
import LocationMgr from "./layouts/admin/ManageLocation/LocationMgr";
import PageNotFound from "./layouts/404PageNotFound";
import PaymentSuccess from "./layouts/PaymentSuccess";
import ReportsMgr from "./layouts/Reports/ReportsMgr";
import ReportSearch from "./layouts/Reports/ReportSearch";
import WarehouseMgr from "./layouts/Warehouse/WarehouseMgr";
import StagingSearch from "./layouts/Warehouse/Staging/StagingSearch";
import StagingDetails from "./layouts/Warehouse/Staging/StagingDetails";
import CheckinSearch from "./layouts/Warehouse/Checkin/CheckinSearch";
import CheckinDetails from "./layouts/Warehouse/Checkin/CheckinDetails";
import CheckoutSearch from "./layouts/Warehouse/Checkout/CheckoutSearch";
import CheckoutDetails from "./layouts/Warehouse/Checkout/CheckoutDetails";
import QualityCheckSearch from "./layouts/Warehouse/QualityCheck/QualityCheckSearch";
import QualityCheckDetails from "./layouts/Warehouse/QualityCheck/QualityCheckDetails";
import QualityCheckDetailsHistory from "./layouts/Warehouse/QualityCheck/QualityCheckDetailsHistory";
import Reports from "./layouts/Reports/Reports";
// import UserEdit from "./layouts/admin/ManageUsers/UserEdit";

const { CUSTOMERS } = CustomerMgr;
const { INVENTORY } = InventoryMgr;
const { CATEGORIES } = CategoryMgr;
const { MANAGE_USERS } = UsersMgr;
const { ORDERS } = OrderMgr;
const { VENDORS } = VendorMgr;
const { MANAGE_LOCATIONS } = LocationMgr;
const { REPORTS } = ReportsMgr;
const { WAREHOUSE_CHECKIN, WAREHOUSE_STAGE, WAREHOUSE_CHECKOUT, WAREHOUSE_QUALITY_CHECK } =
  WarehouseMgr;

const { MANAGER, AGENT, OPERATOR, CUSTOMER, SUBVENDOR, CONSIGNER, ADMIN } = roles;
const {
  LOGIN,
  // DASHBOARD,
  _DASHBOARD,
  RESET_PASSWORD,
  SET_NEW_PASSWORD,
  // MANAG_TEMPLATES,
  // MANAGE_LOCATIONS,
  // MANAGE_WAREHOUSE,
  MANAGE_VENDORS,
  // REPORTS,
  ACCESS_DENIED,
  SUCCESS_PAYMENT,
} = pathUrl;

const routes = [
  // {
  //   type: "collapse",
  //   name: "Brooklyn Alice",
  //   key: "brooklyn-alice",
  //   icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
  //   collapse: [
  //     {
  //       name: "My Profile",
  //       key: "my-profile",
  //       route: "/pages/profile/profile-overview",
  //       component: <ProfileOverview />,
  //     },
  //     {
  //       name: "Settings",
  //       key: "profile-settings",
  //       route: "/pages/account/settings",
  //       component: <Settings />,
  //     },
  //     {
  //       name: "Logout",
  //       key: "logout",
  //       route: "/authentication/sign-in/basic",
  //       component: <SignInBasic />,
  //     },
  //   ],
  // },
  // { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    route: _DASHBOARD.HOMEPAGE,
    component: <Analytics />,
    icon: <Icon fontSize="medium">dashboard</Icon>,
    noCollapse: true,
    requireAuth: true,
    // accessCode: "can_view_dashboard",
    accessRole: [MANAGER.level, AGENT.level, CUSTOMER.level, ADMIN.level, OPERATOR.level],
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customer list",
    route: CUSTOMERS.LIST_ROUTE,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <CustomerSearch />,
    noCollapse: true,
    requireAuth: true,
    accessCode: "view_customer",
    accessRole: [ADMIN.level, MANAGER.level, AGENT.level],
  },
  {
    name: "New Customer",
    key: "new-customer",
    route: CUSTOMERS.CREATE_ROUTE,
    component: <CustomerNew />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Customer Detail",
    key: "customer-detail",
    route: CUSTOMERS.DETAIL_ROUTE,
    component: <CustomerDetail />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Customer Edit",
    key: "customer-edit",
    route: CUSTOMERS.EDIT_ROUTE,
    component: <CustomerNew />,
    requireAuth: false,
    accessRole: [],
  },
  {
    type: "collapse",
    name: "Inventory",
    key: "inventory",
    route: INVENTORY.LIST_ROUTE,
    icon: <Icon fontSize="medium">inventory</Icon>,
    component: <InventorySearch />,
    noCollapse: true,
    requireAuth: true,
    accessCode: "view_inventoryitem",
    accessRole: [],
  },
  {
    name: "New Item",
    key: "new-item",
    route: INVENTORY.CREATE_ROUTE,
    component: <InventoryNew />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "item Detail",
    key: "item-detail",
    route: INVENTORY.DETAIL_ROUTE,
    component: <InventoryDetail />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "item Edit",
    key: "item-edit",
    route: INVENTORY.EDIT_ROUTE,
    component: <InventoryNew />,
    requireAuth: false,
    accessRole: [],
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    route: ORDERS.LIST_ROUTE,
    icon: <Icon fontSize="medium">store</Icon>,
    component: <OrderSearch />,
    noCollapse: true,
    requireAuth: true,
    accessCode: "view_order",
    accessRole: [],
  },
  {
    name: "Order Detail",
    key: "order-detail",
    route: ORDERS.DETAIL_ROUTE,
    component: <OrderDetail />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Order Detail",
    key: "order-detail",
    route: ORDERS.PRINT_DETAIL,
    component: <PrintOrderDetail />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "New Order",
    key: "new-order",
    route: ORDERS.CREATE_ROUTE,
    component: <OrderNew />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Edit Order",
    key: "edit-order",
    route: ORDERS.EDIT_ROUTE,
    component: <OrderNew />,
    requireAuth: false,
    accessRole: [],
  },
  {
    type: "collapse",
    name: "Vendors",
    key: "vendors",
    route: VENDORS.LIST_ROUTE,
    icon: <Icon fontSize="medium">people</Icon>,
    component: <VendorSearch />,
    noCollapse: true,
    requireAuth: true,
    // accessCode: "view_vendor",
    accessRole: [MANAGER.level, AGENT.level, CUSTOMER.level, ADMIN.level, OPERATOR.level],
  },
  {
    type: "collapse",
    name: "Warehouse",
    key: "warehouse",
    icon: <Icon fontSize="medium">warehouse</Icon>,
    collapse: [
      {
        name: "Staging",
        key: "staging",
        route: WAREHOUSE_STAGE.LIST_ROUTE,
        icon: <Icon fontSize="medium">approval</Icon>,
        component: <StagingSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
      {
        name: "Check-out",
        key: "check-out",
        route: WAREHOUSE_CHECKOUT.LIST_ROUTE,
        icon: <Icon fontSize="medium">shopping_cart_checkout</Icon>,
        component: <CheckoutSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
      {
        name: "Check-in",
        key: "check-in",
        route: WAREHOUSE_CHECKIN.LIST_ROUTE,
        icon: <Icon fontSize="medium">done_outline</Icon>,
        component: <CheckinSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
      {
        name: "Quality-Check",
        key: "/quality-check/",
        route: WAREHOUSE_QUALITY_CHECK.LIST_ROUTE,
        icon: <Icon fontSize="medium">assignment_return</Icon>,
        component: <QualityCheckSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
    ],
    requireAuth: true,
    accessRole: [MANAGER.level, AGENT.level, CUSTOMER.level, ADMIN.level, OPERATOR.level],
  },

  {
    type: "collapse",
    name: "Admin",
    key: "admin",
    icon: <Icon fontSize="medium">admin_panel_settings</Icon>,
    collapse: [
      {
        name: "Item Categories",
        key: "manage-categories",
        route: CATEGORIES.LIST_ROUTE,
        icon: <Icon fontSize="medium">category</Icon>,
        component: <CategoriesSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
      // {
      //   name: "Users",
      //   key: "manage-users",
      //   route: MANAGE_USERS.route,
      //   icon: <Icon fontSize="medium">support_agent</Icon>,
      //   component: <Analytics />,
      //   requireAuth: true,
      //   accessRole: [HIDDEN.level],
      // },
      // {
      //   name: "Templates",
      //   key: "manage-templates",
      //   route: MANAG_TEMPLATES.route,
      //   icon: <Icon fontSize="medium">article</Icon>,
      //   component: <Analytics />,
      //   requireAuth: true,
      //   accessRole: [HIDDEN.level],
      // },
      // {
      //   name: "Locations",
      //   key: "manage-locations",
      //   route: MANAGE_LOCATIONS.route,
      //   icon: <Icon fontSize="medium">place</Icon>,
      //   component: <Analytics />,
      //   requireAuth: true,
      //   accessRole: [HIDDEN.level],
      // },
      // {
      //   name: "Warehouse",
      //   key: "manage-warehouse",
      //   route: MANAGE_WAREHOUSE.route,
      //   icon: <Icon fontSize="medium">factory</Icon>,
      //   component: <Analytics />,
      //   requireAuth: true,
      //   accessRole: [HIDDEN.level],
      // },
      {
        name: "Manage Vendors",
        key: "manage-vendor",
        route: MANAGE_VENDORS.LIST_ROUTE,
        icon: <Icon fontSize="medium">people</Icon>,
        component: <ManageVendorSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
      {
        name: "Manage Users",
        key: "manage-users",
        route: MANAGE_USERS.LIST_ROUTE,
        icon: <Icon fontSize="medium">person</Icon>,
        component: <UserSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
      {
        name: "Manage Locations",
        key: "manage-locations",
        route: MANAGE_LOCATIONS.LIST_ROUTE,
        icon: <Icon fontSize="medium">place</Icon>,
        component: <LocationSearch />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },

      {
        name: "Reports",
        key: "reports",
        route: REPORTS.LIST_ROUTE,
        icon: <Icon fontSize="medium">summarize</Icon>,
        component: <Reports />,
        requireAuth: true,
        accessRole: [MANAGER.level, ADMIN.level],
      },
    ],
    requireAuth: true,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  // {
  //   name: "New Category",
  //   key: "new-category",
  //   route: CATEGORIES.CREATE_ROUTE,
  //   component: <CategoriesNew />,
  //   requireAuth: false,
  //   accessRole: [MANAGER.level, ADMIN.level],
  // },
  // {
  //   name: "Category Edit",
  //   key: "category-edit",
  //   route: CATEGORIES.EDIT_ROUTE,
  //   component: <CategoriesNew />,
  //   requireAuth: false,
  //   accessRole: [MANAGER.level, ADMIN.level],
  // },
  {
    name: "New Vendor",
    key: "vendor-new",
    route: MANAGE_VENDORS.CREATE_ROUTE,
    component: <ManageVendorNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Vendor Edit",
    key: "vendor-edit",
    route: MANAGE_VENDORS.EDIT_ROUTE,
    component: <ManageVendorNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Vendor Detail",
    key: "vendor-detail",
    route: MANAGE_VENDORS.DETAIL_ROUTE,
    component: <ManageVendorDetail />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "New Vendor",
    key: "vendor-new",
    route: VENDORS.CREATE_ROUTE,
    component: <VendorNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Vendor Edit",
    key: "vendor-edit",
    route: VENDORS.EDIT_ROUTE,
    component: <VendorNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Vendor Detail",
    key: "vendor-detail",
    route: VENDORS.DETAIL_ROUTE,
    component: <VendorDetail />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "New User",
    key: "user-new",
    route: MANAGE_USERS.CREATE_ROUTE,
    component: <UserNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Edit User",
    key: "user-edit",
    route: MANAGE_USERS.EDIT_ROUTE,
    component: <UserNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "User Detail",
    key: "user-detail",
    route: MANAGE_USERS.DETAIL_ROUTE,
    component: <UserDetail />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "New Location",
    key: "location-new",
    route: MANAGE_LOCATIONS.CREATE_ROUTE,
    component: <LocationNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Edit Location",
    key: "location-edit",
    route: MANAGE_LOCATIONS.EDIT_ROUTE,
    component: <LocationNew />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "Location Detail",
    key: "location-detail",
    route: MANAGE_LOCATIONS.DETAIL_ROUTE,
    component: <LocationDetail />,
    requireAuth: false,
    accessRole: [MANAGER.level, ADMIN.level],
  },
  {
    name: "404 Access Denied",
    key: "access-denied",
    route: ACCESS_DENIED.LIST_ROUTE,
    component: <PageNotFound />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Payment Successful",
    key: "payment-success",
    route: SUCCESS_PAYMENT.PAGE_ROUTE,
    component: <PaymentSuccess />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Staging Detail",
    key: "staging-detail",
    route: WAREHOUSE_STAGE.DETAIL_ROUTE,
    component: <StagingDetails />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Checkin Detail",
    key: "checkin-detail",
    route: WAREHOUSE_CHECKIN.DETAIL_ROUTE,
    component: <CheckinDetails />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Checkout Detail",
    key: "checkout-detail",
    route: WAREHOUSE_CHECKOUT.DETAIL_ROUTE,
    component: <CheckoutDetails />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Quality Check Detail",
    key: "qc-detail",
    route: WAREHOUSE_QUALITY_CHECK.DETAIL_ROUTE,
    component: <QualityCheckDetails />,
    requireAuth: false,
    accessRole: [],
  },
  {
    name: "Quality Check Service History Detail",
    key: "qc-service-history-detail",
    route: WAREHOUSE_QUALITY_CHECK.DETAIL_HISTORY_ROUTE,
    component: <QualityCheckDetailsHistory />,
    requireAuth: false,
    accessRole: [],
  },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  // {
  //   name: "Kanban",
  //   key: "kanban",
  //   route: "/applications/kanban",
  //   component: <Kanban />,
  // },
  // {
  //   name: "Wizard",
  //   key: "wizard",
  //   route: "/applications/wizard",
  //   component: <Wizard />,
  // },
  // {
  //   name: "Customers",
  //   key: "customers",
  //   route: "/applications/customer/search",
  //   icon: <Icon fontSize="medium">PeopleOutline</Icon>,
  //   component: <CustomerSearch />,
  // },
  // {
  //   name: "Calendar",
  //   key: "calendar",
  //   route: "/applications/calendar",
  //   component: <Calendar />,
  // },
  //   ],
  // },
  // { type: "title", title: "Pages", key: "title-pages" },
  // {
  //   type: "collapse",
  //   name: "Pages",
  //   key: "pages",
  //   icon: <Icon fontSize="medium">image</Icon>,
  //   collapse: [
  //     {
  //       name: "Profile",
  //       key: "profile",
  //       collapse: [
  //         {
  //           name: "Profile Overview",
  //           key: "profile-overview",
  //           route: "/pages/profile/profile-overview",
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: "All Projects",
  //           key: "all-projects",
  //           route: "/pages/profile/all-projects",
  //           component: <AllProjects />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Users",
  //       key: "users",
  //       collapse: [
  //         {
  //           name: "New User",
  //           key: "new-user",
  //           route: "/pages/users/new-user",
  //           component: <NewUser />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Account",
  //       key: "account",
  //       collapse: [
  //         {
  //           name: "Settings",
  //           key: "settings",
  //           route: "/pages/account/settings",
  //           component: <Settings />,
  //         },
  //         {
  //           name: "Billing",
  //           key: "billing",
  //           route: "/pages/account/billing",
  //           component: <Billing />,
  //         },
  //         {
  //           name: "Invoice",
  //           key: "invoice",
  //           route: "/pages/account/invoice",
  //           component: <Invoice />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Projects",
  //       key: "projects",
  //       collapse: [
  //         {
  //           name: "Timeline",
  //           key: "timeline",
  //           route: "/pages/projects/timeline",
  //           component: <Timeline />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Pricing Page",
  //       key: "pricing-page",
  //       route: "/pages/pricing-page",
  //       component: <PricingPage />,
  //     },
  //     { name: "RTL", key: "rtl", route: "/pages/rtl", component: <RTL /> },
  //     { name: "Widgets", key: "widgets", route: "/pages/widgets", component: <Widgets /> },
  //     { name: "Charts", key: "charts", route: "/pages/charts", component: <Charts /> },
  //     {
  //       name: "Notfications",
  //       key: "notifications",
  //       route: "/pages/notifications",
  //       component: <Notifications />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Applications",
  //   key: "applications",
  //   icon: <Icon fontSize="medium">apps</Icon>,
  //   collapse: [
  //     {
  //       name: "Kanban",
  //       key: "kanban",
  //       route: "/applications/kanban",
  //       component: <Kanban />,
  //     },
  //     {
  //       name: "Wizard",
  //       key: "wizard",
  //       route: "/applications/wizard",
  //       component: <Wizard />,
  //     },
  //     {
  //       name: "Data Tables",
  //       key: "data-tables",
  //       route: "/applications/data-tables",
  //       component: <DataTables />,
  //     },
  //     {
  //       name: "Calendar",
  //       key: "calendar",
  //       route: "/applications/calendar",
  //       component: <Calendar />,
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Ecommerce",
  //   key: "ecommerce",
  //   icon: <Icon fontSize="medium">shopping_basket</Icon>,
  //   collapse: [
  //     {
  //       name: "Products",
  //       key: "products",
  //       collapse: [
  //         {
  //           name: "New Product",
  //           key: "new-product",
  //           route: "/ecommerce/products/new-product",
  //           component: <NewProduct />,
  //         },
  //         {
  //           name: "Edit Product",
  //           key: "edit-product",
  //           route: "/ecommerce/products/edit-product",
  //           component: <EditProduct />,
  //         },
  //         {
  //           name: "Product Page",
  //           key: "product-page",
  //           route: "/ecommerce/products/product-page",
  //           component: <ProductPage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: "Orders",
  //       key: "orders",
  //       collapse: [
  //         {
  //           name: "Order List",
  //           key: "order-list",
  //           route: "/ecommerce/orders/order-list",
  //           component: <OrderList />,
  //         },
  //         {
  //           name: "Order Details",
  //           key: "order-details",
  //           route: "/ecommerce/orders/order-details",
  //           component: <OrderDetails />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: "Sign In",
  //   key: "sign-in",
  //   collapse: [
  {
    name: "Sign-in",
    key: "sign-in",
    route: LOGIN.route,
    component: <SignIn />,
    requireAuth: false,
    accessRole: [
      MANAGER.level,
      AGENT.level,
      OPERATOR.level,
      CUSTOMER.level,
      SUBVENDOR.level,
      CONSIGNER.level,
      ADMIN.level,
    ],
  },
  {
    name: "Reset Password",
    key: "reset-password",
    route: RESET_PASSWORD.route,
    component: <ResetPassword />,
    requireAuth: false,
    accessRole: [
      MANAGER.level,
      AGENT.level,
      OPERATOR.level,
      CUSTOMER.level,
      SUBVENDOR.level,
      CONSIGNER.level,
      ADMIN.level,
    ],
  },
  {
    name: "Set Password",
    key: "set-password",
    route: SET_NEW_PASSWORD.route,
    component: <SetPassword />,
    requireAuth: false,
    accessRole: [
      MANAGER.level,
      AGENT.level,
      OPERATOR.level,
      CUSTOMER.level,
      SUBVENDOR.level,
      CONSIGNER.level,
      ADMIN.level,
    ],
  },
  {
    name: "Test UI",
    key: "test-ui",
    route: "/test-ui",
    component: <TestUi />,
    requireAuth: false,
    accessRole: [
      MANAGER.level,
      AGENT.level,
      OPERATOR.level,
      CUSTOMER.level,
      SUBVENDOR.level,
      CONSIGNER.level,
      ADMIN.level,
    ],
  },
  // { type: "divider", key: "divider-1" },
  // {
  //   type: "collapse",
  //   name: "Logout",
  //   key: "logout",
  //   route: "/logout",
  //   icon: <Icon fontSize="medium">logout</Icon>,
  //   noCollapse: true,
  // },
  //   ],
  // },
  // { type: "divider", key: "divider-1" },
  // { type: "title", title: "Docs", key: "title-docs" },
  // {
  //   type: "collapse",
  //   name: "Basic",
  //   key: "basic",
  //   icon: <Icon fontSize="medium">upcoming</Icon>,
  //   collapse: [
  //     {
  //       name: "Getting Started",
  //       key: "getting-started",
  //       collapse: [
  //         {
  //           name: "Overview",
  //           key: "overview",
  //           href: "https://www.creative-tim.com/learning-lab/react/overview/material-dashboard/",
  //         },
  //         {
  //           name: "License",
  //           key: "license",
  //           href: "https://www.creative-tim.com/learning-lab/react/license/material-dashboard/",
  //         },
  //         {
  //           name: "Quick Start",
  //           key: "quick-start",
  //           href: "https://www.creative-tim.com/learning-lab/react/quick-start/material-dashboard/",
  //         },
  //         {
  //           name: "Build Tools",
  //           key: "build-tools",
  //           href: "https://www.creative-tim.com/learning-lab/react/build-tools/material-dashboard/",
  //         },
  //       ],
  //     },
  //     {
  //       name: "Foundation",
  //       key: "foundation",
  //       collapse: [
  //         {
  //           name: "Colors",
  //           key: "colors",
  //           href: "https://www.creative-tim.com/learning-lab/react/colors/material-dashboard/",
  //         },
  //         {
  //           name: "Grid",
  //           key: "grid",
  //           href: "https://www.creative-tim.com/learning-lab/react/grid/material-dashboard/",
  //         },
  //         {
  //           name: "Typography",
  //           key: "base-typography",
  //           href: "https://www.creative-tim.com/learning-lab/react/base-typography/material-dashboard/",
  //         },
  //         {
  //           name: "Borders",
  //           key: "borders",
  //           href: "https://www.creative-tim.com/learning-lab/react/borders/material-dashboard/",
  //         },
  //         {
  //           name: "Box Shadows",
  //           key: "box-shadows",
  //           href: "https://www.creative-tim.com/learning-lab/react/box-shadows/material-dashboard/",
  //         },
  //         {
  //           name: "Functions",
  //           key: "functions",
  //           href: "https://www.creative-tim.com/learning-lab/react/functions/material-dashboard/",
  //         },
  //         {
  //           name: "Routing System",
  //           key: "routing-system",
  //           href: "https://www.creative-tim.com/learning-lab/react/routing-system/material-dashboard/",
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Components",
  //   key: "components",
  //   icon: <Icon fontSize="medium">view_in_ar</Icon>,
  //   collapse: [
  //     {
  //       name: "Alerts",
  //       key: "alerts",
  //       href: "https://www.creative-tim.com/learning-lab/react/alerts/material-dashboard/",
  //     },
  //     {
  //       name: "Avatar",
  //       key: "avatar",
  //       href: "https://www.creative-tim.com/learning-lab/react/avatar/material-dashboard/",
  //     },
  //     {
  //       name: "Badge",
  //       key: "badge",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge/material-dashboard/",
  //     },
  //     {
  //       name: "Badge Dot",
  //       key: "badge-dot",
  //       href: "https://www.creative-tim.com/learning-lab/react/badge-dot/material-dashboard/",
  //     },
  //     {
  //       name: "Box",
  //       key: "box",
  //       href: "https://www.creative-tim.com/learning-lab/react/box/material-dashboard/",
  //     },
  //     {
  //       name: "Buttons",
  //       key: "buttons",
  //       href: "https://www.creative-tim.com/learning-lab/react/buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Date Picker",
  //       key: "date-picker",
  //       href: "https://www.creative-tim.com/learning-lab/react/datepicker/material-dashboard/",
  //     },
  //     {
  //       name: "Dropzone",
  //       key: "dropzone",
  //       href: "https://www.creative-tim.com/learning-lab/react/dropzone/material-dashboard/",
  //     },
  //     {
  //       name: "Editor",
  //       key: "editor",
  //       href: "https://www.creative-tim.com/learning-lab/react/quill/material-dashboard/",
  //     },
  //     {
  //       name: "Input",
  //       key: "input",
  //       href: "https://www.creative-tim.com/learning-lab/react/input/material-dashboard/",
  //     },
  //     {
  //       name: "Pagination",
  //       key: "pagination",
  //       href: "https://www.creative-tim.com/learning-lab/react/pagination/material-dashboard/",
  //     },
  //     {
  //       name: "Progress",
  //       key: "progress",
  //       href: "https://www.creative-tim.com/learning-lab/react/progress/material-dashboard/",
  //     },
  //     {
  //       name: "Snackbar",
  //       key: "snackbar",
  //       href: "https://www.creative-tim.com/learning-lab/react/snackbar/material-dashboard/",
  //     },
  //     {
  //       name: "Social Button",
  //       key: "social-button",
  //       href: "https://www.creative-tim.com/learning-lab/react/social-buttons/material-dashboard/",
  //     },
  //     {
  //       name: "Typography",
  //       key: "typography",
  //       href: "https://www.creative-tim.com/learning-lab/react/typography/material-dashboard/",
  //     },
  //   ],
  // },
  // {
  //   type: "collapse",
  //   name: "Change Log",
  //   key: "changelog",
  //   href: "https://github.com/creativetimofficial/ct-material-dashboard-pro-react/blob/main/CHANGELOG.md",
  //   icon: <Icon fontSize="medium">receipt_long</Icon>,
  //   noCollapse: true,
  // },
];

export default routes;
