/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import { connect, useSelector } from "react-redux";

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "components/Tables/DataTable";
import MDInput from "components/MDInput";
// import DataTable from "examples/Tables/DataTable";
import Calendar from "examples/Calendar";

import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import calendarEventsData from "layouts/pages/widgets/data/calendarEventsData";
import { Autocomplete, CircularProgress, Tooltip } from "@mui/material";

import HTTPSServices from "services/https/HTTPSServices";
import pathUrl from "services/context/pathUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardMgr from "layouts/dashboards//DashboardMgr";
import MDSnackbar from "components/MDSnackbar";
import CustomModal from "components/CustomModal";
import Notifications from "layouts/dashboards/analytics/components/Notifications";
// import TodayPickup from "layouts/dashboards/analytics/components/TodayPickup";
// import TomorrowPickup from "layouts/dashboards/analytics/components/TomorrowPickup";
import TodaysPickupWidget from "layouts/dashboards/analytics/components/TodaysPickup/TodaysPickupWidget";
import TomorrowsPickupWidget from "layouts/dashboards/analytics/components/TomorrowsPickup/TomorrowsPickupWidget";
import TodaysReturnWidget from "layouts/dashboards/analytics/components/TodaysReturn/TodaysReturnWidget";
import DashboardCalendarWidget from "./components/DashboardCalendar/DashboardCalendarWidget";

const { getRequest } = HTTPSServices;
const { LOGIN, NOTIFICATION, TODAY_PICKUP, TOMORROW_PICKUP, TODAY_RETURN } = pathUrl;
const {
  _DASHBOARD,
  httpRequest,
  setStorageItem,
  ORDERS,
  getResponseMsg,
  getStorageItem,
  ellipsis,
  hasPermission,
} = DashboardMgr;

function DashboardCalendarModal({ locations, userDetail }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const navigate = useNavigate();
  const location = useLocation();
  const locationList = JSON.parse(getStorageItem("locations_list"));
  const userLocation = JSON.parse(getStorageItem("user_location")) || userDetail?.location;
  const actionList = {
    TODAYS_PICKUP: "todays-pickup",
    TOMORROW_PICKUP: "tomorrows-pickup",
    TODAYS_RETURN: "todays-return",
    NOTIFICATIONS: "notifications",
  };

  const [notificationCount, setNotificationCount] = useState(0);
  const [todayPickupCount, setTodaysPickupCount] = useState(0);
  const [tomorrowsPickupCount, setTomorrrowsPickupCount] = useState(0);
  const [todaysReturnCount, setTodaysReturnCount] = useState(0);

  const [notificationData, setNotificationData] = useState([]);
  const [todayPickup, setTodayPickup] = useState([]);
  const [tomorrowPickup, setTomorrowPickup] = useState([]);
  const [todayReturn, setTodayReturn] = useState([]);

  const [errMsg, setErrMsg] = useState("Failed to load");
  const [errorSB, setErrorSB] = useState(false);

  const [showNotifModal, setShowNotifModal] = useState(false);
  const [showTodayPickUpModal, setShowTodayPickUpModal] = useState(false);
  const [showTomorrowPickupModal, setShowTomorrowPickupModal] = useState(false);
  const [showTodayReturnModal, setShowTodayReturnModal] = useState(false);

  const [notificationLoading, setNotificationLoading] = useState(false);
  const [todaysPickupLoading, setTodaysPickupLoading] = useState(false);
  const [tomorrowsPickupLoading, setTomorrowsPickupLoading] = useState(false);
  const [todaysReturnLoading, setTodaysReturnLoading] = useState(false);

  const [todaysPickupLocationFilter, setTodaysPickupLocationFilter] = useState(
    userLocation || userDetail?.location
  );
  const [tomorrowsPickupLocationFilter, setTomorrowsPickupLocationFilter] = useState(
    userLocation || userDetail?.location
  );
  const [todaysReturnLocationFilter, setTodaysReturnLocationFilter] = useState(
    userLocation || userDetail?.location
  );

  const [loading, setLoading] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openNotifModal = () => setShowNotifModal(true);
  const closeNotifModal = () => setShowNotifModal(false);
  const openTodaysPickupModal = () => setShowTodayPickUpModal(true);
  const closeTodaysPickupModal = () => setShowTodayPickUpModal(false);
  const openTomorrowsPickupModal = () => setShowTomorrowPickupModal(true);
  const closeTomorrowsPickupModal = () => setShowTomorrowPickupModal(false);

  const initialState = () => {
    setNotificationData([]);
    setTodayPickup([]);
    setTomorrowPickup([]);
    setTodayReturn([]);
  };

  const processNotificationResult = (
    data = [
      {
        id: 1,
        content: {
          identifier: "R1000003",
          status: "Reservation",
          customer_name: "customer1 user",
          summary: "Lorem ipsum dolor sit amet 1",
          deficiency: ["missing paperworks", "unconfirmed"],
        },
        is_read: false,
        remarks: "created",
      },
    ]
  ) => {
    let tmpData = [];
    data.map((notif) => {
      tmpData = tmpData.concat({
        id: notif?.id,
        is_read: notif?.is_read,
        // remarks: notif?.remarks,
        uuid: notif?.content?.uuid,
        identifier: notif?.content?.identifier,
        customer_name: notif?.remarks?.customer_name,
        description: ellipsis(notif?.remarks?.description),
        deficiency: notif?.remarks?.deficiency.join(", "),
        status: notif?.remarks?.status,
      });
      return 0;
    });

    return tmpData;
  };

  const loadNotifications = async () => {
    setNotificationLoading(true);
    const response = await httpRequest("get-notification", { entriesPerPage: 5 });
    if (getResponseMsg(response) === "Success") {
      setNotificationData(processNotificationResult(response?.data?.data?.results));
      setNotificationCount(response.data.data.count);
    } else {
      setErrMsg("Failed to load Notifications");
      openErrorSB(true);
    }

    setNotificationLoading(false);
  };

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      loadNotifications();
    } else {
      navigate(LOGIN.route, { state: { from: location }, replace: true });
    }

    return () => {
      initialState();
    };
  }, []);

  const showOrderDetail = async (values) => {
    setStorageItem("current_order_detail", values);
    const response = await httpRequest("orders-detail", {
      uuid: values?.uuid,
      id: values?.id,
    });
    if (getResponseMsg(response) === "Success") {
      if (location?.pathname !== "/orders/details/") {
        navigate(ORDERS.DETAIL_ROUTE, { state: { from: _DASHBOARD?.HOMEPAGE } });
      } else {
        // window.location.reload(true);
        navigate(ORDERS.LIST_ROUTE);
        navigate(ORDERS.DETAIL_ROUTE, { state: { from: _DASHBOARD?.HOMEPAGE } });
      }
    }
  };

  const notificationDataTable = {
    columns: [
      {
        Header: "Order number",
        accessor: "identifier",
        width: "20%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewInventoryDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
            >
              <MDBox mr={2}>
                {!canViewInventoryDetail && (
                  // <MDBox mr={2}>
                  <Tooltip title="You don't have permission to View Orders" placement="top">
                    <Icon>error_outlined</Icon>
                  </Tooltip>
                  // </MDBox>
                )}
                <Icon
                  color={row?.original?.is_read === true ? "inherit" : "info"}
                  fontSize="medium"
                >
                  {row?.original?.is_read === true ? "drafts" : "markunread"}
                </Icon>
              </MDBox>
              <MDBox>
                <MDTypography
                  variant="button"
                  color="text"
                  onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={!canViewInventoryDetail ? "text" : "info"}
                    fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                    textGradient
                  >
                    {/* {row.values.name} */}
                    {value}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
      { Header: "customer name", accessor: "customer_name", headerAlign: "left", width: "25%" },
      { Header: "Job summary", headerAlign: "left", accessor: "description" },
      { Header: "Deficiency ", headerAlign: "left", accessor: "deficiency" },
      // { Header: "Status", accessor: "status" },
    ],
    rows: notificationData || [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {loading && <SimpleBackdrop />}
      <MDBox py={1} mb={1}>
        <MDBox mt={1.5}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} lg={4}>
              <Card height="10rem">
                <TodaysPickupWidget defaultPickupFilter={userLocation} />
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <TomorrowsPickupWidget defaultPickupFilter={userLocation} />
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Card>
                <TodaysReturnWidget defaultPickupFilter={userLocation} />
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={12}>
              <Card height="5px">
                <MDBox
                  pt={2}
                  px={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDBox display="flex">
                    <MDTypography>
                      <Icon color="inherit" fontSize="medium">
                        notifications
                      </Icon>
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="medium">
                      Notifications
                    </MDTypography>
                  </MDBox>
                </MDBox>
                {notificationLoading ? (
                  <MDBox
                    display="flex"
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    pb={4}
                  >
                    <CircularProgress />
                  </MDBox>
                ) : (
                  <MDBox>
                    <MDBox
                      sx={{ height: "23rem" }}
                      direction="column"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDBox>
                        <DataTable
                          table={notificationDataTable}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          isSorted={false}
                          canSearch={false}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox display="flex" justifyContent="center" alignItems="bottom">
                      {true && (
                        <MDButton variant="text" size="small" onClick={() => openNotifModal()}>
                          <MDTypography size="small" variant="body2" fontWeight="medium">
                            View All
                          </MDTypography>
                        </MDButton>
                      )}
                    </MDBox>
                  </MDBox>
                )}
              </Card>
            </Grid>

            <Grid item xs={12} md={6} lg={15}>
              {/* {useMemo(
                () => (
                  <Calendar
                    header={{ title: "calendar", date: "Monday, 2021" }}
                    initialView="dayGridMonth"
                    initialDate="2022-08-10"
                    events={calendarEventsData}
                    selectable
                    editable
                  />
                ),
                [calendarEventsData]
              )} */}
              <DashboardCalendarWidget defaultPickupFilter={userLocation} />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      {showNotifModal && (
        <CustomModal
          showModal={showNotifModal}
          handleHideModal={closeNotifModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: notificationCount > 12 ? "98%" : "auto",
            width: "90%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <Notifications onClose={closeNotifModal} />

          {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
        </CustomModal>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title="Vendor Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <Footer mb={1} />
    </DashboardLayout>
  );
}

DashboardCalendarModal.defaultProps = {
  userDetail: {},
  locations: [],
  warehouses: [],
};

DashboardCalendarModal.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
  locations: PropTypes.arrayOf(PropTypes.any),
  warehouses: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  userDetail: state.authentication?.userDetail,
  locations: state.helper?.locations,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCalendarModal);
