import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import style from "services/context/style";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";

const { postRequest, getRequest, patchRequest, putRequest } = HTTPSServices;

const { fullXs, halfXs, fullSm, halfSm, spacing, flex, flexEnd, spaceBetween, h5, padding } = style;
const { CUSTOMERS, _DASHBOARD, REPORTS } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  addDate,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  getCurrentTime,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  addDateFormatted,
  ellipsis,
  convertTimeZoneFormatDateTime,
  hasPermission,
  convertToCurrency,
  subtractDateFormatted,
} = utils;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  switch (action) {
    // START
    case "daily-rentals":
      response = await getRequest(
        `/reports/daily-rentals/?date=${rest?.dateFrom}&warehouse=${rest?.warehouse}`
      );
      break;

    case "weekly-rentals":
      response = await getRequest(
        `/reports/weekly-rentals/?date_from=${rest?.dateFrom}&date_to=${rest?.dateTo}&warehouse=${rest?.warehouse}`
      );
      break;
    case "monthly-rentals":
      response = await getRequest(
        `/reports/monthly-rentals/?month=${rest?.monthOf}&warehouse=${rest?.warehouse}`
      );
      break;

    case "sales-tax-by-site-rentals":
      response = await getRequest(
        `/reports/sales-tax/?month=${rest?.monthOf}&location=${rest?.location}`
      );
      break;

    case "receivables-aging":
      response = await getRequest(`/reports/receivables-aging/?warehouse=${rest?.warehouse}`);
      break;

    case "consignment-report":
      response = await getRequest(
        `/reports/consignment/?date_from=${rest?.dateFrom}&date_to=${rest?.dateTo}&consigner=${rest?.consigner}`
      );
      break;

    case "export-daily-rentals":
      response = await getRequest(
        `/reports/daily-rentals/?date=${rest?.dateFrom}&warehouse=${rest?.warehouse}&export=${rest?.exportType}`
      );
      break;

    case "export-weekly-rentals":
      response = await getRequest(
        `/reports/weekly-rentals/?date_from=${rest?.dateFrom}&date_to=${rest?.dateTo}&warehouse=${rest?.warehouse}&export=${rest?.exportType}`
      );
      break;
    case "export-monthly-rentals":
      response = await getRequest(
        `/reports/monthly-rentals/?month=${rest?.monthOf}&warehouse=${rest?.warehouse}&export=${rest?.exportType}`
      );
      break;

    case "export-sales-tax-by-site-rentals":
      response = await getRequest(
        `/reports/sales-tax/?month=${rest?.monthOf}&location=${rest?.location}&export=${rest?.exportType}`
      );
      break;

    case "export-receivables-aging":
      response = await getRequest(
        `/reports/receivables-aging/?warehouse=${rest?.warehouse}&export=${rest?.exportType}`
      );
      break;

    case "export-consignment-report":
      response = await getRequest(
        `/reports/consignment/?date_from=${rest?.dateFrom}&date_to=${rest?.dateTo}&consigner=${rest?.consigner}&export=${rest?.exportType}`
      );
      break;

    // END

    case "update-order":
      response = await putRequest(`${REPORTS.API}${rest?.uuid}/`);
      break;

    case "update-sub-item":
      response = await putRequest(
        `${REPORTS.API}${rest?.uuid}/subitems/${rest?.subitem_uuid}`,
        body
      );
      break;

    case "set-status-retired":
      response = await patchRequest(
        `${REPORTS.API}${rest?.uuid}/subitems/${rest?.subitem_uuid}`,
        body
      );
      break;

    case "orders-list":
      response = await getRequest(
        `${REPORTS.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&date_from=${rest?.date_from || ""}&date_to=${rest?.date_to || ""}&status=${
          rest?.status || ""
        }`
      );
      break;

    case "orders-detail":
      response = await getRequest(`${REPORTS.API}${rest?.uuid}/?notif_read=${rest?.id || ""}`);
      break;

    case "orders-availability":
      response = await getRequest(
        `${REPORTS.API}${rest?.uuid}/availability-calendar/?start_date=${rest?.date}`,
        body
      );
      break;

    case "orders-soft-delete":
      response = await patchRequest(`${REPORTS.API}${rest?.uuid}/`);
      break;

    case "order-cancel":
      response = await patchRequest(`${REPORTS.API}${rest?.uuid}/cancel/`, body);
      break;

    case "get-rental-agent-list":
      response = await getRequest("/users/?status=active");
      break;

    case "customer-detail":
      response = await getRequest(`${CUSTOMERS.API}${rest?.uuid}`);

      break;

    case "customer-documents":
      response = await getRequest(`${CUSTOMERS.API}${rest?.uuid}/documents/`);

      break;

    case "revision-logs":
      response = await getRequest(`${REPORTS.API}${rest?.uuid}/revision-logs/`);

      break;

    case "clone-order":
      response = await postRequest(`${REPORTS.API}${rest?.uuid}/clone/`, body);
      break;

    case "payments":
      response = await postRequest(`/payments/`, body);
      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (sortType = "asc", id = "name", searchKeyword = "", entriesPerPage = 25) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${REPORTS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${id}`
    );
  } else {
    response = await getRequest(
      `${REPORTS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}`
    );
  }

  return response;
};

const dataValidation = (data = {}) => {
  /**
   * Billing Start time Checker
   * Checks if:
   * 1. Billing start Date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if  conditions are true
   */
  // if (data.billing_start_date <= getDateToday() && data.billing_start_time < getCurrentTime()) {
  //   return "Billing start time should be past the current time. ";
  // }

  /**
   * Billing End time Checker
   * Checks if:
   * 1. Billing end Date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if conditions are true
   */
  // if (data.billing_end_date <= getDateToday() && data.billing_end_time < getCurrentTime()) {
  //   return "Billing end time should be past the current time. ";
  // }

  /**
   * Pick up date Checker
   * Checks if:
   * 1. Pick up date is before the billing start date
   *
   * Will cause an error if conditions are true
   */
  if (data.pickup_date < data.billing_start_date) {
    return "Pick-up date should be past or the same as billing start date. ";
  }

  /**
   * Pick up date Checker
   * Checks if:
   * 1. Pick up date is after the billing end date
   *
   * Will cause an error if conditions are true
   */
  if (data.pickup_date > data.billing_end_date) {
    return "Pick-up date should not be past the Billing end date. ";
  }

  /**
   * Pick up time Checker
   * Checks if:
   * 1. Pickup date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if conditions are true
   */
  // if (data.pickup_date <= getDateToday() && data.pickup_time < getCurrentTime()) {
  //   return "Pick-up time should be past the current time. ";
  // }

  /**
   * Return date Checker
   * Checks if:
   * 1. Return date is before the billing start date
   *
   * Will cause an error if conditions are true
   */
  if (data.return_by_date < data.billing_start_date) {
    return "Return date should be past or the same as billing start date. ";
  }

  /**
   * Return by time Checker
   * Checks if:
   * 1. Return date is later or equal to current date
   * 2. Time is before the current time
   *
   * Will cause an error if conditions are true
   */
  // if (data.return_by_date <= data.billing_start_date && data.return_by_time < getCurrentTime()) {
  //   return "Return time should be past the billng start time. ";
  // }

  if (data.collections.length <= 0) {
    return "Rental item/s are empty.";
  }

  return null;
};

const ReportsMgr = {
  REPORTS,
  fullXs,
  halfXs,
  fullSm,
  halfSm,
  spacing,
  flex,
  flexEnd,
  spaceBetween,
  h5,
  padding,
  getNextPage,
  getPrevPage,
  getSort,
  addDate,
  httpRequest,
  setStorageItem,
  getStorageItem,
  RESPONSE_SUCCESS,
  getDateToday,
  getDayOfWeek,
  preventNegativeInput,
  getResponseMsg,
  capitalizeFirstLetter,
  limitInputLength,
  getCurrentTime,
  dataValidation,
  convertToDecimal,
  convertToPecentDecimal,
  formatDate,
  CUSTOMERS,
  addDateFormatted,
  ellipsis,
  convertTimeZoneFormatDateTime,
  hasPermission,
  _DASHBOARD,
  convertToCurrency,
  subtractDateFormatted,
};

export default ReportsMgr;
