/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import useSound from "use-sound";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Grid, Icon, Tooltip } from "@mui/material";
import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import ReportsMgr from "layouts/Reports/ReportsMgr";
import SuccessScan from "assets/sounds/success.mp3";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import WarehouseMgr from "../WarehouseMgr";

const {
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getResponseMsg,
  hasPermission,
  getStorageItem,
  WAREHOUSE_STAGE,
  formatDate,
} = WarehouseMgr;

function StagingSearch() {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();
  const [playSuccessHuman] = useSound(SuccessScan);

  const [itemList, setItemList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [prevPageLink, setPrevPageLink] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const loadList = async (searchKeywordVal = "", entriesPerPageVal = 25) =>
    // console.log(statusFilterList);
    httpRequest(
      "warehouse-staging-list",
      {},
      {
        searchKeyword: searchKeywordVal,
        entriesPerPage: entriesPerPageVal,
        // status: statusFilterList,
      }
    );

  const showItemDetail = (values) => {
    setStorageItem("current_staging_detail", values);
    navigate(WAREHOUSE_STAGE.DETAIL_ROUTE, {
      state: { from: location, searchKeyword, hasModal: true },
      // replace: true
    });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Order Number",
        accessor: "identifier",
        width: "15%",
        hasSort: false,
        align: "left",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewOrderDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox>
              {!canViewOrderDetail && (
                // <MDBox mr={2}>
                <Tooltip title="You don't have permission to View Orders" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
                // </MDBox>
              )}
              <MDTypography
                ml={!canViewOrderDetail ? 1 : 0}
                variant="button"
                color="text"
                onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
              >
                <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color={!canViewOrderDetail ? "text" : "info"}
                  fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                  textGradient
                >
                  {/* {row.values.name} */}
                  {value}
                </MDTypography>
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {value}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {value?.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {value?.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList || [],
  };

  const dataTableDataMobile = {
    columns: [
      {
        Header: "Order Number",
        accessor: "identifier",
        width: "20%",
        hasSort: false,
        align: "left",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewOrderDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox width="10rem">
              <Grid container spacing={0}>
                <Grid container item>
                  {!canViewOrderDetail && (
                    // <MDBox mr={2}>
                    <Tooltip title="You don't have permission to View Orders" placement="top">
                      <Icon>error_outlined</Icon>
                    </Tooltip>
                    // </MDBox>
                  )}
                  <MDTypography
                    ml={!canViewOrderDetail ? 1 : 0}
                    variant="caption"
                    color="text"
                    onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                  >
                    <MDTypography
                      component={Link}
                      to="#"
                      variant="caption"
                      color={!canViewOrderDetail ? "text" : "info"}
                      fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                      textGradient
                    >
                      {value}
                    </MDTypography>
                  </MDTypography>
                </Grid>

                <Grid container item>
                  <MDTypography variant="caption" fontWeight="regular">
                    {row?.original?.description}
                  </MDTypography>
                </Grid>

                <Grid container item>
                  <MDTypography variant="caption" fontWeight="regular">
                    {row?.original?.customer?.name}
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="caption" fontWeight="regular">
                  {value?.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList || [],
  };

  const processRows = (data = []) => {
    const items = [];

    data.map((item) => {
      items.push({
        customer: item?.customer,
        description: item?.description,
        pickup_date: formatDate(item?.pickup_date, "LLL"),
        identifier: item?.identifier,
        status: item?.status,
        uuid: item?.uuid,
      });
      return true;
    });

    return items;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setItemList(processRows(response?.data?.data?.results));
      setNextPageLink(response?.data?.data?.next);
      setPrevPageLink(response?.data?.data?.previous);
      setTotalRows(response?.data?.data?.count);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    // playSuccessHuman();
    setSearchKeyword(val);
    setIsLoading(true);
    const response = await loadList(val, entriesPerPage);
    proccessResponse(response);
    setIsLoading(false);
  };

  const handleSearchBarcodePopUpDetail = async (val) => {
    // // setSearchKeyword(val);
    // setIsLoading(true);
    // const result = await loadList(val, 1);

    // if (getResponseMsg(result) === "Success" && result?.data?.data.count > 0) {
    //   showItemDetail(result?.data?.data?.results[0]);
    // }

    // // processRows(response?.data?.data?.results);
    // setIsLoading(false);

    // Start here

    playSuccessHuman();
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(await loadList(val, entriesPerPage));
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(await loadList(searchKeyword, val));
    setIsLoading(false);
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);
    const orderListResponse = await loadList(searchKeyword, entriesPerPage);
    proccessResponse(orderListResponse);
    setIsLoading(false);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <Card>
          <DataTable
            table={!isMobile ? dataTableData : dataTableDataMobile}
            noEndBorder
            details={showItemDetail}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handleSearch={handleSearchKeyword}
            popupDetailScan={handleSearchBarcodePopUpDetail}
            handleChangeEntries={handleChangeEntries}
            handleSort={handleSort}
            hasNextPage={nextPageLink !== null}
            hasPrevPage={prevPageLink !== null}
            totalCount={totalRows}
            keyPressSearch
            canScanQr
            isMobile={isMobile}
            canRefresh
            isLoading={isLoading}
            entriesPerPage={{
              show: !isMobile,
              defaultValue: 25,
              entries: [10, 25, 50, 100],
            }}
            title="Staging"
          />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

StagingSearch.defaultProps = {};

StagingSearch.propTypes = {};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(StagingSearch);
