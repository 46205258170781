/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useMaterialUIController } from "context";

import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Info from "layouts/admin/ManageLocation/detail/Info";
import Delete from "layouts/admin/ManageLocation/detail/Delete";

import LocationMgr from "./LocationMgr";

const { MANAGE_LOCATIONS, httpRequest, setStorageItem, getStorageItem, getResponseMsg } =
  LocationMgr;

function LocationDetail() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [locationData, setLocationData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to fetch user detail.");
  const [errorSB, setErrorSB] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || MANAGE_LOCATIONS.LIST_ROUTE;
  const locationDetail = JSON.parse(getStorageItem("current_location_detail"));

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("locations-detail", null, {
      uuid: locationDetail?.id,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      setLocationData(responseDetails.data.data);
    } else {
      setErrMsg("Failed to fetch User Detail.");
      openErrorSB(true);
    }
    setLoading(false);

    return 0;
  };

  useEffect(() => {
    const controllerRequest = new AbortController();
    if (locationDetail?.id === undefined) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
    loadDetails();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleClose = () => {
    if (from && from !== MANAGE_LOCATIONS.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(MANAGE_LOCATIONS.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  const editLocation = () => {
    const item = {
      id: locationData?.id,
      name: locationData?.name,
      taxRate: locationData?.tax_rate,
      timezone: locationData?.timezone,
      email: locationData?.email,

      mobile: locationData?.contact.mobile,
      telephone: locationData?.contact.telephone,
      fax: locationData?.contact.fax,
      other: locationData?.contact.other,

      line_1: locationData?.address.line_1,
      line_2: locationData?.address.line_2,
      city: locationData?.address.city,
      state: locationData?.address.state,
      country: locationData?.address.country,
      zip_code: locationData?.address.zip_code,
    };

    const temp = { ...item, pageState: "EDIT" };
    setStorageItem("current_location_detail", temp);
    navigate(MANAGE_LOCATIONS.EDIT_ROUTE, {
      state: { from: location, searchKeyword: location.state?.searchKeyword || "" },
      // replace: true,
    });
  };

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1}>
          <MDBox display="flex" justifyContent="flex-end">
            {true && (
              <MDBox display="flex" justifyContent="flex-end">
                <MDBox>
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={editLocation}
                    sx={{ marginRight: 1 }}
                    // disabled
                  >
                    Edit
                  </MDButton>
                </MDBox>
              </MDBox>
            )}
            <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
              back
            </MDButton>
          </MDBox>
        </MDBox>

        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Info data={locationData || []} />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto", mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Delete data={locationData} />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

export default LocationDetail;
