/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";

import { Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import InventoryMgr from "../InventoryMgr";

const { httpRequest, INVENTORY, getResponseMsg, hasPermission } = InventoryMgr;

function DeleteItem({ data }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const navigate = useNavigate();

  const [errMsg, setErrMsg] = useState("");
  const [succMsg, setSuccMsg] = useState("");

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errSb, setErrSb] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShoWTooltip] = useState(false);

  const openTooltip = () => setShoWTooltip(true);
  const closeTooltip = () => setShoWTooltip(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openErrSB = () => setErrSb(true);
  const closeErrSB = () => setErrSb(false);

  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

  const handleConfirm = async (action) => {
    setIsLoading(true);
    let deleteResponse = "";

    switch (action) {
      case "delete":
        handleCloseDeleteDialog();
        deleteResponse = await httpRequest("inventory-soft-delete", null, {
          uuid: data.uuid,
        });

        break;
      default:
        break;
    }

    // debugger;

    if (getResponseMsg(deleteResponse) === "Success") {
      setSuccMsg("Deactivated Successfully.");
      openSuccessSB(true);

      setTimeout(() => {
        setIsLoading(false);
        navigate(INVENTORY.LIST_ROUTE, { state: { from: "" }, replace: true });
      }, 1000);
    } else {
      setErrMsg(deleteResponse.data.data);
      setIsLoading(false);
      openErrSB(true);
    }
  };

  return (
    <Card id="delete-account">
      {isLoading && <SimpleBackdrop />}
      <MDBox
        pr={3}
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        flexDirection={{ xs: "column", sm: "row" }}
      >
        <MDBox p={3} lineHeight={1}>
          <MDBox mb={1}>
            <MDTypography variant="h5">Deactivate Item </MDTypography>
          </MDBox>
          <MDBox mb={1}>
            <MDTypography variant="button" color="warning">
              ** Once you Deactivate the Item, item will be hidden from the records. **
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection={{ xs: "column", sm: "row" }}>
          <Tooltip
            title="You don't have permission to do this"
            placement="top"
            followCursor
            open={showTooltip && !hasPermission("deactivate_item", permissions?.inventory)}
            onClose={closeTooltip}
            onOpen={openTooltip}
          >
            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
              <MDButton
                variant="gradient"
                color="error"
                sx={{ height: "100%" }}
                onClick={handleOpenDeleteDialog}
                disabled={!hasPermission("deactivate_item", permissions?.inventory)}
              >
                Deactivate
              </MDButton>
            </MDBox>
          </Tooltip>
        </MDBox>
      </MDBox>

      {showDeleteDialog && (
        <DraggableDialog
          title="Deactivate Item"
          description={`Are you sure you want to deactivate ${data.name}?`}
          buttonDesc="Deactivate"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={() => handleConfirm("delete")}
          fullWidth={false}
        />
      )}

      <MDSnackbar
        color="success"
        icon="check"
        title="Item Deactivation"
        dateTime=""
        content={succMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />

      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Deactivation"
        dateTime=""
        content={errMsg}
        open={errSb}
        onClose={closeErrSB}
        close={closeErrSB}
        bgWhite={false}
      />
    </Card>
  );
}
DeleteItem.defaultProps = {
  data: {},
};

DeleteItem.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default DeleteItem;
