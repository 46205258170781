/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";

import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import {
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Switch,
} from "@mui/material";

import Done from "assets/sounds/tada.mp3";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";

import MDButton from "components/MDButton";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import Signature from "./Modals/Signature";
import Photo from "./Modals/Photo";
import DragAndDrop from "./Modals/DragAndDrop";

const { WAREHOUSE_CHECKOUT, httpRequest, getStorageItem, capitalizeFirstLetter, getResponseMsg } =
  WarehouseMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUpload = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "50%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

function AttachmentsInfo({ loadDetails, data }) {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { attachments } = data;
  const navigate = useNavigate();
  const [playDoneSound] = useSound(Done);
  const checkoutDetails = JSON.parse(getStorageItem("current_checkout_detail"));

  const [showCapturePhoto, setCapturePhoto] = useState(false);
  const [confirmationDialogue, setConfirmationDialogue] = useState(false);
  const [photoType, setPhotoType] = useState("photo");
  const [facingMode, setFacingMode] = useState({ name: "Back", value: "environment" });

  const theme = {
    color: darkMode ? "white" : "dark",
  };

  const [showUpload, setShowUpload] = useState(false);
  const [showCaptureSignature, setShowCaptureSignature] = useState(false);
  const [uploadData, setUploadData] = useState("photo");

  const [notifTitle, setNotifTitle] = useState("Upload Document");

  const [successSB, setSuccessSB] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [errorSB, setErrorSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenConfirmationDialogue = () => {
    setConfirmationDialogue(true);
  };

  const handleCloseConfirmationDialogue = () => {
    setConfirmationDialogue(false);
    navigate(WAREHOUSE_CHECKOUT?.LIST_ROUTE);
  };
  const handleOpenCaptureSignatureModal = () => {
    setShowCaptureSignature(true);
  };

  const handleOpenCapturePhotoModal = () => {
    setCapturePhoto(true);
  };

  const handleCloseDialog = (action = "", status = "") => {
    if (status === "uploaded") {
      setNotifTitle("Upload Document");
      setSuccessMsg("Successfully Uploaded File");
    }
    if (status === "on_rent") {
      playDoneSound();
      handleOpenConfirmationDialogue();
    } else {
      loadDetails();
    }
    setShowUpload(false);
  };

  const handleCloseCaptureSignatureModal = (action = "", status = "") => {
    switch (action) {
      case "success":
        setShowCaptureSignature(false);
        setSuccessMsg("Successfully Uploaded e-Signature.");
        openSuccessSB(true);
        if (status === "on_rent") {
          playDoneSound();
          handleOpenConfirmationDialogue();
        } else {
          loadDetails();
        }
        break;
      case "failed":
        setErrMsg("Failed to Upload File");
        openErrorSB(true);
        break;
      default:
        setShowCaptureSignature(false);
        break;
    }
  };

  const handleCloseCapturePhotoModal = (action = "", status = "") => {
    switch (action) {
      case "success":
        setCapturePhoto(false);
        setSuccessMsg("Successfully Uploaded Photo.");
        openSuccessSB(true);
        if (status === "on_rent") {
          playDoneSound();
          handleOpenConfirmationDialogue();
        } else {
          loadDetails();
        }

        break;
      case "failed":
        setErrMsg("Failed to Upload File");
        openErrorSB(true);
        break;
      default:
        setCapturePhoto(false);
        break;
    }
  };

  const changeCamera = (e, value = {}) => {
    setCapturePhoto(false);
    setFacingMode(value);
    setCapturePhoto(true);
  };

  const handleOnClick = (action = "") => {
    switch (action) {
      case "photo":
        setPhotoType("photo");
        handleOpenCapturePhotoModal();
        break;
      case "signature":
        handleOpenCaptureSignatureModal();
        break;
      case "valid_id":
        setPhotoType("valid_id");
        handleOpenCapturePhotoModal();
        break;
      default:
        break;
    }
  };

  const handleSkipAttachment = async (attachmentType = "", value = false, index = 0) => {
    // setIsProccessing({ process: true, index });

    const response = await httpRequest(
      "skip-document",
      {
        action: "skip",
        attachment_type: attachmentType,
        is_skipped: value,
      },
      {
        uuid: checkoutDetails?.uuid,
      }
    );
    if (getResponseMsg(response) === "Success") {
      setSuccessMsg(
        value
          ? `Successfully Skipped ${capitalizeFirstLetter(attachmentType).replaceAll(
              "_",
              " "
            )} validation.`
          : `${capitalizeFirstLetter(attachmentType).replaceAll(
              "_",
              " "
            )} is now required for validation.`
      );
      if (response?.data?.data?.order_status === "on_rent") {
        playDoneSound();
        handleOpenConfirmationDialogue();
      } else {
        openSuccessSB();
        loadDetails();
      }
    } else {
      setErrMsg(`Failed to skip ${attachmentType}`);
      openErrorSB();
    }
    // console.log({
    //   action: "skip",
    //   attachment_type: attached[0],
    //   is_skipped: e.target.checked,
    // });
    // setIsProccessing({ process: false, index });
    // handleChange(document, index, e);
  };

  const handleOpenUpload = (attachmentType = "photo") => {
    setShowUpload(true);
    setUploadData(attachmentType);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <Card id="2fa" sx={{ overflow: "visible" }}>
      <MDBox height="auto" textAlign="center" mt={2}>
        <Grid container spacing={2} mt={1}>
          {data?.attachments !== undefined &&
            Object.entries(data?.attachments).map((attached) => (
              <Grid item xs={!isMobile ? 4 : 12}>
                <MDBox mx={3} display="flex" justifyContent="space-between">
                  <MDTypography fontWeight="bold" variant="h6" color={theme.color}>
                    {capitalizeFirstLetter(attached[0]).replaceAll("_", " ")}
                  </MDTypography>
                </MDBox>
                {attached[1]?.file_name !== null ? (
                  <MDBox
                    flexDirection="column"
                    display="center"
                    justifyContent="center"
                    direction="column"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    textAlign="center"
                  >
                    <CardMedia
                      src={attached[1]?.file_url || ""}
                      component="img"
                      title={attached[1]?.file_name || ""}
                      sx={{
                        maxWidth: "100%",
                        margin: 0,
                        maxHeight: "9rem",
                        boxShadow: ({ boxShadows: { md } }) => md,
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                      onClick={() => window.open(attached[1]?.file_url)}
                    />
                  </MDBox>
                ) : (
                  <MDBox>
                    <MDBox>
                      <MDTypography variant="button" color="text" fontWeight="regular">
                        No file
                      </MDTypography>
                    </MDBox>
                    <Grid container>
                      <Grid item xs={6}>
                        <MDBox textAlign="right">
                          <Switch
                            name="waiveDocument"
                            // defaultChecked={document?.is_waived || false}
                            checked={attached[1]?.is_skipped}
                            onChange={(e) => {
                              handleSkipAttachment(attached[0], e.target.checked);
                            }}
                          />
                        </MDBox>
                      </Grid>
                      <Grid item xs={6}>
                        <MDBox textAlign="left">
                          <MDTypography variant="caption" fontWeight="regular" color="text">
                            {attached[1]?.is_skipped ? "Skipped" : "Not Skipped"}
                          </MDTypography>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <MDBox display="flex" justifyContent="center" alignItems="center">
                      <MDBox mr={1}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={() => {
                            handleOnClick(attached[0]);
                          }}
                        >
                          Capture
                        </MDButton>
                      </MDBox>
                      <MDBox>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={() => handleOpenUpload(attached[0])}
                          // onClick={() => {
                          //   handleOnClick(attached[0]);
                          // }}
                        >
                          Upload
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                )}
              </Grid>
            ))}
        </Grid>
      </MDBox>
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      {showCaptureSignature && (
        <Modal
          open={showCaptureSignature}
          onClose={handleCloseCaptureSignatureModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
            <Signature
              loadDetails={loadDetails}
              handleCloseModal={handleCloseCaptureSignatureModal}
            />
          </MDBox>
        </Modal>
      )}
      {showCapturePhoto && (
        <Modal
          open={showCapturePhoto}
          onClose={handleCloseCapturePhotoModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
            <Photo
              loadDetails={loadDetails}
              handleCloseModal={handleCloseCapturePhotoModal}
              changeCamera={changeCamera}
              facingModeValue={facingMode}
              photoType={photoType}
            />
          </MDBox>
        </Modal>
      )}
      {confirmationDialogue && (
        <Dialog
          open={confirmationDialogue}
          onClose={handleCloseConfirmationDialogue}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Order status updated</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              All item status has been changed to On-rent. Order is now On-rent.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseConfirmationDialogue} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
      <MDBox height="auto" textAlign="center" mt={2}>
        {showUpload && (
          <Modal
            open={showUpload}
            onClose={handleCloseDialog}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MDBox bgColor={darkMode ? "dark" : "white"} sx={styleUpload}>
              <DragAndDrop
                uuid={checkoutDetails?.uuid}
                attachmentType={uploadData}
                onClose={handleCloseDialog}
                multiple
              />
            </MDBox>
          </Modal>
        )}
      </MDBox>
    </Card>
  );
}

AttachmentsInfo.defaultProps = {
  data: {},
};

AttachmentsInfo.propTypes = {
  loadDetails: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any),
};

export default AttachmentsInfo;
