/* eslint-disable no-unused-vars */
import { useState } from "react";

// @mui material components

// Material Dashboard 2 PRO React examples

// Pricing page components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Header from "./Header";

function PageNotFound() {
  const [tabValue, setTabValue] = useState(0);
  const [prices, setPrices] = useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Header tabValue={tabValue} tabHandler={handleSetTabValue} />
    </DashboardLayout>
  );
}

export default PageNotFound;
