/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import Info from "layouts/admin/ManageLocation/new/components/Info";
import OtherInfo from "layouts/admin/ManageLocation/new/components/OtherInfo";
import Contact from "layouts/admin/ManageLocation/new/components/Contact";
import form from "layouts/admin/ManageLocation/new/schemas/form";
import initialValues from "layouts/admin/ManageLocation/new/schemas/initialValues";
import validations from "layouts/admin/ManageLocation/new/schemas/validations";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import LocationMgr from "./LocationMgr";
import Address from "./new/components/Address";

const {
  MANAGE_LOCATIONS,
  httpRequest,
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  validateEmail,
  RESPONSE_SUCCESS,
  getConsignerList,
  setConsignerList,
  getResponseMsg,
  getErrorMessage,
  setLocationList,
} = LocationMgr;

function LocationNew({ setConsignerReduxList, locationList }) {
  const { formId, formField } = form;
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || MANAGE_LOCATIONS.LIST_ROUTE;

  const locationDetail = JSON.parse(getStorageItem("current_location_detail"));

  const [errMsg, setErrMsg] = useState("Failed to Create Location Profile");
  const [timezoneError, setTimezoneError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Successfully Created Location Profile");
  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const submitForm = async (values, actions) => {
    let response = null;

    if (locationDetail?.pageState === "NEW") {
      response = await httpRequest("locations-create", values);
      setSuccessMsg("Successfully Created Location");
    } else if (locationDetail?.pageState === "EDIT") {
      response = await httpRequest("locations-update", values, { uuid: locationDetail.id });
      setSuccessMsg("Successfully Updated Location");
    }

    if (getResponseMsg(response) === "Success") {
      openSuccessSB(true);

      const locationListResponse = await httpRequest("locations-list-all-active");
      // debugger;
      if (getResponseMsg(locationListResponse) === "Success") {
        locationList(locationListResponse?.data?.data?.results);
        setStorageItem("locations_list", locationListResponse?.data?.data?.results);
      }
      setTimeout(async () => {
        actions.resetForm();
        setStorageItem("current_location_detail", response.data.data);
        setLoading(false);
        navigate(MANAGE_LOCATIONS.DETAIL_ROUTE, {
          replace: true,
          state: { searchKeyword: location.state?.searchKeyword || "" },
        });
      }, 2000);
    } else {
      setErrMsg(getErrorMessage(response));
      openErrorSB(true);
      setLoading(false);
    }

    actions.setSubmitting(false);
  };

  const handleSave = (err, values) => {
    let responseErrMsg = "";
    Object.entries(err).map((item) => {
      // responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
      responseErrMsg = `${item[1]}`;
      return 0;
    });

    if (responseErrMsg !== "") {
      openErrorSB(true);
      setErrMsg(responseErrMsg);
    }

    if (err.timezone !== "") {
      setTimezoneError(true);
    }
    if (err.country !== "") {
      setCountryError(true);
    }
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);
    // console.log(values);
    // debugger;

    if (values?.timezone === null) {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Location Timezone is required.");
    } else if (values?.country === null) {
      openErrorSB(true);
      setLoading(false);
      setErrMsg("Location Country is required.");
    } else {
      const body = {
        name: values?.name,
        tax_rate: values?.taxRate,
        timezone: values?.timezone?.name,
        email: values?.email,
        contact: {
          mobile: values?.mobile,
          telephone: values?.telephone,
          fax: values?.fax,
          other: values?.other,
        },
        address: {
          line_1: values?.line_1,
          line_2: values?.line_2,
          city: values?.city,
          state: values?.state,
          country: values?.country?.value,
          zip_code: values?.zip_code,
        },
      };
      // console.log(body);
      submitForm(body, actions);
      // setLoading(false);
    }
  };

  const handleClose = () => {
    if (locationDetail?.pageState === "NEW") {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else if (locationDetail?.pageState === "EDIT") {
      navigate(from, {
        state: { id: locationDetail.id, searchKeyword: location.state?.searchKeyword || "" },
        replace: true,
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <SimpleBackdrop />}
      <MDBox mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium" color="dark">
                  {locationDetail?.pageState === "NEW" ? "New Location" : "Edit Location"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={
                locationDetail?.pageState === "NEW"
                  ? initialValues
                  : locationDetail || initialValues
              }
              validationSchema={validations}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <Card>
                      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4} sm={4} md={6}>
                          <MDBox>
                            <Info
                              timezoneError={timezoneError}
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={4} sm={4} md={6}>
                          <MDBox mx={2}>
                            <Contact
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                              }}
                            />
                          </MDBox>
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                          <Address
                            countryError={countryError}
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <MDBox display="flex" justifyContent="flex-end" mb={2} mx={2}>
                        <MDButton
                          size="small"
                          variant="gradient"
                          color="error"
                          onClick={handleClose}
                          sx={{ marginRight: 1 }}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          disabled={isSubmitting}
                          size="small"
                          type="submit"
                          variant="gradient"
                          color="info"
                          onClick={() => handleSave(errors, values)}
                        >
                          save
                        </MDButton>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Location Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Location Creation"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

LocationNew.propTypes = {
  setConsignerReduxList: PropTypes.func.isRequired,
  locationList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  locationList: (data) => dispatch(setLocationList(data)),
});

export default connect(null, mapDispatchToProps)(LocationNew);
