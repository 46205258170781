/* eslint-disable prefer-const */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

// Data
import OrderMgr from "layouts/Order/OrderMgr";
import MDTypography from "components/MDTypography";
import { Autocomplete, CircularProgress, Icon, IconButton } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  preventNegativeInput,
  limitInputLength,
  convertToCurrency,
} = OrderMgr;

function OrderPayment({ onClose }) {
  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));
  const regex = /^[0-9a-zA-Z(\-)]+$/;

  const paymentOptions = [
    { name: "Cash", value: "cash" },
    { name: "Check", value: "check" },
    { name: "Credit/Debit", value: "credit_debit" },
    { name: "ACH payment", value: "ach" },
    // { name: "Credit/Debit or ACH payment", value: "credit_debit" },
    { name: "Wire transfer", value: "wire_transfer" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [revisionLogData, setRevisionLogData] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(paymentOptions[0]);
  const [amount, setAmount] = useState(orderDetail?.remaining_balance);
  // const [cashAmount, setCashAmount] = useState(orderDetail?.remaining_balance);
  // const [checkAmount, setCheckAmount] = useState(orderDetail?.remaining_balance);
  // const [creditDebitAmount, setCreditDebitAmount] = useState(orderDetail?.remaining_balance);
  // const [achAmount, setAchAmount] = useState(orderDetail?.remaining_balance);
  // const [wireTransferAmount, setWireTransferAmount] = useState(orderDetail?.remaining_balance);
  const [checkNumber, setCheckNumber] = useState("");

  const handlePayment = async (method = "") => {
    let response = {};
    let body = {};

    setIsLoading(true);
    switch (method) {
      case paymentOptions[0]?.value:
        body = {
          customer: orderDetail?.customer?.uuid,
          order: orderDetail?.uuid,
          payment_method: method,
          amount,
        };
        break;
      case paymentOptions[1]?.value:
        body = {
          customer: orderDetail?.customer?.uuid,
          order: orderDetail?.uuid,
          payment_method: method,
          amount,
          check_number: checkNumber,
        };
        break;
      case paymentOptions[2]?.value:
      case paymentOptions[3]?.value:
      case paymentOptions[4]?.value:
        body = {
          customer: orderDetail?.customer?.uuid,
          order: orderDetail?.uuid,
          payment_method: method,
          amount,
        };
        // console.log("sent email");
        break;
      default:
        break;
    }

    response = await httpRequest("payments", body);

    if (getResponseMsg(response) === "Success") {
      if (method === paymentOptions[0]?.value || method === paymentOptions[1]?.value) {
        onClose("Success");
      } else {
        onClose("Success-email");
      }
    } else {
      onClose("failed-payment");
    }

    setIsLoading(false);
  };

  return (
    <MDBox>
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Choose Payment Method
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <MDBox p={1}>
        <Grid item xs={12} lg={12}>
          {isLoading ? (
            <MDBox display="flex" justifyContent="center" alignItems="center">
              <MDBox mt={0.75}>
                <CircularProgress />
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  Processing...
                </MDTypography>
              </MDBox>
            </MDBox>
          ) : (
            <Grid item xs={12} lg={12}>
              <MDBox mb={1} display="flex">
                <MDTypography component="div" variant="caption" color="text" fontWeight="regular">
                  Remaining amount to pay is &nbsp;
                </MDTypography>
                <MDTypography
                  component="div"
                  variant="caption"
                  color="info"
                  fontWeight="medium"
                  onClick={() => {
                    setAmount(orderDetail?.remaining_balance || 0);
                  }}
                >
                  {convertToCurrency(orderDetail?.remaining_balance || 0)}
                </MDTypography>
              </MDBox>
              <MDBox>
                <Autocomplete
                  options={paymentOptions}
                  value={paymentMethod}
                  isOptionEqualToValue={(option, value) =>
                    value !== "" ? option.value === value.value : option.value
                  }
                  getOptionLabel={(option) => (option ? option.name : "")}
                  renderInput={(params) => (
                    <MDInput {...params} variant="standard" label="Select payment method" />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setPaymentMethod(newValue);
                    }
                  }}
                />
              </MDBox>

              <MDBox mt={2}>
                <MDInput
                  variant="standard"
                  label="Payment Amount (USD)"
                  value={amount}
                  fullWidth
                  onChange={(e) => {
                    const val = preventNegativeInput(e.target.value);
                    setAmount(val);
                  }}
                  // InputLabelProps={{ shrink: true }}
                />
                {paymentMethod?.value === paymentOptions[2]?.value && (
                  <MDBox mt={2}>
                    <MDBox mt={1} display="flex">
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="warning"
                        fontWeight="regular"
                      >
                        Additional of standard
                      </MDTypography>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="info"
                        fontWeight="medium"
                      >
                        &nbsp;3%&nbsp;
                      </MDTypography>
                      <MDTypography
                        component="div"
                        variant="caption"
                        color="warning"
                        fontWeight="regular"
                      >
                        proccess fee will be applied.
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                )}
                {amount <= 0 && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      Amount should be more than 0.
                    </MDTypography>
                  </MDBox>
                )}
                {Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0 && (
                  <MDBox mt={0.75}>
                    <MDTypography
                      component="div"
                      variant="caption"
                      color="error"
                      fontWeight="regular"
                    >
                      Amount should not exceed the remaining balance.
                    </MDTypography>
                  </MDBox>
                )}

                {paymentMethod?.value === paymentOptions[1]?.value && (
                  <MDBox mt={2}>
                    <MDInput
                      variant="standard"
                      label="Check Number"
                      value={checkNumber}
                      fullWidth
                      onChange={(e) => {
                        const val = limitInputLength(e.target.value, 12);
                        if (val.match(regex) || val === "") {
                          setCheckNumber(val);
                        }
                      }}
                      // InputLabelProps={{ shrink: true }}
                    />
                    {checkNumber.length < 3 && (
                      <MDBox mt={0.75}>
                        <MDTypography
                          component="div"
                          variant="caption"
                          color="error"
                          fontWeight="regular"
                        >
                          Check Number Minimum length is 3.
                        </MDTypography>
                      </MDBox>
                    )}
                  </MDBox>
                )}
              </MDBox>

              <MDBox mt={2} display="flex" justifyContent="flex-end">
                <MDBox mx={2}>
                  <MDButton
                    // variant="outlined"
                    color="success"
                    size="small"
                    onClick={() => {
                      handlePayment(paymentMethod?.value);
                    }}
                    disabled={
                      (paymentMethod?.value === paymentOptions[0]?.value && amount <= 0) ||
                      (paymentMethod?.value === paymentOptions[0]?.value &&
                        Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0) ||
                      (paymentMethod?.value === paymentOptions[1]?.value &&
                        (amount <= 0 ||
                          checkNumber.length <= 0 ||
                          Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0)) ||
                      (paymentMethod?.value === paymentOptions[2]?.value &&
                        Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0) ||
                      (paymentMethod?.value === paymentOptions[3]?.value &&
                        Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0) ||
                      (paymentMethod?.value === paymentOptions[4]?.value &&
                        Number((orderDetail?.remaining_balance || 0) - (amount || 0)) < 0)
                      // ||
                      // paymentMethod?.value === paymentOptions[2]?.value ||
                      // paymentMethod?.value === paymentOptions[3]?.value ||
                      // paymentMethod?.value === paymentOptions[4]?.value
                    }
                  >
                    Proceed Payment
                  </MDButton>
                </MDBox>
                <MDBox>
                  <MDButton
                    // variant="outlined"
                    color="error"
                    size="small"
                    onClick={onClose}
                  >
                    cancel
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

OrderPayment.defaultProps = {
  userDetail: {},
};

OrderPayment.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(OrderPayment);
