/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import ConfiguratorRoot from "examples/Configurator/ConfiguratorRoot";

import {
  useMaterialUIController,
  setOpenConfigurator,
  setTransparentSidenav,
  setWhiteSidenav,
  setMiniSidenav,
  setDarkMode,
  setUserLocation,
  setUserWarehouse,
} from "context";

import authActions from "services/redux/actions/authActions";
import HTTPSServices from "services/https/HTTPSServices";
import pathUrl from "services/context/pathUrl";
import utils from "services/utils";
import helper from "services/helpers";
import { RESPONSE_SUCCESS } from "services/context/response";
import UsersMgr from "layouts/admin/ManageUsers/UsersMgr";
import { Avatar, IconButton } from "@mui/material";

const { postRequest, patchRequest } = HTTPSServices;
const { LOGIN, LOGOUT, CHANGE_LOCATION } = pathUrl;
const { updateUserLocation, logOut, updateUserWarehouse } = authActions;
const { getRefreshToken, setStorageItem, getStorageItem } = utils;
const { getWarehouseList } = helper;
const { httpRequest, getResponseMsg } = UsersMgr;

function Configurator({ ...props }) {
  const { userDetail, locations, updateUserLoc, logout, updateUserWar } = props;
  const [controller, dispatch] = useMaterialUIController();
  const { openConfigurator, miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const [disabled, setDisabled] = useState(false);
  const [locationValue, setLocationValue] = useState(
    JSON.parse(getStorageItem("user_location")) || userDetail?.location
  );
  const [warehouseValue, setWarehouseValue] = useState(
    JSON.parse(getStorageItem("user_warehouse")) || userDetail?.warehouse
  );
  const [warehousesList, setWareshousesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadWarehouse = async () => {
    if (localStorage.getItem("accessToken") && userDetail?.location?.id) {
      const response = await getWarehouseList(userDetail?.location?.id);
      setWareshousesList(response.data.data.results);
      // setWarehouseValue(response[0]);
    }
  };

  useEffect(() => {
    setLocationValue(JSON.parse(getStorageItem("user_location")) || userDetail?.location);
    setWarehouseValue(JSON.parse(getStorageItem("user_warehouse")) || userDetail?.warehouse);
    loadWarehouse();
  }, [openConfigurator]);

  // Use the useEffect hook to change the button state for the sidenav type based on window size.
  useEffect(() => {
    loadWarehouse();
    // A function that sets the disabled state of the buttons for the sidenav type.
    function handleDisabled() {
      return window.innerWidth > 1200 ? setDisabled(false) : setDisabled(true);
    }

    // The event listener that's calling the handleDisabled function when resizing the window.
    window.addEventListener("resize", handleDisabled);

    // Call the handleDisabled function to set the state with the initial value.
    handleDisabled();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleDisabled);
  }, []);

  const handleCloseConfigurator = () => setOpenConfigurator(dispatch, false);
  const handleTransparentSidenav = () => {
    setTransparentSidenav(dispatch, true);
    setWhiteSidenav(dispatch, false);
  };
  const handleWhiteSidenav = () => {
    setWhiteSidenav(dispatch, true);
    setTransparentSidenav(dispatch, false);
  };
  const handleDarkSidenav = () => {
    setWhiteSidenav(dispatch, false);
    setTransparentSidenav(dispatch, false);
  };
  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // sidenav type buttons styles
  const sidenavTypeButtonsStyles = ({
    functions: { pxToRem },
    palette: { white, dark, background },
    borders: { borderWidth },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? background.sidenav : white.main,
    color: darkMode ? white.main : dark.main,
    border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? background.sidenav : white.main,
      color: darkMode ? white.main : dark.main,
      border: `${borderWidth[1]} solid ${darkMode ? white.main : dark.main}`,
    },
  });

  // sidenav type active button styles
  const sidenavTypeActiveButtonStyles = ({
    functions: { pxToRem, linearGradient },
    palette: { white, gradients, background },
  }) => ({
    height: pxToRem(39),
    background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
    color: darkMode ? background.sidenav : white.main,

    "&:hover, &:focus, &:focus:not(:hover)": {
      background: darkMode ? white.main : linearGradient(gradients.dark.main, gradients.dark.state),
      color: darkMode ? background.sidenav : white.main,
    },
  });

  const handleChangeLocation = async (e, val) => {
    if (val) {
      setLoading(true);
      e.preventDefault();
      setLocationValue(val);
      setUserLocation(dispatch, val);
      const response = await getWarehouseList(val?.id);
      setWarehouseValue(response.data.data.results[0]);
      setWareshousesList(response.data.data.results);
      setLoading(false);
    }
  };

  const handleChangeWarehouse = async (e, val) => {
    if (val) {
      setLoading(true);
      e.preventDefault();
      setWarehouseValue(val);
      setUserWarehouse(dispatch, val);
      setLoading(false);
    }
  };

  const handleClick = async () => {
    if (locationValue && warehouseValue) {
      setStorageItem("user_location", locationValue);
      setStorageItem("user_warehouse", warehouseValue);
      setLoading(true);
      const response = await patchRequest(CHANGE_LOCATION.api, {
        location: `${locationValue?.id}`,
        warehouse: `${warehouseValue?.id}`,
      });

      if (response.status === RESPONSE_SUCCESS) {
        // const meResponse = await httpRequest("my-details");
        // if (getResponseMsg(meResponse) === "Success") {
        //   setStorageItem("current_user_detail", meResponse.data.data);
        // }
        handleCloseConfigurator();
        updateUserLoc(locationValue);
        updateUserWar(warehouseValue);
        window.location.reload(false);
      }
      setLoading(false);
    }
  };

  const handleLogout = async () => {
    setLoading(true);
    await postRequest(LOGOUT.api, { refresh: getRefreshToken() });
    logout();
    handleCloseConfigurator();
    navigate(LOGIN.route, { state: { from: location }, replace: true });
  };

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      {loading && <SimpleBackdrop />}
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">User Settings</MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseConfigurator}
        >
          close
        </Icon>
      </MDBox>

      <Divider />

      {userDetail?.tenant &&
        <MDBox
          display="flex"
          justifyContent="justify-between"
          alignItems="align-center"
          pt={0.5}
          pb={0.5}
          px={3}        
        >
        
          <Icon sx={{ marginRight: ".25em" }} fontSize="mediums">
            {"storeMarker"}
          </Icon>
        
          <MDTypography variant="h6">{userDetail.tenant.name}</MDTypography>

        </MDBox>
      }
     

      <Divider />
      <MDBox pt={0.5} pb={3} px={3}>
        <MDBox mt={1} lineHeight={1}>
          <Autocomplete
            disablePortal
            value={locationValue || ""}
            options={locations}
            sx={{ width: 300 }}
            isOptionEqualToValue={(option, value) =>
              value !== "" ? option.name === value.name : option.name
            }
            getOptionLabel={(option) => (option ? option.name : "")}
            onChange={(event, newValue) => {
              handleChangeLocation(event, newValue);
            }}
            renderInput={(params) => <MDInput {...params} label="Choose User Location" />}
          />
          <br />
          {location !== "" && (
            <>
              <Autocomplete
                disablePortal
                value={warehouseValue || ""}
                options={warehousesList || []}
                sx={{ width: 300 }}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                onChange={(event, newValue) => {
                  handleChangeWarehouse(event, newValue);
                }}
                renderInput={(params) => <MDInput {...params} label="Choose User Warehouse" />}
              />
              <br />
            </>
          )}
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={() => {
              handleClick();
            }}
          >
            Apply User Location
          </MDButton>
        </MDBox>
        <MDBox mt={1} lineHeight={1}>
          <br />
          <MDTypography variant="h6">Sidenav Type</MDTypography>
          <MDTypography variant="button" color="text">
            Choose between different sidenav types.
          </MDTypography>

          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}
          >
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleDarkSidenav}
              disabled={disabled}
              fullWidth
              sx={
                !transparentSidenav && !whiteSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              Dark
            </MDButton>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleTransparentSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  transparentSidenav && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                Transparent
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
              sx={
                whiteSidenav && !transparentSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              White
            </MDButton>
          </MDBox>
        </MDBox>
        {/* <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">Navbar Fixed</MDTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MDBox> */}
        <Divider
          sx={{
            opacity: "0.6"
          }} />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Sidenav Mini</MDTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </MDBox>
        <Divider
          sx={{
            opacity: "0.6"
          }} />
        <MDBox display="flex" justifyContent="space-between" alignItems="center" lineHeight={1}>
          <MDTypography variant="h6">Light / Dark</MDTypography>

          <Switch checked={darkMode} onChange={handleDarkMode} />
        </MDBox>
        <Divider
          sx={{
            opacity: "0.6"
          }} />
        <br />
        <MDButton variant="gradient" color="error" fullWidth onClick={handleLogout}>
          Logout
        </MDButton>
      </MDBox>
    </ConfiguratorRoot>
  );
}

Configurator.defaultProps = {
  userDetail: {},
  locations: [],
  warehouses: [],
};

Configurator.propTypes = {
  userDetail: PropTypes.objectOf(PropTypes.any),
  locations: PropTypes.arrayOf(PropTypes.any),
  warehouses: PropTypes.arrayOf(PropTypes.any),
  updateUserLoc: PropTypes.func.isRequired,
  updateUserWar: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userDetail: state.authentication?.userDetail,
  locations: state.helper?.locations,
  warehouses: state.helper?.warehouse,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserLoc: (data) => dispatch(updateUserLocation(data)),
  updateUserWar: (data) => dispatch(updateUserWarehouse(data)),
  logout: () => dispatch(logOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);
