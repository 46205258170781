/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDButton from "components/MDButton";
import { Icon } from "@mui/material";
import { IconButton } from "rsuite";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

const { getStorageItem, httpRequest, getResponseMsg } = WarehouseMgr;

function Signature({ handleCloseModal }) {
  const checkoutDetails = JSON.parse(getStorageItem("current_checkout_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [imgSrc, setImgSrc] = useState("");
  const [isLoading, setLoading] = useState(false);

  let sigPad = "";

  const clear = () => {
    sigPad.clear();
  };

  const trim = async () => {
    setLoading(true);

    const body = new FormData();

    body.append("attachment_type", "signature");
    body.append("attachment", sigPad.getTrimmedCanvas().toDataURL("image/png"));
    body.append("action", "capture");

    // START HERE
    const responseDetails = await httpRequest("upload-attachment", body, {
      uuid: checkoutDetails?.uuid,
    });
    if (getResponseMsg(responseDetails) === "Success") {
      handleCloseModal("success", responseDetails?.data?.data?.order_status);
    } else {
      handleCloseModal("failed");
    }
    setLoading(false);
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              {!isMobile ? `Please affix your e-Signature in the box:` : "e-Signature"}
            </MDTypography>
          </MDBox>

          <MDBox mx={1}>
            <MDBox ml={1}>
              <MDButton color="error" size="small" onClick={handleCloseModal}>
                close
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        <MDBox sx={{ borderStyle: "groove", marginTop: 2 }}>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: isMobile ? 300 : 500,
              height: isMobile ? 100 : 200,
              className: "sigCanvas",
            }}
            ref={(ref) => {
              sigPad = ref;
            }}
          />
        </MDBox>
        <MDBox mt={2} display="flex" justifyContent="flex-end">
          <MDBox>
            <MDButton color="info" onClick={clear}>
              reset
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton color="success" onClick={trim}>
              save
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          flexDirection="column"
          display="center"
          justifyContent="center"
          direction="column"
          alignItems={{ xs: "flex-start", sm: "center" }}
          textAlign="center"
        >
          {/* <img src={imgSrc} alt="signature" /> */}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Signature.defaultProps = {
  // userDetail: {},
};

Signature.propTypes = {
  // userDetail: PropTypes.objectOf(PropTypes.any),
  handleCloseModal: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(Signature);
