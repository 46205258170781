/* eslint-disable no-unused-vars */
import { useMemo, useEffect, useState } from "react";
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";
import { Link, useLocation } from "react-router-dom";

import PropTypes from "prop-types";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDPagination from "components/MDPagination";

import DataTableHeadCell from "examples/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "examples/Tables/DataTable/DataTableBodyCell";
import {
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
// import cinevoLoading from "assets/images/loading-cinevo-no-bg.gif";
import cinevoLoading from "assets/images/rocket/fading-rocket.svg";


function DataTable({
  entriesPerPage,
  canSearch,
  canRefresh,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  hiddenCols,
  details,
  handleNextPage,
  handlePrevPage,
  handleSearch,
  popupDetailScan,
  handleSort,
  handleChangeEntries,
  totalCount,
  hasNextPage,
  hasPrevPage,
  title,
  totals,
  totalPercentage,
  keyPressSearch,
  canScanQr,
  isMobile,
  isLoading,
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);
  const location = useLocation();
  const stateKeyword = location.state?.hasModal === true ? "" : location.state?.searchKeyword || "";

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns: hiddenCols },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  useEffect(() => {
    if (isLoading) {
      document.body.style.cursor = "wait";
    } else {
      document.body.style.cursor = "default";
    }
  }, [isLoading]);

  const [showQrCodeScan, setShowQrCodeScan] = useState(false);
  const [showManualScan, setShowManualScan] = useState(false);
  const [entryStart, setEntryStart] = useState(1);
  const [entryEnd, setEntryEnd] = useState(defaultValue < totalCount ? totalCount : defaultValue);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = (value) => {
    setPageSize(value);
    setEntryStart(1);
    setEntryEnd(value);
  };

  // Render the paginations

  // Handler for the input to set the pagination index

  // Customized page options starting from 1

  // Setting value for the pagination input

  // Search input value state
  const [search, setSearch] = useState(stateKeyword || globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  const handleNextPageButton = () => {
    setEntryStart(entryStart + pageSize);
    if (entryEnd + pageSize < totalCount) {
      setEntryEnd(entryEnd + pageSize);
    } else {
      setEntryEnd(totalCount);
    }

    handleNextPage();
  };

  const handlePrevPageButton = () => {
    setEntryStart(entryStart - pageSize);
    if (entryEnd === totalCount) {
      setEntryEnd(entryEnd - (totalCount % pageSize));
    } else {
      setEntryEnd(entryEnd - pageSize);
    }
    handlePrevPage();
  };

  const handleSearchKeywordChange = (currentTarget) => {
    setEntryStart(1);
    setEntryEnd(pageSize);
    setSearch(currentTarget.value);
    handleSearch(currentTarget.value);
  };

  const handleSorting = (column) => {
    setEntryStart(1);
    setEntryEnd(pageSize);
    handleSort(column.id);
  };

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  return (
    <TableContainer sx={{ boxShadow: "none" }}>
      {entriesPerPage || canSearch ? (
        <MDBox
          display={!isMobile ? "flex" : ""}
          justifyContent="space-between"
          alignItems="center"
          mt={2}
          mx={2}
        >
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              {title}
            </MDTypography>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end" alignItems="center" mr={2}>
            {entriesPerPage.show && (
              <MDBox display="flex" justifyContent="flex-start" alignItems="center" mr={2}>
                <MDTypography variant="caption" color="secondary" mr={1}>
                  &nbsp;&nbsp;entries per page
                </MDTypography>
                <Autocomplete
                  disableClearable
                  value={pageSize.toString()}
                  options={entries}
                  onChange={(event, newValue) => {
                    setEntriesPerPage(parseInt(newValue, 10));
                    handleChangeEntries(newValue);
                  }}
                  size="small"
                  sx={{ width: "5rem" }}
                  renderInput={(params) => <MDInput {...params} />}
                />
              </MDBox>
            )}
            <MDBox display="flex">
              {canScanQr && (
                <MDBox>
                  <Tooltip title="QR Code Searching" placement="top">
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => {
                        setSearch("");
                        setShowQrCodeScan(true);
                      }}
                      // onClick={() => console.log(cell)}
                    >
                      <Icon fontSize="small">qr_code_scanner</Icon>
                    </IconButton>
                  </Tooltip>
                </MDBox>
              )}
              {showQrCodeScan && !isMobile && (
                <MDBox>
                  <Dialog
                    open={showQrCodeScan}
                    onClose={() => {
                      setShowQrCodeScan(false);
                    }}
                    fullWidth
                  >
                    <DialogTitle>Code Scanner</DialogTitle>
                    <DialogContent>
                      <MDInput
                        autoFocus
                        placeholder="Search..."
                        value={search}
                        // size="large"
                        type="search"
                        fullWidth
                        width="20rem"
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            onSearchChange(e.target.value);
                            handleSearchKeywordChange(e.target);
                            popupDetailScan(e.target.value);
                            setShowQrCodeScan(false);
                            setSearch("");
                          }
                        }}
                        onChange={({ currentTarget }) => {
                          setSearch(currentTarget.value);
                          // onSearchChange(currentTarget.value);
                          // handleSearchKeywordChange(currentTarget);
                          // setShowQrCodeScan(false);
                          // setSearch("");
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                </MDBox>
              )}
              {showQrCodeScan && isMobile && (
                <MDBox>
                  <Dialog
                    open={showQrCodeScan}
                    onClose={() => {
                      setShowQrCodeScan(false);
                    }}
                  >
                    <DialogTitle>Code Scanner</DialogTitle>
                    <DialogContent>
                      <MDInput
                        autoFocus
                        placeholder="Search..."
                        value={search}
                        size="small"
                        type="search"
                        fullWidth
                        // onKeyPress={(e) => {
                        //   console.log(e.key, e.target.value);
                        //   if (e.key === "Enter") {
                        //     onSearchChange(e.target.value);
                        //     handleSearchKeywordChange(e.target);
                        //     setSearch("");
                        //   }
                        // }}
                        onChange={({ currentTarget }) => {
                          setSearch(currentTarget.value);
                          onSearchChange(currentTarget.value);
                          handleSearchKeywordChange(currentTarget);
                          popupDetailScan(currentTarget.value);
                          setShowQrCodeScan(false);
                          setSearch("");
                        }}
                      />
                    </DialogContent>
                  </Dialog>
                </MDBox>
              )}
              {canSearch && (
                <MDBox width="10rem">
                  <MDInput
                    // autoFocus
                    placeholder="Search..."
                    value={search}
                    size="small"
                    type="search"
                    fullWidth
                    // onKeyPress={(e) => {
                    //   console.log(e.key, e.target.value);
                    //   if (e.key === "Enter") {
                    //     onSearchChange(e.target.value);
                    //     handleSearchKeywordChange(e.target);
                    //     setSearch("");
                    //   }
                    // }}
                    onChange={({ currentTarget }) => {
                      setSearch(currentTarget.value);
                      onSearchChange(currentTarget.value);
                      handleSearchKeywordChange(currentTarget);
                    }}
                  />
                </MDBox>
              )}
              {canRefresh && (
                <MDBox>
                  <IconButton
                    size="small"
                    aria-label="edit"
                    color="info"
                    onClick={() => {
                      handleSearchKeywordChange({ value: "" });
                    }}
                    // disabled={
                    //   (row.original?.subitems?.length || 0) >= row.original?.quantity
                    // }
                  >
                    <Icon fontSize="small">refresh</Icon>
                  </IconButton>
                </MDBox>
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" sx={{ mb: 5 }}>
          <MDBox>
            <MDAvatar
              src={cinevoLoading}
              alt="cinevoLoading"
              size="xxl"
              variant="rounded"
              color="inherit"
              sx={{
                height: "10rem",
                p: 1,
                borderRadius: ({ borders: { borderRadius } }) => borderRadius.xl,
              }}
            />
            <MDTypography variant="h5" color="text">
              Loading List..
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <>
          <Table {...getTableProps()}>
            <MDBox component="thead">
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) =>
                    !column.hidden && column.hasSort ? (
                      <DataTableHeadCell
                        {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                        hasBorderRight={column?.hasBorderRight || false}
                        width={column.width ? column.width : "auto"}
                        align={column.headerAlign ? column.headerAlign : "center"}
                        // align="center"
                        sorted={setSortedValue(column)}
                        onClick={() => handleSorting(column)}
                      >
                        {column.render("Header")}
                      </DataTableHeadCell>
                    ) : (
                      <DataTableHeadCell
                        {...column.getHeaderProps(isSorted)}
                        hasBorderRight={column?.hasBorderRight || false}
                        width={column.width ? column.width : "auto"}
                        align={column.headerAlign ? column.headerAlign : "center"}
                        // align="center"
                        sorted={false}
                      >
                        {column.render("Header")}
                      </DataTableHeadCell>
                    )
                  )}
                </TableRow>
              ))}
            </MDBox>
            <TableBody {...getTableBodyProps()}>
              {page === []
                ? "No Data"
                : page.map((row, key) => {
                    prepareRow(row);
                    return (
                      <TableRow {...row.getRowProps()}>
                        {row.cells.map(
                          (cell) =>
                            !cell.hidden && (
                              <DataTableBodyCell
                                noBorder={noEndBorder && rows.length - 1 === key}
                                hasBorderRight={cell.column?.hasBorderRight || false}
                                align={cell.column.align ? cell.column.align : "left"}
                                bgColor={cell.column.bgColor ? cell.column.bgColor : "none"}
                                {...cell.getCellProps()}
                              >
                                {cell.column.type === "detail-link" && (
                                  <MDTypography
                                    variant="button"
                                    color="text"
                                    onClick={() => details(cell.row.values)}
                                  >
                                    <MDTypography
                                      component={Link}
                                      to="#"
                                      variant="button"
                                      color="info"
                                      fontWeight="medium"
                                      textGradient
                                    >
                                      {cell.render("Cell")}
                                    </MDTypography>
                                  </MDTypography>
                                )}

                                {cell.column.type === "file-link" && (
                                  <MDTypography variant="button" color="text">
                                    <MDTypography
                                      component={Link}
                                      to="#"
                                      variant="button"
                                      color="info"
                                      fontWeight="medium"
                                      textGradient
                                      onClick={() => window.open(cell.row?.values?.file_url)}
                                    >
                                      {cell.render("Cell")}
                                    </MDTypography>
                                  </MDTypography>
                                )}

                                {cell.column.type !== "detail-link" &&
                                  cell.column.type !== "file-link" &&
                                  cell.render("Cell")}
                              </DataTableBodyCell>
                            )
                        )}
                      </TableRow>
                    );
                  })}
            </TableBody>

            {totals?.show === true && page.length !== 0 && (
              <TableBody {...getTableBodyProps()}>
                <TableRow>
                  {totals?.totalRows.map(
                    (cell, index) =>
                      !cell.hidden && (
                        <DataTableBodyCell
                          hasBorderTop={totals?.hasBorderTop || false}
                          hasBorderRight={
                            totals?.hasBorderRight
                              ? (totals?.totalRows.length || 0) - 5 === index + 1
                              : false
                          }
                          key={`${cell + index}`}
                          // align={cell === "Totals" ? "right" : "left"}

                          align={totals?.align || "left"}
                        >
                          <MDTypography variant="button" color="text" fontWeight="medium">
                            {cell}
                          </MDTypography>
                        </DataTableBodyCell>
                      )
                  )}
                </TableRow>
              </TableBody>
            )}
            {totalPercentage?.show === true && page.length !== 0 && (
              <TableBody {...getTableBodyProps()}>
                <TableRow>
                  {totalPercentage?.totalRows.map(
                    (cell, index) =>
                      !cell.hidden && (
                        <DataTableBodyCell
                          hasBorderRight={
                            totalPercentage?.hasBorderRight
                              ? (totalPercentage?.totalRows.length || 0) - 5 === index + 1
                              : false
                          }
                          key={`${cell + index}`}
                          // align={
                          //   cell === "Percentage Rate" || cell === "Percentage Rate" ? "right" : "left"
                          // }
                          align="center"
                        >
                          <MDTypography variant="button" color="text" fontWeight="medium">
                            {cell}
                          </MDTypography>
                        </DataTableBodyCell>
                      )
                  )}
                </TableRow>
              </TableBody>
            )}
          </Table>
          {page.length === 0 && (
            <MDBox display="flex" justifyContent="center" sx={{ mt: 5 }}>
              <MDTypography variant="h5" color="text">
                No records found
              </MDTypography>
            </MDBox>
          )}

          <MDBox
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            justifyContent="center"
            alignItems={{ xs: "flex-start", sm: "center" }}
            p={!showTotalEntries && totalCount < pageSize ? 0 : 3}
          >
            <MDPagination
              variant={pagination.variant ? pagination.variant : "gradient"}
              color={pagination.color ? pagination.color : "info"}
            >
              {hasPrevPage && (
                <MDPagination item onClick={() => handlePrevPageButton() || previousPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                </MDPagination>
              )}
              {showTotalEntries && !(totalCount < pageSize) && (
                // && searchKeyword === ""
                <MDBox ml={3} mr={3} mb={{ xs: 3, sm: 0 }}>
                  <MDTypography variant="button" color="text" fontWeight="regular">
                    {isMobile
                      ? `${entryStart} to ${entryEnd} of ${totalCount} entries`
                      : `Showing ${entryStart} to ${entryEnd} of ${totalCount} entries`}
                  </MDTypography>
                </MDBox>
              )}

              {hasNextPage && (
                <MDPagination item onClick={() => handleNextPageButton() || nextPage()}>
                  <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                </MDPagination>
              )}
            </MDPagination>
          </MDBox>
        </>
      )}
    </TableContainer>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: true,
  canRefresh: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  hiddenCols: ["uuid"],
  hasNextPage: false,
  hasPrevPage: false,
  keyPressSearch: false,
  isMobile: false,
  canScanQr: false,
  totalCount: 0,
  details: () => {},
  handleNextPage: () => {},
  handlePrevPage: () => {},
  handleSearch: () => {},
  popupDetailScan: () => {},
  handleChangeEntries: () => {},
  handleSort: () => {},
  title: "",
  totals: {},
  totalPercentage: {},
  isLoading: false,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  canRefresh: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  hiddenCols: PropTypes.arrayOf(PropTypes.string),
  totalCount: PropTypes.number,
  hasNextPage: PropTypes.bool,
  hasPrevPage: PropTypes.bool,
  keyPressSearch: PropTypes.bool,
  isMobile: PropTypes.bool,
  canScanQr: PropTypes.bool,
  details: PropTypes.func,
  handleNextPage: PropTypes.func,
  handlePrevPage: PropTypes.func,
  handleSearch: PropTypes.func,
  popupDetailScan: PropTypes.func,
  handleChangeEntries: PropTypes.func,
  handleSort: PropTypes.func,
  title: PropTypes.string,
  totals: PropTypes.objectOf(PropTypes.any),
  totalPercentage: PropTypes.objectOf(PropTypes.any),
  isLoading: PropTypes.bool,
};

export default DataTable;
