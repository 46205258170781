/* eslint-disable no-debugger */
import axios from "axios";
import { useEffect } from "react";

import { RESPONSE_NOT_AUTHORIZED } from "services/context/response";
import utils from "services/utils";

import useRefreshToken from "hooks/useRefreshToken";

const { getAccessToken } = utils;
const baseURL = "/api";

axios.defaults.headers.post["Content-Type"] = "application/json";

export default axios.create({ baseURL });

const axiosPrivate = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  },
});

const AxiosInterceptor = ({ children }) => {
  const refresh = useRefreshToken();
  useEffect(() => {
    const requestHandler = (response) => {
      const currConfig = response;
      if (!response.headers.Authorization) {
        currConfig.headers.Authorization = `Bearer ${getAccessToken()}`;
      }
      return response;
    };

    const requestErrHandler = (error) => {
      Promise.reject(error);
    };

    const responseHandler = (response) => response;

    const responseErrHandler = async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === RESPONSE_NOT_AUTHORIZED && !prevRequest?.sent) {
        prevRequest.sent = true;
        const newAccessToken = await refresh();
        prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosPrivate(prevRequest);
      }
      return Promise.reject(error);
    };

    const requestInterceptor = axiosPrivate.interceptors.request.use(
      requestHandler,
      requestErrHandler
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      responseHandler,
      responseErrHandler
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return children;
};

export { axiosPrivate, AxiosInterceptor };
