import checkout from "layouts/admin/Subcategories/new-sub-category/schemas/form";

const {
  formField: { subcategoryName },
} = checkout;

const initialValues = {
  [subcategoryName.name]: "",
};

export default initialValues;
