import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const contactz = [
    {
        id: 1,
        mobile: "(123) 112-1212",
        telephone: "",
        fax: "",
        other: "",
        first_name: "contact",
        last_name: "one",
        email: "one@contact.com",
    },
    {
        id: 2,
        mobile: "(123) 112-1212",
        telephone: "",
        fax: "",
        other: "",
        first_name: "contact",
        last_name: "two",
        email: "two@contact.com",
    },
];

const columns = [
    { field: "email", headerName: "Email", width: 200 },
    { field: "first_name", headerName: "First Name", width: 200 },
    { field: "last_name", headerName: "Last Name", width: 200 },
    { field: "mobile", headerName: "Mobile", width: 200 },
    { field: "telephone", headerName: "Telephone", width: 200 },
    { field: "fax", headerName: "Fax", width: 200 },
    { field: "other", headerName: "Other", width: 200 },
];

export default function ContactsTable({ contacts }) {
    return (
        <>
            {contacts && (
                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid rows={contacts} columns={columns} pageSize={5} />
                </div>
            )}
        </>
    );
}
