/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { Autocomplete, CircularProgress, Icon, Tooltip } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";
import MDSnackbar from "components/MDSnackbar";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import CustomModal from "components/CustomModal";

import DashboardMgr from "layouts/dashboards//DashboardMgr";
import TodayPickupModal from "./TodayPickupModal";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  setStorageItem,
  ORDERS,
  _DASHBOARD,
  hasPermission,
} = DashboardMgr;

function TodaysPickupWidget({ defaultPickupFilter, locations }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const location = useLocation();
  const navigate = useNavigate();

  const locationList = JSON.parse(getStorageItem("locations_list")) || locations;

  const [todaysPickupData, setTodayPickupData] = useState([]);
  const [todayPickupCount, setTodaysPickupCount] = useState(0);
  const [todaysPickupLocationFilter, setTodaysPickupLocationFilter] = useState(defaultPickupFilter);
  const [todaysPickupLoading, setTodaysPickupLoading] = useState(false);
  const [showTodayPickUpModal, setShowTodayPickUpModal] = useState(false);

  const [errMsg, setErrMsg] = useState("Failed to load");
  const [errorSB, setErrorSB] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openTodaysPickupModal = () => setShowTodayPickUpModal(true);
  const closeTodaysPickupModal = () => setShowTodayPickUpModal(false);

  const processRow = (data = []) => {
    let tmpData = [];
    data.map((order) => {
      tmpData = tmpData.concat({
        identifier: order?.identifier,
        customer_name: order?.customer?.name,
        description: order?.description,
        status: order?.status?.name,
        uuid: order?.uuid,
      });
      return 0;
    });

    return tmpData;
  };

  const showOrderDetail = (values) => {
    setStorageItem("current_order_detail", values);
    navigate(ORDERS.DETAIL_ROUTE, { state: { from: _DASHBOARD?.HOMEPAGE } });
  };

  const todayPickupDataTable = {
    columns: [
      {
        Header: "Order number",
        accessor: "identifier",
        headerAlign: "left",
        width: "20%",
        Cell: ({ row, value }) => {
          const canViewInventoryDetail = hasPermission("view_order", permissions?.orders);

          const status = (
            <MDBox>
              {!canViewInventoryDetail && (
                <Tooltip title="You don't have permission to View Orders" placement="top">
                  <Icon mt={1}>error_outlined</Icon>
                </Tooltip>
              )}
              <MDTypography
                ml={!canViewInventoryDetail ? 1 : 0}
                variant="button"
                color="text"
                onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
              >
                <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color={!canViewInventoryDetail ? "text" : "info"}
                  fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                  textGradient
                >
                  {/* {row.values.name} */}
                  {value}
                </MDTypography>
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      { Header: "customer name", accessor: "customer_name", headerAlign: "left", width: "25%" },
      // { Header: "Job Summary", accessor: "description", width: "25%" },
      { Header: "Order status", accessor: "status", headerAlign: "left", width: "25%" },
    ],
    rows: todaysPickupData || [],
  };

  const loadPickupToday = async (locationId) => {
    setTodaysPickupLoading(true);

    const response = await httpRequest("get-todays-pickup", {
      entriesPerPage: 5,
      locationId,
    });

    if (getResponseMsg(response) === "Success") {
      setTodayPickupData(processRow(response?.data?.data?.results));
      setTodaysPickupCount(response.data.data.count);
    } else {
      setErrMsg("Failed to load Today's Pickup List");
      openErrorSB(true);
    }

    setTodaysPickupLoading(false);
  };

  const handleChange = async (e, val) => {
    setTodaysPickupLocationFilter(val);
    loadPickupToday(val?.id);
  };

  useEffect(() => {
    loadPickupToday(todaysPickupLocationFilter?.id);
  }, []);

  return (
    <MDBox>
      <MDBox pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex">
          <MDTypography>
            <Icon color="inherit" fontSize="medium">
              today
            </Icon>
          </MDTypography>
          <MDTypography variant="h6" fontWeight="medium">
            TODAY&apos;S PICKUP
          </MDTypography>
        </MDBox>
        <MDBox>
          <Autocomplete
            disablePortal
            value={todaysPickupLocationFilter}
            options={locationList}
            sx={{ width: 200 }}
            isOptionEqualToValue={(option, value) =>
              value !== "" ? option.name === value.name : option.name
            }
            getOptionLabel={(option) => (option ? option.name : "")}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                handleChange(event, newValue);
              }
            }}
            renderInput={(params) => <MDInput {...params} label="Filter by Location" />}
          />
        </MDBox>
      </MDBox>
      {todaysPickupLoading ? (
        <MDBox display="flex" direction="row" justifyContent="center" alignItems="center" pb={4}>
          <CircularProgress />
        </MDBox>
      ) : (
        <MDBox>
          <MDBox
            sx={{ height: "20rem" }}
            direction="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <MDBox>
              <DataTable
                table={todayPickupDataTable}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
                canSearch={false}
              />
            </MDBox>
          </MDBox>
          {true && (
            // todayPickupCount >= 5
            <MDBox display="flex" justifyContent="center" alignItems="bottom">
              {true && (
                <MDButton variant="text" size="small" onClick={() => openTodaysPickupModal()}>
                  <MDTypography size="small" variant="body2" fontWeight="medium">
                    View All
                  </MDTypography>
                </MDButton>
              )}
            </MDBox>
          )}
        </MDBox>
      )}

      {showTodayPickUpModal && (
        <CustomModal
          showModal={showTodayPickUpModal}
          handleHideModal={closeTodaysPickupModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: todayPickupCount > 12 ? "98%" : "auto",
            width: "60%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <TodayPickupModal
            onClose={closeTodaysPickupModal}
            defaultPickupFilter={todaysPickupLocationFilter}
          />
        </CustomModal>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title="Vendor Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}
TodaysPickupWidget.defaultProps = {
  locations: [],
};

TodaysPickupWidget.propTypes = {
  defaultPickupFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  userDetail: state.authentication?.userDetail,
  locations: state.helper?.locations,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(TodaysPickupWidget);
