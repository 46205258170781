const form = {
    formId: "new-order-form",
    formField: {
        customerName: {
            name: "customer_name",
            label: "Customer Name *",
            type: "text",
            errorMsg: "Customer name is required.",
        },
        customerUuid: {
            name: "customer_uuid",
            type: "text",
        },
        pickupDate: {
            name: "pickup_date",
            label: "Pick up Date (mm/dd/yyyy)",
            type: "date",
            errorMsg: "Pick up Date is required.",
        },
        pickupTime: {
            name: "pickup_time",
            label: "Pick up Time",
            type: "time",
            errorMsg: "Pick up Time is required.",
        },
        billingStartDate: {
            name: "billing_start_date",
            label: "Billing Start Date (mm/dd/yyyy) *",
            type: "date",
            errorMsg: "Billing Start Date is required.",
        },
        billingStartTime: {
            name: "billing_start_time",
            label: "Billing Start Time *",
            type: "time",
            errorMsg: "Billing Start Time is required.",
        },
        billingEndDate: {
            name: "billing_end_date",
            label: "Billing End Date (mm/dd/yyyy) *",
            type: "date",
            errorMsg: "Billing End Date is required.",
        },
        billingEndTime: {
            name: "billing_end_time",
            label: "Billing End Time *",
            type: "time",
            errorMsg: "Billing End Time is required.",
        },
        returnByDate: {
            name: "return_by_date",
            label: "Return By Date (mm/dd/yyyy)",
            type: "date",
            errorMsg: "Return By Date is required.",
        },
        returnByTime: {
            name: "return_by_time",
            label: "Return By Time",
            type: "time",
            errorMsg: "Return by Time is required.",
        },
        orderDescription: {
            name: "order_description",
            label: "Order Description *",
            type: "text",
            errorMsg: "Order Description is required.",
        },
        billDays: {
            name: "bill_days",
            label: "Bill Days ",
            type: "number",
            errorMsg: "Bill Days is required.",
        },
        defaultDiscount: {
            name: "default_discount",
            label: "Default Discount (%) *",
            type: "number",
            errorMsg: "Default discount is required.",
        },
        rentalAgentAssigned: {
            name: "rental_agent_assigned",
            label: "Rental Agent Assigned *",
            type: "text",
            errorMsg: "Rental Agent is required.",
        },
        depositAmount: {
            name: "deposit_amount",
            label: "Deposit Amount ",
            type: "number",
            errorMsg: "Deposit Amount is required.",
        },
        totalCostOrder: {
            name: "total_cost_order",
            label: "Total Cost Order *",
            type: "number",
            errorMsg: "Deposit Amount is required.",
        },
        taxRate: {
            name: "tax_rate",
            label: "Tax Rate *",
            type: "number",
            errorMsg: "Tax Rate is required.",
        },
        discountTotal: {
            name: "discount_total",
            label: "Discount Total *",
            type: "number",
            errorMsg: "Discount Total is required.",
        },
        invoiceTotal: {
            name: "invoice_total",
            label: "Invoice Total *",
            type: "number",
            errorMsg: "Invoice Total is required.",
        },
        collections: {
            name: "collections",
            errorMsg: "Item to be Rented is required.",
        },
        status: {
            name: "status",
            label: "Status",
            type: "text",
        },
    },
};

export default form;
