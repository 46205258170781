/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";

// react-router-dom components
import { Link } from "react-router-dom";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton"; // Material Dashboard 2 React Components
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Images
import bgImage from "assets/images/reelmen/2.png";

// Services
import HTTPSServices from "services/https/HTTPSServices";
import pathUrl from "services/context/pathUrl";
import utils from "services/utils";
import { RESPONSE_SUCCESS, RESPONSE_INVALID_REQUEST } from "services/context/response";

const { authRequest } = HTTPSServices;
const { RESET_PASSWORD } = pathUrl;
const { validateEmail } = utils;

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [resend, setResend] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState(0);
  const [errorSB, setErrorSB] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [message, setMessage] = useState("");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  // Third Attempts
  useEffect(() => {
    const timer = setInterval(() => {
      if (counter > 0) {
        setCounter(counter - 1);
      } else {
        setDisabled(false);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const handleSend = async () => {
    const errMsg = validateEmail(email);
    if (errMsg === "") {
      const response = await authRequest(RESET_PASSWORD.api, { email });
      if (response.status === RESPONSE_SUCCESS) {
        setResend(true);
        setDisabled(true);
        setCounter(60);
        setMessage(response.data.detail);
        openSuccessSB();
      } else if (response.status === RESPONSE_INVALID_REQUEST) {
        setMessage(response.data.email[0]);
        openErrorSB();
      } else {
        setMessage(response.data.detail);
        openErrorSB();
      }
    } else {
      setMessage(errMsg);
      openErrorSB();
    }
  };

  const handleChange = (val) => {
    setEmail(val);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <IllustrationLayout illustration={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => handleChange(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={(e) => handleSend(e)}
                disabled={disabled}
              >
                {resend ? "Re-send Email (" : "Send Email"}
                {resend ? counter : ""}
                {resend ? ")" : ""}
              </MDButton>
              <MDTypography
                component={Link}
                to="/auth/login"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Log in now
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Reset Password"
        dateTime=""
        content={message}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Reset Password"
        dateTime=""
        content={message}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </IllustrationLayout>
  );
}

export default ResetPassword;
