/* eslint-disable no-unused-vars */
import { useState } from "react";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { Checkbox, FormControlLabel, Slider } from "@mui/material";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import FormField from "layouts/admin/ManageVendors/new-user/components/FormField";
import VendorMgr from "layouts/admin/ManageVendors/VendorMgr";

const { limitInputLength } = VendorMgr;

function Info({ formData, vendorTypeError }) {
  const { formField, values, errors, touched } = formData;
  const { firstName, lastName, companyName, email, vendorType, isIndividual, percentage } =
    formField;
  const {
    first_name: firstNameV,
    last_name: lastNameV,
    company_name: companyNameV,
    email: emailV,
    isIndividual: isIndividualV,
    vendor_type: vendorTypeV,
    percentage: percentageV,
  } = values;

  const [fnameValue, setFnameValue] = useState(firstNameV);
  const [lnameValue, setLnameValue] = useState(lastNameV);
  const [userVendorType, setUserVendorType] = useState(vendorTypeV);
  const [percentageValue, setPercentageValue] = useState(percentageV);
  const [withCompany, setWithCompany] = useState(isIndividualV || false);

  const vendorTypeOption = [
    { name: "Sub-Vendor", value: "subvendor" },
    { name: "Consignor", value: "consignment" },
  ];

  const handleChangeVendorType = (e, val) => {
    if (val) {
      setUserVendorType(val);
      values.vendor_type = val.value;
      if (val.value === "consignment") {
        setPercentageValue(0.6 * 100);
      }
    }
  };

  const handleChangeWithCompanyCB = (e) => {
    values.company_name = e.target.checked === true ? "N/A" : "";
    values.isIndividual = e.target.checked;
    setWithCompany(e.target.checked);
  };

  const valueLabelFormat = (value) => `${value}%`;

  const handleChangeSlider = (event, newValue) => {
    if (typeof newValue === "number") {
      setPercentageValue(newValue);
      values.percentage = newValue;
    }
  };

  return (
    <MDBox ml={2}>
      <MDBox lineHeight={0} m={1}>
        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6">Basic Info</MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant="caption" fontWeight="regular" color="warning">
              * = Required Fields
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox m={1}>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={4} md={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={fnameValue}
              placeholder={firstName.placeholder}
              // error={errors.firstName && touched.firstName}
              // success={firstNameV.length > 0 && !errors.firstName}
              onChange={(e) => {
                setFnameValue(limitInputLength(e.target.value, 20));
                values.first_name = limitInputLength(e.target.value, 20);
              }}
              // onBlur={(e) => handleOnBlur(e)}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lnameValue}
              placeholder={lastName.placeholder}
              // error={errors.lastName && touched.lastName}
              // success={lastNameV.length > 0 && !errors.lastName}
              onChange={(e) => {
                setLnameValue(limitInputLength(e.target.value, 20));
                values.last_name = limitInputLength(e.target.value, 20);
              }}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={3} sm={5} md={8}>
                {!withCompany ? (
                  <MDBox>
                    <FormField
                      type={companyName.type}
                      label={companyName.label}
                      name={companyName.name}
                      value={companyNameV}
                      placeholder={companyName.placeholder}
                      disabled={isIndividualV}
                      // error={errors.companyName && touched.companyName}
                    />
                  </MDBox>
                ) : (
                  <MDBox mt={1.5}>
                    <MDTypography variant="button" color="text">
                      Individual
                    </MDTypography>
                  </MDBox>
                )}
              </Grid>
              <Grid item xs={1} sm={3} md={4} mt={2}>
                <FormControlLabel
                  label={
                    <MDTypography variant="caption" color="text">
                      {isIndividual.label}
                    </MDTypography>
                  }
                  control={<Checkbox checked={withCompany} onChange={handleChangeWithCompanyCB} />}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              // error={errors.email && touched.email}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <MDBox>
              <Autocomplete
                options={vendorTypeOption}
                value={userVendorType}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.value === value.value : option.value
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    name={vendorType.name}
                    variant="standard"
                    label={vendorType.label}
                  />
                )}
                onChange={(event, newValue) => {
                  handleChangeVendorType(event, newValue);
                }}
              />
            </MDBox>

            {vendorTypeError && userVendorType === null && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {vendorType.errorMsg}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          {userVendorType?.value === "consignment" && (
            <Grid item xs={2} sm={4} md={6}>
              <MDTypography variant="button" gutterBottom>
                {percentage.label}: {valueLabelFormat(percentageValue)}
              </MDTypography>
              <Slider
                value={percentageValue}
                min={0}
                step={1}
                max={100}
                getAriaValueText={valueLabelFormat}
                valueLabelFormat={valueLabelFormat}
                onChange={handleChangeSlider}
              />
            </Grid>
          )}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for UserInfo
Info.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  vendorTypeError: PropTypes.bool.isRequired,
};

export default Info;
