import { useLocation, Navigate, Outlet } from "react-router-dom";
import pathUrl from "services/context/pathUrl";
import utils from "services/utils";

const { LOGIN } = pathUrl;
const { getAccessToken } = utils;

function RequireAuth() {
  const location = useLocation();

  return getAccessToken() ? (
    <Outlet />
  ) : (
    <Navigate to={LOGIN.route} state={{ from: location }} replace />
  );
}

export default RequireAuth;
