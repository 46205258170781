/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import { Grid } from "@mui/material";

const { getStorageItem } = WarehouseMgr;

function BasicInfo({ data }) {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const { barcode_id, serial_number, name, is_owned, status, item, manufacturer, model_number } =
    data;

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isMobile ? (
        <MDBox pl={2} pr={2} pt={2} display="flex" justifyContent="space-between">
          <MDBox height="100%" mt={0.5} lineHeight={1}>
            <MDBox display="flex" justifyContent="space-between">
              <MDTypography variant="h5" fontWeight="medium">
                {item?.name}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                Description:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {item?.description}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                Barcode:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {barcode_id}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                Serial:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {serial_number}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                Name:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {name}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                Manufacturer:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {item?.manufacturer}
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDTypography variant="caption" fontWeight="regular">
                Model No.:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {item?.model_number}
              </MDTypography>
            </MDBox>
          </MDBox>
          <MDBox display="flex" justifyContent="flex-end">
            <MDTypography
              variant="h5"
              fontWeight="medium"
              color={status?.value === "canceled" ? "error" : "success"}
            >
              {status?.name}
            </MDTypography>
          </MDBox>
        </MDBox>
      ) : (
        <MDBox pl={2} pr={2} pt={2} display="flex" direction="row" justifyContent="space-between">
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <MDTypography variant="h5" fontWeight="medium">
                {item?.name}
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <MDTypography variant="caption" fontWeight="regular">
                Description:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {item?.description}
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <MDTypography variant="caption" fontWeight="regular">
                Barcode:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {barcode_id}
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <MDTypography variant="caption" fontWeight="regular">
                Serial:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {serial_number}
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <MDTypography variant="caption" fontWeight="regular">
                Name:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {name}
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <MDTypography variant="caption" fontWeight="regular">
                Manufacturer:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {item?.manufacturer}
              </MDTypography>
            </Grid>
            <Grid item xs={4} sm={4} md={6}>
              <MDTypography variant="caption" fontWeight="regular">
                Model No.:&nbsp;
              </MDTypography>
              <MDTypography variant="caption" fontWeight="medium">
                {item?.model_number}
              </MDTypography>
            </Grid>
          </Grid>

          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDTypography variant="h5">Item Status:&nbsp;</MDTypography>

                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  color={status?.value === "canceled" ? "error" : "success"}
                >
                  &nbsp;{status?.name}
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

BasicInfo.defaultProps = {
  data: {},
};

BasicInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};
export default BasicInfo;
