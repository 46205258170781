import helperActions from "services/redux/actions/helperActions";
import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";

const { postRequest, putRequest, getRequest, patchRequest } = HTTPSServices;
const { CATEGORIES, _DASHBOARD } = pathUrl;
const { setStorageItem, getStorageItem, jsonToString, hasPermission } = utils;
const { setCategoryReduxList } = helperActions;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  switch (action) {
    case "category-list":
      response = await getRequest(
        `${CATEGORIES.API}?search=${rest?.searchKeyword}&page_size=${rest?.entriesPerPage}&status=${
          rest?.status || ""
        }`
      );

      break;

    case "category-create":
      response = await postRequest(CATEGORIES.API, body);

      break;

    case "category-update":
      response = await putRequest(
        `${CATEGORIES.API}${rest?.uuid || ""}/subcategories/${rest?.subId || ""}/`,
        body
      );

      break;

    case "category-delete":
      response = await patchRequest(`${CATEGORIES.API}${rest?.uuid || ""}/`);

      break;
    default:
      break;
  }
  return response;
};

const getCategoryList = async (searchKeyword = "", entriesPerPage = 100) => {
  const response = await getRequest(
    `${CATEGORIES.API}?search=${searchKeyword}&page_size=${entriesPerPage}&status=active`
  );

  return response;
};

const createCategory = async (body = {}) => {
  const response = await postRequest(CATEGORIES.API, body);

  if (response.status === RESPONSE_SUCCESS || response.status === 201) {
    return response;
  }

  return false;
};

const updateCategory = async (id = "", data = {}) => {
  const response = await putRequest(`${CATEGORIES.API + id}/`, data);

  if (response.status === RESPONSE_SUCCESS || response.status === 201) {
    return response;
  }

  return false;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};
const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (
  sortType = "asc",
  id = "first_name",
  searchKeyword = "",
  entriesPerPage = 10
) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${CATEGORIES.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${id}`
    );
  } else {
    response = await getRequest(
      `${CATEGORIES.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}`
    );
  }

  return response;
};

const CategoryMgr = {
  getNextPage,
  getPrevPage,
  getSort,
  CATEGORIES,
  getCategoryList,
  createCategory,
  updateCategory,
  setStorageItem,
  getStorageItem,
  setCategoryReduxList,
  RESPONSE_SUCCESS,
  jsonToString,
  httpRequest,
  hasPermission,
  _DASHBOARD,
};

export default CategoryMgr;
