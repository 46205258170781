/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import Info from "layouts/admin/ManageUsers/new-user/components/Info";
import OtherInfo from "layouts/admin/ManageUsers/new-user/components/OtherInfo";
import form from "layouts/admin/ManageUsers/new-user/schemas/form";
import initialValues from "layouts/admin/ManageUsers/new-user/schemas/initialValues";
import validations from "layouts/admin/ManageUsers/new-user/schemas/validations";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import UsersMgr from "./UsersMgr";

const {
    MANAGE_USERS,
    httpRequest,
    setStorageItem,
    getStorageItem,
    capitalizeFirstLetter,
    validateEmail,
    RESPONSE_SUCCESS,
    getConsignerList,
    setConsignerList,
    getResponseMsg,
    getErrorMessage,
    hasRolePermission,
} = UsersMgr;

function UserNew({ setConsignerReduxList }) {
    const role = useSelector((state) => state?.authentication?.userDetail?.group);
    const { formId, formField } = form;
    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || MANAGE_USERS.LIST_ROUTE;

    const userDetail = JSON.parse(getStorageItem("current_user_detail"));

    //Gets tenant uuid from active user
    const tenant = useSelector((state) => state?.authentication?.userDetail?.tenant?.uuid);

    console.log("tenant", tenant);

    const [errMsg, setErrMsg] = useState("Failed to Create User Profile");
    const [successMsg, setSuccessMsg] = useState("Successfully Created User Profile");
    const [isLoading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [warehouseError, setWarehouseError] = useState(false);
    const [roleError, setRoleError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [addPermissionList, setAddPermissionList] = useState([]);

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const submitForm = async (values, actions) => {
        let response = null;

        if (userDetail?.pageState === "NEW") {
            response = await httpRequest("users-create", values);

            setSuccessMsg("Successfully created user profile.");
        } else if (userDetail?.pageState === "EDIT") {
            response = await httpRequest("users-update", values, { uuid: userDetail.uuid });
            setSuccessMsg("Successfully Updated User Profile");
        }

        if (getResponseMsg(response) === "Success") {
            openSuccessSB(true);
            setTimeout(async () => {
                const consignerResponse = await getConsignerList();
                if (
                    consignerResponse.status === RESPONSE_SUCCESS ||
                    consignerResponse.status === 201
                ) {
                    setConsignerReduxList(consignerResponse.data.data);
                }
                actions.resetForm();
                setStorageItem("current_user_detail", response.data.data);
                setLoading(false);
                navigate(MANAGE_USERS.DETAIL_ROUTE, {
                    state: { searchKeyword: location.state?.searchKeyword || "" },
                    replace: true,
                });
            }, 2000);
        } else {
            setErrMsg(getErrorMessage(response));
            openErrorSB(true);
            setLoading(false);
        }

        actions.setSubmitting(false);
    };

    const emailExist = async (email) => {
        const response = await validateEmail(email);
        if (response.status === RESPONSE_SUCCESS || response.status === 201) {
            return false;
        }
        return true;
    };

    const handleSave = (err) => {
        let responseErrMsg = "";
        Object.entries(err).map((item) => {
            // responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
            responseErrMsg = `${item[1]}`;
            return 0;
        });

        if (responseErrMsg !== "") {
            openErrorSB(true);
            setErrMsg(responseErrMsg);
        }
        if (err.location !== "") {
            setLocationError(true);
        }
        if (err.warehouse !== "") {
            setWarehouseError(true);
        }
        if (err.group !== "") {
            setRoleError(true);
        }
        if (err.password !== "") {
            setPasswordError(true);
        }
    };

    const getHasPermissions = (data) => {
        const tmpList = [];
        data.map((permission) => {
            if (permission?.has_permission === true) {
                // tmpList.push(permission?.value);
                tmpList.push(permission?.value);
            }
            return true;
        });

        return tmpList;
        // addPermissionList.push(tmpList);
        // setAddPermissionList(addPermissionList)
    };

    const getHasNoPermissions = (data) => {
        const tmpList = [];
        data.map((permission) => {
            if (permission?.has_permission === false) {
                // tmpList.push(permission?.value);
                tmpList.push(permission?.value);
            }
            return true;
        });

        return tmpList;
        // addPermissionList.push(tmpList);
        // setAddPermissionList(addPermissionList)
    };

    const handleSubmit = async (values, actions) => {
        setLoading(true);
        // console.log(values);
        // debugger;

        if (
            userDetail?.pageState === "EDIT" &&
            values?.email !== userDetail?.email &&
            (await emailExist(values?.email)) === true
        ) {
            setErrMsg("Email already exists.");
            openErrorSB(true);
            setLoading(false);
        } else if (userDetail?.pageState === "NEW" && (await emailExist(values?.email)) === true) {
            setErrMsg("Email already exists.");
            openErrorSB(true);
            setLoading(false);
        } else if (userDetail?.pageState === "NEW" && values?.password === "") {
            setErrMsg("Password is required.");
            openErrorSB(true);
            setLoading(false);
            setPasswordError(true);
        } else if (values?.location === null) {
            openErrorSB(true);
            setLoading(false);
            setErrMsg("User location is required.");
            setLocationError(true);
        } else if (values?.group === null) {
            openErrorSB(true);
            setLoading(false);
            setErrMsg("User group is required.");
            setRoleError(true);
        } else if (values?.warehouse === null) {
            openErrorSB(true);
            setLoading(false);
            setErrMsg("User warehouse is required.");
            setWarehouseError(true);
        } else {
            // TODO: ADD CHECKING FOR PERMISSION

            const custPermission = getHasPermissions(values?.customerPermissions);
            const invPermissions = getHasPermissions(values?.inventoryPermissions);
            const ordersPermission = getHasPermissions(values?.ordersPermissions);
            const paymentsPermission = getHasPermissions(values?.paymentsPermissions);
            const usersPermission = getHasPermissions(values?.usersPermission);

            const body = {
                first_name: capitalizeFirstLetter(values?.first_name),
                last_name: capitalizeFirstLetter(values?.last_name),
                email: values?.email,
                username: values?.username,
                password: values?.password,
                group: values?.role?.value,
                location: values?.location.id,
                warehouse: values?.warehouse.id,
                tenant: tenant,
                permissions:
                    custPermission
                        .concat(invPermissions)
                        .concat(ordersPermission)
                        .concat(paymentsPermission)
                        .concat(usersPermission) || [],
                // add_permissions:
                //   custPermission
                //     .concat(invPermissions)
                //     .concat(ordersPermission)
                //     .concat(paymentsPermission) || [],
                // remove_permissions:
                //   noCustPermission
                //     .concat(noInvPermissions)
                //     .concat(noOrdersPermission)
                //     .concat(noPaymentsPermission) || [],
            };
            // console.log(body);
            submitForm(body, actions);
        }
    };

    const handleClose = () => {
        if (userDetail?.pageState === "NEW") {
            navigate(from, {
                state: { searchKeyword: location.state?.searchKeyword || "" },
                replace: true,
            });
        } else {
            navigate(from, {
                state: {
                    uuid: userDetail.uuid,
                    searchKeyword: location.state?.searchKeyword || "",
                },
                replace: true,
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {isLoading && <SimpleBackdrop />}
            <MDBox mb={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <MDBox display="flex" justifyContent="space-between">
                            <MDBox>
                                <MDTypography variant="h6" fontWeight="medium" color="dark">
                                    {userDetail?.pageState === "NEW" ? "New User" : "Edit User"}
                                </MDTypography>
                            </MDBox>
                            <MDBox display="flex" justifyContent="space-between">
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="dark"
                                    onClick={handleClose}
                                >
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={
                                userDetail?.pageState === "NEW"
                                    ? initialValues
                                    : userDetail || initialValues
                            }
                            validationSchema={validations}
                            onSubmit={(values, actions) => handleSubmit(values, actions)}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form id={formId} autoComplete="off">
                                    <MDBox>
                                        <Card>
                                            {hasRolePermission(role?.value, [
                                                { name: "Admin", value: "admin" },
                                                // { name: "Agent", value: "agent" },
                                                { name: "Manager", value: "manager" },
                                                // { name: "Operator", value: "operator" },
                                            ]) && (
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <MDBox ml={2}>
                                                            <Info
                                                                locationError={locationError}
                                                                warehouseError={warehouseError}
                                                                formData={{
                                                                    values,
                                                                    touched,
                                                                    formField,
                                                                    errors,
                                                                }}
                                                            />
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <OtherInfo
                                                            passwordError={passwordError}
                                                            roleError={roleError}
                                                            formData={{
                                                                values,
                                                                touched,
                                                                formField,
                                                                errors,
                                                            }}
                                                        />
                                                    </Grid>
                                                    {/* <Grid item xs={6}>
                          <MDBox ml={2}>
                            <Contact
                              formData={{
                                values,
                                touched,
                                formField,
                                errors,
                              }}
                            />
                          </MDBox>
                        </Grid> */}
                                                </Grid>
                                            )}
                                            <MDBox
                                                display="flex"
                                                justifyContent="flex-end"
                                                mb={2}
                                                mx={2}
                                            >
                                                <MDButton
                                                    size="small"
                                                    variant="gradient"
                                                    color="error"
                                                    onClick={handleClose}
                                                    sx={{ marginRight: 1 }}
                                                >
                                                    Cancel
                                                </MDButton>
                                                <MDButton
                                                    disabled={isSubmitting}
                                                    size="small"
                                                    type="submit"
                                                    variant="gradient"
                                                    color="info"
                                                    onClick={() => handleSave(errors, values)}
                                                >
                                                    save
                                                </MDButton>
                                            </MDBox>
                                        </Card>
                                    </MDBox>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
            <MDSnackbar
                color="error"
                icon="warning"
                title="User Creation"
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />
            <MDSnackbar
                color="success"
                icon="check"
                title="User Creation"
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />
            <Footer />
        </DashboardLayout>
    );
}

UserNew.propTypes = {
    setConsignerReduxList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
    setConsignerReduxList: (data) => dispatch(setConsignerList(data)),
});

export default connect(null, mapDispatchToProps)(UserNew);
