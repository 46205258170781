/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Card, Icon, IconButton, Tooltip } from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import { useMaterialUIController } from "context";
import WarehouseMgr from "../WarehouseMgr";
import BasicInfo from "./staging-detail/BasicInfo";
import RentItems from "./staging-detail/RentItems";

const { httpRequest, setStorageItem, getResponseMsg, getStorageItem, WAREHOUSE_STAGE } =
  WarehouseMgr;

function StagingDetails() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || WAREHOUSE_STAGE.LIST_ROUTE;
  const stagingDetails = JSON.parse(getStorageItem("current_staging_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));

  const [orderData, setOrderData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const processRows = (data = []) =>
    // console.log({
    //   ...data,
    //   staged_items: data?.items?.staged,
    //   pending_items: data?.items?.pending,
    // });
    ({
      ...data,
      staged_items: data?.items?.staged,
      pending_items: data?.items?.pending,
    });
  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("warehouse-staging-detail", null, {
      uuid: stagingDetails?.uuid,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      setOrderData(processRows(responseDetails?.data?.data));

      setStorageItem("current_staging_detail", processRows(responseDetails?.data?.data));
    } else {
      setStorageItem("current_staging_detail", null);
      navigate(WAREHOUSE_STAGE.LIST_ROUTE, {
        state: {
          from: location,
          searchKeyword: location.state?.searchKeyword || "",
          hasModal: true,
        },
        // replace: true
      });
    }

    setLoading(false);

    return 0;
  };

  useEffect(() => {
    const controllerRequest = new AbortController();
    if (stagingDetails === {}) {
      navigate(WAREHOUSE_STAGE.LIST_ROUTE, {
        // replace: true
      });
    }
    loadDetails();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleClose = () => {
    // console.log(location, from);
    if (from && from !== WAREHOUSE_STAGE.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(WAREHOUSE_STAGE?.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1} display="flex" justifyContent="flex-end">
          {isMobile ? (
            <MDBox>
              <Tooltip title="Back" placement="top">
                <span>
                  <IconButton
                    aria-label="back"
                    size="small"
                    onClick={handleClose}
                    color="error"
                    // disabled
                  >
                    <Icon fontSize="small">keyboard_backspace</Icon>
                  </IconButton>
                </span>
              </Tooltip>
            </MDBox>
          ) : (
            <MDBox mr={1}>
              <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
                back
              </MDButton>
            </MDBox>
          )}
          {/* <MDButton variant="outlined" color="error" size="small" onClick={playSuccessHuman}>
            sound
          </MDButton> */}
        </MDBox>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12}>
                <BasicInfo data={orderData || {}} />
              </Grid>
              <Grid item xs={12}>
                <RentItems
                  data={orderData || {}}
                  setData={setOrderData}
                  loadDetails={loadDetails}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StagingDetails;
