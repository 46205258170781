/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Icon, IconButton } from "@mui/material";

function Info({ data }) {
  const {
    uuid,
    identifier,
    first_name,
    last_name,
    email,
    company_name,
    percentage,
    vendor_type,
    last_completed_contract,
    unpaid_balance,
    address,
    contact,
    status,
  } = data;

  const basicInfoDetails = [
    // { label: "Identifier :", value: identifier },
    { label: "Firstname :", value: first_name },
    { label: "Lastname :", value: last_name },
    { label: "Vendor Type :", value: vendor_type?.name },
    {
      label: "Consignment Percentage :",
      value: `${vendor_type?.value === "consignment" ? `${percentage * 100}%` : ""}`,
    },
  ];

  const addressDetails = [
    { label: "Line 1 :", value: address?.line_1 },
    { label: "Line 2 :", value: address?.line_2 },
    { label: "Country :", value: address?.country?.name },
    { label: "City :", value: address?.city },
    { label: "State :", value: address?.state },
    { label: "Zip :", value: address?.zip_code },
  ];

  const contactsDetails = [
    { label: "Email :", value: email },
    { label: "Tel. 1 No :", value: contact?.mobile ? `+1${contact?.mobile}` : contact?.mobile },
    { label: "Fax No :", value: contact?.fax ? `+1${contact?.fax}` : contact?.fax },
    {
      label: "Tel. 2 No :",
      value: contact?.telephone ? `+1${contact?.telephone}` : contact?.telephone,
    },
    { label: "Others :", value: contact?.other ? `+1${contact?.other}` : contact?.other },
  ];

  const renderItems = (items) =>
    items.map((item) => (
      <MDBox display="flex" key={item.label} py={0.5} pr={2}>
        {/* {item.value !== "" && ( */}
        <MDBox>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label} &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value || "-"}
          </MDTypography>
        </MDBox>
        {/* )} */}
      </MDBox>
    ));

  return (
    // <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
    <MDBox mx={1}>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox p={2} height="100%" mt={0.5} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {`${first_name || ""} ${last_name || ""}`}
          </MDTypography>
          <MDTypography variant="button" color="text" fontWeight="regular">
            {company_name === "N/A" ? "Individual" : `Company name: ${company_name || ""}`}
          </MDTypography>
        </MDBox>
        <MDBox p={2} lineHeight={1}>
          <MDBox>
            <MDTypography variant="caption" fontWeight="bold" textTransform="capitalize">
              Last completed contract: &nbsp;
            </MDTypography>

            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{last_completed_contract || "----/--/--"}
            </MDTypography>
          </MDBox>
          <MDBox>
            <MDTypography variant="caption" fontWeight="bold" textTransform="capitalize">
              Status: &nbsp;
            </MDTypography>
            <IconButton
              size="small"
              aria-label="edit"
              color={status === "active" ? "success" : "error"}
            >
              <Icon fontSize="small">{status === "active" ? "person" : "person_off"}</Icon>
            </IconButton>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;{status || "----/--/--"}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={4}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Basic Info
            </MDTypography>

            <Grid container>{renderItems(basicInfoDetails)}</Grid>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Address
            </MDTypography>
            <Grid container>{renderItems(addressDetails)}</Grid>
          </MDBox>
        </Grid>

        <Grid item xs={12} md={6} xl={4}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Contacts
            </MDTypography>
            <Grid container>{renderItems(contactsDetails)}</Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

Info.defaultProps = {
  data: {},
  address: {},
  contact: {},
};

Info.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  address: PropTypes.objectOf(PropTypes.any),
  contact: PropTypes.objectOf(PropTypes.any),
};

export default Info;
