/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useMaterialUIController } from "context";

import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Info from "layouts/Vendors/user-detail/Info";
import Delete from "layouts/Vendors/user-detail/Delete";
import SubItems from "layouts/Vendors/user-detail/SubItems";

import VendorMgr from "./VendorMgr";

const { VENDORS, httpRequest, setStorageItem, getStorageItem } = VendorMgr;

function VendorDetail() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [vendorData, setVendorData] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || VENDORS.LIST_ROUTE;
  const vendorDetail = JSON.parse(getStorageItem("current_vendor_detail"));

  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("vendors-detail", null, {
      uuid: vendorDetail?.uuid,
    });

    setVendorData(responseDetails.data.data);
    setLoading(false);

    return 0;
  };

  useEffect(() => {
    if (vendorDetail?.uuid === undefined) {
      navigate(from, { replace: true });
    }
    loadDetails();
  }, []);

  const handleClose = () => {
    if (from === VENDORS.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(VENDORS.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  const editCustomer = () => {
    const item = {
      uuid: vendorData.uuid,
      first_name: vendorData.first_name || "",
      last_name: vendorData.last_name || "",
      email: vendorData.email,
      company_name: vendorData.company_name || "",
      isIndividual: vendorData.company_name === "N/A",
      vendor_type: vendorData.vendor_type,
      percentage: vendorData.percentage * 100,

      mobile: vendorData.contact.mobile,
      telephone: vendorData.contact.telephone,
      fax: vendorData.contact.fax,
      other: vendorData.contact.other,

      line_1: vendorData.address.line_1,
      line_2: vendorData.address.line_2,
      city: vendorData.address.city,
      state: vendorData.address.state,
      country: vendorData.address.country,
      zip_code: vendorData.address.zip_code,
    };

    const temp = { ...item, pageState: "EDIT" };
    setStorageItem("current_vendor_detail", temp);
    navigate(VENDORS.EDIT_ROUTE, { state: { from: location }, replace: true });
  };

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1}>
          <MDBox display="flex" justifyContent="flex-end">
            {vendorData.status === "active" && (
              <MDBox display="flex" justifyContent="flex-end">
                <MDBox>
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="info"
                    // onClick={() => console.log("TODO")}
                    sx={{ marginRight: 1 }}
                    disabled
                  >
                    Generate PO
                  </MDButton>
                </MDBox>
                {/* <MDBox>
                  <MDButton
                    size="small"
                    variant="outlined"
                    color="info"
                    onClick={editCustomer}
                    sx={{ marginRight: 1 }}
                  >
                    Edit
                  </MDButton>
                </MDBox> */}
              </MDBox>
            )}
            <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
              back
            </MDButton>
          </MDBox>
        </MDBox>

        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Info data={vendorData || []} />
              </Grid>
              <Grid item xs={12}>
                {vendorData?.vendor_type?.value === "consignment" && (
                  <SubItems data={vendorData || []} />
                )}
              </Grid>
            </Grid>
          </MDBox>
        </Card>
        {/* <Card id="basic-info" sx={{ overflow: "visible", height: "auto", mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Delete data={vendorData} />
            </Grid>
          </Grid>
        </Card> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default VendorDetail;
