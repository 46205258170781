import checkout from "layouts/Inventory/new-item/schemas/form";

const {
  formField: {
    itemName,
    itemDescription,
    itemCategory,
    itemSubCategory,
    consigner,
    itemType,
    dailyPrice,
    weeklyPrice,
    monthlyPrice,
    replacementValue,
    qcRequired,
    isOwned,
    noBarcodeSerial,
    subitems,
    manufacturer,
    modelNumber,
    warehouse,
    collections,
  },
} = checkout;

const initialValues = {
  [itemName.name]: "",
  [itemDescription.name]: "",
  [itemCategory.name]: null,
  [itemSubCategory.name]: null,
  [consigner.name]: null,
  [itemType.name]: null,
  [warehouse.name]: null,
  [dailyPrice.name]: "",
  [weeklyPrice.name]: "",
  [monthlyPrice.name]: "",
  [replacementValue.name]: 0,
  [qcRequired.name]: false,
  [manufacturer.name]: "",
  [modelNumber.name]: "",
  [isOwned.name]: true,
  [noBarcodeSerial.name]: false,
  [subitems.name]: [
    {
      barcode_id: "",
      serial_number: "",
      name: "",
      is_owned: true,
      is_new: true,
      status: { name: "Available to Rent", value: "available" },
    },
  ],
  [collections.name]: [],
};

export default initialValues;
