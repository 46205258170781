/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import FormField from "layouts/admin/ManageVendors/new-user/components/FormField";
import utils from "services/utils";

const { formatZip } = utils;

function Address({ formData }) {
  const reduxCountry = useSelector((state) => state.helper.country);

  const { formField, values, errors, touched } = formData;
  const { line1, line2, city, state, country, zip } = formField;
  const {
    line_1: line1V,
    line_2: line2V,
    city: cityV,
    state: stateV,
    country: countryV,
    zip_code: zipV,
  } = values;

  const [custCountry, setCustCountry] = useState(countryV);
  const [inputZip, setInputZip] = useState(zipV);

  const handleChangeCountry = (e, val) => {
    if (val) {
      setCustCountry(val);
      values.country = val;
    }
  };

  const handleInputZip = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedZip = formatZip(e.target.value);
    // we'll set the input value using our setInputValue
    setInputZip(formattedZip);
    values.zip_code = formattedZip;
  };

  return (
    <MDBox mx={2} mt={2}>
      <MDBox width="100%" display="flex" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="bold">
          Address Information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={4} sm={8} md={12}>
            <FormField
              type={line1.type}
              label={line1.label}
              name={line1.name}
              value={line1V}
              placeholder={line1.placeholder}
              // error={errors.line1 && touched.line1}
              // success={line1V.length > 0 && !errors.line1}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <FormField
              type={line2.type}
              label={line2.label}
              name={line2.name}
              value={line2V}
              placeholder={line2.placeholder}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <Autocomplete
              disablePortal
              options={reduxCountry || ""}
              value={custCountry}
              name={country.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={country.label} />
              )}
              onChange={(event, newValue) => {
                handleChangeCountry(event, newValue);
              }}
            />
          </Grid>
          <Grid item xs={4} sm={4} md={6}>
            <FormField
              type={city.type}
              label={city.label}
              name={city.name}
              value={cityV}
              placeholder={city.placeholder}
              // error={errors.city && touched.city}
              // success={cityV.length > 0 && !errors.city}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={state.type}
              label={state.label}
              name={state.name}
              value={stateV}
              placeholder={state.placeholder}
              // error={errors.state && touched.state}
              // success={stateV.length > 0 && !errors.state}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={zip.type}
              label={zip.label}
              name={zip.name}
              value={inputZip}
              placeholder={zip.placeholder}
              // error={errors.zip && touched.zip}
              onChange={(e) => handleInputZip(e)}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Address;
