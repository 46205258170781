/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Material Dashboard 2 PRO React components
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import OrderMgr from "layouts/Order/OrderMgr";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const { convertToDecimal, httpRequest, getResponseMsg, setStorageItem, CUSTOMERS, getStorageItem } =
  OrderMgr;

function Certificates({ data, certificateData }) {
  const {
    insurance,
    rental_agreement,
    reference,
    // notes,
  } = certificateData;

  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));

  const navigate = useNavigate();
  const location = useLocation();

  // const [insuranceStatus, setInsuranceStatus] = useState(data?.insurance);
  // const [rentalAgreement, setRentalAgreement] = useState(data?.rental_agreement);
  // const [referenceInfo, setReferenceInfo] = useState(data?.reference);

  const pricingDetails = [
    { label: "Insurance Status", value: insurance?.status?.name || "No File" },
    { label: "Rental Agreement: ", value: rental_agreement?.status?.name || "No File" },
    { label: "Reference Info", value: reference?.status?.name || "No File" },
  ];

  const renderFullWidthItems = (items) =>
    items.map((item) => (
      <Grid item xs={12} md={6} xl={12} key={item.name}>
        <MDBox display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value}
          </MDTypography>
        </MDBox>
      </Grid>
    ));

  const showCustomerDetail = () => {
    setStorageItem("current_customer_detail", data.customer);

    navigate(CUSTOMERS.DETAIL_ROUTE, {
      state: { from: location, uuid: data.customer.uuid },
      // replace: true,
    });
  };

  // useEffect(async () => {
  //   const documentResponse = await httpRequest("customer-documents", null, {
  //     uuid: data.customer.uuid,
  //   });
  //   debugger;
  //   if (getResponseMsg(documentResponse) === "Success") {
  //     setInsuranceStatus(documentResponse.data.data.insurance);
  //     setRentalAgreement(documentResponse.data.data.rental_agreement);
  //     setReferenceInfo(documentResponse.data.data.reference);
  //   }
  //   // console.log(documentResponse);
  // }, []);

  return (
    <MDBox>
      <MDBox p={2} display="flex" justifyContent="flex-end">
        {/* <MDBox display="flex">
          <MDTypography variant="h5">Order Status:&nbsp;</MDTypography>

          <MDTypography
            variant="h5"
            fontWeight="medium"
            color={status?.value === "canceled" ? "error" : "success"}
          >
            &nbsp;{status?.name}
          </MDTypography>
        </MDBox> */}
      </MDBox>
      <MDBox mt={7}>
        <MDTypography variant="h6" color="info">
          Certificates
        </MDTypography>
      </MDBox>
      <Grid container>
        {/* <Grid item xs={12} md={6} xl={6}>
          <MDBox display="flex" py={1} pr={2}>
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Product Price: &nbsp;
            </MDTypography>
            <MDTypography variant="button" fontWeight="regular" color="text">
              &nbsp;$90
            </MDTypography>
          </MDBox>
        </Grid> */}
        <Grid item xs={12} md={6} xl={12}>
          <MDBox display="flex" py={1} pr={2}>
            {/* <MDTypography
              variant="button"
              color="text"
              onClick={() => {
                showCustomerDetail(data);
              }}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Insurance Status: &nbsp;
              </MDTypography>
            </MDTypography> */}
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Insurance Status: &nbsp;
            </MDTypography>
            {/* <MDTypography
              variant="button"
              fontWeight="regular"
              color={referenceInfo?.status?.name !== "" ? "success" : "error"}
            >
              &nbsp;{insuranceStatus?.status?.name || "No File"}
            </MDTypography> */}

            <MDTypography variant="button" fontWeight="regular">
              &nbsp;
              {insurance?.is_waived === true ? "Waived" : insurance?.status?.name || "No File"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} xl={12}>
          <MDBox display="flex" py={1} pr={2}>
            {/* <MDTypography
              variant="button"
              color="text"
              onClick={() => {
                showCustomerDetail(data);
              }}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Rental Agreement: &nbsp;
              </MDTypography>
            </MDTypography> */}
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Rental Agreement: &nbsp;
            </MDTypography>

            {/* <MDTypography
              variant="button"
              fontWeight="regular"
              color={referenceInfo?.status?.name !== "" ? "text" : "error"}
            >
              &nbsp;{rentalAgreement?.status?.name || "No File"}
            </MDTypography> */}

            <MDTypography variant="button" fontWeight="regular">
              &nbsp;{rental_agreement?.status?.name || "No File"}
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid item xs={12} md={6} xl={12}>
          <MDBox display="flex" py={1} pr={2}>
            {/* <MDTypography
              variant="button"
              color="text"
              onClick={() => {
                showCustomerDetail(data);
              }}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Reference Info: &nbsp;
              </MDTypography>
            </MDTypography> */}
            <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
              Reference Info: &nbsp;
            </MDTypography>
            {/* <MDTypography
              variant="button"
              fontWeight="regular"
              color={referenceInfo?.status?.name !== "" ? "text" : "error"}
            >
              &nbsp;{referenceInfo?.status?.name || "No File"}
            </MDTypography> */}
            <MDTypography variant="button" fontWeight="regular">
              &nbsp;{reference?.status?.name || "No File"}
            </MDTypography>
          </MDBox>
        </Grid>
        {/* {renderFullWidthItems(pricingDetails)} */}
      </Grid>
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Product Price:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $90
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Delivery:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $14
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Taxes:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $1.95
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mt={3}>
        <MDTypography variant="body1" fontWeight="light" color="text">
          Total:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body1" fontWeight="medium">
            $1.95
          </MDTypography>
        </MDBox>
      </MDBox> */}
    </MDBox>
  );
}

Certificates.defaultProps = {
  data: {},
  certificateData: {},
};

Certificates.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  certificateData: PropTypes.objectOf(PropTypes.any),
};

export default Certificates;
