/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

import FormField from "layouts/admin/ManageLocation/new/components/FormField";
import LocationMgr from "layouts/admin/ManageLocation/LocationMgr";

const { formatZip, getStorageItem } = LocationMgr;

function Address({ formData, countryError }) {
  const locationDetail = JSON.parse(getStorageItem("current_location_detail"));
  const reduxCountry = useSelector((state) => state.helper.country);

  const { formField, values, errors, touched } = formData;
  const { line1, line2, city, state, country, zip } = formField;
  const {
    line_1: line1V,
    line_2: line2V,
    city: cityV,
    state: stateV,
    country: countryV,
    zip_code: zipV,
  } = values;

  const [line1Value, setLine1Value] = useState(line1V);
  const [line2Value, setLine2Value] = useState(line2V);
  const [cityValue, setCityValue] = useState(cityV);
  const [stateValue, setStateValue] = useState(stateV);
  const [countryValue, setCountryValue] = useState(countryV);
  const [zipValue, setZipValue] = useState(zipV);

  const handleChangeCountry = (e, val) => {
    if (val) {
      setCountryValue(val);
      values.country = val;
    }
  };

  const handleInputZip = (e) => {
    // this is where we'll call our future formatPhoneNumber function that we haven't written yet.
    const formattedZip = formatZip(e.target.value);
    // we'll set the input value using our setInputValue
    setZipValue(formattedZip);
    values.zip_code = formattedZip;
  };

  useEffect(
    () => () => {
      setLine1Value("");
      values.line_1 = "";

      setLine2Value("");
      values.line_2 = "";

      setCityValue("");
      values.city = "";

      setStateValue("");
      values.state = "";

      setCountryValue(null);
      values.country = null;

      setZipValue("");
      values.zip_code = "";
    },
    []
  );

  return (
    <MDBox mx={2} my={0}>
      <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
        <MDTypography variant="h6" fontWeight="bold">
          Address Information
        </MDTypography>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={line1.type}
              label={line1.label}
              name={line1.name}
              value={line1Value}
              placeholder={line1.placeholder}
              onChange={(e) => {
                const val = e.target.value;
                setLine1Value(val);
                values.line_1 = val;
              }}
              // error={errors.line1 && touched.line1}
              // success={line1V.length > 0 && !errors.line1}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={line2.type}
              label={line2.label}
              name={line2.name}
              value={line2Value}
              placeholder={line2.placeholder}
              onChange={(e) => {
                const val = e.target.value;
                setLine2Value(val);
                values.line_2 = val;
              }}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <Autocomplete
              disablePortal
              options={reduxCountry || null}
              value={countryValue}
              name={country.name}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label={country.label} />
              )}
              onChange={(event, newValue) => {
                handleChangeCountry(event, newValue);
              }}
            />
            {countryError && countryValue === null && (
              <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                  {country.errorMsg}
                </MDTypography>
              </MDBox>
            )}
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={city.type}
              label={city.label}
              name={city.name}
              value={cityValue}
              placeholder={city.placeholder}
              onChange={(e) => {
                const val = e.target.value;
                setCityValue(val);
                values.city = val;
              }}
              // error={errors.city && touched.city}
              // success={cityV.length > 0 && !errors.city}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={state.type}
              label={state.label}
              name={state.name}
              value={stateValue}
              placeholder={state.placeholder}
              onChange={(e) => {
                const val = e.target.value;
                setStateValue(val);
                values.state = val;
              }}
              // error={errors.state && touched.state}
              // success={stateV.length > 0 && !errors.state}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={zip.type}
              label={zip.label}
              name={zip.name}
              value={zipValue}
              placeholder={zip.placeholder}
              // error={errors.zip && touched.zip}
              onChange={(e) => handleInputZip(e)}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  countryError: PropTypes.bool.isRequired,
};

export default Address;
