/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import InventoryMgr from "../InventoryMgr";

const { convertToCurrency } = InventoryMgr;

function BasicInfo({ data }) {
  const {
    identifier,
    name,
    description,
    category,
    subcategory,
    item_type,
    warehouse,
    replacement_value,
    price,
    local_qty,
    global_available_qty,
    manufacturer,
    model_number,
    is_qc_required,
    // notes,
  } = data;

  const basicInfoDetails = [
    // { label: "Description", value: description },
    { label: "Identifier", value: identifier },
    { label: "Type", value: item_type?.name },
    { label: "Category", value: category?.name },
    { label: "Sub-Category", value: subcategory?.name },
    { label: "Manufacturer", value: manufacturer },
    { label: "Model Number", value: model_number },
    { label: "Quality Check", value: is_qc_required ? "Required" : "Not Required" },
    { label: "Replacement value", value: replacement_value },
    // { label: "Notes", value: notes },
  ];

  const pricingDetails = [
    { label: "Daily", value: `${convertToCurrency(price?.daily)}` },
    { label: "Weekly", value: `${convertToCurrency(price?.weekly)}` },
    { label: "Monthly", value: `${convertToCurrency(price?.monthly)}` },
  ];

  const OtherDetails = [
    { label: "Owned", value: local_qty?.owned },
    { label: "Available", value: local_qty?.available },
    { label: "On rent", value: local_qty?.on_rent },
    { label: "On repair", value: local_qty?.in_repair },
    { label: "Global Available Qty", value: global_available_qty },
  ];

  const renderInfo = (items) =>
    items.map((item) => (
      <Grid
        item
        xs={4}
        sm={4}
        md={item.label === "Category" || item.label === "Sub-Category" ? 12 : 6}
      >
        <MDBox display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value}
          </MDTypography>
        </MDBox>
      </Grid>
    ));

  const renderOthers = (items) =>
    items.map((item) => (
      <Grid item xs={2} sm={4} md={4}>
        <MDBox display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value}
          </MDTypography>
        </MDBox>
      </Grid>
    ));

  return (
    <MDBox>
      <MDBox p={2} display="flex" justifyContent="space-between">
        <MDBox height="100%" mt={0.5} lineHeight={1}>
          <MDTypography variant="h5" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="button" color="text" fontWeight="regular">
            {`Warehouse: ${warehouse?.name || "N/A"}`}
          </MDTypography>
        </MDBox>
      </MDBox>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={12}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Item Info
            </MDTypography>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDBox display="flex" py={1} pr={2}>
                  <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                    Description: &nbsp;
                  </MDTypography>
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{description}
                  </MDTypography>
                </MDBox>
              </Grid>
              {renderInfo(basicInfoDetails)}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={12}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Pricing
            </MDTypography>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              {renderOthers(pricingDetails)}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12} md={6} xl={12}>
          <MDBox pb={1} pl={2}>
            <MDTypography variant="h6" color="info">
              Quantity Summary
            </MDTypography>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              {renderOthers(OtherDetails)}
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

BasicInfo.defaultProps = {
  data: {},
};

BasicInfo.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default BasicInfo;
