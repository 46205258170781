/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import FormField from "layouts/admin/ManageLocation/new/components/FormField";
import LocationMgr from "layouts/admin/ManageLocation/LocationMgr";

const { formatPhoneNumber, getStorageItem } = LocationMgr;

function Contact({ formData }) {
  const locationDetail = JSON.parse(getStorageItem("current_location_detail"));
  const { formField, values, errors, touched } = formData;
  const { mobile, telephone, fax, other } = formField;
  const { mobile: mobileV, telephone: telephoneV, fax: faxV, other: otherV } = values;

  const [mobileValue, setMobileValue] = useState(mobileV);
  const [telephoneValue, setTelephoneValue] = useState(telephoneV);
  const [faxValue, setFaxValue] = useState(faxV);
  const [otherValue, setOtherValue] = useState(otherV);

  const handleInput = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);

    switch (e.target.name) {
      case "mobile":
        setMobileValue(formattedPhoneNumber);
        values.mobile = formattedPhoneNumber;
        break;
      case "telephone":
        setTelephoneValue(formattedPhoneNumber);
        values.telephone = formattedPhoneNumber;
        break;
      case "fax":
        setFaxValue(formattedPhoneNumber);
        values.fax = formattedPhoneNumber;
        break;
      case "other":
        setOtherValue(formattedPhoneNumber);
        values.other = formattedPhoneNumber;
        break;
      default:
        break;
    }
  };

  useEffect(
    () => () => {
      setMobileValue("");
      values.mobile = "";

      setTelephoneValue("");
      values.telephone = "";

      setFaxValue("");
      values.fax = "";

      setOtherValue("");
      values.other = "";
    },
    []
  );

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDBox mt={2} width="100%" display="flex" justifyContent="space-between">
          <MDTypography variant="h6" fontWeight="bold">
            Contact Information
          </MDTypography>
          <MDTypography variant="caption" fontWeight="regular" color="warning">
            * = Required Fields
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={mobile.type}
              label={mobile.label}
              name={mobile.name}
              value={mobileValue}
              placeholder="(123) 123-5075"
              onChange={(e) => handleInput(e)}
              // error={errors.mobile && touched.mobile}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={telephone.type}
              label={telephone.label}
              name={telephone.name}
              value={telephoneValue}
              placeholder="(123) 123-5075"
              onChange={(e) => handleInput(e)}
              // error={errors.telephone && touched.telephone}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={fax.type}
              label={fax.label}
              name={fax.name}
              value={faxValue}
              onChange={(e) => handleInput(e)}
              placeholder="(123) 123-5075"
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6}>
            <FormField
              type={other.type}
              label={other.label}
              name={other.name}
              value={otherValue}
              onChange={(e) => handleInput(e)}
              placeholder="(123) 123-5075"
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for Socials
Contact.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default Contact;
