/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";

import { useMaterialUIController } from "context";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

import OrderMgr from "layouts/Order/OrderMgr";
// Material Dashboard 2 PRO React themes

// Material Dashboard 2 PRO React Dark Mode themes

const { INVENTORY } = InventoryMgr;

const { convertToCurrency } = OrderMgr;

function ProcessFeeTable({ data }) {
  const { payments } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const itemRentedColumns = [
    {
      Header: "Card Type",
      accessor: "card_type",
      headerAlign: "left",
      width: "20%",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
            >
              <MDTypography variant="button" fontWeight="regular">
                {row?.original?.card_type || ""}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    // {
    //   Header: "Item name",
    //   accessor: "item_name",
    //   width: "15%",
    //   headerAlign: "left",
    //   Cell: ({ row }) => {
    //     const canViewInventoryDetail = hasPermission("view_inventoryitem", permissions?.inventory);
    //     const status = (
    //       <MDBox>
    //         {!canViewInventoryDetail && (
    //           // <MDBox mr={2}>
    //           <Tooltip title="You don't have permission to View Details" placement="top">
    //             <Icon>error_outlined</Icon>
    //           </Tooltip>
    //           // </MDBox>
    //         )}
    //         <MDTypography
    //           variant="button"
    //           color="text"
    //           onClick={() => canViewInventoryDetail && showItemDetail(row.original?.item)}
    //         >
    //           <MDTypography
    //             component={Link}
    //             to="#"
    //             variant="button"
    //             color={!canViewInventoryDetail ? "text" : "info"}
    //             fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
    //             textGradient
    //           >
    //             {row.original?.item?.name || ""}
    //           </MDTypography>
    //         </MDTypography>
    //       </MDBox>
    //     );

    //     return status;
    //   },
    // },
    {
      Header: "Price",
      accessor: "process_fee",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.process_fee || 0)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "comment",
      accessor: "comment",
      headerAlign: "left",
      width: "20%",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
            >
              <MDTypography variant="button" fontWeight="regular">
                {row?.original?.comment || ""}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
  ];

  const dataTableData = {
    columns: itemRentedColumns,
    rows: payments || [],
  };

  return (
    <MDBox>
      <MDBox>
        <MDBox pl={2} display="flex" justifyContent="space-between">
          <MDBox pb={1}>
            <MDTypography variant="h6" color="info">
              Credit Card Processing Fee
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" pl={4}>
          <Grid container spacing={1}>
            <DataTable
              height="20rem"
              table={dataTableData}
              entriesPerPage={false}
              // showTotalEntries={false}
              isSorted={false}
              canSearch={false}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

ProcessFeeTable.defaultProps = {
  data: [{}],
};

ProcessFeeTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default ProcessFeeTable;
