/* eslint-disable prefer-const */
/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import {
  Autocomplete,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Zoom,
} from "@mui/material";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";
import CustomModal from "components/CustomModal";
import MDSnackbar from "components/MDSnackbar";
import MDInput from "components/MDInput";

import Lens from "layouts/Warehouse/QualityCheck/qc-detail/components/QCSheets/Lens";
import Camera from "layouts/Warehouse/QualityCheck/qc-detail/components/QCSheets/Camera";
import BasicInfo from "layouts/Warehouse/QualityCheck/qc-detail/BasicInfo";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";

import RentItems from "./qc-detail/RentItems";
import EVF from "./qc-detail/components/QCSheets/EVF";
import Filters from "./qc-detail/components/QCSheets/Filters";
import Monitor from "./qc-detail/components/QCSheets/Monitor";
import ServiceHistory from "./qc-detail/components/Modals/ServiceHistory";
import RentalHitory from "./qc-detail/components/Modals/RentalHitory";

const {
  httpRequest,
  setStorageItem,
  getResponseMsg,
  getStorageItem,
  WAREHOUSE_QUALITY_CHECK,
  capitalizeFirstLetter,
} = WarehouseMgr;

function QualityCheckDetails() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const pdfOptions = [
    { name: "Internal", value: "internal" },
    { name: "Customer", value: "customer" },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from || WAREHOUSE_QUALITY_CHECK.LIST_ROUTE;
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [slackBotMenu, setSlackBotMenu] = useState(null);

  const [orderData, setOrderData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showServiceHistory, setShowServiceHistory] = useState(false);
  const [showRentalHistory, setShowRentalHistory] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to Save QC Sheet");
  const [successMsg, setSuccessMsg] = useState("Successfully Saved QC Sheet");
  const [notifTitle, setNotifTitle] = useState("Upload Document");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showDownloadPdfDIalog, setShowDownloadPdfDialog] = useState(false);
  const [pdfOptionValue, setPdfOptionValue] = useState(pdfOptions[0]);

  const openSlackBotMenu = (event) => setSlackBotMenu(event.currentTarget);
  const closeSlackBotMenu = () => setSlackBotMenu(null);

  const openServiceHistory = () => setShowServiceHistory(true);
  const closeServiceHistory = () => setShowServiceHistory(false);
  const openRentalHistory = () => setShowRentalHistory(true);
  const closeRentalHistory = () => setShowRentalHistory(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const handleOpenDownloadPdfDialog = () => setShowDownloadPdfDialog(true);
  const handleCloseDownloadPdfDialog = () => setShowDownloadPdfDialog(false);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOpenTooltip = () => {
    if (orderData?.qc_sheet?.uuid === undefined) {
      setOpenTooltip(true);
    }
  };

  const processRows = (data = []) =>
    // console.log({
    //   ...data,
    //   staged_items: data?.items?.staged,
    //   pending_items: data?.items?.pending,
    // });
    ({
      ...data,
      qcSheet: data?.qc_sheet,
    });
  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("warehouse-qc-detail", null, {
      uuid: qcDetails?.uuid,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      setOrderData(processRows(responseDetails?.data?.data));

      setStorageItem("current_qc_detail", processRows(responseDetails?.data?.data));
    } else {
      setStorageItem("current_qc_detail", null);
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE, {
        state: { from: location },
        // replace: true
      });
    }

    setLoading(false);

    return 0;
  };

  const downloadPdf = async () => {
    setLoading(true);
    handleCloseDownloadPdfDialog();
    const body = {};
    const responseDetails = await httpRequest("download-pdf", body, {
      uuid: qcDetails?.uuid,
      source: pdfOptionValue?.value,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      window.open(responseDetails?.data?.data?.url, "_blank");
      // console.log(responseDetails?.data?.data?.url);
    } else {
      // console.log(responseDetails);
      let responseErrMsg = "";
      Object.entries(responseDetails?.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      setErrMsg(responseErrMsg || "Failed to generate PDF file.");
      openErrorSB();
      // console.log("not donwloaded");
      //  setStorageItem("current_qc_detail", null);
    }

    setLoading(false);
  };

  useEffect(() => {
    const controllerRequest = new AbortController();
    if (qcDetails === {}) {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE, {
        // replace: true
      });
    }
    loadDetails();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  const handleClose = () => {
    // console.log(location, from);
    if (from && from !== WAREHOUSE_QUALITY_CHECK.CREATE_ROUTE) {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else {
      navigate(WAREHOUSE_QUALITY_CHECK?.LIST_ROUTE, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    }
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "top", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      {/* <MenuItem onClick={close} disabled>
        Edit
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          openServiceHistory();
          close();
        }}
        // disabled
      >
        Service History
      </MenuItem>
      <MenuItem
        onClick={() => {
          // console.log(qcDetails);
          openRentalHistory();
          close();
        }}
        // disabled
      >
        Rental History
      </MenuItem>
      <MenuItem
        onClick={() => {
          // downloadPdf();
          handleOpenDownloadPdfDialog();
          close();
        }}
        disabled={orderData?.qc_sheet?.uuid === undefined}
      >
        <MDBox
        //  display="flex"
        >
          <MDBox>
            <MDTypography variant="caption" fontWeight="regular">
              Download PDF
            </MDTypography>
          </MDBox>
          {orderData?.qc_sheet?.uuid === undefined && (
            <MDBox mt={-0.5}>
              <MDTypography variant="caption" fontWeight="regular" color="warning">
                * No QC sheet available *
              </MDTypography>
              {/* <IconButton
                aria-label="expand row"
                size="small"
                // onClick={() => {
                //   setSearch("");
                //   setShowQrCodeScan(true);
                // }}
                // onClick={() => console.log(cell)}
              >
                <Icon fontSize="small">help</Icon>
              </IconButton> */}
            </MDBox>
          )}
        </MDBox>
      </MenuItem>
      <MenuItem onClick={handleClose}>
        <MDTypography variant="button" color="error" fontWeight="medium">
          Back
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1} display="flex" justifyContent="flex-end">
          {isMobile ? (
            <MDBox>
              <MDTypography
                color="secondary"
                onClick={openSlackBotMenu}
                sx={{
                  ml: "auto",
                  alignSelf: "flex-start",
                }}
              >
                <Icon fontSize="default" sx={{ cursor: "pointer", fontWeight: "bold" }}>
                  more_vert
                </Icon>
              </MDTypography>
              {renderMenu(slackBotMenu, closeSlackBotMenu)}
            </MDBox>
          ) : (
            <MDBox mb={1} display="flex" justifyContent="flex-end">
              <MDBox mr={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => {
                    // console.log(qcDetails);
                    openRentalHistory();
                  }}
                  // disabled
                >
                  rental history
                </MDButton>
              </MDBox>
              <MDBox mr={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => {
                    // console.log(qcDetails);
                    openServiceHistory();
                  }}
                  // disabled
                >
                  service history
                </MDButton>
              </MDBox>
              <Tooltip
                open={openTooltip}
                onClose={handleCloseTooltip}
                onOpen={handleOpenTooltip}
                title="Save the QC sheet before Downloading PDF file."
                TransitionComponent={Zoom}
                followCursor
              >
                <MDBox mr={1}>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={() => {
                      // console.log(qcDetails);
                      // downloadPdf();
                      handleOpenDownloadPdfDialog();
                    }}
                    disabled={orderData?.qc_sheet?.uuid === undefined}
                  >
                    Download PDF
                  </MDButton>
                </MDBox>
              </Tooltip>
              {/* <MDBox mr={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={handleClose}
                  disabled
                >
                  edit
                </MDButton>
              </MDBox> */}
              <MDBox mr={1}>
                <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
                  back
                </MDButton>
              </MDBox>
            </MDBox>
          )}

          {/* <MDButton variant="outlined" color="error" size="small" onClick={playSuccessHuman}>
            sound
          </MDButton> */}
        </MDBox>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12}>
                <BasicInfo data={orderData || {}} />
              </Grid>
              <Grid item xs={12}>
                {orderData?.item?.category === "lens" && (
                  <Lens data={orderData || {}} loadDetails={loadDetails} />
                )}
                {orderData?.item?.category === "camera" && (
                  <Camera data={orderData || {}} loadDetails={loadDetails} />
                )}
                {orderData?.item?.category === "evf" && (
                  <EVF data={orderData || {}} loadDetails={loadDetails} />
                )}
                {orderData?.item?.category === "filter" && (
                  <Filters data={orderData || {}} loadDetails={loadDetails} />
                )}
                {orderData?.item?.category === "monitor" && (
                  <Monitor data={orderData || {}} loadDetails={loadDetails} />
                )}
              </Grid>
              {/* <Grid item xs={12}>
                <RentItems
                  data={orderData || {}}
                  setData={setOrderData}
                  loadDetails={loadDetails}
                />
              </Grid> */}
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      <Footer />
      {showServiceHistory && (
        <CustomModal
          showModal={showServiceHistory}
          handleHideModal={closeServiceHistory}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: isMobile ? "80%" : "98%",
            width: isMobile ? "100%" : "70%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <ServiceHistory onClose={closeServiceHistory} />

          {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
        </CustomModal>
      )}
      {showRentalHistory && (
        <CustomModal
          showModal={showRentalHistory}
          handleHideModal={closeRentalHistory}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: isMobile ? "80%" : "98%",
            width: isMobile ? "100%" : "70%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <RentalHitory onClose={closeRentalHistory} />

          {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
        </CustomModal>
      )}
      {showDownloadPdfDIalog && (
        // <div>
        <Dialog open={showDownloadPdfDIalog} onClose={handleCloseDownloadPdfDialog}>
          <DialogTitle>Download PDF</DialogTitle>
          <DialogContent>
            <Autocomplete
              // disablePortal
              options={pdfOptions || ""}
              value={pdfOptionValue}
              name="PDF Format"
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => (
                <MDInput {...params} variant="standard" label="PDF Format" />
              )}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setPdfOptionValue(newValue);
                  //  values.country = val;
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDownloadPdfDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => downloadPdf()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title="QC Sheet"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="QC Sheet"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </DashboardLayout>
  );
}

export default QualityCheckDetails;
