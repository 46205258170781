/* eslint-disable no-unused-vars */
import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";

const { postRequest, getRequest, putRequest, patchRequest } = HTTPSServices;
const { CUSTOMERS, INVENTORY_TYPE, SUBCATEGORIES, CATEGORIES } = pathUrl;
const { setStorageItem, getStorageItem } = utils;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  switch (action) {
    case "subcategory-list":
      response = await getRequest(
        `${SUBCATEGORIES.API}${rest?.uuid || ""}/subcategories/?search=${
          rest?.searchKeyword || ""
        }&page_size=${rest?.entriesPerPage || "0"}`
      );

      break;

    case "subcategory-create":
      response = await postRequest(`${SUBCATEGORIES.API}${rest?.uuid || ""}/subcategories/`, body);

      break;

    case "subcategory-update":
      response = await putRequest(
        `${SUBCATEGORIES.API}${rest?.uuid || ""}/subcategories/${rest?.subId || ""}/`,
        body
      );

      break;

    case "subcategory-delete":
      response = await patchRequest(
        `${SUBCATEGORIES.API}${rest?.uuid || ""}/subcategories/${rest?.subId || ""}/`
      );

      break;
    default:
      break;
  }
  return response;
};

const getSubCategoryList = async (categoryId = "1", entriesPerPage = 10) => {
  const response = await httpRequest("subcategory-list", null, {
    uuid: categoryId,
    entriesPerPage,
  });

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};
const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (
  sortType = "asc",
  id = "first_name",
  searchKeyword = "",
  entriesPerPage = 10
) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${SUBCATEGORIES.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${id}`
    );
  } else {
    response = await getRequest(
      `${SUBCATEGORIES.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${id}`
    );
  }

  return response;
};

const SubcategoryMgr = {
  getNextPage,
  getPrevPage,
  getSort,
  CUSTOMERS,
  INVENTORY_TYPE,
  CATEGORIES,
  SUBCATEGORIES,
  setStorageItem,
  getStorageItem,
  httpRequest,
  RESPONSE_SUCCESS,
  getSubCategoryList,
};

export default SubcategoryMgr;
