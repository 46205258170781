/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { useMaterialUIController } from "context";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";
import { Card } from "@mui/material";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import Info from "layouts/admin/ManageUsers/user-detail/Info";
import Delete from "layouts/admin/ManageUsers/user-detail/Delete";

import UsersMgr from "./UsersMgr";

const { MANAGE_USERS, httpRequest, setStorageItem, getStorageItem, getResponseMsg } = UsersMgr;

function UserDetail({ loggedinUserInfo }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);

  const [userData, setUserData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to fetch user detail.");
  const [errorSB, setErrorSB] = useState(false);
  const [canEditUser, setCanEditUser] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const from = location.state?.from?.pathname || MANAGE_USERS.LIST_ROUTE;
  const userDetail = JSON.parse(getStorageItem("current_user_detail"));

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const loadDetails = async () => {
    setLoading(true);

    const responseDetails = await httpRequest("users-detail", null, {
      uuid: userDetail?.uuid,
    });

    if (getResponseMsg(responseDetails) === "Success") {
      setUserData(responseDetails.data.data);
    } else {
      setErrMsg("Failed to fetch User Detail.");
      openErrorSB(true);
    }
    setLoading(false);

    return 0;
  };

  useEffect(() => {
    permissions?.customers.map((perm) => {
      if (perm?.value === 54) {
        setCanEditUser(true);
      }
      return 0;
    });
    if (userDetail?.uuid === undefined) {
      navigate(from, {
        state: { from: location, searchKeyword: location.state?.searchKeyword || "" },
        replace: true,
      });
    }
    loadDetails();
  }, []);

  const handleClose = () => {
    if (from && (from !== MANAGE_USERS.CREATE_ROUTE || from !== MANAGE_USERS.EDIT_ROUTE)) {
      navigate(from, {
        state: { searchKeyword: location.state?.searchKeyword || "" },
        replace: true,
      });
    } else {
      navigate(MANAGE_USERS.LIST_ROUTE, {
        state: { searchKeyword: location.state?.searchKeyword || "" },
        replace: true,
      });
    }
  };

  const editUser = () => {
    const item = {
      ...userData,
      // uuid: userData.uuid,
      // first_name: userData.first_name || "",
      // last_name: userData.last_name || "",
      // email: userData.email,
      // company_name: userData.company_name || "",
      // isIndividual: userData.company_name === "N/A",
      // vendor_type: userData.vendor_type,
      // percentage: userData.percentage * 100,

      // mobile: userData.contact.mobile,
      // telephone: userData.contact.telephone,
      // fax: userData.contact.fax,
      // other: userData.contact.other,

      // line_1: userData.address.line_1,
      // line_2: userData.address.line_2,
      // city: userData.address.city,
      // state: userData.address.state,
      // country: userData.address.country,
      // zip_code: userData.address.zip_code,
    };

    const temp = { ...item, pageState: "EDIT" };
    setStorageItem("current_user_detail", temp);
    // console.log(
    //   temp.permissions.customers,
    //   temp.permissions.inventory,
    //   temp.permissions.orders,
    //   temp.permissions.payments
    // );
    navigate(MANAGE_USERS.EDIT_ROUTE, {
      state: { from: location, searchKeyword: location.state?.searchKeyword },
      // replace: true,
    });
  };

  return (
    <DashboardLayout>
      {isLoading && <SimpleBackdrop />}
      <DashboardNavbar />
      <MDBox>
        <MDBox mb={1}>
          <MDBox display="flex" justifyContent="flex-end">
            <MDBox display="flex" justifyContent="flex-end">
              <MDBox>
                <MDButton
                  size="small"
                  variant="outlined"
                  color="info"
                  onClick={editUser}
                  sx={{ marginRight: 1 }}
                  // disabled
                >
                  Edit
                </MDButton>
              </MDBox>
            </MDBox>
            <MDButton variant="outlined" color="error" size="small" onClick={handleClose}>
              back
            </MDButton>
          </MDBox>
        </MDBox>

        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={12} sx={{ display: "flex" }}>
                <Info data={userData || []} />
              </Grid>
              {/* <Grid item xs={12}>
                {userData?.vendor_type?.value === "consignment" && (
                  <SubItems data={userData || []} />
                )}
              </Grid> */}
            </Grid>
          </MDBox>
        </Card>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto", mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Delete data={userData} />
            </Grid>
          </Grid>
        </Card>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

UserDetail.defaultProps = {
  loggedinUserInfo: {},
};

// typechecking props for OtherInfo
UserDetail.propTypes = {
  loggedinUserInfo: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
  loggedinUserInfo: state.authentication.userDetail,
});

export default connect(mapStateToProps, null)(UserDetail);
