import * as Yup from "yup";

const phoneRegExp = /^(\([0-9]{3}\)|[0-9]{3}-) [0-9]{3}-[0-9]{4}$/;

const validations = Yup.object().shape({
  first_name: Yup.string().required("First name is required."),
  last_name: Yup.string().required("Last name is required."),
  company_name: Yup.string().required(
    "Company Name is required, check Individual if not applicable"
  ),
  default_discount: Yup.number().required("Default Discount is required"),
  // .matches(/^\d+(?:\.\d{0,2})$/, "Must be in decimal form ie. (20.00)"),
  email: Yup.string().required("Email address is required.").email("Your email address is invalid"),

  // line_1: Yup.string().required("Address is required."),
  // city: Yup.string().required("City is required."),
  // state: Yup.string().required("State is required."),
  // zip_code: Yup.string().required("Zip is required.").min(4, "Must be 4 digits or more"),

  mobile: Yup.string()
    // .required("Telephone number is required.")
    .matches(phoneRegExp, "Must be 1-3-3-4 format i.e, (123) 123-1234"),
  telephone: Yup.string().matches(phoneRegExp, "Must be 1-3-3-4 format i.e, (123) 123-1234"),
  fax: Yup.string().matches(phoneRegExp, "Must be 1-3-3-4 format i.e, (123) 123-1234"),
  other: Yup.string().matches(phoneRegExp, "Must be 1-3-3-4 format i.e, (123) 123-1234"),
});

export default validations;
