/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";

import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";

import {
    navbar,
    navbarContainer,
    navbarRow,
    navbarIconButton,
    navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

import {
    useMaterialUIController,
    setTransparentNavbar,
    setMiniSidenav,
    setOpenConfigurator,
} from "context";

import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import { NO_SERVER_RESPONSE } from "services/context/messages";
import HTTPSServices from "services/https/HTTPSServices";
import utils from "services/utils";
import authActions from "services/redux/actions/authActions";
import helperActions from "services/redux/actions/helperActions";
import helper from "services/helpers";
import CategoryMgr from "layouts/admin/Categories/CategoryMgr";
import VendorMgr from "layouts/Vendors/VendorMgr";
import MDBadge from "components/MDBadge";
import CustomModal from "components/CustomModal";
import Notifications from "layouts/dashboards/analytics/components/Notifications";

const { postRequest, getRequest } = HTTPSServices;
const {
    LOGIN,
    LOGOUT,
    USER_DETAILS,
    _DASHBOARD,
    MANAGE_USERS,
    CUSTOMERS,
    INVENTORY,
    ORDERS,
    CATEGORIES,
    MANAGE_VENDORS,
    MANAGE_LOCATIONS,
    ACCESS_DENIED,
} = pathUrl;
const { getRefreshToken, getResponseMsg, setStorageItem, hasPermission, hasRolePermission } = utils;
const { logOut, setMeDetails } = authActions;
const {
    setLocationList,
    setCountryList,
    setCategoryReduxList,
    setWarehouseList,
    setConsignerList,
} = helperActions;
const { getLocationList, getCountryList, getAllWarehouseList } = helper;
const { getCategoryList } = CategoryMgr;
const { getConsignerList } = VendorMgr;

function DashboardNavbar({ absolute, light, isMini, ...props }) {
    const {
        logout,
        locationList,
        countryList,
        userDetail,
        me,
        setCategoriesReduxList,
        warehouseList,
        setConsignerReduxList,
    } = props;

    const roleList = [
        { name: "Admin", value: "admin" },
        // { name: "Agent", value: "agent" },
        { name: "Manager", value: "manager" },
        // { name: "Operator", value: "operator" },
    ];

    const navigate = useNavigate();
    const location = useLocation();
    const route = useLocation().pathname.split("/").slice(1);
    const reduxLocation = useSelector((state) => state.helper?.locations);
    const reduxCountry = useSelector((state) => state.helper?.country);
    const reduxWarehouse = useSelector((state) => state.helper?.warehouse);
    const reduxCategories = useSelector((state) => state.helper?.categoryList);
    const reduxConsigner = useSelector((state) => state.helper?.consignerList);

    const [isMobile, setIsMobile] = useState(false);
    const [navbarType, setNavbarType] = useState();
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
    const [openMenu, setOpenMenu] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [errorSB, setErrorSB] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notifCount, setNotifCount] = useState(0);
    const [showNotifModal, setShowNotifModal] = useState(false);

    const userLocation = userDetail?.location?.name;
    const userWarehouse = userDetail?.warehouse?.name;

    const openErrorSB = () => setErrorSB(false);
    const closeErrorSB = () => setErrorSB(false);
    const openNotifModal = () => setShowNotifModal(true);
    const closeNotifModal = () => setShowNotifModal(false);

    const loadHelpers = async () => {
        if (reduxLocation === undefined || reduxLocation.length === 0) {
            const locationResponse = await getLocationList();
            if (locationResponse.status === RESPONSE_SUCCESS || locationResponse.status === 201) {
                locationList(locationResponse.data.data.results);
                setStorageItem("locations_list", locationResponse.data.data.results);
            }
        }

        if (reduxWarehouse.length === 0 || reduxWarehouse === undefined) {
            const warehouseResponse = await getAllWarehouseList();
            if (warehouseResponse.status === RESPONSE_SUCCESS || warehouseResponse.status === 201) {
                warehouseList(warehouseResponse.data.data.results);
            }
        }

        if (reduxCountry === undefined || reduxCountry.length === 0) {
            const countryResponse = await getCountryList();
            if (countryResponse.status === RESPONSE_SUCCESS || countryResponse.status === 201) {
                countryList(countryResponse.data.data);
            }
        }

        if (reduxCategories === undefined || reduxCategories.length === 0) {
            const categoryResponse = await getCategoryList("", 100);
            if (categoryResponse.status === RESPONSE_SUCCESS || categoryResponse.status === 201) {
                setCategoriesReduxList(categoryResponse.data.data.results);
            }
        }

        if (reduxConsigner === undefined || reduxConsigner.length === 0) {
            const consignerResponse = await getConsignerList();
            if (consignerResponse.status === RESPONSE_SUCCESS || consignerResponse.status === 201) {
                setConsignerReduxList(consignerResponse.data.data);
            }
        }
    };

    const checkAccess = (val = "", permissions = []) => {
        let permitted = true;
        switch (val) {
            case CUSTOMERS.LIST_ROUTE:
                if (!hasPermission("view_customer", permissions?.customers)) {
                    permitted = false;
                }
                break;
            case CUSTOMERS.CREATE_ROUTE:
                if (!hasPermission("add_customer", permissions?.customers)) {
                    permitted = false;
                }
                break;
            case CUSTOMERS.DETAIL_ROUTE:
                if (!hasPermission("view_customer", permissions?.customers)) {
                    permitted = false;
                }
                break;
            case CUSTOMERS.EDIT_ROUTE:
                if (!hasPermission("change_customer", permissions?.customers)) {
                    permitted = false;
                }
                break;

            case INVENTORY.LIST_ROUTE:
                if (!hasPermission("view_inventoryitem", permissions?.inventory)) {
                    permitted = false;
                }
                break;
            case INVENTORY.CREATE_ROUTE:
                if (!hasPermission("add_inventoryitem", permissions?.inventory)) {
                    permitted = false;
                }
                break;
            case INVENTORY.DETAIL_ROUTE:
                if (!hasPermission("view_inventoryitem", permissions?.inventory)) {
                    permitted = false;
                }
                break;
            case INVENTORY.EDIT_ROUTE:
                if (!hasPermission("change_inventoryitem", permissions?.inventory)) {
                    permitted = false;
                }
                break;

            case ORDERS.LIST_ROUTE:
                if (!hasPermission("view_order", permissions?.orders)) {
                    permitted = false;
                }
                break;
            case ORDERS.CREATE_ROUTE:
                if (!hasPermission("add_order", permissions?.orders)) {
                    permitted = false;
                }
                break;
            case ORDERS.DETAIL_ROUTE:
                if (!hasPermission("view_order", permissions?.orders)) {
                    permitted = false;
                }
                break;
            case ORDERS.EDIT_ROUTE:
                if (!hasPermission("change_order", permissions?.orders)) {
                    permitted = false;
                }
                break;

            default:
                break;
        }

        if (permitted === false) {
            navigate(_DASHBOARD.HOMEPAGE, {
                replace: true,
            });
        }
    };
    const checkRoleAccess = (val = "", role = {}) => {
        let permitted = true;
        switch (val) {
            case CATEGORIES.LIST_ROUTE:
            case CATEGORIES.DETAIL_ROUTE:
            case MANAGE_VENDORS.LIST_ROUTE:
            case MANAGE_VENDORS.CREATE_ROUTE:
            case MANAGE_VENDORS.DETAIL_ROUTE:
            case MANAGE_VENDORS.EDIT_ROUTE:
            case MANAGE_USERS.LIST_ROUTE:
            case MANAGE_USERS.CREATE_ROUTE:
            case MANAGE_USERS.DETAIL_ROUTE:
            case MANAGE_USERS.EDIT_ROUTE:
            case MANAGE_LOCATIONS.LIST_ROUTE:
            case MANAGE_LOCATIONS.CREATE_ROUTE:
            case MANAGE_LOCATIONS.DETAIL_ROUTE:
            case MANAGE_LOCATIONS.EDIT_ROUTE:
                if (!hasRolePermission(role?.value, roleList)) {
                    permitted = false;
                }
                break;

            // case SUCCESS_PAYMENT.PAGE_ROUTE:
            //   permitted = false;
            //   break;

            default:
                break;
        }

        if (permitted === false) {
            navigate(ACCESS_DENIED.LIST_ROUTE, {
                replace: true,
            });
        }
    };

    const getUserDetails = async () => {
        const response = await getRequest(USER_DETAILS.api);

        if (response?.status === RESPONSE_SUCCESS) {
            me(response.data.data);

            checkAccess(location?.pathname, response?.data?.data?.permissions);
            checkRoleAccess(location?.pathname, response?.data?.data?.group);

            loadHelpers();
        } else {
            setErrMsg(NO_SERVER_RESPONSE);
            openErrorSB(true);
            navigate(LOGIN.route, { state: { from: location }, replace: true });
        }
    };

    const getNotificationCount = async () => {
        const response = await getRequest(`${_DASHBOARD.UNREAD_NOTIF}`);

        if (getResponseMsg(response) === "Success") {
            setNotifCount(response.data.data.count);
        }
    };

    useEffect(() => {
        // let isMobileView = false;
        const controllerRequest = new AbortController();

        // const setResponsiveness = () => {
        //   if (window.innerWidth < 768) {
        //     isMobileView = true;
        //   } else {
        //     isMobileView = false;
        //   }
        //   return 0;
        // };
        // setResponsiveness();

        // setIsMobile(isMobileView);
        // setStorageItem("isMobile", isMobileView);

        // window.addEventListener("resize", () => setResponsiveness());

        if (!localStorage.getItem("accessToken")) {
            navigate(LOGIN.route, { state: { from: location }, replace: true });
        } else {
            getUserDetails();
            getNotificationCount();
        }

        return () => {
            // window.addEventListener("resize", () => setResponsiveness());
            controllerRequest.abort();
        };
    }, []);

    useEffect(() => {
        const setResponsiveness = () =>
            window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    useEffect(() => {
        // Setting the navbar type

        if (fixedNavbar) {
            setNavbarType("sticky");
        } else {
            setNavbarType("static");
        }

        // A function that sets the transparent state of the navbar.
        function handleTransparentNavbar() {
            setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
        }

        /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
        window.addEventListener("scroll", handleTransparentNavbar);

        // Call the handleTransparentNavbar function to set the state with the initial value.
        handleTransparentNavbar();

        // Remove event listener on cleanup
        return () => {
            window.removeEventListener("scroll", handleTransparentNavbar);
        };
    }, [dispatch, fixedNavbar]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
    const handleCloseMenu = () => setOpenMenu(false);

    const handleLogout = async () => {
        setIsLoading(true);
        await postRequest(LOGOUT.api, { refresh: getRefreshToken() });
        setIsLoading(false);
        logout();
        navigate(LOGIN.route, { state: { from: location }, replace: true });
    };

    // Render the notifications menu
    const renderMenu = () => (
        <Menu
            anchorEl={openMenu}
            anchorReference={null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
            sx={{ mt: 2 }}
        >
            <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
            <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
            <NotificationItem
                icon={<Icon>shopping_cart</Icon>}
                title="Payment successfully completed"
            />
        </Menu>
    );

    // Styles for the navbar icons
    const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
        color: () => {
            let colorValue = light || darkMode ? white.main : dark.main;

            if (transparentNavbar && !light) {
                colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
            }

            return colorValue;
        },
    });

    const showUserDetail = () => {
        setStorageItem("current_user_detail", userDetail);
        navigate(MANAGE_USERS.DETAIL_ROUTE, { state: { from: location }, replace: true });
    };

    return (
        <AppBar
            position={absolute ? "absolute" : navbarType}
            color="inherit"
            sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
        >
            {isLoading && <SimpleBackdrop />}
            <Toolbar sx={(theme) => navbarContainer(theme)}>
                <MDBox
                    color="inherit"
                    mb={{ xs: 1, md: 0 }}
                    sx={(theme) => navbarRow(theme, { isMini })}
                >
                    <Breadcrumbs
                        icon="home"
                        title={route[route.length - 1]}
                        route={route}
                        light={light}
                    />
                </MDBox>
                {isMini ? null : (
                    <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
                        {/* <MDBox pr={1}>
              <MDInput label="Search here" />
            </MDBox> */}
                        <MDBox
                            textAlign="center"
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="right"
                        >
                            {!isMobile && (
                                <MDBox textAlign="center">
                                    <MDTypography variant="button" color="inherit">
                                        <MDTypography
                                            component={Link}
                                            onClick={handleConfiguratorOpen}
                                            to={route}
                                            variant="button"
                                            color={light ? "white" : "dark"}
                                            fontWeight="medium"
                                        >
                                            {userLocation ?? ""}
                                        </MDTypography>
                                    </MDTypography>

                                    <MDTypography
                                        variant="button"
                                        fontWeight="medium"
                                        color={light ? "white" : "dark"}
                                    >
                                        &nbsp;&nbsp;/&nbsp;&nbsp;
                                    </MDTypography>
                                    <MDTypography variant="button" color="inherit">
                                        <MDTypography
                                            component={Link}
                                            onClick={handleConfiguratorOpen}
                                            to={route}
                                            variant="button"
                                            color={light ? "white" : "dark"}
                                            fontWeight="medium"
                                        >
                                            {userWarehouse ?? ""}
                                        </MDTypography>
                                    </MDTypography>
                                </MDBox>
                            )}
                        </MDBox>
                        <MDBox
                            color={light ? "white" : "inherit"}
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="right"
                        >
                            {/* <Link to="/dashboards"> */}
                            <IconButton
                                sx={navbarIconButton}
                                size="small"
                                disableRipple
                                onClick={showUserDetail}
                            >
                                <Icon sx={iconsStyle}>account_circle</Icon>
                            </IconButton>
                            {/* </Link> */}
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarMobileMenu}
                                onClick={handleMiniSidenav}
                            >
                                <Icon sx={iconsStyle} fontSize="medium">
                                    {miniSidenav ? "menu_open" : "menu"}
                                </Icon>
                            </IconButton>
                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                onClick={handleConfiguratorOpen}
                            >
                                <Icon sx={iconsStyle}>settings</Icon>
                            </IconButton>

                            <IconButton
                                size="small"
                                disableRipple
                                color="inherit"
                                sx={navbarIconButton}
                                aria-controls="notification-menu"
                                aria-haspopup="true"
                                variant="contained"
                                // onClick={handleOpenMenu}
                                onClick={openNotifModal}
                                // disabled
                            >
                                <MDBadge badgeContent={notifCount} color="error" size="xs" circular>
                                    <Icon sx={iconsStyle}>notifications</Icon>
                                </MDBadge>
                            </IconButton>

                            {false && (
                                <IconButton
                                    size="small"
                                    disableRipple
                                    color="inherit"
                                    sx={navbarIconButton}
                                    aria-controls="notification-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    onClick={handleLogout}
                                >
                                    <Icon sx={iconsStyle}>logout</Icon>
                                </IconButton>
                            )}

                            {renderMenu()}
                        </MDBox>

                        {showNotifModal && (
                            <CustomModal
                                showModal={showNotifModal}
                                handleHideModal={closeNotifModal}
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    height: "98%",
                                    width: "90%",
                                    // border: "2px solid #000",
                                    boxShadow: 24,
                                    borderRadius: "5px",
                                    p: 4,
                                    overflow: "auto",
                                }}
                            >
                                <Notifications onClose={closeNotifModal} />

                                {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
                            </CustomModal>
                        )}
                        <MDSnackbar
                            color="error"
                            icon="warning"
                            title="Sign-in"
                            dateTime=""
                            content={errMsg}
                            open={errorSB}
                            onClose={closeErrorSB}
                            close={closeErrorSB}
                            bgWhite={false}
                        />
                    </MDBox>
                )}
            </Toolbar>
        </AppBar>
    );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
    absolute: false,
    light: false,
    isMini: false,
    userDetail: {},
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
    absolute: PropTypes.bool,
    light: PropTypes.bool,
    isMini: PropTypes.bool,
    userDetail: PropTypes.objectOf(PropTypes.any),
    logout: PropTypes.func.isRequired,
    locationList: PropTypes.func.isRequired,
    warehouseList: PropTypes.func.isRequired,
    countryList: PropTypes.func.isRequired,
    me: PropTypes.func.isRequired,
    setCategoriesReduxList: PropTypes.func.isRequired,
    setConsignerReduxList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    userDetail: state.authentication.userDetail,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logOut()),
    locationList: (data) => dispatch(setLocationList(data)),
    warehouseList: (data) => dispatch(setWarehouseList(data)),
    countryList: (data) => dispatch(setCountryList(data)),
    me: (data) => dispatch(setMeDetails(data)),
    setCategoriesReduxList: (data) => dispatch(setCategoryReduxList(data)),
    setConsignerReduxList: (data) => dispatch(setConsignerList(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNavbar);
