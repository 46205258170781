/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDSnackbar from "components/MDSnackbar";
import DraggableDialog from "components/DragableDialog";

import { RESPONSE_SUCCESS } from "services/context/response";

import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import { Icon, IconButton, Modal, Tooltip } from "@mui/material";
import SubcategoryMgr from "layouts/admin/Subcategories/SubcategoryMgr";
import SubcategoriesNew from "./SubcategoriesNew";

const { httpRequest, setStorageItem, SUBCATEGORIES, getStorageItem } = SubcategoryMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "29rem",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: "20px",
  p: 4,
};

function SubcategoriesSearch({ handleClose }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const entriesPerPage = 100;

  const categoryData = JSON.parse(getStorageItem("current_categories_detail"));
  const [successMsg, setSuccessMsg] = useState("");
  const [itemNameToBeDeleted, setItemNameToBeDeleted] = useState("");

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showAddNewSubCatModal, setShowAddNewSubCatModal] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errMsg, setErrMsg] = useState("Failed to Create item");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const handleOpenDeleteDialog = () => setShowDeleteDialog(true);
  const handleCloseDeleteDialog = () => setShowDeleteDialog(false);

  const controllerRequest = new AbortController();

  const processRows = (data = []) => {
    const list = [];

    data.map((item) =>
      list.push({
        uuid: item.uuid,
        name: item.name,
      })
    );

    return list;
  };

  const proccessResponse = (response) => {
    if (response.status === RESPONSE_SUCCESS) {
      setSubCategoryList(processRows(response?.data?.data?.results));
    }
  };

  const handleOpenNewSubCategoryModal = () => setShowAddNewSubCatModal(true);
  const handleCloseNewSubCategoryModal = async (action = "", rest = {}) => {
    setShowAddNewSubCatModal(false);

    switch (action) {
      case "save":
        if (categoryData?.uuid) {
          // setIsLoading(true);
          proccessResponse(
            await httpRequest("subcategory-list", null, {
              uuid: categoryData?.uuid,
              entriesPerPage,
            })
          );
          setSuccessMsg(rest?.msg);
          openSuccessSB();
          // setIsLoading(false);
        }
        break;
      default:
        break;
    }
  };

  useEffect(async () => {
    if (categoryData?.uuid !== "") {
      setIsLoading(true);
      proccessResponse(
        await httpRequest("subcategory-list", null, {
          uuid: categoryData?.uuid,
          entriesPerPage,
        })
      );

      setIsLoading(false);
    }

    return () => {
      controllerRequest.abort();
    };
  }, [categoryData?.uuid]);

  const editSubCategory = (data) => {
    const tempData = { ...data, pageState: "EDIT" };
    setStorageItem("current_sub_categories_detail", tempData);
    handleOpenNewSubCategoryModal();
  };

  const newSubCategory = () => {
    const tempData = { pageState: "NEW" };
    setStorageItem("current_sub_categories_detail", tempData);
    handleOpenNewSubCategoryModal();
  };

  const deleteSubCategory = async () => {
    setIsLoading(true);
    handleCloseDeleteDialog();

    const response = await httpRequest("subcategory-delete", null, {
      uuid: categoryData?.uuid,
      subId: itemNameToBeDeleted?.uuid,
    });

    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      proccessResponse(
        await httpRequest("subcategory-list", null, {
          uuid: categoryData?.uuid,
          entriesPerPage,
        })
      );
      setSuccessMsg(`Deleted ${itemNameToBeDeleted?.name} successfully`);
      openSuccessSB();
    } else {
      setErrMsg(response.data.data);
      openErrorSB();
    }

    setIsLoading(false);
  };

  const dataTableData = {
    columns: [
      {
        Header: "sub-category name",
        accessor: "name",
        // type: "detail-link",
        width: "40%",
        headerAlign: "left",
        hasSort: true,
      },
      {
        Header: "action",
        accessor: "",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row }) => {
          const status = (
            <MDBox direction="column" justifyContent="space-between" alignItems="center">
              <IconButton
                size="small"
                aria-label="edit"
                color="info"
                onClick={() => editSubCategory(row.original)}
                // disabled
              >
                <Tooltip title="Edit Sub-category" placement="top">
                  <Icon fontSize="small">edit</Icon>
                </Tooltip>
              </IconButton>
              <IconButton
                size="small"
                aria-label="edit"
                color="error"
                onClick={() => {
                  // console.log(row.values.name);
                  setItemNameToBeDeleted(row.original);
                  handleOpenDeleteDialog();
                }}
                // disabled
              >
                <Tooltip title="Delete Sub-category" placement="top">
                  <Icon fontSize="small">delete</Icon>
                </Tooltip>
              </IconButton>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: subCategoryList,
  };

  return (
    <MDBox mt={2.5}>
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox display="flex" justifyContent="space-between" mx={2}>
        <MDBox display="flex" justifyContent="flex-start">
          <MDTypography variant="h6" fontWeight="medium">
            {categoryData?.name} {SUBCATEGORIES.TITLE}
          </MDTypography>
          <IconButton size="small" aria-label="edit" color="info" onClick={newSubCategory}>
            <Icon fontSize="small">add_circle</Icon>
          </IconButton>
        </MDBox>
        <MDBox>
          <IconButton
            size="small"
            aria-label="edit"
            color={darkMode ? "white" : "dark"}
            onClick={handleClose}
          >
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      <DataTable
        table={dataTableData}
        entriesPerPage={false}
        isSorted={false}
        canSearch={false}
        isLoading={isLoading}
        canRefresh
      />
      {showAddNewSubCatModal && (
        <Modal
          keepMounted
          open={showAddNewSubCatModal}
          onClose={handleCloseNewSubCategoryModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
            <SubcategoriesNew handleClose={handleCloseNewSubCategoryModal} />
          </MDBox>
        </Modal>
      )}

      {showDeleteDialog && (
        <DraggableDialog
          title="Delete Sub-Category"
          description={`Are you sure you want to delete ${itemNameToBeDeleted?.name}?`}
          buttonDesc="Delete"
          openDialog={showDeleteDialog}
          handleClose={handleCloseDeleteDialog}
          handleConfirm={deleteSubCategory}
          fullWidth={false}
        />
      )}

      <MDSnackbar
        color="success"
        icon="check"
        title="Sub-category"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />

      <MDSnackbar
        color="error"
        icon="warning"
        title="Item Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

SubcategoriesSearch.defaultProps = {
  handleClose: () => {},
};

SubcategoriesSearch.propTypes = {
  handleClose: PropTypes.func,
};

export default SubcategoriesSearch;
