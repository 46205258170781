/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Card, Modal } from "@mui/material";
import { useState, useEffect } from "react";
import MDButton from "components/MDButton";
import { useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";

import BasicInfo from "layouts/Order/print-order-detail/BasicInfo";
import Availability from "layouts/Inventory/inventory-detail/Availability";
import CancelOrder from "layouts/Order/print-order-detail/CancelOrder";
import MDSnackbar from "components/MDSnackbar";
import SubItems from "layouts/Inventory/inventory-detail/SubItems";

import { useMaterialUIController } from "context";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import OrderMgr from "layouts/Order/OrderMgr";
import RentItems from "layouts/Order/print-order-detail/RentItems";
import OrderSummary from "layouts/Order/print-order-detail/OrderSummary";
import moment from "moment/moment";
import Certificates from "layouts/Order/print-order-detail/Certificates";
import Email from "components/Email";
import CustomModal from "components/CustomModal";
import RevisionLog from "layouts/Order/print-order-detail/RevisionLog";

const {
  httpRequest,
  getStorageItem,
  setStorageItem,
  getResponseMsg,
  ORDERS,
  formatDate,
  convertToDecimal,
} = OrderMgr;

function PrintOrderDetail() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [orderData, setOrderData] = useState([]);
  const [certificateData, setCertificateData] = useState({});
  const [customerData, setCustomerData] = useState({});

  const [errMsg, setErrMsg] = useState("Failed");
  const [successMsg, setSuccessMsg] = useState("Sucess");
  const [notifTitle, setNotifTitle] = useState("Item Creation");

  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [reloadDetails, setReloadDetails] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [emailTypeOptions, setEmailTypeOptions] = useState(null);
  const [showRevisionHistory, setShowRevisionLogs] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openRevisionLogsModal = () => setShowRevisionLogs(true);
  const closeRevisionLogModal = () => setShowRevisionLogs(false);

  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || ORDERS.LIST_ROUTE;
  const orderDetail = JSON.parse(getStorageItem("current_order_detail"));

  const processRows = (data = []) => {
    const tmpList = [];
    let dailyTotal = 0;
    let weeklyTotal = 0;
    let monthlyTotal = 0;

    data?.items.map((item) => {
      if (!item?.optional) {
        dailyTotal += Number(item?.custom_prices?.daily || 0);
        weeklyTotal += Number(item?.custom_prices?.weekly || 0);
        monthlyTotal += Number(item?.custom_prices?.monthly || 0);
      }
      // tmpList.push({
      //   available: item?.available,
      //   item_name: item?.name,
      //   item_price: {
      //     daily: item?.item_price?.daily,
      //     weekly: item?.item_price?.weekly,
      //     monthly: item?.item_price?.monthly,
      //   },
      //   item_type: item?.item_type,
      //   item_uuid: item?.item_uuid,
      //   optional: item?.optional,
      //   quantity: item?.quantity,
      //   uuid: item?.uuid,
      // });
      return 0;
    });

    return { ...data, dailyTotal, weeklyTotal, monthlyTotal };
  };

  const loadDetails = async () => {
    setLoading(true);

    // const responseDetails1 = await httpRequest("inventory-detail", null, {
    //   uuid: itemDetail?.uuid,
    // });

    const responseDetails = await httpRequest("orders-detail", null, {
      uuid: orderDetail?.uuid,
      id: orderDetail?.id,
    });
    // debugger;
    if (getResponseMsg(responseDetails) === "Success") {
      setOrderData(processRows(responseDetails?.data?.data));

      const documentResponse = await httpRequest("customer-documents", null, {
        uuid: responseDetails?.data?.data?.customer?.uuid,
      });

      if (getResponseMsg(documentResponse) === "Success") {
        setCertificateData(documentResponse?.data?.data);
      }

      const customerDetailResponse = await httpRequest("customer-detail", null, {
        uuid: responseDetails?.data?.data?.customer?.uuid,
      });

      // debugger;
      if (getResponseMsg(customerDetailResponse) === "Success") {
        setCustomerData(customerDetailResponse?.data?.data);
        setEmailTypeOptions([
          { name: "Missing Paperworks", disabled: customerData?.has_missing_paperworks === true },
          { name: "Order Invoice", disabled: false },
          { name: "Orders", disabled: false },
        ]);
      }
      // debugger;
    }

    setLoading(false);

    return 0;
  };

  useEffect(() => {
    const controllerRequest = new AbortController();
    setNotifTitle("Order Details");
    if (orderDetail === {}) {
      navigate(ORDERS.LIST_ROUTE, { replace: true });
    }
    loadDetails();

    return () => {
      controllerRequest.abort();
    };
  }, []);

  // useEffect(() => {
  //   setNotifTitle("Inventory Details");
  //   if (itemDetail === {}) {
  //     navigate(INVENTORY.LIST_ROUTE, { replace: true });
  //   }
  //   loadDetails();
  // }, [itemDetail]);

  const handleClose = () => {
    if (from && from !== ORDERS.CREATE_ROUTE) {
      navigate(from, { replace: true });
    } else {
      navigate(ORDERS.LIST_ROUTE, { replace: true });
    }
  };

  const editItem = () => {
    // console.log(orderData);
    const tmpCollection = [];

    orderData?.items.map((item) =>
      tmpCollection.push({
        ...item,
        // ...item.item,
        daily_price: item?.price,
        discount_rate: item?.discount_rate,
        // parent_uuid: item?.uuid,
        // comments,
        // optional: item.optional,
        // quantity: item.quantity,
        // daily_price: item?.custom_prices?.daily,
        // weekly_price: item?.custom_prices?.weekly,
        // monthly_price: item?.custom_prices?.monthly,
        // parent_uuid: item?.uuid,
      })
    );

    const item = {
      ...orderDetail,
      ...orderData,
      customer_name: orderData?.customer?.name,
      customer_uuid: orderData?.customer?.id,
      pickup_date: formatDate(orderData?.pickup_date, "YYYY-MM-DD"),
      pickup_time: formatDate(orderData?.pickup_date, "HH:mm"),
      return_by_date: formatDate(orderData?.return_date, "YYYY-MM-DD"),
      return_by_time: formatDate(orderData?.return_date, "HH:mm"),
      billing_start_date: formatDate(orderData?.billing?.start_date, "YYYY-MM-DD"),
      billing_start_time: formatDate(orderData?.billing?.start_date, "HH:mm"),
      billing_end_date: formatDate(orderData?.billing?.end_date, "YYYY-MM-DD"),
      billing_end_time: formatDate(orderData?.billing?.end_date, "HH:mm"),
      order_description: orderData?.description,
      bill_days: orderData?.billing?.bill_days,
      default_discount: convertToDecimal(orderData?.discount_rate),
      rental_agent_assigned: orderData?.agent,
      deposit_amount: orderData?.deposit_amount,
      collections: { collections: tmpCollection },
      // collections: tmpCollection,
    };

    // console.log(orderData);
    // console.log(item);

    const temp = { ...item, pageState: "EDIT" };
    // console.log(temp);
    setStorageItem("current_order_detail", temp);
    navigate(ORDERS.EDIT_ROUTE, {
      state: { from: location },
      replace: true,
    });
  };

  const handleSendEmail = async () => {
    setShowEmailModal(true);
    // console.log(emailTypeOptions[1]);
    // console.log("email");
  };

  const handleCloseEmailModal = (type = "close", status, emailErrMsg = "") => {
    setNotifTitle("Email");
    switch (type) {
      case emailTypeOptions[0]:
      case emailTypeOptions[1]:
      case emailTypeOptions[2]:
        if (status === true) {
          setSuccessMsg(`Sent email for ${type.name} Successfully`);
          openSuccessSB(true);
        } else {
          setErrMsg(`Failed to send ${type.name} email, this ${emailErrMsg?.data?.order[0]}`);
          openErrorSB(true);
        }
        break;
      default:
        setShowEmailModal(false);
        break;
    }
    setShowEmailModal(false);
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox>
        <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
          <MDBox>
            <Grid container>
              <Grid item xs={6}>
                <BasicInfo data={orderData || {}} />
              </Grid>
              <Grid item xs={3}>
                <Certificates data={orderData || {}} certificateData={certificateData} />
              </Grid>
              <Grid item xs={3}>
                <OrderSummary data={orderData || {}} />
              </Grid>
              {/* <Grid item xs={5}>
                <Availability uuid={itemDetail?.uuid} loadDetails={loadDetails} />
              </Grid> */}
              <Grid item xs={12} pt={2}>
                <RentItems data={orderData || []} />
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </MDBox>
      {showEmailModal && (
        <CustomModal showModal={showEmailModal} handleHideModal={handleCloseEmailModal}>
          <MDBox bgColor={darkMode ? "dark" : "white"}>
            <Email
              onClose={handleCloseEmailModal}
              uuid={customerData?.uuid}
              emailOptions={emailTypeOptions}
              defaultEmailType={emailTypeOptions[2]}
              showCc
            />
          </MDBox>
        </CustomModal>
      )}

      {showRevisionHistory && (
        <CustomModal
          showModal={showRevisionHistory}
          handleHideModal={closeRevisionLogModal}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "90%",
            width: "50%",
            // border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "5px",
            p: 4,
            overflow: "auto",
          }}
        >
          <RevisionLog onClose={closeRevisionLogModal} />

          {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
        </CustomModal>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}

export default PrintOrderDetail;
