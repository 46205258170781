/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HTTPSServices from "services/https/HTTPSServices";
import { RESPONSE_SUCCESS } from "services/context/response";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import DataTable from "components/Tables/DataTable";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import { Icon, IconButton } from "@mui/material";
import CustomerMgr from "layouts/Customers/CustomerMgr";

const { getRequest } = HTTPSServices;
const { getStorageItem, formatDate, httpRequest, getNextPage, getPrevPage, getSort } = CustomerMgr;

function TransactionList({ onClose }) {
  const { uuid } = JSON.parse(getStorageItem("current_customer_detail"));
  const navigate = useNavigate();
  const location = useLocation();
  const stateKeyword = location.state?.searchKeyword || "";

  const [successSB, setSuccessSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPayInvoice, setShowPayInvoice] = useState(false);

  const [transactionList, setTransactionList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState("");
  const [prevPageLink, setPrevPageLink] = useState("");
  const [searchKeyword, setSearchKeyword] = useState(stateKeyword);
  // const [prevSortId, setPrevSortId] = useState("");
  const [sortType, setSortType] = useState("asc");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const dataTableData = {
    columns: [
      { Header: "date", accessor: "transaction_date", headerAlign: "left", width: "auto" },

      {
        Header: "file name ",
        accessor: "contract",
        headerAlign: "left",
        width: "auto",
        Cell: ({ value, row }) => {
          const status = (
            <MDBox>
              <MDTypography
                variant="button"
                color="text"
                onClick={() => window.open(row?.original?.file_url)}
              >
                <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  {/* {row.values.name} */}
                  {value}
                </MDTypography>
              </MDTypography>
            </MDBox>
          );

          return status;
        },
      },
      // { Header: "file link", accessor: "file_url", headerAlign: "left", width: "30%" },
      {
        Header: "status",
        accessor: "status",
        headerAlign: "left",
        width: "15%",
        Cell: ({ value }) => {
          let status;

          if (value === "paid") {
            status = <StatusCell icon="done" color="success" status="Paid" disabled />;
          } else if (value === "refunded") {
            status = <StatusCell icon="replay" color="dark" status="Refunded" disabled />;
          } else {
            status = <StatusCell icon="close" color="error" status="Unpaid" disabled />;
          }

          return status;
        },
      },
    ],
    rows: transactionList || [],
  };

  const processRows = (data = []) => {
    const transList = [];

    data.map((transaction) =>
      transList.push({
        ...transaction,
        transaction_date: formatDate(transaction?.date, "ll") || "----/--/--",
        file_name: transaction.file_name,
        file_url: transaction.file_url,
      })
    );

    return transList;
  };
  const proccessResponse = (response) => {
    if (response.status === RESPONSE_SUCCESS) {
      setTransactionList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(
      await httpRequest("customer-transaction-list", null, {
        searchKeyword: val,
        entriesPerPage,
        uuid,
      })
    );
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);

    proccessResponse(
      await httpRequest("customer-transaction-list", null, {
        searchKeyword,
        entriesPerPage: val,
        uuid,
      })
    );
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  // const getTransactionList = async () => {
  //   setIsLoading(true);

  //   const response = await await httpRequest("customer-transaction-list", null, {
  //     searchKeyword,
  //     entriesPerPage,
  //     uuid,
  //   });

  //   if (response.status === RESPONSE_SUCCESS || response.status === 201) {
  //     setTransactionList(processRows(response?.data?.data?.results));
  //   }

  //   setIsLoading(false);
  //   return 0;
  // };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);
    proccessResponse(
      await httpRequest("customer-transaction-list", null, {
        searchKeyword,
        entriesPerPage,
        uuid,
      })
    );
    setSearchKeyword(stateKeyword);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  // useEffect(() => {
  //   getTransactionList();
  // }, []);

  return (
    <MDBox>
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Transaction History
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      {/* <DataTable
        table={dataTableData}
        entriesPerPage={false}
        showTotalEntries={false}
        isSorted={false}
        canSearch={false}
        hiddenCols={["file_url"]}
        isLoading={isLoading}
      /> */}

      <DataTable
        table={dataTableData}
        noEndBorder
        entriesPerPage={{
          show: true,
          defaultValue: 10,
          entries: ["5", "10", "15", "20", "25"],
        }}
        // details={showCustomerDetail}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSearch={handleSearchKeyword}
        handleSort={handleSort}
        handleChangeEntries={handleChangeEntries}
        hasNextPage={nextPageLink !== null}
        hasPrevPage={prevPageLink !== null}
        totalCount={totalRows}
        canRefresh={false}
        canSearch={false}
        // title="Customer List"
        isLoading={isLoading}
      />
    </MDBox>
  );
}
TransactionList.defaultProps = {};

TransactionList.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default TransactionList;
