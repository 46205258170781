/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { Grid, Icon, Tooltip } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import DataTable from "components/Tables/DataTable";
import DataTable from "examples/Tables/DataTable";

import { useMaterialUIController } from "context";
import { Link, useLocation, useNavigate } from "react-router-dom";
import VendorMgr from "layouts/Vendors/VendorMgr";
import InventoryMgr from "layouts/Inventory/InventoryMgr";

import OrderMgr from "layouts/Order/OrderMgr";
// Material Dashboard 2 PRO React themes

// Material Dashboard 2 PRO React Dark Mode themes

const { setStorageItem, MANAGE_VENDORS } = VendorMgr;
const { INVENTORY, hasPermission } = InventoryMgr;

const { convertToDecimal, convertToCurrency } = OrderMgr;

function RentItems({ data }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const { items } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const showItemDetail = (values) => {
    setStorageItem("current_item_detail", values);
    navigate(INVENTORY.DETAIL_ROUTE, { state: { from: location } });
  };

  const itemRentedColumns = [
    {
      Header: "Item name",
      accessor: "item_name",
      width: "15%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const canViewInventoryDetail = hasPermission("view_inventoryitem", permissions?.inventory);
        const status = (
          <MDBox>
            {!canViewInventoryDetail && (
              // <MDBox mr={2}>
              <Tooltip title="You don't have permission to View Details" placement="top">
                <Icon>error_outlined</Icon>
              </Tooltip>
              // </MDBox>
            )}
            <MDTypography
              variant="button"
              color="text"
              onClick={() => canViewInventoryDetail && showItemDetail(row.original?.item)}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color={!canViewInventoryDetail ? "text" : "info"}
                fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                textGradient
              >
                {row.original?.item?.name || ""}
              </MDTypography>
            </MDTypography>
          </MDBox>
        );

        return status;
      },
    },
    {
      Header: "Daily Rate",
      accessor: "daily_price",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.price || 0)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    { Header: "Quantity", accessor: "quantity", headerAlign: "left", width: "10%" },
    {
      Header: "Discount Rate",
      accessor: "discount_rate",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {Number(convertToDecimal(row?.original?.discount_rate)).toFixed(0)} %
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "Discounted price",
      accessor: "discounted_price",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.discounted_price || 0)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "Bill days",
      accessor: "bill_days",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {row?.original?.bill_days}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "Each",
      accessor: "each_amount",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.each_amount || 0)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    {
      Header: "Price",
      accessor: "total_price",
      width: "10%",
      headerAlign: "left",
      Cell: ({ row }) => {
        const status = (
          <MDBox>
            <MDBox
              // display="flex"
              direction="column"
              justifyContent="flex-start"
              width="5rem"
            >
              <MDTypography variant="button" fontWeight="regular">
                {convertToCurrency(row?.original?.total_price || 0)}
              </MDTypography>
            </MDBox>
          </MDBox>
        );
        return status;
      },
    },
    { Header: "comment", accessor: "comments", headerAlign: "left", width: "20%" },
  ];

  const dataTableData = {
    columns: itemRentedColumns,
    rows: items || [],
  };

  // useEffect(() => {
  //   console.log(data);
  //   console.log(data?.items);
  //   console.log(items);
  // }, []);

  return (
    <MDBox>
      <MDBox>
        <MDBox pl={2} display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" color="info">
              Items to be rented
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox component="form" px={4}>
          <Grid container spacing={1}>
            <DataTable
              height="20rem"
              table={dataTableData}
              entriesPerPage={false}
              // showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              hiddenCols={["file_url"]}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

RentItems.defaultProps = {
  data: [{}],
};

RentItems.propTypes = {
  data: PropTypes.arrayOf(PropTypes.any),
};

export default RentItems;
