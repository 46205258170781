const form = {
    formId: "new-customer-form",
    formField: {
        firstName: {
            name: "first_name",
            label: "First Name *",
            type: "text",
            errorMsg: "First name is required.",
        },
        lastName: {
            name: "last_name",
            label: "Last Name *",
            type: "text",
            errorMsg: "Last name is required.",
        },
        company: {
            name: "company_name",
            label: "Company",
            type: "text",
            errorMsg: "Company Name is required, check Individual if not applicable",
        },
        defaultDiscount: {
            name: "default_discount",
            label: "Default Discount (%)",
            type: "number",
            errorMsg: "Invalid default discount, must be numeric.",
        },
        email: {
            name: "email",
            label: "Email Address *",
            type: "email",
            errorMsg: "Email address is required.",
            invalidMsg: "Your email address is invalid",
        },
        role: {
            name: "role",
            label: "Role",
            type: "text",
        },
        paymentType: {
            name: "payment_type",
            label: "Payment Status *",
            type: "text",
        },
        classification: {
            name: "classification",
            label: "Classification *",
            type: "select",
            errorMsg: "Classification is required.",
        },
        notes: {
            name: "notes",
            label: "Notes",
            type: "text",
        },
        isIndividual: {
            name: "individual",
            label: "N/A",
        },

        taxExempt: {
            name: "tax_exempt",
            label: "N/A",
        },

        line1: {
            name: "line_1",
            label: "Line 1",
            type: "text",
            errorMsg: "Address is required.",
        },
        line2: {
            name: "line_2",
            label: "Line 2",
            type: "text",
        },
        city: {
            name: "city",
            label: "City",
            type: "text",
            errorMsg: "City is required.",
        },
        state: {
            name: "state",
            label: "State",
            type: "text",
            errorMsg: "State is required.",
        },
        country: {
            name: "country",
            label: "Country",
            type: "text",
            errorMsg: "Country is required.",
        },
        zip: {
            name: "zip_code",
            label: "Zip",
            type: "text",
            errorMsg: "Zip is required.",
            invalidMsg: "Your Zip number is invalid",
        },

        mobile: {
            name: "mobile",
            label: "Mobile",
            type: "tel",
            errorMsg: "Mobile number is required.",
            invalidMsg: "Your Mobile number is invalid",
        },
        telephone: {
            name: "telephone",
            label: "Telephone ",
            type: "tel",
            errorMsg: "Telephone number is required.",
            invalidMsg: "Your Telephone number is invalid",
        },
        fax: {
            name: "fax",
            label: "Fax",
            type: "text",
            errorMsg: "Fax number is required.",
            invalidMsg: "Your Fax number is invalid",
        },
        other: {
            name: "other",
            label: "Other",
            type: "text",
            errorMsg: "Other number is required.",
            invalidMsg: "Your Other number is invalid",
        },
    },
};

export default form;
