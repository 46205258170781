const form = {
  formId: "new-sub-category-form",
  formField: {
    subcategoryName: {
      name: "name",
      label: "Sub Category Name *",
      type: "text",
      errorMsg: "Sub Category name is required.",
    },
  },
};

export default form;
