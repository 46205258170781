/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Menu,
  TextField,
  Tooltip,
} from "@mui/material";
import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

import ReportsMgr from "layouts/Reports/ReportsMgr";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import WarehouseMgr from "../WarehouseMgr";

const {
  httpRequest,
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getResponseMsg,
  hasPermission,
  getStorageItem,
  WAREHOUSE_QUALITY_CHECK,
  formatDate,
} = WarehouseMgr;

function QualityCheckSearch() {
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const navigate = useNavigate();
  const location = useLocation();

  const orderStatusOption = [
    { name: "All", value: "all" },
    { name: "Awaiting QC", value: "awaiting_qc" },
    { name: "In Repair", value: "in_repair" },
  ];

  const changeOrderStatusOption = [
    { name: "Awaiting QC", value: "awaiting_qc" },
    { name: "Retired", value: "retired" },
    { name: "Ready to Rent", value: "available" },
  ];

  const [itemList, setItemList] = useState([]);
  const [nextPageLink, setNextPageLink] = useState(null);
  const [prevPageLink, setPrevPageLink] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
  const [entriesPerPage, setEntriesPerPage] = useState(25);
  const [sortType, setSortType] = useState("asc");
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [menu, setMenu] = useState(null);
  const [orderStatus, setOrderStatus] = useState(
    location.state?.orderStatus || orderStatusOption[1]
  );
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [successMsg, setSuccessMsg] = useState("Successfully Updated Item Status.");
  const [errMsg, setErrMsg] = useState("Failed to Update Item Status");
  const [notifTitle, setNotifTitle] = useState("Status Updated");

  const [subitemDetails, setSubitemDetails] = useState({});
  const [changeOrderStatus, setChangeOrderStatus] = useState(changeOrderStatusOption[0]);
  const [changeStatusModal, setChangeStatusModal] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openChangeStatusModal = () => setChangeStatusModal(true);
  const closeChangeStatusModal = () => setChangeStatusModal(false);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const loadList = async (searchKeywordVal = "", entriesPerPageVal = 25) =>
    // console.log(statusFilterList);
    httpRequest(
      "warehouse-qc-list",
      {},
      {
        searchKeyword: searchKeywordVal,
        entriesPerPage: entriesPerPageVal,
        status: orderStatus?.value,
      }
    );

  const showItemDetail = (values) => {
    // console.log();
    if (values?.status?.value === "awaiting_qc") {
      setStorageItem("current_qc_detail", values);
      navigate(WAREHOUSE_QUALITY_CHECK.DETAIL_ROUTE, {
        state: { from: location, searchKeyword, orderStatus },
        // replace: true
      });
    } else {
      // console.log(values);
      setSubitemDetails(values);
      openChangeStatusModal();
    }
  };

  const processRows = (data = []) => {
    const items = [];

    data.map((item) => {
      items.push({ ...item });
      return true;
    });

    return items;
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setItemList(processRows(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
      setPrevPageLink(response.data.data?.previous);
      setTotalRows(response.data.data?.count);
    }
  };

  const handleNextPage = async () => {
    setIsLoading(true);
    proccessResponse(await getNextPage(nextPageLink));
    setIsLoading(false);
  };

  const handlePrevPage = async () => {
    setIsLoading(true);
    proccessResponse(await getPrevPage(prevPageLink));
    setIsLoading(false);
  };

  const handleSearchKeyword = async (val) => {
    setSearchKeyword(val);
    setIsLoading(true);
    proccessResponse(await loadList(val, entriesPerPage));
    setIsLoading(false);
  };

  const handleSort = async (id) => {
    setIsLoading(true);

    proccessResponse(await getSort(sortType, id, searchKeyword, entriesPerPage));

    if (sortType === "asc") {
      setSortType("desc");
    } else {
      setSortType("asc");
    }
    setIsLoading(false);
  };

  const handleChangeEntries = async (val) => {
    setEntriesPerPage(val);
    setIsLoading(true);
    proccessResponse(await loadList(searchKeyword, val));
    setIsLoading(false);
  };

  const handleSearchBarcodePopUpDetail = async (val) => {
    // setSearchKeyword(val);
    setIsLoading(true);
    const result = await loadList(val, 1);

    if (getResponseMsg(result) === "Success" && result.data.data.count > 0) {
      showItemDetail(result?.data?.data?.results[0]);
    }

    // processRows(response?.data?.data?.results);
    setIsLoading(false);
  };

  const dataTableData = {
    columns: [
      {
        Header: "Barcode ID",
        accessor: "barcode_id",
        width: "15%",
        hasSort: false,
        align: "left",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewOrderDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox>
              {!canViewOrderDetail && (
                // <MDBox mr={2}>
                <Tooltip title="You don't have permission to View Orders" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
                // </MDBox>
              )}
              {value ? (
                <MDTypography
                  ml={!canViewOrderDetail ? 1 : 0}
                  variant="button"
                  color="text"
                  onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={!canViewOrderDetail ? "text" : "info"}
                    fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                    textGradient
                  >
                    {value}
                  </MDTypography>
                </MDTypography>
              ) : (
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                  textGradient
                  sx={{ fontStyle: "italic" }}
                >
                  No Barcode ID
                </MDTypography>
              )}
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Serial Number",
        accessor: "serial_number",
        width: "15%",
        hasSort: false,
        align: "left",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewOrderDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox>
              {!canViewOrderDetail && (
                // <MDBox mr={2}>
                <Tooltip title="You don't have permission to View Orders" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
                // </MDBox>
              )}
              {value ? (
                <MDTypography
                  ml={!canViewOrderDetail ? 1 : 0}
                  variant="button"
                  color="text"
                  onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={!canViewOrderDetail ? "text" : "info"}
                    fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                    textGradient
                  >
                    {value}
                  </MDTypography>
                </MDTypography>
              ) : (
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                  textGradient
                  sx={{ fontStyle: "italic" }}
                >
                  No Serial Number
                </MDTypography>
              )}
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "name",
        accessor: "name",
        width: "15%",
        hasSort: false,
        align: "left",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewOrderDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox>
              {!canViewOrderDetail && (
                // <MDBox mr={2}>
                <Tooltip title="You don't have permission to View Orders" placement="top">
                  <Icon>error_outlined</Icon>
                </Tooltip>
                // </MDBox>
              )}

              {value ? (
                <MDTypography
                  ml={!canViewOrderDetail ? 1 : 0}
                  variant="button"
                  color="text"
                  onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={!canViewOrderDetail ? "text" : "info"}
                    fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                    textGradient
                  >
                    {value}
                  </MDTypography>
                </MDTypography>
              ) : (
                <MDTypography
                  variant="caption"
                  color="text"
                  fontWeight="regular"
                  textGradient
                  sx={{ fontStyle: "italic" }}
                >
                  No Item Name
                </MDTypography>
              )}
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "warehouse",
        accessor: "warehouse",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {value}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Is owned",
        accessor: "is_owned",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox display="flex" justifyContent="space-between">
              <IconButton size="small" aria-label="edit" disabled>
                <Icon color={value ? "success" : "error"} fontSize="medium">
                  square
                </Icon>
              </IconButton>
              <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                {value ? "Owned " : "Not Owned"}
              </MDTypography>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {value?.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList || [],
  };

  const dataTableDataMobile = {
    columns: [
      {
        Header: "Item Info",
        accessor: "barcode_id",
        width: "20%",
        hasSort: false,
        align: "left",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewOrderDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox width="10rem">
              <Grid container spacing={0}>
                <Grid container item>
                  {!canViewOrderDetail && (
                    // <MDBox mr={2}>
                    <Tooltip title="You don't have permission to View Orders" placement="top">
                      <Icon>error_outlined</Icon>
                    </Tooltip>
                    // </MDBox>
                  )}
                  {value ? (
                    <MDTypography
                      ml={!canViewOrderDetail ? 1 : 0}
                      variant="button"
                      color="text"
                      onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                    >
                      <MDTypography
                        component={Link}
                        to="#"
                        variant="button"
                        color={!canViewOrderDetail ? "text" : "info"}
                        fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                        textGradient
                      >
                        {value}
                      </MDTypography>
                    </MDTypography>
                  ) : (
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                      textGradient
                      sx={{ fontStyle: "italic" }}
                    >
                      No Barcode ID
                    </MDTypography>
                  )}
                </Grid>
                <Grid container item>
                  {!canViewOrderDetail && (
                    // <MDBox mr={2}>
                    <Tooltip title="You don't have permission to View Orders" placement="top">
                      <Icon>error_outlined</Icon>
                    </Tooltip>
                    // </MDBox>
                  )}
                  {row?.original?.serial_number ? (
                    <MDTypography
                      ml={!canViewOrderDetail ? 1 : 0}
                      variant="button"
                      color="text"
                      onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                    >
                      <MDTypography
                        component={Link}
                        to="#"
                        variant="button"
                        color={!canViewOrderDetail ? "text" : "info"}
                        fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                        textGradient
                      >
                        {row?.original?.serial_number}
                      </MDTypography>
                    </MDTypography>
                  ) : (
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                      textGradient
                      sx={{ fontStyle: "italic" }}
                    >
                      No Serial Number
                    </MDTypography>
                  )}
                </Grid>
                <Grid container item>
                  {!canViewOrderDetail && (
                    // <MDBox mr={2}>
                    <Tooltip title="You don't have permission to View Orders" placement="top">
                      <Icon>error_outlined</Icon>
                    </Tooltip>
                    // </MDBox>
                  )}
                  {row?.original?.name ? (
                    <MDTypography
                      ml={!canViewOrderDetail ? 1 : 0}
                      variant="button"
                      color="text"
                      onClick={() => canViewOrderDetail && showItemDetail(row?.original)}
                    >
                      <MDTypography
                        component={Link}
                        to="#"
                        variant="button"
                        color={!canViewOrderDetail ? "text" : "info"}
                        fontWeight={!canViewOrderDetail ? "regular" : "medium"}
                        textGradient
                      >
                        {row?.original?.name}
                      </MDTypography>
                    </MDTypography>
                  ) : (
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                      textGradient
                      sx={{ fontStyle: "italic" }}
                    >
                      No Barcode ID
                    </MDTypography>
                  )}
                </Grid>

                <Grid container item>
                  <MDTypography variant="caption" fontWeight="regular">
                    {row?.original?.warehouse}
                  </MDTypography>
                </Grid>

                {/* <Grid container item>
                  <MDBox display="flex" justifyContent="space-between">
                    <IconButton size="small" aria-label="edit" disabled>
                      <Icon color={row?.original?.is_owned ? "success" : "error"} fontSize="medium">
                        square
                      </Icon>
                    </IconButton>
                    <MDTypography variant="button" fontWeight="regular" color="text" mt={1}>
                      {row?.original?.is_owned ? "Owned " : "Not Owned"}
                    </MDTypography>
                  </MDBox>
                </Grid> */}
              </Grid>
            </MDBox>
          );

          return status;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="caption" fontWeight="regular">
                  {value?.name}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: itemList || [],
  };
  const handleFilter = async (action) => {
    setIsLoading(true);
    let response = {};
    switch (action) {
      case "apply-filter":
        proccessResponse(await loadList(searchKeyword, entriesPerPage));
        break;

      case "remove-filter":
        setOrderStatus(orderStatusOption[1]);

        response = await httpRequest(
          "warehouse-qc-list",
          {},
          {
            searchKeyword,
            entriesPerPage,
            status: orderStatusOption[1]?.value,
          }
        );
        proccessResponse(response);

        break;
      default:
        break;
    }

    closeMenu();
    setIsLoading(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MDBox sx={{ width: "100%", height: "100%", lineHeight: 1, p: 1 }}>
        <Grid item xs={12} md={12}>
          <Autocomplete
            value={orderStatus}
            options={orderStatusOption}
            isOptionEqualToValue={(option, value) =>
              value !== "" ? option.name === value.name : option.name
            }
            getOptionLabel={(option) => (option ? option.name : "")}
            renderInput={(params) => <TextField {...params} label="Status" />}
            sx={{ width: 300, height: "100%", lineHeight: 1, m: 1 }}
            onChange={(event, newValue) => {
              if (newValue !== null) {
                setOrderStatus(newValue);
              }
            }}
          />
        </Grid>
      </MDBox>

      <Divider sx={{
        margin: "0.5rem 0",
        opacity: "0.6"
      }} />
      <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
        <MDBox mx={1}>
          <MDButton
            variant="gradient"
            size="medium"
            color="error"
            onClick={() => handleFilter("remove-filter")}
          >
            Reset Filter
          </MDButton>
        </MDBox>
        <MDBox mx={1}>
          <MDButton
            variant="gradient"
            size="medium"
            color="info"
            onClick={() => handleFilter("apply-filter")}
          >
            Apply Filter
          </MDButton>
        </MDBox>
      </MDBox>
    </Menu>
  );

  const handeChangeStatus = async () => {
    setNotifTitle("Status Change");
    document.body.style.cursor = "wait";
    // console.log(changeOrderStatus);
    const body = {
      status: changeOrderStatus?.value,
      //  action: "remove",
    };
    const response = await httpRequest("change-status-inrepair-items", body, {
      uuid: subitemDetails?.uuid,
    });

    // console.log(response);
    if (getResponseMsg(response) === "Success") {
      setSuccessMsg("Updated Item Status Successfully.");
      openSuccessSB(true);
      proccessResponse(await loadList(searchKeyword, entriesPerPage));
      closeChangeStatusModal();
    } else {
      const responseErrMsg = "Failed to Update item Status.";
      // Object.entries(response.data).map((item) => {
      //   responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
      //   return 0;
      // });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    }
    document.body.style.cursor = "default";
  };

  const controllerRequest = new AbortController();

  useEffect(async () => {
    setIsLoading(true);
    const qcListResponse = await loadList(searchKeyword, entriesPerPage);
    proccessResponse(qcListResponse);
    setIsLoading(false);

    return () => {
      controllerRequest.abort();
    };
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* {isLoading && <SimpleBackdrop />} */}
      <MDBox mb={2}>
        <MDBox display="flex" justifyContent="flex-end" alignItems="flex-end" mb={2}>
          <MDButton
            variant={menu ? "contained" : "outlined"}
            color={darkMode === true ? "white" : "dark"}
            onClick={openMenu}
          >
            filters&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </MDBox>
        <Card>
          <DataTable
            table={!isMobile ? dataTableData : dataTableDataMobile}
            noEndBorder
            details={showItemDetail}
            handleNextPage={handleNextPage}
            handlePrevPage={handlePrevPage}
            handleSearch={handleSearchKeyword}
            popupDetailScan={handleSearchBarcodePopUpDetail}
            handleChangeEntries={handleChangeEntries}
            handleSort={handleSort}
            hasNextPage={nextPageLink !== null}
            hasPrevPage={prevPageLink !== null}
            totalCount={totalRows}
            keyPressSearch
            canScanQr
            isMobile={isMobile}
            canRefresh
            entriesPerPage={{
              show: !isMobile,
              defaultValue: 25,
              entries: [10, 25, 50, 100],
            }}
            title="Quality-Check"
            isLoading={isLoading}
          />
        </Card>
      </MDBox>
      {changeStatusModal && (
        // <div>
        <Dialog open={changeStatusModal} onClose={closeChangeStatusModal}>
          <DialogTitle>Change Item Status</DialogTitle>
          <DialogContent>
            <DialogContentText>Select Item status</DialogContentText>
            <Autocomplete
              value={changeOrderStatus}
              options={changeOrderStatusOption}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              renderInput={(params) => <TextField {...params} />}
              sx={{ width: 300, height: "100%", lineHeight: 1 }}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setChangeOrderStatus(newValue);
                }
              }}
            />
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={closeChangeStatusModal}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handeChangeStatus()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}
      <MDSnackbar
        color="error"
        icon="warning"
        title={notifTitle}
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={notifTitle}
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

QualityCheckSearch.defaultProps = {};

QualityCheckSearch.propTypes = {};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(QualityCheckSearch);
