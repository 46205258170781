/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomerMgr from "../CustomerMgr";
import CardContainer from "components/Custom/CardContainer";
import ContactsTable from "./components/ContactsTable";

const { convertToCurrency, convertTimeZoneFormatDateTime } = CustomerMgr;

function BasicInfo({ data }) {
    const userLoggedin = useSelector((state) => state?.authentication?.userDetail);

    const {
        identifier,
        company_name,
        classification,
        default_discount,
        last_completed_contract,
        unpaid_balance,
        notes,
        tax_exempt,
        address,
        contacts,
    } = data;

    const basicInfoDetails = [
        { label: "Identifier", value: identifier },
        { label: "Customer Type", value: classification?.name },
        { label: "Default Discount", value: `${(default_discount || 0) * 100} %` },
        { label: "Tax Exempt", value: tax_exempt ? "Yes" : "No" },
        { label: "Unpaid Balance", value: convertToCurrency(unpaid_balance || 0) },
        { label: "Notes", value: notes },
    ];

    const addressDetails = [
        { label: "Line 1", value: address?.line_1 },
        { label: "Line 2", value: address?.line_2 },
        { label: "Country", value: address?.country?.name },
        { label: "City", value: address?.city },
        { label: "State", value: address?.state },
        { label: "Zip", value: address?.zip_code },
    ];

    // useEffect(() => {
    //   document.body.style.cursor = "wait";
    //   // if (newQcSheet) {
    //   //   setTechnicianValue(`${userLoggedin?.first_name} ${userLoggedin?.last_name}`);
    //   // }
    //   setUserTimeZone(userLoggedin?.location?.timezone);

    //   document.body.style.cursor = "default";
    // }, [userLoggedin]);

    return (
        <MDBox display="flex" flexDirection="column" gap={2}>
            {/* Basic Info card */}
            <CardContainer>
                <MDBox display="flex" justifyContent="space-between">
                    <MDBox mb={3} display="flex" alignItems="center" gap={1}>
                        <MDTypography variant="h5" fontWeight="medium" color="info">
                            {company_name === "N/A" ? "Individual" : `${company_name || ""}`}
                        </MDTypography>

                        <MDTypography variant="body2">(Company Name)</MDTypography>
                    </MDBox>

                    <MDBox mt={0.5} lineHeight={1}>
                        <MDTypography
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                        >
                            Last completed contract: &nbsp;
                        </MDTypography>

                        <MDTypography variant="button" fontWeight="regular" color="text">
                            &nbsp;
                            {last_completed_contract && userLoggedin
                                ? convertTimeZoneFormatDateTime(
                                      last_completed_contract,
                                      userLoggedin?.location?.timezone?.name,
                                      userLoggedin?.location?.timezone?.abbreviation,
                                      "LLL"
                                  )
                                : "----/--/--"}
                        </MDTypography>
                    </MDBox>
                </MDBox>

                <Grid container spacing={{ xs: 1, md: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {/* Basic info */}
                    <Grid item xs={12} sm={4} md={4}>
                        <MDBox pb={1} px={2}>
                            <MDTypography variant="h6" color="info">
                                Basic Info
                            </MDTypography>
                            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                                {basicInfoDetails.map((basicInfo) => (
                                    <Grid item xs={12} sm={12} md={12} key={basicInfo.label}>
                                        <MDBox display="flex">
                                            <Grid container>
                                                <Grid item xs={5} sm={6} md={6}>
                                                    <MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                        >
                                                            {basicInfo.label}: &nbsp;
                                                        </MDTypography>
                                                    </MDBox>
                                                </Grid>

                                                <Grid item xs={6} sm={6} md={6}>
                                                    <MDBox textAlign="left">
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="regular"
                                                            color="text"
                                                        >
                                                            &nbsp;{basicInfo.value}
                                                        </MDTypography>
                                                    </MDBox>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                ))}
                            </Grid>
                        </MDBox>
                    </Grid>

                    {/* Address */}
                    <Grid item xs={12} sm={4} md={4}>
                        <MDBox pb={1} px={2}>
                            <MDTypography variant="h6" color="info">
                                Address
                            </MDTypography>
                            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                                {addressDetails.map((addressItem) => (
                                    <Grid item xs={12} sm={12} md={12} key={addressItem.label}>
                                        <MDBox display="flex">
                                            <Grid container>
                                                <Grid item xs={3} sm={3} md={3}>
                                                    <MDBox>
                                                        <MDTypography
                                                            variant="button"
                                                            fontWeight="bold"
                                                            textTransform="capitalize"
                                                        >
                                                            {addressItem?.label}: &nbsp;
                                                        </MDTypography>
                                                    </MDBox>
                                                </Grid>

                                                <Grid item xs={9} sm={9} md={9}>
                                                    <MDTypography
                                                        variant="button"
                                                        fontWeight="regular"
                                                        color="text"
                                                    >
                                                        &nbsp;{addressItem?.value || ""}
                                                    </MDTypography>
                                                </Grid>
                                            </Grid>
                                        </MDBox>
                                    </Grid>
                                ))}
                            </Grid>
                        </MDBox>
                    </Grid>
                </Grid>
            </CardContainer>

            <CardContainer>
                <MDTypography mb={2} variant="h5" fontWeight="medium">
                    Contacts
                </MDTypography>

                <ContactsTable contacts={contacts} />
            </CardContainer>
        </MDBox>
    );
}

BasicInfo.defaultProps = {
    data: {},
    address: {},
    contact: {},
};

BasicInfo.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    address: PropTypes.objectOf(PropTypes.any),
    contact: PropTypes.objectOf(PropTypes.any),
};

export default BasicInfo;
