/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Material Dashboard 2 PRO React components
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import OrderMgr from "layouts/Order/OrderMgr";
import { Link, useLocation, useNavigate } from "react-router-dom";

const { setStorageItem, CUSTOMERS, getStorageItem } = OrderMgr;

function Certificates({ data, certificateData }) {
  const {
    insurance,
    rental_agreement,
    reference,
    // notes,
  } = certificateData;

  const navigate = useNavigate();
  const location = useLocation();

  const showCustomerDetail = () => {
    setStorageItem("current_customer_detail", data.customer);

    navigate(CUSTOMERS.DETAIL_ROUTE, {
      state: { from: location, uuid: data.customer.uuid },
      replace: true,
    });
  };

  // useEffect(async () => {
  //   const documentResponse = await httpRequest("customer-documents", null, {
  //     uuid: data.customer.uuid,
  //   });
  //   debugger;
  //   if (getResponseMsg(documentResponse) === "Success") {
  //     setInsuranceStatus(documentResponse.data.data.insurance);
  //     setRentalAgreement(documentResponse.data.data.rental_agreement);
  //     setReferenceInfo(documentResponse.data.data.reference);
  //   }
  //   // console.log(documentResponse);
  // }, []);

  return (
    <MDBox pb={1} pl={2}>
      <MDBox>
        <MDTypography variant="h6" color="info">
          Certificates
        </MDTypography>
      </MDBox>
      <MDBox>
        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={2} sm={4} md={4}>
            <MDBox display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Insurance Status: &nbsp;
              </MDTypography>
              <MDTypography
                variant="button"
                color="text"
                onClick={() => {
                  showCustomerDetail();
                }}
              >
                {insurance?.status?.value !== "warning" ? (
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={
                      insurance?.is_waived === true ||
                      (insurance?.status?.name !== "Bad" && insurance?.status?.name !== "")
                        ? "success"
                        : "error"
                    }
                    fontWeight="medium"
                    textGradient
                  >
                    &nbsp;{insurance?.status?.name || "No File"}
                  </MDTypography>
                ) : (
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color="warning"
                    fontWeight="medium"
                    textGradient
                  >
                    &nbsp;{insurance?.status?.name || "No File"}
                  </MDTypography>
                )}
                {/* <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color={
                    insurance?.is_waived === true || insurance?.status?.name ? "success" : "error"
                  }
                  fontWeight="medium"
                  textGradient
                >
                  &nbsp;
                  {insurance?.is_waived === true ? "Waived" : insurance?.status?.name || "No File"}
                </MDTypography> */}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={2} sm={4} md={4}>
            <MDBox display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Rental Agreement: &nbsp;
              </MDTypography>
              <MDTypography
                variant="button"
                color="text"
                onClick={() => {
                  showCustomerDetail();
                }}
              >
                {rental_agreement?.status?.value !== "warning" ? (
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={
                      rental_agreement?.status?.name !== "Bad" &&
                      rental_agreement?.status?.name !== ""
                        ? "success"
                        : "error"
                    }
                    fontWeight="medium"
                    textGradient
                  >
                    &nbsp;{rental_agreement?.status?.name || "No File"}
                  </MDTypography>
                ) : (
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color="warning"
                    fontWeight="medium"
                    textGradient
                  >
                    &nbsp;{rental_agreement?.status?.name || "No File"}
                  </MDTypography>
                )}
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={4} sm={4} md={4}>
            <MDBox display="flex" py={1} pr={2}>
              <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                Reference Info: &nbsp;
              </MDTypography>
              <MDTypography
                variant="button"
                color="text"
                onClick={() => {
                  showCustomerDetail();
                }}
              >
                {reference?.status?.value !== "warning" ? (
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={reference?.status?.name ? "success" : "error"}
                    fontWeight="medium"
                    textGradient
                  >
                    &nbsp;{reference?.status?.name || "No File"}
                  </MDTypography>
                ) : (
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color="warning"
                    fontWeight="medium"
                    textGradient
                  >
                    &nbsp;{reference?.status?.name || "No File"}
                  </MDTypography>
                )}
                {/* <MDTypography
                  component={Link}
                  to="#"
                  variant="button"
                  color={reference?.status?.name ? "success" : "error"}
                  fontWeight="medium"
                  textGradient
                >
                  &nbsp;{reference?.status?.name || "No File"}
                </MDTypography> */}
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

Certificates.defaultProps = {
  data: {},
  certificateData: {},
};

Certificates.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  certificateData: PropTypes.objectOf(PropTypes.any),
};

export default Certificates;
