/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import { Autocomplete, Divider, Icon, IconButton, Menu, TextField, Tooltip } from "@mui/material";

import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDTypography from "components/MDTypography";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";

import UsersMgr from "./UsersMgr";

const {
    getNextPage,
    getPrevPage,
    getSort,
    setStorageItem,
    httpRequest,
    MANAGE_USERS,
    getResponseMsg,
    _DASHBOARD,
    hasPermission,
} = UsersMgr;

function UserSearch({ userDetail }) {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const navigate = useNavigate();
    const location = useLocation();

    const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);
    const [userList, setUserList] = useState([]);
    const [nextPageLink, setNextPageLink] = useState("");
    const [prevPageLink, setPrevPageLink] = useState("");
    const [searchKeyword, setSearchKeyword] = useState(location.state?.searchKeyword || "");
    // const [prevSortId, setPrevSortId] = useState("");
    const [sortType, setSortType] = useState("asc");
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [successMsg, setSuccessMsg] = useState("Successfully Deactivated User!");
    const [successSB, setSuccessSB] = useState(false);
    const [errMsg, setErrMsg] = useState("Failed to Deactivate User.");
    const [errorSB, setErrorSB] = useState(false);
    const [userToBeDeactivated, setUserToBeDeactivated] = useState({});
    const [canEditUser, setCanEditUser] = useState(false);
    const [userData, setUserData] = useState([]);

    const [menu, setMenu] = useState(null);
    const [userFilterValue, setUserFilterValue] = useState(null);
    const [userStatusFilterValue, setUserStatusFilterValue] = useState({
        name: "Active only",
        value: "active",
    });

    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const handleOpenDeactivateDialog = () => setShowDeactivateDialog(true);
    const handleCloseDeactiVateDialog = () => setShowDeactivateDialog(false);

    const openMenu = (event) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const controllerRequest = new AbortController();

    const processRows = (data = []) => {
        const list = [];

        data.map((item) => {
            list.push({ ...item });

            return 0;
        });

        return list;
    };

    const processResponse = (response) => {
        setUserList(processRows(response?.data?.data?.results));
        setNextPageLink(response.data.data?.next);
        setPrevPageLink(response.data.data?.previous);
        setTotalRows(response.data.data?.count);
    };

    useEffect(async () => {
        setIsLoading(true);
        permissions?.customers.map((perm) => {
            if (perm?.value === 54) {
                setCanEditUser(true);
            }
            return 0;
        });

        const response = await httpRequest("users-list", null, {
            searchKeyword,
            entriesPerPage,
            userStatus: "active",
        });
        if (getResponseMsg(response) === "Success") {
            processResponse(response);
        }

        setIsLoading(false);
        return () => {
            controllerRequest.abort();
            setUserList([]);
        };
    }, []);

    // const loadDetails = async (data) => {
    //   const responseDetails = await httpRequest("users-detail", null, {
    //     uuid: data?.uuid,
    //   });

    //   if (getResponseMsg(responseDetails) === "Success") {
    //     return responseDetails.data.data;
    //   }

    //   return 0;
    // };

    const editUser = async (data = {}) => {
        setIsLoading(true);
        let item = [];
        const responseDetails = await httpRequest("users-detail", null, {
            uuid: data?.uuid,
        });

        if (getResponseMsg(responseDetails) === "Success") {
            item = {
                ...responseDetails.data.data,
                // uuid: userData.uuid,
                // first_name: userData.first_name || "",
                // last_name: userData.last_name || "",
                // email: userData.email,
                // company_name: userData.company_name || "",
                // isIndividual: userData.company_name === "N/A",
                // vendor_type: userData.vendor_type,
                // percentage: userData.percentage * 100,

                // mobile: userData.contact.mobile,
                // telephone: userData.contact.telephone,
                // fax: userData.contact.fax,
                // other: userData.contact.other,

                // line_1: userData.address.line_1,
                // line_2: userData.address.line_2,
                // city: userData.address.city,
                // state: userData.address.state,
                // country: userData.address.country,
                // zip_code: userData.address.zip_code,
            };
        }
        const temp = { ...item, pageState: "EDIT" };
        setStorageItem("current_user_detail", temp);
        setIsLoading(false);
        // console.log(temp);
        navigate(MANAGE_USERS.EDIT_ROUTE, {
            state: { from: location, searchKeyword },
            //  replace: true
        });
    };

    const showUserDetail = (values) => {
        setStorageItem("current_user_detail", values);
        navigate(MANAGE_USERS.DETAIL_ROUTE, {
            state: { from: location, searchKeyword },
            // replace: true,
        });
    };

    const dataTableData = {
        columns: [
            {
                Header: "User name",
                accessor: "full_name",
                // type: "detail-link",
                headerAlign: "left",
                width: "15%",
                hasSort: true,
                Cell: ({ row }) => {
                    const status = (
                        <MDTypography
                            variant="button"
                            color="text"
                            onClick={() => showUserDetail(row.original)}
                        >
                            <MDTypography
                                component={Link}
                                to="#"
                                variant="button"
                                color={row?.original?.status === "inactive" ? "secondary" : "info"}
                                fontWeight="medium"
                                textGradient
                            >
                                {row.values.full_name}
                            </MDTypography>
                        </MDTypography>
                    );

                    return status;
                },
            },
            {
                Header: "Group",
                accessor: "group",
                headerAlign: "left",
                width: "15%",
                Cell: ({ row }) => {
                    const status = (
                        <MDTypography variant="body" color="inherit" fontSize="small">
                            {row.values.group.name}
                        </MDTypography>
                    );

                    return status;
                },
            },
            {
                Header: "location",
                accessor: "location",
                headerAlign: "left",
                width: "15%",
                Cell: ({ row }) => {
                    const status = (
                        <MDTypography variant="button" color="inherit" fontWeight="regular">
                            {row.values.location.name}
                        </MDTypography>
                    );

                    return status;
                },
            },
            {
                Header: "email",
                accessor: "email",
                headerAlign: "left",
                width: "15%",
                Cell: ({ row }) => {
                    const status = (
                        <MDTypography variant="button" color="inherit" fontWeight="regular">
                            {row.values.email}
                        </MDTypography>
                    );

                    return status;
                },
            },
            {
                Header: "action",
                accessor: "",
                headerAlign: "left",
                width: "15%",
                Cell: ({ row }) => {
                    const status = (
                        <MDBox
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <IconButton
                                size="small"
                                aria-label="edit"
                                color="info"
                                // onClick={() => console.log(row?.original)}
                                onClick={() => editUser(row?.original)}
                                // disabled={!canEditUser}
                            >
                                <Tooltip title="Edit User" placement="top">
                                    <Icon fontSize="small">edit</Icon>
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                size="small"
                                aria-label="edit"
                                color="error"
                                onClick={() => {
                                    // console.log(row?.original?.status === "inactive");
                                    setUserToBeDeactivated(row?.original);
                                    handleOpenDeactivateDialog();
                                }}
                                disabled={
                                    row?.original?.status === "inactive" ||
                                    userDetail?.uuid === row?.original?.uuid ||
                                    !hasPermission("deactivate_user", permissions?.users)
                                }
                            >
                                <Tooltip title="Deactivate User" placement="top">
                                    <Icon fontSize="small">person_off</Icon>
                                </Tooltip>
                            </IconButton>
                        </MDBox>
                    );
                    return status;
                },
            },
            // { Header: "last completed contract", accessor: "last_completed_contract", width: "15%" },
        ],
        rows: userList || [],
    };

    const newVendor = () => {
        const temp = { ...[], pageState: "NEW" };
        setStorageItem("current_user_detail", temp);
        // console.log(temp);
        navigate(MANAGE_USERS.CREATE_ROUTE, {
            state: { from: location, searchKeyword },
            // replace: true,
        });
    };

    const handleNextPage = async () => {
        setIsLoading(true);
        processResponse(await getNextPage(nextPageLink));

        setIsLoading(false);
    };

    const handlePrevPage = async () => {
        setIsLoading(true);
        processResponse(await getPrevPage(prevPageLink));

        setIsLoading(false);
    };

    const handleSearchKeyword = async (val) => {
        setSearchKeyword(val);
        setIsLoading(true);
        processResponse(
            await httpRequest("users-list", null, {
                searchKeyword: val,
                entriesPerPage,
                // vendorType: userFilterValue?.value,
                userStatus: userStatusFilterValue?.value,
            })
        );
        setIsLoading(false);
    };

    const handleChangeEntries = async (val) => {
        setEntriesPerPage(val);
        setIsLoading(true);

        processResponse(
            await httpRequest("users-list", null, {
                searchKeyword,
                entriesPerPage: val,
                // vendorType: userFilterValue?.value,
                userStatus: userStatusFilterValue?.value,
            })
        );
        setIsLoading(false);
    };

    const handleSort = async (id) => {
        setIsLoading(true);

        processResponse(
            await getSort(
                sortType,
                id,
                searchKeyword,
                entriesPerPage
                // userFilterValue?.value,
                // userStatusFilterValue?.value
            )
        );

        if (sortType === "asc") {
            setSortType("desc");
        } else {
            setSortType("asc");
        }
        setIsLoading(false);
    };

    const userStatusOptions = [
        { name: "All status", value: "all" },
        { name: "Active only", value: "active" },
        { name: "Inactive only", value: "inactive" },
    ];

    const handleFilter = async (action) => {
        setIsLoading(true);
        switch (action) {
            case "apply-filter":
                processResponse(
                    await httpRequest(
                        "users-list",
                        {},
                        {
                            searchKeyword,
                            entriesPerPage,
                            // vendorType: userFilterValue?.value,
                            userStatus: userStatusFilterValue?.value,
                        }
                    )
                );
                break;

            case "remove-filter":
                setUserFilterValue(null);
                setUserStatusFilterValue({
                    name: "Active only",
                    value: "active",
                });
                processResponse(
                    await httpRequest(
                        "users-list",
                        {},
                        {
                            searchKeyword,
                            entriesPerPage,
                            vendorType: userFilterValue?.value,
                            userStatus: "active",
                        }
                    )
                );
                break;
            default:
                break;
        }

        closeMenu();
        setIsLoading(false);
    };

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            {/* <Autocomplete
        disablePortal
        value={userFilterValue}
        options={vendorFilterOptions}
        onChange={(event, newValue) => {
          setUserFilterValue(newValue);
        }}
        isOptionEqualToValue={(option, value) =>
          value !== "" ? option.name === value.name : option.name
        }
        getOptionLabel={(option) => (option ? option.name : "")}
        sx={{ width: 300, height: "100%", lineHeight: 4, m: 1 }}
        renderInput={(params) => <TextField {...params} label="Vendor Type" />}
      /> */}
            <Autocomplete
                disablePortal
                value={userStatusFilterValue}
                options={userStatusOptions}
                onChange={(event, newValue) => {
                    setUserStatusFilterValue(newValue);
                }}
                isOptionEqualToValue={(option, value) =>
                    value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => (option ? option.name : "")}
                sx={{ width: 300, height: "100%", lineHeight: 8, m: 1 }}
                renderInput={(params) => <TextField {...params} label="Status" />}
            />

            <Divider
                sx={{
                    margin: "0.5rem 0",
                    opacity: "0.6",
                }}
            />
            <MDBox display="flex" justifyContent="space-between" my={2} mx={2}>
                <MDButton
                    variant="gradient"
                    size="medium"
                    color="info"
                    onClick={() => handleFilter("apply-filter")}
                >
                    Apply Filter
                </MDButton>

                <MDButton
                    variant="gradient"
                    size="medium"
                    color="error"
                    onClick={() => handleFilter("remove-filter")}
                >
                    Reset Filter
                </MDButton>
            </MDBox>
        </Menu>
    );

    const handleConfirm = async (action) => {
        // console.log(action, userToBeDeactivated);
        setIsLoading(true);

        let response = {};
        switch (action) {
            case "deactivate":
                handleCloseDeactiVateDialog();
                response = await httpRequest("users-deactivate", null, {
                    uuid: userToBeDeactivated.uuid,
                });

                if (getResponseMsg(response) === "Success") {
                    setSuccessMsg("Successfully Deactivated User");
                    navigate(_DASHBOARD.HOMEPAGE, {
                        state: { from: location, searchKeyword },
                        replace: true,
                    });
                    navigate(MANAGE_USERS.LIST_ROUTE, {
                        state: { from: location, searchKeyword },
                        replace: true,
                    });
                    openSuccessSB(true);
                } else {
                    setErrMsg("Failed to fetch User Detail.");
                    openErrorSB(true);
                }

                break;
            // case "delete":
            //   handleCloseDeleteDialog();
            //   await httpRequest("customer-delete", null, { uuid: data.uuid });
            //   setMsg("Successfully Deleted User");
            //   break;
            default:
                break;
        }

        setIsLoading(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {/* {isLoading && <SimpleBackdrop />} */}
            <MDBox mb={2}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <MDButton variant="gradient" color="info" onClick={newVendor}>
                        new user
                    </MDButton>
                    <MDBox display="flex">
                        <MDButton
                            variant={menu ? "contained" : "outlined"}
                            color={darkMode ? "white" : "dark"}
                            onClick={openMenu}
                        >
                            filters&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                    </MDBox>
                </MDBox>
                <Card>
                    <DataTable
                        table={dataTableData}
                        noEndBorder
                        entriesPerPage={{ show: true }}
                        handleNextPage={handleNextPage}
                        handlePrevPage={handlePrevPage}
                        handleSearch={handleSearchKeyword}
                        handleSort={handleSort}
                        handleChangeEntries={handleChangeEntries}
                        hasNextPage={nextPageLink !== null}
                        hasPrevPage={prevPageLink !== null}
                        totalCount={totalRows}
                        title="Users List"
                        isLoading={isLoading}
                        canRefresh
                    />
                </Card>
            </MDBox>

            {showDeactivateDialog && (
                <DraggableDialog
                    title="Deactivate User"
                    description={`Are you sure you want to deactivate ${userToBeDeactivated.full_name}?`}
                    buttonDesc="Deactivate"
                    openDialog={showDeactivateDialog}
                    handleClose={handleCloseDeactiVateDialog}
                    handleConfirm={() => handleConfirm("deactivate")}
                    fullWidth={false}
                />
            )}
            <MDSnackbar
                color="error"
                icon="warning"
                title="Item Creation"
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />

            <MDSnackbar
                color="success"
                icon="check"
                title="User Deactivation"
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />

            <Footer />
        </DashboardLayout>
    );
}

UserSearch.defaultProps = {
    userDetail: {},
};

UserSearch.propTypes = {
    userDetail: PropTypes.objectOf(PropTypes.any),
};

const mapStateToProps = (state) => ({
    userDetail: state.authentication?.userDetail,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
