/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import HTTPSServices from "services/https/HTTPSServices";
import { RESPONSE_SUCCESS } from "services/context/response";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import DataTable from "components/Tables/DataTable";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";

import { CircularProgress, Icon, IconButton } from "@mui/material";
import CustomerMgr from "layouts/Customers/CustomerMgr";
import InventoryMgr from "layouts/Inventory/InventoryMgr";
import moment from "moment/moment";
import { connect } from "react-redux";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  convertTimeZoneFormatDateTime,
  setStorageItem,
  ORDERS,
} = InventoryMgr;

function RentalHitory({ onClose, userDetail, subitemData }) {
  const location = useLocation();
  const navigate = useNavigate();

  const itemDetail = JSON.parse(getStorageItem("current_item_detail"));

  const [successSB, setSuccessSB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPayInvoice, setShowPayInvoice] = useState(false);

  const [transactionList, setTransactionList] = useState([]);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenDialog = () => setShowPayInvoice(true);
  const handleCloseDialog = () => setShowPayInvoice(false);

  const showOrderDetail = (values) => {
    // console.log(values);
    setStorageItem("current_order_detail", values);
    navigate(ORDERS.DETAIL_ROUTE, { state: { from: location }, replace: true });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Order number",
        accessor: "identifier",
        width: "15%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const status = (
            <MDTypography
              variant="button"
              color="text"
              onClick={() => showOrderDetail(row?.original)}
            >
              <MDTypography
                component={Link}
                to="#"
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                {/* {row.values.name} */}
                {value}
              </MDTypography>
            </MDTypography>
          );

          return status;
        },
      },
      { Header: "Customer name", accessor: "customer_name", headerAlign: "left", width: "20%" },
      {
        Header: "Date of rental (start date)",
        accessor: "start_date",
        headerAlign: "left",
        width: "15%",
      },
      { Header: "Date returned", accessor: "return_date", headerAlign: "left", width: "15%" },
      {
        Header: "Payment status",
        accessor: "payment_status",
        width: "15%",
        headerAlign: "left",
        Cell: ({ value }) => {
          let status;

          if (value === "paid") {
            status = <StatusCell icon="done" color="success" status="Paid" disabled />;
          } else if (value === "refunded") {
            status = <StatusCell icon="replay" color="dark" status="Refunded" disabled />;
          } else {
            status = <StatusCell icon="close" color="error" status="Unpaid" disabled />;
          }

          return status;
        },
      },
    ],
    rows: transactionList || [],
  };

  const processRows = (data = []) => {
    const rentalHistory = [];

    data.map((history) =>
      rentalHistory.push({
        uuid: history?.uuid,
        identifier: history.identifier,
        customer_name: history.customer.name,
        start_date: convertTimeZoneFormatDateTime(
          history.start_date,
          userDetail?.location?.timezone?.name,
          userDetail?.location?.timezone?.abbreviation,
          "lll"
        ),
        return_date: convertTimeZoneFormatDateTime(
          history.return_date,
          userDetail?.location?.timezone?.name,
          userDetail?.location?.timezone?.abbreviation,
          "lll"
        ),
        payment_status: history?.payment_status || "Unpaid",
      })
    );

    return rentalHistory;
  };

  const getTransactionList = async () => {
    setIsLoading(true);

    const response = await httpRequest("rental-history", null, {
      uuid: itemDetail?.uuid,
      subitemUuid: subitemData?.uuid,
    });

    // debugger;
    if (getResponseMsg(response) === "Success") {
      setTransactionList(processRows(response.data.data));
    }

    setIsLoading(false);
    return 0;
  };

  useEffect(() => {
    getTransactionList();
  }, []);

  return (
    <MDBox>
      <MDBox p={1} display="flex" justifyContent="space-between">
        <MDBox>
          <MDTypography variant="h6" fontWeight="medium">
            Rental History
          </MDTypography>
        </MDBox>

        <MDBox>
          <IconButton size="small" aria-label="close" color="dark" onClick={onClose}>
            <Icon fontSize="small">close</Icon>
          </IconButton>
        </MDBox>
      </MDBox>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </MDBox>
      ) : (
        <DataTable
          table={dataTableData}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          canSearch={false}
          hiddenCols={["file_url"]}
        />
      )}
    </MDBox>
  );
}
RentalHitory.defaultProps = {
  userDetail: {},
  subitemData: {},
};

RentalHitory.propTypes = {
  onClose: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
  subitemData: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(RentalHitory);
