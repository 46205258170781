/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import Info from "layouts/admin/ManageVendors/new-user/components/Info";
import Address from "layouts/admin/ManageVendors/new-user/components/Address";
import Contact from "layouts/admin/ManageVendors/new-user/components/Contact";
import form from "layouts/admin/ManageVendors/new-user/schemas/form";
import initialValues from "layouts/admin/ManageVendors/new-user/schemas/initialValues";
import validations from "layouts/admin/ManageVendors/new-user/schemas/validations";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import VendorMgr from "./VendorMgr";

const {
  MANAGE_VENDORS,
  httpRequest,
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  validateEmail,
  RESPONSE_SUCCESS,
  getConsignerList,
  setConsignerList,
  getResponseMsg,
  getErrorMessage,
} = VendorMgr;

function VendorNew({ setConsignerReduxList }) {
  const { formId, formField } = form;
  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || MANAGE_VENDORS.LIST_ROUTE;

  const vendorDetail = JSON.parse(getStorageItem("current_vendor_detail"));

  const [errMsg, setErrMsg] = useState("Failed to Create Vendor Profile");
  const [successMsg, setSuccessMsg] = useState("Successfully Created Vendor Profile");
  const [isLoading, setLoading] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [vendorTypeError, setVendorTypeError] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const submitForm = async (values, actions) => {
    let response = null;

    if (vendorDetail?.pageState === "NEW") {
      response = await httpRequest("vendors-create", values);
      setSuccessMsg("Successfully Created Vendor Profile");
    } else if (vendorDetail?.pageState === "EDIT") {
      response = await httpRequest("vendors-update", values, { uuid: vendorDetail.uuid });
      setSuccessMsg("Successfully Updated Vendor Profile");
    }

    if (getResponseMsg(response) === "Success") {
      openSuccessSB(true);
      setTimeout(async () => {
        const consignerResponse = await getConsignerList();
        if (consignerResponse.status === RESPONSE_SUCCESS || consignerResponse.status === 201) {
          setConsignerReduxList(consignerResponse.data.data);
        }
        actions.resetForm();
        setStorageItem("current_vendor_detail", response.data.data);
        setLoading(false);
        navigate(MANAGE_VENDORS.DETAIL_ROUTE, {
          replace: true,
          state: { searchKeyword: location.state?.searchKeyword || "" },
        });
      }, 2000);
    } else {
      setErrMsg(getErrorMessage(response));
      openErrorSB(true);
      setLoading(false);
    }

    actions.setSubmitting(false);
  };

  const emailExist = async (email) => {
    const response = await validateEmail(email);
    if (response.status === RESPONSE_SUCCESS || response.status === 201) {
      return false;
    }
    return true;
  };

  const handleSave = (err) => {
    let responseErrMsg = "";
    Object.entries(err).map((item) => {
      // responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
      responseErrMsg = `${item[1]}`;
      return 0;
    });

    if (responseErrMsg !== "") {
      openErrorSB(true);
      setErrMsg(responseErrMsg);
    }
    if (err.vendor_type !== "") {
      setVendorTypeError(true);
    }
  };

  const handleSubmit = async (values, actions) => {
    setLoading(true);

    if (
      vendorDetail?.pageState === "EDIT" &&
      values?.email !== vendorDetail?.email &&
      (await emailExist(values.email)) === true
    ) {
      setErrMsg("Email Already Exist");
      openErrorSB(true);
      setLoading(false);
    } else if (vendorDetail?.pageState === "NEW" && (await emailExist(values.email)) === true) {
      setErrMsg("Email Already Exist");
      openErrorSB(true);
      setLoading(false);
    } else {
      if (values.vendor_type === null) {
        openErrorSB(true);
        setErrMsg("Vendor Type is required.");
      }

      closeErrorSB();
      const body = {
        first_name: capitalizeFirstLetter(values.first_name),
        last_name: capitalizeFirstLetter(values.last_name),
        email: values.email,
        vendor_type: values.vendor_type.value || values.vendor_type,
        company_name: values.company_name,
        percentage:
          values.vendor_type.value === "consignment" || values.vendor_type === "consignment"
            ? (values.percentage / 100).toFixed(2)
            : 0,
        contact: {
          mobile: values.mobile,
          telephone: values.telephone || "",
          fax: values.fax || "",
          other: values.other || "",
        },
        address: {
          line_1: values.line_1,
          line_2: values.line_2,
          city: values.city,
          state: values.state,
          country: values.country.value,
          zip_code: values.zip_code,
        },
      };
      // console.log(body);
      submitForm(body, actions);
    }
  };

  const handleClose = () => {
    if (vendorDetail?.pageState === "NEW") {
      navigate(from, {
        replace: true,
        state: { searchKeyword: location.state?.searchKeyword || "" },
      });
    } else if (vendorDetail?.pageState === "EDIT") {
      navigate(from, {
        state: { uuid: vendorDetail.uuid, searchKeyword: location.state?.searchKeyword || "" },
        replace: true,
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading && <SimpleBackdrop />}
      <MDBox mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDBox display="flex" justifyContent="space-between">
              <MDBox>
                <MDTypography variant="h6" fontWeight="medium" color="dark">
                  {vendorDetail?.pageState === "NEW" ? "New Vendor" : "Edit Vendor"}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" justifyContent="space-between">
                <IconButton size="small" aria-label="close" color="dark" onClick={handleClose}>
                  <Icon fontSize="small">close</Icon>
                </IconButton>
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <Formik
              initialValues={
                vendorDetail?.pageState === "NEW" ? initialValues : vendorDetail || initialValues
              }
              validationSchema={validations}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <MDBox>
                    <Card>
                      <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid item xs={4} sm={4} md={6}>
                          <Info
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                            vendorTypeError={vendorTypeError}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={6}>
                          <Address
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                        </Grid>
                        <Grid item xs={4} sm={8} md={12}>
                          <Contact
                            formData={{
                              values,
                              touched,
                              formField,
                              errors,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <MDBox display="flex" justifyContent="flex-end" mb={2} mx={2}>
                        <MDButton
                          size="small"
                          variant="gradient"
                          color="error"
                          onClick={handleClose}
                          sx={{ marginRight: 1 }}
                        >
                          Cancel
                        </MDButton>
                        <MDButton
                          disabled={isSubmitting}
                          size="small"
                          type="submit"
                          variant="gradient"
                          color="info"
                          onClick={() => handleSave(errors, values)}
                        >
                          save
                        </MDButton>
                      </MDBox>
                    </Card>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <MDSnackbar
        color="error"
        icon="warning"
        title="Vendor Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="Vendor Creation"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
      <Footer />
    </DashboardLayout>
  );
}

VendorNew.propTypes = {
  setConsignerReduxList: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  setConsignerReduxList: (data) => dispatch(setConsignerList(data)),
});

export default connect(null, mapDispatchToProps)(VendorNew);
