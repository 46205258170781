const pathUrl = {
    // AUTHENTICATION
    LOGIN: { api: "auth/login/", route: "/login" },
    LOGOUT: { api: "auth/logout/", route: "/" },
    REFRESH_TOKEN: { api: "auth/token/refresh/", route: "/" },
    RESET_PASSWORD: { api: "auth/reset-password/", route: "/reset-password" },
    SET_NEW_PASSWORD: { api: "auth/set-new-password/", route: "/reset-password/:uidb64/:token" },
    CHANGE_LOCATION: { api: "/users/change-location/", route: "" },

    // DASHBOARD
    DASHBOARD: { api: "", route: "/dashboards" },
    USER_DETAILS: { api: "users/me/", route: "/" },
    NOTIFICATION: { api: "notifications/", route: "/" },
    TODAY_PICKUP: { api: "orders/", route: "/" },
    TOMORROW_PICKUP: { api: "orders/", route: "/" },
    TODAY_RETURN: { api: "orders/", route: "/" },

    _DASHBOARD: {
        // ROUTE
        HOMEPAGE: "/dashboards/",

        // API
        NOTIFICATION: "/notifications/",
        UNREAD_NOTIF: "/notifications/unread/",
        TODAY_PICKUP: "/customers/add-new/",
        TOMORROW_PICKUP: "/customers/details/",
        TODAY_RETURN: "/customers/edit/",
    },
    // CUSTOMER
    CUSTOMERS: {
        // ROUTE
        LIST_ROUTE: "/customers/",
        CREATE_ROUTE: "/customers/add-new/",
        DETAIL_ROUTE: "/customers/details/",
        EDIT_ROUTE: "/customers/edit/",

        // API
        API: "/customers/",
    },

    // INVENTORY
    INVENTORY: {
        TITLE: "Item",

        // ROUTE
        LIST_ROUTE: "/inventory/",
        CREATE_ROUTE: "/inventory/add-new/",
        DETAIL_ROUTE: "/inventory/details/",
        EDIT_ROUTE: "/inventory/edit/",

        // API
        API: "/inventory/items/",
    },

    // ORDERS
    ORDERS: {
        TITLE: "Order",
        // ROUTE
        LIST_ROUTE: "/orders/",
        CREATE_ROUTE: "/orders/add-new/",
        DETAIL_ROUTE: "/orders/details/",
        PRINT_DETAIL: "/orders/print-details/",
        EDIT_ROUTE: "/orders/edit/",

        // API
        API: "/orders/",
    },

    // VENDOR
    VENDORS: {
        TITLE: "Vendors",
        // ROUTE
        LIST_ROUTE: "/vendors/",
        // CREATE_ROUTE: "/vendors/add-new/",
        DETAIL_ROUTE: "/vendors/details/",
        // EDIT_ROUTE: "/vendors/edit/",

        // API
        API: "/vendors/",
    },

    // INVENTORY CATEGORIES
    CATEGORIES: {
        TITLE: "Category",
        // ROUTE
        LIST_ROUTE: "/categories/",
        // CREATE_ROUTE: "/categories/add-new/",
        // DETAIL_ROUTE: "/categories/details/",
        // EDIT_ROUTE: "/categories/edit/",

        // API
        API: "/inventory/categories/",
    },

    // INVENTORY SUB-CATEGORIES
    SUBCATEGORIES: {
        TITLE: "Sub-category",
        // ROUTE
        // LIST_ROUTE: "/sub-categories/",
        // CREATE_ROUTE: "/sub-categories/add-new/",
        // DETAIL_ROUTE: "/sub-categories/details/",
        // EDIT_ROUTE: "/sub-categories/edit/",

        // API
        API: "/inventory/categories/",
    },

    // WAREHOUSE
    CHECKIN: {
        TITLE: "Check-in",
        // ROUTE
        CHECKIN_ROUTE: "/warehouse/check-in/",
        CREATE_ROUTE: "/warehouse/check-in/add-new/",
        DETAIL_ROUTE: "/warehouse/check-in/details/",
        EDIT_ROUTE: "/warehouse/check-in/edit/",

        // API
        API: "/check-in/",
    },

    // ADMIN
    // MANAG_TEMPLATES: { api: "", route: "/manage-templates" },
    // MANAGE_WAREHOUSE: { api: "", route: "/manage-warehouses" },
    // REPORTS: { api: "", route: "/reports" },

    MANAGE_VENDORS: {
        TITLE: "Manage Vendors",
        // ROUTE
        LIST_ROUTE: "/manage-vendors/",
        CREATE_ROUTE: "/manage-vendors/add-new/",
        DETAIL_ROUTE: "/manage-vendors/details/",
        EDIT_ROUTE: "/manage-vendors/edit/",

        // API
        API: "/vendors/",
    },

    // MANAGE USERS
    MANAGE_USERS: {
        TITLE: "Manage Users",
        // ROUTE
        LIST_ROUTE: "/manage-users/",
        CREATE_ROUTE: "/manage-users/add-new/",
        DETAIL_ROUTE: "/manage-users/details/",
        EDIT_ROUTE: "/manage-users/edit/",

        // API
        API: "/users/",
    },

    // MANAGE_LOCATIONS: { api: "", route: "/manage-locations" },
    // MANAGE LOCATION
    MANAGE_LOCATIONS: {
        TITLE: "Manage Users",
        // ROUTE
        LIST_ROUTE: "/manage-locations/",
        CREATE_ROUTE: "/manage-locations/add-new/",
        DETAIL_ROUTE: "/manage-locations/details/",
        EDIT_ROUTE: "/manage-locations/edit/",

        // API
        API: "/locations/",
    },

    ACCESS_DENIED: {
        TITLE: "Access Denied",
        // ROUTE
        LIST_ROUTE: "/access-denied/",
    },

    SUCCESS_PAYMENT: {
        TITLE: "Payment Success",
        // ROUTE
        // PAGE_ROUTE: "/payment-success/checkout-session_id=:checkout_session_id",
        // PAGE_ROUTE: "/payment-success?checkout-session_id=:checkout_session_id",
        PAGE_ROUTE: "/payment-success/",
    },

    // Reports
    REPORTS: {
        TITLE: "Reports",
        // ROUTE
        LIST_ROUTE: "/reports/",
        CREATE_ROUTE: "/reports/add-new/",
        DETAIL_ROUTE: "/reports/details/",
        PRINT_DETAIL: "/reports/print-details/",
        EDIT_ROUTE: "/reports/edit/",

        // API
        API: "/reports/",
    },

    // WAREHOUSE
    WAREHOUSE_STAGE: {
        // ROUTE
        LIST_ROUTE: "/stage/",
        CREATE_ROUTE: "/stage/add-new/",
        DETAIL_ROUTE: "/stage/details/",
        EDIT_ROUTE: "/stage/edit/",

        // API
        API: "/wh-orders/",
    },

    WAREHOUSE_CHECKIN: {
        // ROUTE
        LIST_ROUTE: "/check-in/",
        CREATE_ROUTE: "/check-in/add-new/",
        DETAIL_ROUTE: "/check-in/details/",
        EDIT_ROUTE: "/check-in/edit/",

        // API
        API: "/wh-orders/",
    },

    WAREHOUSE_CHECKOUT: {
        // ROUTE
        LIST_ROUTE: "/check-out/",
        CREATE_ROUTE: "/check-out/add-new/",
        DETAIL_ROUTE: "/check-out/details/",
        EDIT_ROUTE: "/check-out/edit/",

        // API
        API: "/checkouts/",
    },

    WAREHOUSE_QUALITY_CHECK: {
        // ROUTE
        LIST_ROUTE: "/quality-check/",
        CREATE_ROUTE: "/quality-check/add-new/",
        DETAIL_ROUTE: "/quality-check/details/",
        DETAIL_HISTORY_ROUTE: "/quality-check/details/service-history",
        EDIT_ROUTE: "/quality-check/edit/",

        // API
        API: "/quality-checks/",
    },

    // HELPERS
    VALIDATE_EMAIL: { api: "/helpers/validate-email/", route: "" },
    VALIDATE_USERNAME: { api: "/helpers/validate-username/", route: "" },
    COUNTRY_LIST: { api: "/helpers/countries", route: "" },
    LOCATION_LIST: { api: "/locations", route: "" },
    WAREHOUSE_LIST: { api: "/locations/:id/warehouses", route: "" },

    // CONTACTS
    CONTACTS: {
        TITLE: "Contacts",
        // ROUTE
        LIST_ROUTE: "/contacts/",
        DETAIL_ROUTE: "/contacts/details/",

        // API
        API: "/contacts/",
    },
};

export default pathUrl;
