/* eslint-disable no-unused-vars */

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import { useMaterialUIController } from "context";

// Timeline context
import { TimelineProvider } from "components/CustomTimelineItem/context";

function TimelineList({ title, dark, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <TimelineProvider value={dark}>
      <MDBox
        bgColor={dark ? "dark" : "white"}
        variant="gradient"
        borderRadius="xl"
        sx={{ background: ({ palette: { background } }) => darkMode && background.card }}
      >
        <MDBox pt={3} px={3}>
          <MDTypography variant="h6" fontWeight="medium" color={dark ? "white" : "dark"}>
            {title}
          </MDTypography>
        </MDBox>
        <MDBox p={2}>{children}</MDBox>
      </MDBox>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
