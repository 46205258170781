import checkout from "layouts/admin/ManageLocation/new/schemas/form";

const {
  formField: {
    name,
    taxRate,
    timezone,
    email,

    line1,
    line2,
    city,
    state,
    country,
    zip,

    mobile,
    telephone,
    fax,
    other,
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [taxRate.name]: 0,
  [timezone.name]: null,
  [email.name]: "",

  [line1.name]: "",
  [line2.name]: "",
  [city.name]: "",
  [state.name]: "",
  [country.name]: null,
  [zip.name]: "",

  [mobile.name]: "",
  [telephone.name]: "",
  [fax.name]: "",
  [other.name]: "",
};

export default initialValues;
