/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable camelcase */
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Formik, Form } from "formik";

import { Icon, IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";

import Info from "layouts/Order/new-order/components/Info";
import form from "layouts/Order/new-order/schemas/form";
import initialValues from "layouts/Order/new-order/schemas/initialValues";
import validations from "layouts/Order/new-order/schemas/validations";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import OrderMgr from "layouts/Order/OrderMgr";
import OrderItems from "layouts/Order/new-order/components/OrderItems";

const { orderValidation } = validations;

const {
    ORDERS,
    dataValidation,
    httpRequest,
    spacing,
    flex,
    flexEnd,
    spaceBetween,
    setStorageItem,
    getStorageItem,
    getResponseMsg,
    capitalizeFirstLetter,
    formatDate,
    addCommas,
} = OrderMgr;

function OrderNew() {
    const { formId, formField } = form;
    const navigate = useNavigate();
    const location = useLocation();

    const orderDetail = JSON.parse(getStorageItem("current_order_detail"));
    const customer = JSON.parse(getStorageItem("current_order_customer_detail"));

    const [errMsg, setErrMsg] = useState("Failed to Create item");
    const [successMsg, setSuccessMsg] = useState("Successfully Created Item");

    const [customerDetails, setCustomerDetails] = useState({});
    const [contactDetails, setContactDetails] = useState({});
    const [discountPercent, setDiscountPercent] = useState((orderDetail?.discount_rate || 0) * 100);
    const [billDaysOrder, setBillDaysOrder] = useState(orderDetail?.bill_days || 1);
    const [isLoading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);

    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    const submitForm = async (values, actions) => {
        let response = null;

        if (orderDetail?.pageState === "NEW") {
            response = await httpRequest("create-order", values);
            setSuccessMsg("Item has been created successfully!");
        } else if (orderDetail?.pageState === "EDIT") {
            response = await httpRequest("update-order", values, { uuid: orderDetail.uuid });
            setSuccessMsg("Item has been updated successfully!");
        }

        if (getResponseMsg(response) === "Success") {
            openSuccessSB(true);
            setTimeout(() => {
                setLoading(false);
                actions.resetForm();
                setStorageItem("current_order_detail", response.data.data);
                // console.log(uuid);
                navigate(ORDERS.DETAIL_ROUTE, {
                    replace: true,
                    state: { searchKeyword: location.state?.searchKeyword || "" },
                });
            }, 1500);
        } else {
            let responseErrMsg = "";

            Object.entries(response.data).map((item) => {
                responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${
                    item[1]
                }`;
                return 0;
            });

            setErrMsg(responseErrMsg);
            setLoading(false);
            openErrorSB(true);
        }

        actions.setSubmitting(false);
    };

    const handleSave = (err) => {
        // console.log(err);
        let responseErrMsg = "";
        Object.entries(err).map((item) => {
            responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
            return 0;
        });

        if (responseErrMsg !== "") {
            openErrorSB(true);
            setErrMsg(responseErrMsg);
        }
    };

    const nest = (items, id = null, link = "parent_id") =>
        items
            .filter(
                (item) =>
                    // console.log(item);
                    item[link] === id
            )
            .map((item) => ({
                ...item,
                bill_days: Number(item?.bill_days),
                collections: nest(items, item.lookup_id),
                uuid: item?.parent_uuid || null,
                item_uuid: item?.uuid || "",
                quantity: !item?.delete ? Number(item?.quantity || 0) : 1,
                price: item?.daily_price,
                discount_rate: Number(Number(item?.discount_rate) / 100 || 0).toFixed(2),
                discounted_price: Number(item?.discounted_price || 0).toFixed(2),
                each_amount: Number(item?.each_amount || 0).toFixed(2),
                total_price: Number(item?.total_price || 0).toFixed(2),
                comments: item?.comments || "",
            }));

    const handleSubmit = async (values, actions) => {
        let body = {};
        setLoading(true);
        const nestedCollectionsData =
            values?.collections?.length > 0
                ? values?.collections
                : values?.collections?.collections;
        const err = dataValidation(values);

        if (err !== null) {
            setErrMsg(err);
            setLoading(false);
            openErrorSB(true);
            return;
        }
        body = {
            contact: contactDetails?.id,
            agent: values?.rental_agent_assigned?.uuid || values?.rental_agent_assigned?.value,
            description: values?.order_description,
            pickup_date: `${values.pickup_date} ${values.pickup_time}`,
            return_date: `${formatDate(values.return_by_date, "YYYY-MM-DD")} ${
                values.return_by_time
            }`,
            billing: {
                start_date: `${values.billing_start_date} ${values.billing_start_time}`,
                end_date: `${values.billing_end_date} ${values.billing_end_time}`,
                bill_days: Number(values.bill_days),
            },
            items: nest(nestedCollectionsData),
            discount_rate: Number(Number(values.default_discount) / 100 || 0).toFixed(2),
            discount_total: Number(values?.discount_total || 0).toFixed(2),
            tax_rate: Number(values?.tax_rate || 0).toFixed(1),
            tax_amount: Number(values?.taxPrice || 0).toFixed(2),
            rental_total: Number(values?.total_rent || 0).toFixed(2),
            sales_total: Number(values?.total_sales || 0).toFixed(2),
            subtotal: Number(values?.subtotal || 0).toFixed(2),
            order_total: Number(values?.order_total || 0).toFixed(2),
            deposit_amount: values.deposit_amount || 0,
            status: orderDetail?.pageState === "NEW" ? "reservation" : values?.status?.value,
            invoice_total: values.invoice.toFixed(2),
        };

        submitForm(body, actions);
    };

    const handleClose = () => {
        if (orderDetail?.pageState === "NEW") {
            navigate(ORDERS.LIST_ROUTE, {
                replace: true,
                state: { searchKeyword: location.state?.searchKeyword || "" },
            });
        } else {
            navigate(ORDERS.DETAIL_ROUTE, {
                replace: true,
                state: { searchKeyword: location.state?.searchKeyword || "" },
            });
        }
    };

    useEffect(async () => {
        if (orderDetail.pageState === "NEW") {
            setCustomerDetails({});
        } else {
            setCustomerDetails(customer);
        }
    }, []);

    useEffect(
        () => () => {
            setCustomerDetails({});
            setDiscountPercent(0);
        },
        []
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            {isLoading && <SimpleBackdrop />}
            <MDBox
                mb={3}
                // mt={3}
            >
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        <MDBox display={flex} justifyContent={spaceBetween}>
                            <MDBox>
                                <MDTypography variant="h6" fontWeight="medium" color="dark">
                                    {orderDetail?.pageState === "NEW"
                                        ? `New ${ORDERS.TITLE}`
                                        : `Edit ${ORDERS.TITLE}`}
                                </MDTypography>
                            </MDBox>
                            <MDBox display={flex} justifyContent={spaceBetween}>
                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="dark"
                                    onClick={handleClose}
                                >
                                    <Icon fontSize="small">close</Icon>
                                </IconButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12}>
                        <Formik
                            initialValues={
                                orderDetail?.pageState === "NEW"
                                    ? initialValues
                                    : orderDetail || initialValues
                            }
                            validationSchema={orderValidation}
                            onSubmit={(values, actions) => handleSubmit(values, actions)}
                        >
                            {({ values, errors, touched, isSubmitting }) => (
                                <Form id={formId} autoComplete="off">
                                    <Card id="basic-info" sx={{ overflow: "visible" }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Info
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                    }}
                                                    setCustomerDetails={setCustomerDetails}
                                                    setDiscountPercent={setDiscountPercent}
                                                    setBillDaysOrder={setBillDaysOrder}
                                                    setContactDetails={setContactDetails}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <OrderItems
                                                    formData={{
                                                        values,
                                                        touched,
                                                        formField,
                                                        errors,
                                                    }}
                                                    customerDetails={customerDetails}
                                                    // customerDetails={customerDetails}
                                                    discountPercent={discountPercent}
                                                    billDaysOrder={billDaysOrder}
                                                />
                                            </Grid>
                                        </Grid>
                                        <MDBox
                                            display={flex}
                                            justifyContent={flexEnd}
                                            my={2}
                                            mx={2}
                                        >
                                            <MDBox mx={2}>
                                                <MDButton
                                                    size="small"
                                                    variant="gradient"
                                                    color="error"
                                                    onClick={handleClose}
                                                >
                                                    Cancel
                                                </MDButton>
                                            </MDBox>

                                            <MDButton
                                                disabled={isSubmitting}
                                                size="small"
                                                type="submit"
                                                variant="gradient"
                                                color="info"
                                                onClick={() => handleSave(errors)}
                                            >
                                                Save
                                            </MDButton>
                                        </MDBox>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </MDBox>
            <MDSnackbar
                color="error"
                icon="warning"
                title="Item Creation"
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />
            <MDSnackbar
                color="success"
                icon="check"
                title="Item Creation"
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default OrderNew;
