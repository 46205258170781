/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { CompactPicker } from "react-color";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { connect } from "react-redux";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDButton from "components/MDButton";
import { Menu } from "@mui/material";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import CircleImg from "assets/images/reelmen/circle.jpg";
import CircleWhiteImg from "assets/images/reelmen/circle-white.jpg";

const { getStorageItem, httpRequest, getResponseMsg } = WarehouseMgr;

const styles = {
  // backgroundColor: "gray",
  // borderRadius: "50%",
  // borderStyle: "solid",
  width: "220px",
  height: "220px",
  // padding: "5px",
};
function REDrawing({ handleCloseModal, imgSrc, currentDrawing }) {
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const [bgImmage, setBgImmage] = useState(imgSrc || CircleWhiteImg);
  const [penColorMenu, setPenColorMenu] = useState(null);

  const [isLoading, setLoading] = useState(false);
  const [color, setColor] = useState("black");

  let drawRef = "";

  const clear = () => {
    drawRef.clearCanvas();
    setBgImmage(CircleWhiteImg);
  };

  const trim = async () => {
    setLoading(true);

    drawRef
      .exportImage("jpg")
      .then((data) => {
        handleCloseModal(currentDrawing, data);
      })
      .catch((e) => {});
    // START HERE
    // const body = new FormData();

    // body.append("attachment_type", "signature");
    // body.append("attachment", drawRef.getTrimmedCanvas().toDataURL("image/png"));
    // body.append("action", "capture");

    // const responseDetails = await httpRequest("upload-attachment", body, {
    //   uuid: qcDetails?.uuid,
    // });
    // if (getResponseMsg(responseDetails) === "Success") {
    //   handleCloseModal("success", responseDetails?.data?.data?.order_status);
    // } else {
    //   handleCloseModal("failed");
    // }
    setLoading(false);
  };

  const openSlackBotMenu = (event) => {
    setPenColorMenu(event.currentTarget);
  };

  const closeSlackBotMenu = () => setPenColorMenu(null);

  const handleChange = (value) => {
    setColor(value.hex);
    closeSlackBotMenu();
  };

  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      anchorOrigin={{ vertical: "bottom-start", horizontal: "bottom-start" }}
      transformOrigin={{ vertical: "bottom-start", horizontal: "bottom-start" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
    >
      <CompactPicker color={color} onChangeComplete={handleChange} />
    </Menu>
  );

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox>
      {isLoading && <SimpleBackdrop />}
      <MDBox>
        <MDBox display="flex" justifyContent="space-between">
          <MDBox>
            <MDTypography variant="h6" fontWeight="medium">
              RE Drawing
            </MDTypography>
          </MDBox>

          <MDBox my={1}>
            <MDBox ml={1}>
              <MDButton color="error" size="small" onClick={handleCloseModal}>
                close
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>

        {currentDrawing?.includes("internal") && (
          <MDBox mb={1} display="flex">
            <MDBox mx={1}>
              <MDTypography variant="h6" fontWeight="medium">
                Pen color:
              </MDTypography>
            </MDBox>
            <MDBox
              bgColor={color}
              sx={{
                width: "25px",
                height: "25px",
              }}
              onClick={openSlackBotMenu}
            />
            <MDBox>{renderMenu(penColorMenu, closeSlackBotMenu)}</MDBox>
          </MDBox>
        )}
        <MDBox
        // sx={{
        //   borderRadius: "50%",
        //   borderStyle: "solid",
        //   width: "200px",
        //   height: "200px",
        //   lineHeight: "200px",
        //   textAlign: "center",
        // }}
        // bgColor="info"
        >
          {/* <SignatureCanvas
            penColor="black"
            canvasProps={{
              width: isMobile ? 300 : 500,
              height: isMobile ? 100 : 200,
              className: "sigCanvas",
            }}
            ref={(ref) => {
              drawRef = ref;
            }}
          /> */}
          {/* asdasd */}
          <ReactSketchCanvas
            style={styles}
            // width="100%"
            // height="100%"
            backgroundImage={bgImmage || ""}
            strokeWidth={2}
            strokeColor={color || "black"}
            ref={(ref) => {
              drawRef = ref;
            }}
          />
        </MDBox>
        <MDBox mt={2} display="flex" justifyContent="flex-end">
          <MDBox>
            <MDButton color="info" onClick={clear}>
              reset
            </MDButton>
          </MDBox>
          <MDBox ml={1}>
            <MDButton color="success" onClick={trim}>
              save
            </MDButton>
          </MDBox>
        </MDBox>
        <MDBox
          flexDirection="column"
          display="center"
          justifyContent="center"
          direction="column"
          alignItems={{ xs: "flex-start", sm: "center" }}
          textAlign="center"
        >
          {/* <img src={imgSrc} alt="signature" /> */}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

REDrawing.defaultProps = {
  // userDetail: {},
  imgSrc: "",
  currentDrawing: "",
};

REDrawing.propTypes = {
  // userDetail: PropTypes.objectOf(PropTypes.any),
  handleCloseModal: PropTypes.func.isRequired,
  imgSrc: PropTypes.string,
  currentDrawing: PropTypes.string,
};
const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
});

export default connect(mapStateToProps, null)(REDrawing);
