/* eslint-disable no-debugger */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useMaterialUIController } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { Card, Zoom } from "@mui/material";
import { Tooltip } from "@mui/material";
import Grid from "@mui/material/Grid";

import MDButton from "components/MDButton";
import SimpleBackdrop from "components/Backdrop/SimpleBackdrop";
import MDBox from "components/MDBox";
import DraggableDialog from "components/DragableDialog";
import MDSnackbar from "components/MDSnackbar";
import Email from "components/Email";
import CustomModal from "components/CustomModal";

import BasicInfo from "layouts/Order/order-detail/BasicInfo";
import CancelOrder from "layouts/Order/order-detail/CancelOrder";
import OrderMgr from "layouts/Order/OrderMgr";
import RentItems from "layouts/Order/order-detail/RentItems";
import OrderSummary from "layouts/Order/order-detail/OrderSummary";
import Certificates from "layouts/Order/order-detail/Certificates";
import RevisionLog from "layouts/Order/order-detail/RevisionLog";
import OrderPayment from "layouts/Order/order-detail/OrderPayment";
import ProcessFeeTable from "layouts/Order/order-detail/ProcessFeeTable";
import PayLink from "./order-detail/PayLink";

const {
    httpRequest,
    getStorageItem,
    setStorageItem,
    getResponseMsg,
    ORDERS,
    formatDate,
    hasPermission,
    convertToCurrency,
} = OrderMgr;

function OrderDetail() {
    const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
    const [controller] = useMaterialUIController();
    const { darkMode } = controller;

    const location = useLocation();
    const navigate = useNavigate();
    const from = location.state?.from || ORDERS.LIST_ROUTE;
    const orderDetail = JSON.parse(getStorageItem("current_order_detail"));

    const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
    const [orderData, setOrderData] = useState([]);
    const [certificateData, setCertificateData] = useState({});
    const [customerData, setCustomerData] = useState({});
    const [transactionOptions, setTransactionOptions] = useState([]);

    const [errMsg, setErrMsg] = useState("Failed");
    const [successMsg, setSuccessMsg] = useState("Sucess");
    const [notifTitle, setNotifTitle] = useState("Item Creation");

    const [isLoading, setLoading] = useState(false);
    const [successSB, setSuccessSB] = useState(false);
    const [errorSB, setErrorSB] = useState(false);
    const [reloadDetails, setReloadDetails] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emailTypeOptions, setEmailTypeOptions] = useState(null);
    const [showRevisionHistory, setShowRevisionLogs] = useState(false);
    const [showCloneOrderDialog, setShowCloneOrderDialog] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPayLinkModal, setShowPayLinkModal] = useState(false);
    const [showTooltip, setShoWTooltip] = useState(false);
    const [showCloneOrderTooltip, setShowCloneOrderTooltip] = useState(false);
    const [showOrderPaymentTooltip, setShowOrderPaymentTooltip] = useState(false);
    const [showEmailTooltip, setShowEmailTooltip] = useState(false);

    const openEmailTooltip = () => setShowEmailTooltip(true);
    const closeEmailTooltip = () => setShowEmailTooltip(false);
    const openOrderPaymentTooltip = () => setShowOrderPaymentTooltip(true);
    const closeOrderPaymentTooltip = () => setShowOrderPaymentTooltip(false);
    const openCloneTooltip = () => setShowCloneOrderTooltip(true);
    const closeCloneTooltip = () => setShowCloneOrderTooltip(false);
    const openTooltip = () => setShoWTooltip(true);
    const closeTooltip = () => setShoWTooltip(false);
    const openErrorSB = () => setErrorSB(true);
    const closeErrorSB = () => setErrorSB(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);
    const openRevisionLogsModal = () => setShowRevisionLogs(true);
    const handleOpenCloneDialog = () => setShowCloneOrderDialog(true);
    const handleCloseCloneDialog = () => setShowCloneOrderDialog(false);
    const handleOpenPaymentModal = () => setShowPaymentModal(true);

    const processRows = (data = []) => {
        let dailyTotal = 0;
        let weeklyTotal = 0;
        let monthlyTotal = 0;
        const paymentTmp = [];

        // debugger;
        data?.items.map((item) => {
            if (!item?.optional) {
                dailyTotal += Number(item?.custom_prices?.daily || 0);
                weeklyTotal += Number(item?.custom_prices?.weekly || 0);
                monthlyTotal += Number(item?.custom_prices?.monthly || 0);
            }
            return 0;
        });

        data?.payments.map((payment) => {
            if (payment?.payment_method?.value === "credit_debit") {
                paymentTmp.push({
                    ...payment,
                    card_type:
                        `${payment?.payment_method_details?.card?.brand.toUpperCase()} - ${
                            payment?.payment_method_details?.card?.last4
                        }` || "Credit Card Processing Fee",
                    comment: `Paid ${convertToCurrency(payment?.amount)} on ${formatDate(
                        payment?.payment_date,
                        "LLL"
                    )}  `,
                });
            }
            return 0;
        });

        return { ...data, payments: paymentTmp, dailyTotal, weeklyTotal, monthlyTotal };
    };

    const loadDetails = async () => {
        const tmpPaymentTypeOptions = [];
        setLoading(true);

        // const responseDetails1 = await httpRequest("inventory-detail", null, {
        //   uuid: itemDetail?.uuid,
        // });

        const responseDetails = await httpRequest("orders-detail", null, {
            uuid: orderDetail?.uuid,
            id: orderDetail?.id,
        });
        // debugger;
        if (getResponseMsg(responseDetails) === "Success") {
            setOrderData(processRows(responseDetails?.data?.data));

            setStorageItem("current_order_detail", processRows(responseDetails?.data?.data));

            const documentResponse = await httpRequest("customer-documents", null, {
                uuid: responseDetails?.data?.data?.customer?.uuid,
            });

            if (getResponseMsg(documentResponse) === "Success") {
                setCertificateData(documentResponse?.data?.data);
            }

            const customerDetailResponse = await httpRequest("customer-detail", null, {
                uuid: responseDetails?.data?.data?.customer?.uuid,
            });

            // debugger;
            if (getResponseMsg(customerDetailResponse) === "Success") {
                setCustomerData(customerDetailResponse?.data?.data);
                setEmailTypeOptions([
                    {
                        name: "Missing Paperworks",
                        disabled: customerData?.has_missing_paperworks === true,
                    },
                    { name: "Order Invoice", disabled: false },
                    { name: "Orders", disabled: false },
                    {
                        name: "Payment Receipt",
                        disabled: responseDetails?.data?.data?.payments?.length === 0,
                    },
                ]);

                if (responseDetails?.data?.data?.payments?.length > 0) {
                    responseDetails?.data?.data?.payments.map((payment) => {
                        if (payment?.status !== "failed") {
                            tmpPaymentTypeOptions.push({
                                uuid: payment?.uuid,
                                value: payment?.uuid,
                                name: `${payment?.payment_method?.name} (${formatDate(
                                    payment?.payment_date,
                                    "LL"
                                )} - ${convertToCurrency(payment?.amount)})`,
                            });
                        }

                        // console.log();
                        return 0;
                    });
                }
            }
            // debugger;
        }

        setTransactionOptions(tmpPaymentTypeOptions);
        // console.log(transactionOptions);

        setLoading(false);

        return 0;
    };

    useEffect(() => {
        const controllerRequest = new AbortController();
        setNotifTitle("Order Details");
        if (Object.keys(orderDetail).length === 0) {
            navigate(ORDERS.LIST_ROUTE, {
                // replace: true
            });
        }
        loadDetails();

        return () => {
            controllerRequest.abort();
        };
    }, []);

    const handleClosePaymentModal = (status = "") => {
        setLoading(true);
        switch (status) {
            case "Success":
                setSuccessMsg("Payment Recorded.");
                openSuccessSB(true);
                setTimeout(() => {
                    loadDetails();
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                }, 500);

                break;
            case "Success-email":
                setSuccessMsg("Payment link sent to customer email.");
                openSuccessSB(true);
                setTimeout(() => {
                    loadDetails();
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                }, 500);

                break;
            case "failed-payment":
                setErrMsg("Failed to send Payment link to customer email.");
                openErrorSB(true);
                // setTimeout(() => {
                //   loadDetails();
                //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                // }, 1000);

                break;
            default:
                break;
        }
        setShowPaymentModal(false);
        setLoading(false);
    };

    const handleOpenPayLinkModal = async () => {
        document.body.style.cursor = "wait";
        const responseDetails = await httpRequest("orders-detail", null, {
            uuid: orderDetail?.uuid,
            id: orderDetail?.id,
        });

        if (getResponseMsg(responseDetails) === "Success") {
            // console.log(responseDetails);
            if (responseDetails?.data?.data?.payment_link !== null) {
                window.open(responseDetails?.data?.data?.payment_link, "_blank", "noreferrer");
                // console.log(orderDetail?.payment_link);
            } else {
                setShowPayLinkModal(true);
            }
        }
        document.body.style.cursor = "default";
    };

    const handleClosePayLinkModal = (status = "") => {
        setLoading(true);
        switch (status) {
            case "Success":
                setSuccessMsg("Payment Link has been generated.");
                openSuccessSB(true);
                setTimeout(() => {
                    loadDetails();
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                }, 500);

                break;
            case "failed-payment":
                setErrMsg("Failed to send Payment link to customer email.");
                openErrorSB(true);
                // setTimeout(() => {
                //   loadDetails();
                //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                // }, 1000);

                break;
            default:
                break;
        }
        setShowPayLinkModal(false);
        setLoading(false);
    };
    // useEffect(() => {
    //   setNotifTitle("Inventory Details");
    //   if (itemDetail === {}) {
    //     navigate(INVENTORY.LIST_ROUTE, { replace: true });
    //   }
    //   loadDetails();
    // }, [itemDetail]);

    const handleClose = () => {
        // console.log(location, from);
        if (from && from !== ORDERS.CREATE_ROUTE) {
            navigate(from, {
                replace: true,
                state: { searchKeyword: location.state?.searchKeyword || "" },
            });
        } else {
            navigate(ORDERS?.LIST_ROUTE, {
                replace: true,
                state: { searchKeyword: location.state?.searchKeyword || "" },
            });
        }
    };

    //TODO: Change structure of the item to match the order structure
    const editItem = () => {
        // console.log(orderData);
        const tmpCollection = [];

        orderData?.items.map((item) =>
            tmpCollection.push({
                ...item,
                // ...item.item,
                daily_price: item?.price,
                discount_rate: item?.discount_rate,
                bill_days: item?.bill_days,
                each_amount: item?.each_amount,
                // parent_uuid: item?.uuid,
                // comments,
                // optional: item.optional,
                // quantity: item.quantity,
                // daily_price: item?.custom_prices?.daily,
                // weekly_price: item?.custom_prices?.weekly,
                // monthly_price: item?.custom_prices?.monthly,
                // parent_uuid: item?.uuid,total_price
            })
        );

        const item = {
            ...orderDetail,
            ...orderData,
            customer_name: orderData?.customer?.name,
            customer_uuid: orderData?.customer?.id,
            pickup_date: formatDate(orderData?.pickup_date, "YYYY-MM-DD"),
            pickup_time: formatDate(orderData?.pickup_date, "HH:mm"),
            return_by_date: formatDate(orderData?.return_date, "YYYY-MM-DD"),
            return_by_time: formatDate(orderData?.return_date, "HH:mm"),
            billing_start_date: formatDate(orderData?.billing?.start_date, "YYYY-MM-DD"),
            billing_start_time: formatDate(orderData?.billing?.start_date, "HH:mm"),
            billing_end_date: formatDate(orderData?.billing?.end_date, "YYYY-MM-DD"),
            billing_end_time: formatDate(orderData?.billing?.end_date, "HH:mm"),
            order_description: orderData?.description,
            bill_days: orderData?.billing?.bill_days,
            default_discount: (orderData?.discount_rate || 0) * 100,
            rental_agent_assigned: orderData?.agent,
            deposit_amount: orderData?.deposit_amount,
            tax_amount: orderData?.tax_amount,
            collections: { collections: tmpCollection },
            tax_exempt: customerData?.tax_exempt,
            // collections: tmpCollection,
        };

        // console.log(orderData);
        // console.log(item);

        const temp = { ...item, pageState: "EDIT" };
        // console.log(temp);
        setStorageItem("current_order_detail", temp);
        setStorageItem("current_order_customer_detail", customerData);
        navigate(ORDERS.EDIT_ROUTE, {
            state: {
                from: location,
                searchKeyword: location.state?.searchKeyword || "",
                hasModal: true,
            },
            // replace: true,
        });
    };

    const handleSendEmail = async () => {
        setShowEmailModal(true);
        // console.log(emailTypeOptions[1]);
        // console.log("email");
    };

    const handleCloseEmailModal = (type = "close", status, emailErrMsg = "") => {
        setNotifTitle("Email");
        switch (type) {
            case emailTypeOptions[0]:
            case emailTypeOptions[1]:
            case emailTypeOptions[2]:
            case emailTypeOptions[3]:
                if (status === true) {
                    setSuccessMsg(`Sent email for ${type.name} Successfully`);
                    openSuccessSB(true);
                } else {
                    setErrMsg(
                        `Failed to send ${type.name} email, this ${emailErrMsg?.data?.order[0]}`
                    );
                    openErrorSB(true);
                }
                break;
            default:
                setShowEmailModal(false);
                break;
        }
        setShowEmailModal(false);
    };

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };

    const handleConfirm = async (action) => {
        // console.log("clone");
        setLoading(true);
        let cloneResponse = "";
        switch (action) {
            case "clone":
                handleCloseCloneDialog();
                cloneResponse = await httpRequest("clone-order", null, {
                    uuid: orderDetail.uuid,
                });
                break;
            default:
                break;
        }
        // debugger;
        if (getResponseMsg(cloneResponse) === "Success") {
            setSuccessMsg("Cloned Successfully.");
            openSuccessSB(true);
            setTimeout(() => {
                setSuccessMsg(false);
                navigate(ORDERS.LIST_ROUTE, {
                    // replace: true
                });
                navigate(ORDERS.DETAIL_ROUTE, {
                    state: { from: location },
                    // replace: true
                });
            }, 1000);
        } else {
            setErrMsg(cloneResponse.data.data);
            setSuccessMsg(false);
            openErrorSB(true);
        }
    };

    const closeRevisionLogModal = (status = "") => {
        setLoading(true);
        switch (status) {
            case "Success-refund":
                setSuccessMsg("Refund Recorded.");
                openSuccessSB(true);
                setTimeout(() => {
                    loadDetails();
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                }, 500);

                break;
            case "Success-email":
                setSuccessMsg("Payment link sent to customer email.");
                openSuccessSB(true);
                setTimeout(() => {
                    loadDetails();
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                    // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                }, 500);

                break;
            case "failed-payment":
                setErrMsg("Failed to send Payment link to customer email.");
                openErrorSB(true);
                // setTimeout(() => {
                //   loadDetails();
                //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                //   // navigate(ORDERS.LIST_ROUTE, { state: { from: "" }, replace: true });
                // }, 1000);

                break;
            default:
                break;
        }
        setLoading(false);
        setShowRevisionLogs(false);
    };

    useEffect(() => {
        const setResponsiveness = () =>
            window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
        setResponsiveness();

        window.addEventListener("resize", () => setResponsiveness());

        return () => {
            window.removeEventListener("resize", () => setResponsiveness());
        };
    }, []);

    return (
        <DashboardLayout>
            {isLoading && <SimpleBackdrop />}
            <DashboardNavbar />
            <MDBox>
                <MDBox mb={1}>
                    <MDBox display="flex" justifyContent="space-between">
                        <Tooltip
                            TransitionComponent={Zoom}
                            followCursor
                            title={
                                orderData?.remaining_balance <= 0
                                    ? "Your remaining balance is already 0."
                                    : "You don't have permission to do this"
                            }
                            open={
                                showOrderPaymentTooltip &&
                                (orderData?.remaining_balance <= 0 ||
                                    !hasPermission("manage_payment", permissions?.payments))
                            }
                            onClose={closeOrderPaymentTooltip}
                            onOpen={openOrderPaymentTooltip}
                        >
                            <MDBox display="flex" justifyContent="flex-start">
                                <MDBox>
                                    <MDButton
                                        size="small"
                                        variant="outlined"
                                        color="info"
                                        onClick={handleOpenPaymentModal}
                                        sx={{ marginRight: 1 }}
                                        disabled={
                                            orderData?.remaining_balance <= 0 ||
                                            !hasPermission("manage_payment", permissions?.payments)
                                        }
                                    >
                                        Order Payment
                                    </MDButton>
                                </MDBox>
                                <MDBox>
                                    <MDButton
                                        size="small"
                                        variant="outlined"
                                        color="info"
                                        onClick={handleOpenPayLinkModal}
                                        sx={{ marginRight: 1 }}
                                        disabled={
                                            orderData?.remaining_balance <= 0 ||
                                            !hasPermission("manage_payment", permissions?.payments)
                                        }
                                    >
                                        Pay
                                    </MDButton>
                                </MDBox>
                            </MDBox>
                        </Tooltip>
                        <MDBox display="flex" justifyContent="flex-end">
                            {/* <MDButton
              size="small"
              variant="outlined"
              color="info"
              // onClick={handleOpenComingSoonDialog}
              sx={{ marginRight: 1 }}
              disabled
            >
              Pay Invoice
            </MDButton> */}
                            {/* <MDButton
              size="small"
              variant="outlined"
              color="info"
              // onClick={handleOpenComingSoonDialog}
              sx={{ marginRight: 1 }}
              disabled
            >
              Clone
            </MDButton> */}
                            <Tooltip
                                title="You don't have permission to do this"
                                placement="top"
                                followCursor
                                open={
                                    showCloneOrderTooltip &&
                                    !hasPermission("clone_order", permissions?.orders)
                                }
                                onClose={closeCloneTooltip}
                                onOpen={openCloneTooltip}
                            >
                                <MDBox>
                                    <MDButton
                                        size="small"
                                        variant="outlined"
                                        color="info"
                                        onClick={handleOpenCloneDialog}
                                        sx={{ marginRight: 1 }}
                                        disabled={
                                            !hasPermission("clone_order", permissions?.orders)
                                        }
                                    >
                                        Clone Order
                                    </MDButton>
                                </MDBox>
                            </Tooltip>

                            <MDButton
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={openRevisionLogsModal}
                                sx={{ marginRight: 1 }}
                                // disabled
                            >
                                Revision Log
                            </MDButton>
                            <MDButton
                                size="small"
                                variant="outlined"
                                color="info"
                                onClick={() => openInNewTab(ORDERS.PRINT_DETAIL)}
                                sx={{ marginRight: 1 }}
                                // disabled
                            >
                                Print Order Detail
                            </MDButton>
                            <Tooltip
                                title="You don't have permission to do this"
                                placement="top"
                                followCursor
                                open={
                                    showEmailTooltip &&
                                    !hasPermission("send_email", permissions?.customers)
                                }
                                onClose={closeEmailTooltip}
                                onOpen={openEmailTooltip}
                            >
                                <MDBox>
                                    <MDButton
                                        size="small"
                                        variant="outlined"
                                        color="info"
                                        onClick={() => handleSendEmail()}
                                        sx={{ marginRight: 1 }}
                                        disabled={
                                            !hasPermission("send_email", permissions?.customers)
                                        }
                                    >
                                        Email
                                    </MDButton>
                                </MDBox>
                            </Tooltip>
                            <Tooltip
                                title="You don't have permission to do this"
                                placement="top"
                                followCursor
                                open={
                                    showTooltip &&
                                    !hasPermission("change_order", permissions?.orders)
                                }
                                onClose={closeTooltip}
                                onOpen={openTooltip}
                            >
                                <MDBox>
                                    <MDButton
                                        size="small"
                                        variant="outlined"
                                        color="info"
                                        onClick={editItem}
                                        sx={{ marginRight: 1 }}
                                        disabled={
                                            !hasPermission("change_order", permissions?.orders) ||
                                            orderData?.status?.value === "canceled"
                                        }
                                    >
                                        Edit
                                    </MDButton>
                                </MDBox>
                            </Tooltip>

                            <MDButton
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={handleClose}
                            >
                                back
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Card id="basic-info" sx={{ overflow: "visible", height: "auto" }}>
                    <MDBox>
                        <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                            <Grid item xs={4} sm={8} md={12}>
                                <BasicInfo data={orderData || {}} />
                            </Grid>
                            <Grid item xs={4} sm={8} md={12}>
                                <Certificates
                                    data={orderData || {}}
                                    certificateData={certificateData}
                                />
                            </Grid>
                            <Grid item xs={4} sm={8} md={12}>
                                <OrderSummary data={orderData || {}} />
                            </Grid>
                            <Grid item xs={4} sm={8} md={12}>
                                <RentItems data={orderData || []} />
                            </Grid>
                            {/* {orderData?.payments?.length > 0 && ( */}
                            {/* <MDBox width="50rem"> */}
                            <Grid item xs={4} sm={8} md={12}>
                                <ProcessFeeTable data={orderData || []} />
                            </Grid>
                            {/* </MDBox> */}
                            {/* )} */}
                        </Grid>
                    </MDBox>
                </Card>
                <Card id="basic-info" sx={{ overflow: "visible", height: "auto", mt: 2 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <CancelOrder data={orderData || null} />
                        </Grid>
                    </Grid>
                </Card>
            </MDBox>
            {showEmailModal && (
                <CustomModal showModal={showEmailModal} handleHideModal={handleCloseEmailModal}>
                    <MDBox bgColor={darkMode ? "dark" : "white"}>
                        <Email
                            onClose={handleCloseEmailModal}
                            uuid={customerData?.uuid}
                            emailOptions={emailTypeOptions}
                            defaultEmailType={emailTypeOptions[2]}
                            transactionOptions={transactionOptions}
                            showCc
                            showInitialTo={customerData?.email || ""}
                        />
                    </MDBox>
                </CustomModal>
            )}

            {showCloneOrderDialog && (
                <DraggableDialog
                    title="Clone Order"
                    description="Are you sure you want to clone this Order?"
                    buttonDesc="Clone"
                    openDialog={showCloneOrderDialog}
                    handleClose={handleCloseCloneDialog}
                    handleConfirm={() => handleConfirm("clone")}
                    fullWidth={false}
                />
            )}
            {showRevisionHistory && (
                <CustomModal
                    showModal={showRevisionHistory}
                    handleHideModal={closeRevisionLogModal}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "95%",
                        width: "50%",
                        // border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "5px",
                        p: 4,
                        overflow: "auto",
                    }}
                >
                    <RevisionLog onClose={closeRevisionLogModal} loadDetail={loadDetails} />

                    {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
                </CustomModal>
            )}
            {showPaymentModal && (
                <CustomModal
                    showModal={showPaymentModal}
                    handleHideModal={handleClosePaymentModal}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "auto",
                        width: "30%",
                        // border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "5px",
                        p: 2,
                        overflow: "auto",
                    }}
                >
                    <OrderPayment onClose={handleClosePaymentModal} />

                    {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
                </CustomModal>
            )}
            {showPayLinkModal && (
                <CustomModal
                    showModal={showPayLinkModal}
                    handleHideModal={handleClosePayLinkModal}
                    style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        height: "auto",
                        width: "30%",
                        // border: "2px solid #000",
                        boxShadow: 24,
                        borderRadius: "5px",
                        p: 2,
                        overflow: "auto",
                    }}
                >
                    <PayLink onClose={handleClosePayLinkModal} />

                    {/* <TimelineList title="Timeline with dotted line">{renderTimelineItems}</TimelineList> */}
                </CustomModal>
            )}

            <MDSnackbar
                color="error"
                icon="warning"
                title={notifTitle}
                dateTime=""
                content={errMsg}
                open={errorSB}
                onClose={closeErrorSB}
                close={closeErrorSB}
                bgWhite={false}
            />
            <MDSnackbar
                color="success"
                icon="check"
                title={notifTitle}
                dateTime=""
                content={successMsg}
                open={successSB}
                onClose={closeSuccessSB}
                close={closeSuccessSB}
                bgWhite={false}
            />
            <Footer />
        </DashboardLayout>
    );
}

export default OrderDetail;
