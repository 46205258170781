/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataTable from "components/Tables/DataTable";

import { Autocomplete, CircularProgress, Icon, Tooltip } from "@mui/material";
import { connect, useSelector } from "react-redux";
import DashboardMgr from "layouts/dashboards//DashboardMgr";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";

const {
  httpRequest,
  getStorageItem,
  getResponseMsg,
  setStorageItem,
  ORDERS,
  getNextPage,
  _DASHBOARD,
  hasPermission,
} = DashboardMgr;

function TodaysReturnModal({ onClose, defaultPickupFilter, locations, viewData }) {
  const permissions = useSelector((state) => state?.authentication?.userDetail?.permissions);
  const location = useLocation();
  const navigate = useNavigate();

  const locationList = JSON.parse(getStorageItem("locations_list")) || locations;

  const [todaysReturnData, setTodaysReturnData] = useState([]);
  const [todaysReturnLocationFilter, setTodaysReturnLocationFilter] = useState(defaultPickupFilter);

  const [errMsg, setErrMsg] = useState("Failed to load");
  const [errorSB, setErrorSB] = useState(false);
  const [nextPageLink, setNextPageLink] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const processRow = (data = []) => {
    let tmpData = [];
    data.map((order) => {
      tmpData = tmpData.concat({
        name: order?.identifier,
        // customer_name: order?.customer?.name,
        // description: order?.description,
        // status: order?.status?.name,
        uuid: order?.uuid,
      });
      return 0;
    });

    return tmpData;
  };

  const showOrderDetail = (values) => {
    setStorageItem("current_order_detail", values);
    navigate(ORDERS.DETAIL_ROUTE, {
      state: { from: _DASHBOARD?.HOMEPAGE },
      //  replace: true
    });
  };

  const dataTableData = {
    columns: [
      {
        Header: "Order number",
        accessor: "name",
        width: "10%",
        headerAlign: "left",
        Cell: ({ row, value }) => {
          const canViewInventoryDetail = hasPermission("view_order", permissions?.orders);
          const status = (
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
            >
              <MDBox>
                {!canViewInventoryDetail && (
                  <Tooltip title="You don't have permission to View Orders" placement="top">
                    <Icon mt={1}>error_outlined</Icon>
                  </Tooltip>
                )}
                <MDTypography
                  ml={!canViewInventoryDetail ? 1 : 0}
                  variant="button"
                  color="text"
                  onClick={() => canViewInventoryDetail && showOrderDetail(row?.original)}
                >
                  <MDTypography
                    component={Link}
                    to="#"
                    variant="button"
                    color={!canViewInventoryDetail ? "text" : "info"}
                    fontWeight={!canViewInventoryDetail ? "regular" : "medium"}
                    textGradient
                  >
                    {value}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          );

          return status;
        },
      },
      // { Header: "customer name", accessor: "customer_name", width: "25%" },
      // { Header: "Job Summary", accessor: "description", width: "25%" },
      // { Header: "Order status", accessor: "status", width: "25%" },
    ],
    rows: viewData?.data || [],
  };

  const proccessResponse = (response) => {
    if (getResponseMsg(response) === "Success") {
      setTodaysReturnData(todaysReturnData.concat(processRow(response?.data?.data?.results)));
      setNextPageLink(response.data.data?.next);
    }
  };

  const handleNextPage = async () => {
    setLoadMore(true);
    proccessResponse(await getNextPage(nextPageLink));
    setLoadMore(false);
  };

  const loadPickupToday = async (locationId) => {
    setIsLoading(true);
    const response = await httpRequest("get-todays-return", {
      entriesPerPage: 13,
      locationId,
    });
    if (getResponseMsg(response) === "Success") {
      setTodaysReturnData(processRow(response?.data?.data?.results));
      setNextPageLink(response.data.data?.next);
    } else {
      setErrMsg("Failed to load Tomorrow's Pickup list");
      openErrorSB(true);
    }

    setIsLoading(false);
  };

  const handleChange = async (e, val) => {
    setTodaysReturnLocationFilter(val);
    loadPickupToday(val?.id);
  };

  useEffect(() => {
    // loadPickupToday(todaysReturnLocationFilter.id);
  }, []);

  return (
    <MDBox>
      <MDBox display="flex" justifyContent="space-between">
        <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <MDBox display="flex">
            <MDTypography>
              <Icon color="inherit" fontSize="medium">
                today
              </Icon>
            </MDTypography>
            <MDTypography variant="h6" fontWeight="medium">
              {viewData?.title}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" justifyContent="space-between">
          {/* <MDBox mx={2}>
            <Autocomplete
              value={todaysReturnLocationFilter}
              options={locationList}
              sx={{ width: 200 }}
              isOptionEqualToValue={(option, value) =>
                value !== "" ? option.name === value.name : option.name
              }
              getOptionLabel={(option) => (option ? option.name : "")}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  handleChange(event, newValue);
                }
              }}
              renderInput={(params) => <MDInput {...params} label="Filter by Location" />}
            />
          </MDBox> */}
          <MDBox>
            <MDButton size="large" aria-label="close" color="error" onClick={onClose}>
              close
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <MDBox>
        {isLoading ? (
          <MDBox display="flex" direction="row" justifyContent="center" alignItems="center">
            <CircularProgress />
          </MDBox>
        ) : (
          <MDBox mt={-2}>
            <DataTable
              table={dataTableData}
              entriesPerPage={{ show: false, defaultValue: 9999 }}
              showTotalEntries={false}
              isSorted={false}
              canSearch={false}
              hiddenCols={["file_url"]}
            />
          </MDBox>
        )}
      </MDBox>
      {/* {loadMore ? (
        <MDBox display="flex" direction="row" justifyContent="center" alignItems="center" mt={2}>
          <CircularProgress />
        </MDBox>
      ) : (
        <MDBox display="flex" justifyContent="center" alignItems="center" mt={2}>
          {!isLoading && nextPageLink !== null && (
            <MDButton variant="text" size="small" onClick={() => handleNextPage()}>
              <MDTypography size="small" variant="body2" fontWeight="medium">
                Show more...
              </MDTypography>
            </MDButton>
          )}
        </MDBox>
      )} */}
      <MDSnackbar
        color="error"
        icon="warning"
        title="Vendor Creation"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
    </MDBox>
  );
}
TodaysReturnModal.defaultProps = {
  locations: [],
  viewData: {},
};

TodaysReturnModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  defaultPickupFilter: PropTypes.objectOf(PropTypes.any).isRequired,
  locations: PropTypes.arrayOf(PropTypes.any),
  viewData: PropTypes.objectOf(PropTypes.any),
};
const mapStateToProps = (state) => ({
  locations: state.helper?.locations,
});

export default connect(mapStateToProps, null)(TodaysReturnModal);
