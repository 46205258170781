/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
// Material Dashboard 2 PRO React components
import PropTypes from "prop-types";

import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import OrderMgr from "layouts/Order/OrderMgr";

const { convertToDecimal, convertToCurrency } = OrderMgr;

function OrderSummary({ data }) {
  const {
    deposit_amount,
    order_total,
    sales_total,
    rental_total,
    tax_rate,
    invoice_total,
    status,
    subtotal,
    total_amount_paid,
    remaining_balance,
    discount_total,
    tax_amount,
    // notes,
  } = data;

  const pricingDetails = [
    { label: "Sales Total", value: ` ${convertToCurrency(sales_total || 0)}` },
    { label: "Inventory Total", value: `${convertToCurrency(rental_total || 0)}` },
    { label: "Subtotal", value: `${convertToCurrency(subtotal || 0)}` },
    { label: "Tax", value: `${convertToCurrency(tax_amount || 0)}` },
    // { label: "Total cost of Order", value: `${order_total || 0}` },
    // { label: "Discount total", value: `${tax_rate || 0} %` },
    { label: "Invoice Total", value: `${convertToCurrency(invoice_total || 0)}` },
    { label: "Amount Paid", value: `${convertToCurrency(total_amount_paid || 0)}` },
    {
      label: "Remaining Balance",
      value: `${convertToCurrency(Number(remaining_balance || 0) - 0)}`,
    },
  ];

  const renderSummary = (items) =>
    items.map((item) => (
      <Grid item xs={2} sm={4} md={4} key={item.name}>
        <MDBox display="flex" py={1} pr={2}>
          <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
            {item.label}: &nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="regular" color="text">
            &nbsp;{item.value}
          </MDTypography>
        </MDBox>
      </Grid>
    ));

  return (
    <MDBox pb={1} pl={2}>
      <MDBox>
        <MDTypography variant="h6" color="info">
          Order Summary
        </MDTypography>
      </MDBox>

      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        {renderSummary(pricingDetails)}
      </Grid>
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Product Price:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $90
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Delivery:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $14
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mb={0.5}>
        <MDTypography variant="button" fontWeight="regular" color="text">
          Taxes:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body2" fontWeight="medium">
            $1.95
          </MDTypography>
        </MDBox>
      </MDBox> */}
      {/* <MDBox display="flex" justifyContent="space-between" mt={3}>
        <MDTypography variant="body1" fontWeight="light" color="text">
          Total:
        </MDTypography>
        <MDBox ml={1}>
          <MDTypography variant="body1" fontWeight="medium">
            $1.95
          </MDTypography>
        </MDBox>
      </MDBox> */}
    </MDBox>
  );
}

OrderSummary.defaultProps = {
  data: {},
};

OrderSummary.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
};

export default OrderSummary;
