/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";
import MDBox from "components/MDBox";
import { Image } from "mui-image";
import MDTypography from "components/MDTypography";
import {
  Autocomplete,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Modal,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import MDButton from "components/MDButton";
import CircleImg from "assets/images/reelmen/circle.jpg";
import CircleWhiteImg from "assets/images/reelmen/circle-white.jpg";
import WarehouseMgr from "layouts/Warehouse/WarehouseMgr";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDEditor from "components/MDEditor";
import Done from "assets/sounds/tada.mp3";
import FEDrawing from "../Modals/Lens/FEDrawing";
import REDrawing from "../Modals/Lens/REDrawing";
import Photo from "../Modals/Lens/Photo";
import DragAndDrop from "../Modals/Lens/DragAndDrop";

const {
  getDateToday,
  formatDate,
  getStorageItem,
  httpRequest,
  getResponseMsg,
  capitalizeFirstLetter,
  WAREHOUSE_QUALITY_CHECK,
  checkNullEmpty,
} = WarehouseMgr;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "auto",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUploadWeb = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "50%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

const styleUploadMobile = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: "98%",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
};

function Lens({ data, loadDetails, disableFields }) {
  const { qcSheet } = data;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const navigate = useNavigate();
  // const newQcSheet = qcSheet?.checklist.length === 0;
  const newQcSheet = qcSheet?.uuid === undefined;
  const [isMobile, setIsMobile] = useState(getStorageItem("isMobile"));
  const userLoggedin = useSelector((state) => state?.authentication?.userDetail);
  const qcDetails = JSON.parse(getStorageItem("current_qc_detail"));
  const [playDoneSound] = useSound(Done);
  const statusOptions = [
    { name: "Retired", value: "retired" },
    { name: "In Repair", value: "in_repair" },
    // { name: "Awaiting QC", value: "awaiting_qc" },
    { name: "Ready to Rent", value: "available" },
  ];

  const drawingStateOptions = ["internal-fe", "internal-re", "customer-fe", "customer-re"];

  const [qcSheetDetails, setQcSheetDetails] = useState(qcSheet);
  const [feImageSrcInternal, setFeImageSrcInternal] = useState(
    qcSheet?.drawings?.internal?.fe?.file_url === null
      ? ""
      : qcSheet?.drawings?.internal?.fe?.file_url
  );
  const [reImageSrcInternal, setReImageSrcInternal] = useState(
    qcSheet?.drawings?.internal?.re?.file_url === null
      ? ""
      : qcSheet?.drawings?.internal?.re?.file_url
  );
  const [feCbValueInternal, setFeCheckboxValueInternal] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.internal?.fe?.is_checked
  );
  const [reCbValueInternal, setReCheckboxValueInternal] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.internal?.re?.is_checked
  );
  const [feImageSrcCustomer, setFeImageSrcCustomer] = useState(
    qcSheet?.drawings?.customer?.fe?.file_url === null
      ? ""
      : qcSheet?.drawings?.customer?.fe?.file_url
  );
  const [reImageSrcCustomer, setReImageSrcCustomer] = useState(
    qcSheet?.drawings?.customer?.re?.file_url === null
      ? ""
      : qcSheet?.drawings?.customer?.re?.file_url
  );
  const [feCbValueCustomer, setFeCheckboxValueCustomer] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.customer?.fe?.is_checked
  );
  const [reCbValueCustomer, setReCheckboxValueCustomer] = useState(
    qcSheet?.drawings === null ? false : qcSheet?.drawings?.customer?.re?.is_checked
  );
  const [showFEDrawingModal, setShowFEDrawingModal] = useState(false);
  const [showREDrawingModal, setShowREDrawingModal] = useState(false);
  const [showCapturePhoto, setCapturePhoto] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [technicianValue, setTechnicianValue] = useState(
    newQcSheet ? `${userLoggedin?.first_name} ${userLoggedin?.last_name}` : qcSheet?.technician
  );
  const [firmwareValue, setFirmwareValue] = useState(qcSheet?.service_notes?.firmware);
  const [otherValue, setOtherValue] = useState(qcSheet?.service_notes?.other);
  const [privateServiceNotes, setPrivateServerNotes] = useState(qcSheet?.private_service_notes);
  const [lensChecklist, setLensChecklist] = useState(qcSheet?.checklist);
  const [errMsg, setErrMsg] = useState("Failed to Save QC Sheet");
  const [successMsg, setSuccessMsg] = useState("Successfully Saved QC Sheet");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [notifTitle, setNotifTitle] = useState("Upload Document");
  const [showConfirmationDialogue, setShowConfirmationDialogue] = useState(false);
  const [confirmationDialougeMessage, setConfirmationDialougeMessage] = useState("");
  const [fileNameToRemove, setFilenameToRemove] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [showSuccessDialogue, setShowSuccessDialogue] = useState(false);
  const [responseDisplay, setResponseDisplay] = useState({});
  const [noChangeInConditionCb, setNoChangeInConditionCb] = useState(
    qcSheet?.no_change_in_condition === true
    // qcSheet?.no_change_in_condition === true ? false : qcSheet?.no_change_in_condition
  );
  const [changeInConditionCb, setChangeInConditionCb] = useState(
    qcSheet?.change_in_condition === true
    // qcSheet?.change_in_condition === true ? false : qcSheet?.change_in_condition
  );
  const [showChangeInConditionModal, setShowChangeInConditionModal] = useState(false);
  const [statusValue, setStatusValue] = useState(statusOptions[0]);
  const [isEdited, setIsEdited] = useState(false);
  const [isEditedFeImageInternal, setIsEditedFeImageInternal] = useState(false);
  const [isEditedReImageInternal, setIsEditedReImageInternal] = useState(false);
  const [isEditedFeImageCustomer, setIsEditedFeImageCustomer] = useState(false);
  const [isEditedReImageCustomer, setIsEditedReImageCustomer] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [currentDrawing, setCurrentDrawing] = useState("");

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);
  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const handleOpenChangeInConditionModal = () => setShowChangeInConditionModal(true);
  const handleCloseChangeInConditionModal = () => setShowChangeInConditionModal(false);

  const handleOpenSuccessDialog = (value = "") => {
    setShowSuccessDialogue(true);
    playDoneSound();
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialogue(false);
    if (responseDisplay?.subitem_status === "awaiting_qc") {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE);
      setTimeout(() => {
        navigate(WAREHOUSE_QUALITY_CHECK.DETAIL_ROUTE, {
          state: { from: WAREHOUSE_QUALITY_CHECK?.LIST_ROUTE },
        });
      }, 100);
    } else {
      navigate(WAREHOUSE_QUALITY_CHECK.LIST_ROUTE);
    }
  };

  const handleOpenDialog = (value = "") => {
    setFilenameToRemove(value);
    setShowConfirmationDialogue(true);
    setConfirmationDialougeMessage({
      title: "Remove Attachment",
      body: "Are you sure you want to remove this attachment?",
    });
  };
  const handleCloseDialog = () => setShowConfirmationDialogue(false);

  const updateState = (index, name, value) => {
    // UPDATE with render
    const newArray = lensChecklist.map((item, i) => {
      // console.log(item, i, index, index === i);
      if (index === i) {
        return { ...item, value };
      }
      return item;
    });
    setLensChecklist(newArray);
    // values.subitems = newArray;

    // UPDATE without render

    // lensChecklist[index] = {
    //   ...lensChecklist[index],
    //   value,
    // };

    // console.log(lensChecklist);
  };

  const handleOpenFEDrawingModal = (action = "") => {
    // if (newQcSheet) {
    // console.log(feImageSrc?.includes("data:image/png;base64") ? feImageSrc : "");
    setCurrentDrawing(action);
    setShowFEDrawingModal(true);
    // }
  };

  const handleOpenREDrawingModal = (action = "") => {
    // if (newQcSheet) {
    setCurrentDrawing(action);
    setShowREDrawingModal(true);
    // }
  };

  const handleCloseDrawingModal = (action = "", value = "") => {
    switch (action) {
      case drawingStateOptions[0]: // internal fe
        setFeImageSrcInternal(value);
        setShowFEDrawingModal(false);
        // setIsEdited(true);
        setIsEditedFeImageInternal(true);
        break;
      case drawingStateOptions[1]: // internal re
        setReImageSrcInternal(value);
        setShowREDrawingModal(false);
        // setIsEdited(true);
        setIsEditedReImageInternal(true);
        break;
      case drawingStateOptions[2]: // customer fe
        setFeImageSrcCustomer(value);
        setShowFEDrawingModal(false);
        // setIsEdited(true);
        setIsEditedFeImageCustomer(true);
        break;
      case drawingStateOptions[3]: // customer re
        setReImageSrcCustomer(value);
        setShowREDrawingModal(false);
        // setIsEdited(true);
        setIsEditedReImageCustomer(true);
        break;
      default:
        setShowFEDrawingModal(false);
        setShowREDrawingModal(false);
        break;
    }
  };

  const handleOpenCapturePhotoModal = () => {
    setCapturePhoto(true);
  };

  const handleCloseCapturePhotoModal = (action = "", status = "") => {
    switch (action) {
      case "success":
        setCapturePhoto(false);
        setSuccessMsg("Successfully Uploaded Photo.");
        openSuccessSB(true);
        // if (status === "on_rent") {
        //   handleOpenConfirmationDialogue();
        // } else {
        loadDetails();
        // }

        break;
      case "failed":
        setErrMsg("Failed to Upload File");
        openErrorSB(true);
        break;
      default:
        setCapturePhoto(false);
        break;
    }
  };

  const handleOpenUpload = (attachmentType = "photo") => {
    setShowUpload(true);
    // setUploadData(attachmentType);
  };

  const handleCloseupload = (action = "", status = "") => {
    setNotifTitle("Upload Document");
    if (status === "uploaded") {
      setSuccessMsg("Successfully Uploaded File");
      openSuccessSB(true);
      loadDetails();
    }

    setShowUpload(false);
  };

  const handleRemoveItem = async () => {
    const body = {
      file_name: fileNameToRemove,
      action: "remove",
    };
    // console.log(body);
    const response = await httpRequest("remove-attachment-qc", body, { uuid: qcDetails?.uuid });

    // console.log(response);
    if (getResponseMsg(response) === "Success") {
      setSuccessMsg("Successfully removed attachment.");
      openSuccessSB(true);
      loadDetails();
    } else {
      let responseErrMsg = "Failed to Remove attachment.";
      // Object.entries(response.data).map((item) => {
      //   responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
      //   return 0;
      // });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    }
    handleCloseDialog();
  };

  const generateChecklist = () => (
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
      {lensChecklist.map((list, index) => (
        <Grid item xs={4} sm={4} md={6}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={3.5} sm={6} md={10}>
              <MDTypography variant="button" fontWeight="regular">
                {list?.name}&nbsp;
              </MDTypography>
            </Grid>
            <Grid item xs={0.5} sm={1} md={1}>
              <Checkbox
                sx={{ border: "1px solid #4287f5", borderRadius: "30%", margin: "0", p: 0 }}
                disabled={disableFields}
                onChange={(e) => {
                  // console.log(index, list?.name, e.target.checked);
                  setIsEdited(true);
                  updateState(index, list?.name, e.target.checked);
                }}
                checked={list?.value}
                inputProps={{ "aria-label": "controlled" }}
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );

  const generateImageList = () => (
    <ImageList
      sx={{
        width: "auto",
        maxHeight: "400px",
        marginLeft: 2,
      }}
      cols={2}
    >
      {/* <ImageListItem key="Subheader" cols={2}>
        <ListSubheader component="div">Attachments:</ListSubheader>
      </ImageListItem> */}
      {qcSheet?.attachments.map((item) => (
        <ImageListItem
          key={item.img}
          // onClick={() => window.open(item.file_url, "_blank")}
          height="auto"
          width="auto"
        >
          <MDBox>
            <Card
              sx={{
                borderRadius: "5%",
                border: "1px solid #000",
              }}
            >
              <CardMedia
                sx={{
                  display: "flex",
                  width: "auto",
                  height: "150px",
                }}
                image={item.file_url}
                title={item.file_name}
                onClick={() => window.open(item.file_url, "_blank")}
              />
              <CardActions>
                <Typography gutterBottom variant="caption" component="div">
                  {item.file_name}
                </Typography>
                <IconButton
                  color="error"
                  aria-label={`info about ${item.file_name}`}
                  onClick={() => {
                    // console.log(item?.file_name);
                    handleOpenDialog(item?.file_name);
                    // console.log({
                    //   file_name: item.file_name,
                    //   action: "remove",
                    // });
                  }}
                  size="large">
                  <Icon fontSize="small">delete</Icon>
                </IconButton>
              </CardActions>
            </Card>
          </MDBox>
        </ImageListItem>
      ))}
    </ImageList>
  );

  const saveQcSheetWithCondition = async (action = "") => {
    document.body.style.cursor = "wait";

    let response = {};
    let responseNoChange = {};
    let responseChangeCondition = {};
    if (qcSheetDetails?.uuid === undefined) {
      response = await httpRequest(
        "save-qc-sheet",
        {
          sheet_type: "lens",
          technician: technicianValue || "",
          checklist: lensChecklist,
          private_service_notes: privateServiceNotes,
          service_notes: {
            firmware: firmwareValue,
            other: otherValue,
          },
          drawings: {
            internal: {
              fe: {
                is_checked: feCbValueInternal === true,
                // file_url: feImageSrc === CircleWhiteImg ? "" : feImageSrc,
                file_url: feImageSrcInternal?.includes("data:image/png;base64")
                  ? feImageSrcInternal
                  : "",
              },
              re: {
                is_checked: reCbValueInternal === true,
                file_url: reImageSrcInternal?.includes("data:image/png;base64")
                  ? reImageSrcInternal
                  : "",
              },
            },
            customer: {
              fe: {
                is_checked: feCbValueCustomer === true,
                file_url: feImageSrcCustomer?.includes("data:image/png;base64")
                  ? feImageSrcCustomer
                  : "",
              },
              re: {
                is_checked: reCbValueCustomer === true,
                file_url: reImageSrcCustomer?.includes("data:image/png;base64")
                  ? reImageSrcCustomer
                  : "",
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    } else {
      response = await httpRequest(
        "update-qc-sheet",
        {
          sheet: qcSheetDetails?.uuid,
          sheet_type: "lens",
          technician: technicianValue || "",
          checklist: lensChecklist,
          private_service_notes: privateServiceNotes,
          service_notes: {
            firmware: firmwareValue,
            other: otherValue,
          },
          drawings: {
            internal: {
              fe: {
                edited: isEditedFeImageInternal,
                is_checked: feCbValueInternal === true,
                // file_url: feImageSrc === CircleWhiteImg ? "" : feImageSrc,
                file_url: feImageSrcInternal?.includes("data:image/png;base64")
                  ? feImageSrcInternal
                  : "",
              },
              re: {
                edited: isEditedReImageInternal,
                is_checked: reCbValueInternal === true,
                file_url: reImageSrcInternal?.includes("data:image/png;base64")
                  ? reImageSrcInternal
                  : "",
              },
            },
            customer: {
              fe: {
                edited: isEditedFeImageCustomer,
                is_checked: feCbValueCustomer === true,
                // file_url: feImageSrc === CircleWhiteImg ? "" : feImageSrc,
                file_url: feImageSrcCustomer?.includes("data:image/png;base64")
                  ? feImageSrcCustomer
                  : "",
              },
              re: {
                edited: isEditedReImageCustomer,
                is_checked: reCbValueCustomer === true,
                file_url: reImageSrcCustomer?.includes("data:image/png;base64")
                  ? reImageSrcCustomer
                  : "",
              },
            },
          },
        },
        {
          uuid: qcDetails?.uuid,
        }
      );
    }

    if (getResponseMsg(response) === "Success") {
      setSuccessMsg("Successfully Saved all changes.");
      openSuccessSB(true);
      loadDetails();
      setFeImageSrcInternal(response?.data?.data?.drawings?.internal?.fe?.file_url);
      setReImageSrcInternal(response?.data?.data?.drawings?.internal?.re?.file_url);
      setFeImageSrcCustomer(response?.data?.data?.drawings?.customer?.fe?.file_url);
      setReImageSrcCustomer(response?.data?.data?.drawings?.customer?.re?.file_url);
      setIsEdited(false);
      setIsEditedFeImageInternal(false);
      setIsEditedReImageInternal(false);
      setIsEditedFeImageCustomer(false);
      setIsEditedReImageCustomer(false);
      setQcSheetDetails(response?.data?.data);
      switch (action) {
        case "no-change":
          responseNoChange = await httpRequest(
            "conditions",
            {
              sheet: response?.data?.data?.uuid,
              change_in_condition: false,
            },
            { uuid: qcDetails?.uuid }
          );
          if (getResponseMsg(responseNoChange) === "Success") {
            setNoChangeInConditionCb(true);
            setResponseDisplay(responseNoChange?.data?.data);
            handleOpenSuccessDialog();
          } else {
            setNotifTitle("No Change in Condition");
            let responseErrMsg = "Failed to save changes.";
            Object.entries(responseNoChange.data).map((item) => {
              responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
              return 0;
            });
            setErrMsg(responseErrMsg);
            openErrorSB(true);
          }
          break;
        case "change":
          setNotifTitle("Change in Condition");
          responseChangeCondition = await httpRequest(
            "conditions",
            {
              sheet: response?.data?.data?.uuid,
              change_in_condition: true,
              status: statusValue?.value,
            },
            { uuid: qcDetails?.uuid }
          );
          if (getResponseMsg(responseChangeCondition) === "Success") {
            setShowChangeInConditionModal(false);
            setResponseDisplay(responseChangeCondition?.data?.data);
            handleOpenSuccessDialog();
          } else {
            let responseErrMsg = "Failed to save changes.";
            Object.entries(responseChangeCondition.data).map((item) => {
              responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
              return 0;
            });
            setErrMsg(responseErrMsg);
            openErrorSB(true);
          }
          break;
        default:
          break;
      }
    } else {
      let responseErrMsg = "QC Sheet not saved.";
      Object.entries(responseChangeCondition.data).map((item) => {
        responseErrMsg = `${capitalizeFirstLetter(item[0]).replaceAll("_", " ")}: ${item[1]}`;
        return 0;
      });
      setErrMsg(responseErrMsg);
      openErrorSB(true);
    }
    document.body.style.cursor = "default";
  };

  useEffect(() => {
    document.body.style.cursor = "wait";
    if (newQcSheet) {
      setTechnicianValue(`${userLoggedin?.first_name} ${userLoggedin?.last_name}`);
    }
    document.body.style.cursor = "default";
  }, [userLoggedin]);

  useEffect(() => {
    if (newQcSheet) {
      setLensChecklist([
        { name: "Front Element", value: false },
        { name: "Focus", value: false },
        { name: "Rear Element", value: false },
        { name: "Image Shift/Tracking", value: false },
        { name: "Iris/Focus/Zoom Rings", value: false },
        { name: "Servo Unit", value: false },
      ]);
    } else {
      setLensChecklist(qcSheet?.checklist);
      setAttachments(qcSheet?.attachments);
    }
    document.body.style.cursor = "default";
  }, []);

  useEffect(() => {
    const setResponsiveness = () =>
      window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  return (
    <MDBox m={2}>
      <MDBox display="flex" justifyContent="space-between">
        <MDTypography variant="h6" color="info">
          QC Checklist - LENS
        </MDTypography>
      </MDBox>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }} my={2}>
        <Grid item xs={4} sm={4} md={6}>
          <MDTypography variant="button" fontWeight="regular">
            Date:&nbsp;
          </MDTypography>
          <MDTypography variant="button" fontWeight="medium">
            {formatDate(
              checkNullEmpty(qcSheetDetails?.date) ? getDateToday() : qcSheetDetails?.date,
              "MMMM DD, YYYY"
            )}
          </MDTypography>
        </Grid>
        <Grid item xs={4} sm={4} md={6}>
          <MDBox display="flex">
            <MDBox mr={1} display="flex">
              <MDTypography variant="button" fontWeight="regular">
                Technician
              </MDTypography>
              {/* <MDTypography variant="button" fontWeight="regular" color="error">
                *&nbsp;
              </MDTypography> */}
              <MDTypography variant="button" fontWeight="regular">
                :&nbsp;
              </MDTypography>
            </MDBox>
            <MDBox>
              <MDInput
                value={technicianValue}
                disabled={disableFields}
                variant="standard"
                onChange={(e) => {
                  setTechnicianValue(e.target.value);
                  setIsEdited(true);
                }}
              />
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
      <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={4} sm={8} md={8}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12} ml={1}>
              {generateChecklist()}
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                {/* <MDBox
                  display="flex"
                  justifyContent="space-evenly"
                  // sx={{ display: "absolute", marginLeft: "auto", marginRight: "auto", width: "40% " }}
                > */}
                <Grid item xs={4} sm={8} md={6}>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDBox>
                      <MDTypography variant="button" fontWeight="regular">
                        FE (Internal)
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDBox
                        display="flex"
                        alignItems="flex-end"
                        mt={-1}
                        sx={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
                      >
                        <MDBox>
                          {/* <img src={feImageSrc} alt="car" /> */}
                          <CardMedia
                            component="img"
                            sx={{
                              display: "flex",
                              width: "150px",
                              height: "150px",
                              // backgroundColor: "gray",
                              // borderRadius: "50%",
                              // borderStyle: "solid",
                            }}
                            image={feImageSrcInternal || CircleWhiteImg}
                            onClick={() => {
                              if (!disableFields) {
                                handleOpenFEDrawingModal(drawingStateOptions[0]);
                              }
                            }}
                            alt="FE"
                          />
                        </MDBox>
                        <MDBox ml={-3}>
                          <Checkbox
                            disabled={disableFields}
                            sx={{
                              border: "1px solid #4287f5",
                              borderRadius: "30%",
                              margin: "0",
                              p: 0,
                            }}
                            onChange={(e) => {
                              setFeCheckboxValueInternal(e.target.checked);
                              setIsEdited(true);
                              // console.log(index, list?.name, e.target.checked);
                              // updateState(index, list?.name, e.target.checked);
                            }}
                            checked={feCbValueInternal}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </MDBox>
                      </MDBox>

                      {isEditedFeImageInternal && (
                        <MDBox>
                          <MDTypography variant="caption" fontWeight="regular" color="warning">
                            * Image is edited. Please save to avoid data loss. *
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={4} sm={8} md={6}>
                  <MDBox>
                    <MDBox sx={{ textAlign: "center" }}>
                      <MDTypography variant="button" fontWeight="regular">
                        RE (Internal)
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDBox
                        display="flex"
                        alignItems="flex-end"
                        mt={-1}
                        sx={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
                      >
                        <MDBox>
                          <CardMedia
                            component="img"
                            sx={{
                              display: "flex",
                              width: "120px",
                              height: "120px",
                              // backgroundColor: "gray",
                              // borderRadius: "50%",
                              // borderStyle: "solid",
                            }}
                            image={reImageSrcInternal || CircleWhiteImg}
                            onClick={() => {
                              if (!disableFields) {
                                handleOpenREDrawingModal(drawingStateOptions[1]);
                              }
                            }}
                            alt="RE"
                          />
                        </MDBox>
                        <MDBox ml={-3}>
                          <Checkbox
                            disabled={disableFields}
                            sx={{
                              border: "1px solid #4287f5",
                              borderRadius: "30%",
                              margin: "0",
                              p: 0,
                            }}
                            onChange={(e) => {
                              setReCheckboxValueInternal(e.target.checked);
                              setIsEdited(true);
                              // console.log(index, list?.name, e.target.checked);
                              // updateState(index, list?.name, e.target.checked);
                            }}
                            checked={reCbValueInternal}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </MDBox>
                      </MDBox>
                      {isEditedReImageInternal && (
                        <MDBox>
                          <MDTypography variant="caption" fontWeight="regular" color="warning">
                            * Image is edited. Please save to avoid data loss. *
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
              </Grid>
            </Grid>
            {/* <MDBox>
              <hr
                style={{
                  // background: "lime",
                  // color: "lime",
                  // borderColor: "lime",
                  height: "1px",
                }}
              />
            </MDBox> */}
            <Grid item xs={4} sm={8} md={12}>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                {/* <MDBox
                  display="flex"
                  justifyContent="space-evenly"
                  // sx={{ display: "absolute", marginLeft: "auto", marginRight: "auto", width: "40% " }}
                > */}
                <Grid item xs={4} sm={8} md={6}>
                  <MDBox mt={2}>
                    <hr
                      style={{
                        // background: "lime",
                        // color: "lime",
                        // borderColor: "lime",
                        height: "1px",
                      }}
                    />
                  </MDBox>
                  <MDBox sx={{ textAlign: "center" }}>
                    <MDBox>
                      <MDTypography variant="button" fontWeight="regular">
                        FE (Customer)
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDBox
                        display="flex"
                        alignItems="flex-end"
                        mt={-1}
                        sx={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
                      >
                        <MDBox>
                          {/* <img src={feImageSrc} alt="car" /> */}
                          <CardMedia
                            component="img"
                            sx={{
                              display: "flex",
                              width: "150px",
                              height: "150px",
                              // backgroundColor: "gray",
                              // borderRadius: "50%",
                              // borderStyle: "solid",
                            }}
                            image={feImageSrcCustomer || CircleWhiteImg}
                            onClick={() => {
                              if (!disableFields) {
                                handleOpenFEDrawingModal(drawingStateOptions[2]);
                              }
                            }}
                            alt="FE"
                          />
                        </MDBox>
                        <MDBox ml={-3}>
                          <Checkbox
                            disabled={disableFields}
                            sx={{
                              border: "1px solid #4287f5",
                              borderRadius: "30%",
                              margin: "0",
                              p: 0,
                            }}
                            onChange={(e) => {
                              setFeCheckboxValueCustomer(e.target.checked);
                              setIsEdited(true);
                              // console.log(index, list?.name, e.target.checked);
                              // updateState(index, list?.name, e.target.checked);
                            }}
                            checked={feCbValueCustomer}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </MDBox>
                      </MDBox>

                      {isEditedFeImageCustomer && (
                        <MDBox>
                          <MDTypography variant="caption" fontWeight="regular" color="warning">
                            * Image is edited. Please save to avoid data loss. *
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>
                <Grid item xs={4} sm={8} md={6}>
                  {!isMobile && (
                    <MDBox mt={2}>
                      <hr
                        style={{
                          // background: "lime",
                          // color: "lime",
                          // borderColor: "lime",
                          height: "1px",
                        }}
                      />
                    </MDBox>
                  )}
                  <MDBox>
                    <MDBox sx={{ textAlign: "center" }}>
                      <MDTypography variant="button" fontWeight="regular">
                        RE (Customer)
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <MDBox
                        display="flex"
                        alignItems="flex-end"
                        mt={-1}
                        sx={{ marginLeft: "auto", marginRight: "auto", width: "50%" }}
                      >
                        <MDBox>
                          <CardMedia
                            component="img"
                            sx={{
                              display: "flex",
                              width: "120px",
                              height: "120px",
                              // backgroundColor: "gray",
                              // borderRadius: "50%",
                              // borderStyle: "solid",
                            }}
                            image={reImageSrcCustomer || CircleWhiteImg}
                            onClick={() => {
                              if (!disableFields) {
                                handleOpenREDrawingModal(drawingStateOptions[3]);
                              }
                            }}
                            alt="RE"
                          />
                        </MDBox>
                        <MDBox ml={-3}>
                          <Checkbox
                            disabled={disableFields}
                            sx={{
                              border: "1px solid #4287f5",
                              borderRadius: "30%",
                              margin: "0",
                              p: 0,
                            }}
                            onChange={(e) => {
                              setReCheckboxValueCustomer(e.target.checked);
                              setIsEdited(true);
                              // console.log(index, list?.name, e.target.checked);
                              // updateState(index, list?.name, e.target.checked);
                            }}
                            checked={reCbValueCustomer}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </MDBox>
                      </MDBox>
                      {isEditedReImageCustomer && (
                        <MDBox>
                          <MDTypography variant="caption" fontWeight="regular" color="warning">
                            * Image is edited. Please save to avoid data loss. *
                          </MDTypography>
                        </MDBox>
                      )}
                    </MDBox>
                  </MDBox>
                </Grid>

                {/* </MDBox> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12}>
            <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDTypography variant="h6" fontWeight="medium" mt={2}>
                  Service Notes
                </MDTypography>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      Firmware:&nbsp;
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={firmwareValue}
                      color="info"
                      onChange={(e) => {
                        setFirmwareValue(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="caption" fontWeight="regular">
                      Other:&nbsp;
                    </MDTypography>
                  </Grid>
                  {/* <MDEditor
                value={otherValue}
                readOnly={!newQcSheet}
                onChange={setOtherValue}
                variant="standard"
              /> */}
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    {/* {newQcSheet ? ( */}
                    <MDEditor
                      readOnly={disableFields}
                      value={otherValue}
                      onChange={(e) => {
                        setOtherValue(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                    {/* ) : (
                      <MDTypography variant="button" fontWeight="regular">
                        {otherValue}
                      </MDTypography>
                    )} */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={12} mt={2}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={6}>
                <Grid container spacing={1} columns={{ xs: 4, sm: 8, md: 12 }}>
                  <Grid item xs={4} sm={8} md={12}>
                    <MDTypography variant="h6" fontWeight="medium">
                      Private Service Notes
                    </MDTypography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sm={8}
                    md={12}
                    sx={{
                      // length: "10%",
                      width: "100%",
                      height: "auto",
                      maxHeight: "250px",
                      overflow: "auto",
                    }}
                  >
                    <MDEditor
                      readOnly={disableFields}
                      value={privateServiceNotes}
                      onChange={(e) => {
                        setPrivateServerNotes(e);
                        setIsEdited(true);
                      }}
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={4} md={12} mt={2}>
            <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={4} sm={8} md={12}>
                <MDBox display="flex">
                  <MDTypography variant="h6" fontWeight="medium">
                    Item Condition
                  </MDTypography>
                </MDBox>
              </Grid>
              <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={4} sm={8} md={6}>
                  <Checkbox
                    checked={noChangeInConditionCb}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ border: "1px solid #4287f5", borderRadius: "30%", p: 0 }}
                    onClick={() => saveQcSheetWithCondition("no-change")}
                    // disabled={qcSheet?.uuid === undefined || disableFields}
                  />
                  <MDTypography variant="caption" fontWeight="regular" ml={2}>
                    No change in condition&nbsp;
                  </MDTypography>
                </Grid>
                <Grid item xs={4} sm={8} md={6}>
                  <Checkbox
                    checked={changeInConditionCb}
                    inputProps={{ "aria-label": "controlled" }}
                    // disabled={qcSheet?.uuid === undefined || disableFields}
                    onClick={handleOpenChangeInConditionModal}
                    sx={{ border: "1px solid #4287f5", borderRadius: "30%", p: 0 }}
                  />
                  <MDTypography variant="caption" fontWeight="regular" ml={2}>
                    Change in condition&nbsp;
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} sm={8} md={4}>
          <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                sx={{ textAlign: "center" }}
                mt={2}
              >
                <MDBox mr={2}>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleOpenCapturePhotoModal}
                    disabled={disableFields}
                  >
                    Capture
                  </MDButton>
                </MDBox>
                <MDBox>
                  <MDButton
                    variant="outlined"
                    color="info"
                    size="small"
                    onClick={handleOpenUpload}
                    disabled={disableFields}
                  >
                    Upload
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={4} sm={8} md={12}>
              <MDBox>
                {qcSheet?.attachments?.length === 0 ? (
                  <MDBox mr={1} sx={{ textAlign: "center" }}>
                    <MDTypography variant="button" fontWeight="regular">
                      No Attachments
                    </MDTypography>
                  </MDBox>
                ) : (
                  <MDBox mr={1} mt={2}>
                    {qcSheet?.attachments?.length > 0 && generateImageList()}
                  </MDBox>
                )}
              </MDBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showFEDrawingModal && (
        <Modal
          open={showFEDrawingModal}
          onClose={handleCloseDrawingModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
            <FEDrawing
              loadDetails={loadDetails}
              handleCloseModal={handleCloseDrawingModal}
              // feImageSrc={feImageSrc}
              // imgSrc={
              //   feImageSrcInternal?.includes("data:image/png;base64") ? feImageSrcInternal : ""
              // }
              currentDrawing={currentDrawing}
              imgSrc={
                currentDrawing === drawingStateOptions[0] &&
                feImageSrcInternal?.includes("data:image/png;base64")
                  ? feImageSrcInternal
                  : currentDrawing === drawingStateOptions[2] &&
                    feImageSrcCustomer?.includes("data:image/png;base64")
                  ? feImageSrcCustomer
                  : ""
              }
            />
          </MDBox>
        </Modal>
      )}

      {showREDrawingModal && (
        <Modal
          open={showREDrawingModal}
          onClose={handleCloseDrawingModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor={darkMode ? "dark" : "white"} sx={style}>
            <REDrawing
              loadDetails={loadDetails}
              handleCloseModal={handleCloseDrawingModal}
              // reImageSrc={reImageSrc}
              // imgSrc={reImageSrc?.includes("data:image/png;base64") ? reImageSrc : ""}

              currentDrawing={currentDrawing}
              imgSrc={
                currentDrawing === drawingStateOptions[1] &&
                reImageSrcInternal?.includes("data:image/png;base64")
                  ? reImageSrcInternal
                  : currentDrawing === drawingStateOptions[3] &&
                    reImageSrcCustomer?.includes("data:image/png;base64")
                  ? reImageSrcCustomer
                  : ""
              }
            />
          </MDBox>
        </Modal>
      )}

      {showCapturePhoto && (
        <Modal
          open={showCapturePhoto}
          onClose={handleCloseCapturePhotoModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={style}>
            <Photo
              loadDetails={loadDetails}
              handleCloseModal={handleCloseCapturePhotoModal}
              // changeCamera={changeCamera}
              // facingModeValue={facingMode}
              // photoType={photoType}
            />
          </MDBox>
        </Modal>
      )}

      {showUpload && (
        <Modal
          open={showUpload}
          onClose={handleCloseupload}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <MDBox bgColor="white" sx={isMobile ? styleUploadMobile : styleUploadWeb}>
            <DragAndDrop
              uuid={qcDetails?.uuid}
              // attachmentType={uploadData}
              onClose={handleCloseupload}
              multiple
            />
          </MDBox>
        </Modal>
      )}

      {showConfirmationDialogue && (
        // <div>
        <Dialog open={showConfirmationDialogue} onClose={handleCloseDialog}>
          <DialogTitle>{confirmationDialougeMessage?.title}</DialogTitle>
          {/* <DialogTitle>Remove Attachment</DialogTitle> */}
          <DialogContent>
            {/* <DialogContentText>Are you sure you want to remove this attachment?</DialogContentText> */}
            <DialogContentText>{confirmationDialougeMessage?.body}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseDialog}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => handleRemoveItem()}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
        // </div>
      )}

      {showSuccessDialogue && (
        <Dialog
          open={showSuccessDialogue}
          onClose={handleCloseSuccessDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{notifTitle}</DialogTitle>
          <DialogContent>
            {/* <DialogContentText id="alert-dialog-description">
              ♦ Sub-item status is now&nbsp;
              {capitalizeFirstLetter(responseDisplay?.subitem_status).replaceAll("_", " ")}.
            </DialogContentText> */}
            {responseDisplay?.subitem_status === "awaiting_qc" && (
              <DialogContentText id="alert-dialog-description">
                ♦ QC sheet will reset.
              </DialogContentText>
            )}

            {responseDisplay?.subitem_status !== "awaiting_qc" && (
              <DialogContentText id="alert-dialog-description">
                ♦ Sub-item status is now&nbsp;
                {capitalizeFirstLetter(responseDisplay?.subitem_status).replaceAll("_", " ")}.
              </DialogContentText>
            )}
            {responseDisplay?.order_status === "ready_to_invoice" && (
              <DialogContentText id="alert-dialog-description">
                ♦ Order status is now&nbsp;
                {capitalizeFirstLetter(responseDisplay?.order_status).replaceAll("_", " ")}.
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <MDButton color="success" onClick={handleCloseSuccessDialog} autoFocus>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      {showChangeInConditionModal && (
        <Dialog open={showChangeInConditionModal} onClose={handleCloseChangeInConditionModal}>
          <DialogTitle>Change in condition</DialogTitle>
          <DialogContent>
            <DialogContentText>Change status of this sub-item.</DialogContentText>
            <MDBox>
              <Autocomplete
                scroll="body"
                value={statusValue || null}
                options={statusOptions}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setStatusValue(newValue);
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  value !== "" ? option.name === value.name : option.name
                }
                getOptionLabel={(option) => option.name || ""}
                fullWidth
                getOptionDisabled={(option) => option.value === statusValue.value}
                renderInput={(params) => <TextField {...params} />}
              />
            </MDBox>
            {/* </Box> */}
          </DialogContent>
          <DialogActions>
            <MDButton color="error" onClick={handleCloseChangeInConditionModal}>
              Cancel
            </MDButton>
            <MDButton color="info" onClick={() => saveQcSheetWithCondition("change")}>
              Confirm
            </MDButton>
          </DialogActions>
        </Dialog>
      )}

      <MDSnackbar
        color="error"
        icon="warning"
        title="QC Sheet"
        dateTime=""
        content={errMsg}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite={false}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title="QC Sheet"
        dateTime=""
        content={successMsg}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite={false}
      />
    </MDBox>
  );
}
Lens.defaultProps = {
  data: {},
  userDetail: {},
  disableFields: false,
};

Lens.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  loadDetails: PropTypes.func.isRequired,
  userDetail: PropTypes.objectOf(PropTypes.any),
  disableFields: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  userDetail: state?.authentication?.userDetail,
});
export default connect(mapStateToProps, null)(Lens);
