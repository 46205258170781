/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import { Icon } from "@mui/material";
import { useMaterialUIController } from "context";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import MDTypography from "components/MDTypography";

import ReportsMgr from "layouts/Reports/ReportsMgr";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const { httpRequest, getResponseMsg, formatDate, convertToCurrency } = ReportsMgr;

function WeeklyReport({ reportsData }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dataTableData = {
    columns: [
      {
        Header: "Day",
        accessor: "date",
        width: "10%",
        align: "center",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox align="center">
                <MDTypography variant="button" fontWeight="regular">
                  {/* {moment(formatDate(value, "LL")).date()} */}
                  {formatDate(value, "LL")}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Cash",
        accessor: "cash",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start">
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Check",
        accessor: "check",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Credit/Debit",
        accessor: "credit_debit",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "ACH Transfer",
        accessor: "ach",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Wire Transfer",
        accessor: "wire_transfer",
        width: "10%",
        align: "right",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Total Payment",
        accessor: "total_payment",
        width: "10%",
        align: "right",
        headerAlign: "center",
        hasBorderRight: true,
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Rental Revenue",
        accessor: "rental_revenue",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Sales Revenue",
        accessor: "sales_revenue",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Other Revenue",
        accessor: "other_revenue",
        align: "right",
        headerAlign: "center",
        width: "10%",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      // {
      //   Header: "Discounts Given",
      //   accessor: "discounts_given",
      //   align: "right",
      //   width: "10%",
      //   headerAlign: "left",
      //   Cell: ({ value }) => {
      //     const status = (
      //       <MDBox>
      //         <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
      //           <MDTypography variant="button" fontWeight="regular">
      //             {convertToCurrency(value || 0)}
      //           </MDTypography>
      //         </MDBox>
      //       </MDBox>
      //     );
      //     return status;
      //   },
      // },
      {
        Header: "Taxes",
        accessor: "taxes",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
      {
        Header: "Total Revenue",
        accessor: "total_revenue",
        align: "right",
        width: "10%",
        headerAlign: "center",
        Cell: ({ value }) => {
          const status = (
            <MDBox>
              <MDBox direction="column" justifyContent="flex-start" RMRDateRangePicker>
                <MDTypography variant="button" fontWeight="regular">
                  {convertToCurrency(value || 0)}
                </MDTypography>
              </MDBox>
            </MDBox>
          );
          return status;
        },
      },
    ],
    rows: reportsData?.day_totals || [],
    // rows: [],
  };

  return (
    <MDBox mt={2}>
      {/* {reportsData?.day_totals?.length > 0 && (
        <MDBox ml={1} mb={2} display="flex" justifyContent="flex-end">
          <MDBox mr={1}>
            <MDButton
              variant="outlined"
              color="dark"
              onClick={async () => {
                document.body.style.cursor = "wait";
                const response = await httpRequest(
                  "export-weekly-rentals",
                  {},
                  {
                    dateFrom: formatDate(reportsData?.dateFrom, "YYYY-MM-DD"),
                    dateTo: formatDate(reportsData?.dateTo, "YYYY-MM-DD"),
                    warehouse: reportsData?.warehouseFilter?.id,
                    exportType: "pdf",
                  }
                );

                if (getResponseMsg(response) === "Success") {
                  window.open(response?.data?.data?.url, "_blank", "noreferrer");
                }
                document.body.style.cursor = "default";
              }}
            >
              <Icon>description</Icon>
              &nbsp;export PDF
            </MDButton>
          </MDBox>
        </MDBox>
      )} */}
      <MDBox>
        <Card width="100%">
          <DataTable
            table={dataTableData}
            noEndBorder={false}
            // details={showItemDetail}
            // hasNextPage={nextPageLink !== null}
            // hasPrevPage={prevPageLink !== null}
            totals={{
              show: true,
              hasBorderRight: true,
              hasBorderTop: true,
              align: "right",
              totalRows: [
                "Totals",
                // "",
                `${convertToCurrency(reportsData?.column_totals?.cash || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.check || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.credit_debit || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.ach || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.wire_transfer || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.total_payment || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.rental_revenue || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.sales_revenue || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.other_revenue || 0)}`,
                // `${convertToCurrency(reportsData?.column_totals?.discounts_given || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.taxes || 0)}`,
                `${convertToCurrency(reportsData?.column_totals?.total_revenue || 0)}`,
              ],
            }}
            totalPercentage={{
              show: true,
              hasBorderRight: true,
              totalRows: [
                "Percentage Rate",
                // "",
                `${reportsData?.column_percentage?.cash}`,
                `${reportsData?.column_percentage?.check}`,
                `${reportsData?.column_percentage?.credit_debit}`,
                `${reportsData?.column_percentage?.ach}`,
                `${reportsData?.column_percentage?.wire_transfer}`,
                `${reportsData?.column_percentage?.total_payment}`,
                `${reportsData?.column_percentage?.rental_revenue}`,
                `${reportsData?.column_percentage?.sales_revenue}`,
                `${reportsData?.column_percentage?.other_revenue}`,
                // `${reportsData?.column_percentage?.discounts_given}`,
                `${reportsData?.column_percentage?.taxes}`,
                `${reportsData?.column_percentage?.total_revenue}`,
              ],
            }}
            canSearch={false}
            entriesPerPage={{ show: false, defaultValue: 25, entries: [10, 25, 50, 100] }}
            // title={`${reportToGenerate.name} Report List`}
            title={
              <MDBox>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    Weekly Rentals Report list - {reportsData?.warehouse}
                  </MDTypography>
                </MDBox>
                <MDBox>
                  <MDTypography variant="h6" fontWeight="medium">
                    {`${formatDate(reportsData?.dateFrom, "ll")} to ${formatDate(
                      reportsData?.dateTo,
                      "ll"
                    )}`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            }
            // isLoading={isLoading}
            // canRefresh
          />
        </Card>
      </MDBox>
    </MDBox>
  );
}

WeeklyReport.defaultProps = {
  reportsData: [],
  dateFrom: [],
};

WeeklyReport.propTypes = {
  reportsData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  dateFrom: PropTypes.string,
};

const mapStateToProps = (state) => ({
  categoriesListRedux: state?.helper?.categoryList,
  userDetail: state?.authentication?.userDetail,
  warehouseReduxList: state?.helper?.warehouse,
});

export default connect(mapStateToProps, null)(WeeklyReport);
