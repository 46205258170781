import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";

const CardContainer = ({ children }) => {
    return (
        <Card sx={{ overflow: "hidden", height: "auto" }}>
            <MDBox p={3}>{children}</MDBox>
        </Card>
    );
};

export default CardContainer;
