import pathUrl from "services/context/pathUrl";
import { RESPONSE_SUCCESS } from "services/context/response";
import helper from "services/helpers";
import HTTPSServices from "services/https/HTTPSServices";
import helperActions from "services/redux/actions/helperActions";
import utils from "services/utils";

const { postRequest, putRequest, getRequest, patchRequest, deleteRequest } = HTTPSServices;
const { MANAGE_USERS, _DASHBOARD } = pathUrl;
const {
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  limitInputLength,
  getResponseMsg,
  getErrorMessage,
  hasPermission,
  hasRolePermission,
} = utils;
const { setConsignerList } = helperActions;
const { validateEmail } = helper;

const httpRequest = async (action = "", body = {}, rest = {}) => {
  let response = null;
  const API = `${MANAGE_USERS.API}${rest?.uuid}`;

  switch (action) {
    case "users-list":
      response = await getRequest(
        `${MANAGE_USERS.API}?search=${rest?.searchKeyword || ""}&page_size=${
          rest?.entriesPerPage || ""
        }&status=${rest?.userStatus || ""}`
      );

      break;

    case "users-create":
      response = await postRequest(MANAGE_USERS.API, body);

      break;

    case "my-details":
      response = await postRequest("/users/me/", body);

      break;

    case "users-update":
      response = await putRequest(`${API}/`, body);

      break;

    case "users-detail":
      response = await getRequest(`${API}`);

      break;

    case "users-deactivate":
      response = await patchRequest(`${API}/deactivate/`);

      break;

    case "users-delete":
      response = await deleteRequest(`${API}/`);

      break;
    case "warehouse-list":
      response = await getRequest(`/locations/${rest?.uuid}/warehouses/`);

      break;

    case "update-basic-info":
      response = await getRequest(`/locations/${rest?.uuid}/warehouses/`);

      break;

    case "get-permission-list-by-role":
      response = await getRequest(`/permissions/?role=${rest?.role}`);

      break;

    case "users-reset-password":
      response = await putRequest(`${API}/reset-password/`, body);

      break;

    default:
      break;
  }

  return response;
};

const getNextPage = async (nextPageLink = "") => {
  const response = await getRequest(nextPageLink);

  return response;
};

const getPrevPage = async (prevPageLink = "") => {
  const response = await getRequest(prevPageLink);

  return response;
};

const getSort = async (
  sortType = "asc",
  id = "first_name",
  searchKeyword = "",
  entriesPerPage = 10
  // vendorType = "",
  // vendorStatus = "active"
) => {
  let response = null;

  if (sortType === "asc") {
    response = await getRequest(
      `${MANAGE_USERS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=${
        id === "full_name" ? "first_name" : id
        // id
      }`
    );
  } else {
    response = await getRequest(
      `${MANAGE_USERS.API}?search=${searchKeyword}&page=1&page_size=${entriesPerPage}&ordering=-${
        id === "full_name" ? "first_name" : id
        // id
      }`
    );
  }

  return response;
};

const getConsignerList = async () => {
  const response = await httpRequest("users-list", null, {
    searchKeyword: "",
    entriesPerPage: "0",
    vendorType: "consignment",
  });

  return response;
};

const UsersMgr = {
  getNextPage,
  getPrevPage,
  getSort,
  setStorageItem,
  getStorageItem,
  capitalizeFirstLetter,
  jsonToString,
  httpRequest,
  RESPONSE_SUCCESS,
  validateEmail,

  MANAGE_USERS,
  limitInputLength,
  getConsignerList,
  getResponseMsg,
  getErrorMessage,
  setConsignerList,
  _DASHBOARD,
  hasPermission,
  hasRolePermission,
};

export default UsersMgr;
